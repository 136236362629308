import { GlobalHolder } from 'app/global.holder';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { isPresent } from 'share';
import { CACHE_DATA } from '../../cache/component.cache.model';
import { ComponentCacheService } from '../../cache/component.cache.service';
import { FilterInitializer } from '../../initializer';
import { ComponentContext } from '../context';

export class CacheInitializer implements FilterInitializer<ComponentContext> {

    init(context: ComponentContext): Observable<any> {
        let cacheService = environment.injector.get(ComponentCacheService);
        let global = environment.injector.get(GlobalHolder);

        let user_id = global.user.getValue()?.user_id;
        let params = global.cache_params.getValue();
        if (!params.some(p => p.param_key === context.options.name && !'false'.equalsIgnoreCase(p.param_value))) {
            return of(context);
        }
        return cacheService.qry({ name: context.options.name, user_id }).pipe(
            tap(items => {
                let item = items[0];
                if (!isPresent(item)) {
                    CACHE_DATA.set(context.instance, { name: context.options.name, user_id, operate_type: 'C' });
                    return;
                }
                try {
                    item.operate_type = 'U';
                    let keyValues = JSON.parse(item.cache);
                    context.options.caches.forEach(key => context.instance[key] = keyValues[key]);
                    CACHE_DATA.set(context.instance, item);
                } catch (e) {
                }
            }),
            mapTo(context)
        );
    }

    support(context: ComponentContext): boolean {
        return context.options.caches.length !== 0;
    }

}
