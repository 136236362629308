import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PoolSiftService } from 'app/data/pool/filter/pool.filter.service';
import { SiftRule } from 'app/data/pool/pool.model';
import { isPresent } from 'share';

@Component({
    selector: 'ai-mode-value-filter',
    templateUrl: 'mode.value.filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ModeValueFilterComponent),
        multi: true
    }]
})

export class ModeValueFilterComponent implements ControlValueAccessor, OnInit, OnDestroy {

    filters: Array<SiftRule>;
    filter: any;

    private onChange: (_: any) => void;
    private values: Array<any>;

    constructor(private cdRef: ChangeDetectorRef, private siftService: PoolSiftService) {
        this.filter = '';
    }

    ngOnInit() {
    }

    render(): void {
        this.cdRef.markForCheck();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.values = obj;
        if (!isPresent(obj) || !obj[0]) {
            this.filter = '';
            return;
        }
        this.filter = +obj[1] || '';
        this.qryFilters(obj[0]);
    }

    private qryFilters(key: any): void {
        this.siftService.qryByPool(key).subscribe(items => {
            this.filters = items;
            this.render();
        });
    }

    updateValue(): void {
        this.values[1] = this.filter;
        this.onChange(this.values);
    }

    ngOnDestroy() {
    }
}
