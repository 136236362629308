/* tslint:disable */
// bootstrap
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';

//jquery
import 'jquery-mousewheel';

// echarts
import * as echarts from 'echarts/core';
import {
    BarChart,
    LineChart,
    PieChart,
    ScatterChart
} from 'echarts/charts';
import {
    AxisPointerComponent,
    DataZoomComponent,
    GridComponent,
    LegendComponent,
    LegendScrollComponent,
    GraphicComponent,
    MarkLineComponent,
    MarkPointComponent,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent
} from 'echarts/components';

import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
    BarChart,
    LineChart,
    PieChart,
    ScatterChart,
    AxisPointerComponent,
    DataZoomComponent,
    GridComponent,
    LegendComponent,
    LegendScrollComponent,
    GraphicComponent,
    MarkLineComponent,
    MarkPointComponent,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    CanvasRenderer
]);

$.fn.extend({
    tooltip: function() {
        return this;
    }
});

