import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { InputBoolean } from '../facade';

@Component({
    selector: 'ai-divider',
    exportAs: 'divider',
    preserveWhitespaces: false,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'divider.component.html',
    host: {
        '[class.divider]': 'true',
        '[class.divider-horizontal]': `type === 'horizontal'`,
        '[class.divider-vertical]': `type === 'vertical'`,
        '[class.divider-with-text]': `text`,
        '[class.divider-plain]': `plain`,
        '[class.divider-with-text-left]': `text && orientation === 'left'`,
        '[class.divider-with-text-right]': `text && orientation === 'right'`,
        '[class.divider-with-text-center]': `text && orientation === 'center'`,
        '[class.divider-dashed]': `dashed`
    }
})
export class DividerComponent {

    @Input() text: string;
    @Input() type: 'horizontal' | 'vertical' = 'horizontal';
    @Input() orientation: 'left' | 'right' | 'center' = 'center';
    @Input() @InputBoolean() dashed = false;
    @Input() @InputBoolean() plain = false;

}
