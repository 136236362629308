import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Group } from 'app/group/group.model';
import { GroupService } from 'app/group/group.service';


@Injectable({ providedIn: 'root' })
export class UserGroupService extends GroupService {

    public baseUrl = '/xltp/ats/product/user/groups';
    public baseUrl1 = '/xltp/ats/product/user/group';

    constructor(public http: HttpClient) {
        super(http);
    }

    qryRelation(group_id: number, qry: any): Observable<Array<any>> {
        return this.http.get(`${this.baseUrl1}/${group_id}/relation`, { params: qry })
            .pipe(map<any, Array<any>>(items => items || []));
    }

    updateGroup(group: Group): Observable<any> {
        return this.http.post(`${this.baseUrl1}/${group.group_id}/update`, group);
    }

    setRelation(items: Array<{ group_id: number; user_id: number; }>): Observable<any> {
        return this.http.post(`${this.baseUrl1}/relation/add`, items);
    }

    updateRelation(params: Array<{ group_id: number; user_id: number; }>): Observable<any> {
        return this.http.post(`${this.baseUrl1}/relation/update`, params);
    }

    delRelation(items: Array<{ group_id: number; user_id: number; }>): Observable<any> {
        return this.http.post(`${this.baseUrl1}/relation/delete`, items);
    }
}
