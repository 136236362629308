import { Component, forwardRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GlobalHolder } from 'app/global.holder';
import { DrawerService, isPresent } from 'share';
import { AiComponent } from '../metadata/decorators';
import { Column } from './';
import { ColumnService } from './column.service';
import { ColumnConfigComponent } from './config/column.config.component';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'ai-column',
    templateUrl: 'column.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ColumnComponent),
        multi: true
    }],
    styleUrls: ['column.scss']
})
@AiComponent()
export class ColumnComponent implements OnInit, ControlValueAccessor, OnDestroy {

    @Input() name: string;

    items: Array<Column> = [];

    private onChange: (_: any) => void;

    constructor(private drawer: DrawerService, private global: GlobalHolder, private columnService: ColumnService) {
    }

    ngOnInit() {
        let product$ = this.global.product
            .pipe(filter(product => isPresent(product?.product_id)))
            .subscribe(() => this.initColumns());
        this.ngAdd$(product$);
    }


    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
    }

    private initColumns(): void {
        this.columnService.qry(this.name, this.global.user_id, this.global.product_id).subscribe(items => {
            this.items = items?.sort((a, b) => a.order_no - b.order_no);
            this.items.forEach(item => item.checked = item.is_select === 1);
            this.change();
        });
    }

    private change(): void {
        let items = this.items.filter(item => item.checked).sort((a, b) => a.order_no - b.order_no);
        this.onChange(items);
    }

    @HostListener('click')
    config(): void {
        let drawerRef = this.drawer.open(ColumnConfigComponent);
        drawerRef.inputs.items = this.items.map(item => Object.assign({}, item));
        drawerRef.inputs.name = this.name;
        drawerRef.result.subscribe(items => {
            this.items = items;
            this.change();
        });
    }

    ngOnDestroy(): void {
    }

}
