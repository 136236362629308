export enum RuleType {
    PhoneNumber = 'PhoneNumber',
    ListFetch = 'ListFetch',
    IDCard = 'IDCard',
    FixedValue = 'FixedValue',
    TABLE_RULE = 'TABLE_RULE',
    CnName = 'CnName',
    AutoIncr = 'AutoIncr',
    Number = 'Number',
    Char = 'Char',
    Script = 'Script'
}

export enum ToolType {
    USE_DEF = 'USE_DEF',
    SQL_Q = 'SQL_Q',
    SQL_UD = 'SQL_UD',
    PROCEDURE = 'PROCEDURE',
    TABLE_RULE = 'TABLE_RULE',
    SCRIPT = 'SCRIPT',
    CONTEXT_ASSIGN = 'CONTEXT_ASSIGN',
    HTTP_INTF = 'HTTP_INTF',
    REDIS = 'REDIS'
}

export const RULE_TYPES = [RuleType.PhoneNumber, RuleType.ListFetch, RuleType.IDCard, RuleType.FixedValue, RuleType.CnName, RuleType.AutoIncr, RuleType.Number, RuleType.Char, RuleType.Script];
export const RULE_NAMES = ['手机号', '列表取值', '身份证', '固定值', '中文姓名', '自增主键', '数字', '字符', '脚本'];
export const RULE_RIGHTS = ['', '', '', '', '', '', '', '', 'add-data-script-rule'];

export const TOOL_TYPE_KEYS = [ToolType.USE_DEF, ToolType.SQL_Q, ToolType.SQL_UD, ToolType.PROCEDURE, ToolType.TABLE_RULE, ToolType.SCRIPT, ToolType.CONTEXT_ASSIGN, ToolType.HTTP_INTF, ToolType.REDIS];
export const TOOL_TYPE_NAMES = ['自定义', 'SQL查询', 'SQL更新', '存储过程', '表规则', '脚本', '上下文赋值', 'HTTP接口', 'Redis'];

export const DATA_BATCH_STATUS_KEYS = [1, 10, 11];
export const DATA_BATCH_STATUS_NAMES = ['执行中', '执行成功', '执行失败'];
