import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { isTouchEvent } from '../facade';

@Injectable()
export class ResizableService implements OnDestroy {
    private document: Document;
    private listeners = new Map<string, (event: MouseEvent) => void>();

    handleMouseDown$ = new Subject<MouseEvent>();
    documentMouseUp$ = new Subject<MouseEvent>();
    documentMouseMove$ = new Subject<MouseEvent>();
    mouseEntered$ = new Subject<boolean>();

    constructor(private ngZone: NgZone, @Inject(DOCUMENT) document: Document) {
        this.document = document;
    }

    startResizing(event: MouseEvent): void {
        const _isTouchEvent = isTouchEvent(event);
        this.clearListeners();
        const moveEvent = _isTouchEvent ? 'touchmove' : 'mousemove';
        const upEvent = _isTouchEvent ? 'touchend' : 'mouseup';
        const moveEventHandler = (e: MouseEvent) => this.documentMouseMove$.next(e);
        const upEventHandler = (e: MouseEvent) => {
            this.documentMouseUp$.next(e);
            this.clearListeners();
        };

        this.listeners.set(moveEvent, moveEventHandler);
        this.listeners.set(upEvent, upEventHandler);

        this.ngZone.runOutsideAngular(() => this.listeners.forEach((handler, name) => this.document.addEventListener(name, handler as EventListener)));
    
    }

    private clearListeners(): void {
        this.listeners.forEach((handler, name) => this.document.removeEventListener(name, handler as EventListener));
        this.listeners.clear();
    }

    ngOnDestroy(): void {
        this.handleMouseDown$.complete();
        this.documentMouseUp$.complete();
        this.documentMouseMove$.complete();
        this.mouseEntered$.complete();
        this.clearListeners();
    }
}
