import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { isPresent } from '../facade';
import { Checkable } from './batch.select.model';
import { BatchSelectService } from './batch.select.service';

@Component({
    selector: 'batch-select-item', //tslint:disable-line
    templateUrl: 'batch.select.item.component.html'
})

export class BatchSelectItemComponent implements OnInit, OnDestroy {

    private _disabled: boolean;
    private _item: Checkable;

    constructor(private selectService: BatchSelectService<any>) {
    }

    ngOnInit() {
    }

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(value: boolean) {
        this._disabled = value;
        this.applyDisabled();
    }

    get item(): Checkable {
        return this._item;
    }

    @Input()
    set item(value: Checkable) {
        this._item = value;
        this.applyDisabled();
    }

    private applyDisabled(): void {
        if (!isPresent(this._item)) {
            return;
        }
        this._item['disabled'] = this._disabled;
    }

    @HostListener('click', ['$event'])
    click(event: Event): void {
        event.stopPropagation();
    }

    change(): void {
        this.selectService.toggle(this._item);
    }

    ngOnDestroy() {
    }
}
