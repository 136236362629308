import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { ModalService } from '../modal';
import { TooltipDirective } from '../tooltip';

@Component({
    selector: 'ai-confirm',
    templateUrl: 'confirm.component.html'
})

export class ConfirmComponent extends TooltipDirective implements OnInit, OnDestroy {

    @Input() confirmTip: string;
    @Input() beforeConfirm: () => void;

    @Input('tip') directiveNameTitle?: string | TemplateRef<void>;

    @Output() ngConfirm: EventEmitter<any> = new EventEmitter<any>(true);

    constructor(private modal: ModalService,
                elementRef: ElementRef,
                hostView: ViewContainerRef,
                resolver: ComponentFactoryResolver,
                renderer: Renderer2) {
        super(elementRef, hostView, resolver, renderer);
        this.beforeConfirm = () => undefined;
    }

    ngOnInit() {
    }

    @HostListener('click')
    click(): void {
        this.beforeConfirm();
        this.modal.confirm({ title: this.confirmTip }).result.subscribe(() => this.confirm());
    }

    confirm(): void {
        this.ngConfirm.emit();
    }

    ngOnDestroy() {
    }
}
