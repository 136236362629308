import { ClassProvider } from '@angular/core';
import { PageAddSubmit } from 'app/ui/page/submit/add.submit';
import { PageModSubmit } from 'app/ui/page/submit/mod.submit';
import { PageValidateSubmit } from 'app/ui/page/submit/validate.submit';
import { PageTransformSubmit } from 'app/ui/page/submit/transform.submit';
import { GlobalProductSubmit, SUBMIT_PROCESSOR } from 'core';

export const PAGE_SUBMIT_PROCESSORS: ClassProvider[] = [
    { provide: SUBMIT_PROCESSOR, useClass: PageValidateSubmit, multi: true },
    { provide: SUBMIT_PROCESSOR, useClass: GlobalProductSubmit, multi: true },
    { provide: SUBMIT_PROCESSOR, useClass: PageTransformSubmit, multi: true },
    { provide: SUBMIT_PROCESSOR, useClass: PageModSubmit, multi: true },
    { provide: SUBMIT_PROCESSOR, useClass: PageAddSubmit, multi: true }
];
