import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    forwardRef,
    Input,
    OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'edit-switch', // tslint:disable-line
    templateUrl: 'edit.switch.component.html',
    styleUrls: ['edit.switch.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => EditSwitchComponent),
        multi: true
    }]
})

export class EditSwitchComponent implements OnInit, DoCheck, ControlValueAccessor {

    @Input() editable: boolean;
    @Input() text: string;

    @Input() ngModel: number;

    mode: number;

    private onChange: (_: any) => void;

    constructor(private cdRef: ChangeDetectorRef) {
        this.editable = true;
    }

    ngOnInit() {
    }

    ngDoCheck(): void {
        if (this.mode !== this.ngModel) {
            this.mode = this.ngModel;
            this.cdRef.markForCheck();
        }
    }

    change(mode: number): void {
        if (this.mode === mode) {
            return;
        }
        this.onChange(mode);
        this.mode = mode;
        this.cdRef.markForCheck();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.mode = obj;
        this.cdRef.markForCheck();
    }

}
