import { Observable, of } from 'rxjs';
import { FilterInitializer } from '../../initializer';
import { CacheTimeStrategy } from '../constants';
import { ComponentContext } from '../context';

export class BeforeCacheInitializer implements FilterInitializer<ComponentContext> {

    init(context: ComponentContext): Observable<any> {
        context.ngInit.call(context.instance);
        return of(context);
    }

    support(context: ComponentContext): boolean {
        let options = context.options;
        return options.cacheTime === CacheTimeStrategy.AfterNgInit || options.caches.length === 0;
    }

}
