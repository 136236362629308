import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export const SUBMIT_PROCESSOR = new InjectionToken<Array<SubmitProcessor>>('Submit Processor');

export interface SubmitProcessor {
    postProcess(item: any): Observable<any>;
}

export const submitTransform = (previousValue, currentValue) => previousValue.pipe(switchMap(pre => currentValue.postProcess(pre)));