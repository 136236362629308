import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { isPresent } from 'share';
import { SysParamService, SysParamType } from 'core';
import { GlobalHolder } from 'app/global.holder';
import { GuardInitializer } from 'app/guard/guard.initializer';


@Injectable({ providedIn: 'root' })
export class LoginVerifyGuardService implements CanActivate, GuardInitializer {

    constructor(private router: Router,
                private global: GlobalHolder,
                private paramService: SysParamService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.init();
    }

    init(): Observable<boolean> {
        if (isPresent(this.global.loginVerify.getValue())) {
            return of(true);
        }
        return this.paramService.qry(SysParamType.LOGIN_VERIFY).pipe(
            tap(items => this.global.updateLoginVerify(items?.[0]?.param_value)),
            mapTo(true)
        );
    }

}
