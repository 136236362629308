import { UrlHandlingStrategy, UrlTree } from '@angular/router';

export class CustomUrlHandlingStrategy implements UrlHandlingStrategy {

    extract(url: UrlTree): UrlTree {
        url.queryParams.time = new Date().getTime();
        return url;
    }

    merge(newUrlPart: UrlTree, rawUrl: UrlTree): UrlTree {
        return newUrlPart;
    }

    shouldProcessUrl(url: UrlTree): boolean {
        return true;
    }

}
