import { Component, Input, OnInit } from '@angular/core';
import { DrawerService } from 'share';
import { ProductUser } from 'app/product/product.model';
import { ProductUserRoleComponent } from './product.user.role.component';


@Component({
    selector: 'ai-product-user-role-btn',
    templateUrl: 'product.user.role.btn.component.html'
})
export class ProductUserRoleBtnComponent implements OnInit {

    @Input() item: ProductUser;

    constructor(public drawer: DrawerService) {
    }

    ngOnInit() {
    }

    configRole(editable: boolean = true) {
        let drawerRef = this.drawer.open(ProductUserRoleComponent);
        drawerRef.inputs.item = this.item;
        drawerRef.inputs.editable = editable;
    }
}
