export * from './value.mode';


export interface Renderable {
    render(): void;
}

export interface Selectable {
    selected?: boolean;
}

export interface EditableWrapper {
    editable?: boolean;
}
