import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UIPage } from 'app/ui/page/ui.page.model';
import { INITIALIZER, Initializer } from 'core';
import { merge, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, switchMapTo, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class PageInitializerService implements OnDestroy {

    private _destroyed$ = new Subject<void>();

    constructor(private router: Router, private route: ActivatedRoute, @Inject(INITIALIZER) private initializers: Initializer<any>[]) {
    }

    init(item: UIPage): Observable<UIPage> {
        let context = item || {};
        this.initContext(context);

        let observable = this.initializers.reduce<Observable<any>>((previousValue, currentValue) => previousValue.pipe(switchMap(ret => currentValue.init(ret))), of(context));
        return merge(
            of({}),
            this.router.events.pipe(filter(event => event instanceof NavigationEnd)).pipe(tap(() => {
                context.script = '';
                context.component_params = [];
                this.initContext(context);
            }))
        ).pipe(takeUntil(this._destroyed$), switchMapTo(observable));
    }

    private initContext(context: UIPage): void {
        context.component_id = (+this.route.snapshot.params.id || undefined) ?? context.component_id;
        context.fun_id = (+this.route.snapshot.queryParams.group_id || undefined) ?? context.fun_id;
    }

    ngOnDestroy(): void {
        this._destroyed$.next(undefined);
    }
}
