import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ModalService } from 'share';

@Injectable({ providedIn: 'root' })
export class ConfirmInterceptor implements HttpInterceptor {

    constructor(private modal: ModalService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.headers.has(Headers.Confirm)) {
            return next.handle(req);
        }
        return this.modal.confirm({ title: req.headers.get(Headers.Confirm) || '确定删除？' }).result.pipe(
            switchMap(() => next.handle(req.clone({ headers: req.headers.delete(Headers.Confirm) })))
        );
    }
}
