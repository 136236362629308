import { NgModule } from '@angular/core';
import { CoreModule } from 'core';
import { ShareModule } from 'share';

import { PageTreeComponent } from './page.tree.component';
import { PageTreeSelectComponent } from './page.tree.select.component';


@NgModule({
    imports: [
        CoreModule,
        ShareModule
    ],
    exports: [
        PageTreeComponent,
        PageTreeSelectComponent
    ],
    declarations: [
        PageTreeComponent,
        PageTreeSelectComponent
    ]
})
export class PageTreeModule {

}
