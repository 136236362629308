import { Observable, Subject } from 'rxjs';
import { isPresent } from '../facade';

export class TimeHolder {

    selected12Hours: string | undefined = undefined;

    private _value: Date | undefined;
    private _use12Hours: boolean = false;
    private _defaultOpenValue: Date;
    private _changes = new Subject<Date>();

    constructor() {
    }

    get value(): Date {
        return this._value || this._defaultOpenValue;
    }

    get hours(): number | undefined {
        return this.value?.getHours();
    }

    get minutes(): number | undefined {
        return this.value?.getMinutes();
    }

    get seconds(): number | undefined {
        return this.value?.getSeconds();
    }

    get changes(): Observable<Date> {
        return this._changes.asObservable();
    }

    get viewHours(): number | undefined {
        return this._use12Hours && isPresent(this.hours) ? this.calculateViewHour(this?.hours) : this.hours;
    }

    setHours(value: number, disabled: boolean): this {
        if (value !== this.hours && !disabled) {
            this.initValue();
            if (this._use12Hours) {
                if (this.selected12Hours === 'PM' && value !== 12) {
                    this.value.setHours(value + 12);
                } else if (this.selected12Hours === 'AM' && value === 12) {
                    this.value.setHours(0);
                } else {
                    this.value.setHours(value);
                }
            } else {
                this.value.setHours(value);
            }
            this.update();
        }
        return this;
    }

    setMinutes(value: number, disabled: boolean): this {
        if (value !== this.minutes && !disabled) {
            this.initValue();
            this.value.setMinutes(value);
            this.update();
        }
        return this;
    }

    setSeconds(value: number, disabled: boolean): this {
        if (value !== this.seconds && !disabled) {
            this.initValue();
            this.value.setSeconds(value);
            this.update();
        }
        return this;
    }

    setUse12Hours(value: boolean): this {
        this._use12Hours = value;
        return this;
    }

    setSelected12Hours(value: string | undefined): void {
        if (value?.toUpperCase() !== this.selected12Hours) {
            this.selected12Hours = value?.toUpperCase();
            this.update();
        }
    }

    setDefaultOpenValue(value: Date): this {
        this._defaultOpenValue = value;
        return this;
    }

    setValue(value: Date, use12Hours?: boolean): this {
        if (isPresent(use12Hours)) {
            this._use12Hours = use12Hours;
        }
        if (value === this.value) {
            return this;
        }
        this._value = value;
        if (isPresent(this.value)) {
            if (this._use12Hours && isPresent(this.hours)) {
                this.selected12Hours = this.hours >= 12 ? 'PM' : 'AM';
            }
        } else {
            this._clear();
        }
        return this;
    }

    initValue(): void {
        if (isPresent(this.value)) {
            return;
        }
        this.setValue(new Date(), this._use12Hours);
    }

    clear(): void {
        this._clear();
        this.update();
    }

    private _clear(): void {
        this._value = undefined;
        this.selected12Hours = undefined;
    }

    private update(): void {
        if (!isPresent(this.hours) && !isPresent(this.minutes) && !isPresent(this.seconds)) {
            this._value = undefined;
            this._changes.next(this.value);
            return;
        }

        if (isPresent(this.hours)) {
            this.value?.setHours(this.hours);
        }

        if (isPresent(this.minutes)) {
            this.value?.setMinutes(this.minutes);
        }

        if (isPresent(this.seconds)) {
            this.value?.setSeconds(this.seconds);
        }

        if (this._use12Hours) {
            if (this.selected12Hours === 'PM' && this.hours < 12) {
                this.value?.setHours(this.hours + 12);
            }
            if (this.selected12Hours === 'AM' && this.hours >= 12) {
                this.value?.setHours(this.hours - 12);
            }
        }
        this._changes.next(this.value);
    }

    private calculateViewHour(value: number): number {
        const selected12Hours = this.selected12Hours;
        if (selected12Hours === 'PM' && value > 12) {
            return value - 12;
        }
        if (selected12Hours === 'AM' && value === 0) {
            return 12;
        }
        return value;
    }
}
