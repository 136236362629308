import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EmptyComponent } from './empty.component';
import { EmptyContainerComponent } from './empty.container.component';
import { EmptyTableComponent } from './empty.table.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        EmptyComponent,
        EmptyContainerComponent,
        EmptyTableComponent
    ],
    declarations: [
        EmptyComponent,
        EmptyContainerComponent,
        EmptyTableComponent
    ]
})
export class EmptyModule {
}
