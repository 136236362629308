import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppConfig } from 'app/app.model';
import { GlobalHolder } from 'app/global.holder';
import { SysParamService, SysParamType } from 'core';
import { DrawerRef, isPresent } from 'share';

@Component({
    selector: 'ai-app-config',
    templateUrl: 'app.config.component.html',
    styleUrls: ['app.config.scss']
})

export class AppConfigComponent implements OnInit, OnDestroy {

    item: AppConfig = {};

    private params: Array<any>;

    constructor(public drawerRef: DrawerRef, private global: GlobalHolder, private paramService: SysParamService) {
    }

    ngOnInit() {
        this.paramService.qry(SysParamType.APP_CONFIG).subscribe(items => {
            this.params = items;
            items.forEach(item => this.item[item.param_key] = item.param_value);
            this.item.login_for_url = this.item.login_for_url ?? 'pages/workbench';
            this.item.product_for_url = this.item.product_for_url ?? 'pages/product/dashboard';
        });
    }

    changeFile(event: Event, key: string): void {
        let target = event.target as HTMLInputElement;
        let reader = new FileReader();
        reader.onload = ev => this.item[key] = (ev.target as any).result;
        reader.readAsDataURL(target.files[0]);
    }

    deleteFile(key: string): void {
        delete this.item[key];
    }

    submit(): void {
        Object.keys(this.item).forEach(key => {
            let param = this.params.find(p => p.param_key === key);
            if (isPresent(param)) {
                param.param_value = this.item[key];
                param.operate_type = 'U';
                return;
            }
            this.params.push({
                param_type: SysParamType.APP_CONFIG,
                param_key: key,
                param_value: this.item[key],
                status: 0,
                operate_type: 'C'
            });
        });
        this.params.filter(param => !this.item[param.param_key]).forEach(param => param.operate_type = 'D');
        this.global.updateAppConfig(this.item);
        this.paramService.update(this.params).subscribe(() => this.drawerRef.close());
    }

    ngOnDestroy() {
    }
}
