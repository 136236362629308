import { Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { DragTableDirective } from './drag.table';

@Directive({
    selector: 'tr[draggable][item]' // tslint:disable-line
})
export class DragRowDirective implements OnInit {

    @Input() item: any;

    private _draggable: boolean;
    private _dragging: boolean;

    constructor(private dragTable: DragTableDirective) {
    }

    ngOnInit(): void {
        this.dragTable.addRow(this);
    }

    @Input()
    set draggable(value: boolean) {
        this._draggable = value;
    }

    @HostBinding('attr.draggable')
    get draggableAttr() {
        return this._draggable;
    }

    @HostBinding('class.drag-enter')
    get dragging() {
        return this._dragging;
    }

    @HostListener('dragstart', ['$event'])
    start(event: DragEvent): void {
        event.dataTransfer.effectAllowed = 'all';
        this.dragTable.start(this.item);
    }

    @HostListener('dragenter', ['$event'])
    enter(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.dragTable.enter();
        this._dragging = true;
    }

    @HostListener('dragover', ['$event'])
    allow(event: DragEvent): void {
        event.dataTransfer.dropEffect = 'move';
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('dragleave')
    leave(): void {
        this.clearDragging();
    }

    @HostListener('drop')
    drop(): void {
        this.clearDragging();
        this.dragTable.drop(this.item);
    }

    clearDragging(): void {
        this._dragging = false;
    }

}