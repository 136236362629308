import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Headers } from 'app/app.constants';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BasicResolve implements Resolve<any> {

    constructor(private http: HttpClient) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return of(true);
        let headers = new HttpHeaders().set(Headers.NEED_AUTHORIZATION, 'false');
        return this.http.get('/xltp/sys/user/login/auth/client', {
            responseType: 'text',
            headers
        }).pipe(tap(client => {
            // TODO base 认证
            // TokenConstant.CLIENT_SECRET = client;
        }));
    }
}
