import { Injectable, Injector } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { isPresent } from '../facade';
import { ERROR_FORMATTERS, ErrorFormatter } from './errors/ErrorFormatter';

@Injectable()
export class InputService {

    private readonly formatters: Array<ErrorFormatter> = [];

    constructor(injector: Injector) {
        this.formatters = injector.get(ERROR_FORMATTERS);
    }

    qryError(errors: ValidationErrors): string {
        let key = Object.keys(errors)[0];
        let value = errors[key];
        let formatters = this.formatters.filter(item => item.support(key));
        let result: string;
        for (let item of formatters) {
            result = item.format(key, value);
            if (isPresent(result)) {
                return result;
            }
        }
        return value;
    }

}
