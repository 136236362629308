import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalHolder } from 'app/global.holder';
import { GuardInitializer } from 'app/guard/guard.initializer';
import { UserService } from 'app/system/user/user.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class AuthGuardInitializer implements GuardInitializer {

    constructor(private router: Router,
                private global: GlobalHolder,
                private storage: LocalStorageService,
                private userService: UserService) {
    }

    init(): Observable<boolean> {
        return this.userService.qryCurrent().pipe(switchMap(item => {
            if (!isPresent(item) && self === top) {
                this.router.navigate(['/login'])
            }
            this.global.updateUser(item);
            return of(isPresent(item) && typeof item !== 'string');
        }));
    }
}
