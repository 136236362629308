import { Menu } from 'app/system/menu/menu';

export interface Role {
    role_id?: number;
    role_code?: string;
    role_name?: string;
    role_desc?: string;
    status?: number;
    selected?: boolean;
    default_select?: number;
    menus: Array<Menu>;

    is_change: boolean;
    product_id?: number;
}

export enum RoleCodes {
    PRODUCT_ADMIN = 'product_admin',
    PRODUCT_LEADER = 'product_leader',
    PRODUCT_AUDITOR = 'product_reviewed',
    PRODUCT_TESTER = 'product_tester',
    PRODUCT_AUTOR = 'product_autor',
}
