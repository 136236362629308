import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AbstractPanelHeader } from '../abstract-header';
import { DateHelperService } from '../datepicker-helper';
import { DatepickerI18n } from '../datepicker-i18n';
import { PanelSelector } from '../interface';

@Component({
    selector: 'month-header',// tslint:disable-line
    exportAs: 'monthHeader',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../abstract-header.html'
})
export class MonthHeaderComponent extends AbstractPanelHeader {

    constructor(private i18n: DatepickerI18n, private dateHelper: DateHelperService) {
        super();
    }

    getSelectors(): PanelSelector[] {
        return [{
            onClick: () => this.changeMode('year'),
            label: this.dateHelper.format(this.value.nativeDate, this.i18n.getYearFormat())
        }];
    }
}
