import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Tenant } from 'app/system/tenant/tenant.model';
import { TenantService } from 'app/system/tenant/tenant.service';
import { User } from 'app/system/user/user.model';
import { UserService } from 'app/system/user/user.service';
import { fromEvent, Observable } from 'rxjs';
import { DrawerRef, isPresent, validateError } from 'share';

@Component({
    selector: 'ai-tenant-edit',
    templateUrl: 'tenant.edit.component.html',
    styleUrls: ['tenant.edit.scss']
})
export class TenantEditComponent implements OnInit, OnDestroy {

    @Input() item: Tenant;
    @Input() fileTypes: string[] = ['image/png', 'image/jpg', 'image/jpeg'];

    users: Array<User> = [];

    constructor(public drawerRef: DrawerRef,
                private tenantService: TenantService,
                private userService: UserService) {
    }

    ngOnInit(): void {
        this.userService.qryAll().subscribe(items => {
            this.item.managers = this.item.tenant_managers?.map(item => item.user_id) ?? [];
            this.users = items;
        });
    }

    changeIcon({ target }): void {
        const file = target?.files[0];
        if (!this.fileTypes.includes(file.type)) {
            validateError('图片格式错误，请选择格式为jpg、png的图片');
        }
        if (file.size / 1024 > 300) {
            validateError('图片太大，请选择小于300K的图片');
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        fromEvent<ProgressEvent<FileReader>>(reader, 'load')
            .subscribe((e) => {
                this.item.icon = e.target.result.toString();
            })
        target.value = '';
    }

    submit(): void {
        if (!this.item.icon) {
            validateError('请上传租户图标');
        }

        let managers = (this.item.tenant_managers ?? []).map(item => ({ ...item }));

        this.item.managers.filter(id => !managers.some(v => v.user_id === id)).forEach(id => managers.push({
            user_id: id,
            tenant_id: this.item.tenant_id,
            operate_type: 'C'
        }));

        managers.filter(item => !this.item.managers.some(id => item.user_id === id)).forEach(item => item.operate_type = 'D');

        let tenant = Object.assign({}, this.item, { managers: undefined });
        tenant.tenant_managers = managers.filter(v => v.operate_type);

        let observable: Observable<any>;

        if (!isPresent(this.item.tenant_id)) {
            observable = this.tenantService.add(tenant);
        } else {
            observable = this.tenantService.mod(tenant);
        }
        observable.subscribe(() => {
            toastr.success(!isPresent(this.item.tenant_id) ? '添加成功' : '编辑成功');
            this.drawerRef.close(this.item);
        });
    }

    ngOnDestroy(): void {
    }
}
