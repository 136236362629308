import BigNumber from 'bignumber.js';
import { hasOwnProperty, isBigNumber } from 'share';
import { Node, NodeType } from './node';
import { resolveJsonType } from './node.resolver';

export interface JsonVisitor {

    visitUndefined(node: Node);

    visitNull(node: Node);

    visitPrimitive(value: any, node: Node);

    visitArray(value: any[], node: Node);

    visitObject(value: any, node: Node);
}


export interface JsonValueVisitor {

    visitUndefined(node?: Node): any;

    visitNull(node?: Node): any;

    visitString(value: any, node?: Node): string;

    visitBoolean(value: any, node?: Node): boolean;

    visitNumber(value: any, node?: Node): number;

    visitObject(nodes: Node[]): any;

    visitArray(nodes: Node[]): any[];

}

export function visitJson(visitor: JsonVisitor, value: any, node: Node) {
    let type = resolveJsonType(value);
    node.type = type;
    if (type === NodeType.Undefined) {
        visitor.visitUndefined(node);
    } else if (type === NodeType.Null) {
        visitor.visitNull(node);
    } else if (type < NodeType.Primitive) {
        visitor.visitPrimitive(value, node);
    } else if (NodeType.Array & type) {
        visitor.visitArray(value, node);
    } else if (NodeType.Object === type) {
        visitor.visitObject(value, node);
    }
}

export class DefaultJsonVisitor implements JsonVisitor {

    visitArray(value: any[], node: Node) {
        node.children.filter((item, index) => index >= value.length).forEach(child => node.removeChild(child));
        value.forEach((item, index) => {
            let child = node.children[index] || node.addChild(new Node(''));
            child.name = '';
            visitJson(this, item, child);
        });
    }

    visitObject(value: any, node: Node) {
        node.children.filter(child => !hasOwnProperty(value, child.name)).forEach(child => node.removeChild(child));
        for (let key of Object.keys(value)) {
            let child = node.children.find(item => item.name === key) || node.addChild(new Node(key));
            visitJson(this, value[key], child);
        }
    }

    visitPrimitive(value: any, node: Node) {
        node.value = isBigNumber(value) ? value.toFixed() : value;
        node.clearChildren();
    }

    visitNull(node: Node) {
        node.value = null;
        node.clearChildren();
    }

    visitUndefined(node: Node) {
        node.value = undefined;
        node.clearChildren();
    }

}

export function visitValue(visitor: JsonValueVisitor, node: Node): any {
    let type = node.type;
    let value = node.value;

    if (type === NodeType.Undefined) {
        return visitor.visitUndefined(node);
    }

    if (type === NodeType.Null) {
        return visitor.visitNull(node);
    }

    if (type === NodeType.String) {
        return visitor.visitString(value, node);
    }

    if (type === NodeType.Boolean) {
        return visitor.visitBoolean(value, node);
    }

    if (type === NodeType.Number) {
        return visitor.visitNumber(value, node);
    }

    let children = node.children;

    if (type & NodeType.Array) {
        return visitor.visitArray(children);
    }

    if (NodeType.Object === type) {
        return visitor.visitObject(children);
    }
}

export class DefaultJsonValueVisitor implements JsonValueVisitor {

    visitArray(nodes: Node[]): any[] {
        return nodes.map(node => visitValue(this, node));
    }

    visitBoolean(value: any, node?: Node): boolean {
        return value === 'false' ? false : !!value;
    }

    visitNumber(value: any, node?: Node): number {
        if (isBigNumber(value)) {
            return value;
        }
        if (typeof value === 'string' && value.length > 16) {
            return value.includes('.') ? new BigNumber(value) as any : BigInt(new BigNumber(value).toFixed());
        }
        value = parseFloat(value);
        return value === value ? value : 0;
    }

    visitObject(nodes: Node[]): any {
        let obj = {};
        nodes.forEach(node => obj[node.name] = visitValue(this, node));
        return obj;
    }

    visitString(value: any, node?: Node): string {
        return value || '';
    }

    visitNull(node?: Node): any {
        return null;
    }

    visitUndefined(node?: Node): any {
        return undefined;
    }

}

export class SkipNullJsonValueVisitor extends DefaultJsonValueVisitor {

    visitArray(nodes: Node[]): any[] {
        return nodes.map(node => visitValue(this, node)).filter(node => !this.isEmptyObject(node));
    }

    visitNumber(value: any, node?: Node): number {
        console.log(value);
        if (value === undefined || value === null) {
            return null;
        }
        return super.visitNumber(value, node);
    }

    visitString(value: any, node?: Node): string {
        if (value === undefined || value === null) {
            return '';
        }
        return super.visitString(value, node);
    }

    isEmptyObject(obj: any): boolean {
        return obj === null || obj === undefined || obj === '' || JSON.stringify(obj) === '{}';
    }

}
