import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { GlobalHolder } from 'app/global.holder';
import { LoginService } from 'app/login/login.service';
import { ProductEditComponent } from 'app/product/edit/product.edit.component';
import { Product, ProductUser } from 'app/product/product.model';
import { ProductUserConfigComponent } from 'app/product/user/config/product.user.config.component';
import { Menu } from 'app/system/menu/menu';
import { TenantEditComponent } from 'app/system/tenant/edit/tenant.edit.component';
import { Tenant } from 'app/system/tenant/tenant.model';
import { TenantService } from 'app/system/tenant/tenant.service';
import { TenantUserComponent } from 'app/system/tenant/user/tenant.user.component';
import { isAdmin, User } from 'app/system/user/user.model';
import { UserService } from 'app/system/user/user.service';
import { AiComponent, Renderable } from 'core';
import { LocalStorageService } from 'ngx-webstorage';
import { fromEvent, zip } from 'rxjs';
import { filter, switchMap, switchMapTo } from 'rxjs/operators';
import { delay, DrawerService, isPresent } from 'share';

export type PageLayout = 'horizontal' | 'vertical';

@Component({
    selector: 'ai-useravatar',
    templateUrl: 'header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['header.scss']
})
@AiComponent()
export class UseravatarComponent implements OnInit, OnDestroy, Renderable {

    @Input() collapse: boolean;
    @Input() layout: PageLayout;
    // titles:string;
    pageTitle: string;
    menus: Array<Menu> = [];
    user: User;
    product: Product;
    configurable: boolean;
    tenant: Tenant;
    tenants: Array<Tenant> = [];

    private productUser: ProductUser;

    constructor(private drawer: DrawerService,
                private router: Router,
                private route: ActivatedRoute,
                private renderer: Renderer2,
                private cdRef: ChangeDetectorRef,
                private global: GlobalHolder,
                private storage: LocalStorageService,
                private userService: UserService,
                private loginService: LoginService,
                private tenantService: TenantService) {
        this.configurable = false;
        this.collapse = true;
        // this.setLayout(storage.retrieve('layout') || 'horizontal');
        this.setLayout('horizontal');
        // let local = localStorage.getItem('ngx-webstorage|product')
        // if(local){
        //     let ks = JSON.parse(local)
        //     this.titles =ks.product_name
        // }

    }

    ngOnInit(): void {
        this.user = this.global.user.getValue();

        let product$ = this.global.product.pipe(filter(v => isPresent(v))).subscribe(product => {
            this.product = product;
            this.render();
        });
        this.ngAdd$(product$);

        let tenant$ = this.global.tenant.subscribe(item => {
            this.tenant = item;
            this.render();
        });
        this.ngAdd$(tenant$);


        let tenants$ = this.global.tenants.subscribe(items => {
            this.tenants = items;
            this.render();
        });
        this.ngAdd$(tenants$);

        let router$ = this.router.events.pipe(
            filter(ev => ev instanceof NavigationEnd)
        ).subscribe(() => this.getPageTitle(this.route.snapshot))
        this.ngAdd$(router$);

        this.getPageTitle(this.route.snapshot)

     
        
    }

    render(): void {
        this.cdRef.markForCheck();
    }

    toggle(): void {
        this.collapse = !this.collapse;
        this.global.updateCollapse(this.collapse);
    }

    getPageTitle(snapshot: ActivatedRouteSnapshot) {
        if (isPresent(snapshot.firstChild)) {
            this.getPageTitle(snapshot.firstChild);
        } else {
            this.pageTitle = snapshot.data.breadcrumb;
            this.render()
        }
    }

    hasProductAuth(): boolean {
        return isAdmin(this.user) || (isPresent(this.productUser) && this.productUser.isAdmin);
    }

    isAdmin(): boolean {
        return isAdmin(this.user);
    }

    isTenantAdmin(item: Tenant): boolean {
        return item.tenant_managers?.some(v => v.user_id === this.user.user_id);
    }

    modProduct(): void {
        const drawerRef = this.drawer.open(ProductEditComponent);
        drawerRef.inputs.product = Object.assign({}, this.product);
        drawerRef.result.subscribe((result) => this.global.updateProduct(result));
    }

    configParam(): void {
        this.router.navigate([`pages/product/${this.product.product_id}/param`]);
    }

    configUser() {
        const drawerRef = this.drawer.open(ProductUserConfigComponent);
        drawerRef.inputs.product = this.product;
    }

    logout() {
        this.loginService.logout().subscribe(() => this.ngOnDestroy());
    }

    async toggleConfigurable() {
        this.configurable = !this.configurable;
        if (!this.configurable) {
            return;
        }
        await delay(200);
        let ob$ = fromEvent(document, 'click').subscribe(() => {
            this.configurable = false;
            this.cdRef.markForCheck();
            ob$.unsubscribe();
        });
    }

    changeLayout(layout: PageLayout): void {
        if (this.layout === layout) {
            return;
        }
        this.setLayout(layout);
        this.toggleConfigurable();
    }

    switchTenant(item: Tenant): void {
        if (this.tenant?.tenant_id === item.tenant_id) {
            return;
        }
        this.tenantService.changeTenant(item.tenant_id).pipe(switchMapTo(this.tenantService.qryById(item.tenant_id))).subscribe(tenant => {
            this.global.updateTenant(tenant);
            this.global.updateMenus(this.global.topMenus.getValue());
            this.global.updateProduct(null);
            this.router.navigate(['pages/product']);
        });
    }

    configTenant(item: Tenant): void {
        this.tenantService.qryById(item.tenant_id).pipe(
            switchMap(tenant => {
                const drawerRef = this.drawer.open(TenantEditComponent);
                drawerRef.inputs.item = tenant;
                return drawerRef.result;
            }),
            switchMap(() => zip(this.tenantService.qryById(item.tenant_id), this.tenantService.qryByUser(this.user.user_id)))
        ).subscribe(([tenant, items]) => {
            if (this.tenant?.tenant_id === item.tenant_id) {
                this.global.updateTenant(tenant);
            }
            this.global.updateTenants(items);
        });
    }

    configTenantUser(item: Tenant): void {
        let drawerRef = this.drawer.open(TenantUserComponent);
        drawerRef.inputs.item = item;
    }


    private setLayout(layout: PageLayout): void {
        if (typeof layout === 'number') {
            layout = 'horizontal';
        }
        this.layout = layout;
        this.storage.store('layout', layout);
        this.renderer.removeClass(document.body, 'layout-vertical');
        this.renderer.removeClass(document.body, 'layout-horizontal');
        this.renderer.addClass(document.body, `layout-${layout}`);
    }

    ngOnDestroy(): void {
    }
}
