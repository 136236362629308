import { Group } from 'app/group/group.model';
import { TreeIcon, TreeKeyPrefix } from 'core';
import { isPresent, TreeNodeOptions } from 'share';

export function buildGroupTree(groups: Array<Group>, selectable = true): Array<TreeNodeOptions> {

    let nodes: Array<TreeNodeOptions> = [];
    let groupMap = new Map<number, TreeNodeOptions>();
    groups.forEach(item => groupMap.set(item.group_id, {
        key: `${TreeKeyPrefix.GROUP}${item.group_id}`,
        title: item.group_name,
        icon: TreeIcon.GROUP,
        id: item.group_id,
        data: item,
        children: [],
        selectable
    }));
    groups.forEach(item => {
        let node = groupMap.get(item.group_id);
        if (item.parent_id === undefined) {
            nodes.push(node);
            return;
        }
        let parent = groupMap.get(item.parent_id);
        if (isPresent(parent)) {
            parent.children = parent.children ?? [];
            parent.children.push(node);
        }
    });
    return nodes;
}
