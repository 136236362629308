import { Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FunctionProp, SafeAny } from '../types';
import { DateFnsCalendar } from './calendar';
import { DateBodyRow, DateCell } from './interface';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class AbstractTable implements OnInit, OnChanges {

    headRow: DateCell[] = [];
    bodyRows: DateBodyRow[] = [];
    MAX_ROW = 6;
    MAX_COL = 7;

    @Input() value: DateFnsCalendar;
    @Input() activeDate: DateFnsCalendar = new DateFnsCalendar();
    @Input() showWeek: boolean = false;
    @Input() disabledDate: (d: Date) => boolean;
    @Input() cellRender: FunctionProp<TemplateRef<Date> | string>;
    @Input() fullCellRender: FunctionProp<TemplateRef<Date> | string>;

    @Output() readonly valueChange = new EventEmitter<DateFnsCalendar>();

    protected render(): void {
        if (this.activeDate) {
            this.headRow = this.makeHeadRow();
            this.bodyRows = this.makeBodyRows();
        }
    }

    trackByBodyRow(_index: number, item: DateBodyRow): SafeAny {
        return item;
    }

    trackByBodyColumn(_index: number, item: DateCell): SafeAny {
        return item;
    }

    abstract makeHeadRow(): DateCell[];

    abstract makeBodyRows(): DateBodyRow[];

    ngOnInit(): void {
        this.render();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeDate && !changes.activeDate.currentValue) {
            this.activeDate = new DateFnsCalendar();
        }
    }
}
