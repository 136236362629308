import { Inject, Injectable, LOCALE_ID } from '@angular/core';

export function DATEPICKER_18N_FACTORY(locale) {
    return new DatepickerI18nDefault(locale);
}

@Injectable({ providedIn: 'root', useFactory: DATEPICKER_18N_FACTORY, deps: [LOCALE_ID] })
export abstract class DatepickerI18n {

    abstract getDateLocal(): any;

    abstract getDateFormat(): string;

    abstract getYearFormat(): string;

    abstract getMonthFormat(): string;

    abstract getWeekFormat(): string;
}

@Injectable()
export class DatepickerI18nDefault extends DatepickerI18n {

    constructor(@Inject(LOCALE_ID) private _locale: string) {
        super();
    }

    getDateLocal(): any {
        return this._locale;
    }

    getDateFormat(): string {
        return 'yyyy-MM-dd';
    }

    getYearFormat(): string {
        return 'yyyy';
    }

    getMonthFormat(): string {
        return 'MMM';
    }

    getWeekFormat(): string {
        return 'EEEEE';
    }
}
