import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RightBottomButtonComponent } from './right.bottom.button.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        RightBottomButtonComponent
    ],
    declarations: [
        RightBottomButtonComponent
    ]
})
export class RightBottomButtonModule {
}
