import { DefaultJsonValueVisitor, DefaultJsonVisitor, JsonVisitor, visitJson, visitValue } from './json.visitor';
import { Node, NodeType } from './node';
import { DefaultXmlValueVisitor, DefaultXmlVisitor, visitXml, visitXmlValue, XmlVisitor } from './xml.visitor';

export interface FormVisitor {

    visitNode(name: string): Node;

    visitString(value: string, node: Node);

    visitJson(value: string, node: Node);

    visitXml(value: string, node: Node);
}

export interface FormValueVisitor {

    visitString(node: Node): string;

    visitJson(node: Node): string;

    visitXml(node: Node): string;

    visitOther(node: Node): string;
}

export class DefaultFormVisitor implements FormVisitor {

    constructor(private jsonVisitor: JsonVisitor = new DefaultJsonVisitor(), private xmlVisitor: XmlVisitor = new DefaultXmlVisitor()) {
    }

    visitJson(value: string, node: Node) {
        visitJson(this.jsonVisitor, value, node);
    }

    visitString(value: string, node: Node) {
        node.value = value;
        // node.clearChildren();
    }

    visitXml(value: string, node: Node) {
        let dom = $.parseXML(value);
        let element = dom.childNodes[0] as Element;
        let child = node.children[0] || node.addChild(new Node(element.nodeName));
        visitXml(this.xmlVisitor, dom.childNodes[0] as Element, child);
    }

    visitNode(name: string): Node {
        return new Node(name);
    }
}

export class DefaultFormValueVisitor implements FormValueVisitor {

    visitJson(node: Node): string {
        let value = visitValue(new DefaultJsonValueVisitor(), node);
        return JSON.stringify(value).replace(/[\s]*/g, '');
    }

    visitString(node: Node): string {
        return node.value;
    }

    visitXml(node: Node): string {
        let value = visitXmlValue(new DefaultXmlValueVisitor(), node.children[0]);
        return value.replace(/[\s]*/g, '');
    }

    visitOther(node: Node): string {
        return node.value;
    }
}

export function visitForm(visitor: FormVisitor, text: string, node: Node) {
    visitor.visitString(text, node);
    // let type = resolveFormType(text);
    // node.type = type;
    // if (type === NodeType.String) {
    //     visitor.visitString(text, node);
    // } else if (type === NodeType.json) {
    //     visitor.visitJson(text, node);
    // } else if (type === NodeType.xml) {
    //     visitor.visitXml(text, node);
    // }
}

export function visitFormValue(visitor: FormValueVisitor, node: Node) {
    if (node.type === NodeType.String) {
        return visitor.visitString(node);
    }
    if (node.type === NodeType.json) {
        return visitor.visitJson(node);
    }
    if (node.type === NodeType.xml) {
        return visitor.visitXml(node);
    }
    return visitor.visitOther(node);
}
