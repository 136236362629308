import { isPresent } from './lang';
import { formatXml } from './util';

export function resolveTextMode(text: string): { text: string, mode: string } {
    if (!isPresent(text)) {
        return { mode: 'text', text };
    }
    try {
        if (text.startsWith('{') || text.startsWith('[')) {
            let obj = JSON.parse(text);
            return {
                mode: 'json',
                text: JSON.stringify(obj, null, '\t')
            };
        }
        let tags = ['</html>', '</body>', '</div>', '</table>', '</ul>', '</a>', '</p>', '</span>'];
        if (text.startsWith('<') && tags.some(tag => text.includes(tag))) {
            return {
                mode: 'html',
                text: formatXml(text)
            };
        }
        if (text.startsWith('<')) {
            return {
                mode: 'xml',
                text: formatXml(text)
            };
        }
    } catch (e) {
    }
    return {
        mode: 'text',
        text
    };
}

export function formatText(text: string, mode?: string): string {
    if (!isPresent(mode)) {
        return resolveTextMode(text).text;
    }
    try {
        if (mode === 'json') {
            let obj = JSON.parse(text);
            return JSON.stringify(obj, null, '\t');
        }
        if (mode === 'xml' || mode === 'html') {
            return formatXml(text);
        }
    } catch (e) {
    }
    return text;
}
