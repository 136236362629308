import { defaultValue, isPresent } from './lang';

export interface TreeVisitor<T> {

    visitKey(): string;

    visitParentKey(): string;

    visitChildrenKey(): string;

    visitInit(item: any): T;

    visitChildren(parent: T, child: T): void;
}

export class DeFaultTreeVisitor<T> implements TreeVisitor<T> {

    visitChildren(parent: T, child: T): void {
        defaultValue(parent, this.visitChildrenKey(), []);
        parent[this.visitChildrenKey()].push(child);
    }

    visitChildrenKey(): string {
        return 'children';
    }

    visitInit(item: any): T {
        return item;
    }

    visitKey(): string {
        return 'id';
    }

    visitParentKey(): string {
        return 'parent_id';
    }

}

export function createTree<T>(items: Array<any>, visitor: TreeVisitor<T> = new DeFaultTreeVisitor()): Array<any> {
    let itemMap = new Map<any, any>();
    items.forEach(item => itemMap.set(item[visitor.visitKey()], visitor.visitInit(item)));
    let parent: any;
    let tree: Array<T> = [];
    itemMap.forEach(item => {
        if (isPresent(parent = itemMap.get(item[visitor.visitParentKey()]))) {
            visitor.visitChildren(parent, item);
        } else {
            tree.push(item);
        }
    });
    return tree;
}
