import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { GlobalHolder } from 'app/global.holder';
import { Observable, of } from 'rxjs';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class TenantGuardService implements CanActivateChild {

    constructor(private router: Router, private global: GlobalHolder) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (route.routeConfig.loadChildren || route.routeConfig.children) {
            return of(true);
        }
        if (isPresent(this.global.tenant_id)) {
            return of(true);
        }
        if (route.data?.needTenant === false) {
            return of(true);
        }
        toastr.error('请选择租户');
        this.router.navigate(['pages/sys/tenant/switch']);
        return of(false);
    }
}
