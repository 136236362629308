import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { validateError } from '../facade';
import { MsgSendService } from './msg.send.service';

@Component({
    selector: 'msg-send', // tslint:disable-line
    templateUrl: 'msg.send.component.html',
    styleUrls: ['msg.send.scss'],
    host: {
        '[attr.role]': '"button"'
    }
})

export class MsgSendComponent implements OnInit, OnDestroy {

    @Input() type: string;
    @Input() dist: string;
    @Input() distType: 'phone' | 'email';
    @Input() tip = '请输入手机号码';

    private count: number;
    private monitor$: Subscription;

    constructor(private msgSendService: MsgSendService) {
        this.count = 0;
        this.distType = 'phone';
    }

    get text(): string {
        return this.count === 0 ? '发送验证码' : `${this.count} s`;
    }

    ngOnInit() {
    }

    @HostBinding('attr.disabled')
    get disable(): boolean {
        return this.count > 0;
    }

    @HostListener('click')
    click() {
        if (this.count > 0) {
            return;
        }
        this.removeMonitor$();
        this.send();
    }

    private send(): void {
        if (!this.dist) {
            validateError(this.tip);
        }
        let observable = this.distType === 'phone'
            ? this.msgSendService.sendMsgToPhone(this.dist, this.type)
            : this.msgSendService.sendMsgToEmail(this.dist);
        this.monitor$ = observable.pipe(
            tap(() => this.count = 60),
            switchMap(() => interval(1000)),
            tap(() => this.count--),
            takeWhile(() => this.count > 0)
        ).subscribe();
    }

    private removeMonitor$(): void {
        this.monitor$?.unsubscribe();
    }

    ngOnDestroy() {
        this.removeMonitor$();
    }
}
