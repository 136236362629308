import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HighlightModule } from '../highlight';
import { OutletModule } from '../outlet/outlet.module';
import { NzTreeIndentComponent } from './tree-indent.component';
import { TreeNodeCheckboxComponent } from './tree-node-checkbox.component';
import { TreeNodeSwitcherComponent } from './tree-node-switcher.component';
import { TreeNodeTitleComponent } from './tree-node-title.component';
import { TreeNodeComponent } from './tree-node.component';
import { TreeComponent } from './tree.component';

@NgModule({
    imports: [
        CommonModule,
        OutletModule,
        HighlightModule,
        ScrollingModule,
        FormsModule
    ],
    exports: [
        TreeComponent,
        TreeNodeComponent,
        NzTreeIndentComponent
    ],
    declarations: [
        TreeComponent,
        TreeNodeComponent,
        NzTreeIndentComponent,
        TreeNodeSwitcherComponent,
        TreeNodeCheckboxComponent,
        TreeNodeTitleComponent
    ]
})
export class TreeModule {
}
