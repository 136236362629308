import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CaseDataFactory } from 'app/case/data/case.data';
import { isPresent } from 'share';
import { AiComponent, Renderable, ValueMode, ValueModePredicate } from 'core';
import { CaseProperty } from 'app/case/prop';
import { Pool } from 'app/data/pool/pool.model';
import { ProductParam } from 'app/product/product.model';
import { DataRule } from 'app/data/rule/data.rule.model';
import { GlobalHolder } from '../global.holder';


@Component({
    selector: 'ai-mode-value',
    templateUrl: 'mode.value.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['mode.value.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ModeValueComponent),
        multi: true
    }]
})
@AiComponent({
    editConfigurable: false
})
export class ModeValueComponent implements OnInit, ControlValueAccessor, Renderable, OnDestroy {

    @Input() editable: boolean;
    @Input() multiple: boolean;

    databases: Array<ProductParam> = [];
    globals: Array<ProductParam> = [];
    pools: Array<Pool> = [];
    factory: CaseDataFactory;
    factories: Array<CaseDataFactory> = [];
    rules: Array<DataRule> = [];
    props: Array<CaseProperty> = [];

    values: Array<string> = [];
    predicate: ValueModePredicate;

    private _mode: ValueMode;
    private onChange: (_: any) => void;

    constructor(private global: GlobalHolder,
                private elementRef: ElementRef,
                private cdRef: ChangeDetectorRef) {
        this.multiple = true;
        this.editable = true;
        this.updateValue = this.updateValue.bind(this);
    }

    ngOnInit() {
        this.databases = this.global.databases;
        this.globals = this.global.globals;

        let pool$ = this.global.pools.subscribe(items => this.pools = items);
        this.ngAdd$(pool$);

        let params$ = this.global.factories.subscribe(items => {
            this.factories = items.filter(item => item.operate_type !== 'D' && item.params.length > 0);
            this.initFactory();
            this.render();
        });
        this.ngAdd$(params$);

        let rules$ = this.global.rules.subscribe(items => {
            this.rules = items;
            this.render();
        });
        this.ngAdd$(rules$);

        let prop$ = this.global.properties.subscribe(items => {
            console.log(items,'我是属性值')
            this.props = items.filter(item => item.operate_type !== 'D');
            this.resetProp();
            this.render();
        });
        this.ngAdd$(prop$);

        this.elementRef.nativeElement.addEventListener('change', this.updateValue);
    }

    render(): void {
        this.cdRef.markForCheck();
    }

    private initFactory(): void {
        if (this._mode !== ValueMode.FACTORY) {
            return;
        }
        this.factory = this.factories.find(item => item.params.some(p => p.factory_param_id === +this.values[0]));
    }

    get mode(): ValueMode {
        return this._mode;
    }

    @Input()
    set mode(value: ValueMode) {
        if (isPresent(this._mode)) {
            this.values = [];
        }
        this._mode = value;
        this.initFactory();
        this.predicate = new ValueModePredicate(value);
    }

    writeValue(obj: any): void {
        this.values = !Array.isArray(obj) ? [obj] : obj;
        this.initFactory();
        this.render();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    private updateValue(): void {
        this.values.length = [ValueMode.SQL, ValueMode.DATAPOOL].includes(this._mode) || this.options2.length > 0 ? 2 : 1;
        this.values = [...this.values];
        this.onChange?.(this.values);
    }

    get text(): string {
        if (this.mode === ValueMode.NULL || this.mode === ValueMode.UNDEFINED) {
            return '';
        }
        if (this.mode === ValueMode.SQL) {
            return this.values[0];
        }
        if (this.mode === ValueMode.PROP) {
            return this.propText();
        }
        if (this.mode === ValueMode.FACTORY) {
            return this.factoryText();
        }
        if (this.mode === ValueMode.RULE) {
            return this.ruleText();
        }
        return this.values.join('|');
    }

    private propText(): string {
        let prop = this.props.find(item => item.prop_name === this.values[0]);
        let value = isPresent(prop) ? prop.prop_value || '' : '';
        value = Array.isArray(value) ? value[0] : value;
        return value.includes(':') ? this.values.join('|') : this.values[0];
    }

    private factoryText(): string {
        if (!isPresent(this.factory)) {
            return '';
        }
        let param = this.factory.params.find(item => item.factory_param_id === +this.values[0]);
        if (!isPresent(param)) {
            return '';
        }
        return `${param.factory_name} | ${param.factory_param_name}`;
    }

    private ruleText(): string {
        let rule = this.rules.find(item => item.generator_id === +this.values[0]);
        if (!isPresent(rule)) {
            return '';
        }
        return rule.name;
    }

    get options2(): Array<string> {
        if (!this.multiple || this.isSingleMode() || !isPresent(this.values[0])) {
            return [];
        }

        if (this._mode === ValueMode.DATAPOOL && this.values[0].includes(':')) {
            return this.values[0].split(':');
        }

        if (this._mode !== ValueMode.PROP) {
            return [];
        }

        let prop = this.props.find(item => item.prop_name === this.values[0]);
        let value = isPresent(prop) ? prop.prop_value || '' : '';
        value = Array.isArray(value) ? value[0] : value;
        return value.includes(':') ? value.split(':') : [];
    }

    private isSingleMode(): boolean {
        return [ValueMode.DEFAULT, ValueMode.FIXED, ValueMode.SQL, ValueMode.GLOBAL, ValueMode.FACTORY].includes(this._mode);
    }

    private resetProp(): void {
        if (this._mode !== ValueMode.PROP) {
            return;
        }
        let prop = this.props.find(item => item.prop_name === this.values[0]);
        if (!isPresent(prop)) {
            return;
        }
        let value: string = isPresent(prop) ? prop.prop_value || '' : '';
        value = Array.isArray(value) ? value[0] : value;
        if (!value.includes(':') || !value.split(':').includes(this.values[1])) {
            this.values[1] = '';
            this.updateValue();
        }
    }

    resetPool(): void {
        this.values[1] = '';
        this.updateValue();
    }

    ngOnDestroy(): void {
        this.elementRef.nativeElement.removeEventListener('change', this.updateValue);
    }
}
