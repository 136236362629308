import { NgModule } from '@angular/core';
import { CoreModule } from 'core';
import { ShareModule } from 'share';
import { GroupModule } from 'app/group/group.module';
import { UIPageService } from 'app/ui/page/ui.page.service';
import { UIWidgetService } from 'app/ui/widget/ui.widget.service';
import { PageVersionService } from 'app/ui/page/version/page.version.service';
import { UIPageStepModule } from 'app/ui/page/step/ui.page.step.module';

import { UIPageEditComponent } from './ui.page.edit.component';
import { PageBasicComponent } from './basic/page.basic.component';
import { PageScriptModeComponent } from './script/page.script.mode.component';
import { PageParamComponent } from './param/page.param.component';
import { PageParamBtnComponent } from './param/page.param.btn.component';
import { PageStepModeComponent } from 'app/ui/page/edit/step/page.step.mode.component';


@NgModule({
    imports: [
        ShareModule,
        CoreModule,
        GroupModule,
        UIPageStepModule
    ],
    exports: [
        UIPageEditComponent
    ],
    declarations: [
        UIPageEditComponent,
        PageBasicComponent,
        PageScriptModeComponent,
        PageStepModeComponent,
        PageParamComponent,
        PageParamBtnComponent
    ],
    entryComponents: [
        PageParamComponent
    ],
    providers: [
        UIPageService,
        UIWidgetService,
        PageVersionService
    ]
})
export class UIPageEditModule {
}
