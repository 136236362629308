export enum CaseType {
    INTERFACE = 'INTF',
    UI = 'UI',
    APP = 'APP',
    SCRIPT = 'SCRIPT',
    IF = 'IF',
    WHILE = 'WHILE',
    START = 'START',
    VUGEN = 'VUGEN',
    LOGIC = 'LOGIC',
    VERIFY = 'VERIFY'
}

export const CASE_TYPE_KEYS = [CaseType.INTERFACE, CaseType.UI, CaseType.APP, CaseType.SCRIPT, CaseType.IF, CaseType.WHILE, CaseType.START, CaseType.LOGIC, CaseType.VERIFY];
export const STEP_TYPE_NAMES = ['接口', 'UI', 'APP', '脚本', '判断节点', 'while', '开始节点', '测试逻辑', '验证节点'];
export const CASE_TYPE_NAMES = ['接口', 'UI', 'APP'];
export const CASE_TYPE_CLASSES = ['interface-node', 'ui-node', 'app-node', 'script-node', 'if-node', 'while-node', 'start-node', 'logic-node', 'verify-node'];

export const CASE_MODE_KEYS = ['SINGLE', 'MULTI'];
export const CASE_MODE_NAMES = ['单步骤', '多步骤'];

export const CASE_EVAL_KEYS = ['URL_PARAM', 'QRY', 'HEADER', 'JSONPATH', 'XPATH'];
export const CASE_EVAL_NAMES = ['路径参数', '查询参数', '头参数', 'json_path', 'xpath'];
