import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalRef } from 'share';
import { UploadProgress } from './upload.model';
import { UploadService } from './upload.service';


@Component({
    selector: 'ai-upload',
    templateUrl: 'upload.component.html',
    styleUrls: [`upload.component.scss`]
})
export class UploadComponent implements OnInit, OnDestroy {

    private destroy$ = new Subject();

    progress: UploadProgress;

    constructor(public modalRef: ModalRef, private service: UploadService) {
    }

    ngOnInit(): void {
        this.service.progress
            .pipe(takeUntil(this.destroy$))
            .subscribe(item => this.progress = item);
    }

    change(event: Event): void {
        const target = event.target as HTMLInputElement;
        this.service.load(target?.files?.[0]);
        this.service.upload();
    }

    drop(event: DragEvent): void {
        event.stopPropagation();
        event.preventDefault();
        this.service.load(event.dataTransfer?.files?.[0]);
        this.service.upload();
    }

    allowDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    ngOnDestroy(): void {
        this.destroy$.next('');
        this.destroy$.complete();
    }
}
