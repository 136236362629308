import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBoolean, isPresent } from 'share';
import { AiComponent, Renderable } from 'core';
import { UIStep } from 'app/ui/page/step/ui.page.step.model';
import { UIPageParamsSelectService } from './ui.page.params.select.service';

@Component({
    selector: 'ai-page-params-select',
    templateUrl: 'ui.page.params.select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => UIPageParamsSelectComponent),
        multi: true
    }]
})
@AiComponent()
export class UIPageParamsSelectComponent implements OnInit, ControlValueAccessor, OnChanges, Renderable, OnDestroy {

    @Input() items: UIStep[] = [];
    @Input() placeholder: string;
    @Input() @InputBoolean() editable: boolean;
    @Input() @InputBoolean() allowClear: boolean;

    @Output() paramChange = new EventEmitter<any>();

    value: string;
    groups: Array<{ label: string; children: Array<{ label: string; value: string; }> }> = [];

    private onChange: (_: any) => void;

    constructor(private cdRef: ChangeDetectorRef, private service: UIPageParamsSelectService) {
        this.placeholder = '';
    }

    ngOnInit() {
        const options$ = this.service.options.subscribe(items => {
            this.groups = items;
            this.render();
        });
        this.ngAdd$(options$);

        this.service.init(this.items);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes['items'].isFirstChange()) {
            this.groups = [];
            this.service.init(this.items);
        }
    }

    render(): void {
        this.cdRef.markForCheck();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.value = isPresent(obj) ? obj : '';
        this.render();
    }

    change(): void {
        let item: any;
        this.groups.forEach(g => g.children?.forEach(param => {
            if (this.value === param.value) {
                item = param;
            }
        }));
        this.paramChange.emit(item);
        this.onChange(this.value);
    }

    ngOnDestroy() {
        this.service.clear();
    }
}
