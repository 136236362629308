import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OutletModule } from '../outlet/outlet.module';
import { AiOverlayModule } from '../overlay/overlay.module';
import { OptionContainerComponent } from './option-container.component';
import { OptionGroupComponent } from './option-group.component';
import { OptionItemGroupComponent } from './option-item-group.component';
import { OptionItemComponent } from './option-item.component';
import { OptionComponent } from './option.component';
import { SelectArrowComponent } from './select-arrow.component';
import { SelectItemComponent } from './select-item.component';
import { SelectPlaceholderComponent } from './select-placeholder.component';
import { SelectSearchComponent } from './select-search.component';
import { SelectTopControlComponent } from './select-top-control.component';
import { SelectComponent } from './select.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PlatformModule,
        OverlayModule,
        ScrollingModule,
        A11yModule,
        OutletModule,
        AiOverlayModule
    ],
    declarations: [
        OptionComponent,
        SelectComponent,
        OptionContainerComponent,
        OptionGroupComponent,
        OptionItemComponent,
        SelectTopControlComponent,
        SelectSearchComponent,
        SelectItemComponent,
        SelectArrowComponent,
        SelectPlaceholderComponent,
        OptionItemGroupComponent
    ],
    exports: [
        OptionComponent,
        SelectComponent,
        OptionGroupComponent,
        SelectArrowComponent,
        SelectItemComponent,
        SelectPlaceholderComponent,
        SelectSearchComponent
    ]
})
export class SelectModule {
}
