import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnimationNumberComponent } from './animation.number.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        AnimationNumberComponent
    ],
    declarations: [
        AnimationNumberComponent
    ]
})
export class NumberModule {
}
