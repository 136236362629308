import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalHolder } from 'app/global.holder';
import { IPageService } from 'core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPresent } from 'share';
import { Staff } from './staff.model';

@Injectable({ providedIn: 'root' })
export class StaffService implements IPageService {

    private baseUrl = '/xltp/sys/staff';

    constructor(private http: HttpClient, private global: GlobalHolder) {
    }

    qryOwn(nt_account: string, page_no: number, page_size: number): Observable<any> {
        let params = new HttpParams().set('default', '1').set('page_no', `${page_no}`).set('page_size', `${page_size}`);
        if (isPresent(nt_account)) {
            params = params.set('nt_account', nt_account);
        }
        return this.http.get(`${this.baseUrl}/list`, { params });
    }

    qryByPage(params: { page_no?: number, page_size?: number, [key: string]: any }): Observable<any> {
        params.default = params.name ? '2' : '1';
        if (!params.name) {
            const user = this.global.user.getValue();
            params.nt_account = user?.user_type !== 1 || !isPresent(user?.nt_account) ? 'libing3' : user.nt_account;
        }
        return this.http.get(`${this.baseUrl}/list`, { params });
    }

    registerStaff(staffs: Array<Staff>): Observable<Array<Staff>> {
        return this.http.post(`${this.baseUrl}/addUsers`, staffs).pipe(map<any, Array<Staff>>(items => items || []));
    }

}
