import { Pipe, PipeTransform } from '@angular/core';
import { InterfaceParamDefinition } from 'app/interface/api/interface.model';
import { CASE_TYPE_KEYS, CASE_TYPE_NAMES } from 'core';
import { isPresent } from 'share';
import { NodeType } from '../node';

@Pipe({
    name: 'paramName'
})
export class ParamNamePipe implements PipeTransform {

    transform(value: any): any {
        return this.paramName(value);
    }

    paramName(value: InterfaceParamDefinition): string {
        if (!isPresent(value.parent)) {
            return value.param_name || '';
        }

        let parent = value.parent;
        let type = parent.node_type;
        if (type & NodeType.Array) {
            return `${this.paramName(parent)}[${parent.children.filter(item => item.operate_type !== 'D').findIndex(item => item === value)}]`;
        }
        return value.param_name || '';
    }
}


@Pipe({
    name: 'paramEnumType'
})
export class ParamEnumTypePipe implements PipeTransform {

    private types = { 'REQ_QRY': '查询参数', 'REQ_HEAD': '请求头', 'REQ_BODY': '请求体', 'RESP_HEAD': '响应头', 'RESP_BODY': '响应体' };

    transform(value: any): any {
        return this.types[value] || '';
    }
}

@Pipe({
    name: 'errorType'
})
export class ErrorTypePipe implements PipeTransform {

    transform(value: any): any {
        return value === 'error' ? '错误' : '非错误';
    }
}

