// tslint:disable:deprecation
import {
    AfterContentChecked,
    Component,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TabChangeEvent } from 'share';

let nextId = 0;

@Directive({ selector: 'ng-template[tabTitle]' }) //tslint:disable-line
export class NgbTabTitle { //tslint:disable-line
    constructor(public templateRef: TemplateRef<any>) {
    }
}

@Directive({ selector: 'ng-template[tabContent]' }) //tslint:disable-line
export class NgbTabContent { //tslint:disable-line
    constructor(public templateRef: TemplateRef<any>) {
    }
}

@Directive({ selector: 'ai-tab' }) //tslint:disable-line
export class NgbTab implements AfterContentChecked { //tslint:disable-line

    @Input() key: any = `tab-${nextId++}`;
    @Input() title: string;
    @Input() disabled = false;

    titleTpl: NgbTabTitle | null;
    contentTpl: NgbTabContent | null;

    @ContentChildren(NgbTabTitle, { descendants: false }) titleTpls: QueryList<NgbTabTitle>;
    @ContentChildren(NgbTabContent, { descendants: false }) contentTpls: QueryList<NgbTabContent>;

    ngAfterContentChecked() {
        this.titleTpl = this.titleTpls.first;
        this.contentTpl = this.contentTpls.first;
    }
}

@Component({
    selector: 'ai-tabset',
    exportAs: 'tabset',
    encapsulation: ViewEncapsulation.None,
    templateUrl: 'tabset.component.html',
    host: {
        '[class.tabs-container]': 'true'
    }
})
export class NgbTabsetComponent implements OnInit, AfterContentChecked, OnDestroy {

    @Input() showPagination: boolean = true;
    @Input() type: 'pills' | 'card';
    @Input() tabClass: string;
    @Input() allowContent: boolean;
    @Input() activeKey: any;
    @Input() navTabsExtraContent: TemplateRef<void>;

    disableScrollAfter: boolean = true;
    disableScrollBefore: boolean = true;
    showPaginationControls: boolean = true;

    @ContentChildren(NgbTab)
    tabsE: QueryList<NgbTab>;

    @ViewChild('navElement', { static: true })
    navElement: ElementRef<HTMLDivElement>;

    @ViewChild('scrollElement', { static: true })
    scrollElement: ElementRef<HTMLDivElement>;

    @Output() tabChange = new EventEmitter<TabChangeEvent>();

    private destroy$ = new Subject<void>();
    private _scrollDistance: number = 0;
    private scrollDistanceChanged: boolean;

    constructor(private zone: NgZone, private renderer: Renderer2) {
        zone.onStable.pipe(takeUntil(this.destroy$)).subscribe(() => this.updatePagination());
    }

    ngOnInit(): void {
    }


    get tabs(): Array<NgbTab> {
        let items = [];
        this.tabsE?.forEach(tab => items.push(tab));
        return items;
    }

    get tabScrollWidth(): number {
        return this.navElement.nativeElement.scrollWidth;
    }

    get tabScrollOffSetWidth(): number {
        return this.scrollElement.nativeElement.offsetWidth;
    }

    set scrollDistance(v: number) {
        let distance = Math.max(0, Math.min(this.getMaxScrollDistance(), v));
        if (this._scrollDistance === distance) {
            return;
        }
        this._scrollDistance = distance;
        this.scrollDistanceChanged = true;
        this.checkScrollingControls();
    }

    get scrollDistance(): number {
        return this._scrollDistance;
    }

    scroll(direction: number): void {
        this.scrollDistance += direction * this.tabScrollOffSetWidth;
        this.updateTabScrollPosition();
    }

    select(key: any) {
        let tab = this._getTabById(key);
        if (tab.disabled || this.activeKey === key) {
            return;
        }
        let defaultPrevented = false;

        this.tabChange.emit({
            activeKey: this.activeKey,
            nextKey: tab.key,
            preventDefault: () => {
                defaultPrevented = true;
            }
        });

        if (!defaultPrevented) {
            this.activeKey = tab.key;
        }
        this.activeKey = key;
    }

    ngAfterContentChecked() {
        let activeTab = this._getTabById(this.activeKey);
        this.activeKey = activeTab ? activeTab.key : this.tabs[0]?.key;
    }

    private updatePagination(): void {
        this.checkPaginationControls();
        this.updateTabScrollPosition();
    }

    private checkPaginationControls(): void {
        const isEnabled = this.tabScrollWidth > this.tabScrollOffSetWidth;
        if (!isEnabled) {
            this.scrollDistance = 0;
        }
        this.showPaginationControls = isEnabled;
        this.checkScrollingControls();

    }

    private checkScrollingControls() {
        this.disableScrollBefore = this.scrollDistance === 0;
        this.disableScrollAfter = this.scrollDistance === this.getMaxScrollDistance();
    }

    private updateTabScrollPosition(): void {
        if (!this.scrollDistanceChanged) {
            return;
        }
        this.renderer.setStyle(this.navElement.nativeElement, 'transform', `translate3d(${-this.scrollDistance}px, 0, 0)`);
        this.scrollDistanceChanged = false;
    }

    private _getTabById(id: any): NgbTab {
        let tabsWithId: NgbTab[] = this.tabs.filter(tab => tab.key === id);
        return tabsWithId.length ? tabsWithId[0] : null;
    }

    private getMaxScrollDistance(): number {
        return this.tabScrollWidth - this.tabScrollOffSetWidth;
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
