import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { isPresent } from 'share';
import { SearchFilterOption } from './search.filter.model';
import { GlobalHolder } from 'app/global.holder';
import { SearchFilterHolder } from './search.filter.holder';
import { SearchFilterService } from './search.filter.service';


@Component({
    selector: 'ai-search-filter',
    templateUrl: 'search.filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFilterComponent implements OnInit, OnDestroy {

    @Input() cacheable: boolean = true;
    @Input() placeHolder: string = '筛选项';
    @Input() params: any;

    @Output() ngChange: EventEmitter<any> = new EventEmitter<any>(true);

    items: Array<SearchFilterOption> = [];
    id: number;

    private options$: Subscription;
    private params$: Subscription;

    constructor(private cdRef: ChangeDetectorRef,
                private holder: SearchFilterHolder,
                private global: GlobalHolder,
                private filterService: SearchFilterService) {
    }

    ngOnInit() {
        this.options$ = this.holder.options.subscribe(items => {
            this.items = items;
            this.cdRef.markForCheck();
        });
        this.params$ = this.holder.params.subscribe(params => {
            if (!params) {
                this.emit(null);
                return;
            }
            if (!isPresent(params.product_id) || params.product_id === this.global.product_id) {
                this.emit(params);
            } else {
                this.emit(null);
            }
        });

        this.initOptions();
    }

    private initOptions(): void {
        this.filterService.qryAll({ search_type: this.holder.type })
            .subscribe((items => this.holder.updateOptions(items)));
    }

    private emit(params: any): void {
        if (isPresent(params)) {
            this.ngChange.emit(params);
        } else {
            this.ngChange.emit(this.params);
        }
    }

    change(): void {
        if (!this.id) {
            return;
        }
        this.filterService.qryById(this.id).subscribe(item => {
            this.holder.updateOption(item);
            this.emit(JSON.parse(item.search_content));
        });
    }

    del(event: Event, item: SearchFilterOption): void {
        event.stopPropagation();
        this.filterService.del(item.id).subscribe(() => {
            this.items.remove(item);
            this.holder.updateOptions(this.items);
            if (this.id === item.id) {
                this.id = null;
            }
            toastr.success('删除筛选项成功');
        });
    }

    ngOnDestroy(): void {
        if (this.cacheable) {
            this.holder.cacheParams(this.params);
        }
        if (this.options$) {
            this.options$.unsubscribe();
        }
        if (this.params$) {
            this.params$.unsubscribe();
        }
    }
}
