import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BatchSelectModule, ShareModule } from 'share';

import { UserGroupSelectComponent } from './user.group.select.component';

@NgModule({
    imports: [
        FormsModule,
        ShareModule,
        BatchSelectModule
    ],
    declarations: [
        UserGroupSelectComponent
    ],
    exports: [
        UserGroupSelectComponent
    ],
    entryComponents: [
        UserGroupSelectComponent
    ]
})
export class UserGroupSelectModule {
}
