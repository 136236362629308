import { Pipe, PipeTransform } from '@angular/core';
import { isPresent } from 'share';

@Pipe({
    name: 'filePath'
})
export class FilePathPipe implements PipeTransform {

    transform(value: string): any {
        if (!isPresent(value)) {
            return '';
        }
        if (value.startsWith('/')) {
            value = value.substring(1);
        }
        return '/xltp/sftp/' + value
    }
}