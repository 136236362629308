import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { UIPage } from 'app/ui/page/ui.page.model';
import { SUBMIT_PROCESSOR, SubmitProcessor } from 'core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DrawerRef, EventManager, isPresent } from 'share';

@Injectable()
export class PageSubmitService {

    constructor(private router: Router,
                @Optional() private drawerRef: DrawerRef,
                private eventManager: EventManager,
                @Inject(SUBMIT_PROCESSOR) private processors: SubmitProcessor[]) {
    }

    submit(item: UIPage): void {
        let id = item.component_id;
        this.processors.reduce<Observable<any>>((previousValue, currentValue) => previousValue.pipe(switchMap(pre => currentValue.postProcess(pre))), of(item)).subscribe(() => {
            if (isPresent(this.drawerRef)) {
                this.drawerRef.close(item);
                return;
            }
            if (isPresent(id)) {
                toastr.success('编辑组件成功');
                this.eventManager.broadcast('update-page', item);
            } else {
                toastr.success('添加组件成功');
                this.eventManager.broadcast('add-page', item);
            }
            this.router.navigate([`pages/ui/page/edit/${item.component_id}`]);
        });
    }
}
