import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BlockInterceptor } from '../block/block.interceptor';
import { AuthInterceptor } from './auth.interceptor';
import { BaseRequestInterceptor } from './base.request.interceptor';
import { ConfirmInterceptor } from './confirm.interceptor';
import { CsrfInterceptor } from './csrf.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { ParamsInterceptor } from './params.interceptor';
import { RedirectInterceptor } from './redirect.interceptor';
import { TipInterceptor } from './tip.interceptor';

export const INTERCEPTORS = [
    { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ParamsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ConfirmInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RedirectInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BlockInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TipInterceptor, multi: true }
];
