import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { slideMotion } from '../animation';
import { DateHelperService } from '../datepicker/datepicker-helper';

import { InputBoolean } from '../facade';
import { DEFAULT_OVERLAY_POSITION } from './util';

@Component({
    selector: 'ai-time-picker',
    exportAs: 'timePicker',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'time-picker.component.html',
    animations: [slideMotion],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: TimePickerComponent, multi: true }]
})
export class TimePickerComponent implements ControlValueAccessor, OnInit, OnChanges {

    value: Date | null = null;
    origin: CdkOverlayOrigin;
    inputSize: number;
    overlayPositions: ConnectionPositionPair[] = DEFAULT_OVERLAY_POSITION;
    open = false;

    @Input() hourStep: number = 1;
    @Input() minuteStep: number = 1;
    @Input() secondStep: number = 1;
    @Input() popupClassName: string;
    @Input() placeHolder = '';
    @Input() addOn: TemplateRef<void>;
    @Input() defaultOpenValue: Date;
    @Input() disabledHours: () => number[];
    @Input() disabledMinutes: (hour: number) => number[];
    @Input() disabledSeconds: (hour: number, minute: number) => number[];
    @Input() format: string = 'HH:mm:ss';
    @Input() @InputBoolean() use12Hours: boolean = false;
    @Output() readonly openChange = new EventEmitter<boolean>();

    @Input() @InputBoolean() hideDisabledOptions = false;
    @Input() @InputBoolean() disabled = false;
    @Input() @InputBoolean() autoFocus = false;

    private _onChange: (value: Date | null) => void;
    private _onTouched: () => void;

    constructor(private element: ElementRef, private cdr: ChangeDetectorRef, private dateHelper: DateHelperService) {
    }

    get formatValue(): string {
        return this.dateHelper.format(this.value, this.format);
    }

    setValue(value: Date | null): void {
        this.value = value ? new Date(value) : null;
        if (this._onChange) {
            this._onChange(this.value);
        }
        if (this._onTouched) {
            this._onTouched();
        }
    }

    openOverlay(): void {
        if (this.disabled) {
            return;
        }
        this.open = true;
        this.openChange.emit(this.open);
    }

    close(): void {
        this.open = false;
        this.cdr.markForCheck();
        this.openChange.emit(this.open);
    }

    onClickClearBtn(): void {
        this.setValue(null);
    }

    ngOnInit(): void {
        this.inputSize = Math.max(8, this.format.length) + 2;
        this.origin = new CdkOverlayOrigin(this.element);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { use12Hours, format } = changes;
        if (use12Hours && !use12Hours.previousValue && use12Hours.currentValue && !format) {
            this.format = 'h:mm:ss a';
        }
    }

    writeValue(time: Date): void {
        if (time instanceof Date) {
            this.value = time;
        } else {
            this.value = time ? new Date(time) : null;
        }
        this.cdr.markForCheck();
    }

    registerOnChange(fn: (time: Date | null) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.cdr.markForCheck();
    }
}
