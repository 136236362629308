import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ShareModule } from 'share';

import { ColumnComponent } from './column.component';
import { ColumnConfigComponent } from './config/column.config.component';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ShareModule,
        NzIconModule
    ],
    exports: [
        ColumnComponent
    ],
    declarations: [
        ColumnComponent,
        ColumnConfigComponent
    ]
})
export class ColumnModule {
}
