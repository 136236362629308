import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { getBaseFontSize } from '../facade';

@Component({
    selector: 'ai-more-operation',
    templateUrl: 'more.operation.component.html',
    styleUrls: ['more.operation.scss']
})

export class MoreOperationComponent implements OnInit, OnDestroy {

    left: number;
    top: number;

    constructor() {
    }

    ngOnInit() {
    }

    @HostListener('mouseenter', ['$event.target'])
    enter(target: HTMLElement): void {
        let fontSize = getBaseFontSize();
        let react = target.getBoundingClientRect();
        this.left = react.left - fontSize;

        let clientHeight = $(document).height();
        let menuHeight = $(target).find('.dropdown-menu').outerHeight();
        if (react.top + react.height + menuHeight > clientHeight) {
            this.top = react.top - menuHeight + fontSize * 0.25;
        } else {
            this.top = react.top + react.height - fontSize * 0.25;
        }
    }


    ngOnDestroy() {
    }
}
