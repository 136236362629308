export enum CacheTimeStrategy {
    AfterNgInit,
    BeforeNgInit
}

export const SYS_PARAMS_MAP = new Map<string, Map<string, any>>();


export function getSysParams(type: string, key: string) {
   return SYS_PARAMS_MAP.get(type)?.get(key);
}
