import { Routes } from '@angular/router';
import { CacheResolve } from 'app/cache.resolve';
import { InitializerGuardService } from 'app/guard/initializer.guard.service';
import { MenuGuardService } from 'app/guard/menu.guard.service';
import { ProductGuardService } from 'app/guard/product.guard.service';
import { TenantGuardService } from 'app/guard/tenant.guard.service';
import { PasswordGuardService } from 'app/guard/password.guard.service';
import { MainComponent } from 'app/main/main.component';
import { microPrecision } from 'app/micro/micro.context';


import { NgModule } from '@angular/core';
import {  RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ClearMemoryGuard } from './clear-memory.guard';


 
const pagesChildren = [
    {
        path: '', pathMatch: 'full', redirectTo: 'product/dashboard'
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
    },
    {
        path: 'interface',
        loadChildren: () => import('./interface/interface.module').then(m => m.InterfaceModule)
    },
    {
        path: 'mock',
        loadChildren: () => import('./mock/mock.module').then(m => m.MockModule)
    },
    {
        path: 'case',
        loadChildren: () => import('./case/case.module').then(m => m.CaseModule)
    },
    {
        path: 'plan',
        loadChildren: () => import('./plan/plan.module').then(m => m.PlanModule)
    },
    {
        path: 'business',
        loadChildren: () => import('./business/business.module').then(m => m.BusinessModule)
    },
    {
        path: 'sys',
        loadChildren: () => import('./system/sys.module').then(m => m.SysModule)
    },
    {
        path: 'product',
        loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
    },
    {
        path: 'result',
        loadChildren: () => import('./result/result.module').then(m => m.ResultModule)
    },
    {
        path: 'combine_report',
        loadChildren: () => import('./result/combine/result.combine.module').then(m => m.ResultCombineModule)
    },
    {
        path: 'report/template',
        loadChildren: () => import('./result/template/report.template.module').then(m => m.ReportTemplateModule)
    },
    {
        path: 'ui',
        loadChildren: () => import('./ui/ui.module').then(m => m.UIModule)
    },
    {
        path: 'unit',
        loadChildren: () => import('./unit/unit.module').then(m => m.UnitModule)
    },
    // {
    //     path: 'unittest',
    //     loadChildren: () => import('./unit/unittest.module').then(m => m.UnitModule)
    // },
    {
        path: 'mobile',
        loadChildren: () => import('./mobile/mobile.module').then(m => m.MobileModule)
    },
    {
        path: 'security',
        loadChildren: () => import('./security/security.module').then(m => m.SecurityModule)
    },
    {
        path: 'manual',
        loadChildren: () => import('./manual/manual.module').then(m => m.ManualModule)
    },
    {
        path: 'data',
        loadChildren: () => import('./data/data.module').then(m => m.DataModule)
    },
    {
        path: 'detect',
        loadChildren: () => import('./detect/detect.module').then(m => m.DetectModule)
    },
    {
        path: 'perf',
        loadChildren: () => import('./perf/perf.module').then(m => m.PerfModule)
    },
    {
        path: 'operate',
        loadChildren: () => import('./operate/operate.module').then(m => m.OperateModule)
    },
    {
        path: 'workbench',
        loadChildren: () => import('./workbench/workbench.module').then(m => m.WorkbenchModule)
    },
    {
        path: 'test',
        loadChildren: () => import('./test/test.module').then(m => m.TestModule)
    },
    {
        path: 'sandbox',
        loadChildren: () => import('./sandbox/sandbox.module').then(m => m.SandboxModule)
    },
    {
        path: 'common',
        loadChildren: () => import('./common/communal.module').then(m => m.CommunalModule)
    },
    {
        path: 'grafana',
        loadChildren: () => import('./grafana/grafana.module').then(m => m.GrafanaModule)
    },
    {
        path: 'audit',
        loadChildren: () => import('./audit/audit.module').then(m => m.AUDITModule)
    },
    {
        path: 'flowRate',
        loadChildren: () => import('./flowRate/flowRate.module').then(m => m.FLOWRATEModule)
    },
];

if (microPrecision) {
    let microRoutes = [
        {
            path: 'micro',
            loadChildren: () => import('./micro/micro.module').then(m => m.MicroModule)
        },
        {
            path: 'precision',
            redirectTo: 'micro/precision'
        },
        {
            path: 'chain',
            redirectTo: 'micro/chain'
        },
        {
            path: 'repeater',
            redirectTo: 'micro/repeater'
        }
    ];
    // @ts-ignore
    pagesChildren.push(...microRoutes);
} else { 
    pagesChildren.push(...[
        {
            path: 'chain',
            loadChildren: () => import('./chain/chain.module').then(m => m.ChainModule)
        },
        {
            path: 'repeater',
            loadChildren: () => import('./repeater/repeater.module').then(m => m.RepeaterModule)
        },
        {
            path: 'precision',
            loadChildren: () => import('./precision/precision.module').then(m => m.PrecisionModule)
        }
    ]);
}


export const appRoutes: Routes = [
    {
        path: '', pathMatch: 'full', redirectTo: 'pages'
    },
    {
        path: 'pages',
        component: MainComponent,
        canDeactivate: [ClearMemoryGuard],
        canActivate: [
            InitializerGuardService
        ],
        canActivateChild: [
            ProductGuardService,
            MenuGuardService,
            PasswordGuardService,
            TenantGuardService
        ],
        resolve: {
            cache: CacheResolve
        },
        children: pagesChildren
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }