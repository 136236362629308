import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { OverlayRef } from '@angular/cdk/overlay';
import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    NgZone,
    OnDestroy,
    Optional,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';


import { Subject } from 'rxjs';

import { nzModalAnimations } from './modal-animations';
import { BaseModalContainer } from './modal-container';
import { MODAL_OPTIONS, ModalOptions } from './modal-types';

@Component({
    selector: 'ai-modal-confirm-container',
    exportAs: 'modalConfirmContainer',
    template: `
        <div #modalElement
             role="document"
             class="modal"
             [class]="config.modalClass">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="modal-confirm-body-wrapper">
                        <div class="modal-confirm-body">
                            <i class="icon" [ngClass]="config.iconType"></i>
                            <span class="modal-confirm-title">
                                <ng-container *stringTemplateOutlet="config.title">
                                    <span [innerHTML]="config.title"></span>
                                </ng-container>
                            </span>
                            <div class="modal-confirm-content">
                                <ng-template cdkPortalOutlet></ng-template>
                                <div [innerHTML]="config.content"></div>
                            </div>
                        </div>
                        <div class="modal-confirm-btns">
                            <button class="btn" *ngIf="config.cancelText !== null" (click)="onCancel()">{{ config.cancelText || '取消' }}</button>
                            <button class="btn btn-primary" *ngIf="config.okText !== null" (click)="onOk()">{{ config.okText || '确定' }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    animations: [nzModalAnimations.modalContainer],
    changeDetection: ChangeDetectionStrategy.Default,
    host: {
        tabindex: '-1',
        role: 'dialog',
        class: 'modal-wrap',
        '[style.zIndex]': 'config.zIndex',
        '[@modalContainer]': 'state',
        '(@modalContainer.start)': 'onAnimationStart($event)',
        '(@modalContainer.done)': 'onAnimationDone($event)',
        '(click)': 'click($event)'
    }
})
export class ModalConfirmContainerComponent extends BaseModalContainer implements OnDestroy {

    @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;
    @ViewChild('modalElement', { static: true }) modalElementRef: ElementRef<HTMLDivElement>;

    @Output() readonly cancelTriggered = new EventEmitter<void>();
    @Output() readonly okTriggered = new EventEmitter<void>();

    locale: { okText?: string; cancelText?: string } = {};

    private destroy$ = new Subject<void>();

    constructor(elementRef: ElementRef,
                focusTrapFactory: ConfigurableFocusTrapFactory,
                cdr: ChangeDetectorRef,
                render: Renderer2,
                zone: NgZone,
                overlayRef: OverlayRef,
                @Inject(MODAL_OPTIONS) public config: ModalOptions,
                @Optional() @Inject(DOCUMENT) document: any) {
        super(elementRef, focusTrapFactory, cdr, render, zone, overlayRef, config, document);
    }

    onCancel(): void {
        this.cancelTriggered.emit();
    }

    onOk(): void {
        this.okTriggered.emit();
    }

    attachComponentPortal<T>(_portal: ComponentPortal<T>): never {
        throw new Error('The confirm mode does not support using component as content');
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
