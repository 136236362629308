import { Component, Input, OnInit } from '@angular/core';
import { GlobalHolder } from 'app/global.holder';
import { Product, ProductUser } from 'app/product/product.model';
import { isAdmin, User } from 'app/system/user/user.model';

@Component({
    selector: 'ai-product-user-del',
    templateUrl: 'product.user.del.component.html'
})

export class ProductUserDelComponent implements OnInit {

    @Input() item: ProductUser;
    @Input() items: Array<ProductUser> = [];

    @Input() product: Product;
    private readonly user: User;

    constructor(private global: GlobalHolder) {
        this.user = this.global.user.getValue();
        this.product = this.global.product.getValue();
    }

    ngOnInit() {
    }

    get editable(): boolean {

        if (this.product.create_user === this.item.user_id || isAdmin(this.item)) {
            return false;
        }

        if (this.user.user_id === this.product.create_user || isAdmin(this.user)) {
            return true;
        }

        return this.isAdmin(this.user) && !this.isAdmin(this.item);
    }

    private isAdmin(user: any): boolean {
        return isAdmin(user) || this.items.some(item => item.user_id === user.user_id && item.isAdmin);
    }
}
