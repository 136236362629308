import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { isPresent } from 'share';
import { Block } from './block';

@Injectable({ providedIn: 'root' })
export class BlockInterceptor implements HttpInterceptor {

    private methodMessage = { POST: '正在保存数据...', PUT: '正在编辑数据...', DELETE: '正在删除数据...' };

    constructor(private block: Block) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let message = req.headers.get(Headers.BLOCK);
        if (message === 'false') {
            return next.handle(req.clone({ headers: req.headers.delete(Headers.BLOCK) }));
        }
        if (isPresent(message)) {
            req = req.clone({ headers: req.headers.delete(Headers.BLOCK) });
            this.block.show(message);
        } else if (req.method === 'GET') {
            this.block.show();
        } else {
            let msg = this.methodMessage[req.method];
            if (req.url.endsWith('delete')) {
                msg = this.methodMessage.DELETE;
            }
            this.block.show(msg);
        }
        return next.handle(req).pipe(finalize(() => this.block.hide()));
    }
}
