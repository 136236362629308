import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GlobalHolder } from 'app/global.holder';
import { UserService } from 'app/system/user/user.service';
import { GuardInitializer } from 'app/guard/guard.initializer';


@Injectable({ providedIn: 'root' })
export class PasswordGuardInitializer implements GuardInitializer {

    constructor(private router: Router,
                private global: GlobalHolder,
                private userService: UserService) {
    }

    init(): Observable<boolean> {
        return this.userService.qryPwdState(this.global.user_id).pipe(switchMap(item => {
            if (item) {
                toastr.error('密码已过期，请编辑后重新登录');
                this.router.navigate(['pages/account/password']);
            }
            this.global.needChangePwd.next(item);
            return of(!item);
        }));
    }
}
