import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { convertKey, isComp, OPERATE_MAP, UIStep, UIStepWidgetType } from 'app/ui/page/step/ui.page.step.model';

@Pipe({
    name: 'operTips'
})
export class UIPageStepOperTipsPipe implements PipeTransform {
    transform(value: any): any {
        if (!value || !OPERATE_MAP.has(value)) {
            return;
        }
        return OPERATE_MAP.get(value).label;
    }
}

@Pipe({
    name: 'targetTips'
})
export class UIPageStepTargetTipsPipe implements PipeTransform {
    transform(step: UIStep, key: string = 'target', convert = true): any {
        if (!step || !OPERATE_MAP.has(step.operate)) {
            return '';
        }
        if (isComp(step)) {
            return key === 'target' ? step.value ?? step.component_name : '';
        }
        const config = OPERATE_MAP.get(step.operate);
        if (convert) {
            key = convertKey(key, config.flip);
        }
        const widget: UIStepWidgetType = config[key];
        if (widget === 'array' && step[key].length > 0) {
            return step[key].join('\n');
        }
        if (widget === 'elements') {
            return [step[key]].concat(step.paths).join('\n');
        }
        return step[key];
    }
}


const STEP_PIPES = [
    UIPageStepTargetTipsPipe,
    UIPageStepOperTipsPipe
];

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ...STEP_PIPES
    ],
    exports: [
        ...STEP_PIPES
    ]
})
export class UIPageStepPipe {
}

