import { Injectable } from '@angular/core';
import { DatepickerI18n } from 'share';
import { zhCN } from 'date-fns/esm/locale';


@Injectable({ providedIn: 'root' })
export class DateFnsDatepickerI18n extends DatepickerI18n {

    getDateFormat(): string {
        return 'yyyy-MM-dd';
    }

    getDateLocal(): any {
        return zhCN;
    }

    getMonthFormat(): string {
        return 'MM月';
    }

    getWeekFormat(): string {
        return 'EEEEE';
    }

    getYearFormat(): string {
        return 'yyyy年';
    }

}
