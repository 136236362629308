import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { Column } from 'core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ColumnService {

    private baseUrl = '/xltp/sys/customdisplay';

    constructor(private http: HttpClient) {
    }

    qry(source_table: string, user_id: any, product_id: any): Observable<Array<Column>> {
        return this.http.get<Array<Column>>(`${this.baseUrl}/list`, {
            params: {
                source_table,
                user_id,
                product_id
            }
        }).pipe(tap(items => {
            items?.forEach((item, index) => {
                item.order_no = index + 1;
                item.checked = item.is_select === 1;
            });
        }));
    }

    config(item: any): Observable<any> {
        let headers = new HttpHeaders().set(Headers.BLOCK, '正在更改显示字段...');
        return this.http.post(`${this.baseUrl}/column`, item, { headers });
    }

}
