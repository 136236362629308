import {
    addMonths,
    addYears,
    differenceInCalendarDays,
    differenceInCalendarMonths,
    differenceInCalendarYears,
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds,
    isFirstDayOfMonth,
    isLastDayOfMonth,
    isSameDay,
    isSameHour,
    isSameMinute,
    isSameMonth,
    isSameSecond,
    isSameYear,
    isToday,
    isValid,
    setDay,
    setMonth,
    setYear,
    startOfMonth,
    startOfWeek
} from 'date-fns';

export type WeekDayIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type CalendarDateCompareGrain = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second';
export type CalendarDateType = DateFnsCalendar | Date | null;
export type CompatibleValue = DateFnsCalendar | DateFnsCalendar[];

export function sortRangeValue(rangeValue: DateFnsCalendar[]): DateFnsCalendar[] {
    if (Array.isArray(rangeValue)) {
        const [start, end] = rangeValue;
        return start && end && start.isAfterSecond(end) ? [end, start] : [start, end];
    }
    return rangeValue;
}

export function normalizeRangeValue(value: DateFnsCalendar[]): DateFnsCalendar[] {
    const [start, end] = value || [];
    const newStart = start || new DateFnsCalendar();
    const newEnd = end?.isSameMonth(newStart) ? end.addMonths(1) : end || newStart.addMonths(1);
    return [newStart, newEnd];
}

export function cloneDate(value: CompatibleValue): CompatibleValue {
    if (Array.isArray(value)) {
        return value.map(v => (v instanceof DateFnsCalendar ? v.clone() : null));
    } else {
        return value instanceof DateFnsCalendar ? value.clone() : null;
    }
}


export class DateFnsCalendar {

    nativeDate: Date;

    constructor(date?: Date | string | number) {
        if (date) {
            if (date instanceof Date) {
                this.nativeDate = date;
            } else if (typeof date === 'string' || typeof date === 'number') {
                this.nativeDate = new Date(date);
            } else {
                throw new Error('The input date type is not supported ("Date" is now recommended)');
            }
        } else {
            this.nativeDate = new Date();
        }
    }


    calendarStart(options?: { weekStartsOn: WeekDayIndex | undefined }): DateFnsCalendar {
        return new DateFnsCalendar(startOfWeek(startOfMonth(this.nativeDate), options));
    }

    getYear(): number {
        return this.nativeDate.getFullYear();
    }

    getMonth(): number {
        return this.nativeDate.getMonth();
    }

    getDay(): number {
        return this.nativeDate.getDay();
    }

    getTime(): number {
        return this.nativeDate.getTime();
    }

    getDate(): number {
        return this.nativeDate.getDate();
    }

    getHours(): number {
        return this.nativeDate.getHours();
    }

    getMinutes(): number {
        return this.nativeDate.getMinutes();
    }

    getSeconds(): number {
        return this.nativeDate.getSeconds();
    }

    getMilliseconds(): number {
        return this.nativeDate.getMilliseconds();
    }

    clone(): DateFnsCalendar {
        return new DateFnsCalendar(new Date(this.nativeDate));
    }

    setHms(hour: number, minute: number, second: number): DateFnsCalendar {
        return new DateFnsCalendar(this.nativeDate.setHours(hour, minute, second));
    }

    setYear(year: number): DateFnsCalendar {
        return new DateFnsCalendar(setYear(this.nativeDate, year));
    }

    addYears(amount: number): DateFnsCalendar {
        return new DateFnsCalendar(addYears(this.nativeDate, amount));
    }

    // NOTE: month starts from 0
    // NOTE: Don't use the native API for month manipulation as it not restrict the date when it overflows, eg. (new Date('2018-7-31')).setMonth(1) will be date of 2018-3-03 instead of 2018-2-28
    setMonth(month: number): DateFnsCalendar {
        return new DateFnsCalendar(setMonth(this.nativeDate, month));
    }

    addMonths(amount: number): DateFnsCalendar {
        return new DateFnsCalendar(addMonths(this.nativeDate, amount));
    }

    setDay(day: number, options?: { weekStartsOn: WeekDayIndex }): DateFnsCalendar {
        return new DateFnsCalendar(setDay(this.nativeDate, day, options));
    }

    setDate(amount: number): DateFnsCalendar {
        const date = new Date(this.nativeDate);
        date.setDate(amount);
        return new DateFnsCalendar(date);
    }

    addDays(amount: number): DateFnsCalendar {
        return this.setDate(this.getDate() + amount);
    }

    isSame(date: CalendarDateType, grain: CalendarDateCompareGrain = 'day'): boolean {
        let fn;
        switch (grain) {
            case 'year':
                fn = isSameYear;
                break;
            case 'month':
                fn = isSameMonth;
                break;
            case 'day':
                fn = isSameDay;
                break;
            case 'hour':
                fn = isSameHour;
                break;
            case 'minute':
                fn = isSameMinute;
                break;
            case 'second':
                fn = isSameSecond;
                break;
            default:
                fn = isSameDay;
                break;
        }
        return fn(this.nativeDate, this.toNativeDate(date));
    }

    isSameYear(date: CalendarDateType): boolean {
        return this.isSame(date, 'year');
    }

    isSameMonth(date: CalendarDateType): boolean {
        return this.isSame(date, 'month');
    }

    isSameDay(date: CalendarDateType): boolean {
        return this.isSame(date, 'day');
    }

    isSameHour(date: CalendarDateType): boolean {
        return this.isSame(date, 'hour');
    }

    isSameMinute(date: CalendarDateType): boolean {
        return this.isSame(date, 'minute');
    }

    isSameSecond(date: CalendarDateType): boolean {
        return this.isSame(date, 'second');
    }

    compare(date: CalendarDateType, grain: CalendarDateCompareGrain = 'day', isBefore: boolean = true): boolean {
        if (date === null) {
            return false;
        }
        let fn;
        switch (grain) {
            case 'year':
                fn = differenceInCalendarYears;
                break;
            case 'month':
                fn = differenceInCalendarMonths;
                break;
            case 'day':
                fn = differenceInCalendarDays;
                break;
            case 'hour':
                fn = differenceInHours;
                break;
            case 'minute':
                fn = differenceInMinutes;
                break;
            case 'second':
                fn = differenceInSeconds;
                break;
            default:
                fn = differenceInCalendarDays;
                break;
        }
        return isBefore ? fn(this.nativeDate, this.toNativeDate(date)) < 0 : fn(this.nativeDate, this.toNativeDate(date)) > 0;
    }

    isBeforeYear(date: CalendarDateType): boolean {
        return this.compare(date, 'year');
    }

    isBeforeMonth(date: CalendarDateType): boolean {
        return this.compare(date, 'month');
    }

    isBeforeDay(date: CalendarDateType): boolean {
        return this.compare(date, 'day');
    }

    isBeforeHour(date: CalendarDateType): boolean {
        return this.compare(date, 'hour');
    }

    isBeforeMinute(date: CalendarDateType): boolean {
        return this.compare(date, 'minute');
    }

    isBeforeSecond(date: CalendarDateType): boolean {
        return this.compare(date, 'second');
    }

    isAfterYear(date: CalendarDateType): boolean {
        return this.compare(date, 'year', false);
    }

    isAfterMonth(date: CalendarDateType): boolean {
        return this.compare(date, 'month', false);
    }

    isAfterDay(date: CalendarDateType): boolean {
        return this.compare(date, 'day', false);
    }

    isAfterHour(date: CalendarDateType): boolean {
        return this.compare(date, 'hour', false);
    }

    isAfterMinute(date: CalendarDateType): boolean {
        return this.compare(date, 'minute', false);
    }

    isAfterSecond(date: CalendarDateType): boolean {
        return this.compare(date, 'second', false);
    }

    isToday(): boolean {
        return isToday(this.nativeDate);
    }

    isValid(): boolean {
        return isValid(this.nativeDate);
    }

    isFirstDayOfMonth(): boolean {
        return isFirstDayOfMonth(this.nativeDate);
    }

    isLastDayOfMonth(): boolean {
        return isLastDayOfMonth(this.nativeDate);
    }

    protected toNativeDate(date: CalendarDateType): Date {
        return date instanceof DateFnsCalendar ? date.nativeDate : date;
    }
}
