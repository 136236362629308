import { ComponentType, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, TemplatePortal } from '@angular/cdk/portal';
import { Injectable, Injector, OnDestroy, Optional, SkipSelf, TemplateRef } from '@angular/core';
import { defer, Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { isPresent } from '../facade';

import { ModalConfirmContainerComponent } from './modal-confirm-container.component';
import { BaseModalContainer } from './modal-container';
import { ModalContainerComponent } from './modal-container.component';
import { ModalRef } from './modal-ref';
import { ConfirmType, DEFAULT_OPTIONS, MODAL_OPTIONS, ModalOptions } from './modal-types';

const MODAL_MASK_CLASS_NAME = 'modal-mask';

type ContentType<T> = ComponentType<T> | TemplateRef<T> | string;

@Injectable()
export class ModalService implements OnDestroy {

    private openModalsAtThisLevel: ModalRef[] = [];

    private readonly afterAllClosedAtThisLevel = new Subject<void>();

    get openModals(): ModalRef[] {
        return this.parentModal ? this.parentModal.openModals : this.openModalsAtThisLevel;
    }

    get _afterAllClosed(): Subject<void> {
        const parent = this.parentModal;
        return parent ? parent._afterAllClosed : this.afterAllClosedAtThisLevel;
    }

    readonly afterAllClose: Observable<void> = defer(() =>
        this.openModals.length ? this._afterAllClosed : this._afterAllClosed.pipe(startWith(true))
    ) as Observable<void>;

    constructor(private overlay: Overlay, private injector: Injector, @Optional() @SkipSelf() private parentModal: ModalService) {
    }

    open<T, R = any>(content: T, config?: ModalOptions<T, R>): ModalRef<T, R> {
        config = Object.assign({}, config, { content });
        return this._open<T, R>(config.content as ComponentType<T>, config);
    }

    closeAll(): void {
        this.closeModals(this.openModals);
    }

    confirm<T>(options: ModalOptions<T> = {}, confirmType: ConfirmType = 'confirm'): ModalRef<T> {
        options.maskClosable = options.maskClosable ?? false;
        options.modalType = 'confirm';
        options.modalClass = `modal-confirm modal-confirm-${confirmType} ${options.modalClass || ''}`;
        return this._open(options.content as ComponentType<T>, options);
    }

    info<T>(options: ModalOptions<T> = {}): ModalRef<T> {
        return this.confirmFactory(options, 'info');
    }

    success<T>(options: ModalOptions<T> = {}): ModalRef<T> {
        return this.confirmFactory(options, 'success');
    }

    error<T>(options: ModalOptions<T> = {}): ModalRef<T> {
        return this.confirmFactory(options, 'error');
    }

    warning<T>(options: ModalOptions<T> = {}): ModalRef<T> {
        return this.confirmFactory(options, 'warning');
    }

    private _open<T, R>(componentOrTemplateRef: ContentType<T>, config?: ModalOptions): ModalRef<T, R> {
        const configMerged = { ...DEFAULT_OPTIONS, ...config };
        const overlayRef = this.createOverlay(configMerged);
        const modalContainer = this.attachModalContainer(overlayRef, configMerged);
        const modalRef = this.attachModalContent<T, R>(componentOrTemplateRef, modalContainer, overlayRef, configMerged);
        modalContainer.modalRef = modalRef;
        this.openModals.push(modalRef);
        modalRef.afterClose.subscribe(() => this.removeOpenModal(modalRef));
        return modalRef;
    }

    private removeOpenModal(modalRef: ModalRef): void {
        const index = this.openModals.indexOf(modalRef);
        if (index > -1) {
            this.openModals.splice(index, 1);

            if (!this.openModals.length) {
                this._afterAllClosed.next(undefined);
            }
        }
    }

    private closeModals(dialogs: ModalRef[]): void {
        let i = dialogs.length;
        while (i--) {
            dialogs[i].close();
            if (!this.openModals.length) {
                this._afterAllClosed.next(undefined);
            }
        }
    }

    private createOverlay(config: ModalOptions): OverlayRef {
        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy: this.overlay.position().global(),
            disposeOnNavigation: config.closeOnNavigation
        });
        if (config.mask) {
            overlayConfig.backdropClass = MODAL_MASK_CLASS_NAME;
        }
        return this.overlay.create(overlayConfig);
    }

    private attachModalContainer(overlayRef: OverlayRef, config: ModalOptions): BaseModalContainer {
        const injector = new PortalInjector(
            config.viewContainerRef?.injector ?? this.injector,
            new WeakMap<any, any>([
                [OverlayRef, overlayRef],
                [MODAL_OPTIONS, config]
            ])
        );

        const ContainerComponent = config.modalType === 'confirm' ? ModalConfirmContainerComponent : ModalContainerComponent;

        const containerPortal = new ComponentPortal<BaseModalContainer>(ContainerComponent, config.viewContainerRef, injector);
        const containerRef = overlayRef.attach<BaseModalContainer>(containerPortal);

        return containerRef.instance;
    }

    private attachModalContent<T, R>(componentOrTemplateRef: ContentType<T>, modalContainer: BaseModalContainer, overlayRef: OverlayRef, config: ModalOptions<T>): ModalRef<T, R> {
        const modalRef = new ModalRef<T, R>(overlayRef, config, modalContainer);

        if (componentOrTemplateRef instanceof TemplateRef) {
            let context: any = { $implicit: config.inputs, modalRef };
            modalContainer.attachTemplatePortal(new TemplatePortal<T>(componentOrTemplateRef, null, context));
        } else if (isPresent(componentOrTemplateRef) && typeof componentOrTemplateRef !== 'string') {
            const injector = this.createInjector<T, R>(modalRef, config);
            const contentRef = modalContainer.attachComponentPortal<T>(new ComponentPortal(componentOrTemplateRef, config.viewContainerRef, injector));
            Object.assign(contentRef.instance, config.inputs);
            modalRef.componentInstance = contentRef.instance;
        }
        return modalRef;
    }

    private createInjector<T, R>(modalRef: ModalRef<T, R>, config: ModalOptions<T>): PortalInjector {
        const injectionTokens = new WeakMap<any, any>([[ModalRef, modalRef]]);
        return new PortalInjector(config?.viewContainerRef?.injector ?? this.injector, injectionTokens);
    }

    private confirmFactory<T>(options: ModalOptions<T> = {}, confirmType: ConfirmType): ModalRef<T> {
        const iconMap = {
            info: 'icon-info-circle',
            success: 'icon-check-circle',
            error: 'icon-close-circle',
            warning: 'icon-exclamation-circle'
        };
        options.iconType = options.iconType ?? iconMap[confirmType];
        if (!('cancelText' in options)) {
            options.cancelText = null;
        }
        return this.confirm(options, confirmType);
    }

    ngOnDestroy(): void {
        this.closeModals(this.openModalsAtThisLevel);
        this.afterAllClosedAtThisLevel.complete();
    }
}
