import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';
import { collapseMotion } from '../animation';
import { MenuModeType } from './menu.types';

@Component({
    selector: '[ai-submenu-inline-child]', // tslint:disable-line
    animations: [collapseMotion],
    exportAs: 'submenuInlineChild',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-template [ngTemplateOutlet]="templateOutlet"></ng-template> `,
    host: {
        '[class.ai-menu]': 'true',
        '[class.ai-menu-inline]': 'true',
        '[class.ai-menu-sub]': 'true',
        '[@collapseMotion]': 'expandState'
    }
})
export class SubmenuInlineChildComponent implements OnInit, OnChanges {

    @Input() templateOutlet: TemplateRef<any> | null = null;
    @Input() menuClass: string = '';
    @Input() mode: MenuModeType = 'vertical';
    @Input() open = false;

    expandState = 'collapsed';

    private cacheClassNames: string[] = [];

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.calcMotionState();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { mode, open, menuClass } = changes;
        if (mode || open) {
            this.calcMotionState();
        }
        if (!menuClass) {
            return;
        }

        if (this.cacheClassNames.length) {
            this.cacheClassNames
                .filter(item => !!item)
                .forEach(className => this.renderer.removeClass(this.elementRef.nativeElement, className));
        }

        if (this.menuClass) {
            this.cacheClassNames = this.menuClass.split(' ');
            this.cacheClassNames
                .filter(item => !!item)
                .forEach(className => this.renderer.addClass(this.elementRef.nativeElement, className));
        }
    }

    private calcMotionState(): void {
        this.expandState = this.open ? 'expanded' : 'collapsed';
    }
}
