import { isPresent, validateError } from 'share';

import { Node } from './node';
import { NodeFormatter } from './node.formatter';
import {
    DefaultXmlValueVisitor,
    DefaultXmlVisitor,
    visitXml,
    visitXmlValue,
    XmlValueVisitor,
    XmlVisitor
} from './xml.visitor';

export class XmlFormatter implements NodeFormatter {

    constructor(private visitor: XmlVisitor = new DefaultXmlVisitor(), private valueVisitor: XmlValueVisitor = new DefaultXmlValueVisitor()) {
    }

    parse(text: string, node: Node): any {
        if (!text) {
            return;
        }
        let dom: XMLDocument;
        try {
            dom = $.parseXML(text);
        } catch (error) {
            console.info(error);
            validateError(`解析xml错误`);
        }
        if (!isPresent(dom)) {
            validateError('必须包含一个根节点');
        }
        visitXml(this.visitor, dom.childNodes[0] as Element, node);
    }

    stringify(node: Node): string {
        return visitXmlValue(this.valueVisitor, node);
    }

}
