import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { IPageService, MAX_PAGE_SIZE } from 'core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Product, ProductEnv, ProductParam, ProductUser } from './product.model';

@Injectable({ providedIn: 'root' })
export class ProductService implements IPageService {

    private baseUrl = '/xltp/prod';

    constructor(private http: HttpClient) {
    }

    qryByPage(params: { page_no?: number; page_size?: number; [p: string]: any }): Observable<any> {
       
        return this.http.get(this.baseUrl, { params });
    }

    qry(params: any): Observable<Array<Product>> {
        // console.log(111111111111111111111,2222222222222,params)
        params.page_no = 1;
        params.page_size = MAX_PAGE_SIZE;
        params.status = 1;
        if(!params.user_id && params.user_id!=0) return
       
        return this.http.get(this.baseUrl, { params }).pipe(map<any, Array<Product>>(page => page.page_data || []));
    }

    qryAll(): Observable<Array<Product>> {
        return this.http.get(`${this.baseUrl}/all`, { params: new HttpParams().set('status', '1') }).pipe(map<any, Array<Product>>(array => array || []));
    }

    add(product: Product): Observable<any> {
        return this.http.post(this.baseUrl, product);
    }

    mod(product: Product): Observable<any> {
        return this.http.put(`${this.baseUrl}/${product.product_id}`, product);
    }

    del(product: Product): Observable<any> {
        return this.http.delete(`${this.baseUrl}/${product.product_id}`);
    }

    qryParams(id: number): Observable<Array<ProductParam>> {
        return this.http.get(`${this.baseUrl}/${id}/param`).pipe(map<any, Array<ProductParam>>(items => items || []));
    }

    addParam(product_id: number, item: ProductParam): Observable<any> {
        return this.http.post(`${this.baseUrl}/${product_id}/param`, item);
    }

    modParam(product_id: number, id: number, item: ProductParam): Observable<any> {
        return this.http.put(`${this.baseUrl}/${product_id}/param/${id}`, item);
    }

    delParam(product_id: number, id: number): Observable<any> {
        let headers = new HttpHeaders().set(Headers.Confirm, '');
        return this.http.delete(`${this.baseUrl}/${product_id}/param/${id}`, { headers });
    }

    qryById(id: number): Observable<Product> {
        return this.http.get(`${this.baseUrl}/${id}`).pipe(map<any, Product>(value => value as Product));
    }

    addEnv(product_id: number, env: ProductEnv): Observable<any> {
        let data = Object.assign({}, env);
        data.params = env.params.filter(v => v.operate_type !== undefined);
        return this.http.post(`${this.baseUrl}/${product_id}/environs`, data);
    }

    modEnv(product_id: number, id: number, env: ProductEnv): Observable<any> {
        let data = Object.assign({}, env);
        data.params = env.params.filter(v => v.operate_type !== undefined);
        return this.http.put(`${this.baseUrl}/${product_id}/environs/${id}`, data);
    }

    qryEnv(product_id: number): Observable<Array<ProductEnv>> {
        return this.http.get(`${this.baseUrl}/${product_id}/environs`).pipe(map<any, Array<ProductEnv>>(items => items || []));
    }

    delEnv(product_id: number, env_id: number): Observable<any> {
        return this.http.delete(`${this.baseUrl}/${product_id}/environs/${env_id}`);
    }

    delUser(product_id: number, user_id: number): Observable<any> {
        let headers = new HttpHeaders().set(Headers.Confirm, '确定退出项目组');
        return this.http.delete(`${this.baseUrl}/${product_id}/relation/${user_id}`, { headers });
    }

    modUser(product_id: number, user: ProductUser): Observable<any> {
        return this.http.put(`${this.baseUrl}/${product_id}/relation/${user.user_id}`, user);
    }

    testDb(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/testenv`, data, { headers: new HttpHeaders().set(Headers.BLOCK, '正在测试数据库连接状态') });
    }

    testRedis(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/testredis`, data, { headers: new HttpHeaders().set(Headers.BLOCK, '正在测试redis连接状态') });
    }

    updateCurrentProduct(user_id: number, product_id: number): Observable<any> {
        return this.http.post(`${this.baseUrl}/user/${user_id}/current`, product_id);
    }

    qryCurrentProduct(user_id: number): Observable<Product> {
        return this.http.get(`${this.baseUrl}/user/${user_id}/current`);
    }

    qryByExternal(params: any): Observable<Array<Product>> {
        return this.http.get<Array<Product>>(`${this.baseUrl}/sync/list`, { params });
    }
}
