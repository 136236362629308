import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateHelperService } from '../datepicker/datepicker-helper';
import { isPresent } from '../facade';
import { ScrollbarComponent } from '../scrollbar/scrollbar.component';

import { TimeHolder } from './time-holder';

function makeRange(length: number, step: number = 1, start: number = 0): number[] {
    return new Array(Math.ceil(length / step)).fill(0).map((_, i) => (i + start) * step);
}

export type TimePickerUnit = 'hour' | 'minute' | 'second' | '12-hour';

@Component({
    selector: 'time-picker-panel', //tslint:disable-line
    exportAs: 'timePickerPanel',
    templateUrl: 'time-picker-panel.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: TimePickerPanelComponent, multi: true }]
})
export class TimePickerPanelComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @Input() inDatePicker: boolean = false;
    @Input() hideDisabledOptions = false;

    @Output() readonly closePanel = new EventEmitter<void>();

    time = new TimeHolder();
    panelHeight: number;
    hourEnabled = true;
    minuteEnabled = true;
    secondEnabled = true;
    firstScrolled = false;
    hourRange: ReadonlyArray<{ index: number; disabled: boolean }>;
    minuteRange: ReadonlyArray<{ index: number; disabled: boolean }>;
    secondRange: ReadonlyArray<{ index: number; disabled: boolean }>;
    use12HoursRange: ReadonlyArray<{ index: number; value: string }>;

    @ViewChild('hourScrollbar')
    private hourScrollbar: ScrollbarComponent;

    @ViewChild('minuteScrollbar')
    private minuteScrollbar: ScrollbarComponent;

    @ViewChild('secondScrollbar')
    private secondScrollbar: ScrollbarComponent;

    private _hourStep = 1;
    private _minuteStep = 1;
    private _secondStep = 1;
    private _onChange: (value: Date) => void;
    private _onTouch: () => void;
    private _format = 'HH:mm:ss';
    private _use12Hours = false;
    private _defaultOpenValue: Date;
    private _disabledHours: () => number[];
    private _disabledMinutes: (hour: number) => number[];
    private _disabledSeconds: (hour: number, minute: number) => number[];
    private _destroy = new Subject<void>();

    constructor(private cdRef: ChangeDetectorRef,
                private zone: NgZone,
                private elementRef: ElementRef<HTMLElement>,
                private dateHelper: DateHelperService) {
        this.zone.onStable.pipe(takeUntil(this._destroy)).subscribe(() => this.resetHeight());
    }

    get formatStr(): string {
        return this.dateHelper.format(this.time?.value, this._format);
    }

    @Input()
    set disabledHours(value: () => number[]) {
        this._disabledHours = value;
        this.buildHours();
    }

    @Input()
    set disabledMinutes(value: (hour: number) => number[]) {
        this._disabledMinutes = value;
        this.buildMinutes();
    }


    @Input()
    set disabledSeconds(value: (hour: number, minute: number) => number[]) {
        this._disabledSeconds = value;
        this.buildSeconds();
    }

    @Input()
    set format(value: string) {
        if (!isPresent(value)) {
            return;
        }
        this._format = value;
        const charSet = new Set(value);
        this.hourEnabled = charSet.has('H') || charSet.has('h');
        this.minuteEnabled = charSet.has('m');
        this.secondEnabled = charSet.has('s');
        if (this._use12Hours) {
            this.build12Hours();
        }
    }

    get use12Hours(): boolean {
        return this._use12Hours;
    }

    @Input()
    set use12Hours(value: boolean) {
        if (!isPresent(value)) {
            return;
        }
        this._use12Hours = value;
        this.time.setUse12Hours(value);
        this.build12Hours();
    }

    @Input()
    set hourStep(value: number) {
        if (!isPresent(value)) {
            return;
        }
        this._hourStep = value;
        this.buildHours();
    }

    @Input()
    set minuteStep(value: number) {
        if (!isPresent(value)) {
            return;
        }
        this._minuteStep = value;
        this.buildMinutes();
    }


    @Input()
    set secondStep(value: number) {
        if (!isPresent(value)) {
            return;
        }
        this._secondStep = value;
        this.buildSeconds();
    }

    @Input()
    set defaultOpenValue(value: Date) {
        this._defaultOpenValue = value;
        this.time.setDefaultOpenValue(this._defaultOpenValue || new Date());
    }

    ngOnInit(): void {
        this.time.changes.pipe(takeUntil(this._destroy)).subscribe(() => {
            this.changed();
            this.touched();
        });
        this.buildTimes();
        setTimeout(() => {
            this.scrollToTime();
            this.firstScrolled = true;
        });
    }

    writeValue(value: Date): void {
        this.time.setValue(value, this._use12Hours);
        this.buildTimes();

        if (value && this.firstScrolled) {
            this.scrollToTime(120);
        }
        this.cdRef.markForCheck();
    }

    onClickNow(): void {
        const now = new Date();
        if (this.timeDisabled(now)) {
            return;
        }
        this.time.setValue(now);
        this.changed();
        this.closePanel.emit();
    }

    isSelectedHour(hour: { index: number; disabled: boolean }): boolean {
        return hour.index === this.time.viewHours;
    }

    isSelectedMinute(minute: { index: number; disabled: boolean }): boolean {
        return minute.index === this.time.minutes;
    }

    isSelectedSecond(second: { index: number; disabled: boolean }): boolean {
        return second.index === this.time.seconds;
    }

    isSelected12Hours(value: { index: number; value: string }): boolean {
        return value.value.toUpperCase() === this.time.selected12Hours;
    }

    selectHour(hour: { index: number; disabled: boolean }): void {
        this.time.setHours(hour.index, hour.disabled);
        if (!!this._disabledMinutes) {
            this.buildMinutes();
        }
        if (this._disabledSeconds || this._disabledMinutes) {
            this.buildSeconds();
        }
    }

    selectMinute(minute: { index: number; disabled: boolean }): void {
        this.time.setMinutes(minute.index, minute.disabled);
        if (!!this._disabledSeconds) {
            this.buildSeconds();
        }
    }

    selectSecond(second: { index: number; disabled: boolean }): void {
        this.time.setSeconds(second.index, second.disabled);
    }

    select12Hours(value: { index: number; value: string }): void {
        this.time.setSelected12Hours(value.value);
        if (!!this._disabledHours) {
            this.buildHours();
        }
        if (!!this._disabledMinutes) {
            this.buildMinutes();
        }
        if (!!this._disabledSeconds) {
            this.buildSeconds();
        }
    }

    private scrollToTime(delay: number = 0): void {
        if (this.hourEnabled && this.hourScrollbar) {
            this.scrollToSelected(this.hourScrollbar, this.time.viewHours, delay, 'hour');
        }
        if (this.minuteEnabled && this.minuteScrollbar) {
            this.scrollToSelected(this.minuteScrollbar, this.time.minutes, delay, 'minute');
        }
        if (this.secondEnabled && this.secondScrollbar) {
            this.scrollToSelected(this.secondScrollbar, this.time.seconds, delay, 'second');
        }
    }

    private scrollToSelected(scrollbar: ScrollbarComponent, index: number, duration: number = 0, unit: TimePickerUnit): void {
        if (!scrollbar) {
            return;
        }
        const transIndex = this.translateIndex(index, unit);
        const currentOption = this.elementRef.nativeElement.querySelector<HTMLElement>(`[data-id=${unit}${transIndex}]`);
        if (!isPresent(currentOption)) {
            return;
        }
        scrollbar.scrollToTop(currentOption.offsetTop, duration);
    }

    private translateIndex(index: number, unit: TimePickerUnit): number {
        if (unit === 'hour') {
            return this.hourRange.map(item => item.index).indexOf(index);
        }
        if (unit === 'minute') {
            return this.minuteRange.map(item => item.index).indexOf(index);
        }

        return this.secondRange.map(item => item.index).indexOf(index);
    }

    private buildTimes(): void {
        this.buildHours();
        this.buildMinutes();
        this.buildSeconds();
        this.build12Hours();
    }

    private buildHours(): void {
        let hourRanges = 24;
        let disabledHours = this.disabledHours?.();
        let startIndex = 0;
        if (this._use12Hours) {
            hourRanges = 12;
            if (disabledHours) {
                if (this.time.selected12Hours === 'PM') {
                    disabledHours = disabledHours.filter(i => i >= 12).map(i => (i > 12 ? i - 12 : i));
                } else {
                    disabledHours = disabledHours.filter(i => i < 12 || i === 24).map(i => (i === 24 || i === 0 ? 12 : i));
                }
            }
            startIndex = 1;
        }
        this.hourRange = makeRange(hourRanges, this.hourStep, startIndex).map(r => {
            return {
                index: r,
                disabled: disabledHours && disabledHours.indexOf(r) !== -1
            };
        });
        if (this._use12Hours && this.hourRange[this.hourRange.length - 1].index === 12) {
            const temp = [...this.hourRange];
            temp.unshift(temp[temp.length - 1]);
            temp.splice(temp.length - 1, 1);
            this.hourRange = temp;
        }
    }

    private buildMinutes(): void {
        this.minuteRange = makeRange(60, this.minuteStep).map(r => ({
            index: r,
            disabled: this.disabledMinutes?.(this.time.hours).includes(r)
        }));
    }

    private buildSeconds(): void {
        this.secondRange = makeRange(60, this.secondStep).map(r => ({
            index: r,
            disabled: this.disabledSeconds?.(this.time.hours, this.time.minutes).includes(r)
        }));
    }

    private build12Hours(): void {
        const isUpperFormat = this._format.includes('A');
        this.use12HoursRange = [{
            index: 0,
            value: isUpperFormat ? 'AM' : 'am'
        }, {
            index: 1,
            value: isUpperFormat ? 'PM' : 'pm'
        }];
    }

    private changed(): void {
        this._onChange?.(this.time.value);
    }

    private touched(): void {
        this._onTouch?.();
    }

    private timeDisabled(value: Date): boolean {
        const hour = value.getHours();
        const minute = value.getMinutes();
        const second = value.getSeconds();
        return this.disabledHours?.().includes(hour) || this.disabledMinutes?.(hour).includes(minute) || this.disabledSeconds?.(hour, minute).includes(second);
    }

    private resetHeight(): void {
        let height = this.elementRef.nativeElement.clientHeight;
        if (this.panelHeight === height) {
            return;
        }
        this.panelHeight = height;
        this.cdRef.markForCheck();
    }

    registerOnChange(fn: (value: Date) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouch = fn;
    }

    ngOnDestroy(): void {
        this._destroy.next(undefined);
        this._destroy.complete();
    }
}
