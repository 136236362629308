import { Pipe, PipeTransform } from '@angular/core';
import {
    DATA_BATCH_STATUS_KEYS,
    DATA_BATCH_STATUS_NAMES,
    RULE_NAMES, RULE_RIGHTS,
    RULE_TYPES,
    TOOL_TYPE_KEYS,
    TOOL_TYPE_NAMES
} from '../constants';

@Pipe({
    name: 'ruleType'
})
export class RuleTypePipe implements PipeTransform {

    transform(value: any): any {
        return RULE_NAMES[RULE_TYPES.indexOf(value)] || '';
    }
}

@Pipe({
    name: 'ruleRight'
})
export class RuleRightPipe implements PipeTransform {

    transform(value: any): any {
        return RULE_RIGHTS[RULE_TYPES.indexOf(value)] || '';
    }
}

@Pipe({
    name: 'dataBatchStatus'
})
export class DataBatchStatusPipe implements PipeTransform {

    transform(value: any): any {
        return DATA_BATCH_STATUS_NAMES[DATA_BATCH_STATUS_KEYS.indexOf(value)] || '未知';
    }
}


@Pipe({
    name: 'toolType'
})
export class ToolTypePipe implements PipeTransform {

    transform(value: any): any {
        return TOOL_TYPE_NAMES[TOOL_TYPE_KEYS.indexOf(value)];
    }
}
