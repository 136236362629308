import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'file-btn',// tslint:disable-line
    templateUrl: 'file.btn.component.html',
    host: {
        '[attr.tabindex]': '"0"',
        '[attr.role]': '"button"',
        '[class.hand]': 'true',
        '[class.d-inline-block]': 'true'
    },
    preserveWhitespaces: false,
    encapsulation: ViewEncapsulation.None
})

export class FileBtnComponent implements OnInit {

    @Input() accept: string;
    @Input() directory: string;
    @Input() multiple: boolean;

    @ViewChild('file') file: ElementRef;

    constructor() {
    }

    @HostListener('click')
    onClick(): void {
        this.file.nativeElement.click();
    }

    ngOnInit() {
    }
}
