export function getXPathForElement(el: HTMLElement, document: Document) {

    let xpath = '';
    let pos: number, sibling: Node, name: string;

    while (el !== document.documentElement) {
        pos = 0;
        sibling = el;
        while (sibling) {
            if (sibling.nodeType === 1 && sibling.nodeName === el.nodeName) {
                pos += 1;
            }
            sibling = sibling.previousSibling;
        }
        name = el.tagName.toLowerCase();
        if (el.id) {
            xpath = `${name}[@id="${el.id}"]/${xpath}`;
            break;
        }

        if (pos > 0) {
            name += `[${pos}]`;
        }

        xpath = `${name}/${xpath}`;
        el = el.parentNode as HTMLElement;
    }
    return xpath.replace('html[1]/body[1]/', 'html/body/').replace(/\/$/, '');
}


export function getElementOffset(elem: HTMLElement): { top: number; left: number } {
    if (!elem.getClientRects().length) {
        return { top: 0, left: 0 };
    }

    const rect = elem.getBoundingClientRect();
    const win = elem.ownerDocument.defaultView;
    return {
        top: rect.top + win.pageYOffset,
        left: rect.left + win.pageXOffset
    };
}

export function isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
    return event.type.startsWith('touch');
}

export function getEventPosition(event: MouseEvent | TouchEvent): MouseEvent | Touch {
    return isTouchEvent(event) ? event.touches[0] || event.changedTouches[0] : event;
}
