import { ViewportRuler } from '@angular/cdk/overlay';
import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Directive({ selector: '.drawer-body' }) // tslint:disable-line
export class DrawerBodyDirective implements AfterViewInit, OnDestroy {

    private _changeSize$: Subject<any> = new Subject();
    private destroy$: Subject<any> = new Subject();

    constructor(private render: Renderer2, private viewportRuler: ViewportRuler, private elementRef: ElementRef<HTMLElement>) {
    }

    ngAfterViewInit(): void {
        this.viewportRuler.change().pipe(startWith(true), takeUntil(this.destroy$)).subscribe(() => {
            this.gapDrawerBody();
            this._changeSize$.next('');
        });
    }

    get changeSize$(): Observable<any> {
        return this._changeSize$.asObservable();
    }

    private gapDrawerBody(): void {
        let drawerElement = this.findDrawer();
        if (!drawerElement) {
            return;
        }
        if (!drawerElement.classList.contains('drawer-scrollable')) {
            return;
        }
        const gap = 24;
        let drawerHeader = drawerElement.querySelector('.drawer-header');
        let drawerFooter = drawerElement.querySelector('.drawer-footer');
        let marginBottom = (drawerFooter?.getBoundingClientRect().height || 0) + gap;
        let marginTop = drawerHeader ? 0 : gap;
        let minHeight = drawerElement.getBoundingClientRect().height - (drawerHeader?.getBoundingClientRect().height || 0) - marginTop - marginBottom;
        let body = drawerElement.querySelector('.drawer-body');
        this.render.setStyle(body, 'margin-bottom', `${marginBottom}px`);
        this.render.setStyle(body, 'margin-top', `${marginTop}px`);
        // this.render.setStyle(body, 'min-height', `${minHeight}px`);

    }

    private findDrawer(): HTMLElement {
        let element = this.elementRef.nativeElement;
        while (!element.classList.contains('drawer') && !element.tagName.equalsIgnoreCase('body')) {
            element = element.parentElement;
        }
        return element.classList.contains('drawer') ? element : null;
    }

    ngOnDestroy(): void {
        this.destroy$.next('');
        this.destroy$.complete();
    }
}
