import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeKeyPrefix } from 'core';
import { flatNodes, InputBoolean, isPresent, TreeNodeOptions } from 'share';
import { Group, GroupType } from 'app/group/group.model';
import { buildGroupTree } from 'app/group/group.util';
import { GlobalHolder } from 'app/global.holder';
import { UserGroupService } from 'app/product/group/user.group.service';


@Component({
    selector: 'ai-user-group-select',
    templateUrl: 'user.group.select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => UserGroupSelectComponent),
        multi: true
    }]
})
export class UserGroupSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @Input() filter: (item: Group) => boolean;
    @Input() @InputBoolean() multiple: boolean;

    checkedKeys: string[];
    nodes: TreeNodeOptions[] = [];

    private onChange: any;

    constructor(private cdRef: ChangeDetectorRef,
                private global: GlobalHolder,
                private groupService: UserGroupService) {
        this.filter = () => true;
    }

    ngOnInit() {
        this.groupService.qryGroup(this.global.product_id, GroupType.USER).subscribe(items => {
            let nodes = buildGroupTree(items.filter(this.filter));
            flatNodes(nodes).forEach(node => node.isLeaf = !node.children?.length);
            this.nodes = nodes;
            this.cdRef.markForCheck();
        });
    }

    change(): void {
        const value = this.checkedKeys
            .map(key => key?.replace(TreeKeyPrefix.GROUP, ''))
            .join(',');
        this.onChange(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.checkedKeys = isPresent(obj)
            ? obj?.split(',')?.map(val => `${TreeKeyPrefix.GROUP}${val}`)
            : [];
        this.cdRef.markForCheck();
    }

    ngOnDestroy(): void {
    }
}
