import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { ModalService } from 'share/modal';
import { TooltipDirective } from '../tooltip';

@Component({
    selector: 'ai-del',
    templateUrl: 'del.component.html',
    styleUrls: ['del.scss'],
    host: {
        '[class.icon-wrapper]': 'true'
    }
})

export class DelComponent extends TooltipDirective implements OnInit, OnDestroy {

    @Input() confirmTip: string;
    @Input() icon: string;
    @Input() disable: boolean;

    @Input('tip') directiveNameTitle?: string | TemplateRef<void>;

    @Output() ngDel: EventEmitter<any> = new EventEmitter<any>(true);

    constructor(elementRef: ElementRef,
                hostView: ViewContainerRef,
                resolver: ComponentFactoryResolver,
                renderer: Renderer2,
                private modal: ModalService) {
        super(elementRef, hostView, resolver, renderer);
        this.confirmTip = '您确定删除该项吗？';
        // this.directiveNameTitle = '删除';
        this.icon = 'icon-delete';
    }

    ngOnInit() {
    }

    @HostListener('click')
    confirm(): void {
        if (this.disable) {
            return;
        }
        this.modal.confirm({ title: this.confirmTip }).result.subscribe(() => this.ngDel.emit());
    }

    ngOnDestroy() {
    }
}
