import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PAGE_INITIALIZERS } from 'app/ui/page/initializer';
import { PageInitializerService } from 'app/ui/page/initializer/initializer.service';
import { PAGE_SUBMIT_PROCESSORS } from 'app/ui/page/submit';
import { PageSubmitService } from 'app/ui/page/submit/submit.service';
import { UIPage } from 'app/ui/page/ui.page.model';
import { transformToPlugin } from 'app/ui/page/util';
import { GlobalHolder } from 'app/global.holder';
import { UIPageStepHolder } from 'app/ui/page/step/ui.page.step.holder';
import { ValidateRegistry } from 'core';


@Component({
    selector: 'ai-ui-page-edit',
    templateUrl: 'ui.page.edit.component.html',
    styleUrls: ['ui.page.edit.scss'],
    providers: [
        PageInitializerService,
        PageSubmitService,
        UIPageStepHolder,
        ValidateRegistry,
        ...PAGE_INITIALIZERS,
        ...PAGE_SUBMIT_PROCESSORS
    ]
})

export class UIPageEditComponent implements OnInit, OnDestroy {

    @Input() editable = true;
    @Input() item: UIPage;
    @Input() isModal: Boolean;

    constructor(private initializerService: PageInitializerService,
                private global: GlobalHolder,
                private holder: UIPageStepHolder,
                private registry: ValidateRegistry,
                private submitService: PageSubmitService) {
    }

    ngOnInit() {
        this.initializerService.init(this.item).subscribe(context => {
            this.item = context;
            this.holder.updateScript(this.item.script);
        });
    }

    record(): void {
        this.registry.validate();
        this.item.product_id = this.global.product_id;
        window.postMessage({
            key: 'AWAKE',
            data: transformToPlugin(this.item, this.holder.script.getValue())
        }, '*');
    }

    submit(): void {
        this.submitService.submit(this.item);
    }

    ngOnDestroy(): void {
    }
}
