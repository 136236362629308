import { Pipe, PipeTransform } from '@angular/core';
import { PERF_RESULT_KEYS, PERF_RESULT_NAMES } from '../constants/perf';
import { isPresent } from 'share';

@Pipe({
    name: 'perfTaskStatus'
})
export class PerfTaskStatusPipe implements PipeTransform {

    transform(status: any): string {
        return PERF_RESULT_NAMES[PERF_RESULT_KEYS.indexOf(status)] || '未知';
    }
}

@Pipe({
    name: 'perfPercent'
})
export class PerfPercentPipe implements PipeTransform {

    transform(value: number, precision = 2): string {
        if (!isPresent(value) || isNaN(value)) {
            return '0.00%'
        }
        return `${Math.floor(value * 100 * 10 ** 2 ) / 100}%`
    }
}