import { UIPage, UIPageStep } from 'app/ui/page/ui.page.model';
import { Checkable, isNonEmptyString } from 'share';


export const PAGE_STEP_CACHE = new Map<string, any>();

export type UIStep = UIPageStep & UIPage & Checkable;

export type UIStepWidgetType = undefined | 'rich' | 'input' | 'page' | 'array' | 'elements' | 'select';

export interface FormatStepEvent {
    type: string;
    data?: any;
    params?: any;
}

export interface UIStepOperateConfig {
    label?: string;
    value?: UIStepWidgetType;
    target?: UIStepWidgetType;
    targetPlaceholder?: string;
    valuePlaceholder?: string;
    flip?: boolean;
    group?: string;
}

export function parseParams(steps: UIStep[] = []): string[] {
    const params: Set<string> = new Set();
    for (let step of steps) {
        if (step?.operate?.includes('store')) {
            params.add(step.value);
        } else if (step.value && step.value.startsWith('${') && step.value.endsWith('}')) {
            params.add(/\${([\w\W]+?)}/g.exec(step?.value)?.[1]);
        }
        if (step.operate === 'component' && step.steps?.length > 0) {
            for (let config of step.steps) {
                if (config.value && config.value.startsWith('${') && config.value.endsWith('}')) {
                    params.add(/\${([\w\W]+?)}/g.exec(config?.value)?.[1]);
                }
            }
        }
    }
    params.delete('');
    params.delete(undefined);
    params.delete(null);
    return [...params];
}

export function calcHierarchy(steps: Array<UIStep> = [], diffHierarchy = 0): Array<UIStep> {
    if (!steps.length) {
        return [];
    }

    const items: UIStep[] = [];
    steps.forEach((step, index) => {
        step.hierarchy = diffHierarchy;
        step.parent_id = -1;
        step.step_index = index;
    });

    const starts: number[] = [];
    steps.forEach((step, index) => {
        if (isHierarchyEnd(step.operate) && starts.length > 0) {
            const hierarchy = starts.length + diffHierarchy;
            const startIndex = starts.pop();
            const start = steps[startIndex];
            start.hierarchy = hierarchy - 1;
            for (let j = startIndex + 1; j < index; j++) {
                const updateStep = steps[j];
                updateStep.parent_id = updateStep.parent_id > -1 ? updateStep.parent_id : start.id;
                updateStep.hierarchy = updateStep.hierarchy > diffHierarchy ? updateStep.hierarchy : hierarchy;
            }
            step.hierarchy = hierarchy - 1;
            step.parent_id = start.parent_id;
        }
        if (isHierarchyStart(step.operate)) {
            starts.push(index);
        }
        items.push(step);
    });
    items.filter(step => isComp(step)).forEach(step => {
        step.children = calcHierarchy(step.children, step.hierarchy + 1);
    });
    return items;
}

export function convertToScript(item: any): string {
    let script = '{}';
    try {
        let max_id = item?.max_id ?? 1;
        item?.steps.forEach((step, index) => {
            step.step_index = index;
            delete step.checked;
            if (!step.id) {
                step.id = max_id++;
            }
            if (isComp(step)) {
                step.opened = false;
                step.children = [];
            }
        });
        item.steps = calcHierarchy(item?.steps);
        item.max_id = max_id;
        script = JSON.stringify(item);
    } catch (e) {
        console.log(e);
    }
    return script;
}

export function parseScript(value: string): any {
    let obj = {};
    try {
        obj = JSON.parse(value);
    } catch (e) {
        console.log(e);
    }
    return obj;
}

export function isComp(step: UIStep): boolean {
    return isNonEmptyString(step.operate) && step.operate === 'component';
}

function isHierarchyStart(operate: string): boolean {
    return [
        'if',
        'while',
        'else if',
        'else',
        'do',
        'try',
        'finally',
        'catch exception',
        'times',
        'for each',
        'if editable',
        'if not editable',
        'if visible',
        'if not visible',
        'if present',
        'if not present',
        'if checked',
        'if not checked'
    ].includes(operate);
}

function isHierarchyEnd(operate: string): boolean {
    return [
        'else if',
        'else',
        'end',
        'finally',
        'catch exception',
        'repeat if'
    ].includes(operate);
}

export function convertKey(key: string, convert: boolean): string {
    if (!convert) {
        return key;
    }
    if (key === 'target') {
        return 'value';
    }
    if (key === 'value') {
        return 'target';
    }
}

export const OPERATE_DESC = new Map<string, any>();

export const OPERATE_MAP = new Map<string, UIStepOperateConfig>([
    // 基本
    ['open', { label: 'open', value: 'input', group: '基本' }],
    ['click', { label: 'click', target: 'elements', group: '基本' }],
    ['type', { label: 'type', target: 'elements', value: 'input', group: '基本' }],
    ['sleep', { label: 'sleep', value: 'input', group: '基本' }],
    ['component', { label: 'component', target: 'page', group: '基本' }],
    ['store', { label: 'store', target: 'input', value: 'input', flip: true, group: '基本' }],
    ['store component param', { label: 'store component param', target: 'select', value: 'input', flip: true, group: '基本' }],
    ['check', { label: 'check', target: 'rich', group: '基本' }],
    ['uncheck', { label: 'uncheck', target: 'rich', group: '基本' }],
    ['close', { label: 'close', value: 'input', group: '基本' }],
    ['double click', { label: 'double click', target: 'elements', group: '基本' }],
    ['content click', { label: 'content click', target: 'elements', group: '基本' }],
    ['oper log', { label: 'oper log', value: 'rich', group: '基本' }],
    ['edit content', { label: 'edit content', target: 'elements', value: 'rich', group: '基本' }],
    ['alert accept', { label: 'alert accept', group: '基本' }],
    ['alert dismiss', { label: 'alert dismiss', group: '基本' }],
    ['click js', { label: 'click js', target: 'elements', group: '基本' }],
    ['wait for load', { label: 'wait for load', group: '基本' }],

    // 逻辑分支
    ['if', { label: 'if', value: 'rich', group: '逻辑分支' }],
    ['else if', { label: 'else if', value: 'rich', group: '逻辑分支' }],
    ['else', { label: 'else', group: '逻辑分支' }],
    ['end', { label: 'end', group: '逻辑分支' }],
    ['while', { label: 'while', value: 'rich', group: '逻辑分支' }],
    ['repeat if', { label: 'repeat if', value: 'rich', group: '逻辑分支' }],
    ['do', { label: 'do', group: '逻辑分支' }],
    ['for each', { label: 'for each', target: 'array', value: 'input', group: '逻辑分支' }],
    ['times', { label: 'times', value: 'input', group: '逻辑分支' }],
    ['try', { label: 'try', group: '逻辑分支' }],
    ['catch exception', { label: 'catch exception', group: '逻辑分支' }],
    ['finally', { label: 'finally', group: '逻辑分支' }],
    ['exception', { label: 'exception', value: 'rich', group: '逻辑分支' }],
    ['exception cause', { label: 'exception cause', value: 'rich', group: '逻辑分支' }],
    ['if editable', { label: 'if editable', target: 'elements', group: '逻辑分支' }],
    ['if not editable', { label: 'if not editable', target: 'elements', group: '逻辑分支' }],
    ['if visible', { label: 'if visible', target: 'elements', group: '逻辑分支' }],
    ['if not visible', { label: 'if not visible', target: 'elements', group: '逻辑分支' }],
    ['if present', { label: 'if present', target: 'elements', group: '逻辑分支' }],
    ['if not present', { label: 'if not present', target: 'elements', group: '逻辑分支' }],
    ['if checked', { label: 'if checked', target: 'elements', group: '逻辑分支' }],
    ['if not checked', { label: 'if not checked', target: 'elements', group: '逻辑分支' }],

    // 高级
    ['select', { label: 'select', target: 'elements', value: 'input', group: '高级' }],
    ['deselect', { label: 'deselect', target: 'rich', value: 'input', group: '高级' }],
    ['deselect all', { label: 'deselect all', target: 'elements', group: '高级' }],
    ['drag and drop', { label: 'drag and drop', target: 'elements', value: 'rich', group: '高级' }],
    ['execute script', { label: 'execute script', target: 'rich', value: 'input', group: '高级' }],
    ['execute async script', { label: 'execute async script', target: 'rich', value: 'input', group: '高级' }],
    ['mouse up', { label: 'mouse up', target: 'elements', group: '高级' }],
    ['mouse down', { label: 'mouse down', target: 'elements', group: '高级' }],
    ['mouse move to', { label: 'mouse move to', target: 'elements', group: '高级' }],
    ['run script', { label: 'run script', value: 'rich', group: '高级' }],
    ['select frame', { label: 'select frame', target: 'elements', group: '高级' }],
    ['select frame default', { label: 'select frame default', group: '高级' }],
    ['select frame index', { label: 'select frame index', value: 'input', group: '高级' }],
    ['select frame parent', { label: 'select frame parent', group: '高级' }],
    ['select window', { label: 'select window', value: 'input', group: '高级' }],
    ['send keys', { label: 'send keys', target: 'elements', value: 'rich', group: '高级' }],
    ['set window size', { label: 'set window size', value: 'input', group: '高级' }],
    ['alert answer', { label: 'alert answer', value: 'input', group: '高级' }],
    ['alert text', { label: 'alert text', value: 'input', group: '高级' }],
    ['move by offset', { label: 'move by offset', target: 'elements', value: 'rich', group: '高级' }],
    ['scroll to element', { label: 'scroll to element', target: 'elements', value: 'elements', group: '高级' }],
    ['file', { label: 'file', target: 'elements', value: 'rich', group: '高级' }],
    ['code', { label: 'code', value: 'rich', group: '高级' }],
    ['focus', { label: 'focus', target: 'elements', group: '高级' }],
    ['scroll to', { label: 'scroll to', target: 'elements', value: 'input', group: '高级' }],
    ['scroll by', { label: 'scroll by', target: 'elements', value: 'input', group: '高级' }],

    // 赋值
    ['store attribute', { label: 'store attribute', target: 'elements', value: 'input', flip: true, group: '赋值' }],
    ['store json', { label: 'store json', target: 'rich', value: 'input', flip: true, group: '赋值' }],
    ['store title', { label: 'store title', value: 'input', flip: true, group: '赋值' }],
    ['store value', { label: 'store value', target: 'elements', value: 'input', flip: true, group: '赋值' }],
    ['store xpath count', { label: 'store xpath count', target: 'elements', value: 'input', flip: true, group: '赋值' }],
    ['store text', { label: 'store text', target: 'elements', value: 'input', flip: true, group: '赋值' }],
    ['store window handle', { label: 'store window handle', value: 'input', flip: true, group: '赋值' }],
    ['store element is exist', { label: 'store element is exist', target: 'elements', value: 'input', flip: true, group: '赋值' }],
    ['add pre chars', { label: 'add pre chars', target: 'input', value: 'input', group: '赋值' }],
    ['add post chars', { label: 'add post chars', target: 'input', value: 'input', group: '赋值' }],

    // 等待
    ['wait for element editable', { label: 'wait for element editable', target: 'elements', value: 'input', group: '等待' }],
    ['wait for element not editable', { label: 'wait for element not editable', target: 'elements', value: 'input', group: '等待' }],
    ['wait for element visible', { label: 'wait for element visible', target: 'elements', value: 'input', group: '等待' }],
    ['wait for element not visible', { label: 'wait for element not visible', target: 'elements', value: 'input', group: '等待' }],
    ['wait for element present', { label: 'wait for element present', target: 'elements', value: 'input', group: '等待' }],
    ['wait for element not present', { label: 'wait for element not present', target: 'elements', value: 'input', group: '等待' }],

    // 断言
    ['assert', { label: 'assert', target: 'rich', value: 'input', group: '断言' }],
    ['assert alert', { label: 'assert alert', target: 'input', value: 'input', group: '断言' }],
    ['assert checked', { label: 'assert checked', target: 'elements', group: '断言' }],
    ['assert not checked', { label: 'assert not checked', target: 'elements', group: '断言' }],
    ['assert confirmation', { label: 'assert confirmation', target: 'input', group: '断言' }],
    ['assert editable', { label: 'assert editable', target: 'elements', group: '断言' }],
    ['assert not editable', { label: 'assert not editable', target: 'elements', group: '断言' }],
    ['assert element present', { label: 'assert element present', target: 'elements', group: '断言' }],
    ['assert element not present', { label: 'assert element not present', target: 'elements', group: '断言' }],
    ['assert selected value', { label: 'assert selected value', target: 'elements', value: 'input', group: '断言' }],
    ['assert not selected value', { label: 'assert not selected value', target: 'elements', value: 'input', group: '断言' }],
    ['assert text', { label: 'assert text', target: 'elements', value: 'input', group: '断言' }],
    ['assert not text', { label: 'assert not text', target: 'elements', value: 'input', group: '断言' }],
    ['assert title', { label: 'assert title', value: 'input', group: '断言' }],
    ['assert value', { label: 'assert value', target: 'elements', value: 'input', group: '断言' }]

]);