import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalHolder } from 'app/global.holder';
import { GuardInitializer } from 'app/guard/guard.initializer';
import { ProductGlobalService } from 'app/product/product.global.service';
import { ProductService } from 'app/product/product.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class ProductGuardInitializer implements GuardInitializer {

    constructor(private router: Router,
                private global: GlobalHolder,
                private productService: ProductService,
                private productGlobal: ProductGlobalService) {
    }

    init(): Observable<boolean> {
        return this.productService.qryCurrentProduct(this.global.user_id).pipe(switchMap(item => {
            if (!isPresent(item) || item.tenant_code !== this.global.tenant_code) {
                // this.router.navigate(['pages/product']);
                this.router.navigate(['pages/workbench']);
                return of(false);
            }
            this.global.updateProduct(item);
            return this.productGlobal.initGlobal(item.product_id, this.global);
        }));
    }
}
