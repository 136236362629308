import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    Input,
    Optional,
    Renderer2,
    SkipSelf,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { IsMenuInsideDropDownToken } from './menu.token';

export function MenuGroupFactory(isMenuInsideDropDownToken: boolean): boolean {
    return isMenuInsideDropDownToken ? isMenuInsideDropDownToken : false;
}

@Component({
    selector: '[ai-menu-group]',//tslint:disable-line
    exportAs: 'menuGroup',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: IsMenuInsideDropDownToken,
            useFactory: MenuGroupFactory,
            deps: [[new SkipSelf(), new Optional(), IsMenuInsideDropDownToken]]
        }
    ],
    encapsulation: ViewEncapsulation.None,
    template: `
        <div #titleElement
             [class.ai-menu-item-group-title]="!isMenuInsideDropDown"
             [class.ai-dropdown-menu-item-group-title]="isMenuInsideDropDown">
            <ng-container *stringTemplateOutlet="title">{{ title }}</ng-container>
            <ng-content select="[title]" *ngIf="!title"></ng-content>
        </div>
        <ng-content></ng-content>      `,
    preserveWhitespaces: false
})
export class MenuGroupComponent implements AfterViewInit {

    @Input() title?: string | TemplateRef<void>;
    @ViewChild('titleElement') titleElement?: ElementRef;

    constructor(
        public elementRef: ElementRef,
        private renderer: Renderer2,
        @Inject(IsMenuInsideDropDownToken) public isMenuInsideDropDown: boolean
    ) {
        const className = this.isMenuInsideDropDown ? 'ai-dropdown-menu-item-group' : 'ai-menu-item-group';
        this.renderer.addClass(elementRef.nativeElement, className);
    }

    ngAfterViewInit(): void {
        const ulElement = this.titleElement.nativeElement.nextElementSibling;
        if (ulElement) {
            const className = this.isMenuInsideDropDown ? 'ai-dropdown-menu-item-group-list' : 'ai-menu-item-group-list';
            this.renderer.addClass(ulElement, className);
        }
    }
}
