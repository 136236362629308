import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BackComponent } from './back.component';
import { BackDrawerComponent } from './back.drawer.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
@NgModule({
    imports: [
        CommonModule,
        NzIconModule
    ],
    exports: [
        BackComponent,
        BackDrawerComponent
    ],
    declarations: [
        BackComponent,
        BackDrawerComponent
    ]
})
export class BackModule {
}
