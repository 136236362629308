let JSON = require('json-bigint')({
    useNativeBigInt: false,
    alwaysParseAsBig: true
});

export class JSONBig {

    static parse(text: string, reviver?: (this: any, key: string, value: any) => any): any {
        return JSON.parse(text, reviver);
    }

    static stringify(value: any, replacer?: (this: any, key: string, value: any) => any, space?: string | number): string {
        return JSON.stringify(value, replacer, space);
    }
}
