import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CellTooltipDirective } from './cell.tooltip';


@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        CellTooltipDirective
    ],
    declarations: [
        CellTooltipDirective
    ]
})
export class TableModule {
}
