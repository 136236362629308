import { Injectable } from '@angular/core';

import { format, getISOWeek, parse } from 'date-fns';
import { DatepickerI18n } from './datepicker-i18n';


export function DATEPICKER_HELPER_FACTORY(i18n: DatepickerI18n) {
    return new DateFnsDateHelperService(i18n);
}

@Injectable({
    providedIn: 'root',
    useFactory: DATEPICKER_HELPER_FACTORY,
    deps: [DatepickerI18n]
})
export abstract class DateHelperService {

    abstract getISOWeek(date: Date): number;

    abstract parse(value: string, formatStr?: string): Date;

    abstract format(date: Date, formatStr?: string): string;
}

@Injectable()
export class DateFnsDateHelperService extends DateHelperService {

    constructor(private i18n: DatepickerI18n) {
        super();
    }

    getISOWeek(date: Date): number {
        try {
            return getISOWeek(date);
        } catch (e) {
        }
    }

    parse(value: string, formatStr?: string): Date {
        try {
            let date = parse(value, formatStr ?? this.i18n.getDateFormat(), new Date(), { locale: this.i18n.getDateLocal() });
            return date.getTime() ? date : null;
        } catch (e) {
        }
        return null;
    }

    format(date: Date, formatStr?: string): string {
        try {
            return format(date, formatStr ?? this.i18n.getDateFormat(), { locale: this.i18n.getDateLocal() });
        } catch (e) {
        }
        return '';
    }
}
