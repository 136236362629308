import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DownloadComponent } from './download.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        DownloadComponent
    ],
    declarations: [
        DownloadComponent
    ]
})
export class DownloadModule {
}
