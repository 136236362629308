import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const INITIALIZER = new InjectionToken<Array<Initializer<any>>>('Initializer');

export interface Initializer<T> {
    init(context: T): Observable<any>;
}

export interface FilterInitializer<T> extends Initializer<T> {
    support(context: T): boolean;
}
