// tslint:disable
import {
    Directive,
    DoCheck,
    ElementRef,
    Input,
    KeyValueDiffer,
    KeyValueDiffers,
    OnDestroy,
    OnInit
} from '@angular/core';
import { EventNames } from 'app/app.constants';

import * as ECharts from 'echarts/lib/echarts';
import 'echarts/lib/chart/graph';
import 'echarts/lib/chart/tree';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { getBaseFontSize, isPresent } from '../facade';

import { EventManager } from '../providers';
import { registerTheme } from './theme';

registerTheme();

@Directive({
    selector: '[echarts]'
})
export class EchartsDirective implements OnDestroy, DoCheck, OnInit {

    @Input('echarts') options: any;

    private chart: any;
    private currentWidth: number;
    private currentHeight: number;
    private differ: KeyValueDiffer<any, any>;

    private resize$ = new Subject();
    private draw$ = new Subject();

    private subscriptions: Array<Subscription> = [];

    constructor(private el: ElementRef,
                private eventManager: EventManager,
                private differs: KeyValueDiffers) {
        this.differ = differs.find({}).create();
    }

    ngOnInit(): void {
        let resize$ = this.resize$.pipe(debounceTime(200)).subscribe(() => this.chart.resize());
        this.subscriptions.push(resize$);

        let draw$ = this.draw$.pipe(debounceTime(200)).subscribe(() => this.draw(this.options));
        this.subscriptions.push(draw$);

        resize$ = this.eventManager.subscribe(EventNames.RESIZE_CHART, () => this.resize());
        this.subscriptions.push(resize$);
    }


    resize() {
        if (!this.chart || !this.options) {
            return;
        }
        this.resize$.next('');
    }

    ngDoCheck() {
        if (this.currentWidth != this.el.nativeElement.offsetWidth) {
            this.resize();
            this.currentWidth = this.el.nativeElement.offsetWidth;
        }
        if (this.currentHeight != this.el.nativeElement.offsetHeight) {
            this.resize();
            this.currentHeight = this.el.nativeElement.offsetHeight;
        }

        if (this.differ.diff(this.options)) {
            this.draw$.next('');

        }
    }

    draw(opt: any): void {

        if (!isPresent(opt) || !isPresent(opt.series)) {
            this.chart.clear();
            return;
        }

        if (opt.dispose) {
            this.chart.dispose();
        }

        if (opt.clear) {
            this.chart.clear();
        }

        let size = getBaseFontSize();

        this.initFontSize(opt, 'title', size);
        this.initFontSize(opt, 'legend', size * 0.875);
        this.initFontSize(opt, 'tooltip', size);

        if (!this.chart) {
            this.chart = ECharts.init(this.el.nativeElement, 'my');
        }

        if (opt.loading) {
            this.chart.showLoading();
        }

        this.chart.setOption(opt, true);

        if (opt.itemClick) {
            this.chart.on('click', opt.itemClick)
        }

        if (opt.legendChang) {
            this.chart.on('legendselectchanged', opt.legendChang)
        }

        if (opt.loading) {
            this.chart.hideLoading();
        }

        if (opt.dispatchAction) {
            this.chart.dispatchAction(opt.dispatchAction);
        }

        if (opt.off) {
            for (let event of Object.keys(opt.off)) {
                this.chart.off(event, opt.off[event]);
            }
        }

        if (opt.on) {
            for (let event of Object.keys(opt.on)) {
                this.chart.on(event, opt.on[event]);
            }
        }
    }


    private initFontSize(opt: any, key: string, size: number): void {
        if (!isPresent(opt[key])) {
            return;
        }

        if (!isPresent(opt[key].textStyle)) {
            opt[key].textStyle = { fontSize: size };
            return;
        }

        if (!isPresent(opt[key].textStyle.fontSize)) {
            opt[key].textStyle.fontSize = size;
        }
    }

    ngOnDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
        this.subscriptions.forEach(i => i.unsubscribe());
    }

}
