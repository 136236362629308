import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchFilterOption } from './search.filter.model';
import { GlobalHolder } from 'app/global.holder';

@Injectable()
export class SearchFilterService {

    private baseUrl = '/xltp/sys/searchtemplate';

    constructor(private http: HttpClient, private global: GlobalHolder) {
    }

    add(item: SearchFilterOption): Observable<SearchFilterOption> {
        item.product_id = this.global.product_id;
        return this.http.post(`${this.baseUrl}`, item)
            .pipe(map<any, SearchFilterOption>(id => ({ id, ...item })));
    }

    del(id: number): Observable<any> {
        return this.http.post(`${this.baseUrl}/${id}/delete`, {});
    }

    qryAll(params: any): Observable<any> {
        params.product_id = this.global.product_id;
        return this.http.get(`${this.baseUrl}/list`, { params });
    }

    qryById(id: number): Observable<SearchFilterOption> {
        return this.http.get(`${this.baseUrl}/${id}`);
    }
}
