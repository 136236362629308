import { DateHelperService, isObject, isPresent } from 'share';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

function formatKey(key: string): string {
    return key.startsWith('_') ? key.substring(1) : key;
}

@Injectable({ providedIn: 'root' })
export class ParamsTransformer {

    private format: string;

    constructor(private dateHelper: DateHelperService) {
    }

    transform(obj: any, extra?: any): any {
        if (!isObject(obj)) {
            return obj;
        }
        if (isObject(extra)) {
            Object.assign(obj, extra);
        }

        const result: any = {};
        Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (!isPresent(val) || val === '') {
                return;
            }
            if (Array.isArray(val) && val.length < 0) {
                return;
            }

            if ((key.endsWith('time') || key.endsWith('date')) && Array.isArray(val)) {
                const word = key.endsWith('time') ? 'time' : 'date';
                const prefix = key.replace(`_${word}`, '');

                let start_key = formatKey(`${prefix}_start_${word}`);
                let end_key = formatKey(`${prefix}_end_${word}`);

                result[start_key] = this.process(val?.[0]);
                result[end_key] = this.process(val?.[1]);
                return;
            }

            if (Array.isArray(val) && val.length >= 0) {
                result[key] = val.join(',');
                return;
            }

            if (isObject(val)) {
                result[key] = this.transform(val);
                return;
            }

            result[key] = this.process(val);
        });

        return result;
    }

    serialize(obj: any, filter = key => !key.includes('page')): string {
        let params = new HttpParams();
        const qry = this.transform(obj);
        Object.keys(qry).filter(filter).forEach(key => params = params.append(key, qry[key]));
        return params.toString();
    }

    toFormData(obj: any): FormData {
        const data = new FormData();
        Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (!isPresent(val)) {
                return;
            }
            if (!(val instanceof FileList)) {
                data.append(key, obj[key]);
                return;
            }
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < val.length; i++) {
                data.append(`${key}`, val[i]);
            }
        });
        return data;
    }

    loadFormat(format?: string): ParamsTransformer {
        this.format = format;
        return this;
    }

    private process(value: any): any {
        if (this.dateHelper && value instanceof Date) {
            return this.dateHelper.format(value, this.format);
        }
        return value ?? '';
    }
}