import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { GlobalHolder } from 'app/global.holder';

@Injectable({ providedIn: 'root' })
export class MenuGuardService implements CanActivateChild {

    constructor(private global: GlobalHolder) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (route.routeConfig.loadChildren || route.routeConfig.children) {
            return true;
        }
        if (!route.data.inProduct && route.data.productMenu === false) {
            this.updateMenus();
        }
        this.global.updateShowProduct(this.global.topMenus.getValue() !== this.global.menus.getValue());
        return true;
    }

    private updateMenus(): void {
        let menus = this.global.menus.getValue();
        let topMenus = this.global.topMenus.getValue();
        if (menus === topMenus) {
            return;
        }
        this.global.updateMenus(topMenus);
    }

}
