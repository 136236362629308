export enum EventNames {
    RESIZE_EDITOR = 'resize editor'
}

export enum Colors {
    PRIMARY = 'primary',
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    DANGER = 'danger',
    RED = 'red',
    VOLCANO = 'volcano',
    ORANGE = 'orange',
    GOLD = 'gold',
    YELLOW = 'yellow',
    LIME = 'lime',
    GREEN = 'green',
    CYAN = 'cyan',
    BLUE = 'blue',
    GEEKBLUE = 'geekblue',
    PURPLE = 'purple',
    MAGENTA = 'magenta',
    GRAY = 'gray',
}

export const COLOR_KEYS = [
    Colors.GREEN,
    Colors.RED,
    Colors.YELLOW,
    Colors.ORANGE,
    Colors.GOLD,
    Colors.VOLCANO,
    Colors.LIME,
    Colors.CYAN,
    Colors.BLUE,
    Colors.GEEKBLUE,
    Colors.PURPLE,
    Colors.MAGENTA,
    Colors.PRIMARY,
    Colors.SUCCESS,
    Colors.INFO,
    Colors.WARNING,
    Colors.DANGER,
    Colors.GRAY
];
