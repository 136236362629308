import { FocusMonitor } from '@angular/cdk/a11y';
import { DOWN_ARROW, ENTER, SPACE, TAB, UP_ARROW } from '@angular/cdk/keycodes';
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, combineLatest, merge, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { slideMotion } from '../animation';
import { InputBoolean, isPresent } from '../facade';
import { OptionGroupComponent } from './option-group.component';
import { OptionComponent } from './option.component';
import { SelectTopControlComponent } from './select-top-control.component';
import { FilterOptionType, SelectItemInterface, SelectModeType } from './select.types';
import { defaultFilterOption, generateTagItem, nonTripleCompare } from './select.util';

export type NzSelectSizeType = 'large' | 'default' | 'small';

@Component({
    selector: 'ai-select',
    exportAs: 'select',
    preserveWhitespaces: false,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [slideMotion],
    templateUrl: 'select.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SelectComponent),
        multi: true
    }],
    host: {
        '[class.select]': 'true',
        '[class.form-control]': 'true',
        '[class.select-lg]': 'size === "large"',
        '[class.select-sm]': 'size === "small"',
        '[class.select-disabled]': 'disabled',
        '[class.select-open]': 'open',
        '[class.select-focused]': 'open',
        '[class.select-allow-clear]': 'allowClear',
        '[class.select-single]': `mode === 'default'`,
        '[class.select-multiple]': `mode !== 'default'`
    }
})
export class SelectComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy, AfterContentInit {

    @Input() size: NzSelectSizeType = 'default';
    @Input() dropdownClassName: string | null = null;
    @Input() dropdownMatchSelectWidth = true;
    @Input() placeHolder: string;
    @Input() maxTagCount = Infinity;
    @Input() nowrap: boolean = false;
    @Input() customTemplate: TemplateRef<{ $implicit: SelectItemInterface }> | null = null;
    @Input() dropdownRender: TemplateRef<any> | null = null;
    @Input() tokenSeparators: string[] = [];
    @Input() maxTagPlaceholder: TemplateRef<{ $implicit: any[] }> | null = null;
    @Input() maxMultipleCount = Infinity;
    @Input() mode: SelectModeType = 'default';
    @Input() filterOption: FilterOptionType = defaultFilterOption;
    @Input() compareWith: (o1: any, o2: any) => boolean = nonTripleCompare;

    @Input() @InputBoolean() showSearch = false;
    @Input() @InputBoolean() allowClear = false;
    @Input() @InputBoolean() serverSearch = false;
    @Input() @InputBoolean() disabled = false;

    @Output() readonly search = new EventEmitter<string>();
    @Output() readonly scrollToBottom = new EventEmitter<void>();
    @Output() readonly openChange = new EventEmitter<boolean>();
    @Output() readonly ngBlur = new EventEmitter<void>();
    @Output() readonly ngFocus = new EventEmitter<void>();

    @ViewChild(CdkConnectedOverlay, { static: true })
    cdkConnectedOverlay: CdkConnectedOverlay;

    @ViewChild(SelectTopControlComponent, { static: true })
    selectTopControlComponent: SelectTopControlComponent;

    @ContentChildren(OptionComponent, { descendants: true })
    listOfOptionComponent: QueryList<OptionComponent>;

    @ContentChildren(OptionGroupComponent, { descendants: true })
    listOfOptionGroupComponent: QueryList<OptionGroupComponent>;

    open = false;
    origin: CdkOverlayOrigin;
    dropDownPosition: 'top' | 'center' | 'bottom' = 'bottom';
    triggerWidth: number;
    listOfContainerItem: SelectItemInterface[] = [];
    listOfTopItem: SelectItemInterface[] = [];
    activatedValue: any;
    listOfValue: any[] = [];

    private listOfValue$ = new BehaviorSubject<any[]>([]);
    private listOfTemplateItem$ = new BehaviorSubject<SelectItemInterface[]>([]);
    private listOfTagAndTemplateItem: SelectItemInterface[] = [];
    private searchValue: string = '';
    private value: any | any[];
    private destroy$ = new Subject();
    private onChange: any;
    private onTouched: any;


    constructor(private cdRef: ChangeDetectorRef, private elementRef: ElementRef, private focusMonitor: FocusMonitor) {
        this.origin = new CdkOverlayOrigin(this.elementRef);
    }

    onItemClick(value: any): void {
        this.activatedValue = value;
        if (this.mode === 'default') {
            if (this.listOfValue.length === 0 || !this.compareWith(this.listOfValue[0], value)) {
                this.updateListOfValue([value]);
            }
            this.setOpenState(false);
        } else {
            const targetIndex = this.listOfValue.findIndex(o => this.compareWith(o, value));
            if (targetIndex !== -1) {
                const listOfValueAfterRemoved = this.listOfValue.filter((_, i) => i !== targetIndex);
                this.updateListOfValue(listOfValueAfterRemoved);
            } else if (this.listOfValue.length < this.maxMultipleCount) {
                const listOfValueAfterAdded = [...this.listOfValue, value];
                this.updateListOfValue(listOfValueAfterAdded);
            }
            this.focus();
            this.clearInput();
        }
    }

    onItemDelete(item: SelectItemInterface): void {
        const listOfSelectedValue = this.listOfValue.filter(v => !this.compareWith(v, item.value));
        this.updateListOfValue(listOfSelectedValue);
        this.clearInput();
    }

    onTokenSeparate(listOfLabel: string[]): void {
        const listOfMatchedValue = this.listOfTagAndTemplateItem
            .filter(item => listOfLabel.findIndex(label => label === item.label) !== -1)
            .map(item => item.value)
            .filter(item => this.listOfValue.findIndex(v => this.compareWith(v, item)) === -1);
        if (this.mode === 'multiple') {
            this.updateListOfValue([...this.listOfValue, ...listOfMatchedValue]);
        } else if (this.mode === 'tags') {
            const listOfUnMatchedLabel = listOfLabel.filter(
                label => this.listOfTagAndTemplateItem.findIndex(item => item.label === label) === -1
            );
            this.updateListOfValue([...this.listOfValue, ...listOfMatchedValue, ...listOfUnMatchedLabel]);
        }
        this.clearInput();
    }

    onKeyDown(e: KeyboardEvent): void {
        if (this.disabled) {
            return;
        }
        const listOfFilteredOptionNotDisabled = this.listOfContainerItem.filter(item => item.type === 'item').filter(item => !item.disabled);
        const activatedIndex = listOfFilteredOptionNotDisabled.findIndex(item => this.compareWith(item.value, this.activatedValue));
        switch (e.keyCode) {
            case UP_ARROW:
                e.preventDefault();
                if (this.open) {
                    const preIndex = activatedIndex > 0 ? activatedIndex - 1 : listOfFilteredOptionNotDisabled.length - 1;
                    this.activatedValue = listOfFilteredOptionNotDisabled[preIndex].value;
                }
                break;
            case DOWN_ARROW:
                e.preventDefault();
                if (this.open) {
                    const nextIndex = activatedIndex < listOfFilteredOptionNotDisabled.length - 1 ? activatedIndex + 1 : 0;
                    this.activatedValue = listOfFilteredOptionNotDisabled[nextIndex].value;
                } else {
                    this.setOpenState(true);
                }
                break;
            case ENTER:
                e.preventDefault();
                if (this.open) {
                    if (this.activatedValue) {
                        this.onItemClick(this.activatedValue);
                    }
                } else {
                    this.setOpenState(true);
                }
                break;
            case SPACE:
                if (!this.open) {
                    this.setOpenState(true);
                    e.preventDefault();
                }
                break;
            case TAB:
                this.setOpenState(false);
                break;
        }
    }

    setOpenState(value: boolean): void {
        if (this.open === value) {
            return;
        }
        this.open = value;
        this.openChange.emit(value);
        this.onOpenChange();
        this.cdRef.markForCheck();
    }

    toggleOpen(): void {
        if (this.disabled) {
            return;
        }
        this.setOpenState(!this.open);
    }

    onOpenChange(): void {
        this.updateCdkConnectedOverlayWidth();
        this.clearInput();
    }

    onInputValueChange(value: string): void {
        this.searchValue = value;
        this.updateListOfContainerItem();
        this.search.emit(value);
        this.updateCdkConnectedOverlayPositions();
    }

    onClearSelection(): void {
        this.updateListOfValue([]);
    }

    focus(): void {
        this.selectTopControlComponent?.focus();
    }

    blur(): void {
        this.selectTopControlComponent?.blur();
    }

    onPositionChange(position: ConnectedOverlayPositionChange): void {
        this.dropDownPosition = position.connectionPair.originY;
    }

    writeValue(modelValue: any | any[]): void {
        if (this.value === modelValue || (!isPresent(modelValue) && !isPresent(this.value))) {
            return;
        }
        this.value = modelValue;
        const listOfValue = this.mode === 'default' ? [modelValue] : modelValue ?? [];
        this.listOfValue = listOfValue;
        this.listOfValue$.next(listOfValue);
        this.cdRef.markForCheck();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
        if (disabled) {
            this.setOpenState(false);
        }
        this.cdRef.markForCheck();
    }

    ngOnInit(): void {
        this.focusMonitor
            .monitor(this.elementRef, true)
            .pipe(takeUntil(this.destroy$))
            .subscribe(focusOrigin => {
                if (!focusOrigin) {
                    this.ngBlur.emit();
                    Promise.resolve().then(() => {
                        this.onTouched();
                    });
                } else {
                    this.ngFocus.emit();
                }
            });
        combineLatest([this.listOfValue$, this.listOfTemplateItem$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([listOfSelectedValue, listOfTemplateItem]) => {

                const listOfTagItem = listOfSelectedValue
                    .filter(() => this.mode === 'tags')
                    .filter(value => !listOfTemplateItem.some(o => this.compareWith(o.value, value)))
                    .map(value => this.listOfTopItem.find(o => this.compareWith(o.value, value)) || generateTagItem(value));
                this.listOfTagAndTemplateItem = [...listOfTemplateItem, ...listOfTagItem];
                this.listOfTopItem = this.listOfValue
                    .map(v => [...this.listOfTagAndTemplateItem, ...this.listOfTopItem].find(item => this.compareWith(v, item.value)))
                    .filter(item => !!item);
                this.updateListOfContainerItem();
            });
    }

    ngAfterViewInit(): void {
        this.updateCdkConnectedOverlayWidth();
    }

    ngAfterContentInit(): void {
        merge(
            ...[
                this.listOfOptionComponent.changes,
                this.listOfOptionGroupComponent.changes,
                ...this.listOfOptionComponent.map(option => option.changes),
                ...this.listOfOptionGroupComponent.map(option => option.changes)
            ]
        ).pipe(
            startWith(true),
            takeUntil(this.destroy$)
        ).subscribe(() => {
            const options = this.listOfOptionComponent.toArray().map(item => {
                const { template, label, value, disabled, hide, customContent, groupLabel } = item;
                return {
                    template,
                    label,
                    value,
                    disabled,
                    hide,
                    customContent,
                    groupLabel,
                    type: 'item',
                    key: value
                };
            });
            this.listOfTemplateItem$.next(options);
            this.cdRef.markForCheck();
        });
    }

    private updateListOfValue(listOfValue: any[]): void {
        const covertListToModel = (list: any[], mode: SelectModeType): any[] | any => {
            if (mode !== 'default') {
                return list;
            }
            return list.length > 0 ? list[0] : null;
        };
        const model = covertListToModel(listOfValue, this.mode);
        if (this.value === model) {
            return;
        }
        this.listOfValue = listOfValue;
        this.listOfValue$.next(listOfValue);
        this.value = model;
        this.onChange(this.value);
        this.cdRef.markForCheck();
    }

    private updateListOfContainerItem(): void {
        let listOfContainerItem = this.listOfTagAndTemplateItem
            .filter(item => !item.hide)
            .filter(item => {
                if (!this.serverSearch && this.searchValue) {
                    return this.filterOption(this.searchValue, item);
                } else {
                    return true;
                }
            });
        if (this.mode === 'tags' && this.searchValue && !this.listOfTagAndTemplateItem.some(item => item.label === this.searchValue)) {
            const tagItem = generateTagItem(this.searchValue);
            listOfContainerItem = [tagItem, ...listOfContainerItem];
            this.activatedValue = tagItem.value;
        }
        if (this.listOfValue.length !== 0 && !listOfContainerItem.some(item => this.compareWith(item.value, this.activatedValue))) {
            const activatedItem = listOfContainerItem.find(item => this.compareWith(item.value, this.listOfValue[0])) || listOfContainerItem[0];
            this.activatedValue = activatedItem?.value;
        }

        if (this.listOfOptionGroupComponent) {
            this.listOfOptionGroupComponent.forEach(o => {
                const groupItem = { groupLabel: o.label, type: 'group', key: o.label } as SelectItemInterface;
                const index = listOfContainerItem.findIndex(item => groupItem.groupLabel === item.groupLabel);
                listOfContainerItem.splice(index, 0, groupItem);
            });
        }
        this.listOfContainerItem = [...listOfContainerItem];
        this.updateCdkConnectedOverlayPositions();
    }

    private updateCdkConnectedOverlayWidth(): void {
        this.triggerWidth = this.elementRef?.nativeElement?.getBoundingClientRect().width;
    }

    private clearInput(): void {
        this.onInputValueChange('');
        this.selectTopControlComponent?.clearInputValue();
    }

    private updateCdkConnectedOverlayPositions(): void {
        this.cdkConnectedOverlay?.overlayRef?.updatePosition();
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
