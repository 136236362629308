export class CrumbModel {
    name: string;
    type: string; // router | drawer
    path?: string;
    drawerRef?: any;
    id?: number;

    constructor(obj) {
        this.name = obj?.name || '';
        this.type = obj?.type || '';
        this.path = obj?.path || '';
        this.drawerRef = obj?.drawerRef || undefined;
        this.id = new Date().getTime();
    }
}
