import { add, format } from 'date-fns';
import { isChinese, isPresent, toInteger } from 'share';

export interface RecentDate {
    label: string;
    value: number;
}

export function transformName(value: any, length = 4): any {
    if (!value) {
        return '';
    }
    if (value.length <= length + 1) {
        return value;
    }

    let len = 0;
    const chars = value.split('');
    for (let i = 0; i < chars.length; i++) {
        if (isChinese(chars[i])) {
            len += 2;
        } else {
            len += 1;
        }
        if (len >= (length * 2)) {
            return value.substring(0, i + 1) + '...';
        }
    }
    return value;
}

export function getRecentDates() {
    const dates: Array<RecentDate> = [];
    const now = +new Date();

    const durations = [{ days: -6 }, { months: -1 }, { months: -3 }, { months: -6 }, { years: -1 }, null];
    const labels = ['最近一周', '最近一月', '最近三月', '最近半年', '最近一年', '所有'];

    durations.forEach((d, i) => dates.push({
        value: isPresent(d) ? toInteger(format(add(now, d), 'yyyyMMdd')) : null,
        label: labels[i]
    }));
    return dates;
}

export function getToday() {
    return toInteger(format(+new Date(), 'yyyyMMdd'));
}

export function toPercent(value: number, total: number): number {
    if (total === 0) {
        return 0;
    }
    return Math.floor(value / total * 100 * 100) / 100;
}
