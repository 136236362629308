import * as tinycolor from 'tinycolor2';

const hueStep = 2;
const saturationStep = 16;
const saturationStep2 = 5;
const brightnessStep1 = 5;
const brightnessStep2 = 15;
const lightColorCount = 5;
const darkColorCount = 4;

function getHue(h: number, i: number, isLight: boolean): number {
    let hue;
    if (h >= 60 && h <= 240) {
        hue = isLight ? h - hueStep * i : h + hueStep * i;
    } else {
        hue = isLight ? h + hueStep * i : h - hueStep * i;
    }
    if (hue < 0) {
        hue += 360;
    } else if (hue >= 360) {
        hue -= 360;
    }
    return Math.round(hue);
}

function getSaturation(s: number, i: number, isLight: boolean): number {
    let saturation;
    if (isLight) {
        saturation = Math.round(s * 100) - saturationStep * i;
    } else if (i === darkColorCount) {
        saturation = Math.round(s * 100) + saturationStep;
    } else {
        saturation = Math.round(s * 100) + saturationStep2 * i;
    }
    if (saturation > 100) {
        saturation = 100;
    }
    if (isLight && i === lightColorCount && saturation > 10) {
        saturation = 10;
    }
    if (saturation < 6) {
        saturation = 6;
    }
    return Math.round(saturation);
}

function getValue(v: number, i: number, isLight: boolean): number {
    if (isLight) {
        return Math.round(v * 100) + brightnessStep1 * i;
    }
    return Math.round(v * 100) - brightnessStep2 * i;
}

export function colorPalette(color: string, index: number): string {
    let isLight: boolean = index <= 6;
    let hsv = tinycolor(color).toHsv();
    let i = isLight ? lightColorCount + 1 - index : index - lightColorCount - 1;
    return tinycolor({
        h: getHue(hsv.h, i, isLight),
        s: getSaturation(hsv.s, i, isLight),
        v: getValue(hsv.v, i, isLight)
    }).toHexString();
}
