import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TableService {

    private pre: any;

    add(item: any): void {
        if (typeof this.pre === 'function') {
            this.pre()
        }
        this.pre = item
    }

}
