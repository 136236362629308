import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageComponent } from 'core';
import { BatchSelectService, DrawerService, isPresent, validateError } from 'share';
import { ProductUser } from 'app/product/product.model';
import { GlobalHolder } from 'app/global.holder';
import { ProductUserService } from 'app/product/user/product.user.service';
import { ProductUserConfigComponent } from 'app/product/user/config/product.user.config.component';


@Component({
    selector: 'ai-product-user',
    templateUrl: 'product.user.component.html'
})
export class ProductUserComponent extends PageComponent<ProductUser> implements OnInit, OnDestroy {

    constructor(private drawer: DrawerService,
                private global: GlobalHolder,
                private selectService: BatchSelectService<ProductUser>,
                private productUserService: ProductUserService) {
        super(productUserService);
    }

    ngOnInit(): void {
        this.params.product_id = this.global.product_id;
        this.selectService.init([], 'user_id');
        this.qry();
    }

    afterPage() {
        this.items = this.productUserService.transform(this.items);
        this.selectService.changeItems(this.items);
    }

    relevance(): void {
        const drawerRef = this.drawer.open(ProductUserConfigComponent);
        drawerRef.inputs.product = this.global.product.getValue();
        drawerRef.result.subscribe(() => this.qry());
    }

    del(item: ProductUser) {
        let users =JSON.parse(JSON.stringify(this.items))// this.global.users.getValue();
        console.log(users,'9999999')
        let user = users.find(v => v.user_id === item.user_id);
        console.log(users,'8888888')
        if (!isPresent(user)) {
            return;
        }
        let items = users.filter(v => v !== user).map(v => Object.assign({}, v));
        this.productUserService.saveUser(this.global.product_id, items).subscribe(() => this.qry());
    }

    beforeBatch = () => {
        let items = this.selectService.getCheckedRecords();
        if (items.length === 0) {
            validateError('请选择用户');
        }
    };

    batchDel(): void {
        let users = this.global.users.getValue();
        let items = users.filter(v => !this.selectService.getCheckedRecords().some(r => r.user_id === v.user_id)).map(v => Object.assign({}, v));
        this.productUserService.saveUser(this.global.product_id, items).subscribe(() => this.qry());
    }

    ngOnDestroy(): void {
    }
}
