import { Injectable } from '@angular/core';
import { GlobalHolder } from 'app/global.holder';


@Injectable({ providedIn: 'root' })
export class RightJudgeService {

    constructor(private global: GlobalHolder) {
    }

    hasFactoryRight(): boolean {
        const rights = ['use-data-factory'];
        return  rights.some(r => this.global.rights.getValue()?.includes(r));
    }
}
