import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Menu, Operation } from 'app/group/group.model';
import { getBaseFontSize, isPresent } from 'share';

@Component({
    selector: 'ai-menu-item',
    templateUrl: 'menu.item.component.html'
})
export class MenuItemComponent implements OnInit {

    @Input() menu: Menu;
    @Input() level: number;
    @Input() current: Menu;
    @Input() draggable: boolean;
    @Input() openable: boolean = true;

    @Output() operate = new EventEmitter<{ operation: Operation, menu: Menu }>(true);
    @Output() selectItem = new EventEmitter<Menu>(true);
    @Output() dropped = new EventEmitter<any>(true);

    left: number;
    top: number;


    constructor() {
        this.draggable = false;
        this.left = 0;
        this.top = 0;
    }

    get showExpand(): boolean {
        return (this.openable || this.menu?.children?.length > 0) && (this?.menu.type === 1 || this?.menu.icon === 'icon-service'|| this?.menu.icon === 'new-icon-folder2' ) ;
    }
    
    ngOnInit() {
        if (!isPresent(this.current)) {
            this.current = new Menu({});
        }
        console.log(this.menu,'-----222222222')
    }

    emitMenuSelectItem($event): void {
        this.selectItem.emit($event);
    }

    enterMore(event: any): void {
        let { target }: { target: HTMLElement } = event;
        let fontSize = getBaseFontSize();
        let react = target.getBoundingClientRect();
        this.left = react.left - fontSize;

        let clientHeight = $(document).height();
        let menuHeight = $(target).find('.dropdown-menu').outerHeight();
        if (react.top + react.height + menuHeight > clientHeight) {
            this.top = react.top - menuHeight + fontSize * 0.25;
        } else {
            this.top = react.top + react.height - fontSize * 0.25;
        }
    }

    drag(event: DragEvent): void {
        console.log(this.menu,'-----11111111111')
        let  k =this.menu.id
        event.dataTransfer.effectAllowed = 'all';
        event.dataTransfer.setData('menu', `${k}`);
    }

    allowDrop(event: DragEvent): void {
        event.dataTransfer.dropEffect = 'move';
        event.preventDefault();
        event.stopPropagation();
    }

    enter(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.menu.drag_entered = true;
    }

    drop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.menu.drag_entered = false;

        const from = this.menu.root.flatMenu().find(item => item.id === +event.dataTransfer.getData('menu'));
        if (from === this.menu) {
            return;
        }
        this.dropped.emit({ from, to: this.menu });
    }

    emitDropped($event): void {
        this.dropped.emit($event);
    }

    emitMenuOperate($event): void {
        this.operate.emit($event);
    }
}
