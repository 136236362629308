import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AiOverlayModule } from '../overlay/overlay.module';
import { TimePickerModule } from '../time-picker';


import { DatePickerComponent } from './datepicker.component';
import { DecadeHeaderComponent } from './decade/decade-header.component';
import { DecadeTableComponent } from './decade/decade-table.component';
import { CalendarFooterComponent } from './footer/calendar-footer.component';
import { DateHeaderComponent } from './header/date-header.component';
import { MonthHeaderComponent } from './month/month-header.component';
import { MonthPickerComponent } from './month/month-picker.component';
import { MonthTableComponent } from './month/month-table.component';

import { PickerComponent } from './picker.component';
import { InnerPopupComponent } from './popup/inner-popup.component';
import { RangePopupComponent } from './popup/range-popup.component';
import { RangePickerComponent } from './range/range-picker.component';
import { DateTableComponent } from './table/date-table.component';
import { WeekPickerComponent } from './week/week-picker.component';
import { YearHeaderComponent } from './year/year-header.component';
import { YearPickerComponent } from './year/year-picker.component';
import { YearTableComponent } from './year/year-table.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        OverlayModule,
        AiOverlayModule,
        TimePickerModule
    ],
    exports: [
        DatePickerComponent,
        RangePickerComponent,
        MonthPickerComponent,
        YearPickerComponent,
        WeekPickerComponent
    ],
    declarations: [
        PickerComponent,
        DatePickerComponent,
        DateTableComponent,
        DateHeaderComponent,

        RangePickerComponent,

        MonthPickerComponent,
        MonthHeaderComponent,
        MonthTableComponent,

        YearPickerComponent,
        YearHeaderComponent,
        YearTableComponent,

        DecadeHeaderComponent,
        DecadeTableComponent,

        WeekPickerComponent,

        CalendarFooterComponent,
        InnerPopupComponent,
        RangePopupComponent
    ]
})
export class DatepickerModule {
}
