import { Directive, OnInit } from '@angular/core';
import { Textarea } from './textarea';

@Directive({ selector: '[textarea-group]' }) //tslint:disable-line
export class TextareaGroupDirective implements OnInit {

    private items: Array<Textarea> = [];

    constructor() {
    }

    ngOnInit(): void {
    }

    registerTextarea(item: Textarea): void {
        this.items.push(item);
    }

    updateHeight(): void {
        let height = this.items.map(item => item.textHeight).max();
        this.items.forEach(item => item.updateHeight(height));
    }
}
