import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalHolder } from 'app/global.holder';
import { DrawerRef, validateError } from 'share';
import { Column } from '../';
import { ColumnService } from '../column.service';

@Component({
    selector: 'ai-column-config',
    templateUrl: 'column.config.component.html',
    styleUrls: ['column.config.scss']
})

export class ColumnConfigComponent implements OnInit, OnDestroy {

    @Input() items: Array<Column>;
    @Input() name: string;

    columns: Array<Column> = [];

    constructor(public drawerRef: DrawerRef, private global: GlobalHolder, private columnService: ColumnService) {
    }

    get isSelectAll():Boolean{
        if(!this.items){
            return true
        }
        return this.columns.length === this.items.length
    }

    set isSelectAll(val:Boolean){
       if(val === true){
          this.checkAll()
       }else{
            this.unCheckAll()
       }
    }

    ngOnInit() {
        this.columns = this.items
            .filter(item => item.checked)
            .sort((a, b) => a.order_no - b.order_no);
    }

    checkAll(): void {
        this.items.filter(item => !item.checked).forEach(item => {
            item.checked = true;
            this.columns.push(item);
        });
    }

    unCheckAll(): void {
        this.items.filter(item => item.checked).forEach(item => {
            item.checked = false;
            this.columns = [];
        });
    }

    invertCheck(): void {
        this.items.forEach(item => {
            if (item.checked) {
                this.remove(item);
            } else {
                item.checked = true;
                this.columns.push(item);
            }
        });
    }

    check(item: Column): void {
        if (!item.checked) {
            this.columns.remove(item);
        } else {
            this.columns.push(item);
        }
    }

    remove(item: Column): void {
        item.checked = false;
        this.columns.remove(item);
    }

    confirm(): void {
        if (this.columns.length === 0) {
            validateError('请选择显示字段');
        }
        this.columns.forEach((value, index) => value.order_no = index + 1);
        this.items.forEach(item => item.is_select = item.checked ? 1 : 0);
        this.columnService.config({
            product_id: this.global.product_id,
            user_id: this.global.user_id,
            source_table: this.name,
            custom_display_configs: this.items
        }).subscribe(() => this.drawerRef.close(this.items));
    }

    ngOnDestroy() {
    }
}
