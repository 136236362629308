import { isPresent } from 'share';
import { Node, NodeType } from './node';

export interface XmlVisitor {

    visitElement(value: Element, node: Node): void;

    visitChildren(elements: Array<Element>, node: Node): void;

    visitAttributes(attributes: NamedNodeMap, node: Node): void;

    visitAttribute(attribute: Attr, node: Node): void;

    visitValue(value: string, node: Node): void;
}

export interface XmlValueVisitor {

    visitValue(value: string, node: Node): string;

}

export class DefaultXmlVisitor implements XmlVisitor {

    visitAttribute(attribute: Attr, node: Node) {
        this.visitValue(attribute.value, node);
        node.type = NodeType.Attribute;
    }

    visitAttributes(attributes: NamedNodeMap, node: Node) {
        let nodes = node.children.filter(item => item.type & NodeType.Attribute);
        if (!isPresent(attributes)) {
            nodes.forEach(item => node.removeChild(item));
            return;
        }

        for (let i = 0; i < attributes.length; i++) {
            let attr = attributes.item(i);
            let child = node.children.find(item => item.name === attr.name) || node.addChild(new Node(attr.name, '', NodeType.Attribute));
            this.visitAttribute(attr, child);
        }
        nodes.filter(item => !attributes.getNamedItem(item.name)).forEach(item => node.removeChild(item));
    }

    visitElement(value: Element, node: Node) {
        node.type = NodeType.Element;
        let nodes: Array<Element> = [];
        let childNodes: NodeListOf<ChildNode>;
        if (value.nodeType === 4) {
            try {
                let dom = $.parseXML(value.nodeValue.trim());
                childNodes = dom.childNodes;
            } catch (e) {
                console.error(e);
            }
        } else {
            childNodes = value.childNodes;
        }

        (childNodes || []).forEach((child: Element) => {
            if (child.nodeType === 1 || child.nodeType === 4) {
                nodes.push(child);
            }
        });

        let elements = node.children.filter(item => item.type & NodeType.Element);

        if (nodes.length === 0) {
            this.visitValue(value.nodeType === 4 ? value.nodeValue : $(value).html(), node);
            elements.forEach(el => node.removeChild(el));
            return;
        }

        let nodeMap = new Map<string, Element>();
        nodes.forEach(item => nodeMap.set(item.nodeName, item));
        elements.filter(el => !nodeMap.has(el.name)).forEach(el => node.removeChild(el));
        this.visitChildren(nodes, node);

    }

    visitChildren(elements: Array<Element>, node: Node): void {
        elements.forEach(el => {
            let child = node.children.find(item => (item.type & NodeType.Element) && item.name === el.nodeName) || node.addChild(new Node(el.nodeName, '', NodeType.Element));
            visitXml(this, el, child);
        });
    }

    visitValue(value: string, node: Node): void {
        node.value = value;
    }
}

export function visitXml(visitor: XmlVisitor, element: Element, node: Node) {
    visitor.visitAttributes(element.attributes, node);
    visitor.visitElement(element, node);
    node.name = element.nodeName;
}

export class DefaultXmlValueVisitor implements XmlValueVisitor {

    visitValue(value: string, node: Node): string {
        return value;
    }
}

export function visitXmlValue(visitor: XmlValueVisitor, node: Node): string {
    const children: Node[] = node.children;
    let level = node.level;
    let xml = '';
    for (let i = 1; i < level; i++) {
        xml += `\t`;
    }
    let isCdata = node.name === '#cdata-section';
    xml += isCdata ? `<![CDATA[` : `<${node.name}`;
    children.filter(child => child.type & NodeType.Attribute).forEach(child => {
        xml += '\n';
        for (let i = 0; i < level; i++) {
            xml += `\t`;
        }
        xml += `${child.name}="${visitor.visitValue(child.value, child) || ''}"`;
    });
    if (!isCdata) {
        xml += '>';
    }

    let nodes = children.filter(child => child.type & NodeType.Element);
    if (nodes.length > 0) {
        xml += '\n';
        nodes.forEach(child => xml += visitXmlValue(visitor, child));
    } else {
        xml += `${visitor.visitValue(node.value, node) || ''}`;
    }

    if (nodes.length > 0) {
        for (let i = 1; i < level; i++) {
            xml += `\t`;
        }
    }
    xml += isCdata ? `]]>\n` : `</${node.name}>\n`;
    return xml;
}
