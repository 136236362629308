import { Observable, of } from 'rxjs';
import { isPresent } from './lang';

// import * as XLSX from 'xlsx';

export function exportExcel(items: Array<any>, name?: string) {
    // const options: any = {bookType: 'xlsx', bookSST: false, type: 'binary'};
    //
    // const wb = {SheetNames: ['Sheet1'], Sheets: {}, Props: {}};
    // wb.Sheets['Sheet1'] = XLSX.utils.json_to_sheet(items);
    //
    //
    // let blob = new Blob([strToArrayBuffer(XLSX.write(wb, options))], {type: 'application/vnd.ms-excel'});
    // let a = document.createElement('a');
    // a.href = URL.createObjectURL(blob);
    // a.download = `${name}.xlsx`;
    // $(a).css('opacity', 0);
    // $(document.body).append(a);
    // a.click();
    // a.remove();
}

export function strToArrayBuffer(str: string) {
    let buf = new ArrayBuffer(str.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i !== str.length; ++i) {
        view[i] = str.charCodeAt(i) & 0xFF;
    }
    return buf;
}

export function readExcel(file: File): Observable<Array<any>> {
    if (!isPresent(file)) {
        return of([]);
    }

    return Observable.create(observer => {
        let reader = new FileReader();
        // reader.onload = (e) => {
        //     let data = (e.target as any).result;
        //     let wb = XLSX.read(data, {
        //         type: 'binary'
        //     });
        //     observer.next(XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]));
        //     observer.complete();
        // };

        reader.readAsBinaryString(file);
    });

}
