export function getTextWidth(text: string, font: string): number {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
}

export function limitTextWidth(text: string, font: string, width: number, suffix = '...') {
    let suffixWidth = getTextWidth(suffix, font);
    if (width < suffixWidth) {
        width = suffixWidth;
    }
    if (getTextWidth(text, font) <= width) {
        return text;
    }

    let i = text.length - suffix.length;
    let textWidth = getTextWidth(`${text.substr(0, i)}${suffix}`, font);
    if (textWidth > width * 2) {
        i = Math.floor(i / (textWidth / (width * 2)));
    }
    while (getTextWidth(`${text.substr(0, i)}${suffix}`, font) > width) {
        i--;
    }
    return `${text.substr(0, i)}${suffix}`;
}
