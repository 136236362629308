import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { GlobalHolder } from 'app/global.holder';
import { GuardInitializer, INITIALIZER } from 'app/guard/guard.initializer';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InitializerGuardService implements CanActivate {

    private isInit: boolean = false;

    constructor(private global: GlobalHolder, @Inject(INITIALIZER) private initializers: GuardInitializer[]) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (this.isInit && !this.global.refreshGuard) {
            return of(true);
        }
        let subject = new Subject<boolean>();
        let isPass = false;
        let observable = of(true);
        this.initializers.forEach(initializer => observable = observable.pipe(switchMap(flag => {
            if (!flag) {
                return EMPTY;
            }
            return initializer.init();
        })));
        observable.pipe(
            tap(flag => isPass = flag),
            finalize(() => {
                subject.next(isPass);
                subject.complete();
                this.isInit = true;
                this.global.refreshGuard = false;
            })
        ).subscribe();
        return subject.asObservable();
    }
}
