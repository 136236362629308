import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { Observable } from 'rxjs';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class SysParamService {

    private baseUrl = '/xltp/sys/params';

    constructor(private http: HttpClient) {
    }

    qry(type: string, key?: string): Observable<Array<any>> {
        let params = new HttpParams();
        if (isPresent(type)) {
            params = params.set('param_type', type);
        }
        if (isPresent(key)) {
            params = params.set('param_key', key);
        }
        return this.http.get<Array<any>>(`${this.baseUrl}`, {
            params,
            headers: new HttpHeaders().set(Headers.BLOCK, 'false')
        });
    }

    update(items: Array<any>): Observable<any> {
        return this.http.post(`${this.baseUrl}`, items);
    }
}
