import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '../facade';


const MOBILE_REGEXP = /^1[2-9]\d{9}$/;

const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const URL_REGEXP = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i

export class FormValidators {
    static mobile(control: AbstractControl): { [key: string]: any } {
        if (isPresent(Validators.required(control))) {
            return null;
        }
        return MOBILE_REGEXP.test(control.value) ? null : { mobile: '电话号码格式不正确' };
    }

    static email(control: AbstractControl): { [key: string]: any } {
        if (isPresent(Validators.required(control))) {
            return null;
        }
        return EMAIL_REGEXP.test(control.value) ? null : { email: '邮箱格式不正确' };
    }

    static url(control: AbstractControl): { [key: string]: any } {
        if (isPresent(Validators.required(control))) {
            return null;
        }
        return URL_REGEXP.test(control.value) ? null : { url: 'url格式不正确' };
    }

    static maxlength(maxlength: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!isPresent(maxlength)) {
                return null;
            }

            if (isPresent(Validators.required(control))) {
                return null;
            }

            let v: string = control.value;

            let l = 0;
            for (let i = 0; i < v.length; i++) {
                if ((v.charCodeAt(i) & 0xff00) !== 0) {
                    l += 2;
                }
                l++;
            }

            return l <= +maxlength ? null : {
                maxlength: `输入内容不能大于${maxlength}个字符, 一个汉字占3个字符`
            };
        };
    }

    static digits(control: AbstractControl): { [key: string]: any } {
        if (isPresent(Validators.required(control))) {
            return null;
        }
        let v: string = control.value;
        return /^\d+$/.test(v) ? null : { digits: '请输入整数' };
    }

    static decimal(control: AbstractControl): { [key: string]: any } {
        if (isPresent(Validators.required(control))) {
            return null;
        }
        let v: string = control.value;
        return /^\d+$|^\d*\.\d+$/g.test(v) ? null : { digits: '请输入数字' };
    }

    static min(min: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!isPresent(min)) {
                return null;
            }

            if (isPresent(Validators.required(control))) {
                return null;
            }

            if (isPresent(FormValidators.digits(control))) {
                return null;
            }

            let v: number = +control.value;

            return v < min ? { min: `最小为${min}` } : null;
        };
    }

    static max(max: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!isPresent(max)) {
                return null;
            }

            if (isPresent(Validators.required(control))) {
                return null;
            }

            if (isPresent(FormValidators.digits(control))) {
                return null;
            }

            let v: number = +control.value;

            return v > max ? { max: `最大为${max}` } : null;
        };
    }
}
