import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule,ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { GroupModule } from 'app/group/group.module';
import { GUARD_INITIALIZERS } from 'app/guard/guard.initializer';
import { initInjector } from 'app/initializer/injector.initializer';
import { initOpen } from 'app/initializer/open.initializer';
import { initPage } from 'app/initializer/page.initializer';
import { initRightMenuListener } from 'app/initializer/right.initializer';
import { initTag } from 'app/initializer/tag.initializer';
import { AutoLoginModule } from 'app/login/auto/auto.login.module';
import { SingleLoginModule } from 'app/login/single/single.login.module';
import { CacheBtnComponent } from 'app/main/cache/cache.btn.component';
import { CacheComponent } from 'app/main/cache/cache.component';
import { AppConfigBtnComponent } from 'app/main/config/app.config.btn.component';
import { AppConfigComponent } from 'app/main/config/app.config.component';
import { DetectMonitorComponent } from 'app/main/detect/detect.monitor.component';
import { HeaderComponent } from 'app/main/header/header.component';
import { UseravatarComponent } from 'app/main/useravatar/header.component';
import { MainComponent } from 'app/main/main.component';
import { MenuSidebarComponent } from 'app/main/menu/menu.sidebar.component';
import { AppNameComponent } from 'app/main/name/app.name.component';
import { ProductNameComponent } from 'app/main/name/product.name.component';
import { NotifyIconComponent } from 'app/main/notify/notify.icon.component';
import { ScriptRecordComponent } from 'app/main/script/script.record.component';
import { AppSkinBtnComponent } from 'app/main/skin/app.skin.btn.component';
import { AppSkinComponent } from 'app/main/skin/app.skin.component';
import { ProductEditModule } from 'app/product/edit/product.edit.module';
import { ProductGlobalService } from 'app/product/product.global.service';
import { RegisterModule } from 'app/register/register.module';
import { TenantEditModule } from 'app/system/tenant/edit/tenant.edit.module';
import { PageWinModule } from 'app/ui/page/win/page.win.module';
import { CoreModule, IdlePreloadingStrategy, ProductInitService } from 'core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { BaseUrlStrategy, EventManager, PaginationConfig, ShareModule, TagColorService } from 'share';
import { AppComponent } from './app.component';
import { appRoutes } from './app.route';
import { LoginModule } from './login/login.module';
import { CrumbHolder } from 'share/crumb/crumb.holder';
import { initCrumb } from 'app/initializer/crumb.initializer';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { AuditComponent } from './audit/audit.component';
import { MyErrorHandler } from './myErrorHandler'
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])
@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ShareModule,
        CoreModule,
        GroupModule,
        LoginModule,
        AutoLoginModule,
        SingleLoginModule,
        RegisterModule,
        ProductEditModule,
        PageWinModule,
        TenantEditModule,
        NgxWebstorageModule.forRoot(),
        LazyLoadImageModule,
        RouterModule.forRoot(appRoutes, { useHash: true, preloadingStrategy: IdlePreloadingStrategy }),
        NzIconModule.forRoot(icons),
        NzButtonModule,
        NzAvatarModule,
        NzPopoverModule,
        NzToolTipModule,
        NzDropDownModule,
        NzStepsModule
        
    ],
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        UseravatarComponent,
        ScriptRecordComponent,
        DetectMonitorComponent,
        MenuSidebarComponent,
        CacheComponent,
        CacheBtnComponent,
        AppConfigComponent,
        AppConfigBtnComponent,
        AppNameComponent,
        ProductNameComponent,
        AppSkinBtnComponent,
        AppSkinComponent,
        NotifyIconComponent,
        AuditComponent,
    ],
    entryComponents: [
        CacheComponent,
        AppConfigComponent
    ],
    providers: [
        {provide: ErrorHandler, useClass: MyErrorHandler},
        { provide: APP_INITIALIZER, useFactory: initCrumb, multi: true, deps: [CrumbHolder] },
        { provide: APP_INITIALIZER, useFactory: initPage, multi: true, deps: [PaginationConfig] },
        { provide: APP_INITIALIZER, useFactory: initTag, multi: true, deps: [TagColorService] },
        { provide: APP_INITIALIZER, useFactory: initInjector, multi: true, deps: [Injector] },
        { provide: APP_INITIALIZER, useFactory: initOpen, multi: true, deps: [BaseUrlStrategy] },
        { provide: APP_INITIALIZER, useFactory: initRightMenuListener, multi: true, deps: [EventManager] },
        { provide: ProductInitService, useClass: ProductGlobalService },
        ...GUARD_INITIALIZERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

