import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { Widget } from 'app/ui/widget/ui.widget.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPresent } from 'share';

@Injectable()
export class UIWidgetService {

    private baseUrl = '/xltp/ats/element';

    constructor(private http: HttpClient) {
    }

    qryAll(params: { product_id?: number; fun_id?: number; element_name?: string }): Observable<Array<Widget>> {
        let search = new HttpParams();
        Object.keys(params).filter(key => isPresent(params[key])).forEach(key => search = search.set(key, params[key]));
        return this.http.get(`${this.baseUrl}/list`, { params: search }).pipe(map<any, Array<any>>(page => page.page_data || []));
    }

    qryByName(product_id: number, name: string): Observable<Array<Widget>> {
        let params = new HttpParams().set('element_name', name).set('product_id', `${product_id}`);
        return this.http.get(`${this.baseUrl}/list`, { params }).pipe(map<any, Array<any>>(page => page.page_data || []));
    }

    qryById(id: number): Observable<Widget> {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

    save(item: Widget): Observable<any> {
        return this.http.post(`${this.baseUrl}`, item);
    }

    mod(id: number, item: Widget): Observable<any> {
        return this.http.put(`${this.baseUrl}/${id}`, item);
    }

    del(id: number): Observable<any> {
        let headers = new HttpHeaders().set(Headers.Confirm, '');
        return this.http.delete(`${this.baseUrl}/${id}`, { headers });
    }

    modOrder(id: number, step: number): Observable<any> {
        let headers = new HttpHeaders().set(Headers.BLOCK, '正在移动...');
        return this.http.post(`${this.baseUrl}/${id}/move/${step}`, {}, { headers });
    }

}
