import { ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DetectTaskService } from 'app/detect/task/detect.task.service';
import { GlobalHolder } from 'app/global.holder';
import { Product } from 'app/product/product.model';
import { interval, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPresent, ModalService, toastrWarningDark } from 'share';

@Component({
    selector: 'ai-detect-monitor',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ''
})

export class DetectMonitorComponent implements OnInit, OnDestroy {

    private product: Product;

    private detectMonitor$: Subscription;
    private subscriptions: Array<Subscription> = [];
    private tips: Array<any> = [];

    constructor(private global: GlobalHolder,
                private router: Router,
                private zone: NgZone,
                private modal: ModalService,
                private detectService: DetectTaskService) {
        this.hide = this.hide.bind(this);
    }

    ngOnInit() {
        let global$ = this.global.product.subscribe(product => {
            if (!isPresent(product)) {
                return;
            }
            this.product = product;
            this.monitorDetectNotify();
        });
        this.subscriptions.push(global$);
        document.addEventListener('click', this.hide);
    }

    private monitorDetectNotify(): void {
        this.removeDetectMonitor$();
        this.qryDetectLog();
        this.zone.runOutsideAngular(() => this.detectMonitor$ = interval(300 * 1000).subscribe(() => this.qryDetectLog()));
    }

    private qryDetectLog(): void {
        this.detectService.qryByPage({
            product_id: this.global.product_id,
            page_no: 1,
            page_size: 1,
            last_result: 0,
            detect_status: 1
        }, false).pipe(map(page => page.page_data || [])).subscribe(items => {
            if (items.length === 0) {
                return;
            }
            this.showDetectWarning(items[0]);
        });
    }

    private showDetectWarning(item: any): void {
        let url = this.router.url;
        if (url.indexOf('detect') !== -1) {
            return;
        }
        let tip = toastrWarningDark(`巡测任务[${item.task_name}]巡测失败`, {
            timeOut: 10000,
            onclick: () => this.goDetail()
        });
        this.tips.push(tip);
    }

    private goDetail(): void {
        this.modal.confirm({ title: '确定查看失败巡测任务?' }).result.subscribe(() => this.router.navigate(['pages/detect/task'], { queryParams: { last_result: 0 } }));
    }

    private hide(): void {
        this.tips.forEach(tip => toastr.clear(tip, { force: true }));
        this.tips.length = 0;
    }

    private removeDetectMonitor$(): void {
        if (!isPresent(this.detectMonitor$)) {
            return;
        }
        this.detectMonitor$.unsubscribe();
    }


    ngOnDestroy(): void {
        this.removeDetectMonitor$();
        this.subscriptions.forEach(i => i.unsubscribe());
        document.removeEventListener('click', this.hide);
    }
}
