import { AnimationEvent } from '@angular/animations';
import { ConfigurableFocusTrapFactory, FocusTrap } from '@angular/cdk/a11y';
import { OverlayRef } from '@angular/cdk/overlay';
import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
    ChangeDetectorRef,
    ComponentRef,
    ElementRef,
    EmbeddedViewRef,
    EventEmitter,
    NgZone,
    Renderer2
} from '@angular/core';
import { getElementOffset } from '../facade';

import { ModalRef } from './modal-ref';
import { ModalOptions } from './modal-types';

export function throwModalContentAlreadyAttachedError(): never {
    throw Error('Attempting to attach modal content after content is already attached');
}

const ZOOM_CLASS_NAME_MAP = {
    enter: 'zoom-enter',
    enterActive: 'zoom-enter-active',
    leave: 'zoom-leave',
    leaveActive: 'zoom-leave-active'
};

const FADE_CLASS_NAME_MAP = {
    enter: 'fade-enter',
    enterActive: 'fade-enter-active',
    leave: 'fade-leave',
    leaveActive: 'fade-leave-active'
};

export class BaseModalContainer extends BasePortalOutlet {

    portalOutlet: CdkPortalOutlet;
    modalElementRef: ElementRef<HTMLDivElement>;

    animationStateChanged = new EventEmitter<AnimationEvent>();
    containerClick = new EventEmitter<void>();
    cancelTriggered = new EventEmitter<void>();
    okTriggered = new EventEmitter<void>();

    state: 'void' | 'enter' | 'exit' = 'enter';
    document: Document;
    modalRef: ModalRef;

    private elementFocusedBeforeModalWasOpened: HTMLElement = null;
    private focusTrap: FocusTrap;

    constructor(
        protected elementRef: ElementRef,
        protected focusTrapFactory: ConfigurableFocusTrapFactory,
        protected cdr: ChangeDetectorRef,
        protected render: Renderer2,
        protected zone: NgZone,
        protected overlayRef: OverlayRef,
        public config: ModalOptions,
        document?: any) {
        super();
        this.document = document;
    }

    click(e: MouseEvent): void {
        let target = e.target as HTMLElement;
        if (target === this.modalElementRef.nativeElement) {
            this.containerClick.emit();
        }
    }

    attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
        if (this.portalOutlet.hasAttached()) {
            throwModalContentAlreadyAttachedError();
        }
        this.savePreviouslyFocusedElement();
        this.setModalTransformOrigin();
        return this.portalOutlet.attachComponentPortal(portal);
    }

    attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
        if (this.portalOutlet.hasAttached()) {
            throwModalContentAlreadyAttachedError();
        }
        this.savePreviouslyFocusedElement();
        return this.portalOutlet.attachTemplatePortal(portal);
    }

    getNativeElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }


    private setModalTransformOrigin(): void {
        const modalElement = this.modalElementRef.nativeElement;
        if (this.elementFocusedBeforeModalWasOpened as HTMLElement) {
            const previouslyDOMRect = this.elementFocusedBeforeModalWasOpened.getBoundingClientRect();
            const lastPosition = getElementOffset(this.elementFocusedBeforeModalWasOpened);
            const x = lastPosition.left + previouslyDOMRect.width / 2;
            const y = lastPosition.top + previouslyDOMRect.height / 2;
            const transformOrigin = `${x - modalElement.offsetLeft}px ${y - modalElement.offsetTop}px 0px`;
            this.render.setStyle(modalElement, 'transform-origin', transformOrigin);
        }
    }

    private savePreviouslyFocusedElement(): void {
        this.elementFocusedBeforeModalWasOpened = this.document.activeElement as HTMLElement;
        Promise.resolve().then(() => this.elementRef.nativeElement?.focus());
    }

    private trapFocus(): void {
        const element = this.elementRef.nativeElement;

        if (!this.focusTrap) {
            this.focusTrap = this.focusTrapFactory.create(element);
        }

        if (this.config.autofocus) {
            this.focusTrap.focusInitialElementWhenReady().then();
        } else {
            const activeElement = this.document.activeElement;
            if (activeElement !== element && !element.contains(activeElement)) {
                element.focus();
            }
        }
    }

    private restoreFocus(): void {
        const toFocus = this.elementFocusedBeforeModalWasOpened as HTMLElement;

        if (toFocus && typeof toFocus.focus === 'function') {
            const activeElement = this.document.activeElement as Element;
            const element = this.elementRef.nativeElement;

            if (!activeElement || activeElement === this.document.body || activeElement === element || element.contains(activeElement)) {
                toFocus.focus();
            }
        }

        if (this.focusTrap) {
            this.focusTrap.destroy();
        }
    }

    private setEnterAnimationClass(): void {
        this.zone.runOutsideAngular(() => {
            // Make sure to set the `TransformOrigin` style before set the modelElement's class names
            this.setModalTransformOrigin();
            const modalElement = this.modalElementRef.nativeElement;
            const backdropElement = this.overlayRef.backdropElement;
            this.render.addClass(modalElement, ZOOM_CLASS_NAME_MAP.enter);
            this.render.addClass(modalElement, ZOOM_CLASS_NAME_MAP.enterActive);
            this.render.addClass(backdropElement, FADE_CLASS_NAME_MAP.enter);
            this.render.addClass(backdropElement, FADE_CLASS_NAME_MAP.enterActive);
        });
    }

    private setExitAnimationClass(): void {
        this.zone.runOutsideAngular(() => {
            const modalElement = this.modalElementRef.nativeElement;
            const backdropElement = this.overlayRef.backdropElement;
            this.render.addClass(modalElement, ZOOM_CLASS_NAME_MAP.leave);
            this.render.addClass(modalElement, ZOOM_CLASS_NAME_MAP.leaveActive);
            this.render.addClass(backdropElement, FADE_CLASS_NAME_MAP.leave);
            this.render.addClass(backdropElement, FADE_CLASS_NAME_MAP.leaveActive);
        });
    }

    private cleanAnimationClass(): void {
        this.zone.runOutsideAngular(() => {
            const backdropElement = this.overlayRef.backdropElement;
            const modalElement = this.modalElementRef.nativeElement;
            this.render.removeClass(modalElement, ZOOM_CLASS_NAME_MAP.enter);
            this.render.removeClass(modalElement, ZOOM_CLASS_NAME_MAP.enterActive);
            this.render.removeClass(modalElement, ZOOM_CLASS_NAME_MAP.leave);
            this.render.removeClass(modalElement, ZOOM_CLASS_NAME_MAP.leaveActive);

            if (backdropElement) {
                this.render.removeClass(backdropElement, FADE_CLASS_NAME_MAP.enter);
                this.render.removeClass(backdropElement, FADE_CLASS_NAME_MAP.enterActive);
            }
        });
    }


    onAnimationDone(event: AnimationEvent): void {
        if (event.toState === 'enter') {
            this.trapFocus();
        } else if (event.toState === 'exit') {
            this.restoreFocus();
        }
        this.cleanAnimationClass();
        this.animationStateChanged.emit(event);
    }

    onAnimationStart(event: AnimationEvent): void {
        if (event.toState === 'enter') {
            this.setEnterAnimationClass();
        } else if (event.toState === 'exit') {
            this.setExitAnimationClass();
        }
        this.animationStateChanged.emit(event);
    }

    startExitAnimation(): void {
        this.state = 'exit';
        this.cdr.markForCheck();
    }
}
