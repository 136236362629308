import { Injectable } from '@angular/core';
import { isPresent } from '../facade';
import { Checkable } from './batch.select.model';

let empty = () => undefined;

@Injectable()
export class BatchSelectService<T extends Checkable> {

    private items: Array<T> = [];
    private checkedItems: Array<T> = [];
    private key: string;
    all: boolean;
    toggleItemCall: (item: T) => void;

    constructor() {
    }

    init(items: Array<T>, key: string = 'id', toggleItemCall: (item: T) => void = empty): void {
        this.items = items;
        this.checkedItems = items.filter(item => item.checked);
        this.key = key;
        this.all = items.length > 0 && items.every(item => item.checked);
        this.toggleItemCall = toggleItemCall;
    }

    changeItems(items: Array<T>): void {
        this.items = items;
        items.forEach(item => item.checked = item.checked || this.checkedItems.some(value => value[this.key] === item[this.key]));
        this.all = items.length > 0 && items.every(item => item.checked);
    }

    change(item: T, checked: boolean): void {
        item.checked = checked;
        this.checkedItem(item);
        this.all = this.items.every(v => v.checked);
    }

    toggle(item: T): void {
        this.checkedItem(item);
        this.all = this.items.filter(v => !v.disabled).every(v => v.checked);
    }

    changeAll(): void {
        this.items.forEach(item => {
            if (item.disabled) {
                return;
            }
            item.checked = this.all;
            this.checkedItem(item);
        });
    }

    private checkedItem(item: T): void {
        let checkedItem = this.checkedItems.find(value => value[this.key] === item[this.key]);
        this.toggleItemCall(item);
        if (!item.checked) {
            this.checkedItems.remove(checkedItem);
        } else if (!isPresent(checkedItem)) {
            this.checkedItems.push(item);
        }
    }

    clearChecked(): void {
        this.items.forEach(i => i.checked = false);
        this.checkedItems.length = 0;
        this.all = false;
    }

    clear(): void {
        this.clearChecked();
        this.items = [];
    }

    getCheckedRecords(): Array<T> {
        return this.checkedItems;
    }

}
