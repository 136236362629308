import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ResizableDirective } from './resizable.directive';
import { TableColResizableDirective } from './table.col.resizable';
import { VerticalResizeComponent } from './vertical.resize.component';


@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        ResizableDirective,
        TableColResizableDirective,
        VerticalResizeComponent
    ],
    declarations: [
        ResizableDirective,
        TableColResizableDirective,
        VerticalResizeComponent
    ]
})
export class ResizableModule {
}
