import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SubmitProcessor } from 'core';
import { UIPage } from 'app/ui/page/ui.page.model';
import { UIPageStepHolder } from 'app/ui/page/step/ui.page.step.holder';

@Injectable()
export class PageTransformSubmit implements SubmitProcessor {

    constructor(private holder: UIPageStepHolder) {
    }

    postProcess(item: UIPage): Observable<any> {
        if (item.edit_type === 'step') {
            item.script = this.holder.script.getValue();
        }
        return of(item);
    }
}
