export const VERSION_STATE_KEY = ['unconfirmed', 'confirmed', 'changed', 'complete', 'progressing'];
export const VERSION_STATE_NAME = ['待确认', '已确认', '有变更', '完成', '进行中'];

export const DEMAND_STATUS_KEY = [0, 1, 2, 3, -1];
export const DEMAND_STATUS_NAME = ['待测试', '测试中', '已完成', '已取消', '删除'];

export const DEMAND_STATUS_DETAIL_KEY = [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
export const DEMAND_STATUS_DETAIL_NAME = ['删除', '待测试', '验收通过', '测试中', '挂起', '验收不通过', '未交付', '阻塞', '延期', '尚未验收', '无需测试', '废弃', '需求已上线'];

export const DEMAND_LEVEL_KEYS = ['low', 'medium', 'high'];
export const DEMAND_LEVEL_NAMES = ['低', '中', '高'];

export const MANUAL_TEST_STATUS_KEYS = [0,         1,       -1,        2,      3,        4,          5,       6,         7];
export const MANUAL_TEST_STATUS_NAMES = ['待处理', '已处理', '已删除', '待审核', '审核通过', '审核不通过', '待审核', '用例审核通过', '用例审核不通过'];
export const TEST_TASK_QUERY_STATUS_KEYS = [0, 1, 2, 3, 4];

export const WORKBENCH_TASK_STATUS_KEYS = [0, 1, -1, 2, 3, 4, 5, 6, 7];
export const WORKBENCH_TASK_STATUS_NAMES = ['待处理', '已完成', '已失效', '待审核', '审核通过', '审核拒绝', '待审核', '审核通过', '审核拒绝'];

export const WORKBENCH_TASK_TYPE_KEYS = ['audittask', 'testtask', 'autotask', 'bug', 'approvetask', 'taskcaseaudit'];
export const WORKBENCH_TASK_TYPE_NAMES = ['用例审核', '测试任务', '自动化任务', '缺陷', '测试任务审核', '任务用例审核'];

export const TEST_CASE_RESULT_KEYS = [-1, 0, 1, 2, 3, 4];
export const TEST_CASE_RESULT_NAMES = ['未测试', '测试通过', '测试不通过', 'N/A', '已阻塞', '执行中'];

export const TEST_AUTO_CASE_RESULT_KEYS = ['pass', 'noPass', 'waitForTest'];
export const TEST_AUTO_CASE_RESULT_NAMES = ['成功', '失败', '未执行'];

export const BUG_STATUS_KEYS = ['wt4cfm', 'wt4rslv', 'wt4vry', 'reopen', 'closed'];
export const BUG_STATUS_NAMES = ['待确认', '待解决', '待验证', '重处理', '已关闭'];

export const BUG_CLOSE_REASON_KEYS = ['verified', 'nobug', 'other'];
export const BUG_CLOSE_REASON_NAMES = ['验证通过', '非缺陷', '其它原因'];

export const BUG_WORK_TYPE_KEYS = ['created', 'resolved', 'closed'];
export const BUG_WORK_TYPE_NAMES = ['发现', '解决', '验证'];

export const CASE_EXPORT_FIELDS_KEYS = ['input_desc', 'actual_input', 'expect_result', 'check_point_desc', 'actual_result'];
export const CASE_EXPORT_FIELDS_NAMES = ['输入数据说明', '输入数据', '预期结果', '检查点', '检查点说明'];
