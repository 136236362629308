import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TipInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(res => {
            if (!(res instanceof HttpResponse)) {
                return;
            }
            let body = res.body;
            if (typeof body !== 'object') {
                return;
            }
            if (body?.show_tip && body?.msg) {
                toastr.success(body.msg);
            }
        }));
    }
}
