import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { Observable } from 'rxjs';
import { ComponentCache } from './component.cache.model';

@Injectable({ providedIn: 'root' })
export class ComponentCacheService {

    private baseUrl = '/xltp/sys/searchcache';

    constructor(private http: HttpClient) {
    }

    qry(params: any): Observable<Array<ComponentCache>> {
        return this.http.get<Array<ComponentCache>>(this.baseUrl, { params });
    }

    update(item: any): Observable<any> {
        let headers = new HttpHeaders().set(Headers.BLOCK, '');
        return this.http.post(this.baseUrl, item, { headers });
    }
}
