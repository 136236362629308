import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { ViewportRuler } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class TreeVirtualScroll implements AfterViewInit, OnDestroy {

    @Input() virtualHeightDiff: number = 70;

    virtualHeight: number = 30 * 10;

    private destroy$ = new Subject();

    protected constructor(protected elementRef: ElementRef<HTMLElement>,
                          protected cdRef: ChangeDetectorRef,
                          protected viewport: ViewportRuler) {
        this.viewport.change().pipe(takeUntil(this.destroy$)).subscribe(() => this.changeTreeHeight());
    }

    protected changeTreeHeight(): void {
        setTimeout(() => {
            this.virtualHeight = this.elementRef.nativeElement.clientHeight - this.virtualHeightDiff;
            this.render();
        });
    }

    ngAfterViewInit(): void {
        this.changeTreeHeight();
    }

    render(): void {
        this.cdRef.markForCheck();
    }

    ngOnDestroy(): void {
        this.destroy$.next('');
        this.destroy$.complete();
        this.afterDestroy();
    }

    afterDestroy(): void {
    }
}
