import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DrawerRef } from '../../drawer';

@Component({
    selector: 'ai-edit-link',
    templateUrl: 'link.component.html'
})
export class LinkDialogComponent implements OnInit, OnDestroy {

    @Input() context: any;

    item: { text?: string, url?: string, isNewWindow?: boolean, checkProtocol?: boolean } = { isNewWindow: true };

    constructor(public drawerRef: DrawerRef) {
    }

    ngOnInit(): void {
    }

    submit(): void {
        this.drawerRef.close(this.item);
    }

    ngOnDestroy(): void {
    }
}
