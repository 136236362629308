import { EventNames } from 'app/app.constants';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EventManager } from 'share';

export abstract class ProgressComponent {

    index: number;

    protected constructor(protected eventManager?: EventManager) {
        this.index = 0;
        eventManager?.subscribe(EventNames.CHANGE_PROGRESS_INDEX, i => this.index = i);
    }

 
    get progresses(): Array<string> {
        return [];
    }

    changeStep(step: number,txt?:any): void {
        console.log(txt,'pppppppppppppp')
        let index = this.index + step;
        if (index < 0) {
            this.index = 0;
            return;
        }
        if(txt=='我是工厂'){
            this.beforeChange(this.index, index).pipe(filter(v => v)).subscribe(() => {
                if (index >= this.progresses.length) {
                    this.index = this.progresses.length - 1;
                    this.submit();
                    return;
                }
                this.index = index;
            });
        }else{
              // this.beforeChange(this.index, index).pipe(filter(v => v)).subscribe(() => {
                if (index >= this.progresses.length) {
                    this.index = this.progresses.length - 1;
                    this.submit();
                    return;
                }
                this.index = index;
            // });
        }
      
    }

    beforeChange(oldValue: number, newValue: number): Observable<boolean> {
        return of(true);
    }

    abstract submit();
    
}