import { ChangeDetectorRef, Directive, Input, OnDestroy } from '@angular/core';
import { isPresent } from '../facade';
import { DragRowDirective } from './drag.row';

@Directive({
    selector: '[drag-table]' // tslint:disable-line
})
export class DragTableDirective implements OnDestroy {

    @Input() orderKey: string;

    private _items: Array<any> = [];
    private dragItem: any;
    private rows: Set<DragRowDirective> = new Set();

    constructor(private cdRef: ChangeDetectorRef) {
    }

    @Input('drag-table')
    set items(value: Array<any>) {
        this._items = value;
        this.rows.clear();
    }

    addRow(row: DragRowDirective): void {
        this.rows.add(row);
    }

    start(item: any): void {
        this.dragItem = item;
    }

    enter(): void {
        this.rows.forEach(row => row.clearDragging());
    }

    ngOnDestroy(): void {
        this.rows.clear();
    }

    drop(to: any): void {
        const from: any = this.dragItem;
        if (!isPresent(from) || from === to) {
            return;
        }

        if (isPresent(this.orderKey)) {
            this.moveWithOrderKey(from, to);
        } else {
            this.move(from, to);
        }
        this.dragItem = undefined;
        this.cdRef.markForCheck();
    }

    private move(from: any, to: any): void {
        let fromIndex = this._items.findIndex(item => item === from);
        let toIndex = this._items.findIndex(item => item === to);
        this._items.move(fromIndex, toIndex);
    }

    private moveWithOrderKey(from: any, to: any): void {
        let fromIndex = from[this.orderKey];
        let toIndex = to[this.orderKey];
        toIndex = fromIndex > toIndex ? toIndex - 1 : toIndex;
        if (toIndex < fromIndex) {
            this._items.filter(item => item[this.orderKey] > toIndex && item[this.orderKey] < fromIndex).forEach(item => {
                item[this.orderKey]++;
                item.operate_type = item.operate_type || 'U';
            });
            from[this.orderKey] = toIndex + 1;
        } else {
            this._items.filter(item => item[this.orderKey] > fromIndex && item[this.orderKey] <= toIndex).forEach(item => {
                item[this.orderKey]--;
                item.operate_type = item.operate_type || 'U';
            });
            from[this.orderKey] = toIndex;
        }
        from.operate_type = from.operate_type || 'U';
    }
}
