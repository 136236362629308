import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { TreeNode, TreeNodeKey } from './tree-base-node';
import { flattenTreeData, isCheckDisabled, isInArray } from './tree-util';
import { FormatEmitEvent } from './tree.definitions';

@Injectable()
export class TreeBaseService {
    DRAG_SIDE_RANGE = 0.25;
    DRAG_MIN_GAP = 2;

    isCheckStrictly: boolean = false;
    isMultiple: boolean = false;
    selectedNode!: TreeNode;
    rootNodes: TreeNode[] = [];
    flattenNodes$ = new BehaviorSubject<TreeNode[]>([]);
    selectedNodeList: TreeNode[] = [];
    expandedNodeList: TreeNode[] = [];
    checkedNodeList: TreeNode[] = [];
    halfCheckedNodeList: TreeNode[] = [];
    matchedNodeList: TreeNode[] = [];

    initTree(nzNodes: TreeNode[]): void {
        this.rootNodes = nzNodes;
        this.expandedNodeList = [];
        this.selectedNodeList = [];
        this.halfCheckedNodeList = [];
        this.checkedNodeList = [];
        this.matchedNodeList = [];
    }

    flattenTreeData(nzNodes: TreeNode[], expandedKeys: TreeNodeKey[] | true = []): void {
        this.flattenNodes$.next(flattenTreeData(nzNodes, expandedKeys).map(item => item.data));
    }

    getSelectedNode(): TreeNode | null {
        return this.selectedNode;
    }

    /**
     * get some list
     */
    getSelectedNodeList(): TreeNode[] {
        return this.conductNodeState('select');
    }

    /**
     * return checked nodes
     */
    getCheckedNodeList(): TreeNode[] {
        return this.conductNodeState('check');
    }

    getHalfCheckedNodeList(): TreeNode[] {
        return this.conductNodeState('halfCheck');
    }

    /**
     * return expanded nodes
     */
    getExpandedNodeList(): TreeNode[] {
        return this.conductNodeState('expand');
    }

    /**
     * return search matched nodes
     */
    getMatchedNodeList(): TreeNode[] {
        return this.conductNodeState('match');
    }

    isArrayOfTreeNode(value: any[]): boolean {
        return value.every(item => item instanceof TreeNode);
    }

    /**
     * set drag node
     */
    setSelectedNode(node: TreeNode): void {
        this.selectedNode = node;
    }

    /**
     * set node selected status
     */
    setNodeActive(node: TreeNode): void {
        if (!this.isMultiple && node.isSelected) {
            this.selectedNodeList.forEach(n => {
                if (node.key !== n.key) {
                    // reset other nodes
                    n.isSelected = false;
                }
            });
            // single mode: remove pre node
            this.selectedNodeList = [];
        }
        this.setSelectedNodeList(node, this.isMultiple);
    }

    /**
     * add or remove node to selectedNodeList
     */
    setSelectedNodeList(node: TreeNode, isMultiple: boolean = false): void {
        const index = this.getIndexOfArray(this.selectedNodeList, node.key);
        if (isMultiple) {
            if (node.isSelected && index === -1) {
                this.selectedNodeList.push(node);
            }
        } else {
            if (node.isSelected && index === -1) {
                this.selectedNodeList = [node];
            }
        }
        if (!node.isSelected) {
            this.selectedNodeList = this.selectedNodeList.filter(n => n.key !== node.key);
        }
    }

    /**
     * merge checked nodes
     */
    setHalfCheckedNodeList(node: TreeNode): void {
        const index = this.getIndexOfArray(this.halfCheckedNodeList, node.key);
        if (node.isHalfChecked && index === -1) {
            this.halfCheckedNodeList.push(node);
        } else if (!node.isHalfChecked && index > -1) {
            this.halfCheckedNodeList = this.halfCheckedNodeList.filter(n => node.key !== n.key);
        }
    }

    setCheckedNodeList(node: TreeNode): void {
        const index = this.getIndexOfArray(this.checkedNodeList, node.key);
        if (node.isChecked && index === -1) {
            this.checkedNodeList.push(node);
        } else if (!node.isChecked && index > -1) {
            this.checkedNodeList = this.checkedNodeList.filter(n => node.key !== n.key);
        }
    }

    /**
     * conduct checked/selected/expanded keys
     */
    conductNodeState(type: string = 'check'): TreeNode[] {
        let resultNodesList: TreeNode[] = [];
        switch (type) {
            case 'select':
                resultNodesList = this.selectedNodeList;
                break;
            case 'expand':
                resultNodesList = this.expandedNodeList;
                break;
            case 'match':
                resultNodesList = this.matchedNodeList;
                break;
            case 'check':
                resultNodesList = this.checkedNodeList;
                const isIgnore = (node: TreeNode): boolean => {
                    const parentNode = node.getParentNode();
                    if (parentNode) {
                        if (this.checkedNodeList.findIndex(n => n.key === parentNode.key) > -1) {
                            return true;
                        } else {
                            return isIgnore(parentNode);
                        }
                    }
                    return false;
                };
                // merge checked
                if (!this.isCheckStrictly) {
                    resultNodesList = this.checkedNodeList.filter(n => !isIgnore(n));
                }
                break;
            case 'halfCheck':
                if (!this.isCheckStrictly) {
                    resultNodesList = this.halfCheckedNodeList;
                }
                break;
        }
        return resultNodesList;
    }

    updateExpandedNodes(node: TreeNode): void {
        if (node.isLeaf) {
            return;
        }
        const index = this.getIndexOfArray(this.expandedNodeList, node.key);
        if (node.isExpanded && index === -1) {
            this.expandedNodeList.push(node);
        } else if (!node.isExpanded && index > -1) {
            this.expandedNodeList.splice(index, 1);
        }
    }

    setMatchedNodeList(nodes: Array<TreeNode>): void {
        this.matchedNodeList = nodes;
    }

    refreshCheckState(isCheckStrictly: boolean = false): void {
        if (isCheckStrictly) {
            return;
        }
        this.checkedNodeList.forEach(node => this.conduct(node, isCheckStrictly));
    }

    // reset other node checked state based current node
    conduct(node: TreeNode, isCheckStrictly: boolean = false): void {
        const isChecked = node.isChecked;
        if (node && !isCheckStrictly) {
            this.conductUp(node);
            this.conductDown(node, isChecked);
        }
    }

    /**
     * 1、children half checked
     * 2、children all checked, parent checked
     * 3、no children checked
     */
    conductUp(node: TreeNode): void {
        const parentNode = node.getParentNode();
        if (parentNode) {
            if (!isCheckDisabled(parentNode)) {
                if (parentNode.children.every(child => isCheckDisabled(child) || (!child.isHalfChecked && child.isChecked))) {
                    parentNode.isChecked = true;
                    parentNode.isHalfChecked = false;
                } else if (parentNode.children.some(child => child.isHalfChecked || child.isChecked)) {
                    parentNode.isChecked = false;
                    parentNode.isHalfChecked = true;
                } else {
                    parentNode.isChecked = false;
                    parentNode.isHalfChecked = false;
                }
            }
            this.setCheckedNodeList(parentNode);
            this.setHalfCheckedNodeList(parentNode);
            this.conductUp(parentNode);
        }
    }

    /**
     * reset child check state
     */
    conductDown(node: TreeNode, value: boolean): void {
        if (!isCheckDisabled(node)) {
            node.isChecked = value;
            node.isHalfChecked = false;
            this.setCheckedNodeList(node);
            this.setHalfCheckedNodeList(node);
            node.children.forEach(n => {
                this.conductDown(n, value);
            });
        }
    }

    /**
     * flush after delete node
     */
    afterRemove(nodes: TreeNode[]): void {
        // to reset selectedNodeList & expandedNodeList
        const loopNode = (node: TreeNode) => {
            // remove selected node
            this.selectedNodeList = this.selectedNodeList.filter(n => n.key !== node.key);
            // remove expanded node
            this.expandedNodeList = this.expandedNodeList.filter(n => n.key !== node.key);
            // remove checked node
            this.checkedNodeList = this.checkedNodeList.filter(n => n.key !== node.key);
            if (node.children) {
                node.children.forEach(child => {
                    loopNode(child);
                });
            }
        };
        nodes.forEach(n => {
            loopNode(n);
        });
        this.refreshCheckState(this.isCheckStrictly);
    }

    /**
     * drag event
     */
    refreshDragNode(node: TreeNode): void {
        if (node.children.length === 0) {
            // until root
            this.conductUp(node);
        } else {
            node.children.forEach(child => {
                this.refreshDragNode(child);
            });
        }
    }

    // reset node level
    resetNodeLevel(node: TreeNode): void {
        const parentNode = node.getParentNode();
        if (parentNode) {
            node.level = parentNode.level + 1;
        } else {
            node.level = 0;
        }
        for (const child of node.children) {
            this.resetNodeLevel(child);
        }
    }

    calcDropPosition(event: DragEvent): number {
        const { clientY } = event;
        // to fix firefox undefined
        const { top, bottom, height } = event.srcElement
            ? (event.srcElement as Element).getBoundingClientRect()
            : (event.target as Element).getBoundingClientRect();
        const des = Math.max(height * this.DRAG_SIDE_RANGE, this.DRAG_MIN_GAP);

        if (clientY <= top + des) {
            return -1;
        } else if (clientY >= bottom - des) {
            return 1;
        }

        return 0;
    }

    dropAndApply(targetNode: TreeNode, dragPos: number = -1): void {
        if (!targetNode || dragPos > 1) {
            return;
        }
        const treeService = targetNode.treeService;
        const targetParent = targetNode.getParentNode();
        const isSelectedRootNode = this.selectedNode.getParentNode();
        // remove the dragNode
        if (isSelectedRootNode) {
            isSelectedRootNode.children = isSelectedRootNode.children.filter(n => n.key !== this.selectedNode.key);
        } else {
            this.rootNodes = this.rootNodes.filter(n => n.key !== this.selectedNode.key);
        }
        switch (dragPos) {
            case 0:
                targetNode.addChildren([this.selectedNode]);
                this.resetNodeLevel(targetNode);
                break;
            case -1:
            case 1:
                const tIndex = dragPos === 1 ? 1 : 0;
                if (targetParent) {
                    targetParent.addChildren([this.selectedNode], targetParent.children.indexOf(targetNode) + tIndex);
                    const parentNode = this.selectedNode.getParentNode();
                    if (parentNode) {
                        this.resetNodeLevel(parentNode);
                    }
                } else {
                    const targetIndex = this.rootNodes.indexOf(targetNode) + tIndex;
                    // Insert root node.
                    this.rootNodes.splice(targetIndex, 0, this.selectedNode);
                    this.rootNodes[targetIndex].parentNode = null;
                    this.resetNodeLevel(this.rootNodes[targetIndex]);
                }
                break;
        }
        // flush all nodes
        this.rootNodes.forEach(child => {
            if (!child.treeService) {
                child.service = treeService;
            }
            this.refreshDragNode(child);
        });
    }

    formatEvent(eventName: string, node: TreeNode | null, event: MouseEvent | DragEvent | null): FormatEmitEvent {
        const emitStructure: FormatEmitEvent = {
            eventName,
            node,
            event
        };
        switch (eventName) {
            case 'dragstart':
            case 'dragenter':
            case 'dragover':
            case 'dragleave':
            case 'drop':
            case 'dragend':
                Object.assign(emitStructure, { dragNode: this.getSelectedNode() });
                break;
            case 'click':
            case 'dblclick':
                Object.assign(emitStructure, { selectedKeys: this.selectedNodeList });
                Object.assign(emitStructure, { nodes: this.selectedNodeList });
                Object.assign(emitStructure, { keys: this.selectedNodeList.map(n => n.key) });
                break;
            case 'check':
                const checkedNodeList = this.getCheckedNodeList();
                Object.assign(emitStructure, { checkedKeys: checkedNodeList });
                Object.assign(emitStructure, { nodes: checkedNodeList });
                Object.assign(emitStructure, { keys: checkedNodeList.map(n => n.key) });
                break;
            case 'search':
                Object.assign(emitStructure, { matchedKeys: this.getMatchedNodeList() });
                Object.assign(emitStructure, { nodes: this.getMatchedNodeList() });
                Object.assign(emitStructure, { keys: this.getMatchedNodeList().map(n => n.key) });
                break;
            case 'expand':
                Object.assign(emitStructure, { nodes: this.expandedNodeList });
                Object.assign(emitStructure, { keys: this.expandedNodeList.map(n => n.key) });
                break;
        }
        return emitStructure;
    }


    getIndexOfArray(list: TreeNode[], key: string): number {
        return list.findIndex(v => v.key === key);
    }

    conductCheck(keys: TreeNodeKey[], checkStrictly: boolean): void {
        this.checkedNodeList = [];
        this.halfCheckedNodeList = [];
        const calc = (nodes: TreeNode[]) => {
            nodes.forEach(node => {
                if (keys === null) {
                    node.isChecked = !!node.origin.checked;
                } else {
                    if (isInArray(node.key, keys || [])) {
                        node.isChecked = true;
                        node.isHalfChecked = false;
                    } else {
                        node.isChecked = false;
                        node.isHalfChecked = false;
                    }
                }
                if (node.children.length > 0) {
                    calc(node.children);
                }
            });
        };
        calc(this.rootNodes);
        this.refreshCheckState(checkStrictly);
    }

    conductExpandedKeys(keys: TreeNodeKey[] | true = []): void {
        const expandedKeySet = new Set(keys === true ? [] : keys);
        this.expandedNodeList = [];
        const calc = (nodes: TreeNode[]) => {
            nodes.forEach(node => {
                node.setExpanded(keys === true || expandedKeySet.has(node.key) || node.isExpanded === true);
                if (node.isExpanded) {
                    this.updateExpandedNodes(node);
                }
                if (node.children.length > 0) {
                    calc(node.children);
                }
            });
        };
        calc(this.rootNodes);
    }

    conductSelectedKeys(keys: TreeNodeKey[], isMulti: boolean): void {
        this.selectedNodeList.forEach(node => (node.isSelected = false));
        this.selectedNodeList = [];
        const calc = (nodes: TreeNode[]): boolean => {
            return nodes.every(node => {
                if (isInArray(node.key, keys)) {
                    node.isSelected = true;
                    this.setSelectedNodeList(node);
                    if (!isMulti) {
                        // if not support multi select
                        return false;
                    }
                } else {
                    node.isSelected = false;
                }
                if (node.children.length > 0) {
                    // Recursion
                    return calc(node.children);
                }
                return true;
            });
        };
        calc(this.rootNodes);
    }

    expandParentNode(node: TreeNode): void {
        let parent = node.getParentNode();
        if (!parent) {
            return;
        }
        parent.canHide = false;
        parent.setExpanded(true);
        this.updateExpandedNodes(parent);
        this.expandParentNode(parent);
    }
}
