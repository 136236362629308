import { Injectable } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';

export interface ScrollbarOptions extends PerfectScrollbar.Options {
}

export enum ScrollbarEvents {
    SCROLL_Y = 'ps-scroll-y',
    SCROLL_X = 'ps-scroll-x',
    SCROLL_UP = 'ps-scroll-up',
    SCROLL_DOWN = 'ps-scroll-down',
    SCROLL_LEFT = 'ps-scroll-left',
    SCROLL_RIGHT = 'ps-scroll-right',
    Y_REACH_START = 'ps-y-reach-start',
    Y_REACH_END = 'ps-y-reach-end',
    X_REACH_START = 'ps-y-reach-start',
    X_REACH_END = 'ps-y-reach-end',
}

@Injectable()
export class ScrollbarConfig implements ScrollbarOptions {

    handlers?: string[];
    maxScrollbarLength?: number;
    minScrollbarLength?: number;
    scrollingThreshold?: number;
    scrollXMarginOffset?: number;
    scrollYMarginOffset?: number;
    suppressScrollX?: boolean;
    suppressScrollY?: boolean;
    swipeEasing?: boolean;
    useBothWheelAxes?: boolean;
    wheelPropagation?: boolean;
    wheelSpeed?: number;

    constructor() {
        this.handlers = ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'];
        this.wheelSpeed = 1;
        this.wheelPropagation = true;
        this.swipeEasing = true;
        this.minScrollbarLength = null;
        this.maxScrollbarLength = null;
        this.scrollingThreshold = 1000;
        this.useBothWheelAxes = false;
        this.suppressScrollX = true;
        this.suppressScrollY = false;
        this.scrollXMarginOffset = 0;
        this.scrollYMarginOffset = 0;
    }

    public update(options: ScrollbarOptions = {}): boolean {

        let update = false;

        for (let key of Object.keys(options)) {
            if (this[key] !== options[key]) {
                this[key] = options[key];
                update = true;
            }
        }
        return update;
    }
}
