import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SizeDSType } from '../types';

import { StepComponent } from './step.component';

export type DirectionType = 'horizontal' | 'vertical';
export type StatusType = 'wait' | 'process' | 'finish' | 'error';

export type ProgressDotTemplate = TemplateRef<{ $implicit: TemplateRef<void>; status: string; index: number }>;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    preserveWhitespaces: false,
    selector: 'ai-steps',
    exportAs: 'steps',
    templateUrl: 'steps.component.html',
    host: {
        '[class.steps-container]': 'true'
    }
})
export class StepsComponent implements OnChanges, OnInit, OnDestroy, AfterContentInit, AfterViewInit {

    @ContentChildren(StepComponent) steps: QueryList<StepComponent>;

    @ViewChild('steps', { static: true })
    stepsWrap: ElementRef<HTMLElement>;

    @Input() current = 0;
    @Input() direction: DirectionType = 'horizontal';
    @Input() labelPlacement: 'horizontal' | 'vertical' = 'horizontal';
    @Input() type: 'default' | 'navigation' = 'default';
    @Input() size: SizeDSType = 'small';
    @Input() startIndex = 0;
    @Input() status: StatusType = 'process';
    @Input() beforeChange: (oldValue, newValue) => Observable<boolean>;

    @Output() readonly currentChange = new EventEmitter<number>();

    showProcessDot = false;
    customProcessDotTemplate: TemplateRef<{ $implicit: TemplateRef<void>; status: string; index: number }>;

    private destroy$ = new Subject<void>();
    private indexChangeSubscription: Subscription;

    constructor(private render: Renderer2, private elementRef: ElementRef<HTMLElement>) {
        this.beforeChange = () => of(true);
    }

    @Input()
    set progressDot(value: boolean | ProgressDotTemplate) {
        if (value instanceof TemplateRef) {
            this.showProcessDot = true;
            this.customProcessDotTemplate = value;
        } else {
            this.showProcessDot = value;
        }
        this.updateChildrenSteps();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.startIndex || changes.direction || changes.status || changes.current) {
            this.updateChildrenSteps();
        }
    }

    ngOnInit(): void {
        this.updateChildrenSteps();
    }

    ngAfterContentInit(): void {
        this.steps?.changes.pipe(startWith(true), takeUntil(this.destroy$)).subscribe(() => this.updateChildrenSteps());
    }

    ngAfterViewInit(): void {
        this.initStepsWidth();
    }

    private updateChildrenSteps(): void {
        if (!this.steps) {
            return;
        }
        const length = this.steps.length;
        this.steps.toArray().forEach((step, i) => {
            Promise.resolve().then(() => {
                step.outStatus = this.status;
                step.showProcessDot = this.showProcessDot;
                if (this.customProcessDotTemplate) {
                    step.customProcessTemplate = this.customProcessDotTemplate;
                }
                step.direction = this.direction;
                step.index = i + this.startIndex;
                step.currentIndex = this.current;
                step.last = length === i + 1;
                step.markForCheck();
            });
        });
        this.initStepsWidth();
        this.indexChangeSubscription?.unsubscribe();
        let index: number;
        this.indexChangeSubscription = merge(...this.steps.map(step => step.click$))
            .pipe(
                takeUntil(this.destroy$),
                tap(i => index = i),
                switchMap(i => {
                    try {
                        return this.beforeChange(this.current, i);
                    } catch (e) {
                    }
                    return of(false);
                }),
                filter(v => v)
            ).subscribe(() => this.currentChange.next(index));
    }

    private initStepsWidth(): void {
        this.render.setStyle(this.stepsWrap.nativeElement, 'max-width', `${Math.min(this.steps.length * 20 - 10, Math.floor(this.elementRef.nativeElement.offsetWidth * 0.8 / 16))}rem`);
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
