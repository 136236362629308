import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RedirectInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req).pipe(catchError<any, any>((res: HttpErrorResponse) => {
            if (!res.url.includes('/cas/login') || !res.headers.get('content-type').includes('html')) {
                return throwError(res);
            }
            if (window !== window.top) {
                return of(new HttpResponse({
                    body: res.error.text,
                    headers: res.headers,
                    status: res.status,
                    statusText: res.statusText,
                    url: res.url
                }));
            }
            location.href = res.url + location.hash;
            return EMPTY;
        }));
    }
}
