import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { Observable } from 'rxjs';

@Injectable()
export class MsgSendService {

    constructor(private http: HttpClient) {
    }

    sendMsgToPhone(phone: string, type: string): Observable<any> {
        let headers = new HttpHeaders().set(Headers.BLOCK, '发送验证码...');
        return this.http.get(`/xltp/sys/user/login/verifysmscode`, { params: { content: phone, type }, headers });
    }

    sendMsgToEmail(email: string): Observable<any> {
        let headers = new HttpHeaders().set(Headers.BLOCK, '发送验证码...');
        return this.http.post(`/xltp/sys/user/verifycode/mail`, { email }, { headers, responseType: 'text' });
    }
}
