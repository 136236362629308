import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Directive } from '@angular/core';

@Directive({
    selector: '[cdkConnectedOverlay][transparentOverlay]', //tslint:disable-line
    exportAs: 'transparentOverlay'
})
export class TransparentOverlayDirective {

    constructor(private cdkConnectedOverlay: CdkConnectedOverlay) {
        this.cdkConnectedOverlay.backdropClass = 'nz-overlay-transparent-backdrop';
    }
}
