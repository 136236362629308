import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    ExistingProvider,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputBoolean, toBoolean } from '../facade';
import { FunctionProp } from '../types';
import { cloneDate, CompatibleValue, DateFnsCalendar } from './calendar';
import { DatepickerService } from './datepicker.service';

import { PickerComponent } from './picker.component';
import { CompatibleDate, DisabledTimeFn, PanelMode, PresetRanges, SupportTimeOptions } from './standard-types';

const VALUE_ACCESSOR: ExistingProvider = {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => DatePickerComponent)
};

@Component({
    selector: 'ai-datepicker,ai-datepicker-week,ai-datepicker-month,ai-datepicker-year,ai-datepicker-range',
    exportAs: 'datePicker',
    templateUrl: 'datepicker.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DatepickerService,
        VALUE_ACCESSOR
    ]
})
export class DatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

    mode: PanelMode | PanelMode[] = 'date';
    isRange: boolean = false; // Indicate whether the value is a range value
    showWeek: boolean = false; // Should show as week picker

    @Input() @InputBoolean() disabled: boolean = false;
    @Input() disabledDate: (d: Date) => boolean;
    @Input() placeholder: string | string[];
    @Input() popupClassName: string;
    @Input() format: string;
    @Input() dateRender: FunctionProp<TemplateRef<Date> | string>;
    @Input() disabledTime: DisabledTimeFn;
    @Input() @InputBoolean() showToday: boolean = true;
    @Input() ranges: PresetRanges;
    @Input() defaultPickerValue: CompatibleDate | null = null;

    @Output() readonly ngOnPanelChange = new EventEmitter<PanelMode | PanelMode[]>();
    @Output() readonly ngOnCalendarChange = new EventEmitter<Array<Date | null>>();
    @Output() readonly ngOnOk = new EventEmitter<CompatibleDate | null>();
    @Output() readonly ngOnOpenChange = new EventEmitter<boolean>();

    @ViewChild(PickerComponent, { static: true }) protected picker: PickerComponent;

    protected destroyed$: Subject<void> = new Subject();
    private _showTime: SupportTimeOptions | boolean;
    private onChangeFn: any = () => void 0;
    private onTouchedFn: any = () => void 0;

    constructor(public datePickerService: DatepickerService, protected cdRef: ChangeDetectorRef) {
    }

    get showTime(): SupportTimeOptions | boolean {
        return this._showTime;
    }

    @Input()
    set showTime(value: SupportTimeOptions | boolean) {
        this._showTime = typeof value === 'object' ? value : toBoolean(value);
    }

    get realOpenState(): boolean {
        return this.picker.animationOpenState;
    }

    ngOnInit(): void {

        this.datePickerService.isRange = this.isRange;
        this.datePickerService.initValue();
        this.datePickerService.emitValue$.pipe(takeUntil(this.destroyed$)).subscribe(_ => {
            const value = this.datePickerService.value;
            this.datePickerService.initialValue = cloneDate(value);
            if (this.isRange) {
                const vAsRange = value as DateFnsCalendar[];
                if (vAsRange.length) {
                    this.onChangeFn([vAsRange[0].nativeDate, vAsRange[1].nativeDate]);
                } else {
                    this.onChangeFn([]);
                }
            } else {
                if (value) {
                    this.onChangeFn((value as DateFnsCalendar).nativeDate);
                } else {
                    this.onChangeFn(null);
                }
            }
            this.onTouchedFn();
            this.picker.hideOverlay();
        });

        if (!this.format) {
            if (this.showWeek) {
                this.format = 'yyyy-ww';
            } else {
                this.format = this.showTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd';
            }
        }
    }

    onOpenChange(open: boolean): void {
        this.ngOnOpenChange.emit(open);
    }

    writeValue(value: CompatibleDate): void {
        this.setValue(value);
        this.cdRef.markForCheck();
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }

    private setValue(value: CompatibleDate): void {
        const newValue: CompatibleValue = this.datePickerService.makeValue(value);
        this.datePickerService.setValue(newValue);
        this.datePickerService.initialValue = newValue;
    }

    get realShowToday(): boolean {
        return this.mode === 'date' && this.showToday;
    }

    onPanelModeChange(panelMode: PanelMode | PanelMode[]): void {
        this.ngOnPanelChange.emit(panelMode);
    }

    onCalendarChange(value: DateFnsCalendar[]): void {
        if (this.isRange) {
            const rangeValue = value.filter(x => x instanceof DateFnsCalendar).map(x => x.nativeDate);
            this.ngOnCalendarChange.emit(rangeValue);
        }
    }

    onResultOk(): void {
        if (this.isRange) {
            const value = this.datePickerService.value as DateFnsCalendar[];
            if (value.length) {
                this.ngOnOk.emit([value[0].nativeDate, value[1].nativeDate]);
            } else {
                this.ngOnOk.emit([]);
            }
        } else {
            if (this.datePickerService.value) {
                this.ngOnOk.emit((this.datePickerService.value as DateFnsCalendar).nativeDate);
            } else {
                this.ngOnOk.emit(null);
            }
        }
        this.datePickerService.emitValue$.next(undefined);
    }

    ngOnDestroy(): void {
        this.destroyed$.next(undefined);
        this.destroyed$.complete();
    }
}
