import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OutletModule } from '../outlet/outlet.module';
import { AiOverlayModule } from '../overlay/overlay.module';
import { ToolTipComponent, TooltipDirective } from './tooltip';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        AiOverlayModule,
        OutletModule
    ],
    entryComponents: [
        ToolTipComponent
    ],
    declarations: [
        ToolTipComponent,
        TooltipDirective
    ],
    exports: [
        ToolTipComponent,
        TooltipDirective
    ]
})
export class ToolTipModule {
}
