import { InjectionToken, TemplateRef, Type, ViewContainerRef } from '@angular/core';
import { Observable, of } from 'rxjs';

export type ModalTypes = 'default' | 'confirm'; // Different modal styles we have supported

export type ConfirmType = 'confirm' | 'info' | 'success' | 'error' | 'warning'; // Subtypes of Confirm Modal

export const MODAL_OPTIONS = new InjectionToken<ModalOptions>('modal options');

export interface ModalOptions<T = any, R = any> {
    closable?: boolean;
    okDisabled?: boolean;
    cancelDisabled?: boolean;
    autofocus?: 'ok' | 'cancel' | 'auto' | null;
    mask?: boolean;
    maskClosable?: boolean;
    keyboard?: boolean;
    zIndex?: number;
    modalType?: ModalTypes;
    modalClass?: string;
    title?: string | TemplateRef<{}>;
    cancelText?: string | null;
    okText?: string | null;

    content?: string | TemplateRef<any> | Type<T>;
    inputs?: any;

    closeOnNavigation?: boolean;
    viewContainerRef?: ViewContainerRef;

    iconType?: string;

    beforeClose?(): Observable<boolean>;
}

export const DEFAULT_OPTIONS: ModalOptions = {
    closable: true,
    okDisabled: false,
    cancelDisabled: false,
    autofocus: 'auto',
    mask: true,
    maskClosable: true,
    keyboard: true,
    zIndex: 1000,
    modalType: 'default',
    closeOnNavigation: true,
    iconType: 'icon-question-circle',
    beforeClose: () => of(true)
};

export interface ModalButtonOptions<T = any> {
    label: string;
    ghost?: boolean;
    autoLoading?: boolean;

    show?: boolean | ((this: ModalButtonOptions<T>, contentComponentInstance?: T) => boolean);
    loading?: boolean | ((this: ModalButtonOptions<T>, contentComponentInstance?: T) => boolean); // This prop CAN'T use with autoLoading=true
    disabled?: boolean | ((this: ModalButtonOptions<T>, contentComponentInstance?: T) => boolean);

    onClick?(this: ModalButtonOptions<T>, contentComponentInstance?: T): any | Promise<any>;

    [key: string]: any;
}
