export type Position = 'left-top' | 'top-right' | 'left-bottom' | 'right-bottom';

export function locationElement(element: HTMLElement, target: HTMLElement, position: Position) {
    element.style.left = 'auto';
    element.style.top = 'auto';
    element.style.right = 'auto';
    element.style.bottom = 'auto';

    let rect = target.getBoundingClientRect();
    if (position.includes('bottom')) {
        element.style.top = rect.bottom + 'px';
    }

    if (position.includes('left')) {
        element.style.left = rect.left + 'px';
    }

    if (position.includes('right')) {
        element.style.right = (document.documentElement.clientWidth - rect.right) + 'px';
    }
}
