import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FunctionProp } from '../../types';
import { cloneDate, CompatibleValue, DateFnsCalendar, sortRangeValue } from '../calendar';
import { DatepickerService } from '../datepicker.service';
import {
    CompatibleDate,
    DisabledDateFn,
    DisabledTimeConfig,
    DisabledTimeFn,
    DisabledTimePartial,
    PanelMode,
    PresetRanges,
    RangePartType,
    SupportTimeOptions
} from '../standard-types';
import { getTimeConfig, isAllowedDate } from '../util';

@Component({
    selector: 'datepicker-range-popup', // tslint:disable-line
    exportAs: 'datepickerRangePopup',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'range-popup.component.html'

})
export class RangePopupComponent implements OnInit, OnChanges, OnDestroy {

    @Input() isRange: boolean;
    @Input() showWeek: boolean;
    @Input() format: string;
    @Input() placeholder: string | string[];
    @Input() disabledDate: DisabledDateFn;
    @Input() disabledTime: DisabledTimeFn; // This will lead to rebuild time options
    @Input() showToday: boolean;
    @Input() showTime: SupportTimeOptions | boolean;
    @Input() ranges: PresetRanges;
    @Input() dateRender: FunctionProp<TemplateRef<Date> | string>;
    @Input() panelMode: PanelMode | PanelMode[];
    @Input() defaultPickerValue: CompatibleDate;

    @Output() readonly panelModeChange = new EventEmitter<PanelMode | PanelMode[]>();
    @Output() readonly calendarChange = new EventEmitter<CompatibleValue>();
    @Output() readonly resultOk = new EventEmitter<void>(); // Emitted when done with date selecting

    endPanelMode: PanelMode | PanelMode[] = 'date';
    timeOptions: SupportTimeOptions | SupportTimeOptions[] | null;
    hoverValue: DateFnsCalendar[] = [];

    private destroy$ = new Subject();


    constructor(public cdRef: ChangeDetectorRef, public datePickerService: DatepickerService) {
    }

    get hasTimePicker(): boolean {
        return !!this.showTime;
    }

    get hasFooter(): boolean {
        return this.showToday || this.hasTimePicker || !!this.ranges;
    }


    ngOnInit(): void {
        this.datePickerService.valueChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.initActiveDate();
            this.cdRef.markForCheck();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Parse showTime options
        if (changes.showTime || changes.disabledTime) {
            if (this.showTime) {
                this.buildTimeOptions();
            }
        }
        if (changes.panelMode) {
            this.endPanelMode = this.panelMode;
        }
    }

    initActiveDate(): void {
        const activeDate = this.datePickerService.hasValue()
            ? this.datePickerService.value
            : this.datePickerService.makeValue(this.defaultPickerValue);
        this.datePickerService.setActiveDate(activeDate, !this.showTime);
    }

    onClickOk(): void {
        const otherPart = this.datePickerService.activeInput === 'left' ? 'right' : 'left';
        const selectedValue = this.datePickerService.value;
        if (this.isAllowed(selectedValue, true)) {
            this.resultOk.emit();
        } else {
            if (this.isRange && this.isOneAllowed(selectedValue as DateFnsCalendar[])) {
                this.datePickerService.inputPartChange$.next(otherPart);
            } else {
                this.datePickerService.inputPartChange$.next(undefined);
            }
        }
    }

    onClickToday(value: DateFnsCalendar): void {
        this.changeValueFromSelect(value, !this.showTime);
    }

    onDayHover(value: DateFnsCalendar): void {
        if (!this.isRange) {
            return;
        }
        const otherInputIndex = { left: 1, right: 0 }[this.datePickerService.activeInput];
        const base = (this.datePickerService.value as DateFnsCalendar[])[otherInputIndex];
        if (base) {
            if (base.isBeforeDay(value)) {
                this.hoverValue = [base, value];
            } else {
                this.hoverValue = [value, base];
            }
        }
    }

    onPanelModeChange(mode: PanelMode, partType?: RangePartType): void {
        if (this.isRange) {
            const index = this.datePickerService.getActiveIndex(partType);
            if (index === 0) {
                this.panelMode = [mode, this.panelMode[1]] as PanelMode[];
            } else {
                this.panelMode = [this.panelMode[0], mode] as PanelMode[];
            }
        } else {
            this.panelMode = mode;
        }
        this.panelModeChange.emit(this.panelMode);
    }

    onActiveDateChange(value: DateFnsCalendar, partType: RangePartType): void {
        if (this.isRange) {
            if (partType === 'left') {
                this.datePickerService.activeDate = [value, value.addMonths(1)];
            } else {
                this.datePickerService.activeDate = [value.addMonths(-1), value];
            }
        } else {
            this.datePickerService.activeDate = value;
        }
        this.cdRef.markForCheck();
    }

    onSelectTime(value: DateFnsCalendar, partType?: RangePartType): void {
        if (this.isRange) {
            const newValue = cloneDate(this.datePickerService.value) as DateFnsCalendar[];
            const index = this.datePickerService.getActiveIndex(partType);
            newValue[index] = this.overrideHms(value, newValue[index]);
            this.datePickerService.setValue(newValue);
        } else {
            const newValue = this.overrideHms(value, this.datePickerService.value as DateFnsCalendar);
            this.datePickerService.setValue(newValue); // If not select a date currently, use today
        }
        this.datePickerService.inputPartChange$.next(undefined);
        this.buildTimeOptions();
    }

    changeValueFromSelect(value: DateFnsCalendar, emitValue: boolean = true): void {
        if (this.isRange) {
            let selectedValue: DateFnsCalendar[] = cloneDate(this.datePickerService.value) as DateFnsCalendar[];
            let otherPart: RangePartType;
            if (this.datePickerService.activeInput === 'left') {
                otherPart = 'right';
                selectedValue[0] = value;
            } else {
                otherPart = 'left';
                selectedValue[1] = value;
            }

            selectedValue = sortRangeValue(selectedValue);
            this.hoverValue = selectedValue;
            this.datePickerService.setValue(selectedValue);
            this.datePickerService.setActiveDate(selectedValue, !this.showTime);
            this.datePickerService.inputPartChange$.next(undefined);

            if (!this.isAllowed(selectedValue)) {
                return;
            }

            if (emitValue) {
                // If the other input has value
                if (this.isBothAllowed(selectedValue)) {
                    this.calendarChange.emit(selectedValue);
                    this.clearHoverValue();
                    this.datePickerService.emitValue$.next(undefined);
                } else {
                    this.calendarChange.emit([value.clone()]);
                    this.datePickerService.inputPartChange$.next(otherPart);
                }
            }
        } else {
            this.datePickerService.setValue(value);
            this.datePickerService.setActiveDate(value, !this.showTime);
            this.datePickerService.inputPartChange$.next(undefined);

            if (!this.isAllowed(value)) {
                return;
            }
            if (emitValue) {
                this.datePickerService.emitValue$.next(undefined);
            }
        }
    }

    getPanelMode(panelMode: PanelMode | PanelMode[], partType?: RangePartType): PanelMode {
        if (this.isRange) {
            return panelMode[this.datePickerService.getActiveIndex(partType)] as PanelMode;
        } else {
            return panelMode as PanelMode;
        }
    }

    getValue(partType?: RangePartType): DateFnsCalendar {
        if (this.isRange) {
            return ((this.datePickerService.value as DateFnsCalendar[]) || [])[this.datePickerService.getActiveIndex(partType)];
        } else {
            return this.datePickerService.value as DateFnsCalendar;
        }
    }

    getActiveDate(partType?: RangePartType): DateFnsCalendar {
        if (this.isRange) {
            return (this.datePickerService.activeDate as DateFnsCalendar[])[this.datePickerService.getActiveIndex(partType)];
        } else {
            return this.datePickerService.activeDate as DateFnsCalendar;
        }
    }

    disabledStartTime = (value: Date | Date[]): DisabledTimeConfig => {
        return this.disabledTime && this.disabledTime(value, 'start');
    };

    disabledEndTime = (value: Date | Date[]): DisabledTimeConfig => {
        return this.disabledTime && this.disabledTime(value, 'end');
    };

    isOneAllowed(selectedValue: DateFnsCalendar[]): boolean {
        const index = this.datePickerService.getActiveIndex();
        const disabledTimeArr = [this.disabledStartTime, this.disabledEndTime];
        return isAllowedDate(selectedValue?.[index], this.disabledDate, disabledTimeArr[index]);
    }

    isBothAllowed(selectedValue: DateFnsCalendar[]): boolean {
        return (
            isAllowedDate(selectedValue?.[0], this.disabledDate, this.disabledStartTime) &&
            isAllowedDate(selectedValue?.[1], this.disabledDate, this.disabledEndTime)
        );
    }

    isAllowed(value: CompatibleValue, isBoth: boolean = false): boolean {
        if (this.isRange) {
            return isBoth ? this.isBothAllowed(value as DateFnsCalendar[]) : this.isOneAllowed(value as DateFnsCalendar[]);
        } else {
            return isAllowedDate(value as DateFnsCalendar, this.disabledDate, this.disabledTime);
        }
    }

    getTimeOptions(partType?: RangePartType): SupportTimeOptions | null {
        if (this.showTime && this.timeOptions) {
            return this.timeOptions instanceof Array ? this.timeOptions[this.datePickerService.getActiveIndex(partType)] : this.timeOptions;
        }
        return null;
    }

    onClickPresetRange(val: PresetRanges[keyof PresetRanges]): void {
        const value = typeof val === 'function' ? val() : val;
        if (value) {
            this.datePickerService.setValue([new DateFnsCalendar(value[0]), new DateFnsCalendar(value[1])]);
            this.resultOk.emit();
        }
    }

    onPresetRangeMouseLeave(): void {
        this.clearHoverValue();
    }

    onHoverPresetRange(val: PresetRanges[keyof PresetRanges]): void {
        if (typeof val !== 'function') {
            this.hoverValue = [new DateFnsCalendar(val[0]), new DateFnsCalendar(val[1])];
        }
    }

    getObjectKeys(obj: object): string[] {
        return obj ? Object.keys(obj) : [];
    }

    show(partType: RangePartType): boolean {
        const hide = this.showTime && this.isRange && this.datePickerService.activeInput !== partType;
        return !hide;
    }

    private clearHoverValue(): void {
        this.hoverValue = [];
    }

    private buildTimeOptions(): void {
        if (this.showTime) {
            const showTime = typeof this.showTime === 'object' ? this.showTime : {};
            if (this.isRange) {
                const value = this.datePickerService.value as DateFnsCalendar[];
                this.timeOptions = [this.overrideTimeOptions(showTime, value[0], 'start'), this.overrideTimeOptions(showTime, value[1], 'end')];
            } else {
                this.timeOptions = this.overrideTimeOptions(showTime, this.datePickerService.value as DateFnsCalendar);
            }
        } else {
            this.timeOptions = null;
        }
    }

    private overrideTimeOptions(origin: SupportTimeOptions, value: DateFnsCalendar, partial?: DisabledTimePartial): SupportTimeOptions {
        let disabledTimeFn;
        if (partial) {
            disabledTimeFn = partial === 'start' ? this.disabledStartTime : this.disabledEndTime;
        } else {
            disabledTimeFn = this.disabledTime;
        }
        return { ...origin, ...getTimeConfig(value, disabledTimeFn) };
    }

    private overrideHms(newValue: DateFnsCalendar | null, oldValue: DateFnsCalendar | null): DateFnsCalendar {
        // tslint:disable-next-line:no-parameter-reassignment
        newValue = newValue || new DateFnsCalendar();
        // tslint:disable-next-line:no-parameter-reassignment
        oldValue = oldValue || new DateFnsCalendar();
        return oldValue.setHms(newValue.getHours(), newValue.getMinutes(), newValue.getSeconds());
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
