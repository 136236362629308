import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UIPage } from 'app/ui/page/ui.page.model';
import { UIPageStepHolder } from 'app/ui/page/step/ui.page.step.holder';

@Component({
    selector: 'ai-page-step-mode',
    templateUrl: 'page.step.mode.component.html'
})
export class PageStepModeComponent implements OnInit, OnDestroy {

    @Input() editable = true;
    @Input() item: UIPage;

    constructor(private holder: UIPageStepHolder) {
    }

    ngOnInit(): void {
        this.holder.updateScript(this.item.script);
    }

    ngOnDestroy(): void {
    }
}
