import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ClearMemoryGuard implements CanDeactivate<any> {
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      // 执行内存清除操作
      
      this.cleanUpMemory();
      return true;
    }
  
    private cleanUpMemory() {
      // 这里可以手动释放一些内存占用较大的对象
      // global.gc()
      console.log('--------------')
    }
  }