import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FlowTransform } from './flow.transform';

@Directive({
    selector: '[flow-canvas]',//tslint:disable-line
    host: {
        '[class.flow-container]': 'true'
    }
})
export class FlowCanvasDirective implements OnInit, OnDestroy {

    isMoveCanvas: boolean;
    startMovePoint: { x: number, y: number };

    @Input() transform: FlowTransform;

    constructor(private element: ElementRef, private render: Renderer2) {
        this.isMoveCanvas = false;
        this.move = this.move.bind(this);
    }

    ngOnInit(): void {
    }

    @HostListener('mousedown', ['$event'])
    private down(event: MouseEvent): void {
        if (event.target !== this.element.nativeElement) {
            return;
        }
        this.render.addClass(this.element.nativeElement, 'cursor-grabbing');
        this.isMoveCanvas = true;
        this.startMovePoint = { x: event.pageX, y: event.pageY };
        this.element.nativeElement.addEventListener('mousemove', this.move);
    }

    private move(event: MouseEvent): void {
        this.transform.translate(event.pageX - this.startMovePoint.x, event.pageY - this.startMovePoint.y);
        this.transform.transformElement(this.element.nativeElement);
        this.startMovePoint = { x: event.pageX, y: event.pageY };
    }

    @HostListener('mouseup')
    private up(): void {
        this.render.removeClass(this.element.nativeElement, 'cursor-grabbing');
        this.isMoveCanvas = false;
        this.element.nativeElement.removeEventListener('mousemove', this.move);
    }

    @HostListener('mouseleave')
    private leave(): void {
        this.render.removeClass(this.element.nativeElement, 'cursor-grabbing');
        this.isMoveCanvas = false;
        this.element.nativeElement.removeEventListener('mousemove', this.move);
    }

    @HostListener('mousewheel', ['$event'])
    private wheel(event: WheelEvent): void {
        event.preventDefault();
        event.stopPropagation();

        if (event.deltaY < 0) {
            this.transform.scale += 0.1;
        } else {
            this.transform.scale -= 0.1;
        }

        this.transform.transformElement(this.element.nativeElement);
    }

    ngOnDestroy(): void {
    }
}
