import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ai-search',
    templateUrl: 'search.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SearchComponent),
        multi: true
    }],
    host: {
        '[class.search-wrapper]': 'true'
    }
})
export class SearchComponent implements OnInit, ControlValueAccessor, OnDestroy {

    @Input() more: boolean;
    @Input() placeholder: string;

    text: FormControl = new FormControl('');

    @Output() search = new EventEmitter<string>();
    @Output() searchMore = new EventEmitter();

    private onChange: (_: any) => void;
    private destroy$ = new Subject();

    constructor() {
        this.placeholder = '';
    }

    ngOnInit() {
        merge(
            this.text.valueChanges.pipe(filter(v => this.text.dirty && !v)),
            this.text.valueChanges.pipe(filter(v => this.text.dirty && v), debounceTime(1000))
        ).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.search.next(this.text.value);
        });
    }

    change(): void {
        this.onChange?.(this.text.value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: any): void {
        this.text.setValue(obj || '');
    }

    ngOnDestroy() {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
