import {
    AfterViewInit,
    ComponentFactory,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    NgZone,
    Renderer2,
    ViewContainerRef
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { getTextWidth } from '../facade';
import { TooltipBaseDirective, ToolTipComponent } from '../tooltip';

@Directive({ selector: 'span.nowrap' }) //tslint:disable-line
export class SpanTooltipDirective extends TooltipBaseDirective implements AfterViewInit {

    componentFactory: ComponentFactory<ToolTipComponent> = this.resolver.resolveComponentFactory(ToolTipComponent);

    constructor(private zone: NgZone,
                elementRef: ElementRef<HTMLElement>,
                hostView: ViewContainerRef,
                resolver: ComponentFactoryResolver,
                renderer: Renderer2) {
        super(elementRef, hostView, resolver, renderer);
    }

    ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => fromEvent(this.elementRef.nativeElement, 'mouseenter').pipe(takeUntil(this.destroy$)).subscribe(() => this.hover()));
    }

    hover(): void {
        let style = getComputedStyle(this.elementRef.nativeElement);
        let text = this.elementRef.nativeElement.innerText;
        let width = getTextWidth(text, style.font);
        let padding = (parseInt(style.paddingLeft, 10) || 0) + (parseInt(style.paddingRight, 10) || 0);
        if (width <= this.elementRef.nativeElement.offsetWidth - padding) {
            return;
        }
        this.specificTitle = text;
        this.trigger = 'hover';
        if (!this.component) {
            this.createComponent();
        }
        this.removeTriggerListeners();
        this.registerTriggers();
        this.show();
        this.updatePosition();
        this.visibleChange.asObservable().pipe(filter(v => !v)).subscribe(() => this.removeTriggerListeners());
    }
}
