import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectModule } from '../select/select.module';
import { PaginationComponent } from './pagination.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SelectModule
    ],
    declarations: [
        PaginationComponent
    ],
    exports: [
        PaginationComponent
    ]
})
export class PaginationModule {
}
