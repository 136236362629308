import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    Renderer2,
    TemplateRef,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { zoomBigMotion } from '../animation';
import { InputBoolean } from '../facade';
import { TooltipBaseDirective, ToolTipComponent } from '../tooltip';

@Directive({
    selector: '[popConfirm]', //tslint:disable-line
    exportAs: 'popConfirm'
})
export class PopConfirmDirective extends TooltipBaseDirective {

    @Input('popConfirmTitle') specificTitle?: string | TemplateRef<void>;
    @Input('popConfirm') directiveNameTitle?: string | TemplateRef<void>;

    @Input() okText?: string;
    @Input() okType?: string;
    @Input() cancelText?: string;
    @Input() icon?: string;
    @Input() @InputBoolean() condition: boolean = false;

    @Output() readonly cancel = new EventEmitter<void>();
    @Output() readonly confirm = new EventEmitter<void>();

    protected readonly componentFactory: ComponentFactory<PopConfirmComponent> = this.resolver.resolveComponentFactory(PopConfirmComponent);

    protected readonly needProxyProperties = [
        'overlayClassName',
        'overlayStyle',
        'mouseEnterDelay',
        'mouseLeaveDelay',
        'visible',
        'okText',
        'okType',
        'cancelText',
        'condition',
        'icon'
    ];

    constructor(
        elementRef: ElementRef,
        hostView: ViewContainerRef,
        resolver: ComponentFactoryResolver,
        renderer: Renderer2
    ) {
        super(elementRef, hostView, resolver, renderer);
        this.trigger = 'click';
    }

    protected createComponent(): void {
        super.createComponent();

        (this.component as PopConfirmComponent).cancel.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.cancel.emit();
        });
        (this.component as PopConfirmComponent).confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.confirm.emit();
        });
    }
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    selector: 'pop-confirm', //tslint:disable-line
    exportAs: 'popConfirmComponent',
    preserveWhitespaces: false,
    animations: [zoomBigMotion],
    template: `
        <ng-template
                #overlay="cdkConnectedOverlay"
                cdkConnectedOverlay
                transparentOverlay
                [cdkConnectedOverlayOrigin]="origin"
                [cdkConnectedOverlayHasBackdrop]="hasBackdrop"
                (backdropClick)="hide()"
                (detach)="hide()"
                (positionChange)="onPositionChange($event)"
                [cdkConnectedOverlayPositions]="positions"
                [cdkConnectedOverlayOpen]="visible">
            <div class="popover"
                 [ngClass]="classMap"
                 [@zoomBigMotion]="'active'">
                <div class="popover-content">
                    <div class="popover-arrow"></div>
                    <div class="popover-inner">
                        <div>
                            <div class="popover-inner-content">
                                <div class="popover-message">
                                    <ng-container *stringTemplateOutlet="title">
                                        <i class="icon icon-info-fill text-warning" [class.icon-info-fill]="!icon" [ngClass]="icon"></i>
                                        <div class="popover-message-title">{{ title }}</div>
                                    </ng-container>
                                </div>
                                <div class="popover-buttons">
                                    <button class="btn btn-sm" (click)="onCancel()">
                                        <ng-container>{{ cancelText || '取消' }}</ng-container>
                                    </button>
                                    <button class="btn btn-sm btn-primary" (click)="onConfirm()">
                                        <ng-container>{{ okText || '确定' }}</ng-container>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    `
})
export class PopConfirmComponent extends ToolTipComponent implements OnDestroy {

    cancelText?: string;
    condition = false;
    icon?: string;
    okText?: string;

    readonly cancel = new Subject<void>();
    readonly confirm = new Subject<void>();

    prefix = 'popover-placement';
    hasBackdrop = true;

    constructor(cdr: ChangeDetectorRef) {
        super(cdr);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        this.cancel.complete();
        this.confirm.complete();
    }

    show(): void {
        if (!this.condition) {
            super.show();
        } else {
            this.onConfirm();
        }
    }

    onCancel(): void {
        this.cancel.next(undefined);
        super.hide();
    }

    onConfirm(): void {
        this.confirm.next(undefined);
        super.hide();
    }
}
