import { InjectionToken } from '@angular/core';

export const VALIDATABLE = new InjectionToken<Validatable>('Validatable');

export const VALIDATABLE_PROCESSOR = new InjectionToken<ValidatableProcessor<any>>('Validatable Processor');

export interface Validatable {
    validate(): void;
}

export interface ValidatableProcessor<T> extends Validatable {
    process(): T;
}
