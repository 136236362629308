import { Component, OnInit } from '@angular/core';
import { emptyImage } from './empty.config';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'empty-table', // tslint:disable-line
    templateUrl: 'empty.table.component.html',
    styleUrls: ['empty.table.scss']
})
export class EmptyTableComponent implements OnInit {

    defaultSvg: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) {
        this.defaultSvg = this.sanitizer.bypassSecurityTrustResourceUrl(emptyImage);
    }

    ngOnInit() {
    }
}
