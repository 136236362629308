import { Observable } from 'rxjs';
import { DrawerPlacement } from './drawer-options';

export abstract class DrawerRef<R = any> {

    abstract afterClose: Observable<R>;
    abstract result: Observable<R>;

    abstract inputs: { [key: string]: any };

    abstract maskClosable: boolean;
    abstract scrollable: boolean;
    abstract keyboard: boolean;
    abstract mask: boolean;
    abstract placement: DrawerPlacement;
    abstract zIndex: number | string;
    abstract offsetX: number | string;
    abstract offsetY: number | string;

    abstract close(result?: R): void;

    abstract dismiss(): void;

}
