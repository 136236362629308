import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { slideMotion } from '../animation';
import { IsMenuInsideDropDownToken, MenuService } from '../menu';

export type PlacementType = 'bottomLeft' | 'bottomCenter' | 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight';

@Component({
    selector: `ai-dropdown-menu`,
    exportAs: `dropdownMenu`,
    animations: [slideMotion],
    providers: [
        MenuService,
        {
            provide: IsMenuInsideDropDownToken,
            useValue: true
        }
    ],
    template: `
        <ng-template>
            <div class="ai-dropdown"
                 [ngClass]="overlayClassName"
                 [@slideMotion]="dropDownPosition"
                 (mouseenter)="setMouseState(true)"
                 (mouseleave)="setMouseState(false)">
                <ng-content></ng-content>
            </div>
        </ng-template>
    `,
    preserveWhitespaces: false,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownMenuComponent implements AfterContentInit {

    dropDownPosition: 'top' | 'center' | 'bottom' = 'bottom';
    mouseState$ = new BehaviorSubject<boolean>(false);
    isChildSubMenuOpen$ = this.menuService.isChildSubMenuOpen$;
    descendantMenuItemClick$ = this.menuService.descendantMenuItemClick$;
    overlayClassName: string = '';
    overlayStyle: any = {};
    data: any = {};

    @ViewChild(TemplateRef, { static: true }) templateRef: TemplateRef<any>;

    constructor(private cdr: ChangeDetectorRef,
                private elementRef: ElementRef,
                private renderer: Renderer2,
                public viewContainerRef: ViewContainerRef,
                public menuService: MenuService) {
    }

    setMouseState(visible: boolean): void {
        this.mouseState$.next(visible);
    }

    setValue<T extends keyof DropdownMenuComponent>(key: T, value: this[T]): void {
        this[key] = value;
        this.cdr.markForCheck();
    }


    ngAfterContentInit(): void {
        this.renderer.removeChild(this.renderer.parentNode(this.elementRef.nativeElement), this.elementRef.nativeElement);
    }
}
