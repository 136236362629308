import { NgModule } from '@angular/core';
import { TenantUserComponent } from 'app/system/tenant/user/tenant.user.component';
import { CoreModule } from 'core';
import { ShareModule } from 'share';
import { TenantEditComponent } from './tenant.edit.component';

@NgModule({
    imports: [
        CoreModule,
        ShareModule
    ],
    declarations: [
        TenantEditComponent,
        TenantUserComponent
    ]
})
export class TenantEditModule {

}
