import { Right } from 'app/system/right/right.model';
import { isPresent } from 'share';


export class Menu {

    menu_id?: number;
    menu_name?: string;
    parent_id?: number;
    menu_url?: string;
    order?: number;

    parent?: Menu;
    children?: Array<Menu>;
    open?: boolean;
    menu_icon: string;
    functions: Array<Right>;

    private _selected?: boolean;

    constructor(menu: {
        menu_id?: number,
        menu_name?: string,
        parent_id?: number,
        menu_url?: string,
        order?: number,
        functions: Array<Right>
    }) {
        Object.assign(this, menu);
        this._selected = false;
        this.children = [];
        this.functions = menu.functions ?? [];
        this.open = false;
    }

    get selected(): boolean {
        return this._selected;
    }

    set selected(value: boolean) {
        this._selected = value;
        this.updateUp();
        this.updateDown();
        this.updateRight();
    }

    initSelect(value: boolean, rights: Array<Right> = []): void {
        this._selected = value;
        this.functions.forEach(r => r.selected = rights.some(v => v.function_id === r.function_id));
    }

    updateUp(): void {
        if (!isPresent(this.parent)) {
            return;
        }
        this.parent._selected = this.parent.children.some(v => v.selected);
        this.parent.updateUp();
    }

    updateDown(): void {
        if (!this.children?.length) {
            return;
        }
        this.children.forEach(child => {
            child._selected = this.selected;
            child.updateDown();
            child.updateRight();
        });
    }

    private updateRight(): void {
        this.functions.forEach(r => r.selected = this.selected);
    }

    addChild(menu: Menu): void {
        menu.parent = this;
        this.children.push(menu);
    }
}
