import { isPresent } from './lang';

export function isObject(value: any) {
    if (!isPresent(value)) {
        return false;
    }
    return Object.prototype.toString.call(value) === '[object Object]';
}

export function hasOwnProperty(obj: any, key: string): boolean {
    if (!isPresent(obj)) {
        return false;
    }
    return Object.prototype.hasOwnProperty.call(obj, key);
}