import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrlStrategy } from 'share';
import { RequestMethod } from '../constants';

@Injectable({ providedIn: 'root' })
export class BaseRequestInterceptor implements HttpInterceptor {

    constructor(private strategy: BaseUrlStrategy) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let [url, params] = this.strategy.path(req.url).split('?');
        if (req.method === RequestMethod.DELETE) {
            url = `${url}/delete`;
        } else if (req.method === RequestMethod.PUT) {
            url = `${url}/modify`;
        }
        if (params) {
            url = `${url}?${params}`;
        }
        return next.handle(req.clone({
            url,
            method: req.method === RequestMethod.GET ? RequestMethod.GET : RequestMethod.POST
        }));
    }
}
