import { AfterContentInit, Directive, ElementRef, HostBinding, OnDestroy, OnInit, Optional } from '@angular/core';
import { isPresent } from '../facade';
import { Textarea } from './textarea';
import { TextareaGroupDirective } from './textarea.group';

@Directive({ selector: 'textarea[auto-height]' }) //tslint:disable-line
export class TextareaAutoHeightDirective implements OnInit, AfterContentInit, Textarea, OnDestroy {

    textHeight?: number;

    private textarea: HTMLTextAreaElement;
    private observer: IntersectionObserver;

    constructor(private element: ElementRef, @Optional() private group: TextareaGroupDirective) {
        this.textHeight = 0;
        this.resize = this.resize.bind(this);
    }

    ngOnInit(): void {
        if (isPresent(this.group)) {
            this.group.registerTextarea(this);
        }
        this.textarea = document.createElement('textarea');
        document.body.appendChild(this.textarea);
        this.initTextareaStyle();
    }

    ngAfterContentInit(): void {
        let element = this.element.nativeElement as HTMLTextAreaElement;
        element.addEventListener('input', this.resize);
        let observer = new IntersectionObserver(entries => entries.forEach(item => {
            if (!item.isIntersecting) {
                return;
            }
            observer.unobserve(item.target);
            this.initTextareaStyle();
            this.resize();
        }));
        observer.observe(this.element.nativeElement);
        this.observer = observer;
    }

    private initTextareaStyle(): void {
        let element = this.element.nativeElement as HTMLTextAreaElement;
        let style = getComputedStyle(element);
        let textarea = this.textarea;
        textarea.style.font = style.font;
        textarea.rows = 1;
        textarea.style.fontSize = style.fontSize;
        textarea.style.lineHeight = style.lineHeight;
        textarea.style.paddingTop = style.paddingTop;
        textarea.style.paddingBottom = style.paddingBottom;
        textarea.style.paddingLeft = style.paddingLeft;
        textarea.style.paddingRight = style.paddingRight;
        textarea.style.width = `${element.clientWidth}px`;
        textarea.value = element.value;
        textarea.style.position = 'fixed';
        textarea.style.left = '-10000px';
    }

    private resize(): void {
        let element = this.element.nativeElement as HTMLTextAreaElement;
        this.textarea.style.width = `${element.clientWidth}px`;
        this.textarea.value = element.value;
        this.textHeight = this.textarea.scrollHeight;
        if (!isPresent(this.group)) {
            element.style.height = `${this.textarea.scrollHeight}px`;
            return;
        }
        this.group.updateHeight();
    }

    updateHeight(height: number): void {
        let element = this.element.nativeElement as HTMLTextAreaElement;
        element.style.height = `${height}px`;
    }

    @HostBinding('attr.rows')
    get rows(): number {
        return 1;
    }

    ngOnDestroy(): void {
        document.body.removeChild(this.textarea);
        let element = this.element.nativeElement as HTMLTextAreaElement;
        element.removeEventListener('input', this.resize);
        this.observer.disconnect();
    }
}
