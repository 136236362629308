import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { InputBoolean } from '../facade';
import { OptionGroupComponent } from './option-group.component';

@Component({
    selector: 'ai-option',
    exportAs: 'option',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-template>
            <ng-content></ng-content>
        </ng-template>
    `
})
export class OptionComponent implements OnChanges, OnInit, OnDestroy {

    changes = new Subject();
    groupLabel: string | TemplateRef<any> | null = null;

    @ViewChild(TemplateRef, { static: true }) template: TemplateRef<any>;

    @Input() @InputBoolean() customContent = false;
    @Input() @InputBoolean() disabled = false;
    @Input() @InputBoolean() hide = false;
    @Input() label: string;
    @Input() value: any;

    private destroy$ = new Subject<void>();

    constructor(@Optional() private optionGroupComponent: OptionGroupComponent) {
    }

    ngOnInit(): void {
        this.optionGroupComponent?.changes.pipe(startWith(true), takeUntil(this.destroy$)).subscribe(() => this.groupLabel = this.optionGroupComponent.label);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.changes.next(undefined);
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
