import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from 'share';
import { UploadComponent } from './upload.component';
import { UploadService } from './upload.service';


@Component({
    selector: 'ai-upload-btn',
    templateUrl: 'upload.btn.component.html',
    host: {
        '[attr.tabindex]': '"0"',
        '[attr.role]': '"button"',
        '[class.hand]': 'true',
        '[class.d-inline-block]': 'true'
    },
    preserveWhitespaces: false,
    encapsulation: ViewEncapsulation.None
})
export class UploadBtnComponent implements OnInit, OnDestroy {

    @Output() ngComplete: EventEmitter<string> = new EventEmitter<string>();

    private destroy$ = new Subject();

    constructor(private modal: ModalService, private service: UploadService) {
    }

    ngOnInit(): void {
        this.service.filePath
            .pipe(takeUntil(this.destroy$))
            .subscribe(path => {
                if (path && path.length !== 0) {
                    this.ngComplete.emit(path);
                    this.service.filePath.next('');
                }
            });
    }

    @HostListener('click')
    onClick(): void {
        this.modal.open(UploadComponent, { modalClass: 'modal-size-4' });
    }

    ngOnDestroy(): void {
        this.destroy$.next('');
        this.destroy$.complete();
    }
}
