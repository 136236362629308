import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalHolder } from 'app/global.holder';
import { Token } from 'app/login/login.model';
import { LoginService } from 'app/login/login.service';
import { LocalStorageService } from 'ngx-webstorage';
import { isPresent } from 'share';

@Component({
    selector: 'ai-auto-login',
    templateUrl: 'auto.login.component.html'
})

export class AutoLoginComponent implements OnInit {

    error: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private global: GlobalHolder,
                private storage: LocalStorageService,
                private loginService: LoginService) {
    }

    ngOnInit() {
        this.login();
    }

    private login() {
        this.error = '';
        let access_token = this.route.snapshot.queryParams.token;
        let ext_project_id = this.route.snapshot.queryParams.projectId;
        let ext_user_id = this.route.snapshot.queryParams.userId;
        let token_type = this.route.snapshot.queryParams.tokenType;
        let is_manager = this.route.snapshot.queryParams.isManager;

        if (!access_token) {
            this.error = '自动登录地址中没有token信息';
            return;
        }

        if (!ext_project_id) {
            this.error = '自动登录地址中没有项目信息';
            return;
        }

        if (!ext_user_id) {
            this.error = '自动登录地址中没有用户信息';
            return;
        }

        this.loginService.sync({
            token: access_token,
            token_type,
            ext_project_id,
            ext_user_id,
            is_manager
        }).subscribe(result => {
            if (!isPresent(result) || !isPresent(result.user) || !isPresent(result.product)) {
                this.error = '自动登录失败';
                return;
            }

            let token: Token = { token_type, access_token };
            this.storage.store('token', token);
            this.global.updateToken(token);

            result.user.is_auto = true;
            this.storage.store('user', result.user);
            this.global.updateUser(result.user);

            this.global.updateProduct(result.product);

            this.router.navigate(['pages/product/dashboard']);
        }, (result: HttpErrorResponse) => this.error = result.error ? result.error.message : '自动登录失败');
    }
}
