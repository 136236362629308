import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, fromEvent, merge, Subject } from 'rxjs';
import { auditTime, distinctUntilChanged, filter, map, mapTo, switchMap, takeUntil } from 'rxjs/operators';
import { InputBoolean } from '../facade/convert';
import { CONNECTION_POSITIONS } from '../overlay/overlay-position';
import { DropdownMenuComponent, PlacementType } from './dropdown-menu.component';

const listOfPositions = [CONNECTION_POSITIONS.bottomLeft, CONNECTION_POSITIONS.bottomRight, CONNECTION_POSITIONS.topRight, CONNECTION_POSITIONS.topLeft];

@Directive({
    selector: '[ai-dropdown]', //tslint:disable-line
    exportAs: 'dropdown',
    host: {
        '[attr.disabled]': `disabled ? '' : null`,
        '[class.ai-dropdown-trigger]': 'true'
    }
})
export class DropDownDirective implements AfterViewInit, OnDestroy, OnChanges, OnInit {

    @Input() dropdownMenu: DropdownMenuComponent | null = null;
    @Input() trigger: 'click' | 'hover' = 'hover';
    @Input() matchWidthElement: ElementRef | null = null;
    @Input() @InputBoolean() backdrop = true;
    @Input() @InputBoolean() clickHide = true;
    @Input() @InputBoolean() disabled = false;
    @Input() @InputBoolean() visible = false;
    @Input() overlayClassName: string = '';
    @Input() overlayStyle: any = {};
    @Input() placement: PlacementType = 'bottomLeft';
    @Input() data: any = {};

    @Output() readonly visibleChange: EventEmitter<boolean> = new EventEmitter();

    private portal?: TemplatePortal;
    private overlayRef: OverlayRef | null = null;
    private destroy$ = new Subject();
    private positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef.nativeElement).withLockedPosition();
    private inputVisible$ = new BehaviorSubject<boolean>(false);
    private trigger$ = new BehaviorSubject<'click' | 'hover'>('hover');
    private overlayClose$ = new Subject<boolean>();

    constructor(public elementRef: ElementRef,
                private overlay: Overlay,
                private viewContainerRef: ViewContainerRef,
                private platform: Platform) {
    }

    ngOnInit(): void {
        this.positionStrategy.positionChanges.pipe(takeUntil(this.destroy$)).subscribe(change => this.setDropdownMenuValue('dropDownPosition', change.connectionPair.originY));
    }

    ngAfterViewInit(): void {
        if (!this.dropdownMenu) {
            return;
        }
        const nativeElement: HTMLElement = this.elementRef.nativeElement;
        const hostMouseState$ = merge(
            fromEvent(nativeElement, 'mouseenter').pipe(mapTo(true)),
            fromEvent(nativeElement, 'mouseleave').pipe(mapTo(false))
        );
        const menuMouseState$ = this.dropdownMenu.mouseState$;
        const mergedMouseState$ = merge(menuMouseState$, hostMouseState$);
        const hostClickState$ = fromEvent(nativeElement, 'click').pipe(mapTo(true));
        const visibleStateByTrigger$ = this.trigger$.pipe(
            switchMap(trigger => {
                if (trigger === 'hover') {
                    return mergedMouseState$;
                } else if (trigger === 'click') {
                    return hostClickState$;
                } else {
                    return EMPTY;
                }
            })
        );
        const descendantMenuItemClick$ = this.dropdownMenu.descendantMenuItemClick$.pipe(
            filter(() => this.clickHide),
            mapTo(false)
        );
        const domTriggerVisible$ = merge(visibleStateByTrigger$, descendantMenuItemClick$, this.overlayClose$).pipe(
            filter(() => !this.disabled)
        );
        const visible$ = merge(this.inputVisible$, domTriggerVisible$);
        combineLatest([visible$, this.dropdownMenu.isChildSubMenuOpen$])
            .pipe(
                map(([visible, sub]) => visible || sub),
                auditTime(150),
                distinctUntilChanged(),
                filter(() => this.platform.isBrowser),
                takeUntil(this.destroy$)
            )
            .subscribe((visible: boolean) => {
                const element = this.matchWidthElement ? this.matchWidthElement.nativeElement : nativeElement;
                const triggerWidth = element.getBoundingClientRect().width;
                if (this.visible !== visible) {
                    this.visibleChange.emit(visible);
                }
                this.visible = visible;
                if (!visible) {
                    this.overlayRef?.detach();
                    return;
                }

                if (!this.overlayRef) {
                    this.overlayRef = this.overlay.create({
                        positionStrategy: this.positionStrategy,
                        minWidth: triggerWidth,
                        disposeOnNavigation: true,
                        hasBackdrop: this.trigger === 'click',
                        backdropClass: this.backdrop ? undefined : 'overlay-transparent-backdrop',
                        scrollStrategy: this.overlay.scrollStrategies.reposition()
                    });
                    merge(
                        this.overlayRef.backdropClick(),
                        this.overlayRef.detachments(),
                        this.overlayRef.keydownEvents().pipe(filter(e => e.keyCode === ESCAPE && !hasModifierKey(e)))
                    )
                        .pipe(mapTo(false), takeUntil(this.destroy$))
                        .subscribe(this.overlayClose$);
                } else {
                    const overlayConfig = this.overlayRef.getConfig();
                    overlayConfig.minWidth = triggerWidth;
                    overlayConfig.hasBackdrop = this.trigger === 'click';
                }
                this.positionStrategy.withPositions([CONNECTION_POSITIONS[this.placement], ...listOfPositions]);
                if (this.portal?.templateRef !== this.dropdownMenu.templateRef) {
                    this.portal = new TemplatePortal(this.dropdownMenu.templateRef, this.viewContainerRef);
                }
                this.overlayRef.attach(this.portal);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { visible, placement, disabled, overlayClassName, overlayStyle, trigger, data } = changes;
        if (trigger) {
            this.trigger$.next(this.trigger);
        }
        if (visible) {
            this.inputVisible$.next(this.visible);
        }
        if (disabled && this.disabled) {
            this.inputVisible$.next(false);
        }
        if (overlayClassName) {
            this.setDropdownMenuValue('overlayClassName', this.overlayClassName);
        }
        if (overlayStyle) {
            this.setDropdownMenuValue('overlayStyle', this.overlayStyle);
        }
        if (placement) {
            this.setDropdownMenuValue('dropDownPosition', this.placement.indexOf('top') !== -1 ? 'top' : 'bottom');
        }
        if (data) {
            this.setDropdownMenuValue('data', this.data);
        }
    }

    private setDropdownMenuValue<T extends keyof DropdownMenuComponent>(key: T, value: DropdownMenuComponent[T]): void {
        this.dropdownMenu?.setValue(key, value);
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
        this.overlayRef?.dispose();
        this.overlayRef = null;
    }
}
