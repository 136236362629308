import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OutletModule } from '../outlet/outlet.module';
import { ModalConfirmContainerComponent } from './modal-confirm-container.component';
import { ModalContainerComponent } from './modal-container.component';
import { ModalService } from './modal.service';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        OutletModule,
        PortalModule
    ],
    declarations: [
        ModalContainerComponent,
        ModalConfirmContainerComponent
    ],
    providers: [
        ModalService
    ]
})
export class ModalModule {
}
