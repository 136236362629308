import { NgModule } from '@angular/core';
import { GroupEditComponent } from 'app/group/edit/group.edit.component';
import { MenuItemRightsPipe } from 'app/group/menu/menu.item.pipe';
import { MenuItemComponent } from 'app/group/menu/menu.item.component';
import { GroupSelectComponent } from 'app/group/select/group.select.component';
import { GroupSelectModalComponent } from 'app/group/select/group.select.modal.component';
import { GroupSelectWinComponent } from 'app/group/select/group.select.win.component';
import { GroupTreeModule } from 'app/group/tree/group.tree.module';
import { CoreModule } from 'core';
import { ShareModule, DropDownModule } from 'share';
import { GroupPathComponent } from 'app/group/path/group.path.component';

@NgModule({
    imports: [
        CoreModule,
        GroupTreeModule,
        ShareModule,
        DropDownModule
    ],
    exports: [
        MenuItemComponent,
        GroupSelectComponent,
        GroupSelectWinComponent,
        GroupPathComponent
    ],
    declarations: [
        GroupEditComponent,
        MenuItemRightsPipe,
        MenuItemComponent,
        GroupSelectComponent,
        GroupSelectModalComponent,
        GroupSelectWinComponent,
        GroupPathComponent
    ]
})
export class GroupModule {
}
