import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NodeType } from './node';

@Component({
    selector: 'node-value',//tslint:disable-line
    templateUrl: 'node.value.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NodeValueComponent),
        multi: true
    }]
})

export class NodeValueComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @Input() disabled: boolean;
    @Input() type: NodeType;

    private onChange: (_: any) => void;

    private _value: any;

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    get isBoolean(): boolean {
        return this.type === NodeType.Boolean;
    }

    @HostBinding('class.d-none')
    get hide(): boolean {
        return this.type > NodeType.Primitive;
    }

    get value(): any {
        return this._value;
    }

    set value(value: any) {
        if (this._value === value) {
            return;
        }
        this.onChange(value);
        this._value = value;
        this.cdRef.markForCheck();
    }


    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this._value = obj;
        this.cdRef.markForCheck();
    }


    ngOnDestroy() {
    }
}
