import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { GlobalHolder } from 'app/global.holder';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PasswordGuardService implements CanActivateChild {

    constructor(private router: Router, private global: GlobalHolder) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (state?.url.includes('pages/account/password') || state?.url.includes('login')) {
            return of(true);
        }
        if (this.global.needChangePwd.getValue()) {
            toastr.error('密码已过期，请编辑后重新登录');
            this.router.navigate(['pages/account/password']);
            return of(false);
        }
        return of(true);
    }
}
