import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TagColorService {

    private keyColors = new Map<string, string>();
    private groupColors = new Map<string, Map<string, string>>();

    constructor() {
    }

    register(key: string, color: string): void {
        this.keyColors.set(key, color);
    }

    registerGroup(group: string, key: string, color: string): void {
        if (!this.groupColors.has(group)) {
            this.groupColors.set(group, new Map<string, string>());
        }
        this.groupColors.get(group).set(key, color);
    }

    getColor(key: string, group?: string): string {
        return group ? this.groupColors.get(group)?.get(key) : this.keyColors.get(key);
    }
}
