import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AbstractPanelHeader } from '../abstract-header';
import { PanelSelector } from '../interface';

@Component({
    selector: 'year-header', // tslint:disable-line
    exportAs: 'yearHeader',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../abstract-header.html'
})
export class YearHeaderComponent extends AbstractPanelHeader {

    get startYear(): number {
        return parseInt(`${this.value.getYear() / 10}`, 10) * 10;
    }

    get endYear(): number {
        return this.startYear + 9;
    }

    superPrevious(): void {
        this.changeValue(this.value.addYears(-10));
    }

    superNext(): void {
        this.changeValue(this.value.addYears(10));
    }

    getSelectors(): PanelSelector[] {
        return [{
            title: '',
            onClick: () => this.changeMode('decade'),
            label: `${this.startYear}-${this.endYear}`
        }];
    }
}
