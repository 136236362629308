import { Injectable, OnDestroy } from '@angular/core';
import { COLOR_KEYS } from '../constants';
import { TagColorService } from './tag.color.service';
import { TagComponent } from './tag.component';

@Injectable()
export class TagService implements OnDestroy {

    private groupTags = new Map<string, Array<TagComponent>>();

    constructor(private colorService: TagColorService) {
    }

    changeGroup(old: string, newValue: string, tag: TagComponent): void {
        this.groupTags.get(old)?.remove(tag);
        let tags = this.groupTags.get(newValue) ?? [];
        tags.push(tag);
        this.groupTags.set(newValue, tags);
    }

    getColor(group: string, tag: string): string {
        let color = this.colorService.getColor(tag, group);
        if (color) {
            return color;
        }
        if (!this.groupTags.has(group)) {
            return COLOR_KEYS[0];
        }
        let tags = new Set(this.groupTags.get(group).filter(item => !item.color && item.text).map(item => item.text));
        let tagArr = [];
        tags.forEach(v => tagArr.push(v));
        let index = tagArr.indexOf(tag);
        if (index === -1) {
            return COLOR_KEYS[0];
        }
        return COLOR_KEYS[index % COLOR_KEYS.length];

    }

    ngOnDestroy(): void {
        this.groupTags.clear();
    }
}
