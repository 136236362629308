import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RouterOutletDirective } from './router_outlet';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        RouterOutletDirective
    ],
    declarations: [
        RouterOutletDirective
    ]
})
export class RouterModule {
}
