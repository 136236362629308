import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit,Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'app/app.model';
import { GlobalHolder } from 'app/global.holder';
import { treeMenu } from 'app/main/util';
import { Menu } from 'app/system/menu/menu';
import { AiComponent } from 'core';
import { CrumbHolder } from 'share/crumb/crumb.holder';
import { LoginService } from 'app/login/login.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ai-menu-sidebar',
    templateUrl: 'menu.sidebar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['menu.sidebar.scss']
})
@AiComponent()
export class MenuSidebarComponent implements OnInit, OnDestroy {
    menus: Array<Menu> = [];
    item: Menu;
    collapse: boolean;
    hoverMenu: Menu;
    config: AppConfig;
    menusSubscription: Subscription;

   

    constructor(private router: Router,
                private route: ActivatedRoute,
                private global: GlobalHolder,
                private crumbHolder: CrumbHolder,
                private cdRef: ChangeDetectorRef,
                private loginService: LoginService,
                ) {
    }

    ngOnInit() {
        this.menusSubscription = this.global.menus.subscribe(items => {
            const scrollContainer = document.getElementById("scro");

                    // 将滚动容器的滚动位置设置为顶部
                    scrollContainer.scrollTop = 0;
            // this.menus = treeMenu(items);
            // this.cdRef.markForCheck();
          });
        let menus$ = this.global.menus.subscribe(items => {
            this.menus = treeMenu(items);
            
            this.cdRef.markForCheck();
        });
        this.ngAdd$(menus$);

        let config$ = this.global.appConfig.subscribe(config => this.config = config);
        this.ngAdd$(config$);

        this.global.collapse.subscribe(v => {
            this.collapse = v;
            this.menus.forEach(menu => menu.open = false);
            this.cdRef.markForCheck();
        });
    }
    logout() {
        this.loginService.logout().subscribe(() => this.ngOnDestroy());
    }
    toggle(item: Menu): void {
        console.log('----------------->123123')

        if (item.menu_url) {
            this.item = item;
            this.crumbHolder.updateMenu(this.item);
            this.router.navigate([`pages/${item.menu_url}`]);
            console.log(this.menus,'----------------->menu')
            console.log(this.item,'----------------->item')
            return;
        }
        if (this.collapse) {
            return;
        }
        this.menus.filter(menu => menu !== item).forEach(menu => menu.open = false);
        item.open = !item.open;
    }

    togglebtn(): void {
        this.collapse = !this.collapse;
        this.global.updateCollapse(this.collapse);
    }

    enter(item: Menu): void {
        this.hoverMenu = item;
    }

    select(item: Menu): void {
        if (item.menu_url?.includes('http')) {
            window.open(item.menu_url, '_blank');
            return;
        }
        this.item = item;
        this.crumbHolder.updateMenu(this.item);
        this.router.navigate([`pages/${item.menu_url}`]);
    }

    logoClick() {
        let url = this.global.appConfig.getValue()?.home_for_url;
        if (!url || url.length === 0) {
            url = 'pages/workbench';
        }
        this.router.navigate([url]);
    }

    ngOnDestroy(): void {
        this.menusSubscription.unsubscribe();
        this.menusSubscription=null
        location.reload();
    }
}
