import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputBoolean } from '../facade/convert';

// tslint:disable

@Component({
    selector: 'dropdown-input-edit',
    templateUrl: 'dropdown.input.edit.component.html',
    styleUrls: ['dropdown.input.edit.scss']
})

export class DropdownInputEditComponent implements OnInit {

    @Input() placeholder: string = '';
    @Input() placement: string;
    @Input() text: string = '';
    @Input() @InputBoolean() editable: boolean;
    @Input() mode: string = 'mysql';

    @Output() textChange: EventEmitter<string> = new EventEmitter<string>(false);

    constructor(private el: ElementRef) {
        this.placement = 'left';
    }

    ngOnInit() {
    }

    change(text: string = ''): void {
        if (this.text === text) {
            return;
        }
        this.text = text;
        this.textChange.next(text);

        let evObj = document.createEvent('HTMLEvents');
        evObj.initEvent('change', true, true);
        this.el.nativeElement.dispatchEvent(evObj);
    }
}
