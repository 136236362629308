import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ai-select-placeholder',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `{{ placeholder }}`,
    host: {
        '[class.select-selection-placeholder]': 'true'
    }
})
export class SelectPlaceholderComponent {

    @Input() placeholder: string;
}
