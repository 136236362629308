import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { emptyImage } from './empty.config';

@Component({
    selector: 'empty', // tslint:disable-line
    templateUrl: 'empty.component.html',
    styleUrls: ['empty.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyComponent implements OnInit {

    @Input() image: string | TemplateRef<void>;
    @Input() content: string | TemplateRef<void>;
    @Input() footer: string | TemplateRef<void>;

    defaultSvg: SafeResourceUrl;

    isTemplate(template: string | TemplateRef<void>): boolean {
        return !!(template && typeof template !== 'string');
    }

    constructor(private sanitizer: DomSanitizer) {
        this.defaultSvg = this.sanitizer.bypassSecurityTrustResourceUrl(emptyImage);
    }

    ngOnInit(): void {
    }
}