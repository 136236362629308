import { Component, Input, OnInit } from '@angular/core';
import { ModalRef } from 'share';
import { UIPageService } from 'app/ui/page/ui.page.service';
import { OPERATE_DESC } from 'app/ui/page/step/ui.page.step.model';

@Component({
    selector: 'ai-ui-step-operate-explain',
    templateUrl: 'operate.explain.component.html',
    styles: [`
        ::ng-deep .modal-wrap .modal {
            width: 30rem;
        }
    `]
})
export class UIStepOperateExplainComponent implements OnInit {

    @Input() operate: string;

    items: Array<{ label: string; value: string; }> = [];

    constructor(public modalRef: ModalRef, private service: UIPageService) {
    }

    ngOnInit(): void {
        if (OPERATE_DESC.has(this.operate)) {
            this.items = OPERATE_DESC.get(this.operate) as any;
            return;
        }

        this.items = [{ label: '动作', value: this.operate }];
        this.service.qryOpDesc({
            operate: this.operate
        }).subscribe(data => {
            const list = data?.split('\n');
            list?.forEach((val: string, i) => {
                if (i === 0) {
                    this.items.push({ label: '说明', value: val });
                    return;
                }
                const mark = val.indexOf('：');
                this.items.push({
                    label: val.substring(0, mark),
                    value: val.substring(mark + 1)
                });
            });
            OPERATE_DESC.set(this.operate, this.items);
        });
    }
}
