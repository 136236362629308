import { NgModule } from '@angular/core';
import { CoreModule } from 'core';
import { ShareModule } from 'share';

import { ProductUserRoleComponent } from './product.user.role.component';
import { ProductUserRoleBtnComponent } from './product.user.role.btn.component';


@NgModule({
    imports: [
        CoreModule,
        ShareModule
    ],
    declarations: [
        ProductUserRoleComponent,
        ProductUserRoleBtnComponent
    ],
    exports: [
        ProductUserRoleBtnComponent
    ]
})
export class ProductUserRoleModule {
}

