import {
    Directive,
    EmbeddedViewRef,
    Input,
    OnChanges,
    SimpleChange,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[stringTemplateOutlet]', //tslint:disable-line
    exportAs: 'stringTemplateOutlet'
})
export class StringTemplateOutletDirective implements OnChanges {

    @Input() stringTemplateOutletContext: any | null = null;
    @Input() stringTemplateOutlet: string | TemplateRef<any> | null = null;

    private embeddedViewRef: EmbeddedViewRef<any> | null = null;

    constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {
    }

    private recreateView(): void {
        this.viewContainer.clear();
        const isTemplateRef = this.stringTemplateOutlet instanceof TemplateRef;
        const templateRef = (isTemplateRef ? this.stringTemplateOutlet : this.templateRef) as any;
        this.embeddedViewRef = this.viewContainer.createEmbeddedView(templateRef, this.stringTemplateOutletContext);
    }

    private updateContext(): void {
        const newCtx = this.stringTemplateOutletContext;
        if (!newCtx) {
            return;
        }
        const oldCtx = this.embeddedViewRef?.context as any;
        for (const propName of Object.keys(newCtx)) {
            oldCtx[propName] = newCtx[propName];
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const hasContextShapeChanged = (ctxChange: SimpleChange): boolean => {
            const prevCtxKeys = Object.keys(ctxChange.previousValue || {});
            const currCtxKeys = Object.keys(ctxChange.currentValue || {});
            if (prevCtxKeys.length !== currCtxKeys.length) {
                return true;
            }

            for (const propName of currCtxKeys) {
                if (!prevCtxKeys.includes(propName)) {
                    return true;
                }
            }
            return false;
        };

        const shouldRecreateView = (ctxChanges: SimpleChanges): boolean => {
            const { stringTemplateOutletContext, stringTemplateOutlet } = ctxChanges;
            let shouldOutletRecreate = false;
            if (stringTemplateOutlet) {
                if (stringTemplateOutlet.firstChange) {
                    shouldOutletRecreate = true;
                } else {
                    const isPreviousOutletTemplate = stringTemplateOutlet.previousValue instanceof TemplateRef;
                    const isCurrentOutletTemplate = stringTemplateOutlet.currentValue instanceof TemplateRef;
                    shouldOutletRecreate = isPreviousOutletTemplate || isCurrentOutletTemplate;
                }
            }
            const shouldContextRecreate = stringTemplateOutletContext && hasContextShapeChanged(stringTemplateOutletContext);
            return shouldContextRecreate || shouldOutletRecreate;
        };

        const recreateView = shouldRecreateView(changes);
        if (recreateView) {
            this.recreateView();
        } else {
            this.updateContext();
        }
    }
}
