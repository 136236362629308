import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { ResizableDirective } from './resizable.directive';
import { ResizableService } from './resizable.service';

@Directive({
    selector: 'th:not(last-child)', // tslint:disable-line
    providers: [
        ResizableService
    ]
})
export class TableColResizableDirective extends ResizableDirective {
    constructor(renderer: Renderer2,
                elementRef: ElementRef<HTMLElement>,
                resizeService: ResizableService) {
        super(renderer, elementRef, resizeService);
    }
}
