export class FlowTransform {

    private _tx: number;
    private _ty: number;
    private _scale: number;

    constructor(tx?: number, ty?: number, scale?: number) {
        this._tx = tx || 0;
        this._ty = ty || 0;
        this._scale = scale || 1;
    }

    translate(tx: number, ty: number): void {
        this._tx += tx;
        this._ty += ty;
        if (this._tx > 0) {
            this._tx = 0;
        }
        if (this._ty > 0) {
            this._ty = 0;
        }
    }

    set scale(value: number) {
        if (value < 0.5 || value > 2.01) {
            return;
        }
        this._scale = value;
    }

    get scale() {
        return this._scale;
    }

    transformElement(element: HTMLElement): void {
        element.style.transformOrigin = '0 0 0';
        element.style.transform = `translate(${this._tx}px, ${this._ty}px) scale(${this._scale})`;
    }
}
