import {
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { isPresent } from 'share';
import { GroupService } from 'app/group/group.service';


@Component({
    selector: 'ai-group-path',
    templateUrl: 'group.path.component.html'
})
export class GroupPathComponent implements OnInit, OnDestroy {

    @Input() mode: string = 'text';

    path: string;

    constructor(private groupService: GroupService) {
    }

    ngOnInit() {
    }

    @Input()
    set groupId(val: number) {
        this.path = '';
        if (!isPresent(val)) {
            return;
        }
        this.groupService.qryPath(val).subscribe(items => {
            this.path = items.map(item => item.group_name).join('\\');
        });
    }

    ngOnDestroy(): void {
    }
}
