import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBoolean, isPresent } from 'share';
import { SysParamService } from '../../providers/sys.param.service';

@Component({
    selector: 'ai-sys-param-select',
    templateUrl: 'sys.param.select.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SysParamSelectComponent),
        multi: true
    }]
})

export class SysParamSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @Input() @InputBoolean() disabled: boolean;
    @Input() @InputBoolean() selectable?: boolean;
    @Input() @InputBoolean() allowClear?: boolean;

    items: Array<any> = [];
    key: string;
    name: string;

    private onChange: (_: any) => void;

    constructor(private paramService: SysParamService) {
        this.selectable = true;
    }

    ngOnInit() {
    }

    @Input()
    set type(value: string) {
        this.paramService.qry(value).subscribe(items => {
            this.items = items;
            this.initName();
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.key = obj;
        this.initName();
    }

    change(): void {
        this.onChange(this.key);
    }

    private initName(): void {
        let item = this.items.find(v => v.param_key === this.key);
        if (isPresent(item)) {
            this.name = item.param_value;
        }
    }

    ngOnDestroy() {
    }
}
