import { TreeBaseService } from './tree-base.service';
import { TreeNodeBaseComponent } from './tree.definitions';

export type TreeNodeKey = string | number;

export interface FlattenNode {
    parent: FlattenNode | null;
    children: FlattenNode[];
    pos: string;
    data: TreeNode;
    isStart: boolean[];
    isEnd: boolean[];
}

export interface TreeNodeOptions {
    id?: number;
    title: string;
    key: string;
    icon?: string;
    isLeaf?: boolean;
    checked?: boolean;
    selected?: boolean;
    selectable?: boolean;
    disabled?: boolean;
    disableCheckbox?: boolean;
    expanded?: boolean;
    children?: TreeNodeOptions[];
    data?: any;

    [key: string]: any;
}

export class TreeNode {
    private _title: string = '';
    key: string;
    level: number = 0;
    origin: TreeNodeOptions;
    // Parent Node
    parentNode: TreeNode | null = null;
    private _icon: string = '';
    private _children: TreeNode[] = [];
    private _isLeaf: boolean = false;
    private _isChecked: boolean = false;
    /**
     * @deprecated Maybe removed in next major version, use isChecked instead
     */
    private _isAllChecked: boolean = false;
    private _isSelectable: boolean = false;
    private _isDisabled: boolean = false;
    private _isDisableCheckbox: boolean = false;
    private _isExpanded: boolean = false;
    private _isHalfChecked: boolean = false;
    private _isSelected: boolean = false;
    private _isLoading: boolean = false;
    canHide: boolean = false;
    isMatched: boolean = false;

    service: TreeBaseService | null = null;
    component: TreeNodeBaseComponent;

    /** New added in Tree for easy data access */
    isStart?: boolean[];
    isEnd?: boolean[];

    get treeService(): TreeBaseService | null {
        return this.service || (this.parentNode && this.parentNode.treeService);
    }

    /**
     * Init nzTreeNode
     * @param option: user's input
     * @param parent: parent
     * @param service: base nzTreeService
     */
    constructor(option: TreeNodeOptions | TreeNode, parent: TreeNode | null = null, service: TreeBaseService | null = null) {
        if (option instanceof TreeNode) {
            return option;
        }
        this.service = service || null;
        this.origin = option;
        this.key = option.key;
        this.parentNode = parent;
        this._title = option.title || '---';
        this._icon = option.icon || '';
        this._isLeaf = option.isLeaf || false;
        this._children = [];
        // option params
        this._isChecked = option.checked || false;
        this._isSelectable = option.disabled || option.selectable !== false;
        this._isDisabled = option.disabled || false;
        this._isDisableCheckbox = option.disableCheckbox || false;
        this._isExpanded = option.isLeaf ? false : option.expanded || false;
        this._isHalfChecked = false;
        this._isSelected = (!option.disabled && option.selected) || false;
        this._isLoading = false;
        this.isMatched = false;

        /**
         * parent's checked status will affect children while initializing
         */
        if (parent) {
            this.level = parent.level + 1;
        } else {
            this.level = 0;
        }
        if (typeof option.children !== 'undefined' && option.children !== null) {
            option.children.forEach(nodeOptions => {
                const s = this.treeService;
                if (s && !s.isCheckStrictly && option.checked && !option.disabled && !nodeOptions.disabled && !nodeOptions.disableCheckbox) {
                    nodeOptions.checked = option.checked;
                }
                this._children.push(new TreeNode(nodeOptions, this));
            });
        }
    }

    /**
     * auto generate
     * get
     * set
     */
    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
        this.update();
    }

    get icon(): string {
        return this._icon;
    }

    set icon(value: string) {
        this._icon = value;
        this.update();
    }

    get children(): TreeNode[] {
        return this._children;
    }

    set children(value: TreeNode[]) {
        this._children = value;
        this.update();
    }

    get isLeaf(): boolean {
        return this._isLeaf;
    }

    set isLeaf(value: boolean) {
        this._isLeaf = value;
        this.update();
    }

    get isChecked(): boolean {
        return this._isChecked;
    }

    set isChecked(value: boolean) {
        this._isChecked = value;
        this._isAllChecked = value;
        this.origin.checked = value;
        this.afterValueChange('isChecked');
    }

    get isAllChecked(): boolean {
        return this._isAllChecked;
    }

    /**
     * @deprecated Maybe removed in next major version, use `isChecked` instead.
     */
    set isAllChecked(value: boolean) {
        this._isAllChecked = value;
    }

    get isHalfChecked(): boolean {
        return this._isHalfChecked;
    }

    set isHalfChecked(value: boolean) {
        this._isHalfChecked = value;
        this.afterValueChange('isHalfChecked');
    }

    get isSelectable(): boolean {
        return this._isSelectable;
    }

    set isSelectable(value: boolean) {
        this._isSelectable = value;
        this.update();
    }

    get isDisabled(): boolean {
        return this._isDisabled;
    }

    set isDisabled(value: boolean) {
        this._isDisabled = value;
        this.update();
    }

    get isDisableCheckbox(): boolean {
        return this._isDisableCheckbox;
    }

    set isDisableCheckbox(value: boolean) {
        this._isDisableCheckbox = value;
        this.update();
    }

    get isExpanded(): boolean {
        return this._isExpanded;
    }

    set isExpanded(value: boolean) {
        this._isExpanded = value;
        this.origin.expanded = value;
        this.afterValueChange('isExpanded');
        this.afterValueChange('reRender');
    }

    get isSelected(): boolean {
        return this._isSelected;
    }

    set isSelected(value: boolean) {
        this._isSelected = value;
        this.origin.selected = value;
        this.afterValueChange('isSelected');
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    set isLoading(value: boolean) {
        this._isLoading = value;
        this.update();
    }

    public setSyncChecked(checked: boolean = false, halfChecked: boolean = false): void {
        this.setChecked(checked, halfChecked);
        if (this.treeService && !this.treeService.isCheckStrictly) {
            this.treeService.conduct(this);
        }
    }

    /**
     * @deprecated Maybe removed in next major version, use `isChecked` instead.
     */
    public setChecked(checked: boolean = false, halfChecked: boolean = false): void {
        this.origin.checked = checked;
        this.isChecked = checked;
        this.isAllChecked = checked;
        this.isHalfChecked = halfChecked;
    }

    /**
     * @not-deprecated Maybe removed in next major version, use `isExpanded` instead.
     * We need it until tree refactoring is finished
     */
    public setExpanded(value: boolean): void {
        this._isExpanded = value;
        this.origin.expanded = value;
        this.afterValueChange('isExpanded');
    }

    /**
     * @deprecated Maybe removed in next major version, use `isSelected` instead.
     */
    public setSelected(value: boolean): void {
        if (this.isDisabled) {
            return;
        }
        this.isSelected = value;
    }

    public getParentNode(): TreeNode | null {
        return this.parentNode;
    }

    public getRootParent(): TreeNode {
        if (this.parentNode !== null) {
            return this.parentNode.getRootParent();
        }
        return this;
    }

    public getChildren(): TreeNode[] {
        return this.children;
    }

    /**
     * Support appending child nodes by position. Leaf node cannot be appended.
     */
    public addChildren(children: any[], childPos: number = -1): void {
        if (!this.isLeaf) {
            children.forEach(node => {
                const refreshLevel = (n: TreeNode) => {
                    n.getChildren().forEach(c => {
                        c.level = c.getParentNode().level + 1;
                        // flush origin
                        c.origin.level = c.level;
                        refreshLevel(c);
                    });
                };
                let child = node;
                if (child instanceof TreeNode) {
                    child.parentNode = this;
                } else {
                    child = new TreeNode(node, this);
                }
                child.level = this.level + 1;
                child.origin.level = child.level;
                refreshLevel(child);
                try {
                    childPos === -1 ? this.children.push(child) : this.children.splice(childPos, 0, child);
                    // flush origin
                } catch (e) {
                }
            });
            this.origin.children = this.getChildren().map(v => v.origin);
            // remove loading state
            this.isLoading = false;
        }
        this.afterValueChange('addChildren');
        this.afterValueChange('reRender');
    }

    public clearChildren(): void {
        // refresh checked state
        this.afterValueChange('clearChildren');
        this.children = [];
        this.origin.children = [];
        this.afterValueChange('reRender');
    }

    public remove(): void {
        const parentNode = this.getParentNode();
        if (parentNode) {
            parentNode.children = parentNode.getChildren().filter(v => v.key !== this.key);
            parentNode.origin.children = parentNode.origin.children.filter(v => v.key !== this.key);
            this.afterValueChange('remove');
            this.afterValueChange('reRender');
        }
    }

    public afterValueChange(key: string): void {
        if (this.treeService) {
            switch (key) {
                case 'isChecked':
                    this.treeService.setCheckedNodeList(this);
                    break;
                case 'isHalfChecked':
                    this.treeService.setHalfCheckedNodeList(this);
                    break;
                case 'isExpanded':
                    this.treeService.updateExpandedNodes(this);
                    break;
                case 'isSelected':
                    this.treeService.setNodeActive(this);
                    break;
                case 'clearChildren':
                    this.treeService.afterRemove(this.getChildren());
                    break;
                case 'remove':
                    this.treeService.afterRemove([this]);
                    break;
                case 'reRender':
                    this.treeService.flattenTreeData(
                        this.treeService.rootNodes,
                        this.treeService.getExpandedNodeList().map(v => v.key)
                    );
                    break;
            }
        }
        this.update();
    }

    public update(): void {
        if (this.component) {
            this.component.markForCheck();
        }
    }
}
