import { Injectable } from '@angular/core';
import { ValidatableProcessor } from './validate.processor';

@Injectable()
export class ProcessorRegistry {

    private processorSet: Set<ValidatableProcessor<any>> = new Set<ValidatableProcessor<any>>();

    constructor() {
    }

    registerProcessor(processor: ValidatableProcessor<any>): void {
        this.processorSet.add(processor);
    }

    removeProcessor(processor: ValidatableProcessor<any>): void {
        this.processorSet.delete(processor);
    }

    get processors(): Array<ValidatableProcessor<any>> {
        let items: Array<ValidatableProcessor<any>> = [];
        this.processorSet.forEach(item => items.push(item));
        return items;
    }

}
