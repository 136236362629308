import {
    AfterContentInit,
    ChangeDetectorRef,
    ContentChildren,
    Directive,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    QueryList,
    SimpleChanges
} from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkWithHref } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { InputBoolean } from '../facade/convert';
import { MenuService } from './menu.service';
import { IsMenuInsideDropDownToken } from './menu.token';
import { SubmenuService } from './submenu.service';

@Directive({
    selector: '[ai-menu-item]', //tslint:disable-line
    exportAs: 'aiMenuItem',
    host: {
        '[class.ai-dropdown-menu-item]': `isMenuInsideDropDown`,
        '[class.ai-dropdown-menu-item-selected]': `isMenuInsideDropDown && selected`,
        '[class.ai-dropdown-menu-item-disabled]': `isMenuInsideDropDown && disabled`,
        '[class.ai-menu-item]': `!isMenuInsideDropDown`,
        '[class.ai-menu-item-selected]': `!isMenuInsideDropDown && selected`,
        '[class.ai-menu-item-disabled]': `!isMenuInsideDropDown && disabled`,
        '[style.paddingLeft.px]': 'paddingLeft || inlinePaddingLeft'
    }
})
export class MenuItemDirective implements OnInit, OnChanges, OnDestroy, AfterContentInit {

    @Input() paddingLeft?: number;
    @Input() @InputBoolean() disabled = false;
    @Input() @InputBoolean() selected = false;
    @Input() @InputBoolean() matchRouterExact = false;
    @Input() @InputBoolean() matchRouter = false;

    @ContentChildren(RouterLink, { descendants: true })
    routerLinks: QueryList<RouterLink>;

    @ContentChildren(RouterLinkWithHref, { descendants: true })
    routerLinkWithHrefs: QueryList<RouterLinkWithHref>;

    level = this.submenuService ? this.submenuService.level + 1 : 1;
    selected$ = new Subject<boolean>();
    inlinePaddingLeft: number | null = null;

    private destroy$ = new Subject();

    constructor(
        private menuService: MenuService,
        private cdr: ChangeDetectorRef,
        @Optional() private submenuService: SubmenuService,
        @Inject(IsMenuInsideDropDownToken) public isMenuInsideDropDown: boolean,
        @Optional() private routerLink?: RouterLink,
        @Optional() private routerLinkWithHref?: RouterLinkWithHref,
        @Optional() private router?: Router
    ) {
        this.router?.events.pipe(
            takeUntil(this.destroy$),
            filter(e => e instanceof NavigationEnd)
        ).subscribe(() => this.updateRouterActive());
    }

    ngOnInit(): void {
        combineLatest([this.menuService.mode$, this.menuService.inlineIndent$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([mode, inlineIndent]) => {
                this.inlinePaddingLeft = mode === 'inline' ? this.level * inlineIndent : null;
            });
    }

    ngAfterContentInit(): void {
        this.routerLinks.changes.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateRouterActive());
        this.routerLinkWithHrefs.changes.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateRouterActive());
        this.updateRouterActive();
    }

    @HostListener('click', ['$event'])
    clickMenuItem(e: MouseEvent): void {
        if (this.disabled) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        this.menuService.onDescendantMenuItemClick(this);
        if (this.submenuService) {
            this.submenuService.onChildMenuItemClick(this);
        } else {
            this.menuService.onChildMenuItemClick(this);
        }
    }

    setSelectedState(value: boolean): void {
        this.selected = value;
        this.selected$.next(value);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selected) {
            this.setSelectedState(this.selected);
        }
    }

    private updateRouterActive(): void {
        if (!this.routerLinks || !this.routerLinkWithHrefs || !this.router || !this.router.navigated || !this.matchRouter) {
            return;
        }
        Promise.resolve().then(() => {
            const hasActiveLinks = this.hasActiveLinks();
            if (this.selected !== hasActiveLinks) {
                this.selected = hasActiveLinks;
                this.setSelectedState(this.selected);
                this.cdr.markForCheck();
            }
        });
    }

    private hasActiveLinks(): boolean {
        return (
            this.isLinkActive(this.routerLink) ||
            this.isLinkActive(this.routerLinkWithHref) ||
            this.routerLinks.some(this.isLinkActive.bind(this)) ||
            this.routerLinkWithHrefs.some(this.isLinkActive.bind(this))
        );
    }

    private isLinkActive(link: RouterLink | RouterLinkWithHref): boolean {
        return this.router.isActive(link?.urlTree, this.matchRouterExact);
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
