import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[ai-menu-divider]', //tslint:disable-line
    exportAs: 'menuDivider'
})
export class MenuDividerDirective {
    constructor(public elementRef: ElementRef, private renderer: Renderer2) {
        this.renderer.addClass(elementRef.nativeElement, 'dropdown-divider');
    }
}
