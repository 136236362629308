import { Component, HostBinding, OnDestroy, OnInit, Optional } from '@angular/core';
import { DrawerRef } from '../drawer';
import { isPresent } from '../facade';

@Component({
    selector: 'ai-drawer-back',
    templateUrl: 'back.drawer.component.html',
    host: {
        '[class.drawer-back-btn]': 'true',
        '[class.d-flex]': 'true'
    }
})
export class BackDrawerComponent implements OnInit, OnDestroy {

    constructor(@Optional() private drawerRef: DrawerRef) {
    }

    ngOnInit() {
    }

    goBack(): void {
        this.drawerRef.dismiss();
    }

    @HostBinding('class.d-none')
    get hide(): boolean {
        return !isPresent(this.drawerRef);
    }

    ngOnDestroy() {
    }
}
