import { Pipe, PipeTransform } from '@angular/core';
import { Operation } from 'app/group/group.model';

@Pipe({
    name: 'rights'
})
export class MenuItemRightsPipe implements PipeTransform {

    transform(operations: Operation[] = []): string {
        return operations.map(item => item.right).join(',');
    }
}


