import { BACKSPACE } from '@angular/cdk/keycodes';
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import {
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    Self,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


import { merge, Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { slideMotion, zoomMotion } from '../animation';
import { InputBoolean, isPresent } from '../facade';
import { SelectSearchComponent } from '../select/select-search.component';
import { TreeBase } from '../tree/tree-base';
import { TreeNode, TreeNodeOptions } from '../tree/tree-base-node';
import { TreeBaseService } from '../tree/tree-base.service';
import { TreeHigherOrderServiceToken } from '../tree/tree-service.resolver';
import { FormatEmitEvent } from '../tree/tree.definitions';
import { TreeSelectService } from './tree-select.service';


export function higherOrderServiceFactory(injector: Injector): TreeBaseService {
    return injector.get(TreeSelectService);
}

const TREE_SELECT_DEFAULT_CLASS = 'select-dropdown select-tree-dropdown';

@Component({
    selector: 'ai-tree-select',
    exportAs: 'treeSelect',
    animations: [slideMotion, zoomMotion],
    templateUrl: 'tree-select.component.html',
    providers: [
        TreeSelectService,
        {
            provide: TreeHigherOrderServiceToken,
            useFactory: higherOrderServiceFactory,
            deps: [[new Self(), Injector]]
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TreeSelectComponent),
            multi: true
        }
    ],
    host: {
        '[class.select]': 'true',
        '[class.form-control]': 'true',
        '[class.select-lg]': 'size==="large"',
        '[class.select-sm]': 'size==="small"',
        '[class.select-enabled]': '!disabled',
        '[class.select-disabled]': 'disabled',
        '[class.select-single]': '!isMultiple',
        '[class.select-show-arrow]': '!isMultiple',
        '[class.select-show-search]': '!isMultiple',
        '[class.select-multiple]': 'isMultiple',
        '[class.select-allow-clear]': 'allowClear',
        '[class.select-open]': 'open',
        '[class.select-focused]': 'open',
        '(click)': 'trigger()'
    }
})
export class TreeSelectComponent extends TreeBase implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {

    @Input() @InputBoolean() allowClear: boolean = true;
    @Input() @InputBoolean() showExpand: boolean = true;
    @Input() @InputBoolean() showLine: boolean = false;
    @Input() @InputBoolean() dropdownMatchSelectWidth: boolean = true;
    @Input() @InputBoolean() checkable: boolean = false;
    @Input() @InputBoolean() hideUnMatched: boolean = false;
    @Input() @InputBoolean() showIcon: boolean = false;
    @Input() @InputBoolean() showSearch: boolean = false;
    @Input() @InputBoolean() disabled = false;
    @Input() @InputBoolean() asyncData = false;
    @Input() @InputBoolean() multiple = false;
    @Input() @InputBoolean() defaultExpandAll = false;
    @Input() @InputBoolean() checkStrictly = false;
    @Input() expandedKeys: string[] = [];

    @Input() expandedIcon?: TemplateRef<{ $implicit: TreeNode; origin: TreeNodeOptions }>;
    @Input() notFoundContent?: string;
    @Input() nodes: Array<TreeNode | TreeNodeOptions> = [];
    @Input() open = false;
    @Input() size = 'default';
    @Input() placeHolder = '';
    @Input() maxTagCount: number;
    @Input() maxTagPlaceholder: TemplateRef<{ $implicit: TreeNode[] }> | null = null;
    @Input() searchFunc?: (node: TreeNodeOptions, val?: string) => boolean;

    @Output() readonly openChange = new EventEmitter<boolean>();
    @Output() readonly cleared = new EventEmitter<void>();
    @Output() readonly removed = new EventEmitter<TreeNode>();
    @Output() readonly expandChange = new EventEmitter<FormatEmitEvent>();
    @Output() readonly treeClick = new EventEmitter<FormatEmitEvent>();
    @Output() readonly treeCheckBoxChange = new EventEmitter<FormatEmitEvent>();

    @ViewChild(SelectSearchComponent, { static: false }) selectSearchComponent: SelectSearchComponent;
    @ViewChild(CdkConnectedOverlay, { static: false }) cdkConnectedOverlay: CdkConnectedOverlay;

    @Input() treeTemplate: TemplateRef<{ $implicit: TreeNode; origin: TreeNodeOptions }>;
    @ContentChild('treeTemplate', { static: true }) treeTemplateChild: TemplateRef<{ $implicit: TreeNode; origin: TreeNodeOptions }>;

    origin: CdkOverlayOrigin;
    dropdownClassName = TREE_SELECT_DEFAULT_CLASS;
    triggerWidth?: number;
    isComposing = false;
    isDestroy = true;
    isNotFound = false;
    inputValue = '';
    dropDownPosition: 'top' | 'center' | 'bottom' = 'bottom';
    selectionChangeSubscription: Subscription;
    selectedNodes: TreeNode[] = [];
    value: string[] = [];

    @Input() displayWith: (node: TreeNode) => string | undefined = (node: TreeNode) => node.title;

    onChange: any = _value => {
    };
    onTouched: any = () => {
    };

    constructor(treeService: TreeSelectService,
                private renderer: Renderer2,
                private cdRef: ChangeDetectorRef,
                private elementRef: ElementRef) {
        super(treeService);
        this.origin = new CdkOverlayOrigin(this.elementRef);
        this.renderer.addClass(this.elementRef.nativeElement, 'select');
        this.renderer.addClass(this.elementRef.nativeElement, 'tree-select');
    }

    ngOnInit(): void {
        this.isDestroy = false;
        this.selectionChangeSubscription = this.subscribeSelectionChange();
    }

    get placeHolderDisplay(): string {
        return this.inputValue || this.isComposing || this.selectedNodes.length ? 'none' : 'flex';
    }

    get isMultiple(): boolean {
        return this.multiple || this.checkable;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.closeDropDown();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { nodes } = changes;
        if (nodes) {
            this.updateSelectedNodes(true);
        }
    }

    writeValue(value: string[] | string): void {
        if (isPresent(value)) {
            if (this.isMultiple && Array.isArray(value)) {
                this.value = value;
            } else {
                this.value = [value as string];
            }
            this.updateSelectedNodes(true);
        } else {
            this.value = [];
            this.selectedNodes.forEach(node => {
                this.removeSelected(node, false);
            });
            this.selectedNodes = [];
        }
        this.cdRef.markForCheck();
    }

    registerOnChange(fn: (_: string[] | string | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    trigger(): void {
        if (this.disabled || (!this.disabled && this.open)) {
            this.closeDropDown();
        } else {
            this.openDropdown();
            if (this.showSearch || this.isMultiple) {
                this.focusOnInput();
            }
        }
    }

    openDropdown(): void {
        if (this.disabled) {
            return;
        }
        this.open = true;
        this.openChange.emit(this.open);
        this.updateCdkConnectedOverlayStatus();
        this.updatePosition();
    }

    closeDropDown(): void {
        this.onTouched();
        this.open = false;
        this.inputValue = '';
        this.openChange.emit(this.open);
        this.cdRef.markForCheck();
    }

    onKeyDownInput(e: KeyboardEvent): void {
        const keyCode = e.keyCode;
        const eventTarget = e.target as HTMLInputElement;
        if (this.isMultiple && !eventTarget.value && keyCode === BACKSPACE) {
            e.preventDefault();
            if (this.selectedNodes.length) {
                const removeNode = this.selectedNodes[this.selectedNodes.length - 1];
                this.removeSelected(removeNode);
            }
        }
    }

    onExpandedKeysChange(value: FormatEmitEvent): void {
        this.expandChange.emit(value);
        this.expandedKeys = [...value.keys];
    }

    setInputValue(value: string): void {
        this.inputValue = value;
        this.updatePosition();
    }

    removeSelected(node: TreeNode, emit: boolean = true): void {
        node.isSelected = false;
        node.isChecked = false;
        if (this.checkable) {
            this.treeService.conduct(node);
        } else {
            this.treeService.setSelectedNodeList(node, this.multiple);
        }

        if (emit) {
            this.removed.emit(node);
        }
    }

    focusOnInput(): void {
        this.selectSearchComponent?.focus();
    }

    subscribeSelectionChange(): Subscription {
        let sources: Array<Observable<any>> = [];
        sources.push(this.treeClick.pipe(
            tap((event: FormatEmitEvent) => {
                const node = event.node;
                if (this.checkable && !node.isDisabled && !node.isDisableCheckbox) {
                    node.isChecked = !node.isChecked;
                    node.isHalfChecked = false;
                    if (!this.checkStrictly) {
                        this.treeService.conduct(node);
                    }
                }
                if (this.checkable) {
                    node.isSelected = false;
                }
            }),
            filter((event: FormatEmitEvent) => {
                const node = event.node;
                return this.checkable ? !node.isDisabled && !node.isDisableCheckbox : !node.isDisabled && node.isSelectable;
            })
        ));
        if (this.checkable) {
            sources.push(this.treeCheckBoxChange);
        }
        sources.push(this.cleared);
        sources.push(this.removed);
        return merge(...sources).subscribe(() => {
            this.updateSelectedNodes();
            const value = this.selectedNodes.map(node => node.key);
            this.value = [...value];
            if (this.showSearch || this.isMultiple) {
                this.inputValue = '';
                this.isNotFound = false;
            }
            if (this.isMultiple) {
                this.onChange(value);
                this.focusOnInput();
                this.updatePosition();
            } else {
                this.closeDropDown();
                this.onChange(value.length ? value[0] : null);
            }
        });
    }

    updateSelectedNodes(init: boolean = false): void {
        if (init) {
            const nodes = this.coerceTreeNodes(this.nodes);
            this.treeService.isMultiple = this.isMultiple;
            this.treeService.isCheckStrictly = this.checkStrictly;
            this.treeService.initTree(nodes);
            if (this.checkable) {
                this.treeService.conductCheck(this.value, this.checkStrictly);
            } else {
                this.treeService.conductSelectedKeys(this.value, this.isMultiple);
            }
        }

        this.selectedNodes = [...(this.checkable ? this.getCheckedNodeList() : this.getSelectedNodeList())];
    }

    updatePosition(): void {
        setTimeout(() => this.cdkConnectedOverlay?.overlayRef?.updatePosition());
    }

    onPositionChange(position: ConnectedOverlayPositionChange): void {
        this.dropDownPosition = position.connectionPair.originY;
    }

    onClearSelection($event: Event): void {
        $event.stopPropagation();
        this.selectedNodes.forEach(node => this.removeSelected(node, false));
        this.cleared.emit();
    }

    setSearchValues($event: FormatEmitEvent): void {
        Promise.resolve().then(() => this.isNotFound = (this.showSearch || this.isMultiple) && !!this.inputValue && $event.matchedKeys.length === 0);
    }

    updateCdkConnectedOverlayStatus(): void {
        this.triggerWidth = this.elementRef.nativeElement.getBoundingClientRect().width;
    }

    trackValue(_index: number, option: TreeNode): string {
        return option.key;
    }

    ngOnDestroy(): void {
        this.isDestroy = true;
        this.closeDropDown();
        this.selectionChangeSubscription.unsubscribe();
    }
}
