import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'scan'
})

export class ScanPipe implements PipeTransform {
    private scanMap = { 0: '未开始', 1: '扫描中', 2: '完成', 3: '中断' };

    transform(value: any, ...args: any[]): any {
        return this.scanMap[value] || '错误';
    }
}