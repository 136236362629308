import { BaseUrlStrategy } from 'share';

export function initOpen(strategy: BaseUrlStrategy) {
    return () => {
        let origin = window.open;
        window.open = function(url?: string, target?: string, features?: string, replace?: boolean): Window | null {
            return origin.call(window, strategy.path(url), window.top !== window ? '_self' : target, features, replace);
        };
    };
}
