import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UIPageStepHolder } from './ui.page.step.holder';
import { UIPageService } from 'app/ui/page/ui.page.service';
import { calcHierarchy, convertToScript, FormatStepEvent, isComp, PAGE_STEP_CACHE, parseParams, parseScript, UIStep } from './ui.page.step.model';
import { isPresent, ContextMenuService, ModalService, BatchSelectService, validateError } from 'share';
import { Validatable, VALIDATABLE } from 'core';
import { UIStepOperateExplainComponent } from './explain/operate.explain.component';
import { UIPageParamsSelectService } from './params/ui.page.params.select.service';
import { UIPageStepSaveasComponent } from 'app/ui/page/step/saveas/ui.page.step.saveas.component';
import { UIPageTreeService } from 'app/ui/page/tree/page.tree.service';


@Component({
    selector: 'ai-ui-page-step',
    templateUrl: 'ui.page.step.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        .has-icon {
            padding-left: 1.2rem;
        }

        .overflow-visible {
            min-width: 13rem;
        }
    `],
    providers: [
        {
            provide: VALIDATABLE,
            useExisting: forwardRef(() => UIPageStepComponent)
        },
        ContextMenuService,
        UIPageParamsSelectService
    ]
})
export class UIPageStepComponent implements OnInit, Validatable, OnDestroy {

    @Input() type: 'component' | 'case' = 'component';
    @Input() editable = false;
    @Output() handle = new EventEmitter<FormatStepEvent>();

    script: any = {};
    items: Array<UIStep>;
    comps = new Set<any>();
    contentItem: UIStep;

    private copyItem: UIStep[];
    private destroy$: Subject<any> = new Subject();

    constructor(private holder: UIPageStepHolder,
                private service: UIPageService,
                private modal: ModalService,
                private treeService: UIPageTreeService,
                private selectService: BatchSelectService<UIStep>,
                private contextMenuService: ContextMenuService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.holder.script
            .pipe(takeUntil(this.destroy$))
            .subscribe(script => {
                this.items = [];
                this.script = parseScript(script);
                this.items = calcHierarchy(this.script?.steps ?? []);
                this.selectService.init(this.items, 'step_index');
                this.cdRef.markForCheck();
            });
    }

    contextMenu(ev: MouseEvent, content: any, item: UIStep): void {
        ev.preventDefault();
        ev.stopPropagation();
        this.contentItem = item;
        this.contextMenuService.create(ev, content);
    }

    toggle(item: UIStep): void {
        if (!isPresent(item.target) || item.target === '') {
            return;
        }
        if (this.comps.has(item.target) && item.children.length) {
            item.opened = !item.opened;
            this.cdRef.markForCheck();
            return;
        }
        this.service.qryById(+item.target).subscribe(data => {
            try {
                const script = parseScript(data.script);
                const steps = script.steps ?? [];
                const map = new Map<number, any>();
                item.steps?.forEach(config => map.set(config.id, config));

                steps.forEach(step => {
                    step.default = step.value ?? '';
                    if (map.has(step.id)) {
                        Object.assign(step, map.get(step.id));
                    }
                });
                item.steps = [...map.values()];
                item.children = steps;
                this.paramsChange();
                this.items = calcHierarchy(this.items);
                this.comps.add(item.target);
                item.opened = true;
                this.cdRef.markForCheck();
            } catch (e) {
                console.log(e);
            }
        });
    }

    drop(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        this.update(false);
    }

    add(item?: UIStep): void {
        const newItem = { paths: [], hierarchy: 0 };
        if (item) {
            let index = this.items.indexOf(item);
            this.items.splice(index + 1, 0, newItem);
        } else {
            this.items.push(newItem);
        }
        this.update();
    }

    copy(item: UIStep): void {
        const items = this.selectService.getCheckedRecords();
        this.copyItem = (items?.length > 0 ? items : [item]).map(st =>  Object.assign({}, st));
        PAGE_STEP_CACHE.set('copy_step', this.copyItem);
        toastr.success('复制成功');
    }

    paste(item: UIStep): void {
        if (!this.copyItem && !PAGE_STEP_CACHE.has('copy_step')) {
            toastr.error('请先复制步骤');
            return;
        }
        if (!this.copyItem) {
            this.copyItem = PAGE_STEP_CACHE.get('copy_step');
        }
        const index = this.items.indexOf(item);
        const items = this.copyItem.map(ci => Object.assign({}, ci, { checked: false }));
        this.items.splice(index + 1, 0, ...items);
        this.update();
    }

    saveAs(): void {
        const items = this.selectService.getCheckedRecords().filter(step => !isComp(step));
        if (items?.length <= 0) {
            validateError('请先选择非组件步骤');
            return;
        }
        const modalRef = this.modal.open(UIPageStepSaveasComponent);
        modalRef.componentInstance.steps = items;
        modalRef.result.subscribe(item => {
            this.selectService.clearChecked();
            this.treeService.clear();
            if (this.type === 'case') {
                const index = this.items.indexOf(items[0]);
                this.items.splice(index, 0, {
                    operate: 'component',
                    target: item.component_id,
                    value: item.component_name
                });
                items.forEach(step => this.items.remove(step));
            }
            this.update();
        });
    }

    del(item: UIStep): void {
        this.items.remove(item);
        this.selectService.change(item, false)
        this.update();
    }

    batchDel(): void {
        const items = this.selectService.getCheckedRecords();
        items.forEach(item => this.items.remove(item));
        this.selectService.clearChecked();
        this.update();
    }

    viewOperate(item: UIStep): void {
        const modalRef = this.modal.open(UIStepOperateExplainComponent, {
            maskClosable: true
        });
        modalRef.componentInstance.operate = item.operate;
    }

    operateChange(item: UIStep): void {
        if (item.operate !== 'click js') {
            item.target = '';
            item.paths = [];
        }
        item.value = '';
        this.update(false);
    }

    updateStep(item: UIStep, parent?: UIStep): void {
        if (parent) {
            this.updateCompConfig(item, parent);
        }
        this.paramsChange();
        this.cdRef.markForCheck();
    }

    paramsChange(): void {
        if (this.type !== 'case') {
            return;
        }
        this.holder.changeParams(parseParams(this.items));
    }

    updateCompConfig(item: UIStep, parent: UIStep): void {
        const map = new Map<number, UIStep>();
        if (!Array.isArray(parent.steps)) {
            parent.steps = [];
        }
        parent.steps?.forEach(step => map.set(step.id, step));
        const config = {
            id: item.id,
            operate: item.operate,
            default: item.default,
            value: item.value,
            target: item.target
        };
        if (map.has(item.id)) {
            map.get(item.id).value = item.value;
            Object.assign(map.get(item.id), config);
        } else {
            parent.steps.push(config);
        }
        parent.steps = parent.steps.filter(c => c.default !== c.value);
    }

    update(changeItem = true): void {
        this.items = calcHierarchy(this.items);
        if (changeItem) {
            this.selectService.changeItems(this.items);
        }
        console.log(this.items);
        this.cdRef.markForCheck();
        this.cdRef.detectChanges();
    }

    validate(): void {
        this.script.steps = this.items?.filter(item => item.operate?.length > 0);
        this.script.contain_component_ids = this.items
            .filter(item => isComp(item))
            .map(item => item.target)
            .join(',');
        this.holder.updateScript(convertToScript(this.script));
        this.paramsChange();
    }

    ngOnDestroy(): void {
        this.destroy$.next('');
        this.destroy$.complete();
    }
}
