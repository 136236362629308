import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';
import { FunctionProp } from '../../types';
import { DateFnsCalendar } from '../calendar';

import { DisabledDateFn, PanelMode, RangePartType, SupportTimeOptions } from '../standard-types';

@Component({
    selector: 'inner-popup', // tslint:disable-line
    exportAs: 'innerPopup',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'inner-popup.component.html'
})
export class InnerPopupComponent implements OnChanges {

    @Input() activeDate: DateFnsCalendar;
    @Input() endPanelMode: PanelMode;
    @Input() panelMode: PanelMode;
    @Input() showWeek: boolean;
    @Input() showTimePicker: boolean;
    @Input() timeOptions: SupportTimeOptions;
    @Input() disabledDate: DisabledDateFn;
    @Input() dateRender: FunctionProp<TemplateRef<Date> | string>;
    @Input() selectedValue: DateFnsCalendar[]; // Range ONLY
    @Input() hoverValue: DateFnsCalendar[]; // Range ONLY
    @Input() value: DateFnsCalendar;
    @Input() partType: RangePartType;

    @Output() readonly panelModeChange = new EventEmitter<PanelMode>();

    @Output() readonly headerChange = new EventEmitter<DateFnsCalendar>(); // Emitted when user changed the header's value
    @Output() readonly selectDate = new EventEmitter<DateFnsCalendar>(); // Emitted when the date is selected by click the date panel
    @Output() readonly selectTime = new EventEmitter<DateFnsCalendar>();
    @Output() readonly dayHover = new EventEmitter<DateFnsCalendar>(); // Emitted when hover on a day by mouse enter

    enablePrevNext(direction: 'prev' | 'next', panelMode: PanelMode): boolean {
        return this.showTimePicker ||
            panelMode !== this.endPanelMode ||
            !((this.partType === 'left' && direction === 'next') || (this.partType === 'right' && direction === 'prev'));

    }

    onSelectTime(date: Date): void {
        this.selectTime.emit(new DateFnsCalendar(date));
    }

    onSelectDate(date: DateFnsCalendar | Date): void {
        const value = date instanceof DateFnsCalendar ? date : new DateFnsCalendar(date);
        const timeValue = this.timeOptions && this.timeOptions.defaultOpenValue;

        if (!this.value && timeValue) {
            value.setHms(timeValue.getHours(), timeValue.getMinutes(), timeValue.getSeconds());
        }

        this.selectDate.emit(value);
    }

    onChooseMonth(value: DateFnsCalendar): void {
        this.activeDate = this.activeDate.setMonth(value.getMonth());
        if (this.endPanelMode === 'month') {
            this.value = value;
            this.selectDate.emit(value);
        } else {
            this.headerChange.emit(value);
            this.panelModeChange.emit(this.endPanelMode);
        }
    }

    onChooseYear(value: DateFnsCalendar): void {
        this.activeDate = this.activeDate.setYear(value.getYear());
        if (this.endPanelMode === 'year') {
            this.value = value;
            this.selectDate.emit(value);
        } else {
            this.headerChange.emit(value);
            this.panelModeChange.emit(this.endPanelMode);
        }
    }

    onChooseDecade(value: DateFnsCalendar): void {
        this.activeDate = this.activeDate.setYear(value.getYear());
        if (this.endPanelMode === 'decade') {
            this.value = value;
            this.selectDate.emit(value);
        } else {
            this.headerChange.emit(value);
            this.panelModeChange.emit('year');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeDate && !changes.activeDate.currentValue) {
            this.activeDate = new DateFnsCalendar();
        }
        if (changes.panelMode && changes.panelMode.currentValue === 'time') {
            this.panelMode = 'date';
        }
    }
}
