export function exportTxt(text: string, name: string): void {
    let blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
    let a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `${name}.txt`;
    $(a).css('opacity', 0);
    $(document.body).append(a);
    a.click();
    a.remove();
}
