import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TextareaAutoHeightDirective } from './textarea.auto.height';
import { TextareaGroupDirective } from './textarea.group';


@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        TextareaAutoHeightDirective,
        TextareaGroupDirective
    ],
    declarations: [
        TextareaAutoHeightDirective,
        TextareaGroupDirective
    ]
})
export class TextareaModule {
}
