import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalHolder } from 'app/global.holder';
import { LoginService } from 'app/login/login.service';
import { LocalStorageService } from 'ngx-webstorage';


@Component({
    selector: 'ai-auto-login',
    templateUrl: 'single.login.component.html'
})

export class SingleLoginComponent implements OnInit {

    error: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private global: GlobalHolder,
                private storage: LocalStorageService,
                private loginService: LoginService) {
    }

    ngOnInit() {
        this.login();
    }

    private login() {
        this.error = '';
        let url = this.route.snapshot.queryParams.url;
        let urlParams: any;

        if (url?.includes('?')) {
            const vars = url.split('?');
            url = vars[0];
            urlParams = this.getQueryParams(vars[1]);
        }

        if (!url) {
            url = 'pages/product/dashboard';
        }
        this.loginService.singleSync(this.route.snapshot.queryParams).subscribe({
            next: (token) => {
                this.storage.store('token', token);
                this.global.updateToken(token);
                this.global.refreshGuard = true;
                this.router.navigate([url], { queryParams: urlParams });
            },
            error:(result: HttpErrorResponse) => this.error = result.error ? result.error.message : '自动登录失败'
        });
    }

    private getQueryParams(query: string): any {
        if (query.length <= 0) {
            return {};
        }

        const params: any = {};
        const pairs = query?.split('&');
        pairs.forEach(pair => {
            const vars = pair?.split('=');
            params[vars?.[0]] = vars?.[1];
        });
        return params;
    }
}
