import { InjectionToken } from '@angular/core';
import { Initializer } from 'core';
import { Observable } from 'rxjs';
import { AppConfigGuardService } from 'app/guard/app.config.guard.service';
import { AuthGuardInitializer } from 'app/guard/auth.guard.service';
import { LoginModeGuardService } from 'app/guard/login.mode.guard.service';
import { MenuGuardInitializer } from 'app/guard/menu.guard.initializer';
import { ProductGuardInitializer } from 'app/guard/product.guard.initializer';
import { PublicKeyGuardService } from 'app/guard/public.key.guard.service';
import { TenantGuardInitializer } from 'app/guard/tenant.guard.initializer';
import { LoginVerifyGuardService } from 'app/guard/login.verify.guard.service';
import { PasswordGuardInitializer } from 'app/guard/password.guard.initializer';

export const INITIALIZER = new InjectionToken<Array<Initializer<any>>>('GuardInitializer');

export interface GuardInitializer {
    init(): Observable<boolean>;
}

export const GUARD_INITIALIZERS = [
    { provide: INITIALIZER, useClass: AuthGuardInitializer, multi: true },
    { provide: INITIALIZER, useClass: PasswordGuardInitializer, multi: true },
    { provide: INITIALIZER, useClass: PublicKeyGuardService, multi: true },
    { provide: INITIALIZER, useClass: LoginModeGuardService, multi: true },
    { provide: INITIALIZER, useClass: LoginVerifyGuardService, multi: true },
    { provide: INITIALIZER, useClass: AppConfigGuardService, multi: true },
    { provide: INITIALIZER, useClass: MenuGuardInitializer, multi: true },
    { provide: INITIALIZER, useClass: TenantGuardInitializer, multi: true },
    { provide: INITIALIZER, useClass: ProductGuardInitializer, multi: true }
];
