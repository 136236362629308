import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AiOverlayModule } from '../overlay/overlay.module';
import { SelectModule } from '../select/select.module';
import { TreeModule } from '../tree';

import { TreeSelectComponent } from './tree-select.component';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        FormsModule,
        ReactiveFormsModule,
        SelectModule,
        TreeModule,
        AiOverlayModule
    ],
    declarations: [TreeSelectComponent],
    exports: [TreeSelectComponent]
})
export class TreeSelectModule {
}
