import { ConnectionPositionPair } from '@angular/cdk/overlay';

export const DEFAULT_OVERLAY_POSITION = [
    new ConnectionPositionPair({
        originX: 'start',
        originY: 'bottom'
    }, {
        overlayX: 'start',
        overlayY: 'top'
    }, 0, 3)
];
