import { Injectable } from '@angular/core';
import { UIPage } from 'app/ui/page/ui.page.model';
import { SubmitProcessor, ValidateRegistry } from 'core';
import { Observable, of } from 'rxjs';
import { validateError } from 'share';

@Injectable()
export class PageValidateSubmit implements SubmitProcessor {

    constructor(private registry: ValidateRegistry) {
    }

    postProcess(item: UIPage): Observable<any> {
        if (!item.component_name) {
            validateError('请输入组件名称');
        }
        if (!item.fun_id) {
            validateError('请选择目录');
        }
        this.registry.validate();
        return of(item);
    }
}
