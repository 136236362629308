import { NgModule } from '@angular/core';
import { ProductEditComponent } from 'app/product/edit/product.edit.component';
import { ProductUserModule } from 'app/product/user/product.user.module';
import { CoreModule } from 'core';
import { ShareModule } from 'share';

@NgModule({
    imports: [
        CoreModule,
        ShareModule,
        ProductUserModule
    ],
    declarations: [
        ProductEditComponent
    ]
})
export class ProductEditModule {
}
