import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { getValueInRange, isNumber, toInteger } from '../facade';
import { PaginationConfig } from './pagination.config';

@Component({
    selector: 'ai-pagination',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'pagination.component.html',
    host: { 'role': 'navigation' }
})
export class PaginationComponent implements OnChanges {

    pageCount = 0;
    pages: number[] = [];

    /**
     * If `true`, pagination links will be disabled.
     */
    @Input() disabled: boolean;

    /**
     * If `true`, the "First" and "Last" page links are shown.
     */
    @Input() boundaryLinks: boolean;

    /**
     * If `true`, the "Next" and "Previous" page links are shown.
     */
    @Input() directionLinks: boolean;

    /**
     * If `true`, the ellipsis symbols and first/last page numbers will be shown when `maxSize` > number of pages.
     */
    @Input() ellipses: boolean;

    /**
     * Whether to rotate pages when `maxSize` > number of pages.
     *
     * The current page always stays in the middle if `true`.
     */
    @Input() rotate: boolean;

    /**
     *  The number of items in your paginated collection.
     *
     *  Note, that this is not the number of pages. Page numbers are calculated dynamically based on
     *  `collectionSize` and `pageSize`. Ex. if you have 100 items in your collection and displaying 20 items per page,
     *  you'll end up with 5 pages.
     */
    @Input() collectionSize: number;

    /**
     *  The maximum number of pages to display.
     */
    @Input() maxSize: number;

    /**
     *  The current page.
     *
     *  Page numbers start with `1`.
     */
    @Input() page = 1;

    /**
     *  The number of items per page.
     */
    @Input() pageSize: number;

    /**
     *  An event fired when the page is changed. Will fire only if collection size is set and all values are valid.
     *
     *  Event payload is the number of the newly selected page.
     *
     *  Page numbers start with `1`.
     */

    /**
     * The pagination display size.
     *
     * Bootstrap currently supports small and large sizes.
     */
    @Input() size: 'sm' | 'lg';
    @Input() align: 'left' | 'center' | 'right';
    @Input() showQuickJumper: boolean;
    @Input() showSizeChanger: boolean;
    @Input() showTotal: boolean;
    @Input() pageSizeOptions: Array<number>;

    @Output() pageChange = new EventEmitter<number>(true);
    @Output() pageSizeChange = new EventEmitter<number>();

    constructor(config: PaginationConfig) {
        this.disabled = config.disabled;
        this.boundaryLinks = config.boundaryLinks;
        this.directionLinks = config.directionLinks;
        this.ellipses = config.ellipses;
        this.maxSize = config.maxSize;
        this.pageSize = config.pageSize;
        this.rotate = config.rotate;
        this.size = config.size;

        this.showQuickJumper = config.showQuickJumper;
        this.showSizeChanger = config.showSizeChanger;
        this.showTotal = config.showTotal;
        this.pageSizeOptions = config.pageSizeOptions;
        this.align = config.align;
    }

    @HostBinding('class.d-none')
    get hide(): boolean {
        return !this.collectionSize || this.collectionSize <= 10;
    }

    hasPrevious(): boolean {
        return this.page > 1;
    }

    hasNext(): boolean {
        return this.page < this.pageCount;
    }

    nextDisabled(): boolean {
        return !this.hasNext() || this.disabled;
    }

    previousDisabled(): boolean {
        return !this.hasPrevious() || this.disabled;
    }

    selectPage(pageNumber: number): void {
        this._updatePages(pageNumber);
    }

    changePageSize(): void {
        this.pageSizeChange.emit(this.pageSize);
        this._updatePages(1);
        if (this.page === 1) {
            this.pageChange.emit(this.page);
        }
    }

    goPage(event: KeyboardEvent): void {
        if (event.code !== 'Enter') {
            return;
        }
        let target = event.target as HTMLInputElement;
        if (!isNumber(target.value)) {
            target.value = '';
            return;
        }
        this._updatePages(toInteger(target.value));
        target.value = '';
    }


    ngOnChanges(changes: SimpleChanges): void {
        this._updatePages(this.page);
    }

    isEllipsis(pageNumber): boolean {
        return pageNumber === -1;
    }

    /**
     * Appends ellipses and first/last page number to the displayed pages
     */
    private _applyEllipses(start: number, end: number) {
        if (!this.ellipses) {
            return;
        }
        if (start > 0) {
            // The first page will always be included. If the displayed range
            // starts after the third page, then add ellipsis. But if the range
            // starts on the third page, then add the second page instead of
            // an ellipsis, because the ellipsis would only hide a single page.
            if (start > 2) {
                this.pages.unshift(-1);
            } else if (start === 2) {
                this.pages.unshift(2);
            }
            this.pages.unshift(1);
        }
        if (end < this.pageCount) {
            // The last page will always be included. If the displayed range
            // ends before the third-last page, then add ellipsis. But if the range
            // ends on third-last page, then add the second-last page instead of
            // an ellipsis, because the ellipsis would only hide a single page.
            if (end < (this.pageCount - 2)) {
                this.pages.push(-1);
            } else if (end === (this.pageCount - 2)) {
                this.pages.push(this.pageCount - 1);
            }
            this.pages.push(this.pageCount);
        }
    }

    private _applyRotation(): [number, number] {
        let start = 0;
        let end = this.pageCount;
        let leftOffset = Math.floor(this.maxSize / 2);
        let rightOffset = this.maxSize % 2 === 0 ? leftOffset - 1 : leftOffset;

        if (this.page <= leftOffset) {
            // very beginning, no rotation -> [0..maxSize]
            end = this.maxSize;
        } else if (this.pageCount - this.page < leftOffset) {
            // very end, no rotation -> [len-maxSize..len]
            start = this.pageCount - this.maxSize;
        } else {
            // rotate
            start = this.page - leftOffset - 1;
            end = this.page + rightOffset;
        }

        return [start, end];
    }

    private _applyPagination(): [number, number] {
        let page = Math.ceil(this.page / this.maxSize) - 1;
        let start = page * this.maxSize;
        let end = start + this.maxSize;

        return [start, end];
    }

    private _setPageInRange(newPageNo) {
        const prevPageNo = this.page;
        this.page = getValueInRange(newPageNo, this.pageCount, 1);

        if (this.page !== prevPageNo && isNumber(this.collectionSize)) {
            this.pageChange.emit(this.page);
        }
    }

    private _updatePages(newPage: number) {
        this.pageCount = Math.ceil(this.collectionSize / this.pageSize);

        if (!isNumber(this.pageCount)) {
            this.pageCount = 0;
        }

        this.pages.length = 0;
        for (let i = 1; i <= this.pageCount; i++) {
            this.pages.push(i);
        }

        this._setPageInRange(newPage);

        if (this.maxSize === 0 || this.pageCount <= this.maxSize) {
            return;
        }

        let start;
        let end;

        if (this.rotate) {
            [start, end] = this._applyRotation();
        } else {
            [start, end] = this._applyPagination();
        }

        this.pages = this.pages.slice(start, end);

        this._applyEllipses(start, end);
    }
}
