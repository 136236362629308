import { Pipe, PipeTransform } from '@angular/core';
import { isPresent } from 'share';
import { Node, NodeType } from './node';

let typeMap = new Map<NodeType, string>();
typeMap.set(NodeType.String, 'string');
typeMap.set(NodeType.Boolean, 'boolean');
typeMap.set(NodeType.Number, 'number');
typeMap.set(NodeType.Object, 'object');
typeMap.set(NodeType.ArrayString, 'array<string>');
typeMap.set(NodeType.ArrayBoolean, 'array<boolean>');
typeMap.set(NodeType.ArrayNumber, 'array<number>');
typeMap.set(NodeType.ArrayObject, 'array<object>');
typeMap.set(NodeType.Array2String, 'array2<string>');
typeMap.set(NodeType.Array2Boolean, 'array2<boolean>');
typeMap.set(NodeType.Array2Number, 'array2<number>');
typeMap.set(NodeType.Array2Object, 'array2<object>');
typeMap.set(NodeType.Element, 'element');
typeMap.set(NodeType.Attribute, 'attribute');
typeMap.set(NodeType.xml, 'xml');
typeMap.set(NodeType.json, 'json');

@Pipe({
    name: 'nodeType'
})
export class NodeTypePipe implements PipeTransform {

    transform(value: NodeType): any {
        return typeMap.get(value);
    }
}

@Pipe({
    name: 'nodeName'
})
export class NodeNamePipe implements PipeTransform {

    transform(name: string, node: Node): any {
        return this.nodeName(node);
    }

    nodeName(value: Node): string {
        if (!isPresent(value.parent)) {
            return value.name;
        }

        let parent = value.parent;
        let type = parent.type;
        if (type & NodeType.Array) {
            return `${this.nodeName(parent)}[${parent.children.findIndex(item => item === value)}]`;
        }
        return value.name;
    }
}
