import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiftRule } from 'app/data/pool/pool.model';
import { GlobalHolder } from 'app/global.holder';
import { IPageService } from 'core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class PoolSiftService implements IPageService {

    private baseUrl = '/xltp/ats/case/datapool';

    constructor(private http: HttpClient, private global: GlobalHolder) {
    }

    qryByPage(params: { page_no?: number; page_size?: number; [p: string]: any }): Observable<any> {
        let httpParams = new HttpParams();
        Object.keys(params).filter(key => isPresent(params[key])).forEach(key => httpParams = httpParams.set(key, params[key]));
        return this.http.get(`${this.baseUrl}/${params.pool_id}/sift_rules`, { params: httpParams });
    }

    qryByPool(key: string): Observable<Array<SiftRule>> {
        let httpParams = new HttpParams().set('page_no', '1').set('page_size', '100').set('data_key', key).set('product_id', `${this.global.product_id}`);
        return this.http.get(`${this.baseUrl}/sift_rules`, { params: httpParams }).pipe(map<any, Array<SiftRule>>(page => page.page_data || []));
    }

    del(id: number): Observable<any> {
        return this.http.post(`${this.baseUrl}/sift_rules/${id}/delete`, {});
    }

    add(pool_id: number, item: SiftRule): Observable<any> {
        return this.http.post(`${this.baseUrl}/${pool_id}/sift_rules`, item);
    }

    mod(id: number, item: SiftRule): Observable<any> {
        return this.http.post(`${this.baseUrl}/sift_rules/${id}/modify`, item);
    }
}
