import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MoreOperationComponent } from './more.operation.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        MoreOperationComponent
    ],
    declarations: [
        MoreOperationComponent
    ]
})
export class MoreOperationModule {
}
