import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalHolder } from 'app/global.holder';
import { GuardInitializer } from 'app/guard/guard.initializer';
import { TenantService } from 'app/system/tenant/tenant.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, of, zip } from 'rxjs';
import { mapTo, switchMap, tap } from 'rxjs/operators';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class TenantGuardInitializer implements GuardInitializer {

    constructor(private router: Router,
                private global: GlobalHolder,
                private storage: LocalStorageService,
                private tenantService: TenantService) {
    }

    init(): Observable<boolean> {
        return zip(
            this.tenantService.currentTenant(this.global.user_id),
            this.tenantService.qryByUser(this.global.user_id)
        ).pipe(switchMap(([item, items]) => {
            console.log(item)
            console.log(items)
            this.global.updateTenants(items);
            this.global.updateTenant(item);
            if (isPresent(item)) {
                return of(true);
            }
            if (items.length > 1) {
                this.router.navigate(['pages/sys/tenant/switch']);
                return of(false);
            }
           
            return this.tenantService.changeTenant(items[0].tenant_id).pipe(tap(() => this.global.updateTenant(items[0])), mapTo(true));
        }));
    }
}
