import { TemplateRef, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { DrawerRef } from './drawer-ref';

export type DrawerPlacement = 'left' | 'right' | 'top' | 'bottom';

export interface DrawerOptionsOfComponent<T = any, D = any> {
    maskClosable?: boolean;
    scrollable?: boolean;
    mask?: boolean;
    keyboard?: boolean;
    content?: TemplateRef<{ $implicit: D; drawerRef: DrawerRef }> | Type<T>;
    contentStyle?: object;
    inputs?: D;
    drawerClass?: string;
    placement?: DrawerPlacement;
    disposeOnNavigation?: boolean;
    zIndex?: number;
    offsetX?: number;
    offsetY?: number;
}

export interface DrawerOptions<T = any, D = any> extends DrawerOptionsOfComponent<T, D> {
    beforeClose?(r): Observable<boolean>;
}
