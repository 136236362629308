import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({ selector: '[aiHorizontalResize]' })
export class HorizontalResizeDirective implements AfterViewInit, OnDestroy {

    @Input() leftElement: HTMLElement;
    @Input() rightElement: HTMLElement;

    private currentX = 0;
    private moveSubscriptions: Array<Subscription> = [];
    private initWidth = 0;
    private minWidth = 5;

    constructor(private element: ElementRef) {
        this.mouseDown = this.mouseDown.bind(this);
        this.mouseUp = this.mouseUp.bind(this);
        this.doubleClick = this.doubleClick.bind(this);
    }

    ngAfterViewInit(): void {
        $(this.element.nativeElement).on('mousedown', this.mouseDown);
        $(this.element.nativeElement).on('mouseup', this.mouseUp);
        $(this.leftElement).on('mouseup', this.mouseUp);
        $(this.rightElement).on('mouseup', this.mouseUp);
        $(this.element.nativeElement).on('dblclick', this.doubleClick);
        setTimeout(() => this.initWidth = $(this.leftElement).width(), 300);
    }

    mouseDown(event: JQuery.Event): void {
        this.currentX = event.pageX;
        let elementSubscription = fromEvent<MouseEvent>(this.element.nativeElement, 'mousemove').subscribe(evt => this.mouseMove(evt));
        let leftSubscription = fromEvent<MouseEvent>(this.leftElement, 'mousemove').subscribe(evt => this.mouseMove(evt));
        let rightSubscription = fromEvent<MouseEvent>(this.rightElement, 'mousemove').subscribe(evt => this.mouseMove(evt));
        this.moveSubscriptions.push(elementSubscription);
        this.moveSubscriptions.push(leftSubscription);
        this.moveSubscriptions.push(rightSubscription);
    }

    mouseMove(event: MouseEvent): void {
        let x = event.pageX;
        let width = $(this.leftElement).width() + x - this.currentX;
        if (width < this.minWidth) {
            width = this.minWidth;
        }
        let maxWidth = $(this.leftElement).width() + $(this.rightElement).width();
        if (width > maxWidth) {
            width = maxWidth;
        }
        $(this.leftElement).width(width);
        this.currentX = x;
    }

    doubleClick(): void {
        let width = $(this.leftElement).width();
        if (width <= this.minWidth || width > this.initWidth) {
            $(this.leftElement).width(this.initWidth);
        } else {
            $(this.leftElement).width(this.minWidth);
        }
    }

    mouseUp(): void {
        this.moveSubscriptions.forEach(item => item.unsubscribe());
    }

    ngOnDestroy(): void {
        $(this.element.nativeElement).off('mousedown', this.mouseDown);
        $(this.element.nativeElement).off('mouseup', this.mouseUp);
        $(this.leftElement).off('mouseup', this.mouseUp);
        $(this.rightElement).off('mouseup', this.mouseUp);
    }
}
