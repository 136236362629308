export enum DrawerClass {
    SIZE_4 = 'drawer-size-4',
    SIZE_5 = 'drawer-size-5',
    SIZE_6 = 'drawer-size-6',
    SIZE_7 = 'drawer-size-7',
    SIZE_8 = 'drawer-size-8',
    SIZE_9 = 'drawer-size-9',
    PAGE = 'drawer-page',
    FULLSCREEN = 'drawer-fullscreen',
}
