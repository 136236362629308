import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CrumbModel } from './crumb.model';
import { Router } from '@angular/router';
import { Menu } from 'app/system/menu/menu';

@Injectable({ providedIn: 'root' })
export class CrumbHolder {

    menu: BehaviorSubject<Menu> = new BehaviorSubject<Menu>(null);

    crumbList: BehaviorSubject<CrumbModel[]> = new BehaviorSubject<CrumbModel[]>([]);

    currentCrumb: BehaviorSubject<CrumbModel> = new BehaviorSubject<CrumbModel>(null);

    homeCrumb = new CrumbModel({ name: '首页', type: 'router', path: 'pages/product/workbench' });

    constructor(private router: Router) {
    }

    updateMenu(m) {
        this.menu.next(m);
        this.init();
    }

    updateCurrentCrumb(val: CrumbModel) {
        if (this.crumbList.getValue().last().id === val.id) {
            return;
        }
        let crumbList = this.crumbList.getValue();
        while (crumbList.length) {
            let crumb = crumbList.pop();
            if (crumb.id === val.id) {
                crumbList.push(crumb);
                break;
            }
            if (crumb.type === 'drawer') {
                crumb.drawerRef.close();
            }
        }
        this.crumbList.next([...crumbList]);
        this.currentCrumb.next(this.crumbList.getValue().last());
        if (val.type === 'router') {
            this.router.navigateByUrl(val.path).then(() => {
            });
        }
    }

    pushCrumb(val) {
        console.log('pushCrumb');
        let crumb = new CrumbModel(val);
        let list = this.crumbList.getValue();
        list.push(crumb);
        this.crumbList.next([...list]);
        this.currentCrumb.next(this.crumbList.getValue().last());
    }

    init() {
        let list = [];
        // list.push(this.homeCrumb);
        let menu = this.menu.getValue();
        if (menu?.parent) {
            list.push(new CrumbModel({ name: menu.parent.menu_name, type: 'router', path: menu.parent.menu_url }));
        }
        if (menu) {
            list.push(new CrumbModel({ name: menu.menu_name, type: 'router', path: menu.menu_url }));
        }
        this.crumbList.next(list);
        this.currentCrumb.next(this.crumbList.getValue().last());
    }

    clear() {
        this.crumbList.next([]);
        this.currentCrumb.next(null);
    }
}
