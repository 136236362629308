import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { flatNodes, isPresent, TreeNodeOptions } from 'share';
import { Group, GroupType } from 'app/group/group.model';
import { buildGroupTree } from 'app/group/group.util';
import { GlobalHolder } from 'app/global.holder';
import { GroupService } from 'app/group/group.service';


@Injectable({ providedIn: 'root' })
export class GroupTreeService {

    constructor(private global: GlobalHolder, private groupService: GroupService) {
    }

    initTree(type: string, filter = (_: Group) => true): Observable<Array<TreeNodeOptions>> {
        const productId = type === GroupType.MACHINE ? null : this.global.product_id;
        return this.groupService.qryGroup(productId, type).pipe(map(groups => {
            if (isPresent(filter)) {
                groups = groups?.filter(group => filter(group));
            }
            let nodes = buildGroupTree(groups);
            return this.conductGroupTree(nodes);
        }));
    }

    conductGroupTree(nodes: Array<TreeNodeOptions>): Array<TreeNodeOptions> {
        flatNodes(nodes).forEach(node => node.isLeaf = !node.children?.length);
        return nodes;
    }
}
