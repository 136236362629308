import { Directive, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { isPresent } from 'share';
import { PAGE_SIZE } from '../constants';
import { Page } from './page.model';
import { IPageService } from './page.service';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class PageComponent<T> implements OnInit, OnDestroy {

    page: Page<T>;
    params: any = {};
    items: Array<T> = [];
    more: boolean;

    private _pageNo = 1;

    protected constructor(private iService: IPageService) {
        this.more = false;
        this.initPage();
    }

    qry(initPageNo = true): void {
        this.initPage();
        if (initPageNo) {
            this._pageNo = this.page.page_no = 1;
        } else {
            this._pageNo = this.page.page_no || 1;
        }
        this._qry();
    }

    private initPage(): void {
        if (isPresent(this.page)) {
            return;
        }
        this.page = { page_no: this._pageNo, page_size: PAGE_SIZE };
    }

    private _qry(initPageNo = true): void {
        this.params.page_size = this.page.page_size;
        this.params.page_no = this.page.page_no;
        this.beforeQry();
        this.iService.qryByPage(this.params).pipe(
            map<any, Page<T>>(res => ({
                total_row: res.row_count,
                total_page: res.page_count,
                items: res.page_data
            })))
            .subscribe(page => {
                this.page.total_row = page.total_row;
                this.page.total_page = Math.ceil(page.total_row / this.page.page_size);
                page.items = page.items ?? [];
                if (this.more && !initPageNo) {
                    this.items.push(...page.items);
                } else {
                    this.items = page.items;
                }
                this.afterPage();
            });
    }


    ngOnInit(): void {
        this.qry();
    }

    get pageNo(): number {
        return this._pageNo;
    }

    set pageNo(value: number) {
        this._pageNo = value;
        this.page.page_no = value;
        this._qry(false);
    }

    beforeQry() {
    }

    afterPage() {
    }

    afterDel(): void {
        if (this.items.length === 1 && this.pageNo > 1) {
            this.pageNo = this._pageNo - 1;
        } else {
            this.qry(false);
        }
        toastr.success('删除成功');
    }

    ngOnDestroy(): void {
    }
}
