import { Injectable } from '@angular/core';
import { ValueMode } from '../model';
import { RightJudgeService } from '../right/right.judge.service';


@Injectable({ providedIn: 'root' })
export class EnumSelectService {

    constructor(private judgeService: RightJudgeService) {
    }

    filter(enums: any[] = []): Array<ValueMode> {
        if (!enums.includes(ValueMode.FACTORY)) {
            return enums;
        }
        const hasRight = this.judgeService.hasFactoryRight();
        return  enums.filter(value => value !== ValueMode.FACTORY || hasRight);
    }
}
