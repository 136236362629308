import { Checkable, isPresent } from 'share';

export interface User extends Checkable {
    user_id?: number;
    user_name?: string;
    staff_code?: string;
    pwd?: string;
    pwd_confirm?: string;
    mobile?: string;
    email?: string;
    nt_account?: string;
    status?: number;
    user_type?: number;
    product_id?: number;

    is_auto?: boolean;

    product_admin?: boolean;
    verify_code_val?: string;
}

export interface IDCard {
    user_id?: number;
    name?: string;
    sex?: string;
    birth_date?: string;
    ngb_birth_date?: Date;
    address?: string;
    card_code?: string;
    nation?: string;
}

export function isAdmin(user: User): boolean {
    return isPresent(user) && ['admin(admin)', 'admin'].includes(user.user_name);
}
