import { Injectable } from '@angular/core';
import { isPresent } from '../../facade';
import { ErrorFormatter } from './ErrorFormatter';
import { DateHelperService } from '../../datepicker';

const FORMATS: any = {
    invalid: '日期不合法',
    requiredBefore: '不能早于日期%s',
    requiredAfter: '不能晚于日期%s'
};

@Injectable()
export class NgbDateErrorFormatter implements ErrorFormatter {

    constructor(private dateHelper: DateHelperService) {
    }

    support(key: string): boolean {
        return key === 'ngbDate';
    }

    format(key: string, value: any): string {
        if (!isPresent(value)) {
            return;
        }
        let validKey = Object.keys(value)[0];
        return FORMATS[validKey].replace('%s', this.dateHelper.format(value[validKey]));
    }

}
