import { AfterContentInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { isPresent } from '../facade';
import { EditorRichService } from './editor.rich.service';

@Component({
    selector: 'rich-editor', // tslint:disable-line
    templateUrl: 'editor.rich.component.html',
    styleUrls: ['editor.rich.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: RichEditorComponent,
        multi: true
    }]
})
export class RichEditorComponent implements AfterContentInit, ControlValueAccessor, OnDestroy {

    private _height: number;

    onChange: (_: any) => void;

    @ViewChild('editor', { static: true })
    private editor: ElementRef;

    constructor(private elementRef: ElementRef<HTMLElement>, private editorService: EditorRichService) {
    }

    ngAfterContentInit(): void {
        $(this.editor.nativeElement).summernote(this.editorService.initOptions({
            height: this._height
        }));

        $(this.editor.nativeElement).on('summernote.change', (event, contents) => {
            if (!isPresent(this.onChange)) {
                return;
            }
            this.onChange(contents);
        });
    }

    @Input()
    set height(value: number) {
        this._height = value;
        $(this.elementRef.nativeElement).find('.note-editable').height(value);
    }


    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        $(this.editor.nativeElement).summernote('code',  obj ?? '<p></p>');
    }

    ngOnDestroy(): void {
        $(this.editor.nativeElement).summernote('destroy');
    }

}
