import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class StandardCodeService {

    private baseUrl = '/xltp/sys/standardcode';

    constructor(private http: HttpClient) {
    }

    qry(qry: any = {}): Observable<any> {
        let params: HttpParams = new HttpParams();
        Object.keys(qry).filter(key => isPresent(qry[key])).forEach(key => params = params.set(key, qry[key]));
        return this.http.get(`${this.baseUrl}/list`, { params });
    }
}