import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { StepVerifyResult } from 'app/case/verify/case.verify';
import { DetectCaseLog, DetectDBLog } from 'app/detect/report/detect.report.model';
import { TaskSql } from 'app/detect/task/db/detect.task.db';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPresent } from 'share';
import {
    DetectCase,
    DetectPerfAvgCase,
    DetectPerfCaseLog,
    DetectPerfTaskLog,
    DetectTask,
    NotifyConfig
} from './detect.task.model';
import { ParamsTransformer } from 'core';

@Injectable({ providedIn: 'root' })
export class DetectTaskService {

    private baseUrl = '/xltp/ats/detect';

    constructor(private http: HttpClient, private transformer: ParamsTransformer) {
    }

    qryByPage(params: { page_no?: number; page_size?: number; [p: string]: any }, block = true): Observable<any> {
        let headers = new HttpHeaders();
        if (!block) {
            headers = headers.set(Headers.BLOCK, 'false');
        }
        return this.http.get(`${this.baseUrl}/list`, { params: this.transformer.transform(params), headers });
    }

    qryAll(params: any): Observable<Array<DetectTask>> {
        let httParams = new HttpParams({ fromString: 'page_no=1&page_size=10000' });
        Object.keys(params).filter(key => isPresent(params[key])).forEach(key => httParams = httParams.set(key, params[key]));
        return this.http.get(`${this.baseUrl}/list`, { params: httParams }).pipe(map<any, Array<DetectTask>>(page => page.page_data || []));
    }

    qryById(id: number): Observable<DetectTask> {
        return this.http.get<DetectTask>(`${this.baseUrl}/${id}`);
    }

    add(data: any): Observable<any> {
        return this.http.post(this.baseUrl, data);
    }

    mod(data: any): Observable<any> {
        return this.http.put(`${this.baseUrl}/${data.task_id}`, data);
    }

    del(id: number): Observable<any> {
        return this.http.delete(`${this.baseUrl}/${id}`);
    }

    qryCases(id: number): Observable<Array<DetectCase>> {
        return this.http.get(`${this.baseUrl}/${id}/caselist`).pipe(map<any, Array<DetectCase>>(items => items || []));
    }

    qryNotify(id: number): Observable<NotifyConfig> {
        return this.http.get<NotifyConfig>(`${this.baseUrl}/${id}/notifyconf`).pipe(map(item => new NotifyConfig(item)));
    }

    saveNotify(id: number, item: NotifyConfig): Observable<any> {
        return this.http.put(`${this.baseUrl}/${id}/notifyconf`, item);
    }

    start(id: number): Observable<any> {
        return this.http.post(`${this.baseUrl}/${id}/start`, {});
    }

    stop(id: number): Observable<any> {
        return this.http.post(`${this.baseUrl}/${id}/stop`, {});
    }

    exec(id: number, data: any): Observable<any> {
        let headers = new HttpHeaders().set(Headers.BLOCK, '正在执行...');
        return this.http.post(`${this.baseUrl}/${id}/exec`, data, { headers });
    }

    qryLog(id: number, params: { page_no?: any, page_size?: any, [key: string]: any }): Observable<any> {
        let httParams = new HttpParams();
        Object.keys(params).filter(key => isPresent(params[key])).forEach(key => httParams = httParams.set(key, params[key]));
        return this.http.get(`${this.baseUrl}/${id}/loglist`, { params: httParams });
    }

    qryCaseLog(task_batch_id: string, parent_exec_id = '0', showSuc = true): Observable<Array<DetectCaseLog>> {
        let params = new HttpParams({ fromString: `task_batch_id=${task_batch_id}&&parent_exec_id=${parent_exec_id}` });
        if (!showSuc) {
            params = params.set('status', '11,12');
        }
        return this.http.get<Array<DetectCaseLog>>(`${this.baseUrl}/case/execute_results`, { params }).pipe(map<any, Array<DetectCaseLog>>(page => page.page_data || []));
    }

    qryCaseLogsByExecId(exec_id: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/case/execute_results/${exec_id}`);
    }

    qryVerify(exec_id: string): Observable<Array<StepVerifyResult>> {
        let params = new HttpParams({ fromString: 'page_no=1&page_size=10000' }).set('case_exec_id', `${exec_id}`);
        return this.http.get(`${this.baseUrl}/verify_results`, { params }).pipe(map<any, Array<StepVerifyResult>>(page => page.page_data || []));
    }

    qryCaseTime(id: number, params: { page_no?: any, page_size?: any, [key: string]: any }): Observable<any> {
        let httParams = new HttpParams();
        Object.keys(params).filter(key => isPresent(params[key])).forEach(key => httParams = httParams.set(key, params[key]));
        return this.http.get(`${this.baseUrl}/${id}/case_exec_qs`, { params: httParams });
    }

    qryPerfTask(ids: string, date: string): Observable<Array<DetectPerfTaskLog>> {
        let params = new HttpParams().set('task_ids', ids).set('compare_date', date);
        return this.http.get<Array<DetectPerfTaskLog>>(`${this.baseUrl}/performance_analyse`, { params }).pipe(map<any, Array<DetectPerfTaskLog>>(items => items || []));
    }

    qryPerfCase(tasks: string, cases: string, dates: string): Observable<Array<DetectPerfCaseLog>> {
        let params = new HttpParams().set('task_ids', tasks).set('case_ids', cases).set('compare_dates', dates);
        return this.http.get<Array<DetectPerfCaseLog>>(`${this.baseUrl}/compare_tendency`, { params }).pipe(map<any, Array<DetectPerfCaseLog>>(items => items || []));
    }


    qryDate(task_id: number, case_id: number): Observable<Array<any>> {
        let params = new HttpParams().set('task_id', `${task_id}`).set('case_id', `${case_id}`);
        return this.http.get(`${this.baseUrl}/available_date`, { params }).pipe(map<any, Array<any>>(items => items || []));
    }

    qryPerfAvgCase(start_time: string, end_time: string, tasks: string, cases: string): Observable<Array<DetectPerfAvgCase>> {
        let params = new HttpParams().set('task_ids', tasks).set('case_ids', cases).set('start_date', start_time).set('end_date', end_time);
        return this.http.get<Array<DetectPerfCaseLog>>(`${this.baseUrl}/compare_average`, { params }).pipe(map<any, Array<DetectPerfAvgCase>>(items => items || []));
    }


    qryLogs(exec_id: any, block = true): Observable<Array<any>> {
        let params = new HttpParams().set('case_exec_id', exec_id);
        let headers = new HttpHeaders();
        if (!block) {
            headers = headers.set(Headers.BLOCK, 'false');
            headers = headers.set(Headers.SHOW_ERROR, 'false');
        }
        return this.http.get(`${this.baseUrl}/case/oper_logs`, {
            params,
            headers
        }).pipe(map<any, Array<any>>(page => page.page_data || []));
    }

    qrySql(id: number): Observable<Array<TaskSql>> {
        return this.http.get(`${this.baseUrl}/${id}/sqls`).pipe(map<any, Array<TaskSql>>(items => items || []));
    }

    qryDBLog(task_batch_id: string, showSuc = true): Observable<Array<DetectDBLog>> {
        return this.http.get<Array<DetectCaseLog>>(`${this.baseUrl}/sqllogs/${task_batch_id}`).pipe(map<any, Array<DetectDBLog>>(items => items || []));
    }
}

