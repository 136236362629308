import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NodeType } from './node';

@Component({
    selector: 'node-type-select', //tslint:disable-line
    templateUrl: 'node.type.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NodeTypeComponent),
        multi: true
    }]
})

export class NodeTypeComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @Input() disabled: boolean;
    @Input() types: NodeType[];

    private onChange: (_: any) => void;

    private _type: NodeType;

    constructor(private cdRef: ChangeDetectorRef) {
    }

    get type(): NodeType {
        return this._type;
    }

    set type(value: NodeType) {
        if (this.type === value) {
            return;
        }
        this.onChange(value);
        this._type = value;
        this.cdRef.markForCheck();
    }

    ngOnInit() {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this._type = obj;
        this.cdRef.markForCheck();
    }

    ngOnDestroy() {
    }
}
