import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { format } from 'date-fns';
import { isPresent } from 'share';
import { Headers } from 'app/app.constants';
import { FileSection, UPLOAD_PATH, UploadProgress, UploadStatus } from './upload.model';


@Injectable()
export class UploadService {

    private baseUrl = '/xltp/file/fragment/upload';
    private originTime = 8 * 60 * 60 * 1000;

    progress: BehaviorSubject<UploadProgress> = new BehaviorSubject(null);
    filePath: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(private http: HttpClient, @Inject(UPLOAD_PATH) private path: string) {
    }

    load(file: File): void {
        this.progress.next(new UploadProgress(file));
    }

    upload(): void {
        const progress = this.progress.getValue();
        if (!isPresent(progress)) {
            return;
        }
        for (let i = 0; i < 5; i++) {
            this.dispatch(progress.next());
        }
    }

    dispatch(section: FileSection): void {
        if (!isPresent(section)) {
            return;
        }

        const file = this.progress.getValue().file;
        const headers = new HttpHeaders().set(Headers.BLOCK, 'false');
        const params = new FormData();
        params.append('file', section.data, `${file.name}`);
        params.append('index', `${section.index}`);
        params.append('path', this.path);
        params.append('total_size', `${section.total_size}`);

        section.start_time = Date.now();
        this.http.post(this.baseUrl, params, { headers }).subscribe((data: any) => {
            this.update(section);
            this.dispatch(this.progress.getValue().next());
            if (data.merge) {
                this.filePath.next(data.path);
            }
        });
    }

    update(section: FileSection): void {
        const progress = this.progress.getValue();
        progress.complete(section.index);

        const doneList = progress.sections.filter(sc => sc.status === UploadStatus.Done);
        const spendTime = doneList.reduce((total, sc) => sc.spendTime + total, 0);
        const speed = doneList.length * UploadProgress.SECTION_SIZE / spendTime;

        progress.percentage = Math.ceil(doneList.length / progress.sections.length * 100);

        if (speed > 1024 * 1024 / 1000) {
            const speed_MB = 1000 * speed / (1024 * 1024);
            progress.speed = `${speed_MB.toFixed(2)} MB/s`;
        } else {
            const speed_KB = 1000 * speed / 1024;
            progress.speed = `${speed_KB.toFixed(2)} KB/s`;
        }

        let remain = (progress.total_size - doneList.length * UploadProgress.SECTION_SIZE) / speed;
        remain = remain > 0 ? remain : 0;
        progress.remain_time = format(remain - this.originTime, 'HH:mm:ss');

        this.progress.next(progress);
    }
}
