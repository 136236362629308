import { NgComponentOutlet } from '@angular/common';
import {
    ChangeDetectorRef,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    DoCheck,
    Host,
    Input,
    KeyValueDiffer,
    KeyValueDiffers,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { isPresent } from '../facade';

@Directive({
    selector: '[ngComponentOutlet][inputs]' // tslint:disable-line
})
export class DynamicDirective implements OnChanges, DoCheck {

    @Input() inputs: { [k: string]: any };

    private _lastInstance: any = null;
    private inputsDiffer: KeyValueDiffer<string, any>;
    private componentFactory: ComponentFactory<any> | null = null;


    constructor(private differs: KeyValueDiffers,
                private resolver: ComponentFactoryResolver,
                @Host() private componentOutlet: NgComponentOutlet) {
        this.inputsDiffer = differs.find({}).create();
    }

    private get componentRef(): ComponentRef<any> {
        return this.componentOutlet ? (this.componentOutlet as any)._componentRef : null;
    }

    private get instance(): any {
        return this.componentRef ? this.componentRef.instance : null;
    }

    private get instanceChanged(): boolean {
        if (this._lastInstance !== this.instance) {
            this._lastInstance = this.instance;
            return true;
        } else {
            return false;
        }
    }

    ngDoCheck(): void {
        this.updateInputs();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateInputs();
    }

    private updateInputs(): void {
        if (this.instanceChanged) {
            this.resolveCompFactory();
        }
        if (!isPresent(this.componentFactory || !isPresent(this.instance))) {
            return;
        }
        let changes = this.inputsDiffer.diff(this.inputs);
        if (!this.instanceChanged && !changes) {
            return;
        }
        let inputs = this.resolveInputs();
        Object.keys(inputs).forEach(key => this.instance[key] = inputs[key]);
        let cdRef = this.componentRef.injector.get(ChangeDetectorRef);
        cdRef.markForCheck();
    }

    private resolveCompFactory() {
        try {
            this.componentFactory = this.resolver.resolveComponentFactory(this.componentRef.componentType);
        } catch (e) {
        }
    }

    private resolveInputs(): any {
        let inputObj = {};
        Object.keys(this.inputs).forEach(key => {
            let item = this.componentFactory.inputs.find(input => input.templateName === key);
            if (isPresent(item)) {
                inputObj[item.propName] = this.inputs[key];
            }
        });
        return inputObj;
    }
}
