import {
    AfterViewInit,
    ComponentFactory,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    NgZone,
    Renderer2,
    ViewContainerRef
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { getTextWidth } from '../facade';
import { TooltipBaseDirective, ToolTipComponent } from '../tooltip';
import { TableService } from './table.service';

@Directive({ selector: 'td:not([colSpan]):not([colspan]):not(.break):not([no-tooltip])' }) //tslint:disable-line
export class CellTooltipDirective extends TooltipBaseDirective implements AfterViewInit {

    componentFactory: ComponentFactory<ToolTipComponent> = this.resolver.resolveComponentFactory(ToolTipComponent);

    constructor(private zone: NgZone,
                private service: TableService,
                elementRef: ElementRef<HTMLElement>,
                hostView: ViewContainerRef,
                resolver: ComponentFactoryResolver,
                renderer: Renderer2) {
        super(elementRef, hostView, resolver, renderer);
    }

    ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => fromEvent(this.elementRef.nativeElement, 'mouseenter').pipe(takeUntil(this.destroy$)).subscribe(() => this.hover(true)));
        this.zone.runOutsideAngular(() => fromEvent(this.elementRef.nativeElement, 'mouseleave').pipe(takeUntil(this.destroy$)).subscribe(() => this.hover(false)));
    }

    hover(isEnter): void {
        if (this.skip()) {
            return;
        }
        let style = getComputedStyle(this.elementRef.nativeElement);
        let text = this.elementRef.nativeElement.innerText;
        let width = getTextWidth(text, style.font);
        let padding = (parseInt(style.paddingLeft, 10) || 0) + (parseInt(style.paddingRight, 10) || 0);
        if (width <= this.elementRef.nativeElement.offsetWidth - padding) {
            return;
        }
        this.specificTitle = text;
        this.trigger = 'hover';
        if (!this.component) {
            this.createComponent();
        }
        this.removeTriggerListeners();
        this.registerTriggers();
        // 根据时间淡入淡出
        let delayTimer = setTimeout(() => {
            delayTimer = undefined;
            if (isEnter) {
                this.show();
                this.updatePosition();
            } else {
                this.hide();
            }
        }, this.mouseEnterDelay * 1000);

        const dispose = () => {
            this.hide();
            clearTimeout(delayTimer)
        }
        this.service.add(dispose)
        this.visibleChange.asObservable().pipe(filter(v => !v)).subscribe(() => this.removeTriggerListeners());
    }

    private skip(): boolean {
        return !!this.elementRef.nativeElement.querySelector('select,div');
    }
}
