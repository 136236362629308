import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SimpleChange,
    SkipSelf,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { treeCollapseMotion } from '../animation';
import { InputBoolean } from '../facade';
import { TreeBase } from './tree-base';
import { TreeNode, TreeNodeKey, TreeNodeOptions } from './tree-base-node';
import { TreeBaseService } from './tree-base.service';
import { TreeHigherOrderServiceToken } from './tree-service.resolver';
import { flattenTreeData } from './tree-util';
import { FormatBeforeDropEvent, FormatEmitEvent } from './tree.definitions';
import { TreeService } from './tree.service';

export function TreeServiceFactory(higherOrderService: TreeBaseService, treeService: TreeService): TreeBaseService {
    return higherOrderService ? higherOrderService : treeService;
}

@Component({
    selector: 'ai-tree',
    exportAs: 'tree',
    animations: [treeCollapseMotion],
    templateUrl: 'tree.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        TreeService,
        {
            provide: TreeBaseService,
            useFactory: TreeServiceFactory,
            deps: [[new SkipSelf(), new Optional(), TreeHigherOrderServiceToken], TreeService]
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TreeComponent),
            multi: true
        }
    ],
    host: {
        '[class.select-tree]': `selectMode`,
        '[class.select-tree-show-line]': `selectMode && showLine`,
        '[class.select-tree-icon-hide]': `selectMode && !showIcon`,
        '[class.select-tree-block-node]': `selectMode && blockNode`,
        '[class.tree]': `!selectMode`,
        '[class.tree-show-line]': `!selectMode && showLine`,
        '[class.tree-icon-hide]': `!selectMode && !showIcon`,
        '[class.tree-block-node]': `!selectMode && blockNode`,
        '[class.draggable-tree]': `draggable`
    }
})
export class TreeComponent extends TreeBase implements OnInit, OnDestroy, ControlValueAccessor, OnChanges, AfterViewInit {

    @Input() @InputBoolean() showIcon: boolean = false;
    @Input() @InputBoolean() hideUnMatched: boolean = false;
    @Input() @InputBoolean() blockNode: boolean = false;
    @Input() @InputBoolean() expandAll = false;
    @Input() @InputBoolean() selectMode = false;
    @Input() @InputBoolean() checkStrictly = false;
    @Input() @InputBoolean() showExpand: boolean = true;
    @Input() @InputBoolean() showLine = false;
    @Input() @InputBoolean() checkable = false;
    @Input() @InputBoolean() asyncData = false;
    @Input() @InputBoolean() draggable: boolean = false;
    @Input() @InputBoolean() multiple = false;
    @Input() @InputBoolean() useVirtualScroll = false;

    @Input() expandedIcon?: TemplateRef<{ $implicit: TreeNode; origin: TreeNodeOptions }>;
    @Input() virtualItemSize = 28;
    @Input() virtualMaxBufferPx = 500;
    @Input() virtualMinBufferPx = this.virtualItemSize * 10;
    @Input() virtualHeight: string = null;
    @Input() treeTemplate?: TemplateRef<{ $implicit: TreeNode; origin: TreeNodeOptions }>;
    @Input() beforeDrop?: (confirm: FormatBeforeDropEvent) => Observable<boolean>;
    @Input() data: TreeNodeOptions[] | TreeNode[] = [];
    @Input() expandedKeys: TreeNodeKey[] = [];
    @Input() selectedKeys: TreeNodeKey[] = [];
    @Input() checkedKeys: TreeNodeKey[] = [];
    @Input() searchValue?: string;
    @Input() searchFunc?: (node: TreeNodeOptions, val?: string) => boolean;
    @ContentChild('treeTemplate', { static: true }) treeTemplateChild: TemplateRef<{ $implicit: TreeNode; origin: TreeNodeOptions }>;
    @ViewChild(CdkVirtualScrollViewport, { read: CdkVirtualScrollViewport }) cdkVirtualScrollViewport: CdkVirtualScrollViewport;

    @Output() readonly expandedKeysChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() readonly selectedKeysChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() readonly checkedKeysChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() readonly searchValueChange = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngClick = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngDblClick = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngContextMenu = new EventEmitter<FormatEmitEvent>();
    @Output() readonly checkBoxChange = new EventEmitter<FormatEmitEvent>();
    @Output() readonly expandChange = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngOnDragStart = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngOnDragEnter = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngOnDragOver = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngOnDragLeave = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngOnDrop = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngOnDragEnd = new EventEmitter<FormatEmitEvent>();

    flattenNodes: TreeNode[] = [];
    beforeInit = true;

    private destroy$ = new Subject();

    constructor(treeService: TreeBaseService, private cdRef: ChangeDetectorRef) {
        super(treeService);
    }

    ngOnInit(): void {
        this.treeService.flattenNodes$.pipe(takeUntil(this.destroy$)).subscribe(nodes => {
            this.flattenNodes = nodes.filter(node => !this.searchValue || !this.hideUnMatched || node.isMatched || node.isExpanded || !node.canHide);
            this.cdRef.markForCheck();
        });
    }

    get virtualScrollHeight(): number {
        const nodeHeight = this.virtualItemSize * this.flattenNodes.length;
        return Math.max(nodeHeight, this.virtualMinBufferPx);
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
        this.renderTreeProperties(changes);
    }

    ngAfterViewInit(): void {
        this.beforeInit = false;
    }

    writeValue(value: TreeNode[]): void {
        this.handleData(value);
    }

    registerOnChange(fn: (_: TreeNode[]) => void): void {
    }

    registerOnTouched(fn: () => void): void {
    }

    trackByFlattenNode(_: number, node: TreeNode): string {
        return node.key;
    }

    eventTriggerChanged(event: FormatEmitEvent): void {
        const node = event.node;
        switch (event.eventName) {
            case 'expand':
                this.renderTree();
                this.expandChange.emit(event);
                break;
            case 'click':
                this.ngClick.emit(event);
                break;
            case 'dblclick':
                this.ngDblClick.emit(event);
                break;
            case 'contextmenu':
                this.ngContextMenu.emit(event);
                break;
            case 'check':
                // Render checked state with nodes' property `isChecked`
                this.treeService.setCheckedNodeList(node);
                if (!this.checkStrictly) {
                    this.treeService.conduct(node);
                }
                // Cause check method will rerender list, so we need recover it and next the new event to user
                const eventNext = this.treeService.formatEvent('check', node, event.event);
                this.checkBoxChange.emit(eventNext);
                break;
            case 'dragstart':
                // if node is expanded
                if (node.isExpanded) {
                    node.setExpanded(!node.isExpanded);
                    this.renderTree();
                }
                this.ngOnDragStart.emit(event);
                break;
            case 'dragenter':
                const selectedNode = this.treeService.getSelectedNode();
                if (selectedNode && selectedNode.key !== node.key && !node.isExpanded && !node.isLeaf) {
                    node.setExpanded(true);
                    this.renderTree();
                }
                this.ngOnDragEnter.emit(event);
                break;
            case 'dragover':
                this.ngOnDragOver.emit(event);
                break;
            case 'dragleave':
                this.ngOnDragLeave.emit(event);
                break;
            case 'dragend':
                this.ngOnDragEnd.emit(event);
                break;
            case 'drop':
                this.renderTree();
                this.ngOnDrop.emit(event);
                break;
        }
    }

    private renderTreeProperties(changes: { [propertyName: string]: SimpleChange }): void {
        let useDefaultExpandedKeys = false;
        const { data, expandedKeys, selectedKeys, checkedKeys, checkStrictly, expandAll, multiple, searchValue, virtualMinBufferPx } = changes;

        let expandAllValue = false;
        if (expandAll) {
            useDefaultExpandedKeys = true;
            expandAllValue = this.expandAll;
        }

        if (multiple) {
            this.treeService.isMultiple = this.multiple;
        }

        if (checkStrictly) {
            this.treeService.isCheckStrictly = this.checkStrictly;
        }

        if (data) {
            this.handleData(this.data);
        }

        if (data || checkedKeys || checkStrictly) {
            this.handleCheckedKeys(this.checkedKeys);
        }

        if (expandedKeys || expandAll) {
            useDefaultExpandedKeys = true;
            this.handleExpandedKeys(expandAllValue || this.expandedKeys);
        }

        if (selectedKeys) {
            this.handleSelectedKeys(this.selectedKeys, this.multiple);
        }

        if (searchValue) {
            if (!searchValue.firstChange || this.searchValue) {
                useDefaultExpandedKeys = false;
                this.handleSearchValue(searchValue.currentValue, this.searchFunc);
                this.searchValueChange.emit(this.treeService.formatEvent('search', null, null));
            }
        }

        if (virtualMinBufferPx) {
            this.handleVirtualBuffer();
        }

        // flatten data
        const currentExpandedKeys = this.getExpandedNodeList().map(v => v.key);
        const newExpandedKeys = useDefaultExpandedKeys ? expandAllValue || this.expandedKeys : currentExpandedKeys;
        this.handleFlattenNodes(this.treeService.rootNodes, newExpandedKeys);
    }

    private handleData(value: any[]): void {
        if (!Array.isArray(value)) {
            return;
        }
        const data = this.coerceTreeNodes(value);
        this.handleVirtual(data);
        this.treeService.initTree(data);
    }

    private handleVirtual(data: TreeNode[]): void {
        if (!this.useVirtualScroll || this.virtualHeight != null) {
            return;
        }
        this.virtualHeight = flattenTreeData(data, true).length > 40 ? `${this.virtualItemSize * 10}px` : null;
    }

    private handleVirtualBuffer(): void {
        if (!this.virtualMinBufferPx) {
            return;
        }
        if (this.virtualMaxBufferPx < this.virtualMinBufferPx * 1.5) {
            this.virtualMaxBufferPx = this.virtualMinBufferPx * 1.5;
        }
    }

    private handleFlattenNodes(data: TreeNode[], expandKeys: TreeNodeKey[] | true = []): void {
        this.treeService.flattenTreeData(data, expandKeys);
    }

    private handleCheckedKeys(keys: TreeNodeKey[]): void {
        this.treeService.conductCheck(keys, this.checkStrictly);
    }

    private handleExpandedKeys(keys: TreeNodeKey[] | true = []): void {
        this.treeService.conductExpandedKeys(keys);
    }

    private handleSelectedKeys(keys: TreeNodeKey[], isMulti: boolean): void {
        this.treeService.conductSelectedKeys(keys, isMulti);
    }

    private handleSearchValue(value: string, searchFunc?: (node: TreeNodeOptions, val?: string) => boolean): void {
        const treeNodes = flattenTreeData(this.treeService.rootNodes, true).map(v => v.data);
        const checkIfMatched = (node: TreeNode): boolean => {
            if (searchFunc) {
                return searchFunc(node.origin, value);
            }
            return !value || node.title.toLowerCase().includes(value.toLowerCase());
        };
        treeNodes.forEach(node => {
            node.isMatched = checkIfMatched(node);
            node.canHide = !node.isMatched;
            if (!node.isMatched || !value) {
                node.setExpanded(false);
                this.treeService.updateExpandedNodes(node);
            } else {
                this.treeService.expandParentNode(node);
            }
        });
        this.treeService.setMatchedNodeList(treeNodes.filter(node => node.isMatched));
    }

    private renderTree(): void {
        this.handleFlattenNodes(this.treeService.rootNodes, this.getExpandedNodeList().map(v => v.key));
        this.cdRef.markForCheck();
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
