import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'ai-back',
    templateUrl: 'back.component.html',
    styleUrls: ['back.scss']
})
export class BackComponent implements OnInit, OnDestroy {

    constructor(private location: Location) {
    }

    ngOnInit() {
    }

    goBack(): void {
        this.location.back();
    }

    ngOnDestroy() {
    }
}
