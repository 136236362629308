import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ValueMode } from 'core';
import { isPresent } from 'share';

@Injectable()
export class UIPageStepHolder {

    script: BehaviorSubject<string> = new BehaviorSubject('{}');
    params: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

    updateScript(value: string = '{}'): void {
        this.script.next(value);
    }

    updateParams(value: Array<any>): void {
        this.params.next(value);
    }

    changeParams(value: Array<string> = []): void {
        const paramMap = new Map<string, any>();
        this.params.getValue().forEach(param => paramMap.set(param.param_name, param));

        const params: Array<any> = [];
        for(let name of value) {
            if (paramMap.has(name)) {
                const param = paramMap.get(name);
                param.operate_type = param.id >= 0 ? 'U' : 'C'
                params.push(param);
                paramMap.delete(name);
            } else {
                params.push({
                    param_name: name,
                    value_mode: ValueMode.FIXED,
                    direction: 'IN_OUT',
                    operate_type: 'C'
                });
            }
        }
        for (let param of paramMap.values()) {
            if (isPresent(param.id)) {
                param.operate_type = 'D';
                params.push(param);
            }
        }
        this.params.next(params);
    }
}