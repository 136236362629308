import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MenuModeType, MenuThemeType } from './menu.types';

@Injectable()
export class MenuService {

    descendantMenuItemClick$ = new Subject<any>();
    childMenuItemClick$ = new Subject<any>();
    theme$ = new BehaviorSubject<MenuThemeType>('light');
    mode$ = new BehaviorSubject<MenuModeType>('vertical');
    inlineIndent$ = new BehaviorSubject<number>(24);
    isChildSubMenuOpen$ = new BehaviorSubject<boolean>(false);

    onDescendantMenuItemClick(menu: any): void {
        this.descendantMenuItemClick$.next(menu);
    }

    onChildMenuItemClick(menu: any): void {
        this.childMenuItemClick$.next(menu);
    }

    setMode(mode: MenuModeType): void {
        this.mode$.next(mode);
    }

    setTheme(theme: MenuThemeType): void {
        this.theme$.next(theme);
    }

    setInlineIndent(indent: number): void {
        this.inlineIndent$.next(indent);
    }
}
