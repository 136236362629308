import { Injectable } from '@angular/core';
import { GlobalHolder } from 'app/global.holder';
import { ProductService } from 'app/product/product.service';
import { ProductUserService } from 'app/product/user/product.user.service';
import { UserService } from 'app/system/user/user.service';
import { ProductInitService } from 'core';
import { LocalStorageService } from 'ngx-webstorage';
import { EMPTY, Observable, of, Subject, zip } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductGlobalService extends ProductInitService {

    constructor(private storage: LocalStorageService,
                private sysUserService: UserService,
                private productService: ProductService,
                private userService: ProductUserService) {
        super();
    }

    initGlobal(id: number, global: GlobalHolder): Observable<any> {
        let subject = new Subject();
        let completed = false;
        this.productService.qryById(id).pipe(
            switchMap(item => {
                if (item?.status !== 1) {
                    global.updateProduct(null);
                    return EMPTY;
                }
                return of(item);
            }),
            tap(item => global.updateProduct(item)),
            switchMap(item => this.sysUserService.qryMenu(global.user_id, item.product_id)),
            tap(menus => {
                global.updateProMenus(menus);
                this.cacheData(id, global);
                completed = true;
            }),
            finalize(() => {
                subject.next(completed);
                subject.complete();
            })
        ).subscribe();
        return subject.asObservable();
    }

    private cacheData(id: number, global: GlobalHolder): void {
        zip(this.productService.qryEnv(id),
            this.productService.qryParams(id),
            this.userService.qryAll(id)
        ).subscribe(([environments, params, users]) => {
            global.updateEnvironments(environments);
            global.updateProductUser(users);
            global.updateParams(params);
        });
    }
}

