import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class TitleService {

    constructor(private title: Title) {
        this.setTitle(title.getTitle());
    }

    setTitle(title: string): void {
        this.title.setTitle(title);
        window.postMessage({ key: 'SET_APP_TITLE', data: title }, '*');
    }
}
