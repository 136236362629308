import { Component, Input, OnInit } from '@angular/core';
import { UIPageParams } from 'app/ui/page/ui.page.model';
import { DrawerRef } from 'share';

@Component({
    selector: 'ai-page-param',
    templateUrl: 'page.param.component.html'
})

export class PageParamComponent implements OnInit {

    type: number;

    @Input() items: Array<UIPageParams>;
    @Input() editable: boolean;

    constructor(public drawerRef: DrawerRef) {
        this.type = 1;
    }

    ngOnInit() {
    }

    get inParams(): Array<UIPageParams> {
        return this.items.filter(param => param.direction === 'IN' && param.operate_type !== 'D');
    }

    get outParams(): Array<UIPageParams> {
        return this.items.filter(param => param.direction === 'OUT' && param.operate_type !== 'D');
    }

    submit(): void {
        this.drawerRef.close();
    }
}
