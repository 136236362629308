import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BackModule } from '../back/back.module';
import { BackModule2 } from '../back_new/back.module';
import { DrawerModule } from '../drawer/drawer.module';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';
import { UploadModule } from '../upload/upload.module';
import { AceEditorDirective } from './editor.ace.directive';

import { RichEditorComponent } from './editor.rich.component';
import { EditorRichService } from './editor.rich.service';
import { HelpDialogComponent } from './help/help.component';
import { ImageDialogComponent } from './image/image.component';
import { LinkDialogComponent } from './link/link.component';
import { VideoDialogComponent } from './video/video.component';

@NgModule({
    imports: [
        CommonModule,
        DrawerModule,
        BackModule,
        BackModule2,
        ScrollbarModule,
        FormsModule,
        UploadModule
    ],
    exports: [
        RichEditorComponent,
        AceEditorDirective
    ],
    declarations: [
        RichEditorComponent,
        AceEditorDirective,
        HelpDialogComponent,
        LinkDialogComponent,
        ImageDialogComponent,
        VideoDialogComponent
    ],
    providers: [
        EditorRichService
    ]
})
export class EditorModule {
}
