import { Injectable } from '@angular/core';
import { Validatable } from './validate.processor';

@Injectable()
export class ValidateRegistry {

    private validateSet: Set<Validatable> = new Set<Validatable>();

    constructor() {
    }

    register(item: Validatable): void {
        this.validateSet.add(item);
    }

    remove(item: Validatable): void {
        this.validateSet.delete(item);
    }

    get validates(): Array<Validatable> {
        let items: Array<Validatable> = [];
        this.validateSet.forEach(item => items.push(item));
        return items;
    }

    validate(): void {
        this.validates.forEach(v => v.validate());
    }

}
