import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { TreeNode, TreeNodeOptions } from './tree-base-node';

@Component({
    selector: 'ai-tree-node-switcher',
    templateUrl: 'tree-node-switcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        '[class.select-tree-switcher]': 'selectMode',
        '[class.select-tree-switcher-noop]': 'selectMode && isLeaf',
        '[class.select-tree-switcher_open]': 'selectMode && isSwitcherOpen',
        '[class.select-tree-switcher_close]': 'selectMode && isSwitcherClose',
        '[class.tree-switcher]': '!selectMode',
        '[class.tree-switcher-noop]': '!selectMode && isLeaf',
        '[class.tree-switcher_open]': '!selectMode && isSwitcherOpen',
        '[class.tree-switcher_close]': '!selectMode && isSwitcherClose'
    }
})
export class TreeNodeSwitcherComponent {

    @Input() showExpand?: boolean;
    @Input() showLine?: boolean;
    @Input() expandedIcon?: TemplateRef<{ $implicit: TreeNode; origin: TreeNodeOptions }>;
    @Input() selectMode = false;
    @Input() context: TreeNode;
    @Input() isLeaf?: boolean;
    @Input() isLoading?: boolean;
    @Input() isExpanded?: boolean;

    get isShowLineIcon(): boolean {
        return !this.isLeaf && !!this.showLine;
    }

    get isShowSwitchIcon(): boolean {
        return !this.isLeaf && !this.showLine;
    }

    get isSwitcherOpen(): boolean {
        return !!this.isExpanded && !this.isLeaf;
    }

    get isSwitcherClose(): boolean {
        return !this.isExpanded && !this.isLeaf;
    }
}
