import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TrimValueAccessor } from './accessors';
import { InputCloseDirective } from './close.directive';
import { CommonErrorFormatter } from './errors/CommonErrorFormatter';
import { ERROR_FORMATTERS } from './errors/ErrorFormatter';
import { NgbDateErrorFormatter } from './errors/NgbDateErrorFormatter';
import { InputFocusDirective } from './focus.directive';
import { FormDirective } from './form.directive';
import { InputDirective } from './input.directive';
import { SubmitDirective } from './submit.directive';
import {
    DecimalValidator,
    DigitsValidator,
    EmailValidator,
    MaxlengthValidator,
    MaxValidator,
    MinValidator,
    MobileValidator,
    UrlValidator
} from './validator.directive';

const DIRECTIVES = [
    FormDirective,
    SubmitDirective,
    InputDirective,
    InputCloseDirective,
    InputFocusDirective,
    MobileValidator,
    EmailValidator,
    UrlValidator,
    MaxlengthValidator,
    TrimValueAccessor,
    DigitsValidator,
    DecimalValidator,
    MinValidator,
    MaxValidator
];

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ...DIRECTIVES
    ],
    exports: [
        ...DIRECTIVES
    ],
    providers: [
        { provide: ERROR_FORMATTERS, useClass: CommonErrorFormatter, multi: true },
        { provide: ERROR_FORMATTERS, useClass: NgbDateErrorFormatter, multi: true }
    ]
})
export class FormModule {
}
