import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '../form/form.module';
import { AiOverlayModule } from '../overlay/overlay.module';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';
import { SelectModule } from '../select/select.module';

import { SearchComponent } from './search.component';
import { SearchMoreComponent } from './search.more.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormModule,
        ScrollbarModule,
        OverlayModule,
        AiOverlayModule,
        SelectModule
    ],
    exports: [
        SearchComponent,
        SearchMoreComponent
    ],
    declarations: [
        SearchComponent,
        SearchMoreComponent
    ]
})
export class SearchModule {
}
