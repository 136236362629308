import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OutletModule } from '../outlet/outlet.module';
import { AiOverlayModule } from '../overlay/overlay.module';
import { ToolTipModule } from '../tooltip';

import { PopoverComponent, PopoverDirective } from './popover';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        ToolTipModule,
        AiOverlayModule,
        OutletModule
    ],
    exports: [
        PopoverDirective,
        PopoverComponent
    ],
    entryComponents: [
        PopoverComponent
    ],
    declarations: [
        PopoverDirective,
        PopoverComponent
    ]
})
export class PopoverModule {
}
