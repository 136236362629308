import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DropdownMenuComponent } from './dropdown-menu.component';
import { DropDownDirective } from './dropdown.directive';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        FormsModule,
        PlatformModule
    ],
    exports: [
        DropDownDirective,
        DropdownMenuComponent
    ],
    declarations: [
        DropDownDirective,
        DropdownMenuComponent
    ],
    entryComponents: [
        DropdownMenuComponent
    ]
})
export class DropDownModule {
}
