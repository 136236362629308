import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'right-bottom-btn', // tslint:disable-line
    templateUrl: 'right.bottom.button.component.html',
    styleUrls: ['right.bottom.button.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class RightBottomButtonComponent implements OnInit {

    @Input() right: number;
    @Input() bottom: number;
    @Input() icon: string;

    constructor() {
    }

    ngOnInit() {
    }

    @HostBinding('style.right')
    get rightStyle(): string {
        return `${this.right}rem`;
    }

    @HostBinding('style.bottom')
    get bottomStyle(): string {
        return `${this.bottom}rem`;
    }
}