import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { EMPTY_FUNCTION, isPresent, valueFunctionProp } from '../../facade';

import { AbstractTable } from '../abstract-table';
import { DateFnsCalendar } from '../calendar';
import { DateHelperService } from '../datepicker-helper';
import { DatepickerI18n } from '../datepicker-i18n';
import { DateBodyRow, DateCell, DayCell } from '../interface';

@Component({
    selector: 'date-table',// tslint:disable-line
    exportAs: 'dateTable',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../abstract-table.html'
})
export class DateTableComponent extends AbstractTable implements OnChanges, OnInit {

    @Input() selectedValue: DateFnsCalendar[]; // Range ONLY
    @Input() hoverValue: DateFnsCalendar[]; // Range ONLY

    @Output() readonly dayHover = new EventEmitter<DateFnsCalendar>(); // Emitted when hover on a day by mouse enter

    constructor(private i18n: DatepickerI18n, private dateHelper: DateHelperService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        if (
            this.isDateRealChange(changes.activeDate) ||
            this.isDateRealChange(changes.value) ||
            this.isDateRealChange(changes.selectedValue) ||
            this.isDateRealChange(changes.hoverValue)
        ) {
            this.render();
        }
    }


    trackByBodyRow(_index: number, item: DateBodyRow): string {
        return `${item.year}-${item.weekNum}`;
    }

    trackByBodyColumn(_index: number, item: DateCell): string {
        return item.title as string;
    }

    makeHeadRow(): DayCell[] {
        const weekDays: DayCell[] = [];
        const start = this.activeDate.calendarStart();
        for (let colIndex = 0; colIndex < this.MAX_COL; colIndex++) {
            const day = start.addDays(colIndex);
            weekDays.push({
                value: day.nativeDate,
                title: this.dateHelper.format(day.nativeDate, 'E'), // eg. Tue
                content: this.dateHelper.format(day.nativeDate, this.i18n.getWeekFormat()), // eg. Tu,
                isSelected: false,
                isDisabled: false,
                onClick: EMPTY_FUNCTION,
                onMouseEnter: EMPTY_FUNCTION
            });
        }
        return weekDays;
    }

    makeBodyRows(): DateBodyRow[] {
        const weekRows: DateBodyRow[] = [];
        const firstDayOfMonth = this.activeDate.calendarStart();

        for (let week = 0; week < this.MAX_ROW; week++) {
            const weekStart = firstDayOfMonth.addDays(week * 7);
            const row: DateBodyRow = {
                isActive: false,
                isCurrent: false,
                dateCells: [],
                year: weekStart.getYear()
            };

            for (let day = 0; day < 7; day++) {
                const date = weekStart.addDays(day);
                const label = this.dateHelper.format(date.nativeDate, 'dd');

                const cell: DayCell = {
                    value: date.nativeDate,
                    label,
                    isSelected: false,
                    isDisabled: false,
                    isToday: false,
                    cellRender: valueFunctionProp(this.cellRender, date), // Customized content
                    fullCellRender: valueFunctionProp(this.fullCellRender, date),
                    content: `${date.getDate()}`,
                    onClick: () => this.changeValueFromInside(cell, date),
                    onMouseEnter: () => this.dayHover.emit(date)
                };

                if (this.showWeek && !row.weekNum) {
                    row.weekNum = this.dateHelper.getISOWeek(date.nativeDate);
                }

                if (date.isToday()) {
                    cell.isToday = true;
                    row.isCurrent = true;
                }

                if (
                    ((Array.isArray(this.selectedValue) && this.selectedValue.length > 0) || (this.hoverValue && this.hoverValue.length > 0)) &&
                    date.isSameMonth(this.activeDate)
                ) {
                    const [startHover, endHover] = this.hoverValue;
                    const [startSelected, endSelected] = this.selectedValue;

                    // Selected
                    if (startSelected && startSelected.isSameDay(date)) {
                        cell.isSelectedStartDate = true;
                        cell.isSelected = true;
                        row.isActive = true;
                    }
                    if (endSelected && endSelected.isSameDay(date)) {
                        cell.isSelectedEndDate = true;
                        cell.isSelected = true;
                        row.isActive = true;
                    } else if (date.isAfterDay(startSelected) && date.isBeforeDay(endSelected)) {
                        cell.isInSelectedRange = true;
                    }

                    if (startHover && endHover) {
                        // Hover
                        if (startHover.isSameDay(date)) {
                            cell.isHoverStartDate = true;
                        }
                        if (endHover.isSameDay(date)) {
                            cell.isHoverEndDate = true;
                        }
                        if (date.isLastDayOfMonth()) {
                            cell.isLastDayOfMonth = true;
                        }
                        if (date.isFirstDayOfMonth()) {
                            cell.isFirstDayOfMonth = true;
                        }
                    }

                    if (startSelected && !endSelected) {
                        cell.isStartSingle = true;
                    }

                    if (!startSelected && endSelected) {
                        cell.isEndSingle = true;
                    }

                    if (date.isAfterDay(startHover) && date.isBeforeDay(endHover)) {
                        cell.isInHoverRange = true;
                    }
                } else if (date.isSameDay(this.value)) {
                    cell.isSelected = true;
                    row.isActive = true;
                }

                if (this.disabledDate?.(date.nativeDate)) {
                    cell.isDisabled = true;
                }

                cell.classMap = this.getClassMap(cell);

                row.dateCells.push(cell);
            }

            row.classMap = {
                [`week-panel-row`]: this.showWeek,
                [`week-panel-row-selected`]: this.showWeek && row.isActive
            };

            weekRows.push(row);
        }

        return weekRows;
    }

    private isDateRealChange(change: SimpleChange): boolean {
        if (!change) {
            return false;
        }
        const previousValue: DateFnsCalendar | DateFnsCalendar[] = change.previousValue;
        const currentValue: DateFnsCalendar | DateFnsCalendar[] = change.currentValue;
        if (Array.isArray(currentValue)) {
            return (
                !Array.isArray(previousValue) ||
                currentValue.length !== previousValue.length ||
                currentValue.some((value, index) => !previousValue[index]?.isSameDay(value))
            );
        }
        return (!isPresent(previousValue) && isPresent(currentValue)) || !(previousValue as DateFnsCalendar)?.isSameDay(currentValue);
    }

    private changeValueFromInside(cell: DayCell, value: DateFnsCalendar): void {
        if (cell.isDisabled) {
            return;
        }
        this.activeDate = this.activeDate.setYear(value.getYear()).setMonth(value.getMonth()).setDate(value.getDate());
        this.valueChange.emit(this.activeDate);

        if (!this.activeDate.isSameMonth(this.value)) {
            this.render();
        }
    }

    private getClassMap(cell: DayCell): { [key: string]: boolean } {
        const date = new DateFnsCalendar(cell.value);
        return {
            [`picker-cell`]: true,
            [`picker-cell-today`]: !!cell.isToday,
            [`picker-cell-in-view`]: date.isSameMonth(this.activeDate),
            [`picker-cell-selected`]: cell.isSelected,
            [`picker-cell-disabled`]: cell.isDisabled,
            [`picker-cell-in-range`]: !!cell.isInSelectedRange,
            [`picker-cell-range-start`]: !!cell.isSelectedStartDate,
            [`picker-cell-range-end`]: !!cell.isSelectedEndDate,
            [`picker-cell-range-start-single`]: !!cell.isStartSingle,
            [`picker-cell-range-end-single`]: !!cell.isEndSingle,
            [`picker-cell-range-hover`]: !!cell.isInHoverRange,
            [`picker-cell-range-hover-start`]: !!cell.isHoverStartDate,
            [`picker-cell-range-hover-end`]: !!cell.isHoverEndDate,
            [`picker-cell-range-hover-edge-start`]: !!cell.isFirstDayOfMonth,
            [`picker-cell-range-hover-edge-end`]: !!cell.isLastDayOfMonth
        };
    }
}
