import { InjectionToken } from '@angular/core';

export const ERROR_FORMATTERS = new InjectionToken<ErrorFormatter[]>('ERROR_FORMATTERS');

export interface ErrorFormatter {

    support(key: string): boolean;

    format(key: string, value: string): string;
}
