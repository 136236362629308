export interface UIPage {
    component_id?: number;
    component_name?: string;
    edit_type?: string;
    component_version?: string;
    script?: string;
    script_type?: string;
    comment?: string;
    remark?: string;
    fun_id?: number;
    group_name?: string;
    product_id?: number;
    creator_user?: number;
    creator_user_name?: string;
    creator_time?: string;
    update_user?: number;
    update_user_name?: string;
    update_time?: string;
    version_remark?: string;

    opened?: boolean;
    init?: boolean;
    hierarchy?: number;
    step_max_id?: number;
    steps?: Array<UIPageStep>;
    children?: Array<UIPageStep>;
    component_params?: Array<UIPageParams>;
}

export interface UIPageStep {
    id?: number;
    step_index?: number;
    operate?: string;
    target?: string;
    paths?: Array<string>;
    value?: string;
    display?: string;
    frame_path?: string;
    comment?: string;
    remark?: string;
    parent_id?: number;

    default?: string; // 默认值
    editable?: boolean;
    hierarchy?: number;
}

export interface UIPageParams {
    param_id?: number;
    param_name?: string;
    param_value?: string;
    param_type?: string;
    component_id?: number;
    comment?: string;
    direction?: string;

    operate_type: string;
}

export class PageScriptType {
    static readonly PYTHON = 'PYTHON';
    static readonly RUBY = 'RUBY';
}

export const SCRIPT_TYPES: Array<{ id: string, name: string }> = [
    { id: PageScriptType.PYTHON, name: PageScriptType.PYTHON },
    { id: PageScriptType.RUBY, name: PageScriptType.RUBY }
];

export interface PageVersion {
    version_id?: number;
    component_id?: number;
    component_name?: string;
    script_type?: string;
    version?: string;
    script?: string;
    creator_time?: string;
    creator_user?: number;
    creator_user_name?: string;
    version_remark?: string;
}


