import { Pipe, PipeTransform } from '@angular/core';

enum ParamType {
    host = '主机',
    db = '数据库',
    charset = '字符集',
    utimeout = '界面超时时间',
    itimeout = '接口超时时间',
    global = '全局变量',
    csfHost = 'csf主机',
    redis = 'Redis',
}

@Pipe({
    name: 'paramType'
})
export class ParamTypePipe implements PipeTransform {
    transform(value: any): any {
        return ParamType[value] || '';
    }
}

@Pipe({
    name: 'globalValue'
})

export class GlobalValuePipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        try {
            value = JSON.parse(value);
            if (value.type === 'fixed') {
                return value.fixedValue;
            } else if (value.type === 'sql') {
                return `${value.dbName} | ${value.sqlValue}`;
            } else if (value.type === 'interface') {
                return `${value.interfaceName} | ${value.respParam}`;
            }
            return value.type;
        } catch (e) {
        }
        return '';
    }
}
