import { Menu } from 'app/system/menu/menu';
import { isPresent } from 'share';

export function treeMenu(items: Array<Menu>): Array<Menu> {
    let array: Array<Menu> = [];
    let menuMap = new Map<number, Menu>();
    items.sort((a, b) => a.order - b.order);
    items.forEach(menu => menuMap.set(menu.menu_id, new Menu(menu)));
    menuMap.forEach(menu => {
        let parent = menuMap.get(menu.parent_id);
        if (isPresent(parent)) {
            parent.addChild(menu);
        } else {
            array.push(menu);
        }
    });
    return array;
}
