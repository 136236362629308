import { Component, OnInit } from '@angular/core';
import { CrumbModel } from './crumb.model';
import { Router } from '@angular/router';
import { CrumbHolder } from './crumb.holder';

@Component({
    selector: 'ai-crumb',
    templateUrl: './crumb.component.html',
    styleUrls: ['crumb.component.scss']
})
export class CrumbComponent implements OnInit {

    crumbList: CrumbModel[];

    constructor(private holder: CrumbHolder) {
    }

    ngOnInit(): void {
        this.crumbList = [...this.holder.crumbList.getValue()] || [];
    }

    handleClick(item) {
        this.holder.updateCurrentCrumb(item);
    }

    ifCurrent(item) {
        return item === this.crumbList.last();
    }
}
