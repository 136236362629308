import { PAGE_SIZE } from 'core';
import { PaginationConfig } from 'share';

export function initPage(config: PaginationConfig) {
    return () => {
        config.maxSize = 5;
        config.rotate = true;
        config.disabled = false;
        config.boundaryLinks = false;
        config.directionLinks = true;
        config.ellipses = true;
        config.pageSize = PAGE_SIZE;
        config.size = 'sm';
        config.showQuickJumper = true;
        config.showSizeChanger = true;
        config.showTotal = true;
        config.align = 'right';
    };
}
