import { CdkOverlayOrigin, ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { combineLatest, merge, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { InputBoolean } from '../facade/convert';
import { CONNECTION_POSITIONS, getPlacementName } from '../overlay/overlay-position';
import { MenuItemDirective } from './menu-item.directive';
import { MenuService } from './menu.service';
import { IsMenuInsideDropDownToken } from './menu.token';
import { MenuModeType, MenuThemeType } from './menu.types';
import { SubmenuService } from './submenu.service';

const verticalPositions = [
    CONNECTION_POSITIONS.rightTop,
    CONNECTION_POSITIONS.right,
    CONNECTION_POSITIONS.rightBottom,
    CONNECTION_POSITIONS.leftTop,
    CONNECTION_POSITIONS.left,
    CONNECTION_POSITIONS.leftBottom
];
const horizontalPositions = [
    CONNECTION_POSITIONS.bottomLeft
];

@Component({
    selector: '[ai-submenu]',//tslint:disable-line
    exportAs: 'submenu',
    providers: [SubmenuService],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    templateUrl: 'submenu.component.html',
    host: {
        '[class.ai-dropdown-menu-submenu]': `isMenuInsideDropDown`,
        '[class.ai-dropdown-menu-submenu-disabled]': `isMenuInsideDropDown && disabled`,
        '[class.ai-dropdown-menu-submenu-open]': `isMenuInsideDropDown && open`,
        '[class.ai-dropdown-menu-submenu-selected]': `isMenuInsideDropDown && isSelected`,
        '[class.ai-dropdown-menu-submenu-vertical]': `isMenuInsideDropDown && mode === 'vertical'`,
        '[class.ai-dropdown-menu-submenu-horizontal]': `isMenuInsideDropDown && mode === 'horizontal'`,
        '[class.ai-dropdown-menu-submenu-inline]': `isMenuInsideDropDown && mode === 'inline'`,
        '[class.ai-dropdown-menu-submenu-active]': `isMenuInsideDropDown && isActive`,
        '[class.ai-menu-submenu]': `!isMenuInsideDropDown`,
        '[class.ai-menu-submenu-disabled]': `!isMenuInsideDropDown && disabled`,
        '[class.ai-menu-submenu-open]': `!isMenuInsideDropDown && open`,
        '[class.ai-menu-submenu-selected]': `!isMenuInsideDropDown && isSelected`,
        '[class.ai-menu-submenu-vertical]': `!isMenuInsideDropDown && mode === 'vertical'`,
        '[class.ai-menu-submenu-horizontal]': `!isMenuInsideDropDown && mode === 'horizontal'`,
        '[class.ai-menu-submenu-inline]': `!isMenuInsideDropDown && mode === 'inline'`,
        '[class.ai-menu-submenu-active]': `!isMenuInsideDropDown && isActive`
    }
})
export class SubMenuComponent implements OnInit, OnDestroy, AfterContentInit, OnChanges {

    @Input() menuClassName: string = '';
    @Input() paddingLeft: number | null = null;
    @Input() title: string | TemplateRef<void> | null = null;
    @Input() icon: string | null = null;
    @Input() @InputBoolean() open = false;
    @Input() @InputBoolean() disabled = false;
    @Output() readonly openChange: EventEmitter<boolean> = new EventEmitter();

    @ViewChild(CdkOverlayOrigin, { static: true, read: ElementRef })
    cdkOverlayOrigin: ElementRef | null = null;

    @ContentChildren(SubMenuComponent, { descendants: true })
    subMenuComponents: QueryList<SubMenuComponent> | null = null;

    @ContentChildren(MenuItemDirective, { descendants: true })
    menuItemDirectives: QueryList<MenuItemDirective> | null = null;

    position = 'right';
    triggerWidth: number | null = null;
    theme: MenuThemeType = 'light';
    mode: MenuModeType = 'vertical';
    inlinePaddingLeft: number | null = null;
    overlayPositions = verticalPositions;
    isSelected = false;
    isActive = false;

    private level = this.submenuService.level;
    private destroy$ = new Subject<void>();

    constructor(
        public menuService: MenuService,
        private cdr: ChangeDetectorRef,
        public submenuService: SubmenuService,
        private platform: Platform,
        @Inject(IsMenuInsideDropDownToken) public isMenuInsideDropDown: boolean
    ) {
    }

    setOpenStateWithoutDebounce(open: boolean): void {
        this.submenuService.setOpenStateWithoutDebounce(open);
    }

    toggleSubMenu(): void {
        this.setOpenStateWithoutDebounce(!this.open);
    }

    setMouseEnterState(value: boolean): void {
        this.isActive = value;
        if (this.mode !== 'inline') {
            this.submenuService.setMouseEnterTitleOrOverlayState(value);
        }
    }

    setTriggerWidth(): void {
        if (this.mode === 'horizontal' && this.platform.isBrowser && this.cdkOverlayOrigin) {
            // TODO: fast dom
            this.triggerWidth = this.cdkOverlayOrigin.nativeElement.getBoundingClientRect().width;
        }
    }

    onPositionChange(position: ConnectedOverlayPositionChange): void {
        const placement = getPlacementName(position);
        if (placement === 'rightTop' || placement === 'rightBottom' || placement === 'right') {
            this.position = 'right';
        } else if (placement === 'leftTop' || placement === 'leftBottom' || placement === 'left') {
            this.position = 'left';
        }
        this.cdr.markForCheck();
    }


    ngOnInit(): void {
        this.menuService.theme$.pipe(takeUntil(this.destroy$)).subscribe(theme => {
            this.theme = theme;
            this.cdr.markForCheck();
        });
        this.submenuService.mode$.pipe(takeUntil(this.destroy$)).subscribe(mode => {
            this.mode = mode;
            if (mode === 'horizontal') {
                this.overlayPositions = horizontalPositions;
            } else if (mode === 'vertical') {
                this.overlayPositions = verticalPositions;
            }
            this.cdr.markForCheck();
        });
        combineLatest([this.submenuService.mode$, this.menuService.inlineIndent$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([mode, inlineIndent]) => {
                this.inlinePaddingLeft = mode === 'inline' ? this.level * inlineIndent : null;
                this.cdr.markForCheck();
            });
        this.submenuService.isCurrentSubMenuOpen$.pipe(takeUntil(this.destroy$)).subscribe(open => {
            this.isActive = open;
            if (open !== this.open) {
                this.setTriggerWidth();
                this.open = open;
                this.openChange.emit(this.open);
                this.cdr.markForCheck();
            }
        });
    }

    ngAfterContentInit(): void {
        this.setTriggerWidth();
        const menuItemDirectives = this.menuItemDirectives;
        const changes = menuItemDirectives.changes;
        const mergedObservable = merge(...[changes, ...menuItemDirectives.map(menu => menu.selected$)]);
        changes
            .pipe(
                startWith(menuItemDirectives),
                switchMap(() => mergedObservable),
                startWith(true),
                map(() => menuItemDirectives.some(e => e.selected)),
                takeUntil(this.destroy$)
            )
            .subscribe(selected => {
                this.isSelected = selected;
                this.cdr.markForCheck();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { open } = changes;
        if (open) {
            this.submenuService.setOpenStateWithoutDebounce(this.open);
            this.setTriggerWidth();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
