import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBoolean, isPresent, TreeNodeOptions } from 'share';
import { Renderable } from 'core';
import { OPERATE_MAP, UIStep } from 'app/ui/page/step/ui.page.step.model';


@Component({
    selector: 'ai-page-step-operate',
    templateUrl: 'ui.page.step.operate.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => UIPageStepOperateComponent),
        multi: true
    }]
})
export class UIPageStepOperateComponent implements OnInit, ControlValueAccessor, Renderable, OnDestroy {

    @Input() step: UIStep;
    @Input() placeholder: string;
    @Input() @InputBoolean() editable: boolean;
    @Input() @InputBoolean() allowClear: boolean;
    @Input() @InputBoolean() isSupportComp: boolean;

    @Output() toggle = new EventEmitter();

    value: string;
    groups: Array<TreeNodeOptions> = [];

    private onChange: (_: any) => void;

    constructor(private cdRef: ChangeDetectorRef) {
        this.placeholder = '';
    }

    ngOnInit() {
        const groupMap = new Map<string, any>();
        for (let [key, val] of OPERATE_MAP.entries()) {
            if (!this.isSupportComp && ['store component param', 'component'].includes(key)) {
                continue;
            }
            if (!groupMap.has(val.group)) {
                groupMap.set(val.group, {
                    key: val.group,
                    title: val.group,
                    children: [],
                    selectable: false
                });
            }
            groupMap.get(val.group).children.push({
                key,
                title: key,
                data: val,
                children: [],
                selectable: true,
                isLeaf: true
            });
        }
        this.groups = [...groupMap.values()];
        this.render();
    }

    get left(): string {
        if (!this.step || !this.step.hierarchy) {
            return '';
        }
        return `${this.step.hierarchy}rem`;
    }

    render(): void {
        this.cdRef.markForCheck();
    }

    toggleSwitch(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        this.toggle.emit();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.value = isPresent(obj) ? obj : '';
        this.render();
    }

    change(): void {
        this.onChange(this.value);
    }

    ngOnDestroy() {
    }
}
