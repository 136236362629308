import { Injectable } from '@angular/core';
import { GlobalHolder } from 'app/global.holder';
import { Observable, of } from 'rxjs';
import { SubmitProcessor } from './submit.processor';

@Injectable()
export class GlobalProductSubmit implements SubmitProcessor {

    constructor(private global: GlobalHolder) {
    }

    postProcess(item: { product_id?: number }): Observable<any> {
        item.product_id = this.global.product.getValue().product_id;
        return of(item);
    }

}
