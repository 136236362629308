import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ai-option-item-group',
    template: `
        <ng-container *stringTemplateOutlet="label">{{ label }}</ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.select-item]': 'true',
        '[class.select-item-group]': 'true'
    }
})
export class OptionItemGroupComponent {

    @Input() label: string | TemplateRef<any> | null = null;
}
