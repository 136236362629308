import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isPresent } from '../facade';

@Component({
    selector: 'vertical-resize', // tslint:disable-line
    templateUrl: 'vertical.resize.component.html',
    styleUrls: ['vertical.resize.scss']
})

export class VerticalResizeComponent implements AfterViewInit, OnInit, OnDestroy {

    private _le: HTMLElement;
    private _re: HTMLElement;

    private currentX = 0;
    private initWidth = 0;
    private minWidth = 5;

    private _destroy$ = new Subject();
    private _moveUp$ = new Subject();
    private _changeL$ = new Subject();
    private _changeR$ = new Subject();

    constructor(private element: ElementRef) {
    }

    ngOnInit() {
    }

    @Input()
    set le(value: HTMLElement) {
        if (this._le === value) {
            return;
        }
        this._changeL$.next(undefined);
        this._le = value;
        fromEvent<MouseEvent>(this._le, 'mouseup')
            .pipe(takeUntil(merge(this._changeL$, this._destroy$)))
            .subscribe(() => this.mouseUp());
    }

    @Input()
    set re(value: HTMLElement) {
        if (this._re === value) {
            return;
        }
        this._changeR$.next(undefined);
        this._re = value;
        fromEvent<MouseEvent>(this._re, 'mouseup')
            .pipe(takeUntil(merge(this._changeR$, this._destroy$)))
            .subscribe(() => this.mouseUp());
    }

    ngAfterViewInit(): void {
        let element: HTMLElement = this.element.nativeElement;
        fromEvent<MouseEvent>(this.element.nativeElement, 'mousedown').pipe(takeUntil(this._destroy$)).subscribe(evt => this.mouseDown(evt));
        fromEvent<MouseEvent>(this.element.nativeElement, 'mouseup').pipe(takeUntil(this._destroy$)).subscribe(() => this.mouseUp());
        fromEvent<MouseEvent>(this.element.nativeElement, 'dblclick').pipe(takeUntil(this._destroy$)).subscribe(() => this.doubleClick());

        this.le = this._le || element.previousElementSibling as HTMLElement;
        this.re = this._re || element.nextElementSibling as HTMLElement;

        setTimeout(() => this.initWidth = this.width(this._le), 300);
    }

    mouseDown(event: MouseEvent): void {
        this.currentX = event.pageX;
        this.monitorMove(this.element.nativeElement);
        this.monitorMove(this._le);
        this.monitorMove(this._re);
    }

    private monitorMove(el: HTMLElement): void {
        fromEvent<MouseEvent>(el, 'mousemove').pipe(takeUntil(merge(this._destroy$, this._moveUp$))).subscribe(evt => this.mouseMove(evt));
    }

    mouseMove(event: MouseEvent): void {
        let x = event.pageX;
        let width = this.width(this._le) + x - this.currentX;
        if (width < this.minWidth) {
            width = this.minWidth;
        }
        let maxWidth = this.width(this._le) + this.width(this._re);
        if (width > maxWidth) {
            width = maxWidth;
        }
        this.width(this._le, width);
        this.currentX = x;
    }

    doubleClick(): void {
        let width = this.width(this._le);
        if (width <= this.minWidth || width > this.initWidth) {
            this.width(this._le, this.initWidth);
        } else {
            this.width(this._le, this.minWidth);
        }
    }

    mouseUp(): void {
        this._moveUp$.next(undefined);
    }

    private width(el: HTMLElement, val?: number): number {
        if (!isPresent(val)) {
            return el.getBoundingClientRect().width;
        }
        el.style.width = `${val}px`;
    }

    ngOnDestroy(): void {
        this._destroy$.next(undefined);
    }
}
