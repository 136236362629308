import { Node } from './node';
import { NodeFormatter } from './node.formatter';

export class TextFormatter implements NodeFormatter {

    parse(text: string, node: Node): void {
        node.value = text;
    }

    stringify(node: Node): string {
        return node.value;
    }
}
