import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'ai-edit-help',
    templateUrl: 'help.component.html'
})
export class HelpDialogComponent implements OnInit, OnDestroy {

    @Input() context: any;
    keyMap: any;

    constructor() {
    }

    ngOnInit(): void {
        this.keyMap = this.context.options.keyMap[navigator.appVersion.includes('Mac') ? 'mac' : 'pc'];
    }

    ngOnDestroy(): void {
    }
}
