import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import { isPresent } from 'share';

hljs.registerLanguage('java', java);

@Component({
    selector: 'ai-highlight-code',
    templateUrl: 'highlight.code.component.html',
    styleUrls: ['highlight.code.scss']
})

export class HighlightCodeComponent implements OnInit, OnDestroy {

    highlightedCode: string;

    private _code: string;
    private _language: string;

    constructor() {
    }

    ngOnInit() {
    }

    @Input()
    set language(value: string) {
        this._language = value;
        this.highlight();
    }

    @Input()
    set code(value: string) {
        this._code = value;
        this.highlight();
    }

    private highlight(): void {
        if (!isPresent(this._code)) {
            return;
        }
        this.highlightedCode = hljs.highlight(this._code, { language: this._language }).value;
    }


    ngOnDestroy() {
    }
}
