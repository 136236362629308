import { Injectable } from '@angular/core';
import { DrawerService } from '../drawer';
import { ImageViewComponent } from './image.view.component';

@Injectable()
export class ImageViewService {

    constructor(private drawer: DrawerService) {
    }

    open(images: Array<string>, index = 0): void {
        let drawerRef = this.drawer.open(ImageViewComponent, { drawerClass: 'image-modal' });
        drawerRef.inputs.index = index;
        drawerRef.inputs.images = images;
    }
}
