import { Colors, TagColorService } from 'share';

export function initTag(colorService: TagColorService) {
    return () => {

        colorService.register('成功', Colors.SUCCESS);
        colorService.register('失败', Colors.DANGER);

        colorService.register('可用', Colors.PRIMARY);
        colorService.register('不可用', Colors.GRAY);

        colorService.register('有效', Colors.PRIMARY);
        colorService.register('无效', Colors.GRAY);

        colorService.register('正在巡测', Colors.PRIMARY);
        colorService.register('暂停巡测', Colors.GRAY);

        colorService.register('已关联', Colors.PRIMARY);
        colorService.register('未关联', Colors.GRAY);

        colorService.register('上线', Colors.PRIMARY);
        colorService.register('下线', Colors.DANGER);

        colorService.register('已激活', Colors.PRIMARY);
        colorService.register('已冻结', Colors.RED);
        colorService.register('重新加载', Colors.WARNING);

        colorService.register('已登录', Colors.PRIMARY);
        colorService.register('未登录', Colors.DANGER);

        colorService.register('启用', Colors.PRIMARY);
        colorService.register('停用', Colors.DANGER);

        colorService.register('单步骤', Colors.SUCCESS);
        colorService.register('多步骤', Colors.DANGER);

        colorService.register('完成', Colors.SUCCESS);
        colorService.register('执行中', Colors.PRIMARY);
        colorService.register('未执行', Colors.GRAY);

        colorService.register('一致', Colors.SUCCESS);
        colorService.register('不一致', Colors.DANGER);

        colorService.register('运行中', Colors.PRIMARY);
        colorService.register('成功', Colors.SUCCESS);
        colorService.register('错误', Colors.DANGER);
        colorService.register('中断', Colors.WARNING);
        colorService.register('异常', Colors.DANGER);

        colorService.register('online', Colors.PRIMARY);
        colorService.register('offline', Colors.GRAY);

        colorService.register('ACTIVE', Colors.PRIMARY);
        colorService.register('FROZEN', Colors.RED);
        colorService.register('UNLOADED', Colors.GRAY);

        colorService.registerGroup('use_state', '未使用', Colors.PRIMARY);
        colorService.registerGroup('use_state', '已使用', Colors.RED);

        colorService.registerGroup('plan_result', '未执行', Colors.GRAY);
        colorService.registerGroup('plan_result', '执行中', Colors.PRIMARY);
        colorService.registerGroup('plan_result', '完成', Colors.SUCCESS);
        colorService.registerGroup('plan_result', '失败', Colors.DANGER);
        colorService.registerGroup('plan_result', '已停止', Colors.ORANGE);

        colorService.registerGroup('perf_result_status', '执行中', Colors.PRIMARY);
        colorService.registerGroup('perf_result_status', '完成', Colors.SUCCESS);
        colorService.registerGroup('perf_result_status', '错误', Colors.DANGER);
        colorService.registerGroup('perf_result_status', '中断', Colors.ORANGE);

        colorService.registerGroup('perf_device_status', 'login', Colors.GREEN);
        colorService.registerGroup('perf_device_status', 'logout', Colors.YELLOW);
        colorService.registerGroup('perf_device_status', 'error', Colors.DANGER);
        colorService.registerGroup('perf_device_status', 'offline', Colors.GRAY);

        colorService.registerGroup('detect_status', '未巡测', Colors.GRAY);
        colorService.registerGroup('detect_status', '成功', Colors.SUCCESS);
        colorService.registerGroup('detect_status', '失败', Colors.DANGER);

        colorService.registerGroup('data_batch_status', '执行中', Colors.INFO);
        colorService.registerGroup('data_batch_status', '执行成功', Colors.SUCCESS);
        colorService.registerGroup('data_batch_status', '执行失败', Colors.DANGER);
        colorService.registerGroup('data_batch_status', '未知', Colors.GRAY);

        colorService.registerGroup('demand_status', '待测试', Colors.GRAY);
        colorService.registerGroup('demand_status', '测试中', Colors.PRIMARY);
        colorService.registerGroup('demand_status', '完成', Colors.GREEN);
        colorService.registerGroup('demand_status', '取消', Colors.RED);

        colorService.registerGroup('case_status', '待提交', Colors.GRAY);
        colorService.registerGroup('case_status', '待审核', Colors.RED);
        colorService.registerGroup('case_status', '已审核', Colors.SUCCESS);

        colorService.registerGroup('bug_level', '提示', Colors.INFO);
        colorService.registerGroup('bug_level', '一般', Colors.SUCCESS);
        colorService.registerGroup('bug_level', '严重', Colors.VOLCANO);
        colorService.registerGroup('bug_level', '阻断', Colors.RED);

        colorService.registerGroup('bug_status', '待确认', Colors.GRAY);
        colorService.registerGroup('bug_status', '待解决', Colors.PRIMARY);
        colorService.registerGroup('bug_status', '待验证', Colors.SUCCESS);
        colorService.registerGroup('bug_status', '重处理', Colors.GOLD);
        colorService.registerGroup('bug_status', '已关闭', Colors.DANGER);

        colorService.registerGroup('test_status', '待处理', Colors.GRAY);
        colorService.registerGroup('test_status', '已处理', Colors.PRIMARY);
        colorService.registerGroup('test_status', '已删除', Colors.DANGER);
        colorService.registerGroup('test_status', '待审核', Colors.YELLOW);
        colorService.registerGroup('test_status', '审核通过', Colors.SUCCESS);
        colorService.registerGroup('test_status', '用例审核通过', Colors.SUCCESS);
        colorService.registerGroup('test_status', '审核不通过', Colors.DANGER);
        colorService.registerGroup('test_status', '用例审核不通过', Colors.DANGER);

        colorService.registerGroup('test_case_result', '未测试', Colors.GRAY);
        colorService.registerGroup('test_case_result', '测试通过', Colors.SUCCESS);
        colorService.registerGroup('test_case_result', '测试不通过', Colors.DANGER);
        colorService.registerGroup('test_case_result', 'N/A', Colors.VOLCANO);
        colorService.registerGroup('test_case_result', '已阻塞', Colors.ORANGE);
        colorService.registerGroup('test_case_result', '成功', Colors.SUCCESS);
        colorService.registerGroup('test_case_result', '失败', Colors.DANGER);
        colorService.registerGroup('test_case_result', '未执行', Colors.GRAY);
        colorService.registerGroup('test_case_result', '未知', Colors.GRAY);

        colorService.registerGroup('task_status', '待处理', Colors.GRAY);
        colorService.registerGroup('task_status', '已完成', Colors.SUCCESS);
        colorService.registerGroup('task_status', '已失效', Colors.DANGER);
        colorService.registerGroup('task_status', '待审核', Colors.GRAY);
        colorService.registerGroup('task_status', '审核通过', Colors.SUCCESS);
        colorService.registerGroup('task_status', '审核拒绝', Colors.DANGER);

        colorService.registerGroup('unit_result', 'initializing', Colors.GRAY);
        colorService.registerGroup('unit_result', 'wait_running', Colors.PRIMARY);
        colorService.registerGroup('unit_result', 'running', Colors.GOLD);
        colorService.registerGroup('unit_result', 'success', Colors.SUCCESS);
        colorService.registerGroup('unit_result', 'error', Colors.DANGER);

        colorService.registerGroup('repository_status', '未初始化', Colors.PRIMARY);
        colorService.registerGroup('repository_status', '已初始化', Colors.SUCCESS);
        colorService.registerGroup('repository_status', '初始化中', Colors.GOLD);
        colorService.registerGroup('repository_status', '初始化失败', Colors.DANGER);

        colorService.registerGroup('infm_machine_status', '在线', Colors.SUCCESS);
        colorService.registerGroup('infm_machine_status', '离线', Colors.GRAY);
    };
}
