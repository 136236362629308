import { FocusMonitor } from '@angular/cdk/a11y';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ai-select-search',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <input #inputElement
               autocomplete="off"
               class="select-selection-search-input"
               [style.opacity]="showInput ? null : 0"
               [readOnly]="!showInput"
               [formControl]="inputControl" />
        <span #mirrorElement *ngIf="mirrorSync" class="select-selection-search-mirror"></span>`,
    host: {
        '[class.select-selection-search]': 'true'
    }
})
export class SelectSearchComponent implements AfterViewInit, OnChanges, OnDestroy {

    @Input() disabled = false;
    @Input() mirrorSync = false;
    @Input() showInput = true;
    @Input() focusTrigger = false;
    @Input() value = '';

    inputControl: FormControl = new FormControl('');

    @Output() readonly valueChange = new EventEmitter<string>();
    @Output() readonly isComposingChange = new EventEmitter<boolean>();

    @ViewChild('inputElement', { static: true }) inputElement: ElementRef;
    @ViewChild('mirrorElement', { static: false }) mirrorElement: ElementRef;

    private destroy$ = new Subject();

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private focusMonitor: FocusMonitor) {
        this.inputControl.valueChanges.pipe(debounceTime(300)).pipe(takeUntil(this.destroy$)).subscribe(() => this.change());
    }

    @HostListener('compositionstart')
    compositionstart(): void {
        this.isComposingChange.next(true);
    }

    @HostListener('compositionend')
    compositionend(): void {
        this.isComposingChange.next(false);
    }

    syncMirrorWidth(): void {
        const mirrorDOM = this.mirrorElement.nativeElement;
        const hostDOM = this.elementRef.nativeElement;
        const inputDOM = this.inputElement.nativeElement;
        this.renderer.removeStyle(hostDOM, 'width');
        mirrorDOM.innerHTML = `${inputDOM.value}&nbsp;`;
        this.renderer.setStyle(hostDOM, 'width', `${mirrorDOM.scrollWidth}px`);
    }

    focus(): void {
        this.focusMonitor.focusVia(this.inputElement, 'keyboard');
    }

    blur(): void {
        this.inputElement.nativeElement.blur();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const inputDOM = this.inputElement.nativeElement;
        const { focusTrigger, value, disabled } = changes;
        if (focusTrigger && focusTrigger.currentValue === true && focusTrigger.previousValue === false) {
            inputDOM.focus();
        }
        if (value) {
            this.inputControl.setValue(value.currentValue);
        }
        if (disabled) {
            if (disabled.currentValue) {
                this.inputControl.disable();
            } else {
                this.inputControl.enable();
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.mirrorSync) {
            this.syncMirrorWidth();
        }
    }

    private change(): void {
        this.value = this.inputControl.value;
        this.valueChange.next(this.value);
        if (this.mirrorSync) {
            this.syncMirrorWidth();
        }
    }

    ngOnDestroy() {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
