import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { isPresent } from '../facade';

@Component({
    selector: 'message-lines', // tslint:disable-line
    templateUrl: 'msg.lines.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MessageLinesComponent implements OnInit, OnDestroy {

    @Input() title: string;
    @Input() text: string;
    @Input() separator: string;

    constructor() {
        this.separator = '\n';
    }

    ngOnInit() {
    }

    get rows(): Array<string> {
        if (!isPresent(this.text)) {
            return [];
        }

        return this.text.split(this.separator);
    }

    ngOnDestroy() {
    }
}
