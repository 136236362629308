import { Component, OnDestroy, OnInit } from '@angular/core';
import { DEFAULT_COLORS, SkinColor } from 'app/main/skin/app.skin.model';
import { SysParamService, SysParamType } from 'core';
import { DrawerRef, isPresent } from 'share';
import { setSkinColor } from './util';

@Component({
    selector: 'ai-app-skin',
    templateUrl: 'app.skin.component.html'
})

export class AppSkinComponent implements OnInit, OnDestroy {

    items: Array<SkinColor> = [];

    private params: Array<any>;

    constructor(public drawer: DrawerRef, private paramService: SysParamService) {
    }

    ngOnInit() {
        this.paramService.qry(SysParamType.SKIN).subscribe(items => {
            this.params = items;
            this.items = DEFAULT_COLORS.map(color => {
                let item = items.find(v => v.param_key === color.key);
                color.color = item?.param_value ?? color.color;
                color.name = item?.param_desc ?? color.name;
                return color;
            });
        });
    }

    submit(): void {
        this.items.forEach(color => {
            setSkinColor(color);
            let param = this.params.find(p => p.param_key === color.key);
            if (isPresent(param)) {
                param.param_value = color.color;
                param.operate_type = 'U';
                return;
            }
            this.params.push({
                param_type: SysParamType.SKIN,
                param_key: color.key,
                param_value: color.color,
                status: 0,
                operate_type: 'C'
            });
        });
        this.paramService.update(this.params).subscribe(() => this.drawer.close());
    }

    ngOnDestroy() {
    }
}
