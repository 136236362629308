import { getBaseFontSize } from './style';

function extend(source, dst) {
    Object.keys(source).forEach(key => {
        let value = dst[key];
        if (typeof value === 'object' && !Array.isArray(value)) {
            extend(source[key], value);
        } else {
            dst[key] = source[key];
        }
    });

    return dst;
}

export function xAxis(data: Array<any>, options = {}) {
    let fontSize = getBaseFontSize();
    let d = {
        type: 'category',
        boundaryGap: 0,
        data,
        axisLine: {
            lineStyle: {
                color: '#a5bbbc'
            }
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: ['#fafafa']
            }
        },
        axisLabel: {
            fontSize: fontSize * 0.75
        }
    };
    return extend(options, d);
}

export function yAxis(options = {}) {

    let fontSize = getBaseFontSize();

    let d = {
        type: 'value',
        axisLine: {
            symbol: ['none', 'arrow'],
            lineStyle: {
                color: '#a5bbbc'
            }
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: ['#fafafa']
            }
        },
        nameTextStyle: {
            fontSize: fontSize * 0.75
        },
        axisLabel: {
            fontSize: fontSize * 0.75
        }
    };
    return extend(options, d);

}
