import { RouterModule } from '@angular/router';
import { ClassNames } from 'core';
import { ProductUserComponent } from './product.user.component';

export const productUserRoute = RouterModule.forChild([
    {
        path: '',
        component: ProductUserComponent,
        data: { className: ClassNames.PAGE }
    },
    {
        path: 'group',
        loadChildren: () => import('../group/user.group.module').then(m => m.UserGroupModule)
    }
]);
