import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PaginationConfig {
    disabled = false;
    boundaryLinks = false;
    showQuickJumper = false;
    showSizeChanger = false;
    showTotal = false;
    directionLinks = true;
    ellipses = true;
    maxSize = 0;
    pageSize = 15;
    rotate = false;
    size: 'sm' | 'lg';
    pageSizeOptions = [10, 20, 30, 50, 100];
    align: 'left' | 'center' | 'right';
}
