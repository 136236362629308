import { Directive, Host, Optional } from '@angular/core';
import { DatePickerComponent } from '../datepicker.component';

@Directive({
    selector: 'ai-datepicker-month', // tslint:disable-line
    exportAs: 'monthPicker'
})
export class MonthPickerComponent { // tslint:disable-line

    constructor(@Optional() @Host() public datePicker: DatePickerComponent) {
        this.datePicker.mode = 'month';
        this.datePicker.format = 'yyyy-MM';
    }
}
