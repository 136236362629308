import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarModule } from './avatar';
import { BackModule } from './back/back.module';
import { BackModule2 } from './back_new/back.module';
import { RightBottomButtonModule } from './button/button.module';
import { DropdownInputEditComponent } from './components';
import { ConfirmModule } from './confirm/confirm.module';
import { ContextmenuDirective } from './contextmenu';
import { DatepickerModule } from './datepicker/datepicker.module';
import { DelModule } from './del/del.module';
import { AceDifferComponent } from './differ/ace.differ.component';
import { AutoFocusDirective, HorizontalResizeDirective, TriggerFrameDirective, ViewInitDirective } from './directives';
import { PageBodyDirective } from './directives/page-body';
import { StepContentDirective } from './directives/step-content';
import { DividerModule } from './divider/divider.module';
import { DownloadModule } from './download/download.module';
import { DragRowDirective } from './drag/drag.row';
import { DragTableDirective } from './drag/drag.table';
import { DrawerModule } from './drawer/drawer.module';
import { DropDownModule } from './dropdown';
import { EchartsDirective } from './echart/echarts';
import { EditorModule } from './editor/editor.module';
import { EmptyModule } from './empty/empty.module';
import { FlowModule } from './flow/flow.module';
import { FormModule } from './form/form.module';
import { HighlightModule } from './highlight';
import { ImageViewModule } from './image/image.view.module';
import { LayoutNoneChildrenComponent } from './layout/layout.none.children.component';
import { MenuModule } from './menu';
import { ModalModule } from './modal/modal.module';
import { MoreOperationModule } from './more-operation/more.operation.module';
import { MsgSendModule } from './msg/msg.send.module';
import { NumberModule } from './number/number.module';
import { AiOverlayModule } from './overlay/overlay.module';
import { PaginationModule } from './pagination/pagination.module';
import { JsonKeyValuePipe, KeysPipe, MaxlengthPipe, OrderByPipe, ScalePipe } from './pipes';
import { PopoverModule } from './popover';
import { PositionModule } from './position/position.module';
import { ComponentFactoryService } from './providers';
import { ResizableModule } from './resizable/resizable.module';
import { RouterModule } from './router/router.module';
import { ScrollbarModule } from './scrollbar/scrollbar.module';
import { SearchModule } from './search/search.module';
import { SelectModule } from './select/select.module';
import { SpanModule } from './span/span.module';
import { StepsModule } from './steps/steps.module';
import { TableModule } from './table/table.module';
import { TabsetModule } from './tabs/tabset.module';
import { TagModule } from './tag/tag.module';
import { TextModule } from './text/text.module';
import { TextareaModule } from './textarea/textarea.module';
import { TimePickerModule } from './time-picker';
import { ToolTipModule } from './tooltip';
import { TreeModule } from './tree';
import { TreeSelectModule } from './tree-select';
import { UploadModule } from './upload/upload.module';
import { CrumbModule } from './crumb/crumb.module';

const COMPONENTS = [
    DropdownInputEditComponent,
    AceDifferComponent,
    LayoutNoneChildrenComponent
];

const DIRECTIVES = [
    EchartsDirective,
    AutoFocusDirective,
    HorizontalResizeDirective,
    TriggerFrameDirective,
    DragTableDirective,
    DragRowDirective,
    ContextmenuDirective,
    ViewInitDirective,
    PageBodyDirective,
    StepContentDirective
];

const PIPES = [
    KeysPipe,
    OrderByPipe,
    MaxlengthPipe,
    JsonKeyValuePipe,
    ScalePipe
];

const MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormModule,
    ScrollbarModule,
    ImageViewModule,
    UploadModule,
    EmptyModule,
    NumberModule,
    MsgSendModule,
    DownloadModule,
    ResizableModule,
    TextModule,
    EditorModule,
    RightBottomButtonModule,
    PositionModule,
    TextareaModule,
    SearchModule,
    TableModule,
    SpanModule,
    PaginationModule,
    StepsModule,
    DividerModule,
    RouterModule,
    MoreOperationModule,
    BackModule,
    BackModule2,
    TagModule,
    DelModule,
    ConfirmModule,
    AiOverlayModule,
    TimePickerModule,
    DatepickerModule,
    SelectModule,
    DrawerModule,
    TabsetModule,
    ModalModule,
    TreeModule,
    TreeSelectModule,
    ToolTipModule,
    PopoverModule,
    AvatarModule,
    FlowModule,
    MenuModule,
    DropDownModule,
    HighlightModule,
    CrumbModule
];

@NgModule({
    imports: [
        ...MODULES
    ],
    declarations: [
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES
    ],
    exports: [
        ...MODULES,
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES
    ],
    providers: [
        ComponentFactoryService
    ]
})
export class ShareModule {
}
