import { Component, Input, OnInit } from '@angular/core';
import { GlobalHolder } from 'app/global.holder';
import { ProductService } from 'app/product/product.service';
import { Role } from 'app/system/role/role.model';
import { isAdmin, User } from 'app/system/user/user.model';
import { UserService } from 'app/system/user/user.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DrawerRef } from 'share';
import { Product } from '../product.model';

@Component({
    selector: 'ai-product-edit',
    templateUrl: 'product.edit.component.html',
    styleUrls: ['product.edit.scss']
})
export class ProductEditComponent implements OnInit {

    @Input() product: Product;

    roles: Array<Role> = [];

    private user: User;

    constructor(public drawerRef: DrawerRef,
                private global: GlobalHolder,
                private userService: UserService,
                private productService: ProductService) {
        this.product = { rev_lib: '0' };
    }

    ngOnInit(): void {
        this.user = this.global.user.getValue();

        this.userService.qryRoles(this.user.user_id).subscribe(roles => {
            roles.forEach(role => role.selected = this.product.role_ids?.some(id => role.role_id === id));
            this.roles = roles;
        });
    }

    isAdmin(): boolean {
        return isAdmin(this.user);
    }

    submit(): void {
        this.product.role_ids = this.roles.filter(role => role.selected).map(role => role.role_id);
        let observable: Observable<any>;
        if (this.product.product_id !== undefined) {
            observable = this.productService.mod(this.product);
        } else {
            this.product.tenant_code = this.global.tenant.getValue().tenant_code;
            observable = this.productService.add(this.product).pipe(tap(id => this.product.product_id = +id));
        }
        observable.subscribe(() => {
            toastr.success('保存项目信息成功');
            this.drawerRef.close(this.product);
        });
    }
}
