import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { OverlayRef } from '@angular/cdk/overlay';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    NgZone,
    Optional,
    Renderer2,
    ViewChild
} from '@angular/core';

import { nzModalAnimations } from './modal-animations';
import { BaseModalContainer } from './modal-container';
import { MODAL_OPTIONS, ModalOptions } from './modal-types';

@Component({
    selector: 'ai-modal-container',
    exportAs: 'modalContainer',
    templateUrl: 'modal-container.component.html',
    animations: [nzModalAnimations.modalContainer],
    changeDetection: ChangeDetectionStrategy.Default,
    host: {
        tabindex: '-1',
        role: 'dialog',
        class: 'modal-wrap',
        '[style.zIndex]': 'config.zIndex',
        '[@modalContainer]': 'state',
        '(@modalContainer.start)': 'onAnimationStart($event)',
        '(@modalContainer.done)': 'onAnimationDone($event)',
        '(click)': 'click($event)'
    }
})
export class ModalContainerComponent extends BaseModalContainer {

    @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;

    @ViewChild('modalElement', { static: true }) modalElementRef: ElementRef<HTMLDivElement>;

    constructor(
        elementRef: ElementRef,
        focusTrapFactory: ConfigurableFocusTrapFactory,
        cdr: ChangeDetectorRef,
        render: Renderer2,
        zone: NgZone,
        overlayRef: OverlayRef,
        @Inject(MODAL_OPTIONS) public config: ModalOptions,
        @Optional() @Inject(DOCUMENT) document: any
    ) {
        super(elementRef, focusTrapFactory, cdr, render, zone, overlayRef, config, document);
    }
}
