import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicDirective } from './dynamic.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        DynamicDirective
    ],
    exports: [
        DynamicDirective
    ]
})
export class DynamicModule {
}
