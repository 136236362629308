import { AbstractControl } from '@angular/forms';
import { isPresent } from '../facade';

function validityControl(control: AbstractControl) {
    control.markAsDirty();
    control.updateValueAndValidity();
    if (isPresent(control['controls'])) {
        updateValueAndValidity(control as any);
    }
}

export function updateValueAndValidity({ controls }: { controls: { [key: string]: AbstractControl } | AbstractControl[] }) {
    if (Array.isArray(controls)) {
        controls.forEach(control => validityControl(control));
        return;
    }
    Object.keys(controls).forEach(key => validityControl(controls[key]));
}
