import { Directive, Host, Optional } from '@angular/core';
import { DatePickerComponent } from '../datepicker.component';

@Directive({
    selector: 'ai-datepicker-year',// tslint:disable-line
    exportAs: 'yearPicker'
})
export class YearPickerComponent {// tslint:disable-line

    constructor(@Optional() @Host() public datePicker: DatePickerComponent) {
        this.datePicker.mode = 'year';
        this.datePicker.format = 'yyyy';
    }
}
