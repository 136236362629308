import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { EventNames } from 'app/app.constants';
import { Subscription } from 'rxjs';
import { isPresent } from '../facade';
import { EventManager } from '../providers';

@Directive({ selector: '[contextmenu]' }) // tslint:disable-line
export class ContextmenuDirective implements OnInit, OnDestroy {

    @Input('contextmenu') element: HTMLElement;
    @Input() container: 'body' | 'parent';

    private subscriptions: Array<Subscription> = [];

    constructor(private eventManager: EventManager) {
        this.container = 'body';
    }

    ngOnInit(): void {
        let monitor$ = this.eventManager.subscribe(EventNames.CLOSE_ALL_RIGHT_MENU, () => this.hideMenu());
        this.subscriptions.push(monitor$);
    }

    private hideMenu() {
        if (!isPresent(this.element)) {
            return;
        }
        this.element.style.display = 'none';
    }

    @HostListener('contextmenu', ['$event'])
    rightClick(event: MouseEvent) {
        event.stopPropagation();
        if (!isPresent(this.element)) {
            return true;
        }
        this.eventManager.broadcast(EventNames.CLOSE_ALL_RIGHT_MENU);
        this.element.style.display = 'block';
        if (this.container === 'body') {
            this.element.style.left = `${event.pageX + 2}px`;
            this.element.style.top = `${event.pageY + 2}px`;
        } else {
            let parent = this.element.parentElement;
            let rect = parent.getBoundingClientRect();
            this.element.style.left = `${event.pageX - rect.left}px`;
            this.element.style.top = `${event.pageY - rect.top}px`;
        }
        return false;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(item => item.unsubscribe());
    }
}
