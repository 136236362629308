import { Injectable } from '@angular/core';
import { DrawerService } from '../drawer';
import { getBaseFontSize } from '../facade';
import { HelpDialogComponent } from './help/help.component';
import { ImageDialogComponent } from './image/image.component';
import { LinkDialogComponent } from './link/link.component';
import { VideoDialogComponent } from './video/video.component';

@Injectable()
export class EditorRichService {

    constructor(private drawer: DrawerService) {
    }

    initOptions(options: any): any {
        const OPTIONS = {
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear']],
                ['fontname', ['fontname']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['c_link', 'c_picture', 'c_video']],
                ['view', ['fullscreen', 'codeview', 'c_help']]
            ],
            buttons: this.initButtons(),
            minHeight: getBaseFontSize() * 10
        };
        return { ...OPTIONS, ...options };
    }

    private initButtons(): any {
        return {
            c_help: this.initHelpButton(),
            c_link: this.initLinkButton(),
            c_picture: this.initImageButton(),
            c_video: this.initVideoButton()
        };
    }

    private initHelpButton(): Function {
        return context => {
            let ui = context.ui;

            let button = ui.button({
                contents: '<i class="note-icon-question"></i>',
                click: () => {
                    let drawerRef = this.drawer.open(HelpDialogComponent);
                    drawerRef.inputs.context = context;
                }
            });
            return button.render();
        };
    }

    private initLinkButton(): Function {
        return context => {
            let ui = context.ui;

            let button = ui.button({
                contents: '<i class="note-icon-link"></i>',
                click: () => {
                    context.invoke('editor.saveRange');
                    let drawerRef = this.drawer.open(LinkDialogComponent);
                    drawerRef.inputs.context = context;
                    drawerRef.afterClose.subscribe(linkInfo => {
                        context.invoke('editor.restoreRange');
                        if (!linkInfo) {
                            return;
                        }
                        context.invoke('editor.createLink', linkInfo);
                    });
                }
            });
            return button.render();
        };
    }

    private initImageButton(): Function {
        return context => {
            let ui = context.ui;

            let button = ui.button({
                contents: '<i class="note-icon-picture"></i>',
                click: () => {
                    context.invoke('editor.saveRange');
                    let drawerRef = this.drawer.open(ImageDialogComponent);
                    drawerRef.afterClose.subscribe(data => {
                        context.invoke('editor.restoreRange');
                        if (!data) {
                            return;
                        }
                        context.invoke('editor.insertImagesOrCallback', data);
                    });
                }
            });
            return button.render();
        };
    }

    private initVideoButton(): Function {
        return context => {
            let ui = context.ui;

            let button = ui.button({
                contents: '<i class="note-icon-video"></i>',
                click: () => {
                    context.invoke('editor.saveRange');
                    let drawerRef = this.drawer.open(VideoDialogComponent);
                    drawerRef.afterClose.subscribe(data => {
                        context.invoke('editor.restoreRange');
                        if (!data) {
                            return;
                        }
                        let video = document.createElement('video');
                        video.src = data;
                        video.controls = true;
                        video.style.width = '100%';
                        context.invoke('editor.insertNode', video);
                    });
                }
            });
            return button.render();
        };
    }

}
