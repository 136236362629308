import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: 'input[ngModel],input[formControlName],input[formControl]' // tslint:disable-line
})
export class InputFocusDirective implements OnInit, OnDestroy {

    private destroy$ = new Subject();

    constructor(private render: Renderer2, private elementRef: ElementRef<HTMLInputElement>) {
    }

    ngOnInit(): void {
        fromEvent(this.elementRef.nativeElement, 'focus').pipe(takeUntil(this.destroy$)).subscribe(() => this.changeClass(true));
        fromEvent(this.elementRef.nativeElement, 'blur').pipe(takeUntil(this.destroy$)).subscribe(() => this.changeClass(false));
    }

    changeClass(focus: boolean): void {
        let control = this.elementRef.nativeElement as HTMLElement;
        let i = 0;
        while (!control.classList.contains('form-control') && i < 5) {
            control = control.parentNode as HTMLElement;
            ++i;
        }
        if (control === this.elementRef.nativeElement || !control?.classList?.contains('form-control')) {
            return;
        }
        if (focus) {
            this.render.addClass(control, 'form-focus');
        } else {
            this.render.removeClass(control, 'form-focus');
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }

}
