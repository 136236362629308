import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'parseMillisecond'
})
export class ParseMillisecondPipe implements PipeTransform {
    transform(value: number): string {
        if (!value) {
            return;
        }
        let millisecond = Math.ceil(value);
        if (millisecond < 1000) {
            return `${millisecond}毫秒`;
        }
        let time = Math.ceil(millisecond / 1000);
        let hour = Math.floor(time / 3600);
        let minute = Math.floor((time - 3600 * hour) / 60);
        let second = time % 60;

        let r = '';
        if (hour > 0) {
            r = `${hour}小时`;
        }
        if (minute > 0) {
            r = `${r}${minute}分钟`;
        }
        return `${r}${second}秒`;
    }
}
