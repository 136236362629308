import { Observable, of } from 'rxjs';
import { FilterInitializer } from '../../initializer';
import { ComponentContext } from '../context';

export class SubscriptionInitializer implements FilterInitializer<ComponentContext> {

    init(context: ComponentContext): Observable<any> {
        context.instance.subscriptions = [];
        return of(context);
    }

    support(context: ComponentContext): boolean {
        return true;
    }

}
