import { Injectable, OnDestroy } from '@angular/core';
import { UIPage } from 'app/ui/page/ui.page.model';
import { Initializer } from 'core';
import { Observable, of } from 'rxjs';
import { isPresent, ModalService } from 'share';
import { UIPageStepHolder } from 'app/ui/page/step/ui.page.step.holder';
import { UIPageService } from 'app/ui/page/ui.page.service';

@Injectable()
export class RecordInitializer implements Initializer<UIPage>, OnDestroy {

    private item: UIPage;

    constructor(private modal: ModalService,
                private service: UIPageService,
                private holder: UIPageStepHolder) {
        this.receiveMessage = this.receiveMessage.bind(this);
        window.addEventListener('message', this.receiveMessage);
    }

    init(context: UIPage): Observable<any> {
        this.item = context;
        return of(context);
    }

    private receiveMessage(ev: MessageEvent): void {
        let msg = ev.data;
        console.log(msg);
        if (msg.from === 'web' || !isPresent(this.item)) {
            return;
        }

        switch (msg.key) {
            case 'OPERATE_DESC':
                this.qryOpDesc(msg.data);
                break
            case 'RECORD_COMPLETE':
                this.modal.confirm({ title: '是否使用录制的步骤覆盖脚本?' }).result.subscribe(() => this.item.script = msg.code)
                break
            case 'RECORDS_SAVE':
                this.modal.confirm({ title: '是否使用录制的步骤覆盖脚本?' }).result.subscribe(() => this.parseStep(msg));
                break
        }
    }

    private qryOpDesc(data: any) {
        if (!data?.operate) {
            return
        }
        this.service.qryOpDesc({
            operate: data.operate
        }).subscribe(item => {
            window.postMessage({
                key: 'OPERATE_DESC',
                from: 'web',
                data: item
            }, '*');
        })
    }

    private parseStep(msg: any): void {
        try {
            const data = msg.data;
            if (data?.record_type === 'component') {
                const comp = data?.components?.[0] ?? {};
                this.item.edit_type = 'step';
                this.item.component_name = comp.component_name ?? this.item.component_name;
                this.item.script = JSON.stringify({
                    max_id: comp.max_id,
                    steps: comp.steps
                });
                this.holder.updateScript(this.item.script);
            }
        } catch (e) {
            console.log(e);
        }
    }

    ngOnDestroy(): void {
        window.removeEventListener('message', this.receiveMessage);
    }
}
