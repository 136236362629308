import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import { COLOR_KEYS } from '../constants';
import { TagService } from './tag.service';

@Component({
    selector: 'ai-tag',
    templateUrl: 'tag.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'tag',
        '[class.tag-rounded]': 'type === "rounded"',
        '[class.tag-dark]': 'type === "dark"',
        '[class.tag-checked]': 'type === "checked"',
        '[class.active]': 'checked'
    }
})

export class TagComponent implements OnInit, OnChanges, OnDestroy {

    @Input() type: 'light' | 'rounded' | 'dark' | 'checked';
    @Input() dot: boolean;
    @Input() closeable: boolean;
    @Input() color: 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'red' | 'volcano' | 'orange' | 'gold' | 'yellow' | 'lime' | 'green' | 'cyan' | 'blue' | 'geekblue' | 'purple' | 'magenta' | 'gray';
    @Input() text: string;
    @Input() group: string;
    @Input() checked: boolean;

    constructor(private render: Renderer2, private elementRef: ElementRef, private tagService: TagService) {
        this.type = 'light';
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.group) {
            this.tagService.changeGroup(changes.group.previousValue, changes.group.currentValue, this);
        }
        this.initColor();
    }

    txtFn(text){
        let obj={
            login:"登录",
            logout:"注销",
            error:"错误",
            offline:"离线"
        }
        let n=text
        if(obj[text]){
            n= obj[text]
        }
        return n
        
    }

    private initColor(): void {
        COLOR_KEYS.forEach(key => this.render.removeClass(this.elementRef.nativeElement, `tag-${key}`));
        if (this.type === 'checked') {
            return;
        }
        this.render.addClass(this.elementRef.nativeElement, `tag-${this.color ?? this.tagService.getColor(this.group, this.text)}`);
    }

    ngOnDestroy() {
    }
}
