/* tslint:disable */

import { Directive, ExistingProvider, forwardRef, HostListener, Input } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const TRIM_VALUE_ACCESSOR: ExistingProvider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TrimValueAccessor),
    multi: true
};

@Directive({
    selector: 'input:not([ngbDatepicker]):not([ngbTypeahead]):not([type=checkbox]):not([type=radio])[formControlName],textarea[formControlName],input:not([ngbDatepicker]):not([ngbTypeahead]):not([type=checkbox]):not([type=radio])[formControl],textarea[formControl],input:not([ngbDatepicker]):not([ngbTypeahead]):not([type=checkbox]):not([type=radio])[ngModel],textarea[ngModel],[ngDefaultControl]',
    providers: [TRIM_VALUE_ACCESSOR]
})
export class TrimValueAccessor extends DefaultValueAccessor { // tslint:disable-line

    @Input() trim = true;

    @HostListener('input', ['$event.target.value'])
    ngOnChange(val: string) {
        this.onChange(this.process(val));
    }

    writeValue(value: any): void {
        super.writeValue(this.process(value));
    }

    private process(val: any): any {
        if (typeof val === 'string' && this.trim) {
            val = val.trim();
        }
        return val;
    }

}
