import { isPresent, validateError } from 'share';
import { UIPage } from 'app/ui/page/ui.page.model';


function parseScriptParam(text: string, reg: RegExp): Array<string> {
    let names = new Set<string>();

    let execArray = reg.exec(text);
    while (isPresent(execArray)) {
        let name = execArray[1] || execArray[2];
        if (names.has(name)) {
            validateError(`存在同名参数[${name}]`);
        }
        names.add(name);
        execArray = reg.exec(text);
    }

    let array = [];
    names.forEach(name => array.push(name));
    return array;
}


export function parseInParam(text: string): Array<string> {
    return parseScriptParam(text, /\${([\w\W]+?)}|in_data\[['"]([\w\W]+?)['"]]/g);
}

export function parseOutParam(text: string): Array<string> {
    return parseScriptParam(text, /put_out_data\(['"]([\w\W]+?)['"][\s]*,[\s]*[\w\W]+?\)/g);
}

export function parseAppOutParam(text: string): Array<string> {
    if (text.includes('put_out_data')) {
        return parseOutParam(text);
    }
    return parseScriptParam(text, /put_out_app_data\(['"]([\w\W]+?)['"][\s]*,[\s]*[\w\W]+?\)/g);
}

export function transformToPlugin(item: UIPage, script: string): any {
    let steps = [];
    try {
        steps = JSON.parse(script)?.steps;
    } catch (e) {
        console.log(e);
    }
    return {
        record_type: 'component',
        product_id: item.product_id,
        components: [{
            component_id: item.component_id,
            component_name: item.component_name,
            component_type: item.edit_type,
            script: item.edit_type === 'step' ? null : item.script,
            steps
        }]
    };
}
