import { InjectionToken } from '@angular/core';

export enum UploadStatus {
    Queue,
    Uploading,
    Done,
    Cancelled
}

export class FileSection {
    status: UploadStatus;
    data: Blob;
    index: number;
    percentage: number;
    total_size: number;
    start_time: number;
    end_time: number;

    constructor(item: {
        data: Blob;
        index: number;
        total_size: number;
    }) {
        Object.assign(this, item);
        this.percentage = 0;
        this.start_time = 0;
        this.end_time = 0;
        this.status = UploadStatus.Queue;
    }

    get spendTime() {
        if (this.end_time > this.start_time) {
            return this.end_time - this.start_time;
        }
        return 0;
    }
}

export class UploadProgress {

    static SECTION_SIZE = 1024 * 1024;

    file: File;
    status: UploadStatus;

    percentage: number;
    index: number;
    total_size: number;
    remain_time: string;
    speed: string;

    sections: Array<FileSection>;

    constructor(file: File) {
        this.file = file;
        this.total_size = file.size;
        this.index = -1;
        this.speed = '0 KB/s';
        this.percentage = 0;
        this.remain_time = '';
        this.status = UploadStatus.Queue;
        this.sections = [];
        this.slice(0);
    }

    next(): FileSection {
        if (this.index < this.sections.length) {
            this.index++;
            return this.sections[this.index];
        }
    }

    slice(index: number = 0): void {
        const start = index * UploadProgress.SECTION_SIZE;
        const end = (index + 1) * UploadProgress.SECTION_SIZE;
        const data = this.file.slice(start, end < this.total_size ? end : this.total_size + 1);

        this.sections.push(new FileSection({
            data,
            index,
            total_size: this.total_size
        }));
        if (end < this.total_size) {
            this.slice(index + 1);
        }
    }

    complete(index: number): void {
        const section = this.sections[index];
        section.end_time = Date.now();
        section.percentage = 100;
        section.status = UploadStatus.Done;
    }
}

export const UPLOAD_PATH = new InjectionToken<string>('upload path');
