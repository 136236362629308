import { Pipe, PipeTransform } from '@angular/core';
import { isPresent } from 'share';
import {
    DEMAND_LEVEL_KEYS,
    DEMAND_LEVEL_NAMES,
    DEMAND_STATUS_DETAIL_KEY,
    DEMAND_STATUS_DETAIL_NAME,
    DEMAND_STATUS_KEY,
    DEMAND_STATUS_NAME
} from '../constants';

@Pipe({
    name: 'demandStatus'
})
export class DemandStatusPipe implements PipeTransform {
    transform(value: any): any {
        return DEMAND_STATUS_NAME[DEMAND_STATUS_KEY.indexOf(+value)];
    }
}

@Pipe({
    name: 'demandStatusDetail'
})
export class DemandStatusDetailPipe implements PipeTransform {
    transform(value: any): any {
        return DEMAND_STATUS_DETAIL_NAME[DEMAND_STATUS_DETAIL_KEY.indexOf(+value)];
    }
}

@Pipe({
    name: 'demandLevel'
})
export class DemandLevelPipe implements PipeTransform {
    transform(value: any): any {
        return DEMAND_LEVEL_NAMES[DEMAND_LEVEL_KEYS.indexOf(value)];
    }
}

@Pipe({
    name: 'demandTaskStatus'
})

export class DemandTaskStatusPipe implements PipeTransform {

    private statusMap = {
        '0': '待处理',
        '1': '已完成'
    };

    transform(value: any): any {
        if (!isPresent(value)) {
            return '';
        }
        return this.statusMap[value + ''] || '';
    }
}

@Pipe({
    name: 'demandTaskType'
})

export class DemandTaskTypePipe implements PipeTransform {
    private typeMap = {
        '1': '审核任务',
        '2': '测试任务',
        '3': '自动化任务',
        '4': '缺陷任务'
    };

    transform(value: any): any {
        if (!isPresent(value)) {
            return '';
        }
        return this.typeMap[value + ''] || '';
    }
}

@Pipe({
    name: 'demandCaseStatus'
})
export class DemandCaseStatusPipe implements PipeTransform {
    private typeMap = {
        '0': '待提交',
        '1': '待审核',
        '2': '已审核'
    };

    transform(value: any): any {
        if (!isPresent(value)) {
            return '';
        }
        return this.typeMap[value + ''] || '';
    }
}

@Pipe({
    name: 'taskAuditStatus'
})

export class TaskAuditStatusPipe implements PipeTransform {
    private statusMap = {
        '0': '待审核',
        '1': '审核通过',
        '2': '审核不通过'

    };

    transform(value: any): any {
        if (!isPresent(value)) {
            return '';
        }
        return this.statusMap[value + ''] || '';
    }
}
