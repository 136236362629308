import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Initializer } from 'core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isPresent } from 'share';
import { UIPage } from '../ui.page.model';
import { UIPageService } from '../ui.page.service';

@Injectable()
export class PageInitializer implements Initializer<UIPage> {

    constructor(private route: ActivatedRoute, private pageService: UIPageService) {
    }

    init(context: UIPage): Observable<any> {
        if (!isPresent(context.component_id)) {
            context.component_params = [];
            context.edit_type = context.edit_type ?? 'step';
            return of(context);
        }
        return this.pageService.qryById(context.component_id, context.component_version).pipe(tap(item => {
            item.component_params = item.component_params || [];
            item.edit_type = item.edit_type ?? 'script';
            item.component_params.forEach(param => param.operate_type = 'U');
        }));
    }
}
