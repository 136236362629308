import { Pipe, PipeTransform } from '@angular/core';
import { PLAN_PROP_TYPE_KEYS, PLAN_PROP_TYPE_NAMES, PLAN_RESULT_KEYS, PLAN_RESULT_NAMES } from '../constants/plan';

@Pipe({
    name: 'planResult'
})
export class PlanResultStatusPipe implements PipeTransform {

    transform(status: number): string {
        return PLAN_RESULT_NAMES[(PLAN_RESULT_KEYS.indexOf(status))];
    }
}


@Pipe({
    name: 'planPropType'
})
export class PlanPropTypePipe implements PipeTransform {

    transform(type: string): string {
        return PLAN_PROP_TYPE_NAMES[(PLAN_PROP_TYPE_KEYS.indexOf(type))];
    }
}
