import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { flatNodes, InputBoolean, isNumber, isPresent, TreeNodeOptions } from 'share';
import { AiComponent, TreeKeyPrefix } from 'core';
import { UIPageTreeService } from 'app/ui/page/tree/page.tree.service';

@Component({
    selector: 'ai-page-tree-select',
    templateUrl: 'page.tree.select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => PageTreeSelectComponent),
        multi: true
    }]
})
@AiComponent()
export class PageTreeSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @Input() placeHolder: string;
    @Input() editType: string;
    @Input() @InputBoolean() disable: boolean;
    @Input() @InputBoolean() dropdownMatchSelectWidth: boolean = true;

    @Output() ngChange = new EventEmitter<any>()

    value: string;
    nodes: TreeNodeOptions[] = [];

    private onChange: any;

    constructor(private cdRef: ChangeDetectorRef, private treeService: UIPageTreeService) {
    }

    ngOnInit() {
        const tree$ = this.treeService.tree.subscribe(nodes => {
            this.nodes = nodes;
            this.render();
        })
        this.ngAdd$(tree$);

        this.treeService.initTree(this.editType).subscribe();
    }

    change(): void {
        const data = flatNodes(this.nodes).find(node => node.key === this.value)?.data;
        const id = +this.value?.replace(TreeKeyPrefix.PAGE, '');
        this.ngChange.emit(data);
        this.onChange(isNumber(id) ? id : null);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.value = isPresent(obj) ? `${TreeKeyPrefix.PAGE}${obj}` : null;
        this.render();
    }

    render(): void {
        this.cdRef.markForCheck();
    }

    ngOnDestroy() {
        this.treeService.clear();
    }
}
