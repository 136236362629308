import { ClassProvider } from '@angular/core';
import { PageInitializer } from 'app/ui/page/initializer/page.initializer';
import { RecordInitializer } from 'app/ui/page/initializer/record.initializer';
import { RevertInitializer } from 'app/ui/page/initializer/revert.initializer';
import { INITIALIZER } from 'core';

export const PAGE_INITIALIZERS: ClassProvider[] = [
    { provide: INITIALIZER, useClass: PageInitializer, multi: true },
    { provide: INITIALIZER, useClass: RevertInitializer, multi: true },
    { provide: INITIALIZER, useClass: RecordInitializer, multi: true }
];
