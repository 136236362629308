import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeKeyPrefix } from 'core';
import { isNumber, isPresent, TreeNodeOptions } from 'share';
import { GlobalHolder } from 'app/global.holder';
import { Group } from 'app/group/group.model';
import { GroupTreeService } from 'app/group/tree/group.tree.service';

@Component({
    selector: 'ai-group-select',
    templateUrl: 'group.select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => GroupSelectComponent),
        multi: true
    }]
})

export class GroupSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @Input() filter: (item: Group) => boolean;
    @Input() placeHolder = '';
    @Input() dropdownMatchSelectWidth: boolean;

    value: string;
    nodes: TreeNodeOptions[] = [];

    private _type: string;
    private group_id: number;
    private onChange: any;

    constructor(private global: GlobalHolder,
                private cdRef: ChangeDetectorRef,
                private treeService: GroupTreeService) {
        this.filter = () => true;
    }

    ngOnInit() {
    }

    @Input()
    set type(val: string) {
        if (!isPresent(val) || val === '') {
            this.clear();
            return;
        }
        this._type = val;
        this.initTree();
    }

    clear(): void {
        this.nodes = [];
        this?.onChange(null);
        this.render();
    }

    initTree(): void {
        this.treeService.initTree(this._type, this.filter).subscribe(nodes => {
            this.nodes = nodes;
            this.render();
        });
    }

    change(): void {
        this.group_id = +this.value?.replace(TreeKeyPrefix.GROUP, '');
        this.onChange(isNumber(this.group_id) ? this.group_id : null);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.group_id = obj;
        this.value = isPresent(obj) ? `${TreeKeyPrefix.GROUP}${obj}` : null;
        this.render();
    }

    render(): void {
        this.cdRef.markForCheck();
    }

    ngOnDestroy() {
    }
}
