import { Directive, Inject, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { isPresent } from 'share';
import { Validatable, VALIDATABLE } from './validate.processor';
import { ValidateRegistry } from './validate.registry';

@Directive({ selector: '[validatable]' }) //tslint:disable-line
export class ValidatableDirective implements OnInit, OnDestroy {

    constructor(@Optional() private registry: ValidateRegistry, @Self() @Inject(VALIDATABLE) private validate: Validatable) {
    }

    ngOnInit(): void {
        if (!isPresent(this.registry)) {
            return;
        }
        this.registry.register(this.validate);
    }

    ngOnDestroy(): void {
        if (!isPresent(this.registry)) {
            return;
        }
        this.registry.remove(this.validate);
    }

}
