import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'empty-container', // tslint:disable-line
    templateUrl: 'empty.container.component.html',
    styleUrls: ['empty.container.scss']
})
export class EmptyContainerComponent implements OnInit {

    @Input() emptied: boolean;
    @Input() addable: boolean;
    @Input() footer: TemplateRef<void>;
    @Input() content: string | TemplateRef<void>;

    @Output() add: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
        this.addable = false;
    }

    ngOnInit() {
    }
}
