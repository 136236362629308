import {
    Component,
    ComponentRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ModalService } from 'share';
import { SearchFilterHolder } from './search.filter.holder';
import { SearchFilterService } from './search.filter.service';
import { SearchFilterModalComponent } from './search.filter.modal.component';


@Component({
    selector: 'ai-search-filter-btn',
    templateUrl: 'search.filter.btn.component.html',
    host: {
        class: 'btn btn-primary'
    }
})
export class SearchFilterBtnComponent implements OnInit, OnDestroy {

    @Input() ref: ComponentRef<any>;

    constructor(private modal: ModalService,
                private holder: SearchFilterHolder,
                private service: SearchFilterService) {
    }

    ngOnInit(): void {
    }

    @HostListener('click')
    click(): void {
        this.save();
    }

    save(): void {
        const item = this.holder.option.getValue();
        const modalRef = this.modal.open(SearchFilterModalComponent, { modalClass: `modal-size-4` });
        modalRef.componentInstance.searchName = item?.search_name ?? '';
        modalRef.result.subscribe(searchName => {
            this.service.add({
                search_type: this.holder.type,
                search_name: searchName,
                search_content: JSON.stringify(this.ref.instance.params)
            }).subscribe((data) => {
                this.holder.addOption(data);
                toastr.success('保存筛选项成功');
            });
        });
    }

    ngOnDestroy(): void {
    }
}
