import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DrawerService, InputBoolean, isPresent } from 'share';
import { convertKey, OPERATE_MAP, UIStep, UIStepOperateConfig, UIStepWidgetType } from 'app/ui/page/step/ui.page.step.model';
import { PageWinComponent } from 'app/ui/page/win/page.win.component';


@Component({
    selector: 'ai-page-step-target',
    templateUrl: 'ui.page.step.target.component.html',
    styleUrls: ['ui.page.step.target.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIPageStepTargetComponent implements OnInit, OnDestroy {

    @Input() key: string = 'target';
    @Input() @InputBoolean() editable: boolean;
    @Input() @InputBoolean() mark: boolean;
    @Input() items: UIStep[] = [];

    @Input() step: UIStep = {};

    @Output() stepChange = new EventEmitter<any>();

    show: boolean = false;
    value: any = '';
    config: UIStepOperateConfig = {};
    private subject = new Subject()

    constructor(private cdRef: ChangeDetectorRef, private drawer: DrawerService) {
    }

    ngOnInit(): void {
        this.subject.pipe(debounceTime(500))
            .subscribe((step) => {
                this.stepChange.emit(step);
            });
    }

    @Input()
    set operate(val: string) {
        this.config = OPERATE_MAP.get(val) ?? {};
        this.init();
    }

    get isEmpty(): boolean {
        return !(this.value && this.value?.length)
    }

    get canRecover(): boolean {
        return this.step && isPresent(this.step.default) && this.step.default !== this.value;
    }

    get widget(): UIStepWidgetType {
        const key = convertKey(this.key, this.config.flip);
        return this.config[key];
    }

    get isDropdownInput(): boolean {
        return ['elements', 'rich', 'array'].includes(this.widget);
    }

    get placeholder(): string {
        if (this.widget === 'elements') {
            return '请点击输入，多个目标元素用回车分割';
        }
        if (this.widget === 'array') {
            return '请点击输入，多个值用回车分割';
        }
        return '请点击输入';
    }

    get disText() {
        if (this.widget === 'page') {
            return this.step.value;
        }
        if (this.widget === 'select') {
            return this.step.display
        }
        if (this.widget) {
            return this.value;
        }
    }

    visibleChange(event: any) {
        this.show = event;
    }

    init(): void {
        const key = convertKey(this.key, this.config.flip);
        const value = this.step[key];
        this.value = value;
        if (this.widget === 'elements') {
            this.value = [...new Set([value].concat(this.step.paths ?? []))].join('\n');
        }
        if (this.widget === 'array' && value?.length > 0) {
            this.value = value.join('\n');
        }
        this.render();
    }

    textChange(event: any): void {
        this.value = event;
        this.change();
    }

    recover(): void {
        this.step.value = this.step.default;
        this.value = this.step.default;
        this.subject.next(this.step);
    }

    gotoComp(): void {
        const drawerRef = this.drawer.open(PageWinComponent, { scrollable: true });
        drawerRef.inputs.editable = this.editable;
        drawerRef.inputs.item = { component_id: this.step.target };
        drawerRef.result.subscribe(() => {
            if (this.editable) {
                this.step.children = [];
                this.step.opened = false;
                this.subject.next(this.step);
            }
        });
    }

    changeComp(comp: any): void {
        this.step.value = comp.component_name;
        this.step.target = comp.component_id;
        this.subject.next(this.step);
    }

    changeParam(ev: any): void {
        this.step.display = ev.label;
    }

    change(): void {
        const key = convertKey(this.key, this.config.flip);
        this.step[key] = this.value;
        if (this.widget === 'elements') {
            const items: any = this.value.split('\n');
            this.value = items.join('\n');
            this.step[key] = items.shift();
            this.step.paths = items;
        }
        if (this.widget === 'array') {
            this.step[key] = this.value.split('\n');
        }
        if (this.widget === 'input') {
            this.subject.next(this.step);
        }
    }

    render(): void {
        this.cdRef.markForCheck();
    }

    ngOnDestroy(): void {
    }
}
