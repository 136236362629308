export function isChrome(): boolean {
    return navigator.userAgent.indexOf('WebKit') > 0;
}

export function isFirefox(): boolean {
    return navigator.userAgent.indexOf('Firefox') > 0;
}

export function isSafari(): boolean {
    return navigator.userAgent.indexOf('Safari') > 0;
}

export function isMozilla(): boolean {
    return navigator.userAgent.indexOf('Gecko/') > 0;
}

export function isMSIE(): boolean {
    return navigator.userAgent.indexOf('MSIE') > 0;
}
export function isWindows(): boolean {
    return navigator.platform === 'Win32'
        || navigator.platform === 'Windows'
        || navigator.userAgent.indexOf('Windows') > 0;
}

export function getDevicePixelRatio(): number {
    if (typeof devicePixelRatio === 'number' && isWindows()) {
        return devicePixelRatio;
    }
    return 1;
}
