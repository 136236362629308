import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DrawerModule } from '../drawer/drawer.module';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';
import { ImageTokenDirective } from './image.token.directive';

import { ImageViewComponent } from './image.view.component';
import { ImageViewService } from './image.view.service';

@NgModule({
    imports: [
        CommonModule,
        ScrollbarModule,
        DrawerModule
    ],
    exports: [
        ImageTokenDirective
    ],
    declarations: [
        ImageViewComponent,
        ImageTokenDirective
    ],
    entryComponents: [
        ImageViewComponent
    ],
    providers: [
        ImageViewService
    ]
})
export class ImageViewModule {
}
