import { Component, OnDestroy, OnInit } from '@angular/core';
import { SysParamService, SysParamType } from 'core';
import { isPresent, ModalRef } from 'share';

@Component({
    selector: 'ai-cache',
    templateUrl: 'cache.component.html'
})

export class CacheComponent implements OnInit, OnDestroy {

    items: Array<{ code?: string, name?: string, checked?: boolean; }> = [];

    private params: Array<any> = [];

    constructor(public modalRef: ModalRef, private paramService: SysParamService) {
        this.items = [
            { code: 'detect-monitor', name: '巡测平台', checked: false },
            { code: 'monitor-platform', name: '巡测区域', checked: false },
            { code: 'manual-dashboard', name: '测试中心仪表盘', checked: false },
            { code: 'manual-task', name: '测试中心工作台', checked: false },
            { code: 'plan', name: '计划管理', checked: false },
            { code: 'ai-user', name: '用户管理', checked: false },
            { code: 'ai-result-case', name: '计划用例执行结果', checked: false },
            { code: 'workbench', name: '工作台', checked: true }
        ];
    }

    ngOnInit() {
        this.paramService.qry(SysParamType.PARAM_CACHE).subscribe(items => {
            this.params = items || [];
            this.items.forEach(item => item.checked = this.params.some(p => p.param_key === item.code && !'false'.equalsIgnoreCase(p.param_value)));
        });
    }

    submit(): void {
        this.items.forEach(item => {
            let param = this.params.find(p => p.param_key === item.code);
            if (!isPresent(param)) {
                this.params.push({
                    param_type: SysParamType.PARAM_CACHE,
                    param_key: item.code,
                    param_value: item.checked,
                    status: 0,
                    operate_type: 'C'
                });
                return;
            }
            param.param_value = item.checked;
            param.operate_type = 'U';
        });
        this.paramService.update(this.params).subscribe(() => this.modalRef.close());
    }

    ngOnDestroy() {
    }
}
