export interface Page<T> {
    total_row?: number;
    page_size?: number;
    page_no?: number;
    total_page?: number;
    items?: Array<T>;
}

export function initServiceResultPage(page_no: number, page_size: number, items: Array<any>): any {
    let row_count = items.length;
    let page_count = Math.ceil(row_count / page_size);
    let start = (page_no - 1) * page_size;
    return { row_count, page_count, page_data: items.slice(start, start + page_size) };
}
