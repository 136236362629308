import { NgModule } from '@angular/core';
import { CoreModule } from 'core';
import { ShareModule } from 'share';

import { GroupTreeComponent } from './group.tree.component';

@NgModule({
    imports: [
        CoreModule,
        ShareModule
    ],
    exports: [
        GroupTreeComponent
    ],
    declarations: [
        GroupTreeComponent
    ]
})
export class GroupTreeModule {
}
