import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalRef } from 'share';


@Component({
    selector: 'ai-search-filter-modal',
    templateUrl: 'search.filter.modal.component.html'
})
export class SearchFilterModalComponent implements OnInit, OnDestroy {

    @Input() searchName: string;

    constructor(public modalRef: ModalRef) {
    }

    ngOnInit() {
    }

    submit(): void {
        this.modalRef.close(this.searchName);
    }

    ngOnDestroy() {
    }
}
