import { NgModule } from '@angular/core';
import { CoreModule } from 'core';
import { BatchSelectModule, ShareModule } from 'share';
import { ProductUserRoleModule } from 'app/product/user/role/product.user.role.module';
import { UserGroupSelectModule } from 'app/product/group/select/user.group.select.module';
import { ProductUserConfigComponent } from 'app/product/user/config/product.user.config.component';
import { ProductUserDelComponent } from 'app/product/user/del/product.user.del.component';
import { ProductUserGroupComponent } from 'app/product/user/group/product.user.group.component';
import { ProductUserComponent } from './product.user.component';
import { productUserRoute } from './product.user.route';

@NgModule({
    imports: [
        CoreModule,
        ShareModule,
        BatchSelectModule,
        UserGroupSelectModule,
        ProductUserRoleModule
    ],
    declarations: [
        ProductUserComponent,
        ProductUserConfigComponent,
        ProductUserDelComponent,
        ProductUserGroupComponent
    ]
})
export class ProductUserModule {
}

@NgModule({
    imports: [
        productUserRoute,
        ProductUserModule
    ]
})
export class ProductUserRouteModule {

}
