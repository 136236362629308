import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Headers } from 'app/app.constants';
import { tap } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class ProfilesService {

    private data: Map<string, any> = new Map()
    private baseUrl = '/xltp/profiles';

    constructor(private http: HttpClient) {
    }

    qryActive(): Observable<any> {
        if (this.data.has('active')) {
            return of(this.data.get('active'));
        }
        return this.http.get(`${this.baseUrl}/active`, {
            headers: new HttpHeaders().set(Headers.BLOCK, 'false').set(Headers.SHOW_ERROR, 'false'),
            responseType: 'text'
        }).pipe(tap(item => this.data.set('active', item)));
    }

}
