import { isPresent } from 'share';
import { NodeLike, NodeType } from './node';

export class NodeLikeVisitor {

    visitName(node: NodeLike): string {
        return node?.name;
    }

    visitParent(node: NodeLike): NodeLike {
        return node?.parent;
    }

    visitChildren(node: NodeLike): NodeLike[] {
        return node?.children;
    }
}

export function getJsonPath(node: NodeLike, visitor: NodeLikeVisitor = new NodeLikeVisitor()): string {
    let path = '';
    let parent = visitor.visitParent(node);
    while (isPresent(parent)) {
        if (parent.type & NodeType.Array) {
            path = `[${visitor.visitChildren(parent).findIndex(item => item === node)}]${path}`;
        } else {
            path = `.${visitor.visitName(node)}${path}`;
        }
        node = parent;
        parent = visitor.visitParent(node);
    }
    return `\$${path.startsWith('.') ? '' : '.'}${path}`;
}

export function getXpath(node: NodeLike, visitor: NodeLikeVisitor = new NodeLikeVisitor()): string {
    let path = '';
    while (isPresent(node)) {
        let parent = visitor.visitParent(node);
        let children = visitor.visitChildren(parent);
        if (node.type & NodeType.Attribute) {
            path = `/[@${visitor.visitName(node)}]${path}`;
        } else if (isPresent(parent) && children.filter(item => visitor.visitName(item) === visitor.visitName(node)).length > 1) {
            path = `/${visitor.visitName(node)}[${children.findIndex(item => item === node)}]${path}`;
        } else {
            path = `/${visitor.visitName(node)}${path}`;
        }
        node = parent;
    }
    return `/${path}`.replace('//', '/');
}
