import { isPresent } from 'share';

import {
    DefaultFormValueVisitor,
    DefaultFormVisitor,
    FormValueVisitor,
    FormVisitor,
    visitForm,
    visitFormValue
} from './form.visitor';
import { Node } from './node';
import { NodeFormatter } from './node.formatter';

export class FormJsonFormatter implements NodeFormatter {

    constructor(private visitor: FormVisitor = new DefaultFormVisitor(), private valueVisitor: FormValueVisitor = new DefaultFormValueVisitor()) {
    }

    parse(text: string, nodes: Node[]): void {
        try {
            let obj = JSON.parse(text || '{}');
            nodes.filter(node => !isPresent(obj[node.name])).forEach(node => nodes.remove(node));
            Object.keys(obj).forEach(key => {
                let node = nodes.find(item => item.name === key);
                if (!isPresent(node)) {
                    node = this.visitor.visitNode(key);
                    nodes.push(node);
                }
                visitForm(this.visitor, obj[key], node);
            });
        } catch (e) {
            console.error(e);
            console.info(text);
        }
    }

    stringify(nodes: Node[]): string {
        let obj = {};
        nodes.forEach(node => obj[node.name] = visitFormValue(this.valueVisitor, node));
        return JSON.stringify(obj, null, '\t');
    }

}
