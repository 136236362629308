import { Observable, of } from 'rxjs';
import { isPresent, validateError } from 'share';
import { FilterInitializer } from '../../initializer';
import { ComponentContext } from '../context';

export class ValidateInitializer implements FilterInitializer<ComponentContext> {

    init(context: ComponentContext): Observable<any> {
        if (!isPresent(context.ngInit) || !isPresent(context.ngDestroy)) {
            validateError(`组件${context.instance.constructor.name}未实现OnInit和OnDestroy接口`);
        }
        if ((context.options.caches || []).length > 0 && !isPresent(context.options.name)) {
            validateError('请设置缓存名称');
        }
        return of(context);
    }

    support(context: ComponentContext): boolean {
        return true;
    }

}
