import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { DateFnsCalendar } from './calendar';
import { DisabledDateFn, DisabledTimeConfig, DisabledTimeFn } from './standard-types';

const defaultDisabledTime: DisabledTimeConfig = {
    disabledHours(): number[] {
        return [];
    },
    disabledMinutes(): number[] {
        return [];
    },
    disabledSeconds(): number[] {
        return [];
    }
};

export function getTimeConfig(value: DateFnsCalendar, disabledTime: DisabledTimeFn): DisabledTimeConfig {
    let disabledTimeConfig = disabledTime ? disabledTime(value && value.nativeDate) : ({} as DisabledTimeConfig);
    disabledTimeConfig = {
        ...defaultDisabledTime,
        ...disabledTimeConfig
    };
    return disabledTimeConfig;
}

export function isTimeValidByConfig(value: DateFnsCalendar, disabledTimeConfig: DisabledTimeConfig): boolean {
    let invalidTime = false;
    if (value) {
        const hour = value.getHours();
        const minutes = value.getMinutes();
        const seconds = value.getSeconds();
        const disabledHours = disabledTimeConfig.disabledHours();
        if (disabledHours.indexOf(hour) === -1) {
            const disabledMinutes = disabledTimeConfig.disabledMinutes(hour);
            if (disabledMinutes.indexOf(minutes) === -1) {
                const disabledSeconds = disabledTimeConfig.disabledSeconds(hour, minutes);
                invalidTime = disabledSeconds.indexOf(seconds) !== -1;
            } else {
                invalidTime = true;
            }
        } else {
            invalidTime = true;
        }
    }
    return !invalidTime;
}

export function isTimeValid(value: DateFnsCalendar, disabledTime: DisabledTimeFn): boolean {
    const disabledTimeConfig = getTimeConfig(value, disabledTime);
    return isTimeValidByConfig(value, disabledTimeConfig);
}

export function isAllowedDate(value: DateFnsCalendar, disabledDate?: DisabledDateFn, disabledTime?: DisabledTimeFn): boolean {
    if (!value) {
        return false;
    }
    if (disabledDate) {
        if (disabledDate(value.nativeDate)) {
            return false;
        }
    }
    if (disabledTime) {
        if (!isTimeValid(value, disabledTime)) {
            return false;
        }
    }
    return true;
}

export const DEFAULT_OVERLAY_POSITION: ConnectionPositionPair[] = [
    new ConnectionPositionPair({
        originX: 'start',
        originY: 'bottom'
    }, {
        overlayX: 'start',
        overlayY: 'top'
    }),
    new ConnectionPositionPair({
        originX: 'start',
        originY: 'top'
    }, {
        overlayX: 'start',
        overlayY: 'bottom'
    }),
    new ConnectionPositionPair({
        originX: 'end',
        originY: 'bottom'
    }, {
        overlayX: 'end',
        overlayY: 'top'
    }),
    new ConnectionPositionPair({
        originX: 'end',
        originY: 'top'
    }, {
        overlayX: 'end',
        overlayY: 'bottom'
    })
];
