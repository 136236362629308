import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { of  } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { DrawerRef, isPresent } from 'share';
import { isAdmin, User } from 'app/system/user/user.model';
import { Product, ProductUser, UserModel } from 'app/product/product.model';
import { GlobalHolder } from 'app/global.holder';
import { TenantService } from 'app/system/tenant/tenant.service';
import { ProductUserService } from 'app/product/user/product.user.service';


@Component({
    selector: 'ai-product-user-config',
    templateUrl: 'product.user.config.component.html'
})
export class ProductUserConfigComponent implements OnInit, OnDestroy {

    @Input() product: Product;

    name: string;
    pageNo: number;
    totalPage: number;

    users: Array<UserModel>;
    items: Array<ProductUser>;
    user: User;

    constructor(public drawerRef: DrawerRef,
                private global: GlobalHolder,
                private productUserService: ProductUserService,
                private tenantService: TenantService) {
        this.pageNo = 1;
        this.totalPage = 0;
    }

    ngOnInit(): void {
        this.user = this.global.user.getValue();
        this.productUserService.qryAll(this.product.product_id).subscribe(items => {
            this.items = items || [];
            this.qryUser();
        });
    }

    qryUser(): void {
        this.tenantService.qryUserPage(this.global.tenant_id, {
            user_name: this.name,
            page_no: this.pageNo,
            page_size: 15
        }).subscribe(page => {
            this.totalPage = page.page_count;
            this.generateUsers(page.page_data || []);
        });
    }

    private generateUsers(items: Array<any>): void {
        let users: Array<UserModel> = [];
        items.forEach(item => {
            if (isAdmin(item)) {
                return;
            }
            users.push({
                user_id: item.user_id,
                staff_code: item.staff_code,
                user_name: item.staff_name ? `${item.staff_name}(${item.nt_account})` : item.user_name,
                mobile: item.mobile,
                email: item.email,
                selected: this.items.findIndex(user => item.user_id === user.user_id ) !== -1,
                disabled: this.isDisabled(item)
            });
        });
        this.users = users;
    }

    private isDisabled(user: User): boolean {
        if (this.product.create_user === user.user_id || isAdmin(user)) {
            return true;
        }
        if (this.user.user_id === this.product.create_user || isAdmin(this.user)) {
            return false;
        }
        return this.items.findIndex(item => item.isAdmin && item.user_id === this.user.user_id) === -1 ||
            this.items.findIndex(item => item.isAdmin && item.user_id === user.user_id) !== -1;
    }

    goPage(page: number): void {
        this.pageNo = page;
        this.qryUser();
    }

    select(user: UserModel): void {
        if (user.selected) {
            let item = new ProductUser({
                user_id: user.user_id,
                user_name: user.user_name,
                staff_code: user.staff_code,
                mobile: user.mobile,
                email: user.email,
                is_admin: 0,
                data_right: 0
            });
            this.items.push(item);
        } else {
            this.items.splice(this.items.findIndex(item => item.user_id === user.user_id), 1);
        }
    }

    del(user: ProductUser): void {
        let data = this.users.find(item => item.user_id === user.user_id);
        if (isPresent(data)) {
            data.selected = false;
        }
        this.items.splice(this.items.findIndex(item => item === user), 1);
    }

    submit(): void {
        this.productUserService.saveUser(this.product.product_id, this.items).pipe(
            mergeMap(() => {
                if (this.product.product_id !== this.global.product_id) {
                    return of({});
                }
                return this.productUserService.qryAll(this.product.product_id).pipe(tap(items => this.global.updateProductUser(items)));
            })
        ).subscribe(() => {
            toastr.success('配置项目组成员成功');
            this.drawerRef.close();
        });
    }

    ngOnDestroy(): void {
    }
}
