import { isPresent } from './lang';

String.prototype.encodeXml = function() {
    return this.replace(/>(\S*)</g, (str, value) => {
        value = (value || '').replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&apos;');
        return `>${value}<`;
    });
};

String.prototype.decodeXml = function() {
    return this.replace(/&apos;/g, '\'')
        .replace(/&quot;/g, '"')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/&amp;/g, '&');
};

String.prototype.capitalize = function() {
    return this.replace(/(\w)/, function(v) {
        return v.toUpperCase();
    });
};

String.prototype.equalsIgnoreCase = function(anotherString: string): boolean {
    if (this === anotherString) {
        return true;
    }
    if (!isPresent(anotherString)) {
        return false;
    }
    return this.toLocaleLowerCase() === anotherString.toLocaleLowerCase();
};

export function isChinese(str): boolean {
    let reg = /[\u4E00-\u9FA5]/g;
    return reg.test(str);
}
