import * as echarts from 'echarts/core';
import { getBaseFontSize } from '../facade';


let colorPalette = [
    '#0fa5f6', '#09d3a2', '#06c867', '#f5cf0a', '#f59b7c',
    '#f56a6f', '#9999df', '#3f5088', '#95706d', '#dc69aa',
    '#07a2a4', '#9a7fd1', '#588dd5', '#f5994e', '#c05050',
    '#59678c', '#c9ab00', '#7eb00a', '#6f5553', '#c14089'
];

let borderColor = '#f0f0f0';
let titleColor = 'rgba(0, 0, 0, 0.85)';
let textColor = 'rgba(0, 0, 0, 0.45)';

let fontSize = getBaseFontSize();

let theme = {
    color: colorPalette,

    title: {
        left: fontSize,
        top: 0.5 * fontSize,
        textStyle: {
            fontWeight: '500',
            color: titleColor
        }
    },

    visualMap: {
        itemWidth: 15,
        color: ['#5ab1ef', '#e0ffff']
    },

    toolbox: {
        iconStyle: {
            borderColor: colorPalette[0]
        }
    },

    tooltip: {
        backgroundColor: 'rgba(50,50,50,0.5)',
        textStyle: {
            color: '#fff'
        },
        borderWidth: 0,
        axisPointer: {
            type: 'line',
            lineStyle: {
                color: '#008acd'
            },
            crossStyle: {
                color: '#008acd'
            },
            shadowStyle: {
                color: 'rgba(200,200,200,0.2)'
            }
        }
    },

    dataZoom: {
        dataBackgroundColor: '#efefff',
        fillerColor: 'rgba(182,162,222,0.2)',
        handleColor: '#008acd'
    },

    grid: {
        borderColor
    },

    categoryAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: borderColor
            }
        },
        splitLine: {
            lineStyle: {
                color: borderColor
            }
        },
        axisLabel: {
            color: textColor
        }
    },

    valueAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: borderColor
            }
        },
        splitLine: {
            lineStyle: {
                color: [borderColor]
            }
        },
        axisTick: {
            lineStyle: {
                color: borderColor
            }
        },
        nameTextStyle: {
            color: textColor
        },
        axisLabel: {
            color: textColor
        }
    },

    timeline: {
        lineStyle: {
            color: '#008acd'
        },
        controlStyle: {
            color: '#008acd'
        },
        symbol: 'emptyCircle',
        symbolSize: 3
    },

    line: {
        smooth: true,
        symbol: 'emptyCircle',
        symbolSize: 3
    },

    pie: {
        label: {
            color: '#333',
            fontSize
        }
    },

    candlestick: {
        itemStyle: {
            normal: {
                color: '#d87a80',
                color0: '#2ec7c9',
                lineStyle: {
                    color: '#d87a80',
                    color0: '#2ec7c9'
                }
            }
        }
    },

    scatter: {
        symbol: 'circle',
        symbolSize: 4
    },

    map: {
        label: {
            normal: {
                textStyle: {
                    color: '#d87a80'
                }
            }
        },
        itemStyle: {
            normal: {
                borderColor,
                areaColor: '#ddd'
            },
            emphasis: {
                areaColor: '#fe994e'
            }
        }
    },

    graph: {
        color: colorPalette
    },

    gauge: {
        axisLine: {
            lineStyle: {
                color: [[0.2, '#2ec7c9'], [0.8, '#5ab1ef'], [1, '#d87a80']],
                width: 10
            }
        },
        axisTick: {
            splitNumber: 10,
            length: 15,
            lineStyle: {
                color: 'auto'
            }
        },
        splitLine: {
            length: 22,
            lineStyle: {
                color: 'auto'
            }
        },
        pointer: {
            width: 5
        }
    }
};

export function registerTheme(): void {
    echarts.registerTheme('my', theme);
}

export function getTooltipFormatter(template: Map<string, string> | string = '{a}: {c}') {
    return function(params: any) {
        if (!params || params?.length <= 0) {
            return '';
        }
        const xLabel = params[0].axisValueLabel;
        const tips: string[] = [xLabel];
        params.forEach(item => {
            let temp = typeof template === 'string' ? template : template.get(item.seriesName) ?? '';
            temp = temp.replace('{a}', item.seriesName).replace('{c}', item.value);
            tips.push(temp);
        });
        return tips.join(`<br />`);
    };
}
