Array.prototype.min = function(key?: string, isNumber = false) {
    if (this.length === 0) {
        return 0;
    }

    return this.map(item => key ? item[key] : item).map(item => isNumber ? parseFloat(item) : item).reduce((p, c) => c < p ? c : p);
};

Array.prototype.max = function(key?: string, isNumber = false) {
    if (this.length === 0) {
        return 0;
    }
    return this.map(item => key ? item[key] : item).map(item => isNumber ? parseFloat(item) : item).reduce((p, c) => c > p ? c : p, 0);
};

Array.prototype.move = function(fromIndex: number, toIndex: number) {
    let from = this[fromIndex];
    this.splice(fromIndex, 1);
    this.splice(toIndex, 0, from);
};

Array.prototype.remove = function(item: any) {
    let index = this.findIndex(value => value === item);
    if (index === -1) {
        return;
    }
    this.splice(this.findIndex(value => value === item), 1);
};


Array.prototype.shuffle = function() {
    return this.sort(() => Math.random() < 0.5 ? -1 : 1);
};

Array.prototype.first = function() {
    if (this.length === 0) {
        return;
    }
    return this[0];
};

Array.prototype.last = function() {
    if (this.length === 0) {
        return;
    }
    return this[this.length - 1];
};

Array.prototype.prev = function(item: any) {
    let index = this.findIndex(value => value === item);
    if (index === -1) {
        return;
    }
    return this[index - 1];
};

Array.prototype.next = function(item: any) {
    let index = this.findIndex(value => value === item);
    if (index === -1) {
        return;
    }
    return this[index + 1];
};
