import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { ViewportRuler } from '@angular/cdk/overlay';
import { filter } from 'rxjs/operators';
import { AiComponent, Renderable, TreeKeyPrefix } from 'core';
import {
    flatNodes,
    FormatEmitEvent,
    InputBoolean,
    isPresent,
    TreeComponent,
    TreeNode,
    TreeNodeOptions,
    TreeVirtualScroll
} from 'share';
import { UIPage } from 'app/ui/page/ui.page.model';
import { GlobalHolder } from 'app/global.holder';
import { UIPageTreeService } from 'app/ui/page/tree/page.tree.service';


@Component({
    selector: 'ai-page-tree',
    templateUrl: 'page.tree.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
@AiComponent()
export class PageTreeComponent extends TreeVirtualScroll implements OnInit, Renderable {

    @Input() @InputBoolean() checkable: boolean;
    @Input() @InputBoolean() disable: boolean;
    @Input() editType: string = '';

    @Output() ngChange = new EventEmitter<{ items: Array<UIPage>, checked: boolean }>(true);

    @ViewChild('tree')
    treeComponent: TreeComponent;

    searchValue: string;
    nodes: TreeNodeOptions[] = [];
    checkedKeys: Array<string> = [];

    constructor(protected elementRef: ElementRef<HTMLElement>,
                protected cdRef: ChangeDetectorRef,
                protected viewport: ViewportRuler,
                private global: GlobalHolder,
                private treeService: UIPageTreeService) {
        super(elementRef, cdRef, viewport);
    }

    ngOnInit() {
        let product$ = this.global.product.pipe(filter(product => isPresent(product))).subscribe(() => {
            this.initTree();
        });
        this.ngAdd$(product$);
    }

    @Input()
    set checkedIds(value: Array<number>) {
        this.checkedKeys = value?.map(id => `${TreeKeyPrefix.PAGE}${id}`) ?? [];
        this.render();
    }

    initTree(): void {
        this.treeService.initTree(this.editType).subscribe(nodes => {
            this.nodes = nodes;
            this.render();
            this.changeTreeHeight();
        });
    }

    matchName(node: TreeNode): boolean {
        return !this.searchValue || this.searchValue.length <= 0 || node.title.includes(this.searchValue);
    }

    change(event: FormatEmitEvent) {
        const items = (flatNodes([event.node]) as TreeNode[])
            .filter(node => node.key.startsWith(TreeKeyPrefix.PAGE) && this.matchName(node))
            .map(node => node.origin.data);

        this.ngChange.emit({
            items,
            checked: event.node.isChecked
        });
    }

    getCheckedNodes(): Array<TreeNode> {
        return this.treeComponent.getCheckedNodeList();
    }
}
