import { Component, Input, OnInit } from '@angular/core';
import { zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DrawerRef } from 'share';
import { GlobalHolder } from 'app/global.holder';
import { Role } from 'app/system/role/role.model';
import { ProductUser } from 'app/product/product.model';
import { RoleService } from 'app/system/role/role.service';
import { UserService } from 'app/system/user/user.service';


@Component({
    selector: 'ai-product-user-role',
    templateUrl: 'product.user.role.component.html'
})
export class ProductUserRoleComponent implements OnInit {

    @Input() item: ProductUser;
    @Input() editable: boolean;

    items: Array<Role> = []; // 所有角色

    private roles: Array<Role> = []; // 用户角色

    constructor(public drawerRef: DrawerRef,
                private global: GlobalHolder,
                private roleService: RoleService,
                private userService: UserService) {
    }

    ngOnInit() {
        zip(this.roleService.qryAll(this.global.product_id), this.userService.qryRoles(this.item.user_id, this.global.product_id)).subscribe(([roles, userRoles]) => {
            this.items = roles || [];
            this.roles = userRoles || [];
            this.items.forEach(role => role.selected = this.roles.some(v => v.role_id === role.role_id));
        });
    }

    submit(): void {
        let roles = this.items.filter(role => role.selected);
        let items = [];
        this.roles.filter(item => !roles.some(role => role.role_id === item.role_id)).forEach(role => items.push({
            role_id: role.role_id,
            product_id: this.global.product_id,
            opera_type: 'D'
        }));

        roles.filter(item => !this.roles.some(role => role.role_id === item.role_id)).forEach(role => items.push({
            role_id: role.role_id,
            product_id: this.global.product_id,
            opera_type: 'C'
        }));

        this.userService.addRoles(this.item.user_id, items).pipe(switchMap(() => this.userService.qryRoles(this.item.user_id, this.global.product_id))).subscribe(userRoles => {
            let user = this.global.users.getValue().find(v => v.user_id === this.item.user_id);
            user.roles = userRoles;
            toastr.success('编辑用户角色成功');
            this.drawerRef.close();
        });
    }
}
