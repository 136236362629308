import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageParamComponent } from 'app/ui/page/edit/param/page.param.component';
import { UIPage } from 'app/ui/page/ui.page.model';
import { DrawerService } from 'share';

@Component({
    selector: 'ai-page-param-btn',
    templateUrl: 'page.param.btn.component.html'
})

export class PageParamBtnComponent implements OnInit, OnDestroy {

    @Input() editable: boolean;
    @Input() item: UIPage;

    constructor(private drawer: DrawerService) {
    }

    ngOnInit() {
    }

    configParam(): void {
        let drawerRef = this.drawer.open(PageParamComponent);
        drawerRef.inputs.editable = this.editable;
        drawerRef.inputs.items = this.item.component_params ?? [];
    }

    ngOnDestroy() {
    }
}
