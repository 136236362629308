import { AfterViewInit, Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { isPresent } from '../facade';
import { ScrollbarConfig, ScrollbarEvents, ScrollbarOptions } from './scrollbar.model';

@Component({
    selector: 'p-scrollbar', // tslint:disable-line
    templateUrl: 'scrollbar.component.html',
    styleUrls: ['scrollbar.scss'],
    providers: [ScrollbarConfig],
    exportAs: 'scrollbar'
})
export class ScrollbarComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy {

    private scrollbar: PerfectScrollbar;

    private _hide: boolean;
    private _scrollable: boolean;
    private _fixedBottom: boolean;
    private height: number;
    private width: number;
    private listenerMap = new Map<string, Array<Function>>();

    constructor(private elementRef: ElementRef<HTMLElement>,
                private render: Renderer2,
                private config: ScrollbarConfig) {
        this.scrollHandler = this.scrollHandler.bind(this);
        this.resizeHandler = this.resizeHandler.bind(this);
        this.scrollPosition = this.scrollPosition.bind(this);
        this._scrollable = true;
    }

    get scrollTop(): number {
        if (!isPresent(this.elementRef) || !isPresent(this.elementRef.nativeElement)) {
            return 0;
        }
        return this.elementRef.nativeElement.scrollTop;
    }

    @Input()
    set scrollable(value: boolean) {
        this._scrollable = value;
        if (this._scrollable === false) {
            this.destroyScrollbar();
        }
    }

    @Input()
    set hide(value: boolean) {
        this._hide = value;
        this.addOrRemoveHideClass();
    }

    @Input()
    set options(value: ScrollbarOptions) {
        if (this.config.update(value)) {
            this.initScrollbar();
        }
    }

    @Input()
    set scrollX(value: boolean) {
        if (this.config.update({ suppressScrollX: !value })) {
            this.initScrollbar();
        }
    }

    @Input()
    set fixedBottom(value: boolean) {
        this._fixedBottom = value;
    }

    @HostBinding('class.scrollbar-fixed-bottom')
    get scrollbarFixedBottom(): boolean {
        if (!this._fixedBottom) {
            return false;
        }
        const maxHeight = parseInt(getComputedStyle(this.elementRef.nativeElement)['max-height'], 10);
        const height = this.elementRef.nativeElement.scrollHeight;
        return maxHeight > 0 && height > maxHeight;
    }

    ngOnInit() {
        addEventListener('resize', this.resizeHandler);
    }

    ngAfterViewInit() {
        this.initScrollbar();
        this.addOrRemoveHideClass();
        this.addScrollListener(ScrollbarEvents.SCROLL_X, this.scrollPosition);
        this.addScrollListener(ScrollbarEvents.SCROLL_Y, this.scrollPosition);
    }

    ngDoCheck() {
        let { width, height } = this.getSize();
        if (this.width !== width) {
            this.updateWidth(width);
        }
        if (this.height !== height) {
            this.updateHeight(height);
        }
    }

    private initScrollbar(): void {
        this.destroyScrollbar();
        if (!this._scrollable) {
            return;
        }
        this.scrollbar = new PerfectScrollbar(this.elementRef.nativeElement, this.config);
    }

    private addOrRemoveHideClass(): void {
        if (this._hide) {
            this.render.addClass(this.elementRef.nativeElement, 'ps-hide');
        } else {
            this.render.removeClass(this.elementRef.nativeElement, 'ps-hide');
        }
    }

    private updateWidth(width: number): void {
        this.width = width;
        if (isPresent(this.elementRef.nativeElement)) {
            this.elementRef.nativeElement.scrollLeft = 0;
        }
        this.update();
    }

    private updateHeight(height: number): void {
        let sub = height - this.height;
        this.height = height;
        let element = this.elementRef.nativeElement as HTMLElement;
        if (!isPresent(element) || sub > 0) {
            this.update();
            return;
        }
        let scrollTop = element.scrollTop;
        scrollTop = height < (scrollTop + element.clientHeight) ? scrollTop - (scrollTop + element.clientHeight - height) : scrollTop;
        element.scrollTop = scrollTop < 0 ? 0 : scrollTop;
        this.update();
    }

    private getSize(): { width: number, height: number } {
        let element = this.elementRef.nativeElement;
        return {
            width: element.scrollWidth,
            height: element.scrollHeight
        };
    }

    addScrollListener(type: ScrollbarEvents, callback: Function): void {
        if (this.listenerMap.has(type)) {
            this.listenerMap.get(type).push(callback);
            return;
        }
        this.elementRef.nativeElement.addEventListener(type, this.scrollHandler);
        this.listenerMap.set(type, [callback]);
    }

    scrollToTop(offset?: number, time?: number) {
        this.animateScrolling('scrollTop', offset || 0, time);
    }

    scrollToBottom(offset?: number, speed?: number) {
        const height = this.elementRef.nativeElement.scrollHeight;
        this.animateScrolling('scrollTop', height - (offset || 0), speed);
    }

    scrollXStep(step: number) {
        this.scrollStep('scrollLeft', step);
    }

    scrollYStep(step: number) {
        this.scrollStep('scrollTop', step);
    }

    private update(): void {
        if (!isPresent(this.scrollbar)) {
            return;
        }
        this.scrollbar.update();
    }

    private resizeHandler(): void {
        this.update();
    }

    private scrollHandler(event: CustomEvent): void {
        this.listenerMap.get(event.type).forEach(callback => callback(event));
    }

    private scrollPosition(): void {
        this.addScrollPositionClass('x', this.scrollbar.reach.x);
        this.addScrollPositionClass('y', this.scrollbar.reach.y);
        // if (this.scrollbar.reach.y === 'start') { // TODO: 滚动Bug
        //     this.elementRef.nativeElement.scrollTop = 1;
        //     setTimeout(() => this.elementRef.nativeElement.scrollTop = 0);
        // }
    }

    private addScrollPositionClass(direction: string, position: string): void {
        this.render.removeClass(this.elementRef.nativeElement, `scroll-${direction}-start`);
        this.render.removeClass(this.elementRef.nativeElement, `scroll-${direction}-end`);
        this.render.removeClass(this.elementRef.nativeElement, `scroll-${direction}-middle`);

        this.render.addClass(this.elementRef.nativeElement, `scroll-${direction}-${position ?? 'middle'}`);
    }

    private scrollStep(target: string, step: number) {
        let el = this.elementRef.nativeElement;
        let value = el[target] + step;
        if (value < 0) {
            value = 0;
        }
        el[target] = value;
    }

    private animateScrolling(target: string, value: number, duration?: number) {
        let el = this.elementRef.nativeElement;
        if (!isPresent(duration) || duration <= 0) {
            el[target] = value;
            this.update();
            return;
        }

        const difference = value - el[target];
        const perTick = (difference / duration) * 10;

        requestAnimationFrame(() => {
            el[target] = el[target] + perTick;
            if (el[target] === value) {
                this.update();
                return;
            }
            this.animateScrolling(target, value, duration - 10);
        });
    }

    ngOnDestroy() {
        this.destroyScrollbar();
        this.listenerMap.forEach((value, key) => this.elementRef.nativeElement.removeEventListener(key, this.scrollHandler));
        removeEventListener('resize', this.resizeHandler);
    }

    private destroyScrollbar(): void {
        this.scrollbar?.destroy();
        this.scrollbar = null;
    }
}
