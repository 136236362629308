import { NgModule } from '@angular/core';
import { BatchSelectModule, ShareModule } from 'share';
import { CoreModule } from 'core';
import { GroupModule } from 'app/group/group.module';
import { ModeValueModule } from 'app/value/mode.value.module';
import { PageTreeModule } from 'app/ui/page/tree/page.tree.module';

import { UIPageStepPipe } from './ui.page.step.pipe';
import { UIPageStepHolder } from './ui.page.step.holder';
import { UIPageStepComponent } from './ui.page.step.component';
import { UIPageStepEditComponent } from './edit/ui.page.step.edit.component';
import { UIPageStepOperateComponent } from './operate/ui.page.step.operate.component';
import { UIPageStepTargetComponent } from './target/ui.page.step.target.component';
import { UIStepOperateExplainComponent } from './explain/operate.explain.component';
import { UIPageParamsSelectComponent } from './params/ui.page.params.select.component';
import { UIPageStepSaveasComponent } from './saveas/ui.page.step.saveas.component';


@NgModule({
    imports: [
        ShareModule,
        CoreModule,
        GroupModule,
        PageTreeModule,
        UIPageStepPipe,
        ModeValueModule,
        BatchSelectModule,
    ],
    exports: [
        UIPageStepComponent
    ],
    declarations: [
        UIPageStepComponent,
        UIPageStepEditComponent,
        UIPageStepOperateComponent,
        UIPageStepTargetComponent,
        UIStepOperateExplainComponent,
        UIPageStepSaveasComponent,
        UIPageParamsSelectComponent
    ],
    providers: [
        UIPageStepHolder
    ]
})
export class UIPageStepModule {
}
