import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';
import { DateFnsCalendar } from '../calendar';

@Component({
    selector: 'ai-calendar-footer',
    exportAs: 'calendarFooter',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'calender-footer.component.html'
})
export class CalendarFooterComponent implements OnInit, OnChanges, OnDestroy {

    @Input() showToday: boolean = false;
    @Input() hasTimePicker: boolean = false;
    @Input() isRange: boolean = false;

    @Input() okDisabled: boolean = false;
    @Input() disabledDate: (d: Date) => boolean;
    @Input() rangeQuickSelector: TemplateRef<void>;

    @Output() readonly clickOk = new EventEmitter<void>();
    @Output() readonly clickToday = new EventEmitter<any>();

    isTodayDisabled: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabledDate) {
            this.isTodayDisabled = this.disabledDate && this.disabledDate(new Date());
        }
    }

    onClickToday(): void {
        this.clickToday.emit(new DateFnsCalendar());
    }

    ngOnDestroy(): void {
    }
}
