import { GlobalHolder } from 'app/global.holder';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { FilterInitializer } from '../../initializer';
import { PoolService } from '../../providers';
import { ComponentContext } from '../context';

export class PoolInitializer implements FilterInitializer<ComponentContext> {

    init(context: ComponentContext): Observable<any> {
        let global = environment.injector.get(GlobalHolder);
        let poolService = environment.injector.get(PoolService);
        return poolService.qryByPage({ product_id: global.product.getValue().product_id }).pipe(
            tap(page => global.updatePools(page.page_data || [])),
            mapTo(context)
        );
    }

    support(context: ComponentContext): boolean {
        return context.options.initPool;
    }

}
