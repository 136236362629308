import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'deviceStatus'
})

export class DeviceStatusPipe implements PipeTransform {
    private scanMap = { 0: '空闲', 1: '忙碌' };

    transform(value: any, ...args: any[]): any {
        return this.scanMap[value] || '不可用';
    }
}