import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';
import { MenuModeType } from './menu.types';

@Component({
    selector: '[ai-submenu-title]', //tslint:disable-line
    exportAs: 'submenuTitle',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <i class="icon me-3" [ngClass]="icon" *ngIf="icon"></i>
        <ng-container *stringTemplateOutlet="title">
            <span>{{ title }}</span>
        </ng-container>
        <ng-content></ng-content>
        <span *ngIf="isMenuInsideDropDown; else notDropdownTpl" class="ai-dropdown-menu-submenu-arrow">
            <i class="icon icon-right ai-dropdown-menu-submenu-arrow-icon"></i>
        </span>
        <ng-template #notDropdownTpl>
            <i class="icon icon-down ai-menu-submenu-arrow"></i>
        </ng-template>
    `,
    host: {
        '[class.ai-dropdown-menu-submenu-title]': 'isMenuInsideDropDown',
        '[class.ai-menu-submenu-title]': '!isMenuInsideDropDown',
        '[style.paddingLeft.px]': 'paddingLeft',
        '(click)': 'clickTitle()',
        '(mouseenter)': 'setMouseState(true)',
        '(mouseleave)': 'setMouseState(false)'
    }
})
export class SubMenuTitleComponent {

    @Input() icon: string = null;
    @Input() title: string | TemplateRef<void> | null = null;
    @Input() isMenuInsideDropDown = false;
    @Input() disabled = false;
    @Input() paddingLeft: number | null = null;
    @Input() mode: MenuModeType = 'vertical';

    @Output() readonly toggleSubMenu = new EventEmitter();
    @Output() readonly subMenuMouseState = new EventEmitter<boolean>();

    setMouseState(state: boolean): void {
        if (!this.disabled) {
            this.subMenuMouseState.next(state);
        }
    }

    clickTitle(): void {
        if (this.mode === 'inline' && !this.disabled) {
            this.toggleSubMenu.emit();
        }
    }
}
