import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { mapTo, switchMap, tap } from 'rxjs/operators';
import { isPresent } from 'share';
import { User } from 'app/system/user/user.model';
import { Product } from 'app/product/product.model';
import { GlobalHolder } from 'app/global.holder';
import { ProductService } from 'app/product/product.service';
import { UserService } from 'app/system/user/user.service';
import { ProductGlobalService } from 'app/product/product.global.service';


@Injectable({ providedIn: 'root' })
export class ProductsHolder {

    private monitor$: Subscription;
    private tenantId: number;

    products: BehaviorSubject<Array<Product>> = new BehaviorSubject<Array<Product>>([]);

    constructor(private global: GlobalHolder,
                private userService: UserService,
                private productGlobal: ProductGlobalService,
                private productService: ProductService) {
    }

    updateProducts(products: Array<Product> = []): void {
        let colors = ['#f5d67c', '#F17C68', '#8addb6', '#7cc1e1'];
        const preIndex = products.findIndex(p => p.product_id === this.global.user.getValue()?.product_id);
        if (preIndex > 0) {
            const pre = products.splice(preIndex, 1);
            products.unshift(pre[0]);
        }
        products.forEach((product, index) => product.color = colors[index % 4]);
        this.products.next(products);
    }

    start(): void {
        if (this.tenantId === this.global.tenant_id) {
            return;
        }
        this.removeMonitor$();
        this.monitor$ = this.global.tenant.pipe(switchMap(tenant => {
            this.tenantId = tenant?.tenant_id;
            return this.productService.qry({
                user_id: this.global.user.getValue()?.user_id,
                tenant_id: tenant?.tenant_id
            })
        })).subscribe((items:any) =>{
            this.updateProducts(items)
        })
    }

    update(): void {
        this.removeMonitor$();
        this.monitor$ = this.global.tenant.pipe(switchMap(tenant => {
            this.tenantId = tenant?.tenant_id;
            return this.productService.qry({
                user_id: this.global.user.getValue()?.user_id,
                tenant_id: tenant?.tenant_id
            })
        })).subscribe((items:any) =>{
            this.updateProducts(items)
        })
    }

    select(product: Product): Observable<boolean> {
        const productId = this.global.product_id;
        const proMenus = this.global.proMenus.getValue();

        if (productId === product.product_id && proMenus?.length > 0) {
            this.global.updateMenus(proMenus);
            return of(true);
        }

        const user: User = Object.assign({}, this.global.user.getValue());
        user.product_id = product.product_id;
        return this.productService.updateCurrentProduct(this.global.user_id, product.product_id)
            .pipe(
                tap(() => this.global.updateUser(user)),
                switchMap(() => this.productGlobal.initGlobal(product.product_id, this.global)),
                mapTo(true)
            );
    }

    sort(product: Product) {
        const products = this.products.getValue();
        const index = products.findIndex(v => v.product_id === product?.product_id);
        products.splice(index, 1);
        products.unshift(product);
        this.products.next(products);
    }

    private removeMonitor$(): void {
        if (isPresent(this.monitor$)) {
            this.monitor$.unsubscribe();
        }
    }

    stop(): void {
        this.tenantId = null;
        this.removeMonitor$();
    }
}
