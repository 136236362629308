import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UIPageService } from 'app/ui/page/ui.page.service';
import { isComp, parseParams, parseScript, UIStep } from 'app/ui/page/step/ui.page.step.model';

@Injectable()
export class UIPageParamsSelectService {

    options: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    loading = false;

    constructor(private service: UIPageService) {
    }

    init(items: UIStep[] = []): void {
        if (this.loading) {
            return;
        }
        if (items.every(item => !isComp(item))) {
            this.options.next([]);
            return;
        }
        const options: any[] = [];
        this.loading = true;
        const ids = items.filter(item => isComp(item)).map(item => item.target).join(',');
        this.service.qryByIds(ids).subscribe(comps => {
            const paramsMap = new Map<number, Array<string>>();
            comps.forEach(comp => {
                const steps = parseScript(comp.script)?.steps ?? [];
                paramsMap.set(comp.component_id, parseParams(steps));
            });
            items.forEach((item, index) => {
                if (!isComp(item) || !paramsMap.get(+item.target)?.length) {
                    return;
                }
                options.push({
                    label: `${index + 1}-${item.value}`,
                    children: paramsMap.get(+item.target).map(param => ({
                        label: `${index + 1}-${item.value}@${param}`,
                        value: `${item.id}@${param}`
                    }))
                })
            })
            this.options.next(options);
            this.loading = false;
        })
    }

    clear(): void {
        this.loading = false;
        this.options.next([]);
    }
}