import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnumSelectService } from './enum.select.service';


@Component({
    selector: 'enum-select', //tslint:disable-line
    templateUrl: 'enum.select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => EnumSelectComponent),
        multi: true
    }]
})

export class EnumSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @Input() name: string;
    @Input() disabled: boolean;
    @Input() formatter: (_: any) => string;

    private onChange: (_: any) => void;

    private _enum: any;
    private _enums: any[] = [];

    constructor(private cdRef: ChangeDetectorRef, private service: EnumSelectService) {
        this.formatter = (_: any) => _;
    }

    get enum(): any {
        return this._enum;
    }

    set enum(value: any) {
        if (this.enum === value) {
            return;
        }
        this.onChange(value);
        this._enum = value;
        this.cdRef.markForCheck();
    }

    get enums():any[] {
        return this._enums
    }

    @Input()
    set enums(value: any[]) {
        this._enums = this.service.filter(value);
        this.cdRef.markForCheck();
    }

    ngOnInit() {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this._enum = obj;
        this.cdRef.markForCheck();
    }

    ngOnDestroy() {
    }
}
