import { NgModule } from '@angular/core';
import { AutoLoginComponent } from './auto.login.component';
import { route } from './auto.login.route';

@NgModule({
    imports: [
        route
    ],
    declarations: [
        AutoLoginComponent
    ]
})
export class AutoLoginModule {
}