import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ScrollbarComponent } from './scrollbar.component';
import { ScrollbarEvents } from './scrollbar.model';

@Component({
    selector: 'scroll-top', //tslint:disable-line
    templateUrl: 'scroll.top.component.html'
})

export class ScrollTopComponent implements OnInit, OnDestroy {

    @Input() minTop: number;

    private scrollTop: number;

    constructor(private scrollbar: ScrollbarComponent) {
        this.scrollTop = 0;
    }

    ngOnInit() {
        this.scrollbar.addScrollListener(ScrollbarEvents.SCROLL_Y, (event) => this.scrollTop = event.target.scrollTop);
    }

    @HostBinding('class.d-none')
    get hide(): boolean {
        return this.scrollTop <= this.minTop;
    }

    @HostListener('click')
    private scrollToTop(): void {
        this.scrollbar.scrollToTop(0, 1);
    }


    ngOnDestroy() {
    }
}
