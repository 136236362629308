import {
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    ConnectedOverlayPositionChange,
    ConnectionPositionPair,
    HorizontalConnectionPos,
    VerticalConnectionPos
} from '@angular/cdk/overlay';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { slideMotion } from '../animation';
import { isPresent } from '../facade';
import { DateFnsCalendar } from './calendar';
import { DateHelperService } from './datepicker-helper';
import { DatepickerService } from './datepicker.service';
import { RangePopupComponent } from './popup/range-popup.component';
import { RangePartType } from './standard-types';
import { DEFAULT_OVERLAY_POSITION } from './util';

@Component({
    selector: 'ai-picker',
    exportAs: 'picker',
    templateUrl: 'picker.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [slideMotion]
})
export class PickerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input() isRange: boolean = false;
    @Input() disabled: boolean;
    @Input() placeholder: string | string[];
    @Input() format: string;
    @Input() dropdownClassName: string;

    @Output() readonly focusChange = new EventEmitter<boolean>();
    @Output() readonly valueChange = new EventEmitter<DateFnsCalendar | DateFnsCalendar[] | null>();
    @Output() readonly openChange = new EventEmitter<boolean>(); // Emitted when overlay's open state change

    @ViewChild(CdkConnectedOverlay, { static: false })
    private cdkConnectedOverlay: CdkConnectedOverlay;

    @ViewChildren('pickerInput')
    pickerInput: QueryList<ElementRef<HTMLInputElement>>;

    @ContentChild(RangePopupComponent) panel: RangePopupComponent;

    focus: boolean;
    origin: CdkOverlayOrigin;
    inputSize: number;
    inputValue: string | string[];
    activeBarStyle: object = {};
    animationOpenState = false;
    overlayOpen: boolean = false;
    overlayPositions: ConnectionPositionPair[] = DEFAULT_OVERLAY_POSITION;

    currentPositionX: HorizontalConnectionPos = 'start';
    currentPositionY: VerticalConnectionPos = 'bottom';

    private inputWidth: number;
    private arrowLeft: number;
    private destroy$ = new Subject();

    constructor(
        private elementRef: ElementRef,
        private cdRef: ChangeDetectorRef,
        private dateHelper: DateHelperService,
        public datePickerService: DatepickerService
    ) {
        this.origin = new CdkOverlayOrigin(this.elementRef);
        this.updateInputValue();
    }

    get realOpenState(): boolean {
        return this.overlayOpen;
    }

    get isEmpty(): boolean {
        let value = this.datePickerService.value;
        if (!isPresent(value)) {
            return true;
        }
        return this.isRange && (!Array.isArray(value) || value.every(val => !isPresent(val)));
    }

    ngOnInit(): void {
        this.inputSize = Math.max(10, this.format.length) + 2;

        this.datePickerService.valueChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.updateInputValue();
            this.cdRef.markForCheck();
        });
    }

    ngAfterViewInit(): void {
        this.datePickerService.inputPartChange$.pipe(takeUntil(this.destroy$)).subscribe(partType => {
            if (partType) {
                this.datePickerService.activeInput = partType;
            }
            this.datePickerService.arrowPositionStyle = {
                left: this.datePickerService.activeInput === 'left' ? '0px' : `${this.arrowLeft}px`
            };
            this.activeBarStyle = {
                ...this.activeBarStyle,
                ...this.datePickerService.arrowPositionStyle,
                width: `${this.inputWidth}px`
            };
            if (document.activeElement !== this.getInput(this.datePickerService.activeInput)) {
                this.focusInput();
            }
            this.panel?.cdRef.markForCheck();
            this.cdRef.markForCheck();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.open) {
            this.animationStart();
        }
    }

    private resetInputWidth(): void {
        if (!this.isRange) {
            return;
        }
        const padding = 16;
        const iconWidth = 14;
        this.inputWidth = this.pickerInput?.first?.nativeElement?.offsetWidth || 0;
        this.arrowLeft = this.inputWidth + padding + iconWidth;
    }

    private getInput(partType?: RangePartType): HTMLInputElement {
        return this.isRange ? (partType === 'left' ? this.pickerInput.first.nativeElement : this.pickerInput.last.nativeElement) : this.pickerInput.first.nativeElement;
    }

    private focusInput(): void {
        this.getInput(this.datePickerService.activeInput).focus();
    }

    onFocus(partType?: RangePartType): void {
        this.resetInputWidth();
        if (partType) {
            this.datePickerService.inputPartChange$.next(partType);
        }
        this.focus = true;
    }

    onBlur(): void {
        this.focus = false;
    }

    showOverlay(): void {
        if (this.realOpenState) {
            return;
        }
        this.overlayOpen = true;
        this.animationStart();
        this.openChange.emit(true);
        setTimeout(() => {
            if (this.cdkConnectedOverlay && this.cdkConnectedOverlay.overlayRef) {
                this.cdkConnectedOverlay.overlayRef.updatePosition();
            }
        });
    }

    hideOverlay(): void {
        if (!this.realOpenState) {
            return;
        }
        this.overlayOpen = false;
        this.openChange.emit(false);
    }

    onClickInputBox(partType?: RangePartType): void {
        if (!this.disabled) {
            this.showOverlay();
        }
        this.onFocus(partType);
    }

    onClickBackdrop(): void {
        if (this.panel.isAllowed(this.datePickerService.value, true)) {
            this.updateInputValue();
            this.datePickerService.emitValue$.next(undefined);
        } else {
            this.datePickerService.setValue(this.datePickerService.initialValue);
            this.hideOverlay();
        }
    }

    onOverlayDetach(): void {
        this.hideOverlay();
    }

    onOverlayKeydown(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.datePickerService.setValue(this.datePickerService.initialValue);
        }
    }

    onPositionChange(position: ConnectedOverlayPositionChange): void {
        this.currentPositionX = position.connectionPair.originX;
        this.currentPositionY = position.connectionPair.originY;
        this.cdRef.detectChanges();
    }

    onClickClear(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.datePickerService.setValue(this.isRange ? [] : null);
        this.datePickerService.emitValue$.next(undefined);
    }

    updateInputValue(): void {
        const newValue = this.datePickerService.value;
        if (this.isRange) {
            this.inputValue = newValue ? (newValue as DateFnsCalendar[]).map(v => this.formatValue(v)) : ['', ''];
        } else {
            this.inputValue = this.formatValue(newValue as DateFnsCalendar);
        }
    }

    formatValue(value: DateFnsCalendar): string {
        return this.dateHelper.format(value && (value as DateFnsCalendar).nativeDate, this.format);
    }

    onInputKeyup(event: Event, isEnter: boolean = false): void {
        if (isEnter && !this.realOpenState) {
            this.showOverlay();
            return;
        }
        const date = this.checkValidInputDate(event.target);
        if (this.panel && date) {
            this.panel.changeValueFromSelect(date, isEnter);
        }
    }

    private checkValidInputDate(inputTarget: EventTarget): DateFnsCalendar | null {
        const input = (inputTarget as HTMLInputElement).value;
        const date = new DateFnsCalendar(this.dateHelper.parse(input, this.format));

        if (!date.isValid() || input !== this.dateHelper.format(date.nativeDate, this.format)) {
            return null;
        }

        return date;
    }

    getPlaceholder(partType?: RangePartType): string {
        return (this.isRange ? this.placeholder?.[this.datePickerService.getActiveIndex(partType)] : (this.placeholder as string)) ?? '';
    }

    animationStart(): void {
        if (this.realOpenState) {
            this.animationOpenState = true;
        }
    }

    animationDone(): void {
        if (this.realOpenState) {
            return;
        }
        this.animationOpenState = false;
        this.cdRef.markForCheck();
    }


    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
