import { RouterModule } from '@angular/router';
import { AppConfigGuardService } from 'app/guard/app.config.guard.service';
import { LoginModeGuardService } from 'app/guard/login.mode.guard.service';
import { LoginVerifyGuardService } from 'app/guard/login.verify.guard.service';
import { PublicKeyGuardService } from 'app/guard/public.key.guard.service';
import { BasicResolve } from 'app/login/resolve/basic.resolve';
import { SkinResolve } from 'app/skin.resolve';
import { LoginComponent } from './login.component';

export const loginRoute = RouterModule.forChild([{
    path: 'login',
    resolve: {
        basic: BasicResolve,
        skin: SkinResolve
    },
    canActivate: [
        AppConfigGuardService,
        LoginModeGuardService,
        LoginVerifyGuardService,
        PublicKeyGuardService
    ],
    component: LoginComponent
}]);
