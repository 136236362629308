import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SysParamService } from 'core';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SkinResolve implements Resolve<any> {

    constructor(private paramService: SysParamService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        // return this.paramService.qry(SysParamType.SKIN).pipe(tap((items: Array<any>) => {
        //     if (items.length === 0) {
        //         return;
        //     }
        //     items.forEach(item => setSkinColor({ key: item.param_key, color: item.param_value }));
        // }));
        return of({});
    }

}
