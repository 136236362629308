import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { isPresent } from '../facade';

@Injectable({ providedIn: 'root' })
export class BaseUrlStrategy {

    constructor(private platformLocation: PlatformLocation) {
    }

    path(url: string): string {
        if (!isPresent(url)) {
            return url;
        }
        if (!url.startsWith('/')) {
            return url;
        }
        let baseHref = this.platformLocation.getBaseHrefFromDOM();
        if (baseHref === '/' || url.startsWith(baseHref)) {
            return url;
        }
        return `${baseHref}${url}`.replace('//', '/');
    }
}
