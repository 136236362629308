import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ai-tree-node-checkbox',
    templateUrl: 'tree-node-checkbox.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        '[class.select-tree-checkbox]': `selectMode`,
        '[class.tree-checkbox]': `!selectMode`
    }
})
export class TreeNodeCheckboxComponent {

    @Output() readonly checkBoxChange = new EventEmitter<boolean>();

    @Input() selectMode = false;
    @Input() isChecked?: boolean;
    @Input() isHalfChecked?: boolean;
    @Input() isDisabled?: boolean;
    @Input() isDisableCheckbox?: boolean;

    toggleChecked(): void {
        this.checkBoxChange.next(this.isChecked);
    }

}
