import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { isPresent } from '../facade';

@Component({
    selector: 'max-char', //tslint:disable-line
    templateUrl: 'max.char.component.html',
    styleUrls: ['max.char.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaxCharComponent implements OnInit {

    @Input() text: string;
    @Input() max: number;

    constructor() {
    }

    ngOnInit() {
    }

    get maxChar(): string {
        if (!isPresent(this.text) || !isPresent(this.max) || this.text.length <= this.max) {
            return this.text;
        }

        return `${this.text.substr(0, this.max)}...`;
    }

    get isExceed(): boolean {
        return isPresent(this.text) && isPresent(this.max) && this.text.length > this.max;
    }
}
