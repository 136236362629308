import { Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DateFnsCalendar } from './calendar';
import { PanelSelector } from './interface';
import { PanelMode } from './standard-types';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class AbstractPanelHeader implements OnInit, OnChanges {

    selectors: PanelSelector[] = [];

    @Input() value: DateFnsCalendar;
    @Input() showSuperPreBtn: boolean = true;
    @Input() showSuperNextBtn: boolean = true;
    @Input() showPreBtn: boolean = true;
    @Input() showNextBtn: boolean = true;

    @Output() readonly panelModeChange = new EventEmitter<PanelMode>();
    @Output() readonly valueChange = new EventEmitter<DateFnsCalendar>();

    abstract getSelectors(): PanelSelector[];

    superPrevious(): void {
        this.changeValue(this.value.addYears(-1));
    }

    superNext(): void {
        this.changeValue(this.value.addYears(1));
    }

    previous(): void {
        this.changeValue(this.value.addMonths(-1));
    }

    next(): void {
        this.changeValue(this.value.addMonths(1));
    }

    changeValue(value: DateFnsCalendar): void {
        if (this.value === value) {
            return;
        }
        this.value = value;
        this.valueChange.emit(this.value);
        this.render();
    }

    changeMode(mode: PanelMode): void {
        this.panelModeChange.emit(mode);
    }

    private render(): void {
        if (this.value) {
            this.selectors = this.getSelectors();
        }
    }

    ngOnInit(): void {
        if (!this.value) {
            this.value = new DateFnsCalendar(); // Show today by default
        }
        this.selectors = this.getSelectors();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.render();
        }
    }
}
