import { Injectable } from '@angular/core';
import { ErrorFormatter } from './ErrorFormatter';

const ERROR_MESSAGES: any = {
    required: '不能为空',
    email: '邮箱格式不正确'
};

@Injectable()
export class CommonErrorFormatter implements ErrorFormatter {


    support(key: string): boolean {
        return ERROR_MESSAGES.hasOwnProperty(key);
    }

    format(key: string, value: string): string {
        return ERROR_MESSAGES[key];
    }


}
