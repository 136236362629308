import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OutletModule } from '../outlet/outlet.module';
import { MenuDividerDirective } from './menu-divider.directive';
import { MenuGroupComponent } from './menu-group.component';
import { MenuItemDirective } from './menu-item.directive';
import { MenuDirective } from './menu.directive';
import { SubmenuInlineChildComponent } from './submenu-inline-child.component';
import { SubmenuNoneInlineChildComponent } from './submenu-non-inline-child.component';
import { SubMenuTitleComponent } from './submenu-title.component';
import { SubMenuComponent } from './submenu.component';

@NgModule({
    imports: [
        CommonModule,
        PlatformModule,
        OverlayModule,
        OutletModule
    ],
    exports: [
        MenuDirective,
        MenuItemDirective,
        SubMenuComponent,
        MenuDividerDirective,
        MenuGroupComponent
    ],
    declarations: [
        MenuDirective,
        MenuItemDirective,
        SubMenuComponent,
        MenuDividerDirective,
        MenuGroupComponent,
        SubMenuTitleComponent,
        SubmenuInlineChildComponent,
        SubmenuNoneInlineChildComponent
    ]
})
export class MenuModule {
}
