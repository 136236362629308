import { JSONBig, validateError } from 'share';
import {
    DefaultJsonValueVisitor,
    DefaultJsonVisitor,
    JsonValueVisitor,
    JsonVisitor,
    visitJson,
    visitValue
} from './json.visitor';
import { Node } from './node';
import { NodeFormatter } from './node.formatter';

export class JsonFormatter implements NodeFormatter {

    constructor(private visitor: JsonVisitor = new DefaultJsonVisitor(), private valueVisitor: JsonValueVisitor = new DefaultJsonValueVisitor()) {
    }

    parse(text: string, node: Node): void {
        let obj: any;
        try {
            obj = JSONBig.parse(text || '{}');
        } catch (error) {
            console.error(error);
            validateError('解析json失败');
        }
        visitJson(this.visitor, obj, node);
    }

    stringify(node: Node): string {
        return JSONBig.stringify(visitValue(this.valueVisitor, node), null, '\t');
    }

}
