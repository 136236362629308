import { ChangeDetectionStrategy, Component, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { EMPTY_FUNCTION } from '../../facade';
import { AbstractTable } from '../abstract-table';
import { DateBodyRow, DateCell, DecadeCell } from '../interface';

const MAX_ROW = 4;
const MAX_COL = 3;

@Component({
    selector: 'decade-table', // tslint:disable-line
    exportAs: 'decadeTable',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../abstract-table.html'
})
export class DecadeTableComponent extends AbstractTable implements OnChanges {

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value || changes.disabledDate || changes.activeDate) {
            this.render();
        }
    }

    get startYear(): number {
        return parseInt(`${this.activeDate.getYear() / 100}`, 10) * 100;
    }

    get endYear(): number {
        return this.startYear + 99;
    }

    makeHeadRow(): DateCell[] {
        return [];
    }

    makeBodyRows(): DateBodyRow[] {
        const decades: DateBodyRow[] = [];
        const currentYear = this.value && this.value.getYear();
        const startYear = this.startYear;
        const endYear = this.endYear;
        const previousYear = startYear - 10;

        let index = 0;
        for (let rowIndex = 0; rowIndex < MAX_ROW; rowIndex++) {
            const row: DecadeCell[] = [];
            for (let colIndex = 0; colIndex < MAX_COL; colIndex++) {
                const start = previousYear + index * 10;
                const end = previousYear + index * 10 + 9;
                const content = `${start}-${end}`;

                const cell: DecadeCell = {
                    value: this.activeDate.setYear(start).nativeDate,
                    content,
                    title: content,
                    isDisabled: false,
                    isSelected: currentYear >= start && currentYear <= end,
                    isLowerThanStart: end < startYear,
                    isBiggerThanEnd: start > endYear,
                    onClick: () => this.chooseDecade(start),
                    onMouseEnter: EMPTY_FUNCTION
                };

                cell.classMap = this.getClassMap(cell);
                index++;
                row.push(cell);
            }

            decades.push({ dateCells: row });
        }
        return decades;
    }

    getClassMap(cell: DecadeCell): { [key: string]: boolean } {
        return {
            [`picker-cell`]: true,
            [`picker-cell-in-view`]: !cell.isBiggerThanEnd && !cell.isLowerThanStart,
            [`picker-cell-selected`]: cell.isSelected,
            [`picker-cell-disabled`]: cell.isDisabled
        };
    }

    private chooseDecade(year: number): void {
        this.value = this.activeDate.setYear(year);
        this.valueChange.emit(this.value);
    }
}
