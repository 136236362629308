import { Directive, ExistingProvider, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { FormValidators } from './validators';

const MOBILE_VALIDATOR: ExistingProvider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MobileValidator),
    multi: true
};

@Directive({
    selector: '[mobile][formControlName],[mobile][formControl],[mobile][ngModel]', // tslint:disable-line
    providers: [MOBILE_VALIDATOR]
})
export class MobileValidator implements Validator { // tslint:disable-line
    private _enabled: boolean;
    private _onChange: () => void;

    @Input()
    set mobile(value: boolean | string) {
        this._enabled = value === '' || value === true || value === 'true';
        if (this._onChange) {
            this._onChange();
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this._enabled ? FormValidators.mobile(c) : null;
    }

    registerOnValidatorChange(fn: () => void): void {
        this._onChange = fn;
    }
}

const EMAIL_VALIDATOR: ExistingProvider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EmailValidator),
    multi: true
};

@Directive({
    selector: '[email][formControlName],[email][formControl],[email][ngModel]', // tslint:disable-line
    providers: [EMAIL_VALIDATOR]
})
export class EmailValidator implements Validator { // tslint:disable-line
    private _enabled: boolean;
    private _onChange: () => void;

    @Input()
    set email(value: boolean | string) {
        this._enabled = value === '' || value === true || value === 'true';
        if (this._onChange) {
            this._onChange();
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this._enabled ? FormValidators.email(c) : null;
    }

    registerOnValidatorChange(fn: () => void): void {
        this._onChange = fn;
    }
}


const URL_VALIDATOR: ExistingProvider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => UrlValidator),
    multi: true
};

@Directive({
    selector: '[url][formControlName],[url][formControl],[url][ngModel]', // tslint:disable-line
    providers: [URL_VALIDATOR]
})
export class UrlValidator implements Validator { // tslint:disable-line
    private _enabled: boolean;
    private _onChange: () => void;

    @Input()
    set url(value: boolean | string) {
        this._enabled = value === '' || value === true || value === 'true';
        if (this._onChange) {
            this._onChange();
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this._enabled ? FormValidators.url(c) : null;
    }

    registerOnValidatorChange(fn: () => void): void {
        this._onChange = fn;
    }
}


const MAXLENGTH_VALIDATOR: ExistingProvider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MaxlengthValidator),
    multi: true
};

@Directive({
    selector: '[maxChars][formControlName],[maxChars][formControl],[maxChars][ngModel]', // tslint:disable-line
    providers: [MAXLENGTH_VALIDATOR]
})
export class MaxlengthValidator implements Validator, OnInit, OnChanges { // tslint:disable-line

    @Input() maxChars: number;

    private validator: ValidatorFn;
    private onChange: () => void;

    ngOnInit() {
        this.validator = FormValidators.maxlength(this.maxChars);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let key in changes) {
            if (key !== 'maxChars') {
                continue;
            }
            this.validator = FormValidators.maxlength(changes[key].currentValue);
            if (this.onChange) {
                this.onChange();
            }
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this.validator(c);
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn;
    }
}


const DIGITS_VALIDATOR: ExistingProvider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DigitsValidator),
    multi: true
};

@Directive({
    selector: '[digits][formControlName],[digits][formControl],[digits][ngModel]', // tslint:disable-line
    providers: [DIGITS_VALIDATOR]
})
export class DigitsValidator implements Validator { // tslint:disable-line

    validate(c: AbstractControl): { [key: string]: any } {
        return FormValidators.digits(c);
    }
}


const DECIMAL_VALIDATOR: ExistingProvider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DecimalValidator),
    multi: true
};

@Directive({
    selector: '[decimal][formControlName],[decimal][formControl],[decimal][ngModel]', // tslint:disable-line
    providers: [DECIMAL_VALIDATOR]
})
export class DecimalValidator implements Validator { // tslint:disable-line
    validate(c: AbstractControl): { [key: string]: any } {
        return FormValidators.decimal(c);
    }
}

const MIN_VALIDATOR: ExistingProvider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MinValidator),
    multi: true
};

@Directive({
    selector: '[min][formControlName],[min][formControl],[min][ngModel]', // tslint:disable-line
    providers: [MIN_VALIDATOR]
})
export class MinValidator implements Validator, OnInit { // tslint:disable-line
    @Input() min: number;
    private validator: ValidatorFn;

    ngOnInit() {
        this.validator = FormValidators.min(this.min);
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this.validator(c);
    }
}

const MAX_VALIDATOR: ExistingProvider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MaxValidator),
    multi: true
};

@Directive({
    selector: '[max][formControlName],[max][formControl],[max][ngModel]', // tslint:disable-line
    providers: [MAX_VALIDATOR]
})
export class MaxValidator implements Validator, OnInit { // tslint:disable-line
    @Input() max: number;
    private validator: ValidatorFn;

    ngOnInit() {
        this.validator = FormValidators.max(this.max);
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this.validator(c);
    }
}