import { TreeNode } from './tree-base-node';

export interface FormatEmitEvent {
    eventName: string;
    node?: TreeNode | null;
    event?: MouseEvent | DragEvent | null;
    dragNode?: TreeNode;
    selectedKeys?: TreeNode[];
    checkedKeys?: TreeNode[];
    matchedKeys?: TreeNode[];
    nodes?: TreeNode[];
    keys?: string[];
}

export interface FormatBeforeDropEvent {
    dragNode: TreeNode;
    node: TreeNode;
    pos: number;
}

export interface TreeNodeBaseComponent {
    markForCheck(): void;
}
