import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges, TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { SelectItemInterface, SelectModeType } from './select.types';

@Component({
    selector: 'ai-option-container',
    exportAs: 'optionContainer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    preserveWhitespaces: false,
    templateUrl: 'option-container.component.html',
    host: {
        '[class.select-dropdown]': 'true'
    }
})
export class OptionContainerComponent implements OnChanges {

    @Input() activatedValue: any | null = null;
    @Input() listOfSelectedValue: any[] = [];
    @Input() dropdownRender: TemplateRef<any> | null = null;
    @Input() compareWith: (o1: any, o2: any) => boolean;
    @Input() mode: SelectModeType = 'default';
    @Input() matchWidth = true;
    @Input() listOfContainerItem: SelectItemInterface[] = [];
    @Output() readonly itemClick = new EventEmitter<any>();
    @Output() readonly scrollToBottom = new EventEmitter<void>();
    @ViewChild(CdkVirtualScrollViewport, { static: true }) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
    private scrolledIndex = 0;
    readonly itemSize = 32;
    readonly maxItemLength = 8;

    onItemClick(value: any): void {
        this.itemClick.emit(value);
    }

    trackValue(_index: number, option: SelectItemInterface): any {
        return option.key;
    }

    onScrolledIndexChange(index: number): void {
        this.scrolledIndex = index;
        if (index === this.listOfContainerItem.length - this.maxItemLength) {
            this.scrollToBottom.emit();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { listOfContainerItem, activatedValue } = changes;
        if (listOfContainerItem || activatedValue) {
            const index = this.listOfContainerItem.findIndex(item => this.compareWith(item.key, this.activatedValue));
            if (index < this.scrolledIndex || index >= this.scrolledIndex + this.maxItemLength) {
                this.cdkVirtualScrollViewport.scrollToIndex(index || 0);
            }
        }
    }
}
