import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { formatXml, isPresent } from '../facade';

@Component({
    selector: 'message-format', // tslint:disable-line
    templateUrl: 'msg.format.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MessageFormatComponent implements OnInit, OnDestroy {

    @Input() title: string;

    options: any;
    mode: string;

    private _text: string;

    constructor() {
        this.options = {
            wrap: true,
            autoScrollEditorIntoView: true,
            showGutter: false,
            highlightActiveLine: false,
            maxLines: 10000
        };
    }

    get text(): string {
        return this._text;
    }

    @Input()
    set text(value: string) {
        if (!isPresent(value)) {
            return;
        }
        if (value.trim().startsWith('<')) {
            this._text = formatXml(value);
            this.mode = 'xml';
            return;
        }

        if (value.startsWith('{') || value.startsWith('[')) {
            try {
                this._text = JSON.stringify(JSON.parse(value), null, '\t');
                this.mode = 'json';
                return;
            } catch (e) {

            }
        }
        this._text = value;
        this.mode = 'text';

    }

    ngOnInit() {
    }


    ngOnDestroy() {
    }
}
