import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIPage } from 'app/ui/page/ui.page.model';
import { Initializer } from 'core';
import { Observable, of } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { isPresent } from 'share';
import { PageVersionService } from '../version/page.version.service';

@Injectable()
export class RevertInitializer implements Initializer<UIPage> {

    constructor(private route: ActivatedRoute, private versionService: PageVersionService) {
    }

    init(context: UIPage): Observable<any> {
        let version_id = this.route.snapshot.queryParams.version_id;
        if (!isPresent(version_id)) {
            return of(context);
        }
        return this.versionService.qry(context.component_id, version_id)
            .pipe(tap(item => context.script = item.script), mapTo(context));
    }

}
