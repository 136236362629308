import { ConfigurableFocusTrapFactory, FocusTrap } from '@angular/cdk/a11y';
import { ESCAPE } from '@angular/cdk/keycodes';
import { Overlay, OverlayConfig, OverlayKeyboardDispatcher, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScrollbarComponent } from '../scrollbar';
import { DrawerOptionsOfComponent, DrawerPlacement } from './drawer-options';
import { DrawerRef } from './drawer-ref';

export const DRAWER_ANIMATE_DURATION = 300;

@Component({
    selector: 'ai-drawer',
    exportAs: 'drawer',
    templateUrl: 'drawer.component.html',
    preserveWhitespaces: false,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerComponent<T = any, R = any, D = any> extends DrawerRef<R> implements OnInit, OnDestroy, AfterViewInit, DrawerOptionsOfComponent {

    @Input() content: TemplateRef<{ $implicit: D; drawerRef: DrawerRef<R> }> | Type<T>;
    @Input() maskClosable: boolean = true;
    @Input() mask: boolean = true;
    @Input() keyboard: boolean = true;
    @Input() placement: DrawerPlacement = 'right';
    @Input() zIndex = 1000;
    @Input() offsetX = 0;
    @Input() offsetY = 0;
    @Input() drawerClass: string;
    @Input() contentStyle?: object;
    @Input() scrollable: boolean;

    inputs: { [key: string]: any };

    @ViewChild('drawerTemplate', { static: true }) drawerTemplate: TemplateRef<void>;
    @ViewChild(CdkPortalOutlet, { static: false }) bodyPortalOutlet: CdkPortalOutlet;
    @ViewChild(ScrollbarComponent, { static: false }) scrollbar: ScrollbarComponent;

    @Output() ngClose = new EventEmitter<R>();

    confirmClose = new EventEmitter<R>();

    isOpen = false;
    templateContext: { $implicit: D; drawerRef: DrawerRef<R> };

    private previouslyFocusedElement: HTMLElement;
    private overlayRef: OverlayRef | null;
    private focusTrap: FocusTrap;

    private _afterClose = new Subject<R>();
    private _result = new Subject<R>();
    private _destroy$ = new Subject<void>();

    constructor(private renderer: Renderer2,
                private overlay: Overlay,
                private injector: Injector,
                private changeDetectorRef: ChangeDetectorRef,
                private focusTrapFactory: ConfigurableFocusTrapFactory,
                private viewContainerRef: ViewContainerRef,
                private overlayKeyboardDispatcher: OverlayKeyboardDispatcher) {
        super();
    }

    get offsetTransform(): string | null {
        if (!this.isOpen || this.offsetX + this.offsetY === 0) {
            return null;
        }
        switch (this.placement) {
            case 'left':
                return `translateX(${this.offsetX}px)`;
            case 'right':
                return `translateX(-${this.offsetX}px)`;
            case 'top':
                return `translateY(${this.offsetY}px)`;
            case 'bottom':
                return `translateY(-${this.offsetY}px)`;
        }
    }

    get transform(): string | null {
        if (this.isOpen) {
            return null;
        }

        switch (this.placement) {
            case 'left':
                return `translateX(-100%)`;
            case 'right':
                return `translateX(100%)`;
            case 'top':
                return `translateY(-100%)`;
            case 'bottom':
                return `translateY(100%)`;
        }
    }

    get afterClose(): Observable<R> {
        return this._afterClose.asObservable();
    }

    get result(): Observable<R> {
        return this._result.asObservable();
    }

    isTemplateRef(value: {}): boolean {
        return value instanceof TemplateRef;
    }

    ngOnInit(): void {
        this.attachOverlay();
        this.updateOverlayStyle();
        this.updateBodyOverflow();
        this.templateContext = { $implicit: this.inputs as any, drawerRef: this as DrawerRef<R> };
        this.changeDetectorRef.detectChanges();
        this.confirmClose.pipe(takeUntil(this._destroy$)).subscribe(r => this._close(r));
    }

    ngAfterViewInit(): void {
        this.attachBodyContent();
        setTimeout(() => this.open());
    }

    dismiss(): void {
        this._close(null);
    }

    close(result?: R): void {
        this.ngClose.next(result);
    }

    private _close(result?: any): void {
        this.isOpen = false;
        this.updateOverlayStyle();
        this.overlayKeyboardDispatcher.remove(this.overlayRef);
        this.changeDetectorRef.detectChanges();
        setTimeout(() => {
            this.updateBodyOverflow();
            // this.restoreFocus();
            this._afterClose.next(result);
            this._afterClose.complete();
            if (result === null) {
                return;
            }
            this._result.next(result);
            this._result.complete();
        }, DRAWER_ANIMATE_DURATION);
    }

    open(): void {
        this.isOpen = true;
        this.overlayKeyboardDispatcher.add(this.overlayRef);
        this.updateOverlayStyle();
        this.updateBodyOverflow();
        // this.savePreviouslyFocusedElement();
        // this.trapFocus();
        this.changeDetectorRef.detectChanges();
    }

    maskClick(): void {
        if (this.maskClosable && this.mask) {
            this.dismiss();
        }
    }

    private attachBodyContent(): void {
        this.bodyPortalOutlet.dispose();

        if (this.content instanceof Type) {
            const childInjector = Injector.create({
                providers: [
                    { provide: DrawerRef, useValue: this },
                    { provide: ScrollbarComponent, useValue: this.scrollbar }
                ],
                parent: this.injector
            });
            const componentPortal = new ComponentPortal<T>(this.content, null, childInjector);
            const componentRef = this.bodyPortalOutlet.attachComponentPortal(componentPortal);
            Object.assign(componentRef.instance, this.inputs);
            componentRef.changeDetectorRef.detectChanges();
        }
    }

    private attachOverlay(): void {
        this.overlayRef = this.overlayRef ?? this.overlay.create(new OverlayConfig({
            disposeOnNavigation: true,
            positionStrategy: this.overlay.position().global(),
            scrollStrategy: this.overlay.scrollStrategies.block()
        }));
        if (this.overlayRef.hasAttached()) {
            return;
        }
        this.overlayRef.attach(new TemplatePortal(this.drawerTemplate, this.viewContainerRef));
        this.overlayRef.keydownEvents().pipe(takeUntil(this._destroy$)).subscribe((event: KeyboardEvent) => {
            if (event.keyCode === ESCAPE && this.isOpen && this.keyboard) {
                this.dismiss();
            }
        });
    }

    private updateOverlayStyle(): void {
        if (this.overlayRef && this.overlayRef.overlayElement) {
            this.renderer.setStyle(this.overlayRef.overlayElement, 'pointer-events', this.isOpen ? 'auto' : 'none');
        }
    }

    private updateBodyOverflow(): void {
        if (!this.overlayRef) {
            return;
        }
        if (this.isOpen) {
            this.overlayRef.getConfig().scrollStrategy?.enable();
        } else {
            this.overlayRef.getConfig().scrollStrategy?.disable();
        }
    }

    // savePreviouslyFocusedElement(): void {
    //     if (this.previouslyFocusedElement) {
    //         return;
    //     }
    //     this.previouslyFocusedElement = document.activeElement as HTMLElement;
    //     if (typeof this.previouslyFocusedElement?.blur === 'function') {
    //         this.previouslyFocusedElement?.blur();
    //     }
    // }
    //
    // private trapFocus(): void {
    //     if (!this.focusTrap && this.overlayRef && this.overlayRef.overlayElement) {
    //         this.focusTrap = this.focusTrapFactory.create(this.overlayRef?.overlayElement);
    //         this.focusTrap.focusInitialElement();
    //     }
    // }
    //
    // private restoreFocus(): void {
    //     if (typeof this.previouslyFocusedElement?.focus === 'function') {
    //         this.previouslyFocusedElement?.focus();
    //     }
    //     this.focusTrap?.destroy();
    // }

    ngOnDestroy(): void {
        this._destroy$.next(undefined);
        this._destroy$.complete();

        this.overlayRef?.dispose();
        this.overlayRef = null;
    }
}
