import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UIPage } from 'app/ui/page/ui.page.model';
import { parseInParam, parseOutParam } from 'app/ui/page/util';
import { Widget } from 'app/ui/widget/ui.widget.model';
import { UIWidgetService } from 'app/ui/widget/ui.widget.service';
import { AceEditorDirective, isPresent } from 'share';

@Component({
    selector: 'ai-page-script-mode',
    templateUrl: 'page.script.mode.component.html',
})

export class PageScriptModeComponent implements OnInit, OnDestroy {

    @Input() editable: boolean;
    @Input() item: UIPage;

    @ViewChild(AceEditorDirective)
    private editor: AceEditorDirective;

    constructor(private widgetService: UIWidgetService) {
    }

    ngOnInit() {
    }

    selectWidget(widget: Widget): void {
        if (!this.editable) {
            return;
        }
        this.widgetService.qryById(widget.element_id).subscribe(item => this.editor.insert(item.script + '\r\n'));
    }

    blur(): void {
        this.compareParams(parseInParam(this.item.script), 'IN');
        this.compareParams(parseOutParam(this.item.script), 'OUT');
    }

    private compareParams(names: Array<string>, direction: string) {
        this.item.component_params = this.item.component_params || [];
        let params = this.item.component_params;

        names.forEach(name => {
            let param = params.find(item => item.param_name === name && item.direction === direction);
            if (!isPresent(param)) {
                params.push({
                    component_id: this.item.component_id,
                    param_name: name,
                    operate_type: 'C',
                    direction,
                    param_type: direction === 'IN' ? 'input' : undefined
                });
            } else if (param.operate_type === 'D') {
                param.operate_type = 'U';
            }
        });

        params.filter(param => param.direction === direction).filter(param => !names.includes(param.param_name)).forEach(param => {
            if (param.operate_type === 'C') {
                params.remove(param);
            } else {
                param.operate_type = 'D';
            }
        });
    }

    ngOnDestroy() {
    }
}
