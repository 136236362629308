import { EditableHolder } from 'app/editable.holder';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { isPresent } from 'share';
import { FilterInitializer } from '../../initializer';
import { ComponentContext } from '../context';

export class EditableInitializer implements FilterInitializer<ComponentContext> {

    init(context: ComponentContext): Observable<any> {
        let holder = environment.injector.get(EditableHolder);
        let holder$ = holder.editable.subscribe(editable => {
            context.instance.editable = editable;
            if (isPresent(context.instance.render)) {
                context.instance.render();
            }
        });
        context.instance.ngAdd$(holder$);
        return of(context);
    }

    support(context: ComponentContext): boolean {
        return context.options.editConfigurable;
    }

}
