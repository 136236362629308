import { Pipe, PipeTransform } from '@angular/core';
import {
    BUG_STATUS_KEYS,
    BUG_STATUS_NAMES,
    BUG_CLOSE_REASON_KEYS,
    BUG_CLOSE_REASON_NAMES,
    MANUAL_TEST_STATUS_KEYS,
    MANUAL_TEST_STATUS_NAMES,
    TEST_AUTO_CASE_RESULT_KEYS,
    TEST_AUTO_CASE_RESULT_NAMES,
    TEST_CASE_RESULT_KEYS,
    TEST_CASE_RESULT_NAMES,
    VERSION_STATE_KEY,
    VERSION_STATE_NAME,
    WORKBENCH_TASK_STATUS_KEYS,
    WORKBENCH_TASK_STATUS_NAMES,
    WORKBENCH_TASK_TYPE_KEYS,
    WORKBENCH_TASK_TYPE_NAMES,
    BUG_WORK_TYPE_KEYS,
    BUG_WORK_TYPE_NAMES,
    CASE_EXPORT_FIELDS_KEYS,
    CASE_EXPORT_FIELDS_NAMES
} from '../constants';


@Pipe({
    name: 'versionState'
})
export class ManualVersionPipe implements PipeTransform {

    transform(state: string): string {
        return VERSION_STATE_NAME[VERSION_STATE_KEY.indexOf(state)];
    }
}

@Pipe({
    name: 'manualTaskState'
})
export class ManualTaskStatusPipe implements PipeTransform {

    transform(status: any): string {
        return MANUAL_TEST_STATUS_NAMES[MANUAL_TEST_STATUS_KEYS.indexOf(status)];
    }
}

@Pipe({
    name: 'taskStatus'
})
export class TaskStatusPipe implements PipeTransform {

    transform(status: any): string {
        return WORKBENCH_TASK_STATUS_NAMES[WORKBENCH_TASK_STATUS_KEYS.indexOf(status)];
    }
}

@Pipe({
    name: 'taskType'
})
export class TaskTypePipe implements PipeTransform {

    transform(status: any): string {
        return WORKBENCH_TASK_TYPE_NAMES[WORKBENCH_TASK_TYPE_KEYS.indexOf(status)];
    }
}

@Pipe({
    name: 'testCaseResult'
})
export class TestCaseResultPipe implements PipeTransform {

    transform(status: any): string {
        return TEST_CASE_RESULT_NAMES[TEST_CASE_RESULT_KEYS.indexOf(status)] ?? TEST_AUTO_CASE_RESULT_NAMES[TEST_AUTO_CASE_RESULT_KEYS.indexOf(status)] ?? '未知';
    }
}

@Pipe({
    name: 'bugStatus'
})
export class BugStatusPipe implements PipeTransform {

    transform(status: any): string {
        return BUG_STATUS_NAMES[BUG_STATUS_KEYS.indexOf(status)];
    }
}

@Pipe({
    name: 'bugCloseReason'
})
export class BugCloseReasonPipe implements PipeTransform {

    transform(reason: string): string {
        return BUG_CLOSE_REASON_NAMES[BUG_CLOSE_REASON_KEYS.indexOf(reason)];
    }
}

@Pipe({
    name: 'bugWorkType'
})
export class BugWorkTypePipe implements PipeTransform {

    transform(reason: string): string {
        return BUG_WORK_TYPE_NAMES[BUG_WORK_TYPE_KEYS.indexOf(reason)];
    }
}


@Pipe({
    name: 'haveAuto'
})
export class HaveAutoPipe implements PipeTransform {

    transform(have_auto: any): string {
        return have_auto ? '已关联' : '未关联';
    }
}

@Pipe({
    name: 'manualCaseField'
})
export class ManualCaseFieldPipe implements PipeTransform {

    transform(reason: string): string {
        return CASE_EXPORT_FIELDS_NAMES[CASE_EXPORT_FIELDS_KEYS.indexOf(reason)];
    }
}
