import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    Input,
    Renderer2,
    TemplateRef,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { zoomBigMotion } from '../animation';

import { isTooltipEmpty, TooltipBaseComponent, TooltipBaseDirective } from './base';

@Directive({
    selector: '[tooltip]',//tslint:disable-line
    exportAs: 'tooltip',
    host: {
        '[class.custom-tooltip-open]': 'visible'
    }
})
export class TooltipDirective extends TooltipBaseDirective {

    @Input('tooltipTitle') specificTitle?: string | TemplateRef<void>;
    @Input('tooltip') directiveNameTitle?: string | TemplateRef<void>;

    componentFactory: ComponentFactory<ToolTipComponent> = this.resolver.resolveComponentFactory(ToolTipComponent);

    constructor(elementRef: ElementRef,
                hostView: ViewContainerRef,
                resolver: ComponentFactoryResolver,
                renderer: Renderer2) {
        super(elementRef, hostView, resolver, renderer);
    }
}

@Component({
    selector: 'ai-tooltip',
    exportAs: 'tooltipComponent',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [zoomBigMotion],
    template: `
        <ng-template
                #overlay="cdkConnectedOverlay"
                cdkConnectedOverlay
                transparentOverlay
                [cdkConnectedOverlayOrigin]="origin"
                [cdkConnectedOverlayOpen]="visible"
                [cdkConnectedOverlayHasBackdrop]="hasBackdrop"
                [cdkConnectedOverlayPositions]="positions"
                (backdropClick)="hide()"
                (detach)="hide()"
                (positionChange)="onPositionChange($event)">
            <div class="tooltip"
                 [ngClass]="classMap"
                 [@zoomBigMotion]="'active'">
                <div class="tooltip-content">
                    <div class="tooltip-arrow"></div>
                    <div class="tooltip-inner">
                        <ng-container *stringTemplateOutlet="title">{{ title }}</ng-container>
                    </div>
                </div>
            </div>
        </ng-template>`,
    preserveWhitespaces: false
})
export class ToolTipComponent extends TooltipBaseComponent {

    @Input() title: string | TemplateRef<void> = null;

    constructor(cdr: ChangeDetectorRef) {
        super(cdr);
    }

    public isEmpty(): boolean {
        return isTooltipEmpty(this.title);
    }
}
