import { Pipe, PipeTransform } from '@angular/core';
import { isChinese } from '../facade';

@Pipe({
    name: 'cnLast'
})

export class CnLastPipe implements PipeTransform {
    transform(value: string): any {
        if (!value) {
            return;
        }
        if (!isChinese(value)) {
            return value;
        }
        return value.split('').reverse().find(char => isChinese(char));
    }
}
