import { hasOwnProperty, isBigNumber, isPresent, isPrimitive } from 'share';
import { NodeType } from './node';

export function resolveJsonType(value: any): NodeType {
    if (value === undefined) {
        return NodeType.Undefined;
    }

    if (value === null) {
        return NodeType.Null;
    }

    if (isPrimitive(value)) {
        return NodeType[(typeof value).capitalize()];
    }

    if (Array.isArray(value)) {
        let item = value[0];
        if (Array.isArray(item)) {
            if (isBigNumber(item[0])) {
                return NodeType.Array2Number;
            }
            return NodeType[`Array2${(typeof item[0]).capitalize()}`];
        }

        if (isBigNumber(item)) {
            return NodeType.ArrayNumber;
        }

        if (isPrimitive(item)) {
            return NodeType[`Array${(typeof item).capitalize()}`];
        }

        return NodeType.ArrayObject;
    }

    if (isBigNumber(value)) {
        return NodeType.Number;
    }

    if (typeof value === 'object') {
        return NodeType.Object;
    }

    return NodeType.String;
}

function updateArrayObj(obj, newValue) {
    for (let key of Object.keys(newValue)) {
        let value = newValue[key];
        if (Array.isArray(value) && value.length > 0) {
            let arrayValue = resolveArrayDef(value);
            if (isPrimitive(arrayValue) || !hasOwnProperty(obj, key) || obj[key].length === 0) {
                obj[key] = [arrayValue];
            } else {
                updateArrayObj(obj[key][0], arrayValue);
            }
        } else if (!hasOwnProperty(obj, key)) {
            obj[key] = value;
        } else if (typeof value === 'object' && value !== null) {
            if (!isPresent(obj[key])) {
                obj[key] = value;
            } else {
                updateArrayObj(obj[key], value);
            }
        }
    }
}

export function resolveArrayDef(array: Array<any>): any {
    let item = array[0];

    if (isPrimitive(item) || item === undefined || item === null) {
        return item;
    }

    if (isBigNumber(item)) {
        return item;
    }

    if (Array.isArray(item)) {
        return [resolveArrayDef(item)];
    }

    let obj = {};
    array.forEach(v => updateArrayObj(obj, v));
    return obj;
}

export function resolveFormType(value: string): NodeType {
    if (!value) {
        return NodeType.String;
    }
    try {
        if (value.startsWith('{') || value.startsWith('[')) {
            JSON.parse(value);
            return NodeType.json;
        }
        if (value.startsWith('<')) {
            let dom = $.parseXML(value);
            return isPresent(dom) ? NodeType.xml : NodeType.String;
        }
    } catch (e) {

    }
    return NodeType.String;
}
