import { Inject, Injectable, InjectionToken, Optional, Self, Type } from '@angular/core';

export const NG_FACTORIES = new InjectionToken<Array<ComponentFactoryHolder>>('ComponentFactoryHolder');


export interface ComponentFactoryHolder {
    id: string;
    factory: Type<any>;
}

@Injectable()
export class ComponentFactoryService {

    constructor(@Optional() @Self() @Inject(NG_FACTORIES) private factories: ComponentFactoryHolder[]) {
    }

    getFactory(id: string): Type<any> {
        return this.factories?.find(f => f.id === id).factory;
    }
}
