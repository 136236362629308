import { Directive, ElementRef, NgZone, OnDestroy, OnInit, Optional, Renderer2, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { isPresent } from '../facade';
import { InputService } from './input.service';

@Directive({
    selector: '[ngModel],[formControlName],[formControl]', // tslint:disable-line
    providers: [InputService]
})
export class InputDirective implements OnInit, OnDestroy {

    private validate$: Subscription;
    private zone$: Subscription;
    private feedback: HTMLElement;

    constructor(private render: Renderer2,
                private elementRef: ElementRef<HTMLElement>,
                private zone: NgZone,
                private inputService: InputService,
                @Optional() @Self() private control: NgControl) {
    }

    ngOnInit(): void {
        this.watchControl();
        this.zone$ = this.zone.onStable.subscribe(() => this.updatePosition());
    }


    private watchControl(): void {
        this.remove$();
        if (!isPresent(this.control)) {
            return;
        }
        this.validate$ = this.control.statusChanges?.subscribe(() => this.toggleError());
    }

    private remove$(): void {
        this.validate$?.unsubscribe();
    }

    private toggleError(): void {
        if (!isPresent(this.control?.errors)) {
            this.hideError();
            return;
        }
        if (this.control.dirty) {
            this.showError();
        }
    }

    showError() {
        if (!isPresent(this.control?.errors)) {
            return;
        }
        let element = this.elementRef.nativeElement as HTMLElement;
        this.render.addClass(element, 'is-invalid');
        if (!isPresent(this.feedback)) {
            this.feedback = this.render.createElement('div');
            this.render.appendChild(element.parentNode, this.feedback);
        }
        this.render.addClass(this.feedback, 'invalid-feedback');
        this.feedback.innerHTML = this.inputService.qryError(this.control.errors);
        this.updatePosition();
    }

    private hideError(): void {
        this.feedback?.remove();
        this.feedback = undefined;
        this.render.removeClass(this.elementRef.nativeElement, 'is-invalid');
    }

    updatePosition(): void {
        if (!isPresent(this.feedback)) {
            return;
        }
        let el = this.elementRef.nativeElement as HTMLElement;
        let rect = el.getBoundingClientRect();
        this.feedback.style.transform = '';
        let fRect = this.feedback.getBoundingClientRect();
        this.feedback.style.width = `${rect.width}px`;
        this.feedback.style.transform = `translate(${rect.left - fRect.left}px,${rect.bottom - fRect.top}px)`;
    }

    ngOnDestroy(): void {
        this.remove$();
        this.zone$?.unsubscribe();
    }

}
