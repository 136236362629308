import { isPresent } from 'share';
import {
    DefaultFormValueVisitor,
    DefaultFormVisitor,
    FormValueVisitor,
    FormVisitor,
    visitForm,
    visitFormValue
} from './form.visitor';
import { Node } from './node';
import { NodeFormatter } from './node.formatter';

export class FormFormatter implements NodeFormatter {

    constructor(
        private visitor: FormVisitor = new DefaultFormVisitor(),
        private valueVisitor: FormValueVisitor = new DefaultFormValueVisitor(),
        private keyValueSplitter: string = ':',
        private nodeSplitter: string = '\n',
        private defaultValue: string = '') {
    }

    parse(text: string, nodes: Node[]): void {
        let keyValueMap = new Map<string, string>();
        let rows = text.split('\n').filter(value => !!value);
        for (let row of rows) {
            let [key, value] = row.split(':');
            keyValueMap.set(key, value);
        }
        nodes.filter(node => !keyValueMap.has(node.name)).forEach(node => nodes.remove(node));
        keyValueMap.forEach((value, key) => {
            let node = nodes.find(item => item.name === key);
            if (!isPresent(node)) {
                node = this.visitor.visitNode(key);
                nodes.push(node);
            }
            visitForm(this.visitor, value, node);
        });
    }

    stringify(nodes: Node[]): string {
        return nodes.reduce<string>((previousValue, currentValue, index) => `${previousValue}${currentValue.name}${this.keyValueSplitter}${visitFormValue(this.valueVisitor, currentValue) || this.defaultValue}${index === nodes.length - 1 ? '' : this.nodeSplitter}`, '');
    }
}

export class FormFormatterBuilder {

    private dVisitor: FormVisitor = new DefaultFormVisitor();
    private dValueVisitor: FormValueVisitor = new DefaultFormValueVisitor();
    private dKeyValueSplitter = ':';
    private dNodeSplitter = '\n';
    private dDefaultValue = '\n';

    visitor(visitor: FormVisitor): FormFormatterBuilder {
        this.dVisitor = visitor;
        return this;
    }

    valueVisitor(visitor: FormValueVisitor): FormFormatterBuilder {
        this.dValueVisitor = visitor;
        return this;
    }

    keyValueSplitter(keyValueSplitter: string): FormFormatterBuilder {
        this.dKeyValueSplitter = keyValueSplitter;
        return this;
    }

    nodeSplitter(nodeSplitter: string): FormFormatterBuilder {
        this.dNodeSplitter = nodeSplitter;
        return this;
    }

    defaultValue(defaultValue: string): FormFormatterBuilder {
        this.dDefaultValue = defaultValue;
        return this;
    }

    build(): NodeFormatter {
        return new FormFormatter(this.dVisitor, this.dValueVisitor, this.dKeyValueSplitter, this.dNodeSplitter);
    }

}
