import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageScriptType } from 'app/ui/page/ui.page.model';
import { PageWinComponent } from 'app/ui/page/win/page.win.component';
import { switchMap } from 'rxjs/operators';
import { DrawerService, ModalService } from 'share';

@Component({
    selector: 'ai-script-record',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ''
})

export class ScriptRecordComponent implements OnInit, OnDestroy {

    constructor(private router: Router, private drawer: DrawerService, private modal: ModalService) {
    }

    ngOnInit() {
        this.addEventListener();
    }

    private addEventListener(): void {
        this.handleMessage = this.handleMessage.bind(this);
        window.addEventListener('message', this.handleMessage);
    }


    private handleMessage(ev: MessageEvent): void {
        let msg = ev.data;
        let url = this.router.routerState.snapshot.url;
        if (msg.key !== 'RECORD_COMPLETE' || (url.indexOf('pages/ui/page/edit') !== -1) && url.indexOf('?') !== -1) {
            return;
        }
        this.modal.confirm({ title: '是否使用录制的步骤新增组件?' }).result.pipe(switchMap(() => {
            const drawerRef = this.drawer.open(PageWinComponent, { scrollable: true });
            drawerRef.inputs.editable = true;
            drawerRef.inputs.item = {
                script: msg.code,
                script_type: PageScriptType.PYTHON,
                component_params: []
            };
            return drawerRef.result;
        })).subscribe(item => this.router.navigate([`pages/ui/page/edit/${item.component_id}`]));
    }

    ngOnDestroy(): void {
        window.removeEventListener('message', this.handleMessage);
    }
}
