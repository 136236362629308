import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';
import { auditTime, distinctUntilChanged, filter, flatMap, map, mapTo } from 'rxjs/operators';
import { MenuService } from './menu.service';
import { IsMenuInsideDropDownToken } from './menu.token';
import { MenuModeType } from './menu.types';

@Injectable()
export class SubmenuService {

    mode$: Observable<MenuModeType> = this.menuService.mode$.pipe(
        map(mode => {
            if (mode === 'inline') {
                return 'inline';
            } else if (mode === 'vertical' || this.hostSubmenuService) {
                return 'vertical';
            } else {
                return 'horizontal';
            }
        })
    );
    level = 1;
    isCurrentSubMenuOpen$ = new BehaviorSubject<boolean>(false);

    private isChildSubMenuOpen$ = new BehaviorSubject<boolean>(false);
    private isMouseEnterTitleOrOverlay$ = new Subject<boolean>();
    private childMenuItemClick$ = new Subject<any>();

    constructor(
        @SkipSelf() @Optional() private hostSubmenuService: SubmenuService,
        public menuService: MenuService,
        @Inject(IsMenuInsideDropDownToken) public isMenuInsideDropDown: boolean
    ) {
        if (this.hostSubmenuService) {
            this.level = this.hostSubmenuService.level + 1;
        }
        const isClosedByMenuItemClick = this.childMenuItemClick$.pipe(
            flatMap(() => this.mode$),
            filter(mode => mode !== 'inline' || this.isMenuInsideDropDown),
            mapTo(false)
        );
        const isCurrentSubmenuOpen$ = merge(this.isMouseEnterTitleOrOverlay$, isClosedByMenuItemClick);
        const isSubMenuOpenWithDebounce$ = combineLatest([this.isChildSubMenuOpen$, isCurrentSubmenuOpen$]).pipe(
            map(([isChildSubMenuOpen, isCurrentSubmenuOpen]) => isChildSubMenuOpen || isCurrentSubmenuOpen),
            auditTime(150),
            distinctUntilChanged()
        );
        isSubMenuOpenWithDebounce$.pipe(distinctUntilChanged()).subscribe(data => {
            this.setOpenStateWithoutDebounce(data);
            if (this.hostSubmenuService) {
                this.hostSubmenuService.isChildSubMenuOpen$.next(data);
            } else {
                this.menuService.isChildSubMenuOpen$.next(data);
            }
        });
    }

    onChildMenuItemClick(menu: any): void {
        this.childMenuItemClick$.next(menu);
    }

    setOpenStateWithoutDebounce(value: boolean): void {
        this.isCurrentSubMenuOpen$.next(value);
    }

    setMouseEnterTitleOrOverlayState(value: boolean): void {
        this.isMouseEnterTitleOrOverlay$.next(value);
    }
}
