import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'ai-option-item',
    template: `
        <div class="select-item-option-content">
            <ng-container *ngIf="!customContent; else template">{{ label }}</ng-container>
        </div>
        <div *ngIf="showState && selected" class="select-item-option-state">
            <i class="icon icon-check"></i>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.select-item]': 'true',
        '[class.select-item-option]': 'true',
        '[class.select-item-option-grouped]': 'grouped',
        '[class.select-item-option-selected]': 'selected && !disabled',
        '[class.select-item-option-disabled]': 'disabled',
        '[class.select-item-option-active]': 'activated && !disabled'
    }
})
export class OptionItemComponent implements OnChanges {

    selected = false;
    activated = false;

    @Input() grouped = false;
    @Input() customContent = false;
    @Input() template: TemplateRef<any> | null = null;
    @Input() disabled = false;
    @Input() showState = false;
    @Input() label: string | null = null;
    @Input() value: any | null = null;
    @Input() activatedValue: any | null = null;
    @Input() listOfSelectedValue: any[] = [];
    @Input() compareWith: (o1: any, o2: any) => boolean;

    @Output() readonly itemClick = new EventEmitter<any>();
    @Output() readonly itemHover = new EventEmitter<any>();

    @HostListener('click')
    onHostClick(): void {
        if (this.disabled) {
            return;
        }
        this.itemClick.next(this.value);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { value, activatedValue, listOfSelectedValue } = changes;
        if (value || listOfSelectedValue) {
            this.selected = this.listOfSelectedValue.some(v => this.compareWith(v, this.value));
        }
        if (value || activatedValue) {
            this.activated = this.compareWith(this.activatedValue, this.value);
        }
    }
}
