import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlowCanvasDirective } from './flow.canvas.directive';


@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        FlowCanvasDirective
    ],
    declarations: [
        FlowCanvasDirective
    ],
    providers: []
})
export class FlowModule {
}
