import { Directive, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { isPresent } from '../facade';
import { BaseUrlStrategy } from '../providers';

@Directive({
    selector: 'img,video' // tslint:disable-line
})

export class ImageTokenDirective implements OnInit, OnDestroy {

    private _src: any;

    constructor(private strategy: BaseUrlStrategy, private storage: LocalStorageService) {
    }

    ngOnInit() {
    }

    @HostBinding('attr.src')
    get srcNew(): any {
        if (typeof this._src === 'object') {
            return this._src;
        }
        let token = this.storage.retrieve('token');
        if (!isPresent(token) || !isPresent(this._src) || this._src.startsWith('data:')) {
            return this.strategy.path(this._src);
        }
        return this.strategy.path(`${this._src}${this._src.indexOf('?') !== -1 ? '&' : '?'}access_token=${token?.access_token}`);
    }

    @Input()
    set src(value: any) {
        this._src = value;
    }


    ngOnDestroy() {
    }
}
