import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'detectCaseLogStatus'
})
export class DetectCaseLogStatusPipe implements PipeTransform {
    transform(status: number): string {
        if (status === 0) {
            return '等待执行';
        }
        if (status === 1) {
            return '正在执行';
        }
        if (status === 10) {
            return '成功';
        }
        if (status === 11) {
            return '失败';
        }
        if (status === 12) {
            return '超时';
        }
        return '未知';
    }
}

@Pipe({
    name: 'detectDBLogStatus'
})
export class DetectDBLogStatusPipe implements PipeTransform {

    transform(status: number): string {
        if (status === 0) {
            return '等待执行';
        }
        if (status === 10) {
            return '成功';
        }
        if (status === 11) {
            return '失败';
        }
        return '未知';
    }
}
