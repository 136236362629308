import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AvatarComponent } from './avatar.component';
import { CnLastPipe } from './avatar.pipe';

@NgModule({
    imports: [
        CommonModule,
        PlatformModule
    ],
    exports: [
        AvatarComponent
    ],
    declarations: [
        AvatarComponent,
        CnLastPipe
    ]
})
export class AvatarModule {
}
