import { isPresent } from 'share';

export enum GroupType {
    SCENE = 'scene',
    INT = 'inf',
    CASE = 'demand-case',
    INTF_CASE = 'case',
    UI_CASE = 'ui_case',
    APP_CASE = 'mobile_case',
    USER = 'user-group',
    MOCK = 'mock',
    WIDGET = 'widget',
    MACHINE = 'agent'
}

export enum GroupBuType {
    DEMAND = 'demand-case-bu',
    UI = 'ui-case-bu',
    APP = 'app-case-bu',
    INTF = 'intf-case-bu'
}

export interface Group {
    group_id?: number;
    group_name?: string;
    group_new_name?: string;
    group_type?: string;
    order?: number;
    parent_id?: number;
    product_id?: number;
    app_id?: number;
    count?: number;
}

export class Menu {
    id?: number;
    group_id?: number;
    name?: string;
    icon?: string;
    icon_color?: string;
    sub_icon?: string;
    sub_icon_color?: string;
    parent_id?: number;
    parent?: Menu;
    children?: Array<Menu>;
    hide?: boolean;
    type?: number; // 0-产品, 1-分组, 2-服务, 3-接口
    type_data: any;
    operations?: Array<Operation>;
    

    selected?: boolean;
    indeterminate?: boolean;

    private _opened?: boolean;
    private _drag_entered?: boolean;
   

    constructor(item: {
        id?: number;
        name?: string;
        icon?: string;
        icon_color?: string;
        parent_id?: number;
        type?: number; // 1-分组, 2-服务, 3-接口
        type_data?: any;
        opened?: boolean;
        operations?: Array<Operation>;
        selected?: boolean;
    }) {
        this.id = item.id;
        this.name = item.name;
        this.icon = item.icon;
        this.icon_color = item.icon_color;
        this.parent_id = item.parent_id;
        this.children = [];
        this.hide = false;
        this.type = item.type || 0;
        this.type_data = item.type_data;
        this.operations = item.operations || [];
        this._opened = item.opened || false;
        this.selected = item.selected || false;

        this._drag_entered = false;
    }

    addChildren(menu: Menu, index?: number): void {
        if (isPresent(index)) {
            this.children.splice(index, 0, menu);
        } else {
            this.children.push(menu);
        }
        menu.parent_id = this.id;
        menu.parent = this;
    }

    removeChildren(menu: Menu): void {
        let index = this.children.findIndex(item => item.id === menu.id);
        if (index !== -1) {
            this.children.splice(index, 1);
        }
    }

    showMenu(): void {
        this._opened = this.type === 0;
        this.children.forEach(menu => menu.showMenu());
    }

    hideMenu(): void {
        this.hide = true;
        this.children.forEach(menu => menu.hideMenu());
    }

    isDescendantOf(menu: Menu): boolean {
        return menu.flatMenu().filter(item => item !== menu).some(item => this === item);
    }

    isClusterOf(menu: Menu): boolean {
        return menu.flatMenu().some(item => this === item || this.id === item.id);
    }

    flatMenu(): Array<Menu> {
        let items = [];
        items.push(this);
        this.children.forEach(item => items.push(...item.flatMenu()));
        return items;
    }

    get opened(): boolean {
        return this._opened;
    }

    set opened(value: boolean) {
        if (!value) {
            this._opened = value;
            return;
        }
        this._opened = this.children.length !== 0;

    }

    get drag_entered(): boolean {
        return this._drag_entered;
    }

    set drag_entered(value: boolean) {
        if (!value) {
            this._drag_entered = false;
            return;
        }
        this.root.flatMenu().forEach(item => item.drag_entered = false);
        this._drag_entered = value;
    }

    get root(): Menu {
        let root: Menu = this;
        while (isPresent(root.parent)) {
            root = root.parent;
        }
        return root;
    }

}

export interface MenuOption {
    root_name?: string;
    group_type?: string;
    group_icon?: string;
    root_operations?: Array<Operation>;
    group_operations?: Array<Operation>;
    home_path?: string;
}

export interface Operation {
    id?: string;
    name?: string;
    icon?: string;
    right?: string;

    children?: Array<Operation>
}

export function getRootName(menu: Menu): string {
    let root = menu;
    while (isPresent(root.parent)) {
        root = root.parent;
    }
    return root.name;
}

const GroupNameMap = new Map([
    [GroupType.INT, '接口']
]);

export function getGroupName(type: string): string {
    if (!isPresent(type)) {
        return '';
    }
    return GroupNameMap.get(type as GroupType);
}
