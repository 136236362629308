import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIPageEditModule } from 'app/ui/page/edit/ui.page.edit.module';
import { PageWinComponent } from 'app/ui/page/win/page.win.component';
import { ShareModule } from 'share';

@NgModule({
    imports: [
        CommonModule,
        ShareModule,
        UIPageEditModule
    ],
    exports: [],
    declarations: [
        PageWinComponent
    ],
    entryComponents: [
        PageWinComponent
    ],
    providers: []
})
export class PageWinModule {
}
