import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateHelperService, ensureTwo, isPresent } from 'share';
import { Cron } from './cron';

@Component({
    selector: 'ai-cron',
    templateUrl: 'cron.component.html',
    styles:[`
      .dropdown-menu {
        width: 100%;
        padding: 1.5rem;
      }
    `]
})

export class CronComponent implements OnInit {

    @Input() disabled: boolean;
    @Input() placeholder: string;

    item: Cron;
    months: Array<{ id?: number, text?: string }> = [];
    hours: Array<number> = [];
    minutes: Array<number> = [];
    days: Array<number> = [];
    show: boolean;

    @Output() cronChange = new EventEmitter<string>(true);

    private _cron: string;

    constructor(private dateHelper: DateHelperService) {
        this.item = { type: 'date' };
        this.show = false;
        this.disabled = false;
    }

    ngOnInit() {
        this.initMouths();
        this.initDays();
        this.initHours();
        this.initMinutes();
    }

    get cron(): string {
        return this._cron;
    }

    @Input()
    set cron(value: string) {
        if (isPresent(this._cron) && this._cron === value) {
            return;
        }
        this._cron = value;
        if (!value) {
            this.initCron();
            return;
        }
        this.parseCron();
    }

    markDisabled = (date: Date) => date.getTime() < new Date().getTime();

    private initMouths(): void {
        this.months.push({ id: 0, text: '每月' });
        for (let i = 1; i <= 12; i++) {
            this.months.push({ id: i, text: i + '月' });
        }
    }

    private initDays(): void {
        for (let i = 1; i <= 31; i++) {
            this.days.push(i);
        }
    }

    private initHours(): void {
        for (let i = 0; i <= 23; i++) {
            this.hours.push(i);
        }
    }

    private initMinutes(): void {
        for (let i = 0; i <= 59; i++) {
            this.minutes.push(i);
        }
    }

    private initCron(): void {
        let now = new Date();
        this.item.type = 'date';
        this.item.date = now;
        this.item.hours = now.getHours();
        this.item.minutes = now.getMinutes();
    }

    private parseCron(): void {
        let splits = this._cron.split(' ');
        let hour = splits[2];
        if (hour.indexOf('/') !== -1) {
            this.item.type = 'hour';
            this.item.hour = +hour.split('/')[1];
            return;
        }

        let week = splits[5];
        if (week !== '?') {
            this.item.type = 'week';
            this.item.day = +week;
            this.item.hours = +hour;
            return;
        }

        let minute = splits[1];
        let day = splits[3];
        let month = splits[4];
        let year = splits[6];

        if (isPresent(year)) {
            this.item.minutes = +minute;
            this.item.hours = +hour;
            this.item.date = this.dateHelper.parse(`${+year}-${ensureTwo(month)}-${ensureTwo(day)}`);
            this.item.type = 'date';
            return;
        }

        if (day === '*') {
            this.item.type = 'day';
            this.item.minutes = +minute;
            this.item.hours = +hour;
            return;
        }

        this.item.type = 'month';
        this.item.hours = +hour;
        this.item.day = +day;
        this.item.month = +month;
    }

    toggle(): void {
        if (this.disabled) {
            return;
        }
        this.show = !this.show;
    }

    submit() {
        let express = '';
        switch (this.item.type) {
            case 'date' :
                express = `0 ${this.item.minutes} ${this.item.hours} ${this.item.date.getDate()} ${this.item.date?.getMonth() + 1} ? ${this.item.date?.getFullYear()}`;
                break;
            case 'month' :
                express = `0 0 ${this.item.hours} ${this.item.day} ${+this.item.month || '*'} ?`;
                break;
            case 'week' :
                express = `0 0 ${this.item.hours} ? * ${this.item.day}`;
                break;
            case 'day' :
                express = `0 ${this.item.minutes} ${this.item.hours} * * ?`;
                break;
            case 'hour' :
                express = `0 0 0/${this.item.hour} * * ?`;
                break;
            default:
                break;
        }

        if (this._cron !== express) {
            this.cronChange.emit(express);
        }
        this.toggle();
    }
}
