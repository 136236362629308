import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TagComponent } from './tag.component';
import { TagService } from './tag.service';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        TagComponent
    ],
    declarations: [TagComponent],
    providers: [
        TagService
    ]
})
export class TagModule {
}
