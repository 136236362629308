import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BatchSelectAllComponent } from './batch.select.all.component';
import { BatchSelectItemComponent } from './batch.select.item.component';
import { BatchSelectService } from './batch.select.service';


@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        BatchSelectAllComponent,
        BatchSelectItemComponent
    ],
    declarations: [
        BatchSelectAllComponent,
        BatchSelectItemComponent
    ],
    providers: [
        BatchSelectService
    ]
})
export class BatchSelectModule {
}
