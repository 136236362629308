import { FlattenNode, TreeNode, TreeNodeKey, TreeNodeOptions } from './tree-base-node';

export function isCheckDisabled(node: TreeNode): boolean {
    const { isDisabled, isDisableCheckbox } = node;
    return !!(isDisabled || isDisableCheckbox);
}

export function isInArray(needle: any, haystack: any[]): boolean {
    return haystack.length > 0 && haystack.indexOf(needle) > -1;
}

export function getPosition(level: string | number, index: number): string {
    return `${level}-${index}`;
}

export function getKey(key: TreeNodeKey, pos: string): TreeNodeKey {
    if (key !== null && key !== undefined) {
        return key;
    }
    return pos;
}

/**
 * Flat nest tree data into flatten list. This is used for virtual list render.
 * @param treeNodeList Origin data node list
 * @param expandedKeys
 * need expanded keys, provides `true` means all expanded (used in `rc-tree-select`).
 */
export function flattenTreeData(treeNodeList: TreeNode[] = [], expandedKeys: TreeNodeKey[] | true = []): FlattenNode[] {
    const expandedKeySet = new Set(expandedKeys === true ? [] : expandedKeys);
    const flattenList: FlattenNode[] = [];

    function dig(list: TreeNode[], parent: FlattenNode | null = null): FlattenNode[] {
        return list.map((treeNode, index) => {
            const pos: string = getPosition(parent ? parent.pos : '0', index);
            const mergedKey = getKey(treeNode.key, pos);
            treeNode.isStart = [...(parent ? parent.isStart : []), index === 0];
            treeNode.isEnd = [...(parent ? parent.isEnd : []), index === list.length - 1];
            // Add FlattenDataNode into list
            // TODO: only need data here.
            const flattenNode: FlattenNode = {
                parent,
                pos,
                children: [],
                data: treeNode,
                isStart: [...(parent ? parent.isStart : []), index === 0],
                isEnd: [...(parent ? parent.isEnd : []), index === list.length - 1]
            };

            flattenList.push(flattenNode);

            // Loop treeNode children
            if (expandedKeys === true || expandedKeySet.has(mergedKey) || treeNode.isExpanded) {
                flattenNode.children = dig(treeNode.children || [], flattenNode);
            } else {
                flattenNode.children = [];
            }

            return flattenNode;
        });
    }

    dig(treeNodeList);
    return flattenList;
}

export function flatNodes(nodes: Array<TreeNodeOptions>): Array<TreeNodeOptions> {
    let items: Array<TreeNodeOptions> = [];
    nodes?.forEach(node => {
        items.push(node);
        items.push(...flatNodes(node.children));
    });
    return items;
}

export function addNodeToTree(node: TreeNodeOptions, key: string, nodes: Array<TreeNodeOptions>): void {
    if (!nodes?.length) {
        return;
    }
    let item = flatNodes(nodes).find(v => v.key === key);
    if (!item) {
        return;
    }
    item.children = item.children ?? [];
    item.children.push(node);
}

export function conductTree(items: Array<TreeNodeOptions>, groups: Array<TreeNodeOptions>, groupKey = 'group_id'): Array<TreeNodeOptions>  {
    const flatGroups = flatNodes(groups).reverse();
    const groupMap = new Map<number, TreeNodeOptions>();

    flatGroups.forEach(item => groupMap.set(item.id, item));

    items.forEach(node => groupMap.get(node.data[groupKey])?.children.push(node));
    flatGroups.forEach(node => {
        node.children = node.children?.filter(v => v.isLeaf || v.children?.length > 0);
        node.selectable = false;
    });

    const nodes = groups.filter(v => v.children?.length > 0);
    if (nodes.length === 1) {
        nodes.forEach(node => node.expanded = true);
    }
    return nodes;
}
