import { GlobalHolder } from 'app/global.holder';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { getInjector, isPresent } from 'share';
import { FilterInitializer } from '../../initializer';
import { ComponentContext } from '../context';
import { ProductInitService } from '../interfaces';

export class ProductInitializer implements FilterInitializer<ComponentContext> {

    init(context: ComponentContext): Observable<any> {
        let injector = getInjector(context.instance);
        let initService = injector.get(ProductInitService);
        let key = Object.keys(context.instance).find(v => (typeof context.instance[v] === 'object') && isPresent(context.instance[v].product_id));
        let product_id = context.instance[key].product_id;
        let global = environment.injector.get(GlobalHolder);
        let localGlobal = injector.get(GlobalHolder);
        if (isPresent(global.product_id) && global.product_id === product_id) {
            localGlobal.updateProduct(global.product.getValue());
            localGlobal.updateProductUser(global.users.getValue());
            localGlobal.updateEnvironments(global.environments.getValue());
            localGlobal.updateParams(global.params.getValue());
            return of(context);
        }
        return initService.initGlobal(product_id, localGlobal).pipe(mapTo(context));
    }

    support(context: ComponentContext): boolean {
        return context.options.initProduct;
    }

}
