import { Component, Input, OnInit } from '@angular/core';
import { ModalRef } from 'share';
import { Group } from 'app/group/group.model';
import { GroupService } from 'app/group/group.service';

@Component({
    selector: 'ai-group-edit',
    templateUrl: 'group.edit.component.html'
})
export class GroupEditComponent implements OnInit {

    @Input() group: Group;

    constructor(public modalRef: ModalRef, public groupService: GroupService) {
    }

    ngOnInit() {
    }

    submit(): void {
        if (this.group.group_id) {
            this.groupService.updateGroup(this.group).subscribe(() => {
                toastr.success('编辑成功');
                this.modalRef.close(this.group);
            });
        } else {
            this.groupService.addGroup(this.group).subscribe(id => {
                toastr.success('操作成功');
                let group = Object.assign({}, this.group);
                group.group_id = id;
                this.modalRef.close(group);
            });
        }
    }

}
