import { Pipe, PipeTransform } from '@angular/core';
import { isPresent } from '../facade';

@Pipe({ name: 'orderBy', pure: false })
export class OrderByPipe<T> implements PipeTransform {

    transform(values: T[], predicate = '', reverse = false): T[] {
        if (!isPresent(values)) {
            return values;
        }
        if (predicate === '') {
            return reverse ? values.sort().reverse() : values.sort();
        }
        return values.sort((a, b) => {
            if (a[predicate] < b[predicate]) {
                return reverse ? 1 : -1;
            } else if (b[predicate] < a[predicate]) {
                return reverse ? -1 : 1;
            }
            return 0;
        });
    }
}
