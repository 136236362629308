import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PopConfirmModule } from '../popconfirm';

import { DelComponent } from './del.component';

@NgModule({
    imports: [
        CommonModule,
        PopConfirmModule
    ],
    exports: [
        DelComponent
    ],
    declarations: [
        DelComponent
    ]
})
export class DelModule {
}
