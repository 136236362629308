import { NgModule } from '@angular/core';
import { ShareModule } from 'share';

import { UPLOAD_PATH } from './upload.model';
import { UploadService } from './upload.service';
import { UploadComponent } from './upload.component';
import { UploadBtnComponent } from './upload.btn.component';


@NgModule({
    imports: [
        ShareModule
    ],
    exports: [
        UploadComponent,
        UploadBtnComponent
    ],
    declarations: [
        UploadComponent,
        UploadBtnComponent
    ],
    providers: [
        { provide: UPLOAD_PATH, useValue: 'APP' },
        { provide: UploadService }
    ]
})
export class UploadxModule {
}
