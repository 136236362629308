export interface NodeLike {
    name?: string;
    value?: any;
    type?: NodeType;
    level?: number;
    parent?: NodeLike;
    children?: NodeLike[];
}


export class Node {

    parent?: Node;

    private _name?: string;
    private _value?: any;
    private _type?: NodeType;

    private readonly _children?: Node[];
    private _level = 1;

    constructor(name: string, value?: any, type?: NodeType) {
        this._name = name;
        this._value = value;
        this._type = type;
        this._children = [];
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get value(): any {
        return this._value;
    }

    set value(value: any) {
        this._value = value;
    }

    get type(): NodeType {
        return this._type;
    }

    set type(value: NodeType) {
        this._type = value;
    }

    get children(): Node[] {
        return this._children;
    }

    get level(): number {
        return this._level;
    }

    set level(value: number) {
        this._level = value;
    }

    addChild(node: Node): Node {
        this._children.push(node);
        node.parent = this;
        node._level = this._level + 1;
        return node;
    }

    clearChildren(): void {
        this._children.length = 0;
    }

    removeChild(node: Node): void {
        this._children.remove(node);
    }

    isObject(): boolean {
        return !!(this.type & NodeType.Object);
    }

    isPrimitive(): boolean {
        return this.type < NodeType.Primitive;
    }
}

export enum NodeType {
    Undefined = 0,
    Null = 1,

    String = 1 << 1,
    Boolean = 1 << 2,
    Number = 1 << 3,
    Object = 1 << 4,

    Array = 1 << 5,

    ArrayString = Array | String,
    ArrayBoolean = Array | Boolean,
    ArrayNumber = Array | Number,
    ArrayObject = Array | Object,

    Array2 = 1 << 6,

    Array2String = Array2 | ArrayString,
    Array2Boolean = Array2 | ArrayBoolean,
    Array2Number = Array2 | ArrayNumber,
    Array2Object = Array2 | ArrayObject,

    Element = 1 << 10,
    Attribute = 1 << 11,

    XML = Element | Attribute,

    json = 1 << 20,
    xml = 1 << 21,

    Node = json | xml,

    Primitive = String | Boolean | Number,
}

export const JSON_TYPES = [
    NodeType.String,
    NodeType.Boolean,
    NodeType.Number,
    NodeType.Object,
    NodeType.ArrayString,
    NodeType.ArrayBoolean,
    NodeType.ArrayNumber,
    NodeType.ArrayObject,
    NodeType.Array2String,
    NodeType.Array2Boolean,
    NodeType.Array2Number,
    NodeType.Array2Object
];

export const FORM_TYPES = [NodeType.String, NodeType.json, NodeType.xml];

export const XML_TYPES = [NodeType.Element, NodeType.Attribute];
