import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import * as ace from 'brace';
import 'brace/mode/html';
import 'brace/mode/java';
import 'brace/mode/javascript';
import 'brace/mode/json';
import 'brace/mode/hjson';
import 'brace/mode/mysql';
import 'brace/mode/python';
import 'brace/mode/xml';
import 'brace/mode/yaml';
import 'brace/theme/github';
import 'brace/theme/eclipse';
import { EventNames } from '../constants';
import { delay } from '../facade';
import { EventManager } from '../providers';
import { LineStyle } from './editor.ace.model';

type Mode = 'text' | 'html' | 'java' | 'javascript' | 'json' | 'hjson' | 'mysql' | 'python' | 'xml' | 'yaml' | undefined;

@Directive({
    selector: '[aiAceEditor]',
    exportAs: 'aceEditor'
})
export class AceEditorDirective {

    @Output() textChange = new EventEmitter();

    editor: ace.Editor;

    private _oldVal: string;
    private fontSize: number;

    private markerIds: Array<number> = [];

    constructor(private eventManager: EventManager, elementRef: ElementRef) {
        this.fontSize = 14;
        const el = elementRef.nativeElement as HTMLElement;
        el.classList.add('editor');
        this.editor = ace.edit(el);
        this.editor.setTheme('ace/theme/github');
        this.editor.$blockScrolling = Infinity;
        this.editor.setShowPrintMargin(false);
        this.editor.setFontSize('14px');
        this.editor.setOption('indentedSoftWrap', false);

        this.editor.on('change', () => {
            const newVal = this.editor.getValue();
            if (newVal === this._oldVal) {
                return;
            }
            this.textChange.emit(newVal);
            this._oldVal = newVal;
        });

        $(el).on('keydown', event => {
            if (!event.altKey || !event.shiftKey) {
                return;
            }

            if (event.keyCode === 187) {
                this.editor.setFontSize(`${++this.fontSize}px`);
            }

            if (event.keyCode === 189 && this.fontSize > 12) {
                this.editor.setFontSize(`${--this.fontSize}px`);
            }
        });

        this.editor.on('blur', () => {
            let event = document.createEvent('FocusEvent');
            event.initEvent('blur', true, true);
            el.dispatchEvent(event);
        });

        this.eventManager.subscribe(EventNames.RESIZE_EDITOR, () => this.resize());
    }

    @Input()
    set options(value) {
        this.editor.setOptions(value || {
            indent_size: 2
        });
    }

    @Input()
    set readOnly(value: boolean) {
        this.editor.setReadOnly(value);
    }

    @Input()
    set mode(value: any) {
        if (!value) return;
        this.editor.getSession().setMode(`ace/mode/${value}`);
    }

    @Input()
    set theme(value: any) {
        this.editor.setTheme(`ace/theme/${value}`);
    }

    @Input()
    set text(value: string) {
        if (value === this._oldVal) {
            return;
        }
        this.editor.setValue(value || '', 1);
        this.resize();
    }

    @Input()
    set useWrapMode(value: boolean) {
        this.editor.getSession().setUseWrapMode(value);
    }

    @Input()
    set lineStyle(values: LineStyle[]) {
        this.markerIds.forEach(id => this.editor.getSession().removeMarker(id));
        this.markerIds = [];
        values.forEach(line => this.markerIds.push(this.editor.getSession().addMarker(new ace.Range(line.startRow - 1, 0, line.endRow - 1, Infinity), line.className, 'fullLine', false)));
    }

    goToLine(line: number): void {
        this.editor.gotoLine(line);
    }

    insert(text: string): void {
        this.editor.insert(text);
    }

    async resize() {
        await delay(300);
        this.editor.resize(true);
    }

}
