import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig, LoginMode } from 'app/app.model';
import { CaseDataFactory, CaseDataRestore } from 'app/case/data/case.data';
import { DataRule } from 'app/data/rule/data.rule.model';
import { Pool } from 'app/data/pool/pool.model';
import { ProductEnv, ProductUser } from 'app/product/product.model';
import { Menu } from 'app/system/menu/menu';
import { Tenant } from 'app/system/tenant/tenant.model';
import { TitleService } from 'app/title.service';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject } from 'rxjs';
import { isPresent } from 'share';
import { Token } from './login/login.model';
import { Product, ProductParam } from './product/product.model';
import { User } from './system/user/user.model';


@Injectable({ providedIn: 'root' })
export class GlobalHolder {

    product: BehaviorSubject<Product> = new BehaviorSubject<Product>(null);
    tenant: BehaviorSubject<Tenant> = new BehaviorSubject<Tenant>(null);
    users: BehaviorSubject<Array<ProductUser>> = new BehaviorSubject<Array<ProductUser>>([]);
    tenants: BehaviorSubject<Array<Tenant>> = new BehaviorSubject<Array<Tenant>>([]);


    token: BehaviorSubject<Token> = new BehaviorSubject<Token>(null);
    user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    menus: BehaviorSubject<Array<Menu>> = new BehaviorSubject<Array<Menu>>([]);
    topMenus: BehaviorSubject<Array<Menu>> = new BehaviorSubject<Array<Menu>>([]);
    rights: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);
    proMenus: BehaviorSubject<Array<Menu>> = new BehaviorSubject<Array<Menu>>([]);

    properties: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    pools: BehaviorSubject<Array<Pool>> = new BehaviorSubject<Array<Pool>>([]);

    environments: BehaviorSubject<Array<ProductEnv>> = new BehaviorSubject<Array<ProductEnv>>([]);

    params: BehaviorSubject<Array<ProductParam>> = new BehaviorSubject<Array<ProductParam>>([]);

    factories: BehaviorSubject<Array<CaseDataFactory>> = new BehaviorSubject<Array<CaseDataFactory>>([]);
    rules: BehaviorSubject<Array<DataRule>> = new BehaviorSubject<Array<DataRule>>([]);
    restores: BehaviorSubject<Array<CaseDataRestore>> = new BehaviorSubject<Array<CaseDataRestore>>([]);
    cache_params: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);

    name: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    needChangePwd: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    collapse: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    showProduct: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    appConfig: BehaviorSubject<AppConfig> = new BehaviorSubject<AppConfig>(null);
    loginModes: BehaviorSubject<Array<LoginMode>> = new BehaviorSubject<Array<LoginMode>>(null);
    loginVerify: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    refreshGuard: boolean;

    constructor(private title: TitleService, private storage: LocalStorageService, private router: Router) {
        this.updateToken(this.storage.retrieve('token'));
        this.updateUser(this.storage.retrieve('user'));
        this.updateTenant(this.storage.retrieve('tenant'));
        // this.updateCollapse(true);
    }

    get product_id(): number {
        return this.product.getValue()?.product_id;
    }

    get user_id(): number {
        return this.user.getValue()?.user_id;
    }

    get tenant_id(): number {
        return this.tenant.getValue()?.tenant_id;
    }

    get tenant_code(): string {
        return this.tenant.getValue()?.tenant_code;
    }

    get globals(): Array<ProductParam> {
        return this.params.getValue().filter(item => item.param_type === 'global');
    }

    get databases(): Array<ProductParam> {
        return this.params.getValue().filter(item => item.param_type === 'db');
    }

    get allowNgLogin(): boolean {
        return this.loginModes.getValue()?.some(mode => mode.type === 'nt') ?? false;
    }

    get currentUser(): ProductUser {
        return this.users.getValue()?.find(item => item.user_id === this.user_id);
    }

    get hosts(): Array<ProductParam> {
        let params = this.params.getValue().filter(param => param.param_type === 'host');
        let environments = this.environments.getValue();
        return params.map(param => {
            if (environments.length === 0 || (environments[0].params || []).length === 0) {
                return param;
            }
            return environments[0].params.find(i => i.param_type === 'host' && i.param_name === param.param_name) || param;
        });
    }

    updateProduct(product: Product): void {
        this.product.next(product);
        if (isPresent(product)) {
            this.storage.store('product', product);
        } else {
            this.storage.clear('product');
        }
    }

    updateTenant(value: Tenant): void {
        this.tenant.next(value);
        if (isPresent(value)) {
            this.storage.store('tenant', value);
        } else {
            this.storage.clear('tenant');
        }
    }

    updateTenants(value: Array<Tenant>): void {
        if (!value.some(v => v.tenant_id === this.tenant_id)) {
            this.updateTenant(null);
        }
        this.tenants.next(value);
    }

    updateParams(items: Array<ProductParam>): void {
        this.params.next(items || []);
    }

    updateProductUser(users: Array<ProductUser>): void {
        users.sort((a, b) => (a.user_name || '').localeCompare(b.user_name));
        this.users.next(users);
        this.isAdmin.next(users?.find(u => u.user_id === this.user_id)?.isAdmin);
    }

    findProductUserById(id: number): ProductUser {
        return this.users.getValue().find(v => v.user_id === +id);
    }

    updateEnvironments(items: Array<ProductEnv>): void {
        this.environments.next(items || []);
    }

    updateToken(token: Token) {
        this.token.next(token);
        if (isPresent(token)) {
            this.storage.store('token', token);
        } else {
            this.storage.clear('token');
        }
    }

    updateUser(user: User) {
        this.user.next(user);
        if (isPresent(user)) {
            this.storage.store('user', user);
        } else {
            this.storage.clear('user');
        }
    }

    updateMenus(menus: Array<Menu>): void {
        this.menus.next(menus);
        this.updateRights(menus);
    }

    updateProMenus(menus: Array<Menu>): void {
        this.proMenus.next(menus);
        this.updateMenus(menus);
    }

    updateRights(menus: Array<Menu>): void {
        const items: Array<any> = [];
        menus.forEach(menu => items.push(...menu.functions));
        this.rights.next(items.map(r => r.function_code));
    }

    updateTopMenus(menus: Array<Menu>): void {
        this.topMenus.next(menus);
    }

    updateProperties(properties: Array<any>): void {
        this.properties.next(properties);
    }

    updatePools(pools: Array<any>): void {
        this.pools.next(pools);
    }

    updateFactories(factories: Array<CaseDataFactory>): void {
        this.factories.next(factories);
    }

    updateRules(rules: Array<DataRule>): void {
        this.rules.next(rules);
    }

    updateRestores(restores: Array<CaseDataRestore>): void {
        this.restores.next(restores);
    }

    updateCacheParams(items: Array<any>): void {
        this.cache_params.next(items);
    }

    updateName(value: string): void {
        this.name.next(value);
    }

    updateCollapse(value: boolean): void {
        if (value) {
            document.body.classList.add('menu-collapse');
        } else {
            document.body.classList.remove('menu-collapse');
        }
        this.collapse.next(value);
    }

    updateShowProduct(value: boolean): void {
        this.showProduct.next(value);
    }

    updateAppConfig(value: AppConfig): void {
        this.appConfig.next(value);
        if (isPresent(value)) {
            this.updateName(value.name);
            this.title.setTitle(value.name);
            this.storage.store('APP_CONFIG', value);
        }
    }

    updateLoginMode(value: Array<LoginMode>): void {
        this.loginModes.next(value);
    }

    updateLoginVerify(value: string): void {
        this.loginVerify.next(value);
    }

    clearToken() {
        this.updateUser(null);
        this.updateToken(null);
        this.updateProduct(null);
        this.updateTenant(null);
        const config = this.appConfig.getValue();
        if (config && config.logout_for_url?.length >= 0) {
            window.location.href = config.logout_for_url;
            return;
        }
        this.router.navigate(['login']);
    }
}
