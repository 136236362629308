import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ConnectedPosition } from '@angular/cdk/overlay/position/flexible-connected-position-strategy';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CONNECTION_POSITIONS } from '../overlay/overlay-position';
import { ComponentFactoryService } from '../providers';


@Component({
    selector: 'ai-search-more',
    templateUrl: 'search.more.component.html',
    styleUrls: ['search.more.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SearchMoreComponent),
        multi: true
    }],
    host: {
        class: 'btn'
    }
})
export class SearchMoreComponent implements OnInit, ControlValueAccessor, OnDestroy {

    @Input() name: string;
    @Input() footer: TemplateRef<any>;

    cRef: ComponentRef<any>;
    origin: CdkOverlayOrigin;
    showOverlay: boolean;
    positions: ConnectedPosition[] = [];

    @ViewChild('vc', { read: ViewContainerRef })
    private vc: ViewContainerRef;

    @ViewChild(CdkConnectedOverlay)
    private overlay: CdkConnectedOverlay;

    private onChange: (_: any) => void;
    private params: any;

    _maxHeight = '';

    constructor(private cdRef: ChangeDetectorRef,
                private elementRef: ElementRef,
                private cfResolver: ComponentFactoryResolver,
                private factoryService: ComponentFactoryService) {
        this.origin = new CdkOverlayOrigin(this.elementRef);
        this.positions = [
            CONNECTION_POSITIONS.bottomCenter,
            new ConnectionPositionPair({
                originX: 'start',
                originY: 'bottom'
            }, {
                overlayX: 'start',
                overlayY: 'top'
            }, -200),
            new ConnectionPositionPair({
                originX: 'start',
                originY: 'bottom'
            }, {
                overlayX: 'end',
                overlayY: 'top'
            }, 100)
        ];
    }

    ngOnInit() {
    }

    @HostListener('click')
    click(): void {
        this.open();
    }

    private open(): void {
        this.showOverlay = true;
        setTimeout(() => {
            let cRef = this.vc.createComponent(this.cfResolver.resolveComponentFactory(this.factoryService.getFactory(this.name)));
            cRef.instance.params = Object.assign({}, this.params);
            cRef.changeDetectorRef.markForCheck();
            this.cRef = cRef;
            this._maxHeight = `calc(100vh - ${this.overlay?.overlayRef?.overlayElement?.offsetTop + 30}px)`;
        });
    }

    hideOverlay(): void {
        this.showOverlay = false;
        this.cdRef.markForCheck();
    }

    dismiss(): void {
        this.hideOverlay();
    }

    reset(): void {
        this.cRef.instance?.reset();
        this.cRef.changeDetectorRef.markForCheck();
    }

    close(): void {
        this.hideOverlay();
        this.params = this.cRef.instance.params;
        this.onChange(this.params);
        this.hideOverlay();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: any): void {
        this.params = obj;
    }

    ngOnDestroy() {
    }
}
