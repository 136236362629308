import { ChangeDetectorRef, Directive, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalHolder } from 'app/global.holder';


@Directive({ selector: '[right-judge]' }) //tslint:disable-line
export class RightJudgeDirective implements OnInit, OnDestroy { 

    @Input('right-judge') right: string;

    rights: Array<string>;

    @Input() have: boolean; // true-有某权限, false-没有某权限
    @Input() mode: string; // some-某一权限, every-所有权限

    private rights$: Subscription;

    constructor(private global: GlobalHolder, private cdRef: ChangeDetectorRef) {
        this.have = true;
    }

    ngOnInit(): void {
        
        this.rights$ = this.global.rights.subscribe(rights => {
            this.rights = rights;
            this.cdRef.markForCheck();
        });
    }

    @HostBinding('class.d-none')
    get hide(): boolean {
        if (this.right === '') {
            return false;
        }
       
        let have: boolean;
        if (this.mode === 'some') {
            have = this.right?.split(',').some(v => this.rights.includes(v)) ?? true
        } else {
            have = this.right?.split(',').every(v => this.rights.includes(v)) ?? true
            // have = this.right?.split(',').every(v =>{this.rights.includes(v)}) ?? true
        }
        return (this.have && !have) || (!this.have && have);
    }

    ngOnDestroy(): void {
        this.rights$.unsubscribe();
    }
}
