import { Component, Input, OnInit } from '@angular/core';
import { DrawerRef } from '../drawer';

@Component({
    selector: 'image-view', // tslint:disable-line
    templateUrl: 'image.view.component.html',
    styleUrls: ['image.view.scss']
})

export class ImageViewComponent implements OnInit {

    @Input() index: number;
    @Input() images: Array<string> = [];

    constructor(public drawerRef: DrawerRef) {
    }

    ngOnInit() {
    }

    changeIndex(step: number): void {
        let index = this.index + step;
        if (index < 0) {
            index = this.images.length - 1;
        } else if (index >= this.images.length) {
            index = 0;
        }
        this.index = index;
    }
}
