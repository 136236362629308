import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { User } from 'app/system/user/user.model';
import { DrawerRef } from 'share';
import { UserGroup } from 'app/product/group/user.group.model';
import { UserGroupService } from 'app/product/group/user.group.service';

@Component({
    selector: 'ai-product-user-group',
    templateUrl: 'product.user.group.component.html'
})
export class ProductUserGroupComponent implements OnInit, OnDestroy {

    @Input() users: User[];
    @Input() editable: boolean;

    group: UserGroup = {};

    constructor(public drawerRef: DrawerRef, private groupService: UserGroupService) {
    }

    ngOnInit() {
    }

    submit(): void {
        const items = this.users.map(v => ({ group_id: this.group.group_id, user_id: v.user_id }));
        this.groupService.updateRelation(items).subscribe(() => {
            toastr.success('关联成员分组成功');
            this.drawerRef.close();
        });
    }

    ngOnDestroy(): void {
    }
}
