import { Component, OnInit } from '@angular/core';
import { RouteHolder } from 'core';

@Component({
    selector: 'ai-app',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

    constructor(private holder: RouteHolder) {
    }
 
    ngOnInit(): void {
        NProgress.done();
        this.holder.init();
    }
}
