import { Component, OnDestroy, OnInit } from '@angular/core';
import { DrawerRef } from '../../drawer';

@Component({
    selector: 'ai-edit-image',
    templateUrl: 'image.component.html'
})
export class ImageDialogComponent implements OnInit, OnDestroy {

    file: File;

    constructor(public drawerRef: DrawerRef) {
    }

    ngOnInit(): void {
    }

    change(event: Event): void {
        let target = event.target as HTMLInputElement;
        this.file = target.files[0];
    }

    submit(): void {
        this.drawerRef.close([this.file]);
    }

    ngOnDestroy(): void {
    }
}
