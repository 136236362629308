import { Role, RoleCodes } from 'app/system/role/role.model';
import { isAdmin } from 'app/system/user/user.model';
import { Checkable } from 'share';

export interface Product {

    email?: string;
    manager?: string;
    manager_face?: string;
    mobile?: string;
    product_bu?: string;
    product_depart?: string;
    product_desc?: string;
    product_id?: number;
    product_logo?: string;
    product_name?: string;
    tenant_id?: number;
    rev_lib?: string;
    status?: number;
    create_user?: number;
    user_name?: string;
    create_time?: string;
    color?: string;
    is_admin?: number;
    role_ids?: Array<number>;

    tenant_code?: string;

    checked?: boolean;
    disabled?: boolean;
}

export class ProductUser implements Checkable {

    product_id?: number;
    staff_code?: string;
    user_id?: number;
    user_name?: string;
    is_admin?: number;
    data_right: number;
    editable: boolean;
    current_app_name?: string;
    mobile?: string;
    email?: string;
    status?: number;
    roles: Array<Role> = [];

    selected?: boolean;
    checked?: boolean;

    constructor(item: {
        product_id?: number;
        staff_code?: string;
        user_id?: number;
        user_name?: string;
        is_admin?: number;
        data_right?: number;
        mobile?: string;
        email?: string;
        current_app_name?: string;
    } = {}) {
        Object.assign(this, item);
        this.selected = false;
        this.checked = false;
    }

    get isAdmin(): boolean {
        return isAdmin(this) || this.roles.some(r => r.role_code === RoleCodes.PRODUCT_ADMIN);
        // return isAdmin(this) || this.is_admin === 1;
    }

    get isTestLeader(): boolean {
        return this.roles.some(r => r.role_code === RoleCodes.PRODUCT_LEADER);
        // return (this.data_right & 1) === 1;
    }

    get isAuditor(): boolean {
        return this.roles.some(r => r.role_code === RoleCodes.PRODUCT_AUDITOR);
        // return (this.data_right & 2) === 2;
    }

    get isTester(): boolean {
        return this.roles.some(r => r.role_code === RoleCodes.PRODUCT_TESTER);
        // return (this.data_right & 4) === 4;
    }

    get isAuto(): boolean {
        return this.roles.some(r => r.role_code === RoleCodes.PRODUCT_AUTOR);
        // return (this.data_right & 8) === 8;
    }
}

export type UserType = 'tester' | 'auditor' | 'auto' | 'testLeader' | 'admin';

export interface UserModel {
    user_id?: number;
    staff_code?: string;
    user_name?: string;
    email?: string;
    mobile?: string;
    selected?: boolean;
    disabled?: boolean;
}

export interface ProductParam {
    id?: number;
    product_id?: number;
    param_desc?: string;
    param_name?: string;
    param_value?: string;
    param_type?: string;
    order?: string;
}

export interface ProductEnv {
    env_id?: number;
    env_name?: string;
    env_desc?: string;
    is_default?: number;
    active?: boolean;
    product_id?: number;
    params?: Array<EnvParam>;

    checked?: boolean;
}

export interface EnvParam extends ProductParam {
    env_id?: number;
    operate_type?: string;
}

export interface DatabaseParam {
    type?: string;
    name?: string;
    url?: string;
    username?: string;
    password?: string;
    old_password?: string;
    password_type?: string;
    model?: string;
    cluster?: string;
    tenant?: string;
}

export interface GlobalParam {
    type?: string;
}

export interface FixedGlobalParam extends GlobalParam {
    fixedValue?: string;
    oldFixedValue?: string;
}

export interface SqlGlobalParam extends GlobalParam {
    dbName?: string;
    sqlValue?: string;
}

export interface InterfaceGlobalParam extends GlobalParam {
    interfaceId?: number;
    interfaceName?: string;
    respParam?: string;
    prefix?: string;
    postfix?: string;
    failureTime?: number;
}

export interface RedisParam {
    host_name?: number;
    port?: string;
    password?: string;
    db_idx?: number;
}

export function getUserFilter(type: UserType) {
    switch (type) {
        case 'tester':
            return (user: ProductUser) => user.isTester;
        case 'testLeader':
            return (user: ProductUser) => user.isTestLeader;
        case 'auditor':
            return (user: ProductUser) => user.isAuditor;
        case 'auto':
            return (user: ProductUser) => user.isAuto;
        case 'admin':
            return (user: ProductUser) => user.isAdmin;
        default:
            return () => true;
    }
}
