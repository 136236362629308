import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OutletModule } from '../outlet/outlet.module';
import { AiOverlayModule } from '../overlay/overlay.module';
import { ToolTipModule } from '../tooltip';

import { PopConfirmComponent, PopConfirmDirective } from './popconfirm';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        ToolTipModule,
        AiOverlayModule,
        OutletModule
    ],
    exports: [
        PopConfirmComponent,
        PopConfirmDirective
    ],
    declarations: [
        PopConfirmComponent,
        PopConfirmDirective
    ],
    entryComponents: [
        PopConfirmComponent
    ]
})
export class PopConfirmModule {
}
