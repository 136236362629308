import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RouteHolder {

    private _urls: Array<string> = [];

    constructor(private router: Router) {
    }

    init(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => this._urls.push(event.url));
    }

    get urls(): Array<string> {
        return this._urls;
    }

    get prevUrl(): string {
        return this._urls[this._urls.length - 2];
    }
}
