import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FileBtnComponent } from './file.btn.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        FileBtnComponent
    ],
    declarations: [
        FileBtnComponent
    ]
})
export class UploadModule {
}
