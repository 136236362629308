export enum ModalClass {
    SIZE_4 = 'modal-size-4',
    SIZE_5 = 'modal-size-5',
    SIZE_6 = 'modal-size-6',
    SIZE_7 = 'modal-size-7',
    SIZE_8 = 'modal-size-8',
    SIZE_9 = 'modal-size-9',
    SIZE_10 = 'modal-size-10',
    H_100 = 'modal-full-height',
    RIGHT = 'modal-right',
    TREE = 'modal-tree-win',
    PAGE = 'modal-page'
}
