import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import { Subject } from 'rxjs';
import { InputBoolean } from '../facade';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    selector: 'ai-step',
    exportAs: 'step',
    preserveWhitespaces: false,
    templateUrl: 'step.component.html',
    host: {
        class: 'steps-item',
        '[class.steps-item-wait]': 'status === "wait"',
        '[class.steps-item-process]': 'status === "process"',
        '[class.steps-item-finish]': 'status === "finish"',
        '[class.steps-item-error]': 'status === "error"',
        '[class.steps-item-active]': 'currentIndex === index',
        '[class.steps-item-disabled]': 'disabled',
        '[class.steps-item-custom]': '!!icon',
        '[class.steps-next-error]': '(outStatus === "error") && (currentIndex === index + 1)'
    }
})
export class StepComponent implements OnDestroy {

    @ViewChild('processDotTemplate', { static: false }) processDotTemplate: TemplateRef<void>;

    @Input() title: string | TemplateRef<void>;
    @Input() subtitle: string | TemplateRef<void>;
    @Input() description: string | TemplateRef<void>;

    @Input() @InputBoolean() disabled = false;
    @Input() @InputBoolean() clickable = true;

    isIconString = true;
    customProcessTemplate: TemplateRef<{ $implicit: TemplateRef<void>; status: string; index: number }>; // Set by parent.
    direction = 'horizontal';
    index = 0;
    last = false;
    outStatus = 'process';
    showProcessDot = false;
    isCustomStatus = false;

    click$ = new Subject<number>();

    private _icon: string | TemplateRef<void>;
    private _currentIndex = 0;
    private _status = 'wait';

    constructor(private cdr: ChangeDetectorRef) {
    }

    @Input()
    get status(): string {
        return this._status;
    }

    set status(status: string) {
        this._status = status;
        this.isCustomStatus = true;
    }

    @Input()
    get icon(): string | TemplateRef<void> {
        return this._icon;
    }

    set icon(value: string | TemplateRef<void>) {
        this.isIconString = !(value instanceof TemplateRef);
        this._icon = value;
    }

    get currentIndex(): number {
        return this._currentIndex;
    }

    set currentIndex(current: number) {
        this._currentIndex = current;
        if (!this.isCustomStatus) {
            this._status = current > this.index ? 'finish' : current === this.index ? this.outStatus || '' : 'wait';
        }
    }

    onClick(): void {
        if (this.clickable && this.currentIndex !== this.index && !this.disabled) {
            this.click$.next(this.index);
        }
    }

    markForCheck(): void {
        this.cdr.markForCheck();
    }

    ngOnDestroy(): void {
        this.click$.complete();
    }
}
