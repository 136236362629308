import {
    AfterContentInit,
    ChangeDetectorRef,
    ContentChildren,
    Directive,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    QueryList,
    SimpleChanges,
    SkipSelf
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputBoolean } from '../facade/convert';
import { MenuItemDirective } from './menu-item.directive';
import { MenuService } from './menu.service';
import { IsMenuInsideDropDownToken, MenuServiceLocalToken } from './menu.token';
import { MenuModeType, MenuThemeType } from './menu.types';
import { SubMenuComponent } from './submenu.component';

export function MenuServiceFactory(serviceInsideDropDown: MenuService, serviceOutsideDropDown: MenuService): MenuService {
    return serviceInsideDropDown ? serviceInsideDropDown : serviceOutsideDropDown;
}

export function MenuDropDownTokenFactory(isMenuInsideDropDownToken: boolean): boolean {
    return isMenuInsideDropDownToken ? isMenuInsideDropDownToken : false;
}

@Directive({
    selector: '[ai-menu]', //tslint:disable-line
    exportAs: 'aiMenu',
    providers: [
        {
            provide: MenuServiceLocalToken,
            useClass: MenuService
        },
        {
            provide: MenuService,
            useFactory: MenuServiceFactory,
            deps: [[new SkipSelf(), new Optional(), MenuService], MenuServiceLocalToken]
        },
        {
            provide: IsMenuInsideDropDownToken,
            useFactory: MenuDropDownTokenFactory,
            deps: [[new SkipSelf(), new Optional(), IsMenuInsideDropDownToken]]
        }
    ],
    host: {
        '[class.ai-dropdown-menu]': `isMenuInsideDropDown`,
        '[class.ai-dropdown-menu-root]': `isMenuInsideDropDown`,
        '[class.ai-dropdown-menu-light]': `isMenuInsideDropDown && theme === 'light'`,
        '[class.ai-dropdown-menu-dark]': `isMenuInsideDropDown && theme === 'dark'`,
        '[class.ai-dropdown-menu-vertical]': `isMenuInsideDropDown && actualMode === 'vertical'`,
        '[class.ai-dropdown-menu-horizontal]': `isMenuInsideDropDown && actualMode === 'horizontal'`,
        '[class.ai-dropdown-menu-inline]': `isMenuInsideDropDown && actualMode === 'inline'`,
        '[class.ai-dropdown-menu-inline-collapsed]': `isMenuInsideDropDown && inlineCollapsed`,
        '[class.ai-menu]': `!isMenuInsideDropDown`,
        '[class.ai-menu-root]': `!isMenuInsideDropDown`,
        '[class.ai-menu-light]': `!isMenuInsideDropDown && theme === 'light'`,
        '[class.ai-menu-dark]': `!isMenuInsideDropDown && theme === 'dark'`,
        '[class.ai-menu-vertical]': `!isMenuInsideDropDown && actualMode === 'vertical'`,
        '[class.ai-menu-horizontal]': `!isMenuInsideDropDown && actualMode === 'horizontal'`,
        '[class.ai-menu-inline]': `!isMenuInsideDropDown && actualMode === 'inline'`,
        '[class.ai-menu-inline-collapsed]': `!isMenuInsideDropDown && inlineCollapsed`
    }
})
export class MenuDirective implements AfterContentInit, OnInit, OnChanges, OnDestroy {

    @ContentChildren(MenuItemDirective, { descendants: true }) listOfMenuItemDirective: QueryList<MenuItemDirective>;
    @ContentChildren(SubMenuComponent, { descendants: true }) listOfSubMenuComponent: QueryList<SubMenuComponent>;

    @Input() inlineIndent = 24;
    @Input() theme: MenuThemeType = 'light';
    @Input() mode: MenuModeType = 'vertical';
    @Input() @InputBoolean() inlineCollapsed = false;
    @Input() @InputBoolean() selectable = !this.isMenuInsideDropDown;
    @Output() readonly ngClick = new EventEmitter<MenuItemDirective>();

    actualMode: MenuModeType = 'vertical';

    private inlineCollapsed$ = new BehaviorSubject<boolean>(this.inlineCollapsed);
    private mode$ = new BehaviorSubject<MenuModeType>(this.mode);
    private destroy$ = new Subject();
    private listOfOpenedNzSubMenuComponent: SubMenuComponent[] = [];

    constructor(private menuService: MenuService, @Inject(IsMenuInsideDropDownToken) public isMenuInsideDropDown: boolean, private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        combineLatest([this.inlineCollapsed$, this.mode$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([inlineCollapsed, mode]) => {
                this.actualMode = inlineCollapsed ? 'vertical' : mode;
                this.menuService.setMode(this.actualMode);
                this.cdr.markForCheck();
            });
        this.menuService.descendantMenuItemClick$.pipe(takeUntil(this.destroy$)).subscribe(menu => {
            this.ngClick.emit(menu);
            if (this.selectable && !menu.matchRouter) {
                this.listOfMenuItemDirective.forEach(item => item.setSelectedState(item === menu));
            }
        });
    }

    ngAfterContentInit(): void {
        this.inlineCollapsed$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.updateInlineCollapse();
            this.cdr.markForCheck();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { inlineCollapsed, inlineIndent, theme, mode } = changes;
        if (inlineCollapsed) {
            this.inlineCollapsed$.next(this.inlineCollapsed);
        }
        if (inlineIndent) {
            this.menuService.setInlineIndent(this.inlineIndent);
        }
        if (theme) {
            this.menuService.setTheme(this.theme);
        }
        if (mode) {
            this.mode$.next(this.mode);
            if (!changes.mode.isFirstChange() && this.listOfSubMenuComponent) {
                this.listOfSubMenuComponent.forEach(submenu => submenu.setOpenStateWithoutDebounce(false));
            }
        }
    }


    updateInlineCollapse(): void {
        if (!this.listOfMenuItemDirective) {
            return;
        }

        if (this.inlineCollapsed) {
            this.listOfOpenedNzSubMenuComponent = this.listOfSubMenuComponent.filter(submenu => submenu.open);
            this.listOfSubMenuComponent.forEach(submenu => submenu.setOpenStateWithoutDebounce(false));
        } else {
            this.listOfOpenedNzSubMenuComponent.forEach(submenu => submenu.setOpenStateWithoutDebounce(true));
            this.listOfOpenedNzSubMenuComponent = [];
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
