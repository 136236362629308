import { ConnectedOverlayPositionChange, ConnectionPositionPair } from '@angular/cdk/overlay';

export const CONNECTION_POSITIONS = {
    top: new ConnectionPositionPair({
        originX: 'center',
        originY: 'top'
    }, {
        overlayX: 'center',
        overlayY: 'bottom'
    }),
    topCenter: new ConnectionPositionPair({
        originX: 'center',
        originY: 'top'
    }, {
        overlayX: 'center',
        overlayY: 'bottom'
    }),
    topLeft: new ConnectionPositionPair({
        originX: 'start',
        originY: 'top'
    }, {
        overlayX: 'start',
        overlayY: 'bottom'
    }),
    topRight: new ConnectionPositionPair({
        originX: 'end',
        originY: 'top'
    }, {
        overlayX: 'end',
        overlayY: 'bottom'
    }),
    right: new ConnectionPositionPair({
        originX: 'end',
        originY: 'center'
    }, {
        overlayX: 'start',
        overlayY: 'center'
    }),
    rightTop: new ConnectionPositionPair({
        originX: 'end',
        originY: 'top'
    }, {
        overlayX: 'start',
        overlayY: 'top'
    }),
    rightBottom: new ConnectionPositionPair({
        originX: 'end',
        originY: 'bottom'
    }, {
        overlayX: 'start',
        overlayY: 'bottom'
    }),
    bottom: new ConnectionPositionPair({
        originX: 'center',
        originY: 'bottom'
    }, {
        overlayX: 'center',
        overlayY: 'top'
    }),
    bottomCenter: new ConnectionPositionPair({
        originX: 'center',
        originY: 'bottom'
    }, {
        overlayX: 'center',
        overlayY: 'top'
    }),
    bottomLeft: new ConnectionPositionPair({
        originX: 'start',
        originY: 'bottom'
    }, {
        overlayX: 'start',
        overlayY: 'top'
    }),
    bottomRight: new ConnectionPositionPair({
        originX: 'end',
        originY: 'bottom'
    }, {
        overlayX: 'end',
        overlayY: 'top'
    }),
    left: new ConnectionPositionPair({
        originX: 'start',
        originY: 'center'
    }, {
        overlayX: 'end',
        overlayY: 'center'
    }),
    leftTop: new ConnectionPositionPair({
        originX: 'start',
        originY: 'top'
    }, {
        overlayX: 'end',
        overlayY: 'top'
    }),
    leftBottom: new ConnectionPositionPair({
        originX: 'start',
        originY: 'bottom'
    }, {
        overlayX: 'end',
        overlayY: 'bottom'
    })
};

export const DEFAULT_TOOLTIP_POSITIONS = [CONNECTION_POSITIONS.top, CONNECTION_POSITIONS.right, CONNECTION_POSITIONS.bottom, CONNECTION_POSITIONS.left];

export function getPlacementName(position: ConnectedOverlayPositionChange): string | undefined {
    for (const placement in CONNECTION_POSITIONS) {
        if (
            position.connectionPair.originX === CONNECTION_POSITIONS[placement].originX &&
            position.connectionPair.originY === CONNECTION_POSITIONS[placement].originY &&
            position.connectionPair.overlayX === CONNECTION_POSITIONS[placement].overlayX &&
            position.connectionPair.overlayY === CONNECTION_POSITIONS[placement].overlayY
        ) {
            return placement;
        }
    }
    return undefined;
}
