import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const PLUGIN = new InjectionToken<Array<Plugin<any, any>>>('plugin');

export interface Plugin<I, O> {

    support(context: I): boolean;

    execute(input: I): Observable<O>;
}

export class PluginWare<I, O> {

    constructor(private plugins: Plugin<I, O>[]) {
    }

    getPlugin(input: I): Plugin<I, O> {
        return this.plugins.filter(p => p.support(input))[0];
    }
}
