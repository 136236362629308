import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalHolder } from 'app/global.holder';
import { Product } from 'app/product/product.model';
import { AiComponent } from 'core';

@Component({
    selector: 'ai-product-name',
    templateUrl: 'product.name.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
@AiComponent()
export class ProductNameComponent implements OnInit, OnDestroy {

    product: Product;

    show: boolean;

    constructor(private global: GlobalHolder, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        let product$ = this.global.product.subscribe(item => {
            this.product = item;
            this.cdRef.markForCheck();
        });
        this.ngAdd$(product$);

        let show$ = this.global.showProduct.subscribe(item => {
            this.show = item;
            this.cdRef.markForCheck();
        });
        this.ngAdd$(show$);
    }


    ngOnDestroy() {
    }
}
