import { Observable } from 'rxjs';

export abstract class ModalLegacyAPI<T, R> {

    abstract afterOpen: Observable<void>;
    abstract afterClose: Observable<R>;
    abstract result: Observable<R>;

    abstract close(result?: R): void;

    abstract dismiss(): void;

}
