import { Component, OnDestroy, OnInit } from '@angular/core';
import { DrawerRef } from '../../drawer';
import { FileBaseService } from '../../providers';

@Component({
    selector: 'ai-edit-video',
    templateUrl: 'video.component.html'
})
export class VideoDialogComponent implements OnInit, OnDestroy {

    file: File;

    constructor(public drawerRef: DrawerRef, private fileService: FileBaseService) {
    }

    ngOnInit(): void {
    }

    change(event: Event): void {
        let target = event.target as HTMLInputElement;
        this.file = target.files[0];
    }

    submit(): void {
        this.fileService.upload(this.file).subscribe(path => this.drawerRef.close(`${this.fileService.filePrefix()}/${path}`));
    }

    ngOnDestroy(): void {
    }
}
