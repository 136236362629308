import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ShareModule } from 'share';
import { LoginComponent } from './login.component';
import { loginRoute } from './login.route';

@NgModule({
    imports: [
        loginRoute,
        FormsModule,
        ShareModule,
        CommonModule
    ],
    declarations: [
        LoginComponent
    ]
})
export class LoginModule {
}
