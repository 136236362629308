import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';

import { TimePickerPanelComponent } from './time-picker-panel.component';
import { TimePickerComponent } from './time-picker.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        OverlayModule,
        ScrollbarModule
    ],
    exports: [
        TimePickerPanelComponent,
        TimePickerComponent
    ],
    declarations: [
        TimePickerComponent,
        TimePickerPanelComponent
    ]
})
export class TimePickerModule {
}
