import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DrawerRef, isPresent } from 'share';
import { User } from 'app/system/user/user.model';
import { Staff } from 'app/system/staff/staff.model';
import { Tenant, TenantUser } from 'app/system/tenant/tenant.model';
import { GlobalHolder } from 'app/global.holder';
import { UserService } from 'app/system/user/user.service';
import { StaffService } from 'app/system/staff/staff.service';
import { TenantService } from 'app/system/tenant/tenant.service';


@Component({
    selector: 'ai-tenant-user',
    templateUrl: 'tenant.user.component.html'
})
export class TenantUserComponent implements OnInit, OnDestroy {

    @Input() item: Tenant;

    type = 1;
    name: string;
    pageNo: number;
    totalPage: number;
    allowNgLogin: boolean;

    users: Array<User> = [];
    items: Array<TenantUser> = [];

    constructor(public drawerRef: DrawerRef,
                private global: GlobalHolder,
                private tenantService: TenantService,
                private staffService: StaffService,
                private userService: UserService) {
        this.pageNo = 1;
        this.totalPage = 0;
        this.allowNgLogin = this.global.allowNgLogin;
    }

    ngOnInit(): void {
        this.tenantService.qryUsers(this.item.tenant_id).subscribe(items => {
            this.items = items;
            this.qryUser();
        });
        this.qryUser();
    }

    changeType(type: number): void {
        if (this.type === type) {
            return;
        }
        this.type = type;
        this.pageNo = 1;
        this.qryUser();
    }

    qryUser(): void {
        let observable: Observable<any>;
        if (this.type === 1) {
            observable = this.userService.qrySystemUserByPage({
                name: this.name,
                page_no: this.pageNo,
                page_size: 15
            });
        } else {
            observable = this.staffService.qryByPage({
                name: this.name,
                page_no: this.pageNo,
                page_size: 15
            });
        }
        const checkedIds = this.items.map(item => item.user_id);
        observable.subscribe(page => {
            this.totalPage = page.page_count;
            this.users = (page.page_data || []).map(user => ({
                ...user,
                checked: checkedIds.includes(user.user_id),
                user_name: user.staff_name ? `${user.staff_name}(${user.nt_account})` : user.nt_account,
            }));
        });
    }

    goPage(page: number): void {
        this.pageNo = page;
        this.qryUser();
    }

    select(user: User): void {
        let exist = this.items.find(item => item.user_id === user.user_id);
        if (!user.checked) {
            this._del(exist);
            return;
        }

        if (isPresent(exist)) {
            delete exist.operate_type;
        } else {
            this.items.push({
                staff_code: user.staff_code,
                user_id: user.user_id,
                user_name: user.user_name,
                operate_type: 'C'
            });
        }
    }

    del(item: TenantUser): void {
        this._del(item);
        let user = this.users.find(v => v.user_id === item.user_id);
        if (isPresent(user)) {
            user.checked = false;
        }
    }

    private _del(item: TenantUser): void {
        if (!isPresent(item)) {
            return;
        }
        if (item.operate_type === 'C') {
            this.items.remove(item);
        } else {
            item.operate_type = 'D';
        }
    }

    submit(): void {
        const staffUsers = new Map<string, any>();
        const items = this.items.filter(item => isPresent(item.operate_type));
        items.filter(item => item.user_id === undefined).forEach(item => staffUsers.set(item.staff_code, item));

        let observable: Observable<Array<Staff>>;
        if (staffUsers.size > 0) {
            observable = this.staffService.registerStaff([...staffUsers.keys()].map(code => ({ staff_code: code })));
        } else {
            observable = of([]);
        }

        observable.pipe(
            mergeMap<Array<Staff>, any>(staffs => {
                staffs.forEach(staff => {
                    if (!staffUsers.has(staff.staff_code)) {
                        return;
                    }
                    const user = staffUsers.get(staff.staff_code);
                    user.user_id = staff.user_id;
                    delete user.staff_code;
                });
                return this.tenantService.configUser(this.item.tenant_id, items);
            })
        ).subscribe(() => {
            toastr.success('配置租户用户成功');
            this.drawerRef.close();
        });
    }

    ngOnDestroy(): void {
    }
}
