import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EditableHolder {

    editable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    updateEditable(editable: boolean): void {
        if (editable === this.editable.getValue()) {
            return;
        }
        this.editable.next(editable);
    }

}
