import { Pipe, PipeTransform } from '@angular/core';
import { CaseStepParam } from 'app/case/case.model';
import { CaseProperty } from 'app/case/prop';
import { isPresent } from 'share';
import {
    CASE_EVAL_KEYS,
    CASE_EVAL_NAMES,
    CASE_MODE_KEYS,
    CASE_MODE_NAMES,
    CASE_TYPE_CLASSES,
    CASE_TYPE_KEYS,
    CASE_TYPE_NAMES,
    STEP_TYPE_NAMES
} from '../constants/case.constants';
import { modeFormatter, ValueMode } from '../model/value.mode';
import { NodeType } from '../node';

@Pipe({
    name: 'caseMode'
})
export class CaseModePipe implements PipeTransform {

    transform(value: any): any {
        return CASE_MODE_NAMES[CASE_MODE_KEYS.indexOf(value)];
    }
}


export const OPERATIONS =
    [
        { id: 'jsonEqual', name: 'json全等' },
        { id: '==', name: '等于' },
        { id: '!=', name: '不等于' },
        { id: '>=', name: '大于等于' },
        { id: '>', name: '大于' },
        { id: '<=', name: '小于等于' },
        { id: '<', name: '小于' },
        { id: 'contains', name: '包含' },
        { id: 'notcontains', name: '不包含' },
        { id: 'in', name: 'in' },
        { id: 'notin', name: 'not in' }
    ];

@Pipe({
    name: 'verifyOperator'
})
export class VerifyOperatorPipe implements PipeTransform {
    transform(value: string): string {
        let operation = OPERATIONS.find(item => item.id === value);
        return operation ? operation.name : '';
    }
}

@Pipe({
    name: 'casePropType'
})
export class CasePropTypePipe implements PipeTransform {
    private typeMap = { 'SCENE': '场景域', 'CASE': '用例域', 'CONTEXT': '上下文' };

    transform(value: any): any {
        return this.typeMap[value] || '';
    }
}

@Pipe({
    name: 'casePropValueType'
})
export class CasePropValueTypePipe implements PipeTransform {
    private typeMap = { 'NORMAL': '初始化', 'CURRENT': '实时获取' };

    transform(value: any): any {
        return this.typeMap[value] || '';
    }
}

@Pipe({
    name: 'verifyType'
})
export class VerifyTypePipe implements PipeTransform {
    transform(value: string): string {
        if (value === 'RESP') {
            return '响应体';
        } else if (value === 'HEAD') {
            return '响应头';
        } else if (value === 'OUT_PARAM') {
            return '出参';
        } else if (value === 'IN_PARAM') {
            return '入参';
        } else if (value === 'STATUS_CODE') {
            return '响应码';
        } else if (value === 'DB_FILE') {
            return '数据文件';
        } else if (value === 'PROP') {
            return '上下文';
        } else if (value === 'IN_OUT_PARAM') {
            return '用例参数';
        }
        return 'SQL验证';
    }
}

@Pipe({
    name: 'valueMode'
})
export class CaseValueModePipe implements PipeTransform {

    transform(value: ValueMode): any {
        return modeFormatter(value) || '';
    }
}

@Pipe({
    name: 'stepType'
})
export class StepTypePipe implements PipeTransform {

    transform(value: any): any {
        return STEP_TYPE_NAMES[CASE_TYPE_KEYS.indexOf(value)];
    }
}

@Pipe({
    name: 'caseType'
})
export class CaseTypePipe implements PipeTransform {

    transform(value: any): any {
        return CASE_TYPE_NAMES[CASE_TYPE_KEYS.indexOf(value)];
    }
}


@Pipe({
    name: 'caseTypeClass'
})
export class CaseTypeClassPipe implements PipeTransform {

    transform(value: any): any {
        return CASE_TYPE_CLASSES[CASE_TYPE_KEYS.indexOf(value)];
    }
}

@Pipe({
    name: 'evalType'
})
export class CaseEvalTypePipe implements PipeTransform {

    transform(value: any): any {
        return CASE_EVAL_NAMES[CASE_EVAL_KEYS.indexOf(value)];
    }
}

@Pipe({
    name: 'executeResultStatus'
})
export class ExecuteResultStatusPipe implements PipeTransform {
    transform(status: number): string {
        if (status === 0) {
            return '等待执行';
        }
        if (status === 1) {
            return '正在执行';
        }
        if (status === 9) {
            return '脚本运行结束';
        }
        if (status === 10) {
            return '成功';
        }
        if (status === 11) {
            return '失败';
        }
        if (status === 12) {
            return '超时';
        }
        return '未知';
    }
}

@Pipe({
    name: 'caseCheckResultStatus'
})
export class CaseCheckResultStatusPipe implements PipeTransform {
    transform(status: string): string {
        if (status === 'SUC') {
            return '成功';
        }
        if (status === 'FAIL') {
            return '失败';
        }
        if (status === 'UNCHK') {
            return '未检查';
        }
        return '未知';
    }
}

@Pipe({
    name: 'verifyRespName'
})
export class VerifyRespNamePipe implements PipeTransform {
    transform(respName: string): string {
        return respName ? respName.replace(/%[0-9]*/g, '') || '根节点' : '根节点';
    }
}


@Pipe({
    name: 'verifyContent'
})
export class VerifyContentPipe implements PipeTransform {
    transform(content: string, type: string): string {
        if (type === 'RESP') {
            return content ? content.replace(/%[0-9]*/g, '') || '根节点' : '根节点';
        } else if (type === 'STATUS_CODE') {
            return '响应码';
        } else {
            return content;
        }
    }
}

@Pipe({
    name: 'filterCaseProp',
    pure: false
})
export class CasePropFilterPipe implements PipeTransform {
    transform(value: Array<CaseProperty>, isContext?: boolean): Array<CaseProperty> {
        return value.filter(prop => prop.operate_type !== 'D' && prop.prop_type !== 'OUT' && prop.prop_name && (isContext !== true || prop.prop_type === 'CONTEXT'));
    }
}

@Pipe({
    name: 'stepParamName',
    pure: false
})
export class StepParamNamePipe implements PipeTransform {

    transform(value: CaseStepParam): any {
        return this.paramName(value);
    }

    paramName(param: CaseStepParam): string {
        if (!isPresent(param.parent)) {
            return param.param_name;
        }
        let parent = param.parent;
        let type = parent.type;
        if (type & NodeType.Array) {
            return `${this.paramName(parent)}[${parent.children.filter(item => item.operate_type !== 'D').findIndex(item => item === param)}]`;
        }
        return param.param_name;
    }
}
