import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoginMode } from 'app/app.model';
import { GlobalHolder } from 'app/global.holder';
import { GuardInitializer } from 'app/guard/guard.initializer';
import { SysParamService, SysParamType } from 'core';
import { Observable, of } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class LoginModeGuardService implements CanActivate, GuardInitializer {

    constructor(private router: Router,
                private global: GlobalHolder,
                private paramService: SysParamService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.init();
    }

    init(): Observable<boolean> {
        if (isPresent(this.global.loginModes.getValue())) {
            return of(true);
        }
        return this.paramService.qry(SysParamType.LOGIN_MODE).pipe(
            tap(items =>
                this.global.updateLoginMode(items.map<LoginMode>(item => ({
                    type: item.param_key,
                    value: +item.param_value,
                    name: item.param_desc
                })))
            ),
            mapTo(true)
        );
    }


}
