import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Group } from './group.model';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class GroupService {

    protected baseUrl = '/xltp/prod/groups';
    protected baseUrl1 = '/xltp/prod/group';

    constructor(protected http: HttpClient) {
    }

    qryGroup(productId: number, type: string, parentId?: number): Observable<Array<Group>> {
        let params = new HttpParams().set('group_type', type);
        if (isPresent(productId)) {
            params = params.set('product_id', `${productId}`);
        }
        if (parentId !== undefined) {
            params = params.set('parent_id', `${parentId}`);
        }
        return this.http.get(`${this.baseUrl}`, { params }).pipe(map<any, Array<Group>>(items => items || []));
    }

    qry(params: any): Observable<Array<Group>> {
        return this.http.get(`${this.baseUrl}`, { params }).pipe(map<any, Array<Group>>(items => items || []));
    }

    addGroup(group: Group): Observable<any> {
        return this.http.post(`${this.baseUrl1}`, group);
    }

    updateGroup(group: Group): Observable<any> {
        return this.http.put(`${this.baseUrl1}/${group.group_id}`, group);
    }

    deleteGroup(id: number): Observable<any> {
        let headers = new HttpHeaders().set(Headers.Confirm, '');
        return this.http.delete(`${this.baseUrl1}/${id}`, { headers });
    }

    modOrder(id: number, step: number): Observable<any> {
        let headers = new HttpHeaders().set(Headers.BLOCK, '正在移动...');
        return this.http.post(`${this.baseUrl1}/${id}/move/${step}`, {}, { headers });
    }

    qryPath(id: number): Observable<Array<any>> {
        return this.http.get(`${this.baseUrl1}/${id}/absolutepath`).pipe(map<any, Array<any>>(items => items || []));
    }
}
