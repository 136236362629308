import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers, TokenConstant } from 'app/app.constants';
import { Role } from 'app/system/role/role.model';
import { IPageService, MAX_PAGE_SIZE } from 'core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Menu } from '../menu/menu';
import { IDCard, User } from './user.model';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class UserService implements IPageService {

    private baseUrl = '/xltp/sys/user';
    private baseUrluser = '/xltp/sys/applyFor/applyForTrial';
    

    constructor(private http: HttpClient) {
    }

    qryByPage(params: { page_no?: number; page_size?: number; [p: string]: any }): Observable<any> {
        return this.http.get(this.baseUrl, { params });
    }

    qryAll(): Observable<Array<User>> {
        return this.qryByPage({ page_no: 1, page_size: MAX_PAGE_SIZE }).pipe(map(page => page.page_data || []));
    }

    qrySystemUserByPage(params: { page_no?: number; page_size?: number; [p: string]: any }): Observable<any> {
        return this.http.get(`${this.baseUrl}/register`, { params });
    }

    qryById(id: number): Observable<User> {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

    add(user: User): Observable<any> {
        user = Object.assign({}, user);
        user.pwd = TokenConstant.encrypt(user.pwd);
        return this.http.post(`${this.baseUrl}/add`, user);
    }

    register(user: User): Observable<any> {
        user = Object.assign({}, user);
        // delete user.pwd_confirm;
        // user.pwd = TokenConstant.encrypt(user.pwd);
        let headers = new HttpHeaders()
            .set('Authorization', `Basic ${TokenConstant.CLIENT_SECRET}`)
            .set(Headers.NEED_AUTHORIZATION, 'false');
        return this.http.post(`${this.baseUrluser}`, user, { headers });
    }

    mod(user: User): Observable<any> {
        return this.http.put(`${this.baseUrl}/${user.user_id}`, user);
    }

    del(user: User): Observable<any> {
        return this.http.delete(`${this.baseUrl}/${user.user_id}`);
    }

    modPassword(id: number, password: string, new_password: string): Observable<any> {
        return this.http.put(`${this.baseUrl}/${id}/password`, {
            new_password: TokenConstant.encrypt(new_password),
            password: password === 'admin' ? null : TokenConstant.encrypt(password),
            is_admin_update_password: password === 'admin' ? 1 : 0
        });
    }

    qryRoles(id: number, product_id?: any): Observable<Array<Role>> {
        let params = new HttpParams();
        if (isPresent(product_id)) {
            params = params.set('product_id', product_id);
        }
        return this.http.get<Array<Role>>(`${this.baseUrl}/${id}/role`, { params });
    }

    addRoles(id: number, roles: any[]): Observable<any> {
        return this.http.post(`${this.baseUrl}/${id}/opera`, roles);
    }

    qryMenu(id: number, product_id?: any): Observable<Array<Menu>> {
        let params = new HttpParams();
        if (isPresent(product_id)) {
            params = params.set('product_id', product_id);
        }
        return this.http.get(`${this.baseUrl}/${id}/menus`, { params }).pipe(map<any, Array<Menu>>(items => items?.map(item => new Menu(item))));
    }

    qryPublicMenus(id: number): Observable<Array<Menu>> {
        return this.http.get(`${this.baseUrl}/${id}/public_menus`).pipe(map<any, Array<Menu>>(items => items?.map(item => new Menu(item))));
    }

    bindPhone(item: any): Observable<any> {
        item = Object.assign({}, item);
        item.password = TokenConstant.encrypt(item.password);
        return this.http.post(`${this.baseUrl}/bindmobilephone`, item);
    }

    bindEmail(item: any): Observable<any> {
        item = Object.assign({}, item);
        item.password = TokenConstant.encrypt(item.password);
        return this.http.post(`${this.baseUrl}/email/bind`, item);
    }

    qryIDCard(id: number): Observable<IDCard> {
        return this.http.get(`${this.baseUrl}/${id}/card`);
    }

    updateIDCard(id: number, item: IDCard): Observable<IDCard> {
        return this.http.post(`${this.baseUrl}/${id}/card`, item);
    }

    mockIDCard(): Observable<IDCard> {
        return this.http.get(`/mock_server/device/card`);
    }

    updateProduct(id: number, product_id: number): Observable<any> {
        return this.http.post(`${this.baseUrl}/${id}/product?product_id=${product_id}`, {});
    }

    qryCurrent(): Observable<User> {
        return this.http.get(`${this.baseUrl}/current`);
    }

    qryPwdState(user_id: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/${user_id}/ischangepwd`);
    }

    qryPublicKey(): Observable<string> {
        let headers = new HttpHeaders().set(Headers.NEED_AUTHORIZATION, 'false');
        return this.http.get(`${this.baseUrl}/login/publickey`, {
            responseType: 'text',
            headers
        }).pipe(tap(key => TokenConstant.KEY = key));
    }

}
