import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'simpleRightName'
})
export class SimpleRightNamePipe implements PipeTransform {
    transform(name: string): string {
        if (!name.includes('可')) {
            return name;
        }
        return name.substr(name.indexOf('可'));
    }
}