import { TreeNode } from './tree-base-node';
import { TreeBaseService } from './tree-base.service';

export class TreeBase {

    constructor(public treeService: TreeBaseService) {
    }

    coerceTreeNodes(value: any[]): TreeNode[] {
        if (!this.treeService.isArrayOfTreeNode(value)) {
            return value.map(item => new TreeNode(item, null, this.treeService));
        } else {
            return value.map((item: TreeNode) => {
                item.service = this.treeService;
                return item;
            });
        }
    }

    /**
     * Get all nodes({@link TreeNode})
     */
    getTreeNodes(): TreeNode[] {
        return this.treeService.rootNodes;
    }

    /**
     * Get {@link TreeNode} with key
     */
    getTreeNodeByKey(key: string): TreeNode | null {
        // flat tree nodes
        const nodes: TreeNode[] = [];
        const getNode = (node: TreeNode): void => {
            nodes.push(node);
            node.getChildren().forEach(n => {
                getNode(n);
            });
        };
        this.getTreeNodes().forEach(n => {
            getNode(n);
        });
        return nodes.find(n => n.key === key) || null;
    }

    /**
     * Get checked nodes(merged)
     */
    getCheckedNodeList(): TreeNode[] {
        return this.treeService.getCheckedNodeList();
    }

    /**
     * Get selected nodes
     */
    getSelectedNodeList(): TreeNode[] {
        return this.treeService.getSelectedNodeList();
    }

    /**
     * Get half checked nodes
     */
    getHalfCheckedNodeList(): TreeNode[] {
        return this.treeService.getHalfCheckedNodeList();
    }

    /**
     * Get expanded nodes
     */
    getExpandedNodeList(): TreeNode[] {
        return this.treeService.getExpandedNodeList();
    }

    /**
     * Get matched nodes(if nzSearchValue is not null)
     */
    getMatchedNodeList(): TreeNode[] {
        return this.treeService.getMatchedNodeList();
    }
}
