import { Component, Input, OnInit } from '@angular/core';
import { DrawerRef } from 'share';
import { UIStep } from 'app/ui/page/step/ui.page.step.model';


@Component({
    selector: 'ai-ui-page-step-edit',
    templateUrl: 'ui.page.step.edit.component.html'
})
export class UIPageStepEditComponent implements OnInit {

    @Input() mode: string = 'edit';
    @Input() type: string;
    @Input() item: UIStep;

    target: string = '';

    constructor(public drawerRef: DrawerRef) {
    }

    ngOnInit(): void {
        if (this.item.target && this.item.target.length > 0) {
            this.target = [...new Set([this.item.target].concat(this.item.paths))].join('\n');
        }
    }

    get hasTarget(): boolean {
        return [
            'click',
            'type',
            'component',
            'store',
        ].includes(this.item.operate);
    }

    get hasValue(): boolean {
        return [
            'open',
            'type',
            'store',
            'sleep',
            'if',
            'else if',
        ].includes(this.item.operate);
    }

    changeComp(comp: any): void {
        this.item.value = comp.component_name;
    }

    submit(): void {
        if (this.item.operate !== 'component') {
            const paths = [...new Set(this.target.split('\n'))];
            this.item.target = paths.shift();
            this.item.paths = paths;
        }
        this.drawerRef.close(this.item);
    }
}
