import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalHolder } from 'app/global.holder';
import { LoginService } from 'app/login/login.service';
import { User } from 'app/system/user/user.model';
import { UserService } from 'app/system/user/user.service';
import { LocalStorageService } from 'ngx-webstorage';
import { mergeMap } from 'rxjs/operators';
import {  ModalService } from 'share';

@Component({
    selector: 'ai-register',
    templateUrl: 'register.component.html',
    styleUrls: ['register.scss']
})

export class RegisterComponent implements OnInit {

    user: User;

    constructor(private router: Router,
                protected modal: ModalService,
                private global: GlobalHolder,
                private storage: LocalStorageService,
                private loginService: LoginService,
                private userService: UserService) {
    }

    ngOnInit() {
        this.user = {};
    }

    submit(): void {
        // if (this.user.pwd !== this.user.pwd_confirm) {
        //     toastr.error('两次密码输入不一样, 请确认');
        //     return;
        // }
        // const pwdReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@!%*#?&])[A-Za-z\d$@!%*#?&]{6,16}$/
        // if (!pwdReg.test(this.user.pwd)) {
        //     toastr.error('密码长度必须为6-16位，必须包含字母（不区分大小写），数字，特殊字符！');
        //     return;
        // }
        let item = Object.assign({}, this.user);

        this.userService.register(item).subscribe(user => {
            this.modal.success({
                title: '申请试用成功',
                content: '账号：testuser 　　 密码：123@test'
              }).result.subscribe(() => {
                this.user={}
            });;
              
            // this.storage.store('user', user);
            // this.global.updateUser(user);
            // let product = { product_id: user.product_id };
            // this.global.updateProduct(product);
            // this.router.navigate(['pages/product/dashboard']);
        });
    }
}
