import { Pipe, PipeTransform } from '@angular/core';
import { isPresent } from '../facade';

@Pipe({
    name: 'scale'
})
export class ScalePipe implements PipeTransform {
    transform(value: any, ratio: number, precision: number = 2): any {
        if (!isPresent(value)) {
            return;
        }
        return (value * ratio).toFixed(precision);
    }
}
