import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GlobalHolder } from 'app/global.holder';
import { SysParamService, SysParamType } from 'core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CacheResolve implements Resolve<any> {

    constructor(private global: GlobalHolder, private paramService: SysParamService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        // return this.paramService.qry(SysParamType.PARAM_CACHE).pipe(tap(items => this.global.updateCacheParams(items || [])));
        return this.paramService.qry(SysParamType.PARAM_CACHE).pipe(tap(items => this.global.updateCacheParams([])));
    }

}
