import { Component, DoCheck, ElementRef, HostBinding, OnInit } from '@angular/core';
import { isPresent } from '../facade';

@Component({
    selector: 'layout-none-children',  // tslint:disable-line
    templateUrl: 'layout.none.children.component.html'
})
export class LayoutNoneChildrenComponent implements OnInit, DoCheck {

    @HostBinding('class.d-none') hide = true;

    constructor(private elementRef: ElementRef) {
    }

    ngOnInit() {
    }

    ngDoCheck(): void {
        let el: HTMLElement = this.elementRef.nativeElement.children[0];
        this.hide = !isPresent(el) || el.classList.contains('d-none');
    }

}
