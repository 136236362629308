import { coerceCssPixelValue } from '@angular/cdk/coercion';
import { Injector, TemplateRef } from '@angular/core';
import { FunctionProp, SafeAny } from '../types';
import BigNumber from 'bignumber.js';

export function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

export function toFloat(value: any): number {
    return parseFloat(`${value}`);
}

export function toBoolean(value: boolean | string): boolean {
    return isPresent(value) && `${value}` !== 'false';
}

export function toCssPixel(value: number | string): string {
    return coerceCssPixelValue(value);
}

export function isNumber(value: any): value is number {
    return !isNaN(toInteger(value));
}

export function isPresent(obj: any) {
    return obj !== undefined && obj !== null;
}

export function isPrimitive(obj: any) {
    let type = typeof obj;
    return type === 'boolean' || type === 'string' || type === 'number';
}

export function isBigInt(obj: any) {
    return typeof obj === 'bigint';
}

export function isBigNumber(obj: any) {
    return BigNumber.isBigNumber(obj);
}

export function valueFunctionProp<T>(prop: FunctionProp<T>, ...args: SafeAny[]): T {
    return typeof prop === 'function' ? prop(...args) : prop;
}

export function isNonEmptyString(value: any): boolean {
    return typeof value === 'string' && value !== '';
}

export function isTemplateRef(value: any): boolean {
    return value instanceof TemplateRef;
}

export function getValueInRange(value: number, max: number, min = 0): number {
    return Math.max(Math.min(value, max), min);
}

export const delay = time => new Promise(resolve => setTimeout(resolve, time));

export const EMPTY_FUNCTION = () => void 0;

export function toDecimal(num: number, d: number, ceil = false): number {
    if (!isPresent(num)) {
        return 0;
    }
    let multi = 1;
    while (d-- > 0) {
        multi = multi * 10;
    }
    if (ceil) {
        return Math.ceil(+num * multi) / multi;
    }
    return Math.floor(+num * multi) / multi;
}

export function defaultValue(obj: any, key: string, value: any) {
    obj[key] = obj[key] || value;
}

function initDarkToastr(options?: ToastrOptions) {
    options = options ?? {};
    options.positionClass = options.positionClass ?? 'toast-top-right';
    options.toastClass = 'toast-dark';
    return options;
}

export function toastrErrorDark(msg: string, title?: string, options?: ToastrOptions): JQuery {
    options = initDarkToastr(options);
    console.log('我是返回错误')
    return toastr.error(msg, title, options);
}

export function toastrWarningDark(msg: string, options?: ToastrOptions): JQuery {
    options = initDarkToastr(options);
    return toastr.warning(msg, '', options);
}

export function toastrInfoDark(msg: string, options?: ToastrOptions): JQuery {
    options = initDarkToastr(options);
    return toastr.info(msg, '', options);
}

export function toastrSuccessDark(msg: string, options?: ToastrOptions): JQuery {
    options = initDarkToastr(options);
    return toastr.success(msg, '', options);
}

export function getInjector(component: any): Injector {
    return window.ng.getInjector(component);
}

export function toSortString(value: string, separator = ','): string {
    if (!isPresent(value) || value.length <= 0) return '';
    if (!value.includes(separator)) return value;
    return value.split(separator).filter(item => item?.length > 0).sort((a, b) => b.localeCompare(a)).join(',');
}

export function copyToClipboard(text: string): void {
    let input = document.createElement('textarea');
    input.style.position = 'fixed';
    input.style.left = '-100px';
    input.style.top = '-100px';
    document.body.appendChild(input);
    input.value = text;
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
}
