import { Directive, ElementRef, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { isPresent } from '../facade';

@Directive({
    selector: 'input[ngModel],input[formControlName],input[formControl]' // tslint:disable-line
})
export class InputCloseDirective implements OnInit, OnDestroy {

    private close: HTMLElement;
    private value$: Subscription;
    private close$: Subscription;
    private zone$: Subscription;

    constructor(private render: Renderer2,
                private zone: NgZone,
                private elementRef: ElementRef<HTMLInputElement>,
                private control: NgControl) {
        this.zone$ = this.zone.onStable.subscribe(() => this.updatePosition());
    }

    ngOnInit(): void {
        if (this.skip()) {
            return;
        }
        this.value$ = this.control.valueChanges?.subscribe(() => this.toggleClose());
    }

    private skip(): boolean {
        if (['radio', 'checkbox', 'range'].includes(this.elementRef.nativeElement.type)) {
            return true;
        }
        if (this.elementRef.nativeElement.disabled || this.elementRef.nativeElement.readOnly) {
            return true;
        }
        return this.elementRef.nativeElement.getAttribute('data-close') === 'false';

    }

    toggleClose(): void {
        if (!this.control.value || this.skip()) {
            this.closeClose();
            return;
        }
        if (!isPresent(this.close)) {
            this.close = this.render.createElement('i');
            this.render.addClass(this.close, 'icon');
            this.render.addClass(this.close, 'icon-error-fill');
            this.close.style.position = 'absolute';
            this.close.style.left = '0';
            this.close.style.top = '0';
            this.render.appendChild(this.elementRef.nativeElement.parentNode, this.close);
            this.close$ = fromEvent<MouseEvent>(this.close, 'click').subscribe((e) => {
                e.preventDefault();
                e.stopPropagation();
                this.control.control.markAsDirty();
                this.control.control.setValue('');
            });
        }
        this.render.removeClass(this.close, 'd-none');
        this.updatePosition();
    }

    private closeClose(): void {
        this.render.removeClass(this.elementRef.nativeElement, 'has-close');
        if (!this.close) {
            return;
        }
        this.render.addClass(this.close, 'd-none');
    }

    updatePosition(): void {
        if (!isPresent(this.close)) {
            return;
        }
        let rect = this.elementRef.nativeElement.getBoundingClientRect();
        this.close.style.transform = '';
        let closeRect = this.close.getBoundingClientRect();
        this.close.style.transform = `translate(${rect.right - closeRect.left - 8 - closeRect.width}px,${rect.bottom - closeRect.top - (rect.height - closeRect.height) / 2 - closeRect.height}px)`;
        this.render.addClass(this.elementRef.nativeElement, 'has-close');
    }


    ngOnDestroy(): void {
        this.value$?.unsubscribe();
        this.close$?.unsubscribe();
        this.zone$?.unsubscribe();
        this.close?.remove();
        this.close = null;
    }

}
