import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'ai-tree-indent',
    exportAs: 'treeIndent',
    template: `<span *ngFor="let i of listOfUnit; let index = index" [ngClass]="unitMapOfClass(index)"></span> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        '[attr.aria-hidden]': 'true',
        '[class.tree-indent]': '!selectMode',
        '[class.select-tree-indent]': 'selectMode'
    }
})
export class NzTreeIndentComponent implements OnInit, OnChanges {

    @Input() treeLevel?: number;
    @Input() isStart?: boolean[];
    @Input() isEnd?: boolean[];
    @Input() selectMode = false;

    listOfUnit: number[] = [];

    unitMapOfClass(index: number): { [key: string]: boolean } {
        return {
            [`tree-indent-unit`]: !this.selectMode,
            [`tree-indent-unit-start`]: !this.selectMode && this.isStart[index + 1],
            [`tree-indent-unit-end`]: !this.selectMode && this.isEnd[index + 1],
            [`select-tree-indent-unit`]: this.selectMode,
            [`select-tree-indent-unit-start`]: this.selectMode && this.isStart[index + 1],
            [`select-tree-indent-unit-end`]: this.selectMode && this.isEnd[index + 1]
        };
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { treeLevel } = changes;
        if (treeLevel) {
            this.listOfUnit = [...new Array(treeLevel.currentValue || 0)];
        }
    }
}
