import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppConstant, Headers, TokenConstant } from 'app/app.constants';
import { GlobalHolder } from 'app/global.holder';
import { LoginParam } from 'app/login/login.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({ providedIn: 'root' })
export class LoginService {

    constructor(private http: HttpClient,  private storage: LocalStorageService,private global: GlobalHolder,private router: Router) {
        // this.initSpringBoot2x();
    }

    login(item: LoginParam): Observable<any> {
        const data = new FormData();
        data.append('username', item.username);

        data.append('password', TokenConstant.encrypt(item.password));
        data.append('type', `${item.type || 2}`);
        data.append('grant_type', 'password');
        data.append('scope', 'read');
        data.append('verify_code_key', item.verify_code_key);
        data.append('verify_code_val', item.verify_code_val);
        let headers = new HttpHeaders()
            .set('Authorization', `Basic ${TokenConstant.CLIENT_SECRET}`)
            .set(Headers.BLOCK, '正在验证用户名密码...')
            .set(Headers.READONLY, 'false')
            .set(Headers.NEED_AUTHORIZATION, 'false');
        return this.http.post(`/xltp/oauth${AppConstant.isSpringBoot2x ? 2 : ''}/token`, data, { headers }); // TODO 登录接口适配, 后期删除
    }

    qryVerifyCode(): Observable<any> {
        let headers = new HttpHeaders().set(Headers.NEED_AUTHORIZATION, 'false');
        return this.http.get('/xltp/sys/user/login/verifycode', { headers });
    }

    sync(item: any): Observable<any> {
        let headers = new HttpHeaders()
            .set(Headers.SHOW_ERROR, 'false')
            .set('Authorization', `Bearer ${item.token}`)
            .set(Headers.BLOCK, '正在验证...');
        return this.http.post(`/xltp/sys/external/aido/sync`, item, { headers });
    }

    singleSync(item: any): Observable<any> {
        let headers = new HttpHeaders()
            .set(Headers.READONLY, 'false')
            .set(Headers.SHOW_ERROR, 'false')
            .set('Authorization', `Basic ${TokenConstant.CLIENT_SECRET}`)
            .set(Headers.NEED_AUTHORIZATION, 'false');
        return this.http.post(`/xltp/sys/external/sync`, item, { headers });
    }

    logout(): Observable<any> {
        return this.http.post('/xltp/logout', {}).pipe(tap(() => {
            if(this.global){
                this.global.clearToken();
            }else{
                this.storage.clear('token');
                this.storage.clear('product');
                this.storage.clear('tenant');
                this.storage.clear('user');
                this.router.navigate(['login']);
            }
                
        }));
    }

    private initSpringBoot2x(): void {
        let headers = new HttpHeaders().set(Headers.SHOW_ERROR, 'false').set(Headers.NEED_AUTHORIZATION, 'false');
        this.http.get('/xltp/info', { headers }).subscribe(p => AppConstant.isSpringBoot2x = false);
    }

}
