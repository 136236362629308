import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AppConfigComponent } from 'app/main/config/app.config.component';
import { DrawerService } from 'share';

@Component({
    selector: 'ai-app-config-btn',
    templateUrl: 'app.config.btn.component.html'
})

export class AppConfigBtnComponent implements OnInit, OnDestroy {

    constructor(private drawer: DrawerService) {
    }

    ngOnInit() {
    }

    @HostListener('click')
    click(): void {
        this.drawer.open(AppConfigComponent, { scrollable: true });
    }

    ngOnDestroy() {
    }
}
