import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OutletModule } from '../outlet/outlet.module';

import { StepComponent } from './step.component';
import { StepsComponent } from './steps.component';

@NgModule({
    imports: [
        CommonModule,
        OutletModule
    ],
    exports: [
        StepsComponent,
        StepComponent
    ],
    declarations: [
        StepsComponent,
        StepComponent
    ]
})
export class StepsModule {
}
