import {
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    ConnectedOverlayPositionChange,
    ConnectionPositionPair
} from '@angular/cdk/overlay';
import {
    AfterViewInit,
    ChangeDetectorRef,
    ComponentFactory,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { isPresent, toBoolean } from '../facade';
import { CONNECTION_POSITIONS, DEFAULT_TOOLTIP_POSITIONS, getPlacementName } from '../overlay/overlay-position';

export type TooltipTrigger = 'click' | 'focus' | 'hover' | null;

@Directive()
export abstract class TooltipBaseDirective implements OnChanges, OnDestroy, AfterViewInit {

    @Input() placement?: string;
    @Input() trigger?: TooltipTrigger = 'hover';
    @Input() origin?: ElementRef<HTMLElement>;
    @Input() content?: string | TemplateRef<HTMLElement>;

    @Input() mouseEnterDelay: number = 0.2;
    @Input() mouseLeaveDelay: number = 0.2;
    @Input() overlayClassName?: string;
    @Input() visible?: boolean;

    directiveNameTitle?: string | TemplateRef<void>;
    specificTitle?: string | TemplateRef<void>;

    protected componentFactory!: ComponentFactory<TooltipBaseComponent>;

    protected get title(): string | TemplateRef<void> {
        return this.specificTitle || this.directiveNameTitle || null;
    }

    protected needProxyProperties = [
        'overlayClassName',
        'mouseEnterDelay',
        'mouseLeaveDelay',
        'visible'
    ];

    @Output() readonly visibleChange = new EventEmitter<boolean>();

    component?: TooltipBaseComponent;

    protected readonly destroy$ = new Subject<void>();
    protected readonly triggerDisposables: Array<() => void> = [];

    private delayTimer?: any;

    protected constructor(public elementRef: ElementRef<HTMLElement>,
                          protected hostView: ViewContainerRef,
                          protected resolver: ComponentFactoryResolver,
                          protected renderer: Renderer2) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { trigger } = changes;

        if (trigger && !trigger.isFirstChange()) {
            this.registerTriggers();
        }

        if (this.component) {
            this.updateChangedProperties(changes);
        }
    }

    ngAfterViewInit(): void {
        this.createComponent();
        this.registerTriggers();

    }

    show(): void {
        this.component?.show();
    }

    hide(): void {
        this.component?.hide();
    }

    updatePosition(): void {
        this.component?.updatePosition();
    }

    protected createComponent(): void {
        const componentRef = this.hostView.createComponent(this.componentFactory);

        this.component = componentRef.instance;
        this.component.hasBackdrop = this.trigger === 'click';

        this.renderer.removeChild(this.renderer.parentNode(this.elementRef.nativeElement), componentRef.location.nativeElement);
        this.component.setOverlayOrigin({ elementRef: this.origin || this.elementRef });

        this.updateChangedProperties(this.needProxyProperties);

        this.component.visibleChange.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((visible: boolean) => {
            this.visible = visible;
            this.visibleChange.emit(visible);
        });
    }

    protected registerTriggers(): void {
        // When the method gets invoked, all properties has been synced to the dynamic component.
        // After removing the old API, we can just check the directive's own `nzTrigger`.
        const el = this.elementRef.nativeElement;
        const trigger = this.trigger;

        this.removeTriggerListeners();

        if (trigger === 'hover') {
            let overlayElement: HTMLElement;
            this.triggerDisposables.push(
                this.renderer.listen(el, 'mouseenter', () => this.delayEnterLeave(true, true, this.mouseEnterDelay))
            );
            this.triggerDisposables.push(
                this.renderer.listen(el, 'mouseleave', () => {
                    this.delayEnterLeave(true, false, this.mouseLeaveDelay);
                    if (this.component?.overlay.overlayRef && !overlayElement) {
                        overlayElement = this.component.overlay.overlayRef.overlayElement;
                        this.triggerDisposables.push(
                            this.renderer.listen(overlayElement, 'mouseenter', () => {
                                this.delayEnterLeave(false, true);
                            })
                        );
                        this.triggerDisposables.push(
                            this.renderer.listen(overlayElement, 'mouseleave', () => {
                                this.delayEnterLeave(false, false);
                            })
                        );
                    }
                })
            );
        } else if (trigger === 'focus') {
            this.triggerDisposables.push(this.renderer.listen(el, 'focus', () => this.show()));
            this.triggerDisposables.push(this.renderer.listen(el, 'blur', () => this.hide()));
        } else if (trigger === 'click') {
            this.triggerDisposables.push(
                this.renderer.listen(el, 'click', e => {
                    e.preventDefault();
                    this.show();
                })
            );
        }
    }

    protected updateChangedProperties(propertiesOrChanges: string[] | SimpleChanges): void {
        const isArray = Array.isArray(propertiesOrChanges);
        const keys = isArray ? (propertiesOrChanges as string[]) : Object.keys(propertiesOrChanges);

        keys.filter(property => this.needProxyProperties.includes(property)).forEach(property => this.updateComponentValue(property, this[property]));

        if (isArray) {
            this.updateComponentValue('title', this.title);
            this.updateComponentValue('content', this.content);
            this.updateComponentValue('placement', this.placement);
        } else {
            const c = propertiesOrChanges as SimpleChanges;
            if (c.specificTitle || c.directiveNameTitle) {
                this.updateComponentValue('title', this.title);
            }
            if (c.content) {
                this.updateComponentValue('content', this.content);
            }
            if (c.trigger) {
                this.updateComponentValue('hasBackdrop', this.trigger === 'click');
            }
            if (c.placement) {
                this.updateComponentValue('placement', this.placement);
            }
        }

        this.component?.updateByDirective();
    }

    protected removeTriggerListeners(): void {
        this.triggerDisposables.forEach(dispose => dispose());
        this.triggerDisposables.length = 0;
    }

    private updateComponentValue(key: string, value: any): void {
        if (typeof value !== 'undefined') {
            this.component[key] = value;
        }
    }

    private delayEnterLeave(isOrigin: boolean, isEnter: boolean, delay: number = -1): void {
        if (this.delayTimer) {
            this.clearTogglingTimer();
        } else if (delay > 0) {
            this.delayTimer = setTimeout(() => {
                this.delayTimer = undefined;
                isEnter ? this.show() : this.hide();
            }, delay * 1000);
        } else {
            isEnter && isOrigin ? this.show() : this.hide();
        }
    }

    private clearTogglingTimer(): void {
        if (this.delayTimer) {
            clearTimeout(this.delayTimer);
            this.delayTimer = undefined;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
        this.clearTogglingTimer();
        this.removeTriggerListeners();
    }
}

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class TooltipBaseComponent implements OnDestroy {

    @ViewChild('overlay', { static: false }) overlay!: CdkConnectedOverlay;

    visibleChange = new Subject<boolean>();
    title: string | TemplateRef<void> = null;
    content: string | TemplateRef<void> = null;
    overlayClassName!: string;
    mouseEnterDelay?: number;
    mouseLeaveDelay?: number;

    origin?: CdkOverlayOrigin;
    preferredPlacement = 'top';

    classMap: any = {};
    hasBackdrop = false;
    prefix = 'tooltip-placement';
    positions: ConnectionPositionPair[] = [...DEFAULT_TOOLTIP_POSITIONS];

    private _visible = false;

    protected constructor(public cdr: ChangeDetectorRef) {
    }

    public abstract isEmpty(): boolean;

    set visible(value: boolean) {
        const visible = toBoolean(value);
        if (this._visible !== visible) {
            this._visible = visible;
            this.visibleChange.next(visible);
        }
    }

    get visible(): boolean {
        return this._visible;
    }

    set placement(value: string) {
        if (value === this.preferredPlacement) {
            return;
        }
        this.preferredPlacement = value;
        if (value.startsWith('!')) {
            this.positions = [...value.split(',').map(v => CONNECTION_POSITIONS[v.replace('!', '')])];
        } else {
            this.positions = [...value.split(',').map(v => CONNECTION_POSITIONS[v]), ...this.positions];
        }
    }

    get placement(): string {
        return this.preferredPlacement;
    }

    show(): void {
        if (this.visible) {
            return;
        }

        if (!this.isEmpty()) {
            this.visible = true;
            this.visibleChange.next(true);
            this.cdr.detectChanges();
        }
    }

    hide(): void {
        if (!this.visible) {
            return;
        }

        this.visible = false;
        this.visibleChange.next(false);
        this.cdr.detectChanges();
    }

    updateByDirective(): void {
        this.setClassMap();
        this.cdr.detectChanges();

        Promise.resolve().then(() => {
            this.updatePosition();
            this.updateVisibilityByTitle();
        });
    }

    updatePosition(): void {
        if (this.origin && this.overlay && this.overlay.overlayRef) {
            this.overlay.overlayRef.updatePosition();
        }
    }

    onPositionChange(position: ConnectedOverlayPositionChange): void {
        this.preferredPlacement = getPlacementName(position);
        this.setClassMap();
        this.cdr.detectChanges();
    }

    setClassMap(): void {
        this.classMap = {
            [this.overlayClassName]: true,
            [`${this.prefix}-${this.preferredPlacement}`]: true
        };
    }

    setOverlayOrigin(origin: CdkOverlayOrigin): void {
        this.origin = origin;
        this.cdr.markForCheck();
    }

    private updateVisibilityByTitle(): void {
        if (this.isEmpty()) {
            this.hide();
        }
    }

    ngOnDestroy(): void {
        this.visibleChange.complete();
    }

}

export function isTooltipEmpty(value: string | TemplateRef<void> | null): boolean {
    return value instanceof TemplateRef ? false : value === '' || !isPresent(value);
}
