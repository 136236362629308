import { AfterViewInit, Directive, EventEmitter, Output } from '@angular/core';

@Directive({ selector: '[viewInit]' }) // tslint:disable-line
export class ViewInitDirective implements AfterViewInit {

    @Output() initialized: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    ngAfterViewInit(): void {
        this.initialized.emit();
    }
}
