import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { TreeIcon, TreeKeyPrefix } from 'core';
import { conductTree, isPresent, TreeNodeOptions } from 'share';
import { GroupType } from 'app/group/group.model';
import { UIPage } from 'app/ui/page/ui.page.model';
import { buildGroupTree } from 'app/group/group.util';
import { GlobalHolder } from 'app/global.holder';
import { GroupService } from 'app/group/group.service';
import { UIPageService } from 'app/ui/page/ui.page.service';


@Injectable({ providedIn: 'root' })
export class UIPageTreeService {

    tree: BehaviorSubject<Array<TreeNodeOptions>> = new BehaviorSubject([]);

    private productId: number;
    private editType: string;
    private loading = false;

    constructor(private global: GlobalHolder,
                private groupService: GroupService,
                private pageService: UIPageService) {
    }

    initTree(edit_type?: string): Observable<Array<TreeNodeOptions>> {
        if (this.loading) {
            return of(this.tree.getValue());
        }
        if (this.tree.getValue().length > 0 && isPresent(this.productId)) {
            return of(this.tree.getValue());
        }

        this.loading = true;
        return zip(
            this.groupService.qryGroup(this.global.product_id, GroupType.WIDGET),
            this.pageService.qryAll({
                product_id: this.global.product_id,
                edit_type
            })
        ).pipe(map(([groups, items]) => {
            const groupNodes = buildGroupTree(groups);
            const nodes = this.conductNodes(items);
            const tree = conductTree(nodes, groupNodes, 'fun_id');
            this.productId = this.global.product_id;
            this.editType = edit_type;
            this.tree.next(tree);
            this.loading = false;
            return tree;
        }));
    }

    conductNodes(items: Array<UIPage>): Array<TreeNodeOptions> {
        let nodes: Array<TreeNodeOptions> = [];
        items.forEach(item => {
            nodes.push({
                key: `${TreeKeyPrefix.PAGE}${item.component_id}`,
                title: `${item.component_name}`,
                icon: TreeIcon.PAGE,
                id: item.component_id,
                isLeaf: true,
                data: item,
                selectable: true
            });
        });
        return nodes;
    }

    clear(): void {
        this.tree.next([]);
        this.productId = null;
        this.loading = false;
    }
}
