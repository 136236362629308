import { Directive, Host, Optional } from '@angular/core';
import { DatePickerComponent } from '../datepicker.component';

@Directive({
    selector: 'ai-datepicker-range', // tslint:disable-line
    exportAs: 'rangePicker'
})
export class RangePickerComponent { // tslint:disable-line

    constructor(@Optional() @Host() public datePicker: DatePickerComponent) {
        this.datePicker.isRange = true;
        this.datePicker.mode = ['date', 'date'];
    }
}
