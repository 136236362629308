export function parseByte(value: number): string {
    let unit = ['', 'kb', 'mb', 'gb'];
    let i = 0;
    for (; i < unit.length; i++) {
        if (value < Math.pow(1024, i + 1)) {
            break;
        }
    }
    return `${Math.ceil(value / Math.pow(1024, i))} ${unit[i]}`;
}
