import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig, LoginMode } from 'app/app.model';
import { GlobalHolder } from 'app/global.holder';
import { LoginParam } from 'app/login/login.model';
import { AiComponent } from 'core';
import { LocalStorageService } from 'ngx-webstorage';
import { validateError } from 'share';
import { LoginService } from './login.service';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.scss']
})
@AiComponent()
export class LoginComponent implements OnInit, OnDestroy {

    item: LoginParam;
    error: string;
    code_img: string;
    types: Array<LoginMode> = [];
    config: AppConfig;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private global: GlobalHolder,
                private storage: LocalStorageService,
                private loginService: LoginService) {
        this.item = {};
    }

    get placeholder(): string {
        return ['请输入NT账号', '请输入用户名', '', '请输入用户名或手机号码'][this.item.type - 1] || '';
    }

    get isMessageVerify(): boolean {
        return this.item.type === 4 || this.global.loginVerify?.getValue() === 'sms';
    }

    get hasVerify(): boolean {
        return this.global.loginVerify?.getValue() !== 'no';
    }

    ngOnInit(): void {
        this.initTypes();
        let config$ = this.global.appConfig.subscribe(config => this.config = config);
        this.ngAdd$(config$);
    }

    private initTypes(): void {
        let types = this.global.loginModes.getValue().filter(mode => mode.value !== 4);
        if (types.length === 0) {
            return;
        }
        let old = this.storage.retrieve('login_type');
        this.item.type = types.some(item => item.value === old) ? old : types[0].value;
        this.types = types;
        this.qryVerifyCode();
    }

    qryVerifyCode() {
        if (!this.hasVerify || this.isMessageVerify) {
            return;
        }
        this.item.verify_code_val = '';
        this.loginService.qryVerifyCode().subscribe(item => {
            this.item.verify_code_key = item.verify_code_key;
            this.code_img = item.verify_code_img;
        });
    }

    changeType(type: number): void {
        if (this.item.type === type) {
            return;
        }
        this.item.verify_code_val = '';
        this.item.type = type;
        this.storage.store('login_type', type);
        if (type !== 4) {
            this.qryVerifyCode();
        }
    }

    trial(ev: Event): void {
        ev.stopPropagation();
        ev.preventDefault();
        window.open('http://10.1.208.170:8080/', '_blank');
    }

    login() {
        this.error = '';
        if (!this.item.username) {
            validateError(this.placeholder);
        }

        if (this.item.type !== 4 && !this.item.password) {
            validateError('请输入密码');
        }

        if (this.hasVerify && !this.item.verify_code_val) {
            validateError('请输入验证码');
        }

        this.loginService.login(this.item).subscribe((token) => {
            this.storage.store('token', token);
            this.global.updateToken(token);
            this.global.refreshGuard = true;
            this.router.navigate([this.config.login_for_url ?? 'pages/workbench']);
        }, () => this.qryVerifyCode());
    }

    ngOnDestroy(): void {
    }

}
