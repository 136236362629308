import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    Input,
    OnDestroy
} from '@angular/core';
import { getTextWidth, isPresent, limitTextWidth } from '../facade';

@Component({
    selector: 'text-overflow', // tslint:disable-line
    templateUrl: 'text.overflow.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['text.overflow.scss']
})

export class TextOverflowComponent implements AfterViewInit, DoCheck, OnDestroy {

    @Input() text: string;
    @Input() mode: any;

    options: any;

    private width = 0;

    private observer: IntersectionObserver;

    constructor(private element: ElementRef, private cdRef: ChangeDetectorRef) {
        this.mode = 'text';
        this.options = {
            wrap: true,
            autoScrollEditorIntoView: true,
            showGutter: false,
            highlightActiveLine: false,
            maxLines: 1000
        };
    }

    ngAfterViewInit() {
        let observer = new IntersectionObserver(entries => {
            entries.forEach((item) => {
                if (!item.isIntersecting) {
                    return;
                }
                observer.unobserve(item.target);
                this.checkWidth();
            });
        });
        observer.observe(this.element.nativeElement);
        this.observer = observer;
    }

    ngDoCheck(): void {
        this.checkWidth();
    }

    private checkWidth(): void {
        let el = this.element.nativeElement as HTMLElement;
        if (!isPresent(el) || this.width === el.clientWidth) {
            return;
        }
        this.cdRef.markForCheck();
        this.width = el.clientWidth;
    }

    get maxText(): string {
        let el = this.element.nativeElement as HTMLElement;
        if (!isPresent(this.text) || !isPresent(el)) {
            return '';
        }
        let style = getComputedStyle(el);
        let padding = (parseFloat(style.paddingLeft) || 0) + (parseFloat(style.paddingRight) || 0);
        return limitTextWidth(this.text, style.font, el.clientWidth - padding);
    }

    get isExceed(): boolean {
        let el = this.element.nativeElement as HTMLElement;

        if (!isPresent(this.text) || !isPresent(el)) {
            return false;
        }
        let style = getComputedStyle(el);
        return getTextWidth(this.text, style.font) > el.clientWidth;
    }


    ngOnDestroy() {
        this.observer.disconnect();
    }
}
