import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollTopComponent } from './scroll.top.component';

import { ScrollbarComponent } from './scrollbar.component';
import { XScrollbarComponent } from './x.scrollbar.component';

export { ScrollbarComponent } from './scrollbar.component';
export { ScrollbarOptions, ScrollbarEvents, ScrollbarConfig } from './scrollbar.model';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        ScrollbarComponent,
        XScrollbarComponent,
        ScrollTopComponent
    ],
    declarations: [
        ScrollbarComponent,
        XScrollbarComponent,
        ScrollTopComponent
    ]
})
export class ScrollbarModule {
}
