import { encode } from 'js-base64';
import JSEncrypt from 'jsencrypt';

export class Headers {
    static DELETE = 'Delete-Message';
    static SHOW_ERROR = 'Show-Error-Message';
    static NEED_AUTHORIZATION = 'NEED_AUTHORIZATION';
    static TRANSFORM_TO_JSON = 'Transform-To-Json';
    static BLOCK = 'Block-Message';
    static READONLY = 'READONLY';
    static Confirm = 'Confirm';
}

export enum EventNames {
    CLOSE_ALL_RIGHT_MENU = 'close all right menu',
    CLOSE_ALL_DATE = 'Close all date',
    CHANGE_DIRECTION_SHOW_TYPE = 'change interface direction type',
    CHANGE_REQUEST_TYPE = 'change request show type',
    CHANGE_RESPONSE_TYPE = 'change response show type',
    CHANGE_BODY_MODE = 'change body show mode',
    CHANGE_STEP_TYPE = 'change case step show type',
    ADD_PARAM_VERIFY = 'add param verify',

    CHANGE_PROGRESS_INDEX = 'change progress index',

    RESIZE_CHART = 'RESIZE_CHART',

    RECORD_UI_CASE = 'record ui case'
}

export class TokenConstant {
    static KEY;
    static CLIENT_SECRET = encode('root:devopS@itc2022');

    static encrypt(str: string): string {
        let encrypt = new JSEncrypt({});
        encrypt.setPublicKey(TokenConstant.KEY);
        return encrypt.encrypt(str) as string;
    }
}

export class AppConstant {
    static isSpringBoot2x = true;
}

export const CONTENT_TYPE = 'Content-Type';
export const JAR_KEY_SPLIT = '/';

export const CHART_COLORS: Array<string> = [
    '#0794f1',
    '#7362bc',
    '#069f51',
    '#e6a60a',
    '#d87a80',
    '#8d98b3',
    '#e5cf0d',
    '#97b552',
    '#95706d',
    '#dc69aa',
    '#07a2a4',
    '#9a7fd1',
    '#588dd5',
    '#f5994e',
    '#c05050',
    '#59678c',
    '#c9ab00',
    '#7eb00a',
    '#6f5553',
    '#c14089'
];
