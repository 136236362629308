import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { TokenConstant } from 'app/app.constants';
import { GuardInitializer } from 'app/guard/guard.initializer';
import { UserService } from 'app/system/user/user.service';
import { Observable, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class PublicKeyGuardService implements CanActivate, GuardInitializer {

    constructor(private userService: UserService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.init();
    }

    init(): Observable<boolean> {
        if (isPresent(TokenConstant.KEY)) {
            return of(true);
        }
        return this.userService.qryPublicKey().pipe(mapTo(true));
    }
}
