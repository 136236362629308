import { NgModule } from '@angular/core';
import { ModeValueFilterComponent } from 'app/value/mode.value.filter.component';
import { CoreModule } from 'core';
import { ShareModule } from 'share';

import { ModeValueComponent } from './mode.value.component';

@NgModule({
    imports: [
        CoreModule,
        ShareModule
    ],
    exports: [
        ModeValueComponent,
        ModeValueFilterComponent
    ],
    declarations: [
        ModeValueComponent,
        ModeValueFilterComponent
    ]
})
export class ModeValueModule {
}
