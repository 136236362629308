import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Optional, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DrawerBodyDirective } from '../drawer/drawer-body';
import { InputBoolean } from '../facade';
import { PageBodyDirective } from './page-body';

@Directive({ selector: '.step-content' }) // tslint:disable-line
export class StepContentDirective implements AfterViewInit, OnDestroy {

    private destroy$: Subject<any> = new Subject();
    @Input() @InputBoolean() fixedHeight: boolean;


    constructor(private render: Renderer2,
                private elementRef: ElementRef<HTMLElement>,
                @Optional() private drawerBody: DrawerBodyDirective,
                @Optional() private pageBody: PageBodyDirective) {
    }

    ngAfterViewInit(): void {
        let observable = this.drawerBody?.changeSize$ ?? this.pageBody?.changeSize$;
        observable?.pipe(takeUntil(this.destroy$)).subscribe(() => this.gapContent());

    }

    private gapContent(): void {
        let parent = this.elementRef.nativeElement.parentElement;
        this.render.setStyle(this.elementRef.nativeElement, this.fixedHeight ? 'height' : 'min-height', `${parseFloat(parent.style.minHeight) - 73}px`);

    }

    ngOnDestroy(): void {
        this.destroy$.next('');
        this.destroy$.complete();
    }
}
