import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EditorModule } from '../editor/editor.module';
import { PositionModule } from '../position/position.module';

import { MaxCharComponent } from './max.char.component';
import { TextOverflowComponent } from './text.overflow.component';

@NgModule({
    imports: [
        CommonModule,
        EditorModule,
        PositionModule
    ],
    exports: [
        MaxCharComponent,
        TextOverflowComponent
    ],
    declarations: [
        MaxCharComponent,
        TextOverflowComponent
    ]
})
export class TextModule {
}
