import { Injectable } from '@angular/core';
import { GlobalHolder } from 'app/global.holder';
import { GuardInitializer } from 'app/guard/guard.initializer';
import { UserService } from 'app/system/user/user.service';
import { Observable } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MenuGuardInitializer implements GuardInitializer {

    constructor(private global: GlobalHolder, private userService: UserService) {
    }

    init(): Observable<boolean> {
        return this.userService.qryPublicMenus(this.global.user_id).pipe(
            tap(items => {
                this.global.updateTopMenus(items);
                if (!this.global.menus.getValue()?.length) {
                    this.global.updateMenus(items);
                }
            }),
            mapTo(true)
        );
    }
}
