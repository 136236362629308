import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { ViewportRuler } from '@angular/cdk/overlay';
import { FormatEmitEvent, InputBoolean, TreeComponent, TreeNode, TreeNodeOptions, TreeVirtualScroll } from 'share';
import { Renderable, TreeKeyPrefix } from 'core';
import { GroupTreeService } from 'app/group/tree/group.tree.service';

@Component({
    selector: 'ai-group-tree',
    templateUrl: 'group.tree.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupTreeComponent extends TreeVirtualScroll implements OnInit, Renderable, OnDestroy {

    @Input() @InputBoolean() checkable: boolean;
    @Input() @InputBoolean() checkStrictly: boolean;
    @Input() virtualHeightDiff: number = 40;

    @Output() ngClick = new EventEmitter<any>();

    searchValue: string;
    nodes: TreeNodeOptions[] = [];
    checkedKeys: Array<string> = [];

    @ViewChild('tree')
    treeComponent: TreeComponent;

    constructor(protected elementRef: ElementRef<HTMLElement>,
                protected cdRef: ChangeDetectorRef,
                protected viewport: ViewportRuler,
                private treeService: GroupTreeService) {
        super(elementRef, cdRef, viewport);
    }

    ngOnInit() {
    }

    @Input()
    set type(value: string) {
        this.treeService.initTree(value).subscribe(nodes => {
            this.nodes = nodes;
            this.render();
        });
    }

    @Input()
    set checkedIds(value: Array<number>) {
        this.checkedKeys = value?.map(id => `${TreeKeyPrefix.GROUP}${id}`) ?? [];
    }

    getCheckedNodes(): Array<TreeNode> {
        return this.treeComponent.getCheckedNodeList();
    }

    getSelectedNodes(): Array<TreeNode> {
        return this.treeComponent.getSelectedNodeList();
    }

    change(e: FormatEmitEvent): void {
        this.ngClick.emit(e?.node?.origin?.data);
    }
}
