import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { getTextWidth, isPresent, toastrErrorDark, validateError } from 'share';
import { Block } from '../block/block';
import { GlobalHolder } from 'app/global.holder';

function showErrorMsg(msg: string) {
    let style = getComputedStyle(document.body);
    let width = getTextWidth(msg, style.font);
    if (width < 15 * 16) {
        toastr.error(msg);
    } else {
        toastrErrorDark(msg);
    }
}

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private global: GlobalHolder, private block: Block) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let message = req.headers.get(Headers.BLOCK);
        if (req.headers.get(Headers.SHOW_ERROR) === 'false') {
            return next.handle(req.clone({ headers: req.headers.delete(Headers.SHOW_ERROR) }));
        }
        return next.handle(req).pipe(catchError<any, any>(res => {
           if (message !== 'false') {
                this.block.hide();
            }
            if (res.status === 404) {
                validateError('接口不存在');
                return throwError(res);
            }
            let obj = res.error;
            if (typeof obj === 'string' && obj.startsWith('{')) {
                try {
                    obj = JSON.parse(obj);
                } catch (e) {
                }
            }
            if (typeof obj === 'string') {
                showErrorMsg(obj);
            } else if (obj?.message) {
                showErrorMsg(obj.message);
            } else if (obj?.error_description) {
                showErrorMsg(obj.error_description);
            } else {
                showErrorMsg(obj.text || res.message || res.statusText);
            }

            if (isPresent(obj) && obj['code'] === '501') {
                this.global.clearToken();
                return EMPTY;
            }
            return throwError(res);
        }));
    }
}
