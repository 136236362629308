import { Pipe, PipeTransform } from '@angular/core';
import { isPresent } from 'share';

@Pipe({
    name: 'vertical'
})
export class TextVerticalPipe implements PipeTransform {

    transform(value: string): any {
        if (!isPresent(value)) {
            return value;
        }

        return value.split('').join('\r\n');
    }
}


@Pipe({
    name: 'maxChars'
})
export class MaxCharsPipe implements PipeTransform {

    transform(value: string, num: number): any {
        if (!isPresent(value) || value.length <= num) {
            return value;
        }

        return `${value.substr(0, num)}...`;
    }
}