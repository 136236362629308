import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { zoomBigMotion } from '../animation';

import { isTooltipEmpty, TooltipBaseDirective, ToolTipComponent } from '../tooltip';

@Directive({
    selector: '[ai-popover]', //tslint:disable-line
    exportAs: 'popover',
    host: {
        '[class.popover-open]': 'visible'
    }
})
export class PopoverDirective extends TooltipBaseDirective implements OnInit {

    @Input('popoverTitle') specificTitle?: string | TemplateRef<void>;
    @Input('ai-popover') directiveNameTitle?: string | TemplateRef<void>;

    componentFactory: ComponentFactory<PopoverComponent> = this.resolver.resolveComponentFactory(PopoverComponent);

    constructor(private router: Router,
                elementRef: ElementRef,
                hostView: ViewContainerRef,
                resolver: ComponentFactoryResolver,
                renderer: Renderer2) {
        super(elementRef, hostView, resolver, renderer);
    }

    ngOnInit(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).pipe(takeUntil(this.destroy$)).subscribe(() => this.hide());
    }
}

@Component({
    selector: 'ai-popover',
    exportAs: 'popoverComponent',
    animations: [zoomBigMotion],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    preserveWhitespaces: false,
    template: `
        <ng-template
                #overlay="cdkConnectedOverlay"
                cdkConnectedOverlay
                transparentOverlay
                [cdkConnectedOverlayOrigin]="origin"
                [cdkConnectedOverlayHasBackdrop]="hasBackdrop"
                (backdropClick)="hide()"
                (detach)="hide()"
                (positionChange)="onPositionChange($event)"
                [cdkConnectedOverlayPositions]="positions"
                [cdkConnectedOverlayOpen]="visible">
            <div class="popover" [ngClass]="classMap" [@zoomBigMotion]="'active'">
                <div class="popover-content">
                    <div class="popover-arrow" *ngIf="!isEmpty()"></div>
                    <div class="popover-inner" role="tooltip">
                        <div>
                            <div class="popover-title" *ngIf="title">
                                <ng-container *stringTemplateOutlet="title">{{ title }}</ng-container>
                            </div>
                            <div class="popover-inner-content">
                                <ng-container *stringTemplateOutlet="content">{{ content }}</ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    `
})
export class PopoverComponent extends ToolTipComponent {

    prefix = 'popover-placement';

    @Input() content: string | TemplateRef<void> = null;


    constructor(cdr: ChangeDetectorRef) {
        super(cdr);
    }

    public isEmpty(): boolean {
        return isTooltipEmpty(this.title) && isTooltipEmpty(this.content);
    }
}
