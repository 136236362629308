import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CacheComponent } from 'app/main/cache/cache.component';
import { ModalClass } from 'core';
import { ModalService } from 'share';

@Component({
    selector: 'ai-cache-btn',
    templateUrl: 'cache.btn.component.html'
})

export class CacheBtnComponent implements OnInit, OnDestroy {

    constructor(private modal: ModalService) {
    }

    ngOnInit() {
    }

    @HostListener('click')
    click(): void {
        this.modal.open(CacheComponent, { modalClass: `${ModalClass.SIZE_5}` });
    }

    ngOnDestroy() {
    }
}
