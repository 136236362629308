import { Injectable } from '@angular/core';
import { UIPage } from 'app/ui/page/ui.page.model';
import { UIPageService } from 'app/ui/page/ui.page.service';
import { SubmitProcessor } from 'core';
import { Observable, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { isPresent } from 'share';

@Injectable()
export class PageModSubmit implements SubmitProcessor {

    constructor(private pageService: UIPageService) {
    }

    postProcess(item: UIPage): Observable<any> {
        if (!isPresent(item.component_id)) {
            return of(item);
        }
        return this.pageService.mod(item.component_id, item).pipe(mapTo(item));
    }

}
