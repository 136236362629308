import { InjectionToken } from '@angular/core';

export interface SearchFilterOption {
    id?: number;
    product_id?: number;
    search_type?: string;
    search_name?: string;
    search_content?: string;
    create_user_id?: number;
    create_time?: string;
}

export const PARAMS_SEARCH_TYPE = new InjectionToken<string>('params search type');

export const PARAMS_CACHES = new Map<string, any>();
