import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { isNumber, ModalRef, validateError } from 'share';

@Component({
    selector: 'ai-group-select-win',
    templateUrl: 'group.select.win.component.html'
})

export class GroupSelectWinComponent implements OnInit, OnDestroy {

    @Input() type: string;

    id: number;

    constructor(public modalRef: ModalRef) {
    }

    ngOnInit() {
    }

    @Input()
    set groupId(val: number) {
        if (isNumber(val)) {
            this.id = val;
        }
    }

    submit(): void {
        if (!(+this.id >= 0)) {
            validateError('请选择分组');
        }
        this.modalRef.close(this.id);
    }

    ngOnDestroy(): void {
    }
}
