import { AnimationEvent } from '@angular/animations';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { BaseModalContainer } from './modal-container';
import { ModalLegacyAPI } from './modal-legacy-api';
import { ModalOptions } from './modal-types';

export const enum ModalState {
    OPEN,
    CLOSING,
    CLOSED
}

export class ModalRef<T = any, R = any> implements ModalLegacyAPI<T, R> {

    componentInstance: any;
    state: ModalState = ModalState.OPEN;
    afterClose: Subject<R> = new Subject();
    afterOpen: Subject<void> = new Subject();
    result: Subject<R> = new Subject();

    private closeTimeout: any;
    private _result?: R;

    constructor(private overlayRef: OverlayRef, private config: ModalOptions, public containerInstance: BaseModalContainer) {
        containerInstance.animationStateChanged.pipe(
            filter(event => event.phaseName === 'done' && event.toState === 'enter'),
            take(1)
        ).subscribe(() => {
            this.afterOpen.next(undefined);
            this.afterOpen.complete();
        });

        containerInstance.animationStateChanged.pipe(
            filter(event => event.phaseName === 'done' && event.toState === 'exit'),
            take(1)
        ).subscribe(() => {
            clearTimeout(this.closeTimeout);
            this.overlayRef.dispose();
        });

        containerInstance.containerClick.pipe(take(1)).subscribe(() => {
            if (config.mask && config.maskClosable) {
                this.dismiss();
            }
        });

        overlayRef.keydownEvents()
            .pipe(filter(event => this.config.keyboard as boolean && event.keyCode === ESCAPE && !hasModifierKey(event)))
            .subscribe(event => {
                event.preventDefault();
                this.dismiss();
            });

        containerInstance.cancelTriggered.subscribe(() => this.dismiss());
        containerInstance.okTriggered.subscribe(() => this.close());

        overlayRef.detachments().subscribe(() => {
            this.afterClose.next(this._result);
            this.afterClose.complete();
            if (this._result !== null) {
                this.result.next(this._result);
                this.result.complete();
            }
            this.componentInstance = null;
            this.overlayRef.dispose();
        });
    }

    close(result?: R): void {
        this._result = result;
        this.config.beforeClose().pipe(
            filter(v => v),
            switchMap<any, Observable<AnimationEvent>>(() => {
                this.containerInstance.startExitAnimation();
                this.state = ModalState.CLOSING;
                return this.containerInstance.animationStateChanged;
            }),
            filter(event => event.phaseName === 'start'),
            take(1)
        ).subscribe(event => {
            this.state = ModalState.CLOSED;
            this.overlayRef.detachBackdrop();
            this.closeTimeout = setTimeout(() => this.overlayRef.dispose(), event.totalTime + 100);
        });
    }

    dismiss(): void {
        this.close(null);
    }
}
