import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EditorModule } from '../editor/editor.module';
import { MessageFormatComponent } from './msg.format.component';
import { MessageLinesComponent } from './msg.lines.component';

import { MsgSendComponent } from './msg.send.component';
import { MsgSendService } from './msg.send.service';

@NgModule({
    imports: [
        CommonModule,
        EditorModule
    ],
    exports: [
        MsgSendComponent,
        MessageFormatComponent,
        MessageLinesComponent
    ],
    declarations: [
        MsgSendComponent,
        MessageFormatComponent,
        MessageLinesComponent
    ],
    providers: [
        MsgSendService
    ]
})
export class MsgSendModule {
}
