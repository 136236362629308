import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalHolder } from 'app/global.holder';
import { AiComponent, Renderable } from 'core';

@Component({
    selector: 'ai-app-name',
    templateUrl: 'app.name.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
@AiComponent()
export class AppNameComponent implements OnInit, OnDestroy, Renderable {

    name: string;

    constructor(private global: GlobalHolder, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        let name$ = this.global.name.subscribe(name => {
            this.name = name;
            this.render();
        });
        this.ngAdd$(name$);
    }

    render(): void {
        this.cdRef.markForCheck();
    }

    ngOnDestroy() {
    }
}
