import { Pipe, PipeTransform } from '@angular/core';
import { isPresent } from '../facade';

@Pipe({
    name: 'keys'
})
export class KeysPipe implements PipeTransform {
    transform(value: any): any {
        if (!isPresent(value)) {
            return;
        }
        return Object.keys(value);
    }
}
