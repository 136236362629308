import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { cloneDate, CompatibleValue, DateFnsCalendar, normalizeRangeValue } from './calendar';
import { CompatibleDate, RangePartType } from './standard-types';

@Injectable()
export class DatepickerService implements OnDestroy {

    initialValue: CompatibleValue;
    value: CompatibleValue;
    activeDate: CompatibleValue;
    activeInput: RangePartType = 'left';
    arrowPositionStyle = {};
    isRange = false;

    valueChange$ = new ReplaySubject<CompatibleValue>(1);
    emitValue$ = new Subject<void>();
    inputPartChange$ = new Subject<RangePartType>();

    initValue(): void {
        if (this.isRange) {
            this.setActiveDate([]);
            this.value = this.initialValue = [];
        } else {
            this.value = this.initialValue = null;
        }
    }

    hasValue(value: CompatibleValue = this.value): boolean {
        if (Array.isArray(value)) {
            return !!value[0] && !!value[1];
        } else {
            return !!value;
        }
    }

    makeValue(value: CompatibleDate | null): CompatibleValue {
        if (this.isRange) {
            return value ? (value as Date[]).map(val => val ? new DateFnsCalendar(val) : null) : [];
        } else {
            return value ? new DateFnsCalendar(value as Date) : null;
        }
    }

    setActiveDate(value: CompatibleValue, normalize: boolean = false): void {
        if (this.isRange) {
            this.activeDate = normalize ? normalizeRangeValue(value as DateFnsCalendar[]) : value;
        } else {
            this.activeDate = cloneDate(value);
        }
    }

    setValue(value: CompatibleValue): void {
        this.value = value;
        this.valueChange$.next(this.value);
    }

    getActiveIndex(part: RangePartType = this.activeInput): number {
        return { left: 0, right: 1 }[part];
    }

    ngOnDestroy(): void {
        this.valueChange$.complete();
        this.emitValue$.complete();
        this.inputPartChange$.complete();
    }
}
