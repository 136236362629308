import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { Observable } from 'rxjs';
import { FileBaseService, isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class FileService extends FileBaseService {

    private baseUrl = '/xltp/sys/file';

    constructor(private http: HttpClient) {
        super();
    }

    upload(file: File, file_name?: string, params?: any): Observable<string> {
        let data = new FormData();
        data.append('file', file);
        if (isPresent(file_name)) {
            data.append('file_name', file_name);
        }
        if (isPresent(params)) {
            Object.keys(params).forEach(key => data.append(key, params[key]));
        }
        let headers = new HttpHeaders().set(Headers.BLOCK, '正在上传文件...');
        return this.http.put(`${this.baseUrl}`, data, { headers, responseType: 'text' });
    }

    filePrefix(): string {
        return '/xltp/sys/files';
    }
}
