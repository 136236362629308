import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IdlePreloadingStrategy implements PreloadingStrategy {

    constructor() {
    }

    preload(route: Route, fn: () => Observable<any>): Observable<any> {
        return timer(50).pipe(switchMap(() => fn()));
    }
}
