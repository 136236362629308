import { LOCALE_ID, PLATFORM_INITIALIZER } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import microApp from '@micro-zoe/micro-app';
import { AppModule } from 'app/app.module';
import { initLocale } from 'app/initializer/locale.initializer';
import { initWindowName } from 'app/initializer/name.initializer';
import { initWindowRatio } from 'app/initializer/ratio.initializer';
import { initToastr } from 'app/initializer/toastr.initializer';
import { environment } from 'environments/environment';
import './vendor';

if (module['hot']) {
    module['hot'].accept();
}

// microApp 启动
microApp.start({
    plugins: {
        global: []
    }
});

export function main(): Promise<any> {
    let providers = [
        { provide: PLATFORM_INITIALIZER, useValue: initToastr, multi: true },
        { provide: PLATFORM_INITIALIZER, useValue: initLocale, multi: true },
        { provide: PLATFORM_INITIALIZER, useValue: initWindowName, multi: true },
        { provide: PLATFORM_INITIALIZER, useValue: initWindowRatio, multi: true },
    ];
    return platformBrowserDynamic(providers)
        .bootstrapModule(AppModule, {
            providers: [
                { provide: LOCALE_ID, useValue: 'zh-Hans' }
            ]
        })
        .then(environment.decorateModuleRef)
        .catch((err) => console.error(err));
}

switch (document.readyState) {
    case 'loading':
        document.addEventListener('DOMContentLoaded', _domReadyHandler, false);
        break;
    case 'interactive':
    case 'complete':
    default:
        main();
}

function _domReadyHandler() {
    document.removeEventListener('DOMContentLoaded', _domReadyHandler, false);
    main();
}
