import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ai-select-arrow',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <i class="icon icon-down"></i>
    `
})
export class SelectArrowComponent {
    @Input() search = false;
}
