import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'ai-select-item',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-container *stringTemplateOutlet="contentTemplateOutlet; context: { $implicit: contentTemplateOutletContext }">
            <div class="select-selection-item-content" *ngIf="deletable; else labelTemplate">{{ label }}</div>
            <ng-template #labelTemplate>{{ label }}</ng-template>
        </ng-container>
        <span *ngIf="deletable && !disabled" class="select-selection-item-remove" (click)="onDelete($event)">
            <i class="icon icon-close"></i>
        </span>`,
    host: {
        '[class.select-selection-item]': 'true',
        '[class.select-selection-item-disabled]': 'disabled'
    }
})
export class SelectItemComponent {

    @Input() disabled = false;
    @Input() label: string | null = null;
    @Input() deletable = false;
    @Input() contentTemplateOutletContext: any | null = null;
    @Input() contentTemplateOutlet: string | TemplateRef<any> | null = null;
    @Output() readonly delete = new EventEmitter<MouseEvent>();

    onDelete(e: MouseEvent): void {
        e.preventDefault();
        e.stopPropagation();
        if (!this.disabled) {
            this.delete.next(e);
        }
    }
}
