import { RouterModule } from '@angular/router';
import { BasicResolve } from 'app/login/resolve/basic.resolve';
import { SingleLoginComponent } from './single.login.component';
import { AppConfigGuardService } from 'app/guard/app.config.guard.service';

export const route = RouterModule.forChild([{
    path: 'single_login',
    component: SingleLoginComponent,
    canActivate: [
        AppConfigGuardService
    ],
    resolve: {
        basic: BasicResolve
    }
}]);