import { Directive, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { isPresent } from '../facade';
import { locationElement, Position } from './position';

@Directive({ selector: '[fixed]' }) // tslint:disable-line
export class PositionFixedDirective implements OnInit, OnDestroy {

    @Input('fixed') element: HTMLElement;
    @Input() position: Position = 'left-bottom';

    constructor(private renderer: Renderer2) {
    }

    ngOnInit(): void {
    }

    @HostListener('mouseenter', ['$event'])
    enter(event: MouseEvent) {
        if (!isPresent(this.element)) {
            return;
        }
        this.renderer.addClass(this.element, 'd-fixed');
        this.element.style.setProperty('position', 'fixed', 'important');
        locationElement(this.element, event.target as HTMLElement, this.position);
        return false;
    }

    ngOnDestroy(): void {
    }
}
