import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { TreeNode, TreeNodeOptions } from './tree-base-node';

@Component({
    selector: 'ai-tree-node-title',
    templateUrl: 'tree-node-title.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        '[attr.title]': 'title',
        '[attr.draggable]': 'canDraggable',
        '[attr.aria-grabbed]': 'canDraggable',
        '[class.draggable]': 'canDraggable',
        '[class.select-tree-node-content-wrapper]': `selectMode`,
        '[class.select-tree-node-content-wrapper-open]': `selectMode && isSwitcherOpen`,
        '[class.select-tree-node-content-wrapper-close]': `selectMode && isSwitcherClose`,
        '[class.select-tree-node-selected]': `selectMode && isSelected`,
        '[class.tree-node-content-wrapper]': `!selectMode`,
        '[class.tree-node-content-wrapper-open]': `!selectMode && isSwitcherOpen`,
        '[class.tree-node-content-wrapper-close]': `!selectMode && isSwitcherClose`,
        '[class.tree-node-selected]': `!selectMode && isSelected`
    }
})
export class TreeNodeTitleComponent {

    @Input() searchValue: string;
    @Input() treeTemplate: TemplateRef<{ $implicit: TreeNode; origin: TreeNodeOptions }> | null = null;
    @Input() draggable: boolean;
    @Input() showIcon: boolean;
    @Input() selectMode = false;
    @Input() context: TreeNode;
    @Input() icon: string;
    @Input() title: string;
    @Input() isLoading: boolean;
    @Input() isSelected: boolean;
    @Input() isDisabled: boolean;
    @Input() isMatched: boolean;
    @Input() isExpanded: boolean;
    @Input() isLeaf: boolean;

    get canDraggable(): boolean | null {
        return this.draggable && !this.isDisabled ? true : null;
    }

    get matchedValue(): string {
        return this.isMatched ? this.searchValue : '';
    }

    get isSwitcherOpen(): boolean {
        return this.isExpanded && !this.isLeaf;
    }

    get isSwitcherClose(): boolean {
        return !this.isExpanded && !this.isLeaf;
    }
}
