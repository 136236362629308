import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({ selector: '[aiTriggerFrame]' })
export class TriggerFrameDirective implements AfterViewInit, OnDestroy {

    constructor(private element: ElementRef) {
    }

    ngAfterViewInit(): void {
        $(this.element.nativeElement).on('load', this.loadFrame.bind(this));
    }

    private loadFrame(): void {
        $(this.element.nativeElement.contentWindow.document).on('mouseup mousemove', ({ type }) => {
            let event = document.createEvent('MouseEvents');
            event.initEvent(type, true, false);
            this.element.nativeElement.parentNode.dispatchEvent(event);
        });
    }

    ngOnDestroy(): void {
        $(this.element.nativeElement.contentWindow.document).off('mouseup mousemove');
    }
}