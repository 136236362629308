import { Directive, Inject, OnDestroy, OnInit, Self } from '@angular/core';
import { ProcessorRegistry } from './processor.registry';
import { VALIDATABLE_PROCESSOR, ValidatableProcessor } from './validate.processor';

@Directive({ selector: '[validatableProcessor]' }) //tslint:disable-line
export class ValidatableProcessorDirective implements OnInit, OnDestroy {

    constructor(private registry: ProcessorRegistry, @Self() @Inject(VALIDATABLE_PROCESSOR) private processor: ValidatableProcessor<any>) {
    }

    ngOnInit(): void {
        this.registry.registerProcessor(this.processor);
    }

    ngOnDestroy(): void {
        this.registry.removeProcessor(this.processor);
    }

}
