import { ComponentRef, Directive, OnDestroy, OnInit, Renderer2, Self } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Directive({ selector: 'router-outlet' }) //tslint:disable-line
export class RouterOutletDirective implements OnInit, OnDestroy {

    private activate$: Subscription;

    constructor(private render: Renderer2, @Self() private outlet: RouterOutlet) {
        this.activate$ = this.outlet.activateEvents.subscribe(() => this.onActivate());
    }

    ngOnInit(): void {
    }

    onActivate(): void {
        let activated: ComponentRef<any> = this.outlet['activated'];
        if (!activated) {
            return;
        }
        let className = this.outlet?.activatedRouteData?.className as string;
        className?.split(' ').filter(name => name).forEach(name => this.render.addClass(activated?.location?.nativeElement, name));
    }

    ngOnDestroy(): void {
        this.activate$.unsubscribe();
    }

}
