import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';
import { DrawerBodyDirective } from './drawer-body';

import { DrawerComponent } from './drawer.component';
import { DrawerService } from './drawer.service';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        PortalModule,
        ScrollbarModule
    ],
    exports: [
        DrawerComponent,
        DrawerBodyDirective
    ],
    declarations: [
        DrawerComponent,
        DrawerBodyDirective
    ],
    providers: [
        DrawerService
    ]
})
export class DrawerModule {
}
