import { AfterContentInit, Component, DoCheck, ElementRef, Input, OnDestroy } from '@angular/core';

import AceDiff from 'ace-diff/src';
import { Observable, Observer, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { getBaseFontSize, isPresent } from '../facade';

const ace = require('brace');
ace.Range = ace.acequire('ace/range').Range;

@Component({
    selector: 'ace-differ', // tslint:disable-line
    templateUrl: 'ace.differ.component.html',
    styleUrls: ['ace.differ.scss']
})

export class AceDifferComponent implements DoCheck, AfterContentInit, OnDestroy {

    private differ: any;
    private width: number;
    private height: number;
    private readonly options: any;

    private optionsObserver: Observer<any>;

    private subscriptions: Array<Subscription> = [];

    currentDiffIndex = -1;

    constructor(private el: ElementRef) {
        this.options = {
            ace,
            element: this.el.nativeElement,
            // theme: 'ace/theme/Coffee',
            left: {
                editable: false,
                copyLinkEnabled: false
            },
            right: {
                editable: false,
                copyLinkEnabled: false
            }
        };

        let options: Observable<any> = new Observable(observer => this.optionsObserver = observer);
        let options$ = options.pipe(debounceTime(200)).subscribe(() => this.setOptions());
        this.subscriptions.push(options$);

    }

    nextDiff() {
        if (!this.differ || !this.differ.getEditors() || !this.differ.diffs || !this.differ.diffs.length) {
            return;
        }
        this.currentDiffIndex = this.currentDiffIndex + 1;
        if (this.currentDiffIndex >= this.differ.diffs.length - 1) {
            this.currentDiffIndex = this.differ.diffs.length - 1;
        }
        this.scroll2line(this.currentDiffIndex);
    }

    preDiff() {
        if (!this.differ || !this.differ.getEditors() || !this.differ.diffs || !this.differ.diffs.length) {
            return;
        }
        this.currentDiffIndex = this.currentDiffIndex - 1;
        if (this.currentDiffIndex <= 0) {
            this.currentDiffIndex = 0;
        }
        this.scroll2line(this.currentDiffIndex);
    }

    scroll2line(diffIndex) {
        if (diffIndex < 0) {
            return;
        }
        console.log('this.differ.diffs', this.differ.diffs);
        let currentDiffItem = this.differ.diffs[diffIndex];
        let lineHeightLeft = document.querySelectorAll('.acediff__left .ace_gutter-cell ')[0].clientHeight;
        let lineHeightRight = document.querySelectorAll('.acediff__right .ace_gutter-cell ')[0].clientHeight;
        this.differ.getEditors().left.getSession().setScrollTop(currentDiffItem.leftStartLine * lineHeightLeft);
        this.differ.getEditors().right.getSession().setScrollTop(currentDiffItem.rightStartLine * lineHeightRight);
    }

    ngAfterContentInit(): void {
        this.differ = new AceDiff(this.options);
        let fontSize = getBaseFontSize() * 0.875;
        let editors = this.differ.getEditors();
        editors.left.setFontSize(`${fontSize}px`);
        editors.right.setFontSize(`${fontSize}px`);
    }


    ngDoCheck() {
        if (this.width !== this.el.nativeElement.offsetWidth) {
            this.diff();
            this.width = this.el.nativeElement.offsetWidth;
        }
        if (this.height !== this.el.nativeElement.offsetHeight) {
            this.diff();
            this.height = this.el.nativeElement.offsetHeight;
        }
    }


    @Input()
    set mode(value: string) {
        this.options.mode = `ace/mode/${value}`;
        this.optionsObserver.next('');
    }

    @Input()
    set left(value: string) {
        this.options.left.content = value || '';
        this.optionsObserver.next('');
    }


    @Input()
    set right(value: string) {
        this.options.right.content = value || '';
        this.optionsObserver.next('');
    }

    private diff(): void {
        if (!isPresent(this.differ)) {
            return;
        }
        try {
            let editors = this.differ.getEditors();
            editors.left.resize();
            editors.right.resize();
        } catch (e) {
        }
    }

    private setOptions(): void {
        if (!isPresent(this.differ)) {
            this.optionsObserver.next('');
        }
        this.differ.setOptions(this.options);
        try {
            let editors = this.differ.getEditors();
            editors.left.setValue(this.options.left.content, 1);
            editors.right.setValue(this.options.right.content, 1);
        } catch (e) {
        }
    }

    ngOnDestroy() {
        try {
            if (this.differ) {
                this.differ.destroy();
            }
        } catch (e) {
        }

        this.subscriptions.forEach(i => i.unsubscribe());
    }
}
