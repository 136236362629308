import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UrlHandlingStrategy } from '@angular/router';
import { FileBaseService, SelectModule, ShareModule } from 'share';
import { DatepickerI18n } from 'share/datepicker/datepicker-i18n';
import { ColumnModule } from './column/column.module';
import { CronComponent } from './components/cron/cron.component';
import { EditSwitchComponent } from './components/edit/edit.switch.component';
import { SysParamSelectComponent } from './components/sys-param-select/sys.param.select.component';
import { EnumSelectComponent } from './enum/enum.select.component';
import { INTERCEPTORS  } from './interceptor';
import { NodeNamePipe, NodeTypePipe } from './node/node.pipe';
import { NodeTypeComponent } from './node/node.type.component';
import { NodeValueComponent } from './node/node.value.component';
import { XmlValueComponent } from './node/xml.value.component';
import {
    CaseCheckResultStatusPipe,
    CaseEvalTypePipe,
    CaseModePipe,
    CasePropFilterPipe,
    CasePropTypePipe,
    CasePropValueTypePipe,
    CaseTypeClassPipe,
    CaseTypePipe,
    CaseValueModePipe,
    ExecuteResultStatusPipe,
    StepParamNamePipe,
    StepTypePipe,
    VerifyContentPipe,
    VerifyOperatorPipe,
    VerifyRespNamePipe,
    VerifyTypePipe
} from './pipe/case.pipe';
import { DataBatchStatusPipe, RuleRightPipe, RuleTypePipe, ToolTypePipe } from './pipe/data.pipe';
import { ParseMillisecondPipe } from './pipe/date.pipe';
import {
    DemandCaseStatusPipe,
    DemandLevelPipe,
    DemandStatusPipe,
    DemandStatusDetailPipe,
    DemandTaskStatusPipe,
    DemandTaskTypePipe,
    TaskAuditStatusPipe
} from './pipe/demand.pipe';
import { DetectCaseLogStatusPipe, DetectDBLogStatusPipe } from './pipe/detect.pipe';
import { ParamEnumTypePipe, ParamNamePipe, ErrorTypePipe } from './pipe/interface.pipe';
import {
    BugStatusPipe,
    BugCloseReasonPipe,
    BugWorkTypePipe,
    HaveAutoPipe,
    ManualTaskStatusPipe,
    ManualCaseFieldPipe,
    ManualVersionPipe,
    TaskStatusPipe,
    TaskTypePipe,
    TestCaseResultPipe
} from './pipe/manual.pipe';
import { DeviceStatusPipe } from './pipe/mobile.pipe';
import { PerfPercentPipe, PerfTaskStatusPipe } from './pipe/perf.pipe';
import { PlanPropTypePipe, PlanResultStatusPipe } from './pipe/plan.pipe';
import { PoolNamePipe } from './pipe/pool.pipie';
import { FilePathPipe } from './pipe/file-path.pipe';
import { GlobalValuePipe, ParamTypePipe } from './pipe/product.pipe';
import { SimpleRightNamePipe } from './pipe/right.pipe';
import { ScanPipe } from './pipe/security.pipe';
import { MaxCharsPipe, TextVerticalPipe } from './pipe/text.pipe';
import { RepeaterCaseStatusPipe } from './pipe/repeater.pipe';
import { UIParamValueTypePipe } from './pipe/ui.pipe';
import { ValidatableProcessorDirective } from './processor';
import { ValidatableDirective } from './processor/validate.directive';
import { FileService } from './providers';
import { DateFnsDatepickerI18n } from './providers/datepickerI18n';
import { CustomUrlHandlingStrategy } from './providers/url_handling_strategy';
import { RightModule } from './right/right.module';

const MODULES = [
    RightModule,
    ColumnModule
];

const COMPONENTS = [
    CronComponent,
    EditSwitchComponent,
    NodeTypeComponent,
    NodeValueComponent,
    XmlValueComponent,
    EnumSelectComponent,
    SysParamSelectComponent
];

const DIRECTIVES = [
    ValidatableDirective,
    ValidatableProcessorDirective
];

const PIPES = [
    VerifyOperatorPipe,
    VerifyTypePipe,
    CaseModePipe,
    CaseValueModePipe,
    ExecuteResultStatusPipe,
    CaseCheckResultStatusPipe,
    PlanResultStatusPipe,
    PlanPropTypePipe,
    VerifyRespNamePipe,
    VerifyContentPipe,
    CasePropFilterPipe,
    CasePropTypePipe,
    CasePropValueTypePipe,
    StepTypePipe,
    CaseTypePipe,
    CaseTypeClassPipe,
    CaseEvalTypePipe,
    StepParamNamePipe,
    ScanPipe,
    ParamNamePipe,
    ErrorTypePipe,
    DeviceStatusPipe,
    UIParamValueTypePipe,
    ParamTypePipe,
    GlobalValuePipe,
    FilePathPipe,

    HaveAutoPipe,
    DemandStatusPipe,
    DemandStatusDetailPipe,
    DemandLevelPipe,
    DemandTaskStatusPipe,
    DemandTaskTypePipe,
    DemandCaseStatusPipe,
    ManualTaskStatusPipe,
    ManualCaseFieldPipe,
    ManualVersionPipe,
    TestCaseResultPipe,
    BugStatusPipe,
    BugCloseReasonPipe,
    BugWorkTypePipe,
    TaskStatusPipe,
    TaskTypePipe,

    TaskAuditStatusPipe,
    RuleTypePipe,
    RuleRightPipe,
    ToolTypePipe,
    DataBatchStatusPipe,
    DetectCaseLogStatusPipe,
    DetectDBLogStatusPipe,
    TextVerticalPipe,
    SimpleRightNamePipe,
    MaxCharsPipe,
    ParamEnumTypePipe,
    PerfTaskStatusPipe,
    PerfPercentPipe,
    ParseMillisecondPipe,
    PoolNamePipe,
    NodeTypePipe,
    NodeNamePipe,

    RepeaterCaseStatusPipe
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SelectModule,
        ...MODULES,
        ShareModule
    ],
    exports: [
        ...MODULES,
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES
    ],
    declarations: [
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES
    ],
    providers: [
        ...INTERCEPTORS,
        { provide: DatepickerI18n, useClass: DateFnsDatepickerI18n },
        { provide: UrlHandlingStrategy, useClass: CustomUrlHandlingStrategy },
        { provide: FileBaseService, useClass: FileService }
    ]
})
export class CoreModule {
}
// core.js:7744 ERROR Error: Expected to not be in Angular Zone, but it is!