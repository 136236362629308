import { FilterOptionType, SelectItemInterface } from './select.types';

export function generateTagItem(value: string): SelectItemInterface {
    return {
        value,
        label: value,
        type: 'item'
    };
}

export const defaultFilterOption: FilterOptionType = (searchValue: string, item: SelectItemInterface): boolean => {
    return item?.label?.toLowerCase().includes(searchValue?.toLowerCase());
};

export const defaultCompare = (o1: any, o2: any) => {
    let nullValue = [undefined, null, ''];
    if (nullValue.includes(o1) && nullValue.includes(o2)) {
        return true;
    }
    return o1 === o2;
};

export const nonTripleCompare = (o1: any, o2: any) => {
    let nullValue = [undefined, null, ''];
    if (nullValue.includes(o1) && nullValue.includes(o2)) {
        return true;
    }
    let types = ['string', 'number'];
    if (o1 !== '' && o2 !== '' && types.includes(typeof o1) && types.includes(typeof o1)) {
        // tslint:disable-next-line:triple-equals
        return o1 == o2;
    }
    return o1 === o2;
};

export const transformValue = (object: any) => {
    if (typeof object === 'string') {
        return object.split(',').filter(v => v !== '');
    }
    return object;
};
