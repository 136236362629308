import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'ai-notify-icon',
    templateUrl: 'notify.icon.component.html',
    styleUrls: ['notify.icon.scss']
})

export class NotifyIconComponent implements OnInit, OnDestroy {

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

    @HostListener('click')
    click(): void {
        this.router.navigate(['pages/workbench']);
    }

    ngOnDestroy() {
    }
}
