import { BACKSPACE } from '@angular/cdk/keycodes';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { getTextWidth } from '../facade';
import { SelectSearchComponent } from './select-search.component';
import { SelectItemInterface, SelectModeType, SelectTopControlItemType } from './select.types';

@Component({
    selector: 'ai-select-top-control',
    exportAs: 'nzSelectTopControl',
    preserveWhitespaces: false,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    templateUrl: 'select-top-control.component.html'
})
export class SelectTopControlComponent implements OnChanges {

    @Input() showSearch = false;
    @Input() placeHolder: string;
    @Input() open = false;
    @Input() maxTagCount: number = Infinity;
    @Input() nowrap: boolean = false;
    @Input() disabled = false;
    @Input() mode: SelectModeType = 'default';
    @Input() customTemplate: TemplateRef<{ $implicit: SelectItemInterface }> | null = null;
    @Input() maxTagPlaceholder: TemplateRef<{ $implicit: any[] }> | null = null;
    @Input() listOfTopItem: SelectItemInterface[] = [];
    @Input() tokenSeparators: string[] = [];

    @Output() readonly tokenize = new EventEmitter<string[]>();
    @Output() readonly inputValueChange = new EventEmitter<string | null>();
    @Output() readonly deleteItem = new EventEmitter<SelectItemInterface>();

    @ViewChild(SelectSearchComponent) selectSearchComponent: SelectSearchComponent;

    listOfSlicedItem: SelectTopControlItemType[] = [];
    isShowPlaceholder = true;
    isShowSingleLabel = false;
    isComposing = false;
    inputValue: string = null;

    constructor(private elementRef: ElementRef<HTMLElement>) {
    }

    @HostListener('keydown', ['$event'])
    onHostKeydown(e: KeyboardEvent): void {
        const inputValue = (e.target as HTMLInputElement).value;
        if (e.keyCode === BACKSPACE && this.mode !== 'default' && !inputValue && this.listOfTopItem.length > 0) {
            e.preventDefault();
            this.onDeleteItem(this.listOfTopItem[this.listOfTopItem.length - 1]);
        }
    }

    updateTemplateVariable(): void {
        const isSelectedValueEmpty = this.listOfTopItem.length === 0;
        this.isShowPlaceholder = isSelectedValueEmpty && !this.isComposing && !this.inputValue;
        this.isShowSingleLabel = !isSelectedValueEmpty && !this.isComposing && !this.inputValue;
    }

    isComposingChange(isComposing: boolean): void {
        this.isComposing = isComposing;
        this.updateTemplateVariable();
    }

    onInputValueChange(value: string): void {
        if (value === this.inputValue) {
            return;
        }
        this.inputValue = value;
        this.updateTemplateVariable();
        this.inputValueChange.emit(value);
        this.tokenSeparate(value, this.tokenSeparators);
    }

    private tokenSeparate(inputValue: string, tokenSeparators: string[]): void {
        const includesSeparators = (str: string | string[], separators: string[]): boolean => separators.some(separator => str.lastIndexOf(separator) > 0);

        const splitBySeparators = (str: string | string[], separators: string[]): string[] => {
            const reg = new RegExp(`[${separators.join()}]`);
            const array = (str as string).split(reg).filter(token => token);
            return [...new Set(array)];
        };
        if (
            inputValue &&
            inputValue.length &&
            tokenSeparators.length &&
            this.mode !== 'default' &&
            includesSeparators(inputValue, tokenSeparators)
        ) {
            const listOfLabel = splitBySeparators(inputValue, tokenSeparators);
            this.tokenize.next(listOfLabel);
        }
    }

    clearInputValue(): void {
        this.inputValue = '';
        this.updateTemplateVariable();
    }

    focus(): void {
        this.selectSearchComponent?.focus();
    }

    blur(): void {
        this.selectSearchComponent?.blur();
    }

    trackValue(_index: number, option: SelectTopControlItemType): any {
        return option.value;
    }

    onDeleteItem(item: SelectItemInterface): void {
        if (!this.disabled && !item.disabled) {
            this.deleteItem.next(item);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { listOfTopItem, maxTagCount, customTemplate, maxTagPlaceholder } = changes;
        if (listOfTopItem) {
            this.updateTemplateVariable();
        }
        if (!listOfTopItem && !maxTagCount && !customTemplate && !maxTagPlaceholder) {
            return;
        }
        if (this.nowrap) {
            this.listOfSlicedItem = this.sliceOnWidth();
        } else {
            this.listOfSlicedItem = this.sliceOnIndex(this.maxTagCount);
        }
    }

    private sliceOnIndex(max: number): SelectTopControlItemType[] {
        const listOfSlicedItem: SelectTopControlItemType[] = this.listOfTopItem.slice(0, max).map(o => {
            return {
                label: o.label,
                value: o.value,
                disabled: o.disabled,
                contentTemplateOutlet: this.customTemplate,
                contentTemplateOutletContext: o
            };
        });
        if (this.listOfTopItem.length > max) {
            const exceededLabel = `${this.listOfTopItem.length - max}个未显示...`;
            const listOfSelectedValue = this.listOfTopItem.map(item => item.value);
            const exceededItem = {
                label: exceededLabel,
                value: '$$__nz_exceeded_item',
                disabled: true,
                contentTemplateOutlet: this.maxTagPlaceholder,
                contentTemplateOutletContext: listOfSelectedValue.slice(this.maxTagCount)
            };
            listOfSlicedItem.push(exceededItem);
        }
        return listOfSlicedItem;
    }

    private sliceOnWidth(): SelectTopControlItemType[] {

        let selectWidth = this.elementRef.nativeElement.offsetWidth - 4;
        let maxIndex = 0;
        let width = 0;

        const contentMarin = 4;
        const closeWidth = 14;
        const padding = 8 + 4;
        const border = 2;
        const marginRight = 4;
        const extWidth = contentMarin + closeWidth + padding + border + marginRight;
        for (let item of this.listOfTopItem) {
            width += getTextWidth(item.label, getComputedStyle(this.elementRef.nativeElement).font) + extWidth;
            if (width > selectWidth) {
                break;
            }
            maxIndex++;
        }

        if (maxIndex === this.listOfTopItem.length) {
            return this.listOfTopItem.map(v => ({
                label: v.label,
                value: v.value,
                disabled: v.disabled,
                contentTemplateOutlet: this.customTemplate,
                contentTemplateOutletContext: v
            }));
        }

        width += getTextWidth(`${this.listOfTopItem.length - maxIndex}个未显示`, getComputedStyle(this.elementRef.nativeElement).font);

        for (let i = maxIndex - 1; i > 0; i--) {
            width -= getTextWidth(this.listOfTopItem[i].label, getComputedStyle(this.elementRef.nativeElement).font) + extWidth;
            maxIndex--;
            if (width <= selectWidth) {
                break;
            }
        }
        return this.sliceOnIndex(maxIndex);
    }
}
