import { TemplateRef } from '@angular/core';

export enum DateFormat {
    Date = 'yyyy-MM-dd',
    DateTime = 'yyyy-MM-dd HH:mm:ss'
}

export interface PanelSelector {
    className?: string;
    title?: string;
    label?: string;

    onClick?(): void;
}

export interface DateCell {
    isDisabled: boolean;
    isSelected: boolean;
    label?: string;
    title?: string;
    cellRender?: TemplateRef<Date> | string;
    fullCellRender?: TemplateRef<Date> | string;
    isToday?: boolean;
    classMap?: object;
    value: Date;
    content: TemplateRef<Date> | string;

    onClick(): void;

    onMouseEnter(): void;
}

export interface DateBodyRow {
    dateCells: DateCell[];
    isCurrent?: boolean; // Is the week that today stays in
    isActive?: boolean; // Is the week that current setting date stays in
    weekNum?: number;
    year?: number;
    classMap?: object;
}

export interface DayCell extends DateCell {
    isSelectedStartDate?: boolean;
    isSelectedEndDate?: boolean;
    isHoverStartDate?: boolean;
    isHoverEndDate?: boolean;
    isInHoverRange?: boolean;
    isInSelectedRange?: boolean;
    isLastDayOfMonth?: boolean;
    isFirstDayOfMonth?: boolean;
    isStartSingle?: boolean;
    isEndSingle?: boolean;
}

export interface DecadeCell extends DateCell {
    isBiggerThanEnd?: boolean;
    isLowerThanStart?: boolean;
}

export interface YearCell extends DateCell {
    isSameDecade?: boolean;
}
