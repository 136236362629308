import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DrawerOptions, DrawerOptionsOfComponent } from './drawer-options';
import { DrawerRef } from './drawer-ref';
import { DrawerComponent } from './drawer.component';
import { DrawerClass } from './drawer.constants';

export class DrawerBuilderForService<R> {

    private overlayRef: OverlayRef;
    private drawerRef: ComponentRef<DrawerComponent>;

    constructor(private overlay: Overlay, private options: DrawerOptions) {
        const { beforeClose, ...componentOption } = this.options;
        this.createDrawer();
        this.updateOptions(componentOption);
        // this.drawerRef?.instance.savePreviouslyFocusedElement();
        let result: any;
        this.drawerRef?.instance.ngClose.pipe(
            tap(r => result = r),
            switchMap(r => beforeClose ? beforeClose(r) : of(true)),
            filter(v => v)
        ).subscribe(() => this.drawerRef.instance.confirmClose.next(result));

        this.drawerRef?.instance.afterClose.subscribe(() => {
            this.overlayRef.dispose();
            this.drawerRef = null;
        });
    }

    getInstance(): DrawerRef<R> {
        return this.drawerRef?.instance;
    }

    createDrawer(): void {
        this.overlayRef = this.overlay.create({ disposeOnNavigation: this.options.disposeOnNavigation });
        this.drawerRef = this.overlayRef.attach(new ComponentPortal(DrawerComponent));
    }

    updateOptions(options: DrawerOptionsOfComponent): void {
        Object.assign(this.drawerRef?.instance, { inputs: {} }, options);
    }

    destroy(): void {
        this.overlayRef?.dispose();
        this.drawerRef?.destroy();
    }
}

@Injectable()
export class DrawerService implements OnDestroy {

    private _destroy$ = new Subject();

    constructor(private overlay: Overlay, private router: Router) {
    }

    open<T = any, D = any, R = any>(content: T, options: DrawerOptions<T, D> = {}): DrawerRef<R> {
        options.content = content as any;
        return new DrawerBuilderForService<R>(this.overlay, options).getInstance();
    }

    openPage<T = any, D = any, R = any>(content: T, options: DrawerOptions<T, D> = {}): DrawerRef<R> {
        let drawerClass = DrawerClass.PAGE + '';
        if (options.drawerClass) {
            drawerClass += ` ${options.drawerClass}`;
        }
        let builder = new DrawerBuilderForService<R>(
            this.overlay,
            Object.assign({}, options, {
                drawerClass,
                content,
                mask: false,
                disposeOnNavigation: true
            })
        );
        this.router.events.pipe(takeUntil(this._destroy$), filter(event => event instanceof NavigationEnd)).subscribe(() => builder.destroy());
        return builder.getInstance();
    }

    ngOnDestroy(): void {
        this._destroy$.next(undefined);
    }
}
