import { Type } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { isPresent } from 'share';
import { CACHE_DATA } from '../../cache/component.cache.model';
import { ComponentCacheService } from '../../cache/component.cache.service';
import { FilterInitializer } from '../../initializer';
import { PageComponent } from '../../page';
import { CacheTimeStrategy, SYS_PARAMS_MAP } from '../constants';
import { ComponentContext } from '../context';
import { Component } from '../directives';
import { AfterCacheInitializer } from './after.cache.initializer';
import { BeforeCacheInitializer } from './before.cache.initializer';
import { CacheInitializer } from './cache.initializer';
import { EditableInitializer } from './editable.initializer';
import { PoolInitializer } from './pool.initializer';
import { ProductInitializer } from './product.initializer';
import { SubscriptionInitializer } from './subscription.initializer';
import { ValidateInitializer } from './validate.initializer';
import { SysParamService } from '../../providers/sys.param.service';


function init(context: ComponentContext) {
    return function() {
        context = { ...context };
        context.instance = this;
        let cacheTime = this instanceof PageComponent ? CacheTimeStrategy.BeforeNgInit : CacheTimeStrategy.AfterNgInit;
        let options = {
            caches: [],
            cacheTime,
            initPool: false,
            initProduct: false,
            editConfigurable: true
        };
        context.options = { ...options, ...context.options };

        let initializers: Array<FilterInitializer<ComponentContext>> = [
            new SubscriptionInitializer(),
            new ValidateInitializer(),
            new BeforeCacheInitializer(),
            new CacheInitializer(),
            new AfterCacheInitializer(),
            new ProductInitializer(),
            new PoolInitializer(),
            new EditableInitializer()
        ];
        initializers
            .filter(item => item.support(context))
            .reduce<Observable<any>>((previousValue, currentValue) => previousValue.pipe(switchMap(ret => currentValue.init(ret))), of(context))
            .subscribe(() => {
                if (isPresent(this.render)) {
                    this.render();
                }
            });
    };
}

function destroy(context: ComponentContext) {
    return function() {
        context.ngDestroy.call(this);
        this.subscriptions.forEach($ => $.unsubscribe());
        let data = CACHE_DATA.get(this);
        if (!isPresent(data)) {
            return;
        }
        this.cacheService = environment.injector.get(ComponentCacheService);
        let keys = {};
        context.options.caches.forEach(key => keys[key] = this[key]);
        data.cache = JSON.stringify(keys);
        this.cacheService.update({
            user_id: data.user_id,
            list: [data]
        }).subscribe(() => CACHE_DATA.delete(this));
    };
}

function ngAdd$($: Subscription) {
    this.subscriptions.push($);
}

export class ComponentInitializerService {

    init(cls: Type<any>, options: Component): void {
        let context: ComponentContext = {
            cls,
            options,
            ngInit: cls.prototype.ngOnInit,
            ngDestroy: cls.prototype.ngOnDestroy
        };
        cls.prototype.ngOnInit = init(context);
        cls.prototype.ngAdd$ = ngAdd$;
        cls.prototype.ngOnDestroy = destroy(context);
    }
}

export class SysParamsInitializerService {

    init(cls: Type<any>, options: any): void {
        let context: any = {
            type: options.type,
            objectKey: options.objectKey ?? options.type,
            ngInit: cls.prototype.ngOnInit
        };

        cls.prototype.ngOnInit = function() {

            let observable: Observable<Map<string, any>>;
            if (SYS_PARAMS_MAP.has(context.type)) {
                observable = of(SYS_PARAMS_MAP.get(context.type));
            } else {
                const service = environment.injector.get(SysParamService);
                observable = service.qry(context.type).pipe(switchMap(items => {
                    const _map = new Map<string, any>();
                    items.forEach(item => _map.set(item.param_key, item.param_value));
                    SYS_PARAMS_MAP.set(context.type, _map);
                    return of(_map);
                }));
            }

            observable.subscribe((_map) => {
                const obj: any = {};
                _map.forEach((value, key) => obj[key] = value);
                this[context.objectKey] = obj;
                context.ngInit.call(this);
            });

        };
    }

}
