import { ChangeDetectionStrategy, Component, Input, OnChanges, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'ai-option-group',
    exportAs: 'optionGroup',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-content></ng-content>
    `
})
export class OptionGroupComponent implements OnChanges {

    @Input() label: string | TemplateRef<any> | null = null;

    changes = new Subject<void>();

    ngOnChanges(): void {
        this.changes.next(undefined);
    }
}
