import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPageService, MAX_PAGE_SIZE } from 'core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Role } from './role.model';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class RoleService implements IPageService {

    private baseUrl = '/xltp/sys/role';

    constructor(private http: HttpClient) {
    }

    qryByPage(params: { page_no: number; page_size: number; [index: string]: any }): Observable<any> {
        return this.http.get(this.baseUrl, { params });
    }

    qryAll(product_id?: any): Observable<Array<Role>> {
        let params = new HttpParams().set('page_no', '1').set('page_size', `${MAX_PAGE_SIZE}`).set('product_id', product_id ?? '');
        return this.http.get(this.baseUrl, { params }).pipe(map<any, Array<Role>>(page => page.page_data));
    }

    qryById(id: number): Observable<Role> {
        return this.http.get<Role>(`${this.baseUrl}/${id}`);
    }

    add(role: Role): Observable<any> {
        return this.http.post(this.baseUrl, role);
    }

    mod(role: Role): Observable<any> {
        if (!isPresent(role.role_id)) {
            return this.add(role);
        }
        return this.http.post(`${this.baseUrl}/${role.role_id}/modify`, role);
    }

    del(role: Role): Observable<any> {
        return this.http.post(`${this.baseUrl}/${role.role_id}/delete`, {});
    }
}
