import { Component } from './directives';
import { ComponentInitializerService, SysParamsInitializerService } from './initializer/initializer.service';


export function AiComponent(options: Component = {}) {
    return function(Cls: any): any {
        new ComponentInitializerService().init(Cls, options);
    };
}

// 列表或循环使用时，需同时在父组件使用
export function SysParams(options: { type: string; objectKey?: string; }) {
    return function(cls: any): any {
        new SysParamsInitializerService().init(cls, options);
    };
}
