import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AppSkinComponent } from 'app/main/skin/app.skin.component';
import { DrawerService } from 'share';

@Component({
    selector: 'ai-app-skin-btn',
    templateUrl: 'app.skin.btn.component.html'
})

export class AppSkinBtnComponent implements OnInit, OnDestroy {

    constructor(private drawer: DrawerService) {
    }

    ngOnInit() {
    }

    @HostListener('click')
    click(): void {
        this.drawer.open(AppSkinComponent);
    }


    ngOnDestroy() {
    }
}
