import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BatchSelectService } from './batch.select.service';

@Component({
    selector: 'batch-select-all', //tslint:disable-line
    templateUrl: 'batch.select.all.component.html'
})

export class BatchSelectAllComponent implements OnInit, OnDestroy {

    @Input() disabled: boolean;

    constructor(private selectService: BatchSelectService<any>) {
    }

    ngOnInit() {
    }

    get checked(): boolean {
        return this.selectService.all;
    }

    set checked(value: boolean) {
        this.selectService.all = value;
        this.selectService.changeAll();
    }

    ngOnDestroy() {
    }
}
