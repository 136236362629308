import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ShapeType, SizeLDSType } from '../types';

@Component({
    selector: 'avatar', //tslint:disable-line
    exportAs: 'avatar',
    template: `
        <i class="icon" *ngIf="icon && hasIcon" [ngClass]="icon"></i>
        <img *ngIf="src && hasSrc" [src]="src" [attr.srcset]="srcSet" [attr.alt]="alt" (error)="imgError($event)" />
        <span class="avatar-string" #textEl [ngStyle]="textStyles" *ngIf="text && hasText">{{ text | cnLast }}</span>
    `,
    host: {
        '[class.avatar]': 'true',
        '[class.avatar-lg]': `size === 'large'`,
        '[class.avatar-sm]': `size === 'small'`,
        '[class.avatar-square]': `shape === 'square'`,
        '[class.avatar-circle]': `shape === 'circle'`,
        '[class.avatar-icon]': `icon`,
        '[class.avatar-image]': `hasSrc `,
        '[style.width]': 'customSize',
        '[style.height]': 'customSize',
        '[style.line-height]': 'customSize',
        '[style.font-size.px]': '(hasIcon && customSize) ? $any(size) / 2 : null'
    },
    preserveWhitespaces: false,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AvatarComponent implements OnChanges {

    @Input() shape: ShapeType = 'circle';
    @Input() size: SizeLDSType = 'default';
    @Input() text?: string;
    @Input() src?: string;
    @Input() srcSet?: string;
    @Input() alt?: string;
    @Input() icon?: string;
    @Output() readonly ngError = new EventEmitter<Event>();

    hasText: boolean = false;
    hasSrc: boolean = true;
    hasIcon: boolean = false;
    textStyles: any = {};
    customSize: string | null = null;

    @ViewChild('textEl', { static: false }) textEl?: ElementRef;

    private el: HTMLElement = this.elementRef.nativeElement;

    constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {
    }

    imgError($event: Event): void {
        this.ngError.emit($event);
        if (!$event.defaultPrevented) {
            this.hasSrc = false;
            this.hasIcon = false;
            this.hasText = false;
            if (this.icon) {
                this.hasIcon = true;
            } else if (this.text) {
                this.hasText = true;
            }
            this.cdr.detectChanges();
            this.setSizeStyle();
            this.notifyCalc();
        }
    }

    ngOnChanges(): void {
        this.hasText = !this.src && !!this.text;
        this.hasIcon = !this.src && !!this.icon;
        this.hasSrc = !!this.src;

        this.setSizeStyle();
        this.notifyCalc();
    }

    private calcStringSize(): void {
        if (!this.hasText) {
            return;
        }

        const childrenWidth = this.textEl.nativeElement.offsetWidth;
        const avatarWidth = this.el.getBoundingClientRect().width;
        const scale = avatarWidth - 8 < childrenWidth ? (avatarWidth - 8) / childrenWidth : 1;
        this.textStyles = { transform: `scale(${scale}) translateX(-50%)` };
        if (this.customSize) {
            Object.assign(this.textStyles, { lineHeight: this.customSize });
        }
        this.cdr.detectChanges();
    }

    private notifyCalc(): void {
        setTimeout(() => this.calcStringSize());
    }

    private setSizeStyle(): void {
        if (typeof this.size === 'number') {
            this.customSize = `${this.size}px`;
        } else {
            this.customSize = null;
        }
        this.cdr.markForCheck();
    }
}
