import { NgModule } from '@angular/core';
import { ShareModule } from 'share';
import { SearchFilterService } from './search.filter.service';
import { SearchFilterHolder } from './search.filter.holder';
import { SearchFilterComponent } from './search.filter.component';
import { SearchFilterModalComponent } from './search.filter.modal.component';
import { SearchFilterBtnComponent } from './search.filter.btn.component';


@NgModule({
    imports: [
        ShareModule
    ],
    exports: [
        SearchFilterComponent,
        SearchFilterBtnComponent
    ],
    declarations: [
        SearchFilterComponent,
        SearchFilterModalComponent,
        SearchFilterBtnComponent
    ],
    providers: [
        SearchFilterHolder,
        SearchFilterService
    ]
})
export class SearchFilterModule {
}
