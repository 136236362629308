import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { Tenant, TenantProduct, TenantUser } from 'app/system/tenant/tenant.model';
import { IPageService, MAX_PAGE_SIZE, PAGE_SIZE } from 'core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class TenantService implements IPageService {

    private baseUrl = '/xltp/ats/tenant';

    constructor(private http: HttpClient) {
    }

    qryByPage(params: { page_no?: number; page_size?: number; [p: string]: any }): Observable<any> {
        return this.http.get(`${this.baseUrl}/list`, { params });
    }

    qryById(id: number, showError = true): Observable<Tenant> {
        let headers = new HttpHeaders();
        if (!showError) {
            headers = headers.set(Headers.SHOW_ERROR, 'false');
        }
        if (!isPresent(id)) {
            return of(null);
        }
        return this.http.get(`${this.baseUrl}/${id}`, { headers });
    }

    add(item: Tenant): Observable<any> {
        return this.http.post(`${this.baseUrl}`, item);
    }

    mod(item: Tenant): Observable<any> {
        return this.http.put(`${this.baseUrl}/${item.tenant_id}`, item);
    }

    del(item: Tenant): Observable<any> {
        return this.http.delete(`${this.baseUrl}/${item.tenant_id}`);
    }

    qryUsers(id: number): Observable<Array<TenantUser>> {
        return this.http.get(`${this.baseUrl}/${id}/userlist`, {
            params: {
                page_no: '1',
                page_size: `${MAX_PAGE_SIZE}`
            }
        }).pipe(map<any, Array<TenantUser>>(page => page.page_data || []));
    }

    qryUserPage(id: number, params: any): Observable<any> {
        return this.http.get(`${this.baseUrl}/${id}/userlist`, { params })
    }

    configUser(id: number, items: Array<TenantUser>): Observable<any> {
        return this.http.post(`${this.baseUrl}/${id}/user`, items);
    }

    qryProducts(id: number): Observable<Array<TenantProduct>> {
        return this.http.get(`${this.baseUrl}/${id}/productlist`, {
            params: {
                page_no: '1',
                page_size: `${PAGE_SIZE}`
            }
        }).pipe(map<any, Array<TenantProduct>>(page => page.page_data || []));
    }

    qryByUser(id: number, name?: string): Observable<Array<Tenant>> {
        return this.http.get(`${this.baseUrl}/${id}/tenantlist`, {
            params: {
                page_no: '1',
                page_size: `${MAX_PAGE_SIZE}`,
                tenant_name: name
            }
        }).pipe(map<any, Array<Tenant>>(page => page.page_data || []));
    }

    changeTenant(id: number): Observable<any> {
        return this.http.post(`${this.baseUrl}/${id}/refresh`, {});
    }

    currentTenant(id: number): Observable<Tenant> {
        return this.http.get(`${this.baseUrl}/user/${id}/current_tenant`);
    }

}
