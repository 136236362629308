import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbTab, NgbTabContent, NgbTabsetComponent, NgbTabTitle } from './tabset.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        NgbTabsetComponent,
        NgbTab,
        NgbTabContent,
        NgbTabTitle
    ],
    exports: [
        NgbTabsetComponent,
        NgbTab,
        NgbTabContent,
        NgbTabTitle
    ]
})
export class TabsetModule {
}
