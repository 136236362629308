import { NgModule } from '@angular/core';
import { ModalModule } from '../modal/modal.module';

import { ConfirmComponent } from './confirm.component';

@NgModule({
    imports: [
        ModalModule
    ],
    exports: [
        ConfirmComponent
    ],
    declarations: [
        ConfirmComponent
    ]
})
export class ConfirmModule {
}
