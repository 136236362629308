import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChange,
    TemplateRef
} from '@angular/core';

import { fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputBoolean } from '../facade';
import { TreeNode, TreeNodeOptions } from './tree-base-node';
import { TreeBaseService } from './tree-base.service';
import { FormatBeforeDropEvent, FormatEmitEvent } from './tree.definitions';

@Component({
    selector: 'ai-tree-node',
    exportAs: 'treeNode',
    template: `
        <ai-tree-indent
                [treeLevel]="treeNode.level"
                [selectMode]="selectMode"
                [isStart]="isStart"
                [isEnd]="isEnd"
        ></ai-tree-indent>
        <ai-tree-node-switcher
                *ngIf="showExpand"
                [showExpand]="showExpand"
                [showLine]="showLine"
                [expandedIcon]="expandedIcon"
                [selectMode]="selectMode"
                [context]="treeNode"
                [isLeaf]="isLeaf"
                [isExpanded]="isExpanded"
                [isLoading]="isLoading"
                (click)="clickExpand($event)">
        </ai-tree-node-switcher>
        <ai-tree-node-checkbox
                *ngIf="isCheckable"
                (checkBoxChange)="toggleCheckBox($event)"
                [selectMode]="selectMode"
                [isChecked]="isChecked"
                [isHalfChecked]="isHalfChecked"
                [isDisabled]="isDisabled"
                [isDisableCheckbox]="isDisableCheckbox">
        </ai-tree-node-checkbox>
        <ai-tree-node-title
                [icon]="icon"
                [title]="title"
                [isLoading]="isLoading"
                [isSelected]="isSelected"
                [isDisabled]="isDisabled"
                [isMatched]="isMatched"
                [isExpanded]="isExpanded"
                [isLeaf]="isLeaf"
                [searchValue]="searchValue"
                [treeTemplate]="treeTemplate"
                [draggable]="draggable"
                [showIcon]="showIcon"
                [selectMode]="selectMode"
                [context]="treeNode"
                (dblclick)="dblClick($event)"
                (click)="clickSelect($event)"
                (contextmenu)="contextMenu($event)">
        </ai-tree-node-title>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        '[class.select-tree-treenode]': `selectMode`,
        '[class.select-tree-treenode-disabled]': `selectMode && isDisabled`,
        '[class.select-tree-treenode-switcher-open]': `selectMode && isSwitcherOpen`,
        '[class.select-tree-treenode-switcher-close]': `selectMode && isSwitcherClose`,
        '[class.select-tree-treenode-checkbox-checked]': `selectMode && isChecked`,
        '[class.select-tree-treenode-checkbox-indeterminate]': `selectMode && isHalfChecked`,
        '[class.select-tree-treenode-selected]': `selectMode && isSelected`,
        '[class.select-tree-treenode-loading]': `selectMode && isLoading`,
        '[class.tree-treenode]': `!selectMode`,
        '[class.tree-treenode-disabled]': `!selectMode && isDisabled`,
        '[class.tree-treenode-switcher-open]': `!selectMode && isSwitcherOpen`,
        '[class.tree-treenode-switcher-close]': `!selectMode && isSwitcherClose`,
        '[class.tree-treenode-checkbox-checked]': `!selectMode && isChecked`,
        '[class.tree-treenode-checkbox-indeterminate]': `!selectMode && isHalfChecked`,
        '[class.tree-treenode-selected]': `!selectMode && isSelected`,
        '[class.tree-treenode-loading]': `!selectMode && isLoading`
    }
})
export class TreeNodeComponent implements OnInit, OnChanges, OnDestroy {

    @Input() icon: string = '';
    @Input() title: string = '';
    @Input() isLoading: boolean = false;
    @Input() isSelected: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() isMatched: boolean = false;
    @Input() isExpanded: boolean;
    @Input() isLeaf: boolean;
    @Input() isChecked?: boolean;
    @Input() isHalfChecked?: boolean;
    @Input() isDisableCheckbox?: boolean;
    @Input() isSelectable?: boolean;
    @Input() canHide?: boolean;
    @Input() isStart?: boolean[];
    @Input() isEnd?: boolean[];
    @Input() treeNode: TreeNode;
    @Input() @InputBoolean() showLine?: boolean;
    @Input() @InputBoolean() showExpand?: boolean;
    @Input() @InputBoolean() checkable?: boolean;
    @Input() @InputBoolean() multiple?: boolean;
    @Input() @InputBoolean() asyncData?: boolean;
    @Input() @InputBoolean() hideUnMatched = false;
    @Input() @InputBoolean() selectMode = false;
    @Input() @InputBoolean() showIcon = false;
    @Input() expandedIcon?: TemplateRef<{ $implicit: TreeNode; origin: TreeNodeOptions }>;
    @Input() treeTemplate: TemplateRef<{ $implicit: TreeNode; origin: TreeNodeOptions }> | null = null;
    @Input() beforeDrop?: (confirm: FormatBeforeDropEvent) => Observable<boolean>;
    @Input() searchValue = '';
    @Input() draggable: boolean = false;
    @Output() readonly ngClick = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngDblClick = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngContextMenu = new EventEmitter<FormatEmitEvent>();
    @Output() readonly checkBoxChange = new EventEmitter<FormatEmitEvent>();
    @Output() readonly expandChange = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngOnDragStart = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngOnDragEnter = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngOnDragOver = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngOnDragLeave = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngOnDrop = new EventEmitter<FormatEmitEvent>();
    @Output() readonly ngOnDragEnd = new EventEmitter<FormatEmitEvent>();

    /**
     * drag var
     */
    destroy$ = new Subject();
    dragPos = 2;
    dragPosClass: { [key: string]: string } = {
        '0': 'drag-over',
        '1': 'drag-over-gap-bottom',
        '-1': 'drag-over-gap-top'
    };

    constructor(public treeService: TreeBaseService,
                private ngZone: NgZone,
                private renderer: Renderer2,
                private elementRef: ElementRef,
                private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.treeNode.component = this;
    }

    get isCheckable() {
        return this.checkable && (this.multiple || this.treeNode.isLeaf);
    }

    get isSwitcherOpen(): boolean {
        return this.isExpanded && !this.isLeaf;
    }

    get isSwitcherClose(): boolean {
        return !this.isExpanded && !this.isLeaf;
    }


    ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
        const { draggable } = changes;
        if (draggable) {
            this.handDragEvent();
        }
    }

    @HostListener('mousedown', ['$event'])
    onMousedown(event: MouseEvent): void {
        if (this.selectMode) {
            event.preventDefault();
        }
    }

    clickExpand(event: MouseEvent): void {
        event.preventDefault();
        if (!this.isLoading && !this.isLeaf) {
            // set async state
            if (this.asyncData && this.treeNode.children.length === 0 && !this.isExpanded) {
                this.treeNode.isLoading = true;
            }
            this.treeNode.setExpanded(!this.isExpanded);
        }
        this.treeService.updateExpandedNodes(this.treeNode);
        const eventNext = this.treeService.formatEvent('expand', this.treeNode, event);
        this.expandChange.emit(eventNext);
    }

    clickSelect(event: MouseEvent): void {
        event.preventDefault();
        if (!this.treeNode.isSelectable && this.treeNode.isLeaf) {
            this.toggleCheckBox(!this.treeNode.isChecked);
            return;
        }
        if (this.isSelectable && !this.isDisabled) {
            this.treeNode.isSelected = !this.treeNode.isSelected;
        }
        this.treeService.setSelectedNodeList(this.treeNode);
        const eventNext = this.treeService.formatEvent('click', this.treeNode, event);
        this.ngClick.emit(eventNext);
    }

    dblClick(event: MouseEvent): void {
        event.preventDefault();
        const eventNext = this.treeService.formatEvent('dblclick', this.treeNode, event);
        this.ngDblClick.emit(eventNext);
    }

    contextMenu(event: MouseEvent): void {
        event.preventDefault();
        const eventNext = this.treeService.formatEvent('contextmenu', this.treeNode, event);
        this.ngContextMenu.emit(eventNext);
    }

    toggleCheckBox(checked: boolean): void {
        this.treeNode.isChecked = checked;
        this.treeNode.isHalfChecked = false;
        this.treeService.setCheckedNodeList(this.treeNode);
        const eventNext = this.treeService.formatEvent('check', this.treeNode, null);
        this.checkBoxChange.emit(eventNext);
    }

    clearDragClass(): void {
        const dragClass = ['drag-over-gap-top', 'drag-over-gap-bottom', 'drag-over'];
        dragClass.forEach(e => {
            this.renderer.removeClass(this.elementRef.nativeElement, e);
        });
    }


    handleDragStart(e: DragEvent): void {
        try {
            // ie throw error
            // firefox-need-it
            e.dataTransfer.setData('text/plain', this.treeNode.key);
        } catch (error) {
            // empty
        }
        this.treeService.setSelectedNode(this.treeNode);
        const eventNext = this.treeService.formatEvent('dragstart', this.treeNode, e);
        this.ngOnDragStart.emit(eventNext);
    }

    handleDragEnter(e: DragEvent): void {
        e.preventDefault();
        // reset position
        this.dragPos = 2;
        this.ngZone.run(() => {
            const eventNext = this.treeService.formatEvent('dragenter', this.treeNode, e);
            this.ngOnDragEnter.emit(eventNext);
        });
    }

    handleDragOver(e: DragEvent): void {
        e.preventDefault();
        const dropPosition = this.treeService.calcDropPosition(e);
        if (this.dragPos !== dropPosition) {
            this.clearDragClass();
            this.dragPos = dropPosition;
            // leaf node will pass
            if (!(this.dragPos === 0 && this.isLeaf)) {
                this.renderer.addClass(this.elementRef.nativeElement, this.dragPosClass[this.dragPos]);
            }
        }
        const eventNext = this.treeService.formatEvent('dragover', this.treeNode, e);
        this.ngOnDragOver.emit(eventNext);
    }

    handleDragLeave(e: DragEvent): void {
        e.preventDefault();
        this.clearDragClass();
        const eventNext = this.treeService.formatEvent('dragleave', this.treeNode, e);
        this.ngOnDragLeave.emit(eventNext);
    }

    handleDragDrop(e: DragEvent): void {
        this.ngZone.run(() => {
            this.clearDragClass();
            const node = this.treeService.getSelectedNode();
            if (!node || (node && node.key === this.treeNode.key) || (this.dragPos === 0 && this.isLeaf)) {
                return;
            }
            // pass if node is leafNo
            const dropEvent = this.treeService.formatEvent('drop', this.treeNode, e);
            const dragEndEvent = this.treeService.formatEvent('dragend', this.treeNode, e);
            if (this.beforeDrop) {
                this.beforeDrop({
                    dragNode: this.treeService.getSelectedNode(),
                    node: this.treeNode,
                    pos: this.dragPos
                }).subscribe((canDrop: boolean) => {
                    if (canDrop) {
                        this.treeService.dropAndApply(this.treeNode, this.dragPos);
                    }
                    this.ngOnDrop.emit(dropEvent);
                    this.ngOnDragEnd.emit(dragEndEvent);
                });
            } else if (this.treeNode) {
                this.treeService.dropAndApply(this.treeNode, this.dragPos);
                this.ngOnDrop.emit(dropEvent);
            }
        });
    }

    handleDragEnd(e: DragEvent): void {
        e.preventDefault();
        this.ngZone.run(() => {
            // if user do not custom beforeDrop
            if (!this.beforeDrop) {
                const eventNext = this.treeService.formatEvent('dragend', this.treeNode, e);
                this.ngOnDragEnd.emit(eventNext);
            }
        });
    }

    /**
     * Listening to dragging events.
     */
    handDragEvent(): void {
        this.ngZone.runOutsideAngular(() => {
            if (this.draggable) {
                const nativeElement = this.elementRef.nativeElement;
                this.destroy$ = new Subject();
                fromEvent<DragEvent>(nativeElement, 'dragstart')
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((e: DragEvent) => this.handleDragStart(e));
                fromEvent<DragEvent>(nativeElement, 'dragenter')
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((e: DragEvent) => this.handleDragEnter(e));
                fromEvent<DragEvent>(nativeElement, 'dragover')
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((e: DragEvent) => this.handleDragOver(e));
                fromEvent<DragEvent>(nativeElement, 'dragleave')
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((e: DragEvent) => this.handleDragLeave(e));
                fromEvent<DragEvent>(nativeElement, 'drop')
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((e: DragEvent) => this.handleDragDrop(e));
                fromEvent<DragEvent>(nativeElement, 'dragend')
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((e: DragEvent) => this.handleDragEnd(e));
            } else {
                this.destroy$.next(undefined);
                this.destroy$.complete();
            }
        });
    }

    markForCheck(): void {
        this.cdr.markForCheck();
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }
}
