import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';
import { slideMotion, zoomBigMotion } from '../animation';
import { MenuModeType, MenuThemeType } from './menu.types';

@Component({
    selector: '[ai-submenu-none-inline-child]', //tslint:disable-line
    exportAs: 'nzSubmenuNoneInlineChild',
    encapsulation: ViewEncapsulation.None,
    animations: [zoomBigMotion, slideMotion],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div [class.ai-dropdown-menu]="isMenuInsideDropDown"
             [class.ai-menu]="!isMenuInsideDropDown"
             [class.ai-dropdown-menu-vertical]="isMenuInsideDropDown"
             [class.ai-menu-vertical]="!isMenuInsideDropDown"
             [class.ai-dropdown-menu-sub]="isMenuInsideDropDown"
             [class.ai-menu-sub]="!isMenuInsideDropDown"
             [ngClass]="menuClass">
            <ng-template [ngTemplateOutlet]="templateOutlet"></ng-template>
        </div>
    `,
    host: {
        '[class.ai-menu-submenu]': 'true',
        '[class.ai-menu-submenu-popup]': 'true',
        '[class.ai-menu-light]': `theme === 'light'`,
        '[class.ai-menu-dark]': `theme === 'dark'`,
        '[class.ai-menu-submenu-placement-bottom]': `mode === 'horizontal'`,
        '[class.ai-menu-submenu-placement-right]': `mode === 'vertical' && position === 'right'`,
        '[class.ai-menu-submenu-placement-left]': `mode === 'vertical' && position === 'left'`,
        '[@slideMotion]': 'expandState',
        '[@zoomBigMotion]': 'expandState',
        '(mouseenter)': 'setMouseState(true)',
        '(mouseleave)': 'setMouseState(false)'
    }
})
export class SubmenuNoneInlineChildComponent implements OnInit, OnChanges {

    @Input() menuClass: string = '';
    @Input() theme: MenuThemeType = 'light';
    @Input() templateOutlet: TemplateRef<any> | null = null;
    @Input() isMenuInsideDropDown = false;
    @Input() mode: MenuModeType = 'vertical';
    @Input() position = 'right';
    @Input() disabled = false;
    @Input() open = false;

    @Output() readonly subMenuMouseState = new EventEmitter<boolean>();

    expandState = 'collapsed';

    setMouseState(state: boolean): void {
        if (!this.disabled) {
            this.subMenuMouseState.next(state);
        }
    }

    calcMotionState(): void {
        if (this.open) {
            if (this.mode === 'horizontal') {
                this.expandState = 'bottom';
            } else if (this.mode === 'vertical') {
                this.expandState = 'active';
            }
        } else {
            this.expandState = 'collapsed';
        }
    }

    ngOnInit(): void {
        this.calcMotionState();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { mode, open } = changes;
        if (mode || open) {
            this.calcMotionState();
        }
    }
}
