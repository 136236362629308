export interface ComponentCache {
    id?: number;
    name?: string;
    cache?: string;

    user_id?: number;
    product_id?: number;
    operate_type?: string;
}

export const CACHE_DATA = new Map<any, ComponentCache>();
