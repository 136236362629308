import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GroupTreeComponent } from 'app/group/tree/group.tree.component';
import { TreeKeyPrefix } from 'core';
import { DrawerRef, flatNodes, TreeNode } from 'share';

@Component({
    selector: 'ai-group-select-modal',
    templateUrl: 'group.select.modal.component.html'
})

export class GroupSelectModalComponent implements OnInit, OnDestroy {

    @Input() ids: Array<number> = [];
    @Input() checkable: boolean;
    @Input() type: string;

    @ViewChild('tree')
    tree: GroupTreeComponent;

    constructor(public drawerRef: DrawerRef) {
    }

    ngOnInit() {
    }

    confirm(): void {
        let nodes = flatNodes(this.checkable ? this.tree.getCheckedNodes() : this.tree.getSelectedNodes()) as Array<TreeNode>;
        this.drawerRef.close(nodes.filter(node => node.key.startsWith(TreeKeyPrefix.GROUP)).map(node => node.origin));
    }

    ngOnDestroy(): void {
    }
}
