import { AfterContentInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ensureInRange } from '../facade';
import { ResizableService } from './resizable.service';

@Directive({
    selector: '[resizable]', // tslint:disable-line
    providers: [
        ResizableService
    ]
})
export class ResizableDirective implements AfterContentInit, OnDestroy {

    @Input() bounds: 'window' | 'parent' | 'scroll';
    @Input() minWidth = 0;

    private handleElement: HTMLElement;
    private resizing = false;
    private currentX = 0;

    private _direction: string = 'right';
    private destroy$ = new Subject<void>();

    constructor(private renderer: Renderer2,
                private elementRef: ElementRef<HTMLElement>,
                private resizeService: ResizableService) {
        this.resizeService.handleMouseDown$.pipe(takeUntil(this.destroy$)).subscribe(event => {
            this.resizing = true;
            this.resizeService.startResizing(event);
            this.currentX = event.pageX;
        });
        this.resizeService.documentMouseMove$.pipe(takeUntil(this.destroy$), filter(() => this.resizing)).subscribe(event => this.resize(event));
        this.resizeService.documentMouseUp$.pipe(takeUntil(this.destroy$)).subscribe(() => this.resizing = false);
    }

    ngAfterContentInit(): void {
        this.setPosition();
        this.initHandle();
    }

    @Input()
    set direction(value: string) {
        this._direction = value;
        this.initHandleClass();
    }

    get leftElement(): HTMLElement {
        if (!this.elementRef) {
            return null;
        }
        if (this._direction === 'right') {
            return this.elementRef.nativeElement;
        }
        return this.elementRef.nativeElement.previousElementSibling as HTMLElement;
    }

    get rightElement(): HTMLElement {
        if (!this.elementRef) {
            return null;
        }
        if (this._direction === 'right') {
            return this.elementRef.nativeElement.nextElementSibling as HTMLElement;
        }
        return this.elementRef.nativeElement;
    }

    private resize(event: MouseEvent): void {
        let x = event.pageX;
        this.setLeftWidth(x);
        this.setRightWidth(x);
        this.currentX = x;
    }

    private setLeftWidth(x: number): void {
        if (this.leftElement !== this.elementRef.nativeElement && this.bounds !== 'parent') {
            return;
        }

        let width = this.leftElement.getBoundingClientRect().width + x - this.currentX;
        width = ensureInRange(width, this.minWidth);
        this.leftElement.style.setProperty('width', `${width}px`, 'important');
    }

    private setRightWidth(x: number): void {
        if (this.rightElement !== this.elementRef.nativeElement && this.bounds !== 'parent') {
            return;
        }

        let width = this.rightElement.getBoundingClientRect().width - (x - this.currentX);
        width = ensureInRange(width, this.minWidth);
        this.rightElement.style.setProperty('width', `${width}px`, 'important');
    }

    private setPosition(): void {
        const position = getComputedStyle(this.elementRef.nativeElement).position;
        if (position === 'static' || !position) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'relative');
        }
    }

    private initHandle(): void {
        this.handleElement = this.renderer.createElement('div');
        this.renderer.appendChild(this.elementRef.nativeElement, this.handleElement);
        fromEvent<MouseEvent>(this.handleElement, 'mousedown').pipe(takeUntil(this.destroy$)).subscribe(evt => this.resizeService.handleMouseDown$.next(evt));
        this.initHandleClass();
    }

    private initHandleClass(): void {
        if (!this.handleElement) {
            return;
        }
        this.renderer.setAttribute(this.handleElement, 'class', `resizable-handle resizable-handle-${this._direction}`);
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
        this.renderer.removeChild(this.elementRef.nativeElement, this.handleElement);
    }
}
