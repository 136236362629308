import { ViewportRuler } from '@angular/cdk/overlay';
import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Directive({ selector: '.page-body' }) // tslint:disable-line
export class PageBodyDirective implements AfterViewInit, OnDestroy {

    private _changeSize$: Subject<any> = new Subject();
    private destroy$: Subject<any> = new Subject();

    constructor(private render: Renderer2, private viewportRuler: ViewportRuler, private elementRef: ElementRef<HTMLElement>) {
    }

    ngAfterViewInit(): void {
        this.viewportRuler.change().pipe(startWith(true), takeUntil(this.destroy$)).subscribe(() => {
            this.gapPageBody();
            this._changeSize$.next('');
        });
    }

    get changeSize$(): Observable<any> {
        return this._changeSize$.asObservable();
    }

    private gapPageBody(): void {
        let container = this.findContainer();
        if (!container) {
            return;
        }
        let isFixedFooter = container.classList.contains('fixed-page-footer');
        const topHeader = 64;
        let header = container.querySelector('.page-header');
        let footer = container.querySelector('.page-footer');
        let style = getComputedStyle(container);
        let padding = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
        let marginBottom = isFixedFooter ? (footer ? footer.getBoundingClientRect().height : 0) : 0;
        let minHeight = document.body.getBoundingClientRect().height - topHeader - padding - (header?.getBoundingClientRect().height || 0) - marginBottom;
        let body = container.querySelector('.page-body');
        this.render.setStyle(body, 'margin-bottom', `${marginBottom}px`);
        this.render.setStyle(body, 'min-height', `${minHeight}px`);

    }

    private findContainer(): HTMLElement {
        let element = this.elementRef.nativeElement;
        while (!element.classList.contains('page-container') && !element.tagName.equalsIgnoreCase('body')) {
            element = element.parentElement;
        }
        return element.classList.contains('page-container') ? element : null;
    }

    ngOnDestroy(): void {
        this.destroy$.next('');
        this.destroy$.complete();
    }
}
