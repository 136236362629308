export interface SkinColor {
    key?: string;
    name?: string;
    color?: string;
    desc?: string;
}

export const DEFAULT_COLORS: Array<SkinColor> = [
    {
        key: 'primary',
        name: '主题色',
        color: '#147bd1'
    },
    {
        key: 'success',
        name: '成功色',
        color: '#19c005'
    },
    {
        key: 'info',
        name: '信息色',
        color: '#0958fe'
    },
    {
        key: 'warning',
        name: '警示色',
        color: '#fd9909'
    },
    {
        key: 'danger',
        name: '错误色',
        color: '#fb0015'
    },
    {
        key: 'red',
        name: '薄暮',
        color: '#f5222d',
        desc: '斗志、奔放'
    },
    {
        key: 'volcano',
        name: '火山',
        color: '#fa541c',
        desc: '醒目、澎湃'
    },
    {
        key: 'orange',
        name: '日暮',
        color: '#fa8c16',
        desc: '温暖、欢快'
    },
    {
        key: 'gold',
        name: '金盏花',
        color: '#faad14',
        desc: '活力、积极'
    },
    {
        key: 'yellow',
        name: '日出',
        color: '#fadb14',
        desc: '出生、阳光'
    },
    {
        key: 'lime',
        name: '青柠',
        color: '#a0d911',
        desc: '自然、生机'
    },
    {
        key: 'green',
        name: '极光绿',
        color: '#52c41a',
        desc: '健康、创新'
    },
    {
        key: 'cyan',
        name: '明青',
        color: '#13c2c2',
        desc: '希望、坚强'
    },
    {
        key: 'blue',
        name: '拂晓蓝',
        color: '#1890ff',
        desc: '包容、科技、普惠'
    },
    {
        key: 'geekblue',
        name: '极客蓝',
        color: '#2f54eb',
        desc: '探索、钻研'
    },
    {
        key: 'purple',
        name: ' 酱紫',
        color: '#722ed1',
        desc: '优雅、浪漫'
    },
    {
        key: 'magenta',
        name: ' 法式洋红',
        color: '#eb2f96',
        desc: '明快、感性'
    }
];
