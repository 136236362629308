export enum ValueMode {
    DEFAULT = 'DEFAULT',
    FIXED = 'FIXED',
    PROP = 'PROP',
    SQL = 'SQL',
    DATAPOOL = 'DATAPOOL',
    GLOBAL = 'GLOBAL',
    NULL = 'NULL',
    UNDEFINED = 'UNDEFINED',
    FACTORY = 'FACTORY',
    RULE = 'RULE', // 生成器
    VAR = 'VAR',
    DB_FILE = 'DB_FILE',
    IN = 'IN_PARAM',
    EMPTY = 'EMPTY'
}

export const MODES = [
    ValueMode.DEFAULT,
    ValueMode.FIXED,
    ValueMode.PROP,
    ValueMode.SQL,
    ValueMode.DATAPOOL,
    ValueMode.GLOBAL,
    ValueMode.FACTORY,
    ValueMode.RULE
];

export const EMPTY_MODES = [
    ValueMode.NULL,
    ValueMode.UNDEFINED,
    ValueMode.EMPTY
];

const keys: ValueMode[] = [
    ...MODES,
    ...EMPTY_MODES,
    ValueMode.VAR
];

const values: string[] = [
    '默认值',
    '固定值',
    '属性值',
    'SQL',
    '数据池',
    '全局变量',
    '数据工厂',
    '生成器',
    'Null',
    'Undefined',
    '空字符串',
    '变量取值'
];


export function modeFormatter(mode: ValueMode): string {
    return values[keys.indexOf(mode)] || (mode === ValueMode.IN ? '入参' : '');
}

export class ValueModePredicate {

    constructor(private mode: ValueMode) {
    }

    isDefault(): boolean {
        return this.mode === ValueMode.DEFAULT;
    }

    isFixed(): boolean {
        return this.mode === ValueMode.FIXED;
    }

    isSql(): boolean {
        return this.mode === ValueMode.SQL;
    }

    isPool(): boolean {
        return this.mode === ValueMode.DATAPOOL;
    }

    isGlobal(): boolean {
        return this.mode === ValueMode.GLOBAL;
    }

    isFactory(): boolean {
        return this.mode === ValueMode.FACTORY;
    }

    isRule(): boolean {
        return this.mode === ValueMode.RULE;
    }

    isProp(): boolean {
        return this.mode === ValueMode.PROP;
    }

    isVar(): boolean {
        return this.mode === ValueMode.VAR;
    }
}
