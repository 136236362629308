import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProfilesService } from 'core';

@Component({
    selector: 'ai-main',
    templateUrl: 'main.component.html',
    styleUrls: ['main.scss']
})
export class MainComponent implements OnInit, OnDestroy {

    active: string;

    constructor(private profilesService: ProfilesService) {
    }

    ngOnInit(): void {
        if (this.onlyContent) {
            document.body.classList.add('only-page-body');
        } else {
            document.body.classList.remove('only-page-body');
        }
        this.profilesService.qryActive().subscribe(item => this.active = item);
    }

    get hasDetectMonitor() {
        return this.active?.includes('detect');
    }

    get onlyContent() {
        return self !== top;
    }

    ngOnDestroy(): void {
    }
}
