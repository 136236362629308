import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { Pool } from 'app/data/pool/pool.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPresent } from 'share';
import { MAX_PAGE_SIZE } from '../constants';
import { IPageService } from '../page';

@Injectable({ providedIn: 'root' })
export class PoolService implements IPageService {

    private baseUrl = '/xltp/ats/case/datapool';

    constructor(private http: HttpClient) {
    }

    qryByPage(page_params: { page_no?: number; page_size?: number; [p: string]: any }): Observable<any> {
        let params = new HttpParams();
        Object.keys(page_params).filter(key => isPresent(page_params[key])).forEach(key => params = params.set(key, page_params[key]));
        return this.http.get(`${this.baseUrl}/keys`, { params });
    }

    qryAll(product_id: number): Observable<Array<Pool>> {
        let params = new HttpParams().set('page_no', '1').set('page_size', `${MAX_PAGE_SIZE}`).set('product_id', `${product_id}`);
        return this.http.get(`${this.baseUrl}/keys`, { params }).pipe(map<any, Array<Pool>>(page => page.page_data || []));
    }

    importPool(file: File, product_id: number, env_id?: number): Observable<any> {
        let data = new FormData();
        data.append('file', file);
        data.append('product_id', `${product_id}`);
        if (isPresent(env_id)) {
            data.append('env_id', `${env_id}`);
        }
        let headers = new HttpHeaders().set(Headers.TRANSFORM_TO_JSON, 'false').set(Headers.BLOCK, '正在导入数据...');
        return this.http.post(`${this.baseUrl}/import`, data, { headers });
    }

    qryById(id: number): Observable<Pool> {
        return this.http.get<Pool>(`${this.baseUrl}/${id}`);
    }

    del(id: number): Observable<any> {
        return this.http.delete(`${this.baseUrl}/key/${id}`);
    }

    add(item: Pool): Observable<any> {
        return this.http.post(`${this.baseUrl}/key`, item);
    }

    mod(id: number, item: Pool): Observable<any> {
        return this.http.put(`${this.baseUrl}/key/${id}`, item);
    }

    recover(item: any): Observable<any> {
        return this.http.put(`${this.baseUrl}/values`, item);
    }

    importData(id: number, file: File, product_id: number): Observable<any> {
        let data = new FormData();
        data.append('file', file);
        data.append('product_id', `${product_id}`);
        let headers = new HttpHeaders().set(Headers.TRANSFORM_TO_JSON, 'false').set(Headers.BLOCK, '正在解析文件...');
        return this.http.post(`${this.baseUrl}/${id}/import/upload`, data, { headers });
    }

    importFileData(id: number, file: File, product_id: number): Observable<any> {
        let data = new FormData();
        data.append('file', file);
        data.append('product_id', `${product_id}`);
        let headers = new HttpHeaders().set(Headers.TRANSFORM_TO_JSON, 'false').set(Headers.BLOCK, '正在解析文件...');
        return this.http.post(`${this.baseUrl}/${id}/file/import`, data, { headers, responseType: 'text' });
    }

    saveFileData(id: number, product_id: any, item: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/${id}/import/save`, item, { params: { product_id } });
    }

    handlerData(item: any): Observable<any> {
        return this.http.post(`/xltp/ats/data/generator/getandtransfer`, item);
    }

}
