import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ScrollbarComponent } from './scrollbar.component';
import { ScrollbarConfig } from './scrollbar.model';

@Component({
    selector: 'x-scrollbar', // tslint:disable-line
    templateUrl: 'scrollbar.component.html',
    styleUrls: ['scrollbar.scss'],
    providers: [ScrollbarConfig]
})
export class XScrollbarComponent extends ScrollbarComponent {

    constructor(elementRef: ElementRef, render: Renderer2, dConfig: ScrollbarConfig) {
        let config = new ScrollbarConfig();
        config.update(dConfig);
        config.suppressScrollX = false;
        config.suppressScrollY = true;
        super(elementRef, render, config);
    }
}
