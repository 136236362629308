import { ContentChildren, Directive, OnDestroy, OnInit, Optional, QueryList } from '@angular/core';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { isPresent } from '../facade';
import { InputDirective } from './input.directive';
import { updateValueAndValidity } from './tool';

@Directive({
    selector: 'form:not([novalidate]),ng-form', // tslint:disable-line
    host: { 'attr.novalidate': 'novalidate' } // tslint:disable-line
})
export class FormDirective implements OnInit, OnDestroy {

    @ContentChildren(InputDirective) inputs: QueryList<InputDirective>;


    constructor(@Optional() private ngForm: NgForm, @Optional() private group: FormGroupDirective) {
    }

    ngOnInit(): void {
        let ngSubmit = this.ngForm?.ngSubmit ?? this.group.ngSubmit;
        ngSubmit.emit = value => {
            const form = this.ngForm ?? this.group.control;
            updateValueAndValidity(form);
            this.toggleError();

            if (!form.invalid) {
                ngSubmit.next(value);
            } else {
                toastr.error('请完善基本信息');
            }
        };
    }

    private toggleError() {
        if (!isPresent(this.inputs)) {
            return;
        }
        this.inputs.forEach(item => item.showError());
    }

    ngOnDestroy(): void {
    }

}
