import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'app/app.constants';
import { UIPage, UIPageParams } from 'app/ui/page/ui.page.model';
import { IPageService, MAX_PAGE_SIZE } from 'core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class UIPageService implements IPageService {

    private baseUrl = '/xltp/ats/component';

    constructor(private http: HttpClient) {
    }

    qryByPage(params: { page_no?: number, page_size?: number, [key: string]: any }): Observable<any> {
        let search = new HttpParams();
        Object.keys(params).filter(key => isPresent(params[key])).forEach(key => search = search.set(key, params[key]));
        return this.http.get(`${this.baseUrl}/list`, { params: search });
    }

    qryAll(params: { product_id?: number; fun_id?: number; component_name?: string; [prop: string]: any; }): Observable<Array<UIPage>> {
        let search = new HttpParams().set('page_no', '1').set('page_size', `${MAX_PAGE_SIZE}`);
        Object.keys(params).filter(key => isPresent(params[key])).forEach(key => search = search.set(key, params[key]));
        return this.http.get(`${this.baseUrl}/list`, { params: search }).pipe(map<any, Array<any>>(data => data.page_data || []));
    }

    qryByName(product_id: number, name: string): Observable<Array<UIPage>> {
        let params = new HttpParams();
        params = params.set('component_name', name);
        params = params.set('product_id', `${product_id}`);
        params = params.set('page_no', '1');
        params = params.set('page_size', `${MAX_PAGE_SIZE}`);
        return this.http.get(`${this.baseUrl}/list`, { params }).pipe(map<any, Array<any>>(data => data.page_data || []));
    }

    qryById(id: number, version?: string): Observable<UIPage> {
        if (!isPresent(id)) {
            return of({});
        }
        const query = version ? `?version=${version}` : '';
        return this.http.get(`${this.baseUrl}/${id}${query}`).pipe(map<any, UIPage>(item => item));
    }

    qryByIds(ids: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/find_component_by_ids/${ids}`);
    }

    save(item: UIPage): Observable<any> {
        return this.http.post(`${this.baseUrl}`, item);
    }

    mod(id: number, item: UIPage): Observable<any> {
        return this.http.put(`${this.baseUrl}/${id}`, item);
    }

    del(id: number): Observable<any> {
        let headers = new HttpHeaders().set(Headers.Confirm, '');
        return this.http.delete(`${this.baseUrl}/${id}`, { headers });
    }

    move(id: number, step: number): Observable<any> {
        let headers = new HttpHeaders().set(Headers.BLOCK, '正在移动...');
        return this.http.post(`${this.baseUrl}/${id}/move/${step}`, {}, { headers });
    }

    import(file: File, product_id: any): Observable<any> {
        let data = new FormData();
        data.append('file', file);
        data.append('product_id', product_id);
        let header = new HttpHeaders().set(Headers.BLOCK, '正在解析excel文件');
        return this.http.post(`${this.baseUrl}/importcomponent`, data, { headers: header });
    }

    export(product_id: number, groups: Array<number>, components: Array<number>): void {

        let params = new HttpParams().append('product_id', `${product_id}`);
        if (groups.length > 0) {
            params = params.append('group_ids', groups.join(','));
        }
        if (components.length > 0) {
            params = params.append('component_ids', components.join(','));
        }
        window.open(`${this.baseUrl}/exportcomponent?${params.toString()}`, '_blank');
    }

    qryParamsByIds(ids: Array<any>, direction?: any): Observable<Array<UIPageParams>> {
        return this.http.get(`${this.baseUrl}/componentparams`, {
            params: {
                ids: ids.join(','),
                direction: direction || ''
            }
        }).pipe(map<any, Array<UIPageParams>>(items => items || []));
    }

    qryCases(id: number): Observable<Array<any>> {
        return this.http.get(`${this.baseUrl}/${id}/cases`).pipe(map<any, Array<any>>(items => items || []));
    }

    qryOpDesc(params: any): Observable<any> {
        return this.http.get(`/xltp/ats/case/operate/get_remark`, {
            responseType: 'text',
            params
        });
    }

}
