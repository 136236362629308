import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageVersion } from 'app/ui/page/ui.page.model';
import { IPageService } from 'core';
import { Observable } from 'rxjs';

@Injectable()
export class PageVersionService implements IPageService {

    private baseUrl = '/xltp/ats/component';

    constructor(private http: HttpClient) {
    }

    qryByPage(params: { page_no?: number, page_size?: number, [key: string]: any }): Observable<any> {
        return this.http.get(`${this.baseUrl}/${params.component_id}/version/list`, { params });
    }

    qry(id: number, version_id: number): Observable<PageVersion> {
        return this.http.get(`${this.baseUrl}/${id}/version/${version_id}`);
    }

    qryPre(id: number, version_id: number): Observable<PageVersion> {
        return this.http.get(`${this.baseUrl}/${id}/version/${version_id}/prev`);
    }

}
