import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BackComponent2 } from './back.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
@NgModule({
    imports: [
        CommonModule,
        NzIconModule
    ],
    exports: [
        BackComponent2,
        
    ],
    declarations: [
        BackComponent2,
        
    ]
})
export class BackModule2 {
}
