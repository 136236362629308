import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPageService, MAX_PAGE_SIZE } from 'core';
import { isAdmin } from 'app/system/user/user.model';
import { ProductUser } from 'app/product/product.model';
import { GlobalHolder } from 'app/global.holder';


@Injectable({ providedIn: 'root' })
export class ProductUserService implements IPageService {

    private baseUrl = '/xltp/prod';

    constructor(private http: HttpClient, private global: GlobalHolder) {
    }

    qryByPage(params: { page_no?: number; page_size?: number; [p: string]: any }): Observable<any> {
        params.page_size=10000
        return this.http.get(`${this.baseUrl}/${params.product_id}/relation`, { params });
    }

    qryAll(product_id: number): Observable<Array<ProductUser>> {
        let params = new HttpParams().set('page_no', '1').set('page_size', `${MAX_PAGE_SIZE}`);
        return this.http.get(`${this.baseUrl}/${product_id}/relation`, { params }).pipe(map<any, Array<ProductUser>>(page => (page?.page_data || []).map(item => new ProductUser(item))));
    }

    saveUser(product_id: number, users: Array<ProductUser>): Observable<any> {
        return this.http.post(`${this.baseUrl}/${product_id}/relation`, users);
    }

    transform(items: Array<any> = []): Array<ProductUser> {
        if (items.length === 0) {
            return [];
        }
        const users = new Map<number, ProductUser>();
        this.global.users.getValue().forEach(user => users.set(user.user_id, user));

        const curr = users.get(this.global.user_id);
        return items.filter(item => users.has(+item.user_id)).map(item => {
            const user = users.get(+item.user_id);
            user.editable = this.canEdit(user, curr);
            return user;
        });
    }

    private canEdit(item: ProductUser, curr: ProductUser): boolean {
        if (isAdmin(item)) {
            return false;
        }
        if (isAdmin(this.global.user.getValue())) {
            return true;
        }
        if (item?.isAdmin) {
            return false;
        }
        return curr?.isAdmin;
    }
}
