import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isPresent, validateError } from 'share';
import { SCRIPT_TYPES, UIPage } from '../../ui.page.model';

@Component({
    selector: 'ai-page-basic',
    templateUrl: 'page.basic.component.html'
})

export class PageBasicComponent implements OnInit {

    @Input() item: UIPage;
    @Input() editable: boolean;

    script_types: Array<{ id: string, name: string }> = SCRIPT_TYPES;

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.item.edit_type='script'
    }

    get hasGroup(): boolean {
        return isPresent(this.route.snapshot.queryParams.group_id);
    }

    validate(): void {
        if (!this.item.component_name) {
            validateError('请输入组件名称');
        }

        if (!isPresent(this.item.fun_id)) {
            validateError('请选择分组');
        }
    }

}
