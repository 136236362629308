import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { isPresent } from '../facade';
import { BaseUrlStrategy } from '../providers';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';


@Component({
    selector: 'download', // tslint:disable-line
    templateUrl: 'download.component.html',
    styleUrls: ['download.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadComponent implements OnInit, OnDestroy {

    @Input() validator: () => boolean;
    @Input() throttleTime = 3000;
    @Input() disabled = false;

    @ViewChild('a')
    private a: ElementRef;

    private _url: string;
    private subscriptions: Subscription[] = [];

    constructor(private el: ElementRef,
                private strategy: BaseUrlStrategy,
                private storage: LocalStorageService) {
        this.validator = () => true;
    }

    ngOnInit() {
        const a$ = fromEvent(this.el.nativeElement.firstChild, 'click')
            .subscribe((event: any) => {
                event.stopPropagation();
            });
        this.subscriptions.push(a$);

        const host$ = fromEvent(this.el.nativeElement, 'click')
            .pipe(throttleTime(this.throttleTime))
            .subscribe(() => {
                this.onClick();
            });
        this.subscriptions.push(host$);
    }

    get url(): string {
        let token = this.storage.retrieve('token');
        if (!isPresent(token) || !isPresent(this._url)) {
            return this.strategy.path(this._url);
        }
        return this.strategy.path(`${this._url}${this._url.indexOf('?') !== -1 ? '&' : '?'}access_token=${token?.access_token}`);
    }

    @Input()
    set url(value: string) {
        this._url = value.endsWith('?') ? value.replace('?', '') : value;
    }

    onClick(): void {
        if (this.disabled) {
            return;
        }
        if (this.validator()) {
            window.open(this.url);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.filter(s => isPresent(s)).forEach(s => s.unsubscribe());
    }
}
