import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateHelperService, isPresent } from 'share';

@Injectable({ providedIn: 'root' })
export class ParamsInterceptor implements HttpInterceptor {

    constructor(private dateHelper: DateHelperService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let params = new HttpParams();
        req.params.keys().forEach(key => {
            let values: Array<any> = req.params.getAll(key);
            if (values.every(v => !isPresent(v) || v === '')) {
                return;
            }
            values.filter(v => isPresent(v)).forEach((v: any) => {
                if (v.indexOf && v.indexOf('GMT+0800') > 0) {
                    params = params.append(key, this.dateHelper.format(new Date(v)));
                } else {
                    params = params.append(key, v instanceof Date ? this.dateHelper.format(v) : v);
                }
            });
        });
        return next.handle(req.clone({ params }));
    }
}
