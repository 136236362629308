import { NgModule } from '@angular/core';
import { SingleLoginComponent } from './single.login.component';
import { route } from './single.login.route';

@NgModule({
    imports: [
        route
    ],
    declarations: [
        SingleLoginComponent
    ]
})
export class SingleLoginModule {
}