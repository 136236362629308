import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpanTooltipDirective } from './span.tooltip';


@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        SpanTooltipDirective
    ],
    declarations: [
        SpanTooltipDirective
    ]
})
export class SpanModule {
}
