export enum TreeKeyPrefix {
    GROUP = 'group_',
    USER = 'user_',
    SERVICE = 'service_',
    INTERFACE = 'interface_',
    PLAN = 'plan_',
    CASE = 'case_',
    DEMAND = 'DEMAND_',
    MACHINE = 'machine_',
    JAR = 'JAR_',
    CLASS = 'class_',
    METHOD = 'method_',
    SELECT = 'select_',
    PAGE = 'page_',
}

export enum TreeIcon {
    // GROUP = 'icon-folder',
    GROUP = 'new-icon-folder',
    USER = 'icon-user',
    SERVICE = 'icon-database',
    INTERFACE = 'icon-interface',
    PLAN = 'icon-plan',
    CASE = '',
    DIRECTORY = 'icon-folder',
    FILE = 'icon-file',
    CLASS = '',
    PAGE = 'icon-page',
    METHOD = 'icon-method'
}
