import { AfterViewInit, Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { isPresent } from '../facade';

@Directive({ selector: 'ng-form' }) // tslint:disable-line
export class SubmitDirective implements AfterViewInit, OnDestroy {

    constructor(private ngForm: NgForm, private elementRef: ElementRef) {
        this.submit = this.submit.bind(this);
    }

    ngAfterViewInit(): void {
        let submitBtn = this.getSubmitBtn();
        if (isPresent(submitBtn)) {
            submitBtn.addEventListener('click', this.submit);
        }
    }

    @HostListener('keydown', ['$event'])
    private keydown(event: KeyboardEvent): void {
        let target = event.target as HTMLElement;
        if (target.classList.contains('note-editable') || target.nodeName.toLocaleLowerCase() === 'textarea') {
            return;
        }
        if (event.key === 'Enter') {
            this.submit(event);
        }
    }

    private getSubmitBtn(): HTMLElement {
        let element = this.elementRef.nativeElement as HTMLElement;
        return element.querySelector('[type="submit"]');
    }

    submit(event: Event): void {
        this.ngForm.onSubmit(event);
    }

    ngOnDestroy(): void {
        let submitBtn = this.getSubmitBtn();
        if (isPresent(submitBtn)) {
            submitBtn.removeEventListener('click', this.submit);
        }
    }

}
