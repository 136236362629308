import { Component, Input, OnInit } from '@angular/core';
import { UIPage } from 'app/ui/page/ui.page.model';

@Component({
    selector: 'ai-page-win',
    templateUrl: 'page.win.component.html',
    styleUrls: ['page.win.scss']
})
export class PageWinComponent implements OnInit {

    @Input() item: UIPage;
    @Input() editable: boolean;
    @Input() isModal: boolean;

    constructor() {
    }

    ngOnInit() {
    }
}
