import { Pipe, PipeTransform } from '@angular/core';
import { REPEATER_CASE_STATUS_KEYS, REPEATER_CASE_STATUS_NAMES } from '../constants/repeater';

@Pipe({
    name: 'repeaterCaseStatus'
})
export class RepeaterCaseStatusPipe implements PipeTransform {

    transform(status: any): string {
        return REPEATER_CASE_STATUS_NAMES[REPEATER_CASE_STATUS_KEYS.indexOf(status)];
    }
}
