import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { EMPTY_FUNCTION } from '../../facade';
import { AbstractPanelHeader } from '../abstract-header';
import { PanelSelector } from '../interface';

@Component({
    selector: 'decade-header', // tslint:disable-line
    exportAs: 'decadeHeader',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../abstract-header.html'
})
export class DecadeHeaderComponent extends AbstractPanelHeader {

    previous(): void {
    }

    next(): void {
    }

    get startYear(): number {
        return parseInt(`${this.value.getYear() / 100}`, 10) * 100;
    }

    get endYear(): number {
        return this.startYear + 99;
    }

    superPrevious(): void {
        this.changeValue(this.value.addYears(-100));
    }

    superNext(): void {
        this.changeValue(this.value.addYears(100));
    }

    getSelectors(): PanelSelector[] {
        return [{
            onClick: EMPTY_FUNCTION,
            label: `${this.startYear}-${this.endYear}`
        }];
    }
}
