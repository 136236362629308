import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isObject } from 'share';
import { PARAMS_CACHES, PARAMS_SEARCH_TYPE, SearchFilterOption } from './search.filter.model';


@Injectable()
export class SearchFilterHolder {

    options: BehaviorSubject<Array<SearchFilterOption>> = new BehaviorSubject<Array<SearchFilterOption>>([]);
    option: BehaviorSubject<SearchFilterOption> = new BehaviorSubject<SearchFilterOption>(null);
    params: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(@Inject(PARAMS_SEARCH_TYPE) public type: string) {
        this.updateParams(PARAMS_CACHES.get(this.type));
    }

    updateOptions(value: Array<SearchFilterOption>): void {
        this.options.next(value);
    }

    updateOption(value: SearchFilterOption): void {
        this.option.next(value);
    }

    addOption(value: SearchFilterOption): void {
        const items = this.options.getValue();
        const index = items.findIndex(o => o.search_name === value.search_name);
        if (index !== -1) {
            items.splice(index, 1, value);
        } else {
            items.push(value);
        }
        this.updateOptions(items);
    }

    updateParams(value: any): void {
        if (!isObject(value)) {
            return;
        }
        this.params.next(value);
    }

    cacheParams(value: any): void {
        PARAMS_CACHES.set(this.type, value);
    }
}
