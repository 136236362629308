import { Component, Input, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { isPresent } from '../facade';

@Component({
    selector: 'animation-number', // tslint:disable-line
    templateUrl: 'animation.number.component.html'
})
export class AnimationNumberComponent implements OnInit {

    private _value = 0;

    private interval$: Subscription;

    constructor() {
    }

    ngOnInit() {
    }

    get value(): number {
        return this._value;
    }

    @Input()
    set value(value: number) {
        this.clear();
        let great = value > this._value;
        let step = Math.ceil((value - this._value) / 20);
        if (!great) {
            step = Math.floor((value - this._value) / 20);
        }
        this.interval$ = interval(50).subscribe(() => {
            if ((great && this._value + step >= value) || (!great && this._value + step <= value)) {
                this._value = value;
                this.clear();
                return;
            }
            this._value += step;
        });
    }

    private clear(): void {
        if (isPresent(this.interval$)) {
            this.interval$.unsubscribe();
        }
    }
}
