import { Pipe, PipeTransform } from '@angular/core';
import { isPresent } from 'share';

@Pipe({
    name: 'poolName'
})
export class PoolNamePipe implements PipeTransform {
    transform(value: any, pools: Array<any>): any {
        let pool = pools.find(item => item.data_key === value);
        return isPresent(pool) ? pool.key_name : '请选择数据池';
    }
}
