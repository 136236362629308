import { isPresent } from './lang';

export function formatDate(dateStr?: any) {
    if (!isPresent(dateStr)) {
        return '';
    }
    let date: Date;
    if (dateStr.constructor === Date) {
        date = dateStr;
    } else if (typeof dateStr === 'number') {
        date = new Date(dateStr);
    } else {
        date = new Date(+dateStr);
    }
    return `${date.getFullYear()}-${ensureTwo(date.getMonth() + 1)}-${ensureTwo(date.getDate())} ${ensureTwo(date.getHours())}:${ensureTwo(date.getMinutes())}:${ensureTwo(date.getSeconds())}`;
}

export function ensureTwo(num: any): string {
    if (+num < 10) {
        return `0${num}`;
    }
    return num;
}
