import { Component, Input, OnInit } from '@angular/core';
import { ModalRef } from 'share';
import { convertToScript, UIStep } from 'app/ui/page/step/ui.page.step.model';
import { UIPageService } from 'app/ui/page/ui.page.service';
import { GlobalHolder } from 'app/global.holder';

@Component({
    selector: 'ai-page-step-saveas',
    templateUrl: 'ui.page.step.saveas.component.html',
    styles: [`
        ::ng-deep .modal-wrap .modal {
            width: 45rem;
        }
    `]
})
export class UIPageStepSaveasComponent implements OnInit {

    @Input() steps: UIStep[] = [];
    item: any = {};

    constructor(public modalRef: ModalRef,
                private globalHolder: GlobalHolder,
                private pageService: UIPageService) {
    }

    ngOnInit() {
    }

    submit(): void {
        this.item.edit_type = 'step';
        this.item.product_id = this.globalHolder.product_id;
        this.item.script = convertToScript({
            steps: this.steps
        });
        this.pageService.save(this.item).subscribe(id => {
            this.item.component_id = id;
            this.modalRef.close(this.item);
        });
    }

}
