export enum ClassNames {
    PAGE = 'page-container',
    FIXED_TABLE = 'fixed-table-height',
    HAS_DETAIL = 'has-detail-header',
    FIXED_FOOTER = 'fixed-page-footer',
    PROGRESS = 'page-progress-container',
    SIDEBAR = 'page-sidebar',
    Body = 'page-body',
    SHOW_HEADER = 'show-header',
}
