import { Selectable } from 'core';
import { Checkable, isPresent } from 'share';

export interface DetectTask extends Selectable {
    task_id?: number;
    task_name?: string;
    status?: number;
    product_id?: number;
    delay?: number;
    delay_unit?: string;
    alarm_delay?: number;
    alarm_unit?: string;
    begin_date?: Date;
    begin_time?: any;
    end_time?: any;
    detect_count?: number;
    number?: number;
    last_result?: number;
    detect_status?: number;
    type?: number;
    db_config?: string;

    client_machines?: string;
    is_create_bug?: number;
    bug_handle_user_id?: number;
    env_id?: number;
    app_id?: number;
    terminals?: string;

    system_name?: string;
    alarm_level?: number;
}

export interface DetectCase extends Checkable {
    id?: number;
    task_id?: number;
    case_id?: number;
    case_name?: string;
    case_group_id?: number;
    root_group_id?: number;
    root_group_name?: string;
    mode?: string;
    product_id?: number;
    exec_index?: number;

    operate_type?: string;
}

export class NotifyConfig {
    task_id?: number;
    level_name?: string;
    timeout?: string;
    overrate?: number;
    restore_threshold?: number;

    notify_groups?: number;
    sms_no?: string;
    mail_address?: string;
    wx_groups?: string;
    healthy_sms?: number;
    template_sms?: number;

    level2_notify_groups?: number;
    level2_notify_time?: number;
    level2_sms_no?: string;
    level2_mail_address?: string;
    level2_wx_groups?: string;

    level3_notify_groups?: number;
    level3_notify_time?: number;
    level3_sms_no?: string;
    level3_mail_address?: string;
    level3_wx_groups?: string;

    constructor(item: {
        task_id?: number;
        level_name?: string;
        sms_no?: string;
        mail_address?: string;
        wx_groups?: string;
        timeout?: string;
        overrate?: number;
        restore_threshold?: number;
        level2_notify_time?: number;
        level2_sms_no?: string;
        level2_mail_address?: string;
        level2_wx_groups?: string;
        level3_notify_time?: number;
        level3_sms_no?: string;
        level3_mail_address?: string;
        level3_wx_groups?: string;
    } = {}) {
        Object.keys(item)
            .filter(key => key !== 'overrate')
            .forEach(key => this[key] = item[key]);
        this.overrate = isPresent(item.overrate) ? +item.overrate * 100 : 0;
    }

    iValue(): any {
        let obj: any = {};
        Object.keys(this)
            .filter(key => key !== 'overrate')
            .forEach(key => obj[key] = this[key]);
        obj.overrate = this.overrate / 100;
        return obj;
    }
}

export interface DetectPerfTaskLog {
    task_id?: number;
    task_name?: string;
    start_time?: string;
    exec_time?: number;
}

export interface DetectPerfCaseLog extends DetectPerfTaskLog {
    case_id?: number;
    case_name?: string;
}

export interface DetectPerfAvgCase {
    case_id?: number;
    case_name?: string;
    task_id?: number;
    task_name?: string;
    average_time?: number;
}
